<ng-template class="modal fade" #passwordModal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Set new password</h4>
    </div>
    <div *ngIf="invalidOrExpiredCode; then invalidCode else resetPassword"></div>
  </div>
</ng-template>

<ng-template class="modal fade" #tenantModal>
  <app-tenant-dialog (continueHereEvent)="showCallbackModal()"></app-tenant-dialog>
</ng-template>

<ng-template class="modal fade" #recoverEmailModal>
  <div class="modal-content" style="min-height: 200px;">
      <div class="modal-header">
        <h4 class="modal-title">Recover email</h4>
      </div>
      <div *ngIf="invalidOrExpiredCode; then invalidCode else recoverEmail"></div>
  </div>
</ng-template>

<ng-template class="modal fade" #verifiedEmailModal>
  <div class="modal-content" style="min-height: 130px;">
      <div *ngIf="invalidOrExpiredCode; then invalidCode else verifiedEmail"></div>
  </div>
</ng-template>

<ng-template class="modal fade" #redeemModal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Redeem invitation</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="emailAddress">Confirm your email address</label>
        <input type="email" class="form-control" id="email_auth" required autofocus [(ngModel)]="emailAddress">
        <!-- <small [hidden]="emailAddress.valid || newPassword.pristine" class="text-danger">New password is required</small>
        <small *ngIf="newPasswordError && newPassword.pristine" class="text-danger">{{newPasswordError}}</small> -->
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="redeemNow(redeemModal)">
        Redeem
      </button>
    </div>
  </div>
</ng-template>

<ng-template #verifiedEmail>
  <div class="my-3">
    <div class="d-flex justify-content-center mb-2 mt-3" style="font-size: 30px; line-height: 30px; vertical-align: center;">
      <span class="mr-3" style="color: var(--success)"><i class="fa fa-check-circle"></i></span>
      <span style="font-size: 0.8em">Congratulations!{{ hideModal(2000)}}</span>
    </div>
    <div class="d-flex justify-content-center mt-2" style="font-size: 16px;">
        <span>Your email address has been verified.</span>
    </div>
  </div>
</ng-template>

<ng-template #recoverEmail>
  <div *ngIf="errorMessage" class="card border-danger m-3">
    <div class="card-body" id='error-message'>
      {{errorMessage}}
    </div>
  </div>
  <div *ngIf="textMessage" class="card border-success m-3">
    <div class="card-body" id='text-message'>
      {{textMessage}}
    </div>
  </div>
  <div *ngIf="showCompleteLogin">
    <form (ngSubmit)="completeRestore(emailRecoveryForm.value.password);" #emailRecoveryForm="ngForm">
      <div class="modal-body">
        Please enter your credentails to complete login
        <div class="form-group mt-3">
          <input type="password" class="form-control" id="password" name="password" placeholder="Your password" ngModel #password="ngModel">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!emailRecoveryForm.form.valid">
          Signin
        </button>
      </div>
    </form>
</div>
</ng-template>

<ng-template #resetPassword>
  <form (ngSubmit)="setPassword(passwordForm.value.newPassword); passwordForm.reset()" #passwordForm="ngForm">
    <div class="modal-body">
      <div class="form-group">
        <label for="newPassword">New password</label>
        <input type="password" class="form-control" id="newPassword" required autofocus ngModel name="newPassword" #newPassword="ngModel">
        <small [hidden]="newPassword.valid || newPassword.pristine" class="text-danger">New password is required</small>
        <small *ngIf="newPasswordError && newPassword.pristine" class="text-danger">{{newPasswordError}}</small>
      </div>
      <div class="form-group">
        <label for="repeatPassword">Repeat password</label>
        <input type="password" class="form-control" id="repeatPassword" [pattern]="passwordForm.value.newPassword | regexEscape" required ngModel name="repeatPassword" #repeatPassword="ngModel">
        <small [hidden]="repeatPassword.valid || (repeatPassword.pristine && newPassword.pristine)" class="text-danger">Passwords must match</small>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.form.valid">
        Set password
      </button>
    </div>
  </form>
</ng-template>
<ng-template #invalidCode>
  <app-password-reset-form
      [errorMessage]="'Password reset code has expired
      or is invalid. Please enter email address to send
      a new code.'">
  </app-password-reset-form>
</ng-template>
