export enum MotekHbmConfiguration {
    None,
    MotekHbmFullBody, //46 markers
    MotekHbmGait, //26 markers
}

export class MotekHbm {

    markerList: any[];

    readonly head = ["RFHD", "LFHD", "LBHD", "RBHD"];

    readonly upperLimbLeft = ["LSHO", "LUPA", "LELB", "LFRM", "LWRA", "LWRB", "LFIN"];
    readonly upperLimbRight = ["RSHO", "RUPA", "RELB", "RFRM", "RWRA", "RWRB", "RFIN"];

    readonly torso = ["C7", "T10", "XIPH", "JN"];

    readonly pelvis = ["LASIS", "RASIS", "RPSIS", "LPSIS"];
    readonly lowerLimbLeft = ["LLTHI", "LLEK", "LLSHA",];
    readonly lowerLimbRight = ["RLTHI", "RLEK", "RLSHA"];

    readonly leftFoot = [ "LLM", "LHEE", "LMT2", "RMT2"];
    readonly rightFoot = ["RLM", "RHEE", "RMT2", "RMT5"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];

        /*segments.push({ name: "head", markerList: this.head, allConnected: true});

        segments.push({ name: "rightArm", markerList: ["RSHO", "RUPA", "RELB"], allConnected: true, side: 2 });
        segments.push({ name: "rightLowerArm", markerList: ["RELB", "RFRM", "RWRA", "RWRB"], allConnected: true, side: 2 });
        segments.push({ name: "rightHand", markerList: ["RWRA", "RWRB", "RFIN"], allConnected: true, side: 2 });

        segments.push({ name: "leftArm", markerList: ["LSHO", "LUPA", "LELB"], allConnected: true, side: 1 });
        segments.push({ name: "leftLowerArm", markerList: ["LELB", "LFRM", "LWRA", "LWRB"], allConnected: true, side: 1 });
        segments.push({ name: "leftHand", markerList: ["LWRA", "LWRB", "LFIN"], allConnected: true, side: 1 });*/

        segments.push(  { name: "torso", markerList:  ["C7", "T10", "XIPH", "JN"], allConnected: true });

        segments.push(  { name: "pelvis", markerList:  ["LASIS", "RASIS", "RPSIS", "LPSIS"], allConnected: true });

        segments.push(  { name: "leftLegUp", markerList:  ["LASIS", "LPSIS", "LLTHI", "LLEK", "LMEK"], allConnected: true, side: 1 });
        segments.push(  { name: "leftLeg", markerList:  ["LLEK", "LMEK", "LLSHA", "LLM", "LMM"], allConnected: true, side: 1 });
        segments.push(  { name: "leftFoot", markerList:  ["LLM", "LMM", "LHEE", "LMT2", "LMT5"], allConnected: true, side: 1 });

        segments.push(  { name: "rightLegUp", markerList:  ["RASIS", "RPSIS", "RLTHI", "RLEK", "RMEK"], allConnected: true, side: 2 });
        segments.push(  { name: "rightLeg", markerList: ["RLEK", "RMEK", "RLSHA", "RLM", "RMM"], allConnected: true, side: 2 });
        segments.push(  { name: "rightFoot", markerList:  ["RLM", "RMM", "RHEE", "RMT2", "RMT5"], allConnected: true, side: 2 });

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];
        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      findConfiguration() {

        const fullBody = [this.head,
          this.upperLimbLeft,
          this.upperLimbRight,
          this.torso,
          this.pelvis,
          this.lowerLimbLeft,
          this.lowerLimbRight,
          this.leftFoot,
          this.rightFoot
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return MotekHbmConfiguration.MotekHbmFullBody;
        }

        const gait = [
            this.pelvis,
            this.lowerLimbLeft,
            this.lowerLimbRight,
            this.leftFoot,
            this.rightFoot
          ];

        if (this.checkConfiguration(gait).length <= 1)
            return MotekHbmConfiguration.MotekHbmGait;

        return MotekHbmConfiguration.None;
      }

}
