export const environment = {
  production: true,
  env: 'dev',
  baseHref: 'https://app.dev.moveshelf.com/',
  baseHrefUs: 'https://app.us.dev.moveshelf.com/',
  subdomainTemplate: '.dev.moveshelf.com',
  graphqlUrl: 'https://api.dev.moveshelf.com/graphql',
  serverGraphqlUrl: 'http://backend/graphql',
  privateApiUrl: 'https://api.dev.moveshelf.com/_private_api/',
  firebase: {
    apiKey: "AIzaSyD3Au-EsbRk1Rt_SzqI7O6mdqdCH4EVxNs",
    authDomain: "moveshelf-dev.firebaseapp.com",
    databaseURL: "https://moveshelf-dev.firebaseio.com",
    projectId: "moveshelf-dev",
    storageBucket: "moveshelf-dev.appspot.com",
    messagingSenderId: "891728977265"
  },
  stripe: {
    apiKey: 'pk_test_zOglmvy9WFGTUkKK1e7nu90B'
  },
  fb_appId: "416005518766088",
  twitter_userName: "@moveshelf",
  staffPicks: ['TW9jYXBDbGlwvQh92mgrQT2U9mLI_aRlaQ', //home
  'TW9jYXBDbGlwEvLKnya1Tbu6IZRC-0TZAw', //learn-more
  'TW9jYXBDbGlwMA2370IORHGmGqhpzLNJfA', //character-page
  'TW9jYXBDbGlwQjT9BsfLQoKzuM6bNtRUng'], //beta-access-vicon
  reportTestId: ['TW9jYXBDbGlwUtaWQgUWQUGLuSinnyuMGw'],
};


