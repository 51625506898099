<span *ngIf=allowEdit> <!-- currently only edit actions here -->
<span *ngIf="!showApprove else approveDeny">
<ng-template #popTemplate>
  <div style="width: 70px">
    <span *ngIf=allowEdit class="clickable" (click)="onEditClick()"> <i class="fa fa-pencil-square-o"></i> Edit </span><br>
    <span *ngIf=allowEdit class="clickable" (click)="onDeleteClick()"><i class="fa fa-trash-o"></i> Delete </span>
  </div>
</ng-template>
<span class="clickable"><a [popover]="popTemplate"
[outsideClick]="true" placement="bottom">...</a></span>
</span>
<ng-template #approveDeny>
<i class="fa fa-check clickable" (click)=onApproveClick()></i>
<i class="fa fa-times clickable" (click)=onDenyClick()></i>
</ng-template>
</span>