import { Component } from '@angular/core';

@Component({
  selector: 'app-force-overlay-icon',
  templateUrl: './force-overlay-icon.component.html',
  styleUrls: ['./force-overlay-icon.component.scss']
})
export class ForceOverlayIconComponent {

}
