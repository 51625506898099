import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Apollo } from "apollo-angular";
import { SessionService } from "app/projects/patient-view/create-session/session.service";
import { ProgressionAnalysisOptions } from "app/projects/project-info/project-info-data.service";
import { SubjectContextService } from "app/projects/subject-context/subject-context.service";
import { SubjectResolverService } from "app/projects/subject-resolver.service";
import gql from 'graphql-tag';
import { firstValueFrom, Subject } from "rxjs";
import { ClipId } from "../processor.types";

export const SubjectClipsByIdQuery = gql`
  query getPatient($id: ID!) {
    node(id: $id) {
      ... on Patient {
        id,
        project {
          id
          canEdit
        }
        metadata
        sessions {
          id
          projectPath
          metadata
          clips {
            id
            title
            additionalData {
              id,
              dataType,
              originalFileName,
              previewDataUri,
              uploadStatus
            }
          }
        }
      }
    }
  }
`;

interface Measure {
  name: string,
  value: number,
  date: Date,
  version: string
}

const versionPrefix = '_<v>';

@Injectable()
export class ProgressionParamsService {

  public progressionParams: Map<string, any> = new Map<string, any>();
  public processedClips$: Subject<number> = new Subject<number>();
  private allMeasures: Measure[] = [];
  private maxMajorVersion = 0;
  constructor(
    private apollo: Apollo,
    private subjectService: SubjectContextService,
    private sessionService: SessionService,
    private http: HttpClient,
    private subjectResolverService: SubjectResolverService,
    private route: ActivatedRoute,
  ) {}

  public async processClips(clipIds: ClipId[], progressionAnalysisConfig: any): Promise<void> {
    if (!this.route.snapshot?.data?.subjectId) {
      throw new Error('subject from URL not loaded correctly');
    }


    try {
      const subjectResult = await firstValueFrom(this.apollo.query<{node: any}>({
        query: SubjectClipsByIdQuery,
        variables: {
          id: this.route.snapshot.data.subjectId
        }
      }));
      let processedClips = 0;
      this.allMeasures = [];
      this.processedClips$.next(0);
      subjectResult.data.node.sessions.forEach(session => {
        if (!session.clips) {
          throw new Error('error retrieving clip data');
        }
        session.clips.forEach(async clip => {
          if (clipIds.indexOf(clip.id) >= 0) {
            if (!clip.additionalData) {
              throw new Error('error retrieving additional data');
            }
            const additionalDataLoaders: Promise<number>[] = [];
            const progressionAnalysisOptions = progressionAnalysisConfig as ProgressionAnalysisOptions;
            this.progressionParams.set(clip.id, {});
            let additionalDataProcessed = 0;
            clip.additionalData.forEach(additionalData => {
              if (progressionAnalysisOptions &&
                additionalData.dataType == 'data'  && additionalData.originalFileName.indexOf("gait_params") != -1) {
                additionalDataProcessed++;
                additionalDataLoaders.push(this.extractProgressionParams(additionalData.previewDataUri, progressionAnalysisConfig.parameterFilter, progressionAnalysisConfig.subjectMetaDataParameters, clip.id));
              }
            });
            if (additionalDataProcessed === 0) {
              processedClips++;
              this.processedClips$.next(processedClips);
            } else {
              Promise.all(additionalDataLoaders).then(() => {
                this.addProgressionMeasures(progressionAnalysisConfig, session, clip.id);
                processedClips++;
                this.processedClips$.next(processedClips);
              });
            }
          }
        });
      });
    } catch(queryError) {
      console.error('Error retrieving subject data', queryError);
      throw queryError;
    }
  }

  public addMeasures(): boolean {
    let hasOlderVersions = false;
    for (const measure of this.allMeasures) {
      // check if we have a version and only push latest version
      if (this.maxMajorVersion === 0 || (this.maxMajorVersion >= 0 && measure.version === this.maxMajorVersion.toString())) {
        const olderVersionFound = this.subjectService.addMeasure(measure.name, measure.value, measure.date, measure.version);
        hasOlderVersions = hasOlderVersions || olderVersionFound;
      } else if (measure.version.length === 0 && this.maxMajorVersion > 0) {
        hasOlderVersions = true;
      }
    }
    return hasOlderVersions;
  }

  public async writeProgressionResults(): Promise<void> {
    this.subjectService.writeCurrentMetadataInfo().subscribe(({ data }) => {
      console.log('written');
    });
  }

  private async extractProgressionParams(dataUri: string, paramsFilter: string[], metaDataList: string[], clipId: string): Promise<number> {
    const res = await this.http.get(dataUri).toPromise();
    if (res) {
      const parsedData = res as any;
      const params = parsedData.data;
      const jsonDate = parsedData.date;
      if (jsonDate) {
        const validTrialDate = !isNaN(new Date(jsonDate).getTime());
        if (validTrialDate) {
          this.progressionParams.get(clipId)['date'] = jsonDate;
        }
      }

      // check for pipeline version and keep track of highest version
      let pipeline_version: string = '';
      let majorVersionStr: string = '';
      if (parsedData.pipeline_version) {
        pipeline_version = parsedData.pipeline_version;
        const versionSplit = pipeline_version.split('.');
        if (versionSplit && versionSplit.length > 1) {
          const majorVersion = parseInt(versionSplit[0]);
          if (!isNaN(majorVersion)) {
            this.maxMajorVersion = Math.max(this.maxMajorVersion, majorVersion);
            majorVersionStr = versionSplit[0];
            this.progressionParams.get(clipId)['pipeline_major_version'] = this.maxMajorVersion.toString();
          }
        }
      }

      const paramsFilterLowerCased = paramsFilter.map(param => param.toLowerCase());
      if (paramsFilter && metaDataList && paramsFilter.length === metaDataList.length && params) {
        for (const p of params) {
          const index = paramsFilterLowerCased.indexOf(p['label'].toLowerCase());
          if (index > -1) {
            let value = parseFloat(p['values']['mean']);
            if (p['label'].toLowerCase().includes('speed') && p['unit'] !== undefined && p['unit'].includes('km/h')) {
              // we expect m/s for speed, so convert measure
              value /= 3.6;
            }
            let metaDataParam = String(metaDataList[index]);
            if (majorVersionStr.length > 0) {
              metaDataParam += versionPrefix + majorVersionStr; // store version with parameter to be able to pick the matching versions when gathering all data
            }
            if (!this.progressionParams.get(clipId)[metaDataParam]) {
              this.progressionParams.get(clipId)[metaDataParam] = [];
            }
            this.progressionParams.get(clipId)[metaDataParam].push(value);
          }
        }
      }
      return Promise.resolve(0);
    } else {
      return Promise.resolve(0);
    }
  }

  private addProgressionMeasures(progressionAnalysisConfig, session, clipId: string): void {
    const progressionAnalysisOptions = progressionAnalysisConfig as ProgressionAnalysisOptions;
    if (progressionAnalysisOptions) {
      const sessionName = this.sessionService.extractSessionNameFromSession(session);
      let dateToStore = new Date(sessionName);
      if (isNaN(dateToStore.getTime())) {
        const timestamp = sessionName.split('_')[0];
        if (timestamp && timestamp.length > 0) {
          dateToStore = new Date(+timestamp*1000);  // convert s to ms
        }
      }
      const validDate = !isNaN(dateToStore.getTime());
      if (validDate) {
        const measurementTimeStr = 'measurementTime';
        let doAverage = false;
        let doWeightWithTime = false;
        if (progressionAnalysisOptions.averageTrial === true) {
          doAverage = true;
          if (this.progressionParams.get(clipId)['measurementTime'] && this.progressionParams.get(clipId)['measurementTime'].length > 0) {
            doWeightWithTime = true;
          }
        }

        // First check if we have a date stored in the data
        let pipeline_major_version: string = "";
        let hasVersion = false;
        for (const p in this.progressionParams.get(clipId)) {
          if (p === 'date') {
            const trialDate = new Date( this.progressionParams.get(clipId)[p]);
            if (!isNaN(trialDate.getTime())) {
              dateToStore = trialDate;
            }
          }
          if (p === 'pipeline_major_version') {
            pipeline_major_version = this.progressionParams.get(clipId)[p];
            hasVersion = true;
          }
        }


        for (const p in this.progressionParams.get(clipId)) {
          let myParam;
          if (p !== measurementTimeStr && p !== 'date' && p !== 'pipeline_major_version') {
            // only keep latest version
            const paramStrSplit = p.split(versionPrefix);
            if (hasVersion && (paramStrSplit.length <=1 || (paramStrSplit.length > 1 && paramStrSplit[1]) !== pipeline_major_version)) {
              continue;
            }

            if (doAverage) {
              if (doWeightWithTime && this.progressionParams.get(clipId)[p].length !== this.progressionParams.get(clipId)[measurementTimeStr].length) {
                doWeightWithTime = false;
              }
              let sum = 0;
              let measurementTime = 0;
              for (let i = 0; i < this.progressionParams.get(clipId)[p].length; i++) {
                let avgWeight = 1;
                if (doWeightWithTime) {
                  avgWeight = this.progressionParams.get(clipId)[measurementTimeStr][i];
                }
                // skip if NaN
                if (!isNaN(this.progressionParams.get(clipId)[p][i]) && !isNaN(avgWeight)) {
                  sum += this.progressionParams.get(clipId)[p][i]*avgWeight;
                  measurementTime += avgWeight;
                }
              }
              // Only store value if we found a valid value
              if (measurementTime > 0) {
                myParam = sum/measurementTime;
              }
            } else {
              myParam = this.progressionParams.get(clipId)[p];
            }

            // remove version label from parameter before storing
            let nameToStore = p;
            if (hasVersion) {
              nameToStore = nameToStore.replace(versionPrefix + pipeline_major_version, "");
            }
            if (myParam ) {
              if (Array.isArray(myParam)) {
                for (let i = 0; i < myParam.length; i++) {
                  if (myParam[i]) {
                    myParam[i] = Math.round(myParam[i]*1000)/1000;
                  }
                  this.allMeasures.push({name: nameToStore, value: myParam[i], date: dateToStore, version: pipeline_major_version});
                }
              } else {
                myParam = Math.round(myParam*1000)/1000;
                this.allMeasures.push({name: nameToStore, value: myParam, date: dateToStore, version: pipeline_major_version});
              }
            }
          }
        }
      }
    }
  }

}
