import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripFirstPath'
})
export class StripFirstPathPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const parts = value.split('/');
    let path = "";
    for (let i=1; i < parts.length; i++)
      path += parts[i];

    return path;
  }

}
