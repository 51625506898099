<div *ngIf="this.layouts?.length>0" class="ml-2">
    <h5 class="clickable-success" (click)="this.toggleExpand()">Layout shortcuts <i
            [class]="expanded ? 'fa fa-angle-up': 'fa fa-angle-down'"></i>
        <app-spinner *ngIf="this.spinner === true" [size]="'small'"></app-spinner>
    </h5>
    <div class="mx-1 mb-3" *ngIf="this.expanded">
        <ul>
            <li *ngFor="let layout of this.layouts; let i = index" [class.disabled-li]="spinner">
                <span class="clickable-success" (click)="spinner ? null : loadLayout(i)">{{layout.layoutName +
                    (layout.version && layout.version > 1 ? ' (' + layout.version + ')' : '')}}</span>
                <i *ngIf="this.canEdit" class="clickable-danger fa fa-trash align-self"
                    (click)="spinner ? null : openDeleteConfirmationModal(i)"></i>
            </li>
        </ul>
    </div>
</div>