import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ShareModule } from 'ngx-sharebuttons';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedModule } from '../shared/shared.module';
import { ClipLoaderComponent } from './clip-loader/clip-loader.component';
import { ClipLoaderService } from './clip-loader/clip-loader.service';
import { MocapClipComponent } from './mocap-clip.component';




@NgModule({ declarations: [
        ClipLoaderComponent,
    ],
    exports: [
        MocapClipComponent,
        ClipLoaderComponent,
    ], imports: [CommonModule,
        RouterModule,
        ShareModule,
        UiSwitchModule,
        SharedModule,
        TooltipModule,
        MocapClipComponent], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class Moveshelf3dPlayerModule {
  static forRoot(): ModuleWithProviders<Moveshelf3dPlayerModule> {
    return {
      ngModule: Moveshelf3dPlayerModule,
      providers: [
        ClipLoaderService
      ]
    };
  }
}
