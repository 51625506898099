import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licenseName'
})
export class LicenseNamePipe implements PipeTransform {

  transform(value: string, includeArticle?: boolean): string {
    const license = this.mapLicense(value);

    if (includeArticle) {
      switch (value) {
          case 'Restricted': return 'an ' + license;
          default: return 'a ' + license;
      }
    }
    return license;
  }

  private mapLicense(license: string): string {
    switch (license) {
      case 'CC_BY':
        return 'Creative Commons Attribution';

      case 'CC_BY_ND':
        return 'Creative Commons Attribution No Derivatives';

      case 'CC_BY_SA':
        return 'Creative Commons Attribution Share-Alike';

      case 'CC_BY_NC':
        return 'Creative Commons Attribution Non-Commercial';

      case 'CC_BY_NC_ND':
        return 'Creative Commons Attribution Non-Commercial No-Derivatives';

      case 'CC_BY_NC_SA':
        return 'Creative Commons Attribution Non-Commercial Share-Alike';

      default:
        return 'All Rights Restricted';
    }
  }
}
