
import { APP_BASE_HREF, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, makeStateKey, Optional, PLATFORM_ID, StateKey, TransferState } from '@angular/core';

import { EnvironmentService } from 'app/shared/services/environment.service';
import { Observable, of as observableOf } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as SparkMD5 from 'spark-md5';

@Injectable()
export class SvgIconCacheService {
  private localCache: Map<string, string> = new Map<string, string>();

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    private environment: EnvironmentService,
    @Inject(PLATFORM_ID) private platformId,
    @Optional()  @Inject(APP_BASE_HREF) private origin: string
  ) { }

  public getSvg(url: string): Observable<string> {
    this.origin = this.environment.getEnvironment().baseHref;
    const key = this.makeKey(url);
    if (this.localCache.has(key)) {
      return observableOf(this.localCache.get(key));
    } else if (this.transferState.hasKey(key)) {
      const value = this.transferState.get(key, null);
      this.localCache.set(key, value);
      return observableOf(value);
    } else {
      return this.http.get(`${url}`, {responseType: 'text'}).pipe(
        tap(svg => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(key, svg);
            } else {
              this.localCache.set(key, svg);
            }
          },
          err => console.error('Error fetching svg icon: ', err))
      );
    }
  }

  private makeKey(url: string): StateKey<string> {
    // casting SparkMD5 as any to avoid library import issues not finding function "hash"
    return makeStateKey('svg-icon-'+ (SparkMD5 as any).hash(url));
  }
}
