import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { AdditionalDataInfo } from 'app/core/additional-data.service';
import { pollConfig } from 'app/shared/services/polling.service';
import gql from 'graphql-tag';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import {
  filter, map, mergeMap, switchMap, takeWhile
} from 'rxjs/operators';
import { ProjectDataByIdQuery, viewerProjects } from '../shared/queries';


declare let require: any;

export interface ProjectMetadataInput {
  name?: string;
  description?: string;
  unlistedAccess?: boolean;
  public?: boolean;
  imageUri?: string;
  isGlobal?: boolean;
}

export interface UpdateProjectInput {
  name: string;
  metadata: ProjectMetadataInput;
  configuration?: JSON;
  organization?: string;
}

export interface ProjectMemberInput {
  name: string;
  member: string;
  role?: string;
}

export interface ClipInfo {
  id: string;
  title: string;
  previewImageUri: string;
  dataReady: boolean;
  projectPath?: string;
  uploadStatus: string;
  additionalData: AdditionalDataInfo[];
}

@Injectable()
export class ClipService {

  private _monitorClips = new Subject<ClipInfo[]>();

  public multiSelection = new Map<string, string>();
  isEditingExpanded = false;
  canDownloadSelection = false;

  constructor(
    private apollo: Apollo
  ) {
    this.startClipMonitor();
  }

  collapseEdit() {
    this.isEditingExpanded = false;
    this.multiSelection.clear();
  }

  expandEdit() {
    this.isEditingExpanded = true;
  }

  selectedCount() {
      return this.multiSelection.size;
  }

  getClipsForProjectId(projectId: string): Observable<ClipInfo[]> {
    return this.apollo.watchQuery<any>({
      query: ProjectDataByIdQuery,
      variables: {
        id: projectId
      }
    }).valueChanges.pipe(
      map(({data}) => data.node.clips.edges.map(e => e.node))
    );
  }

  public getProjectDataQueyById(projectId: string): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: ProjectDataByIdQuery,
      variables: {
        id: projectId
      },
    });
  }


  monitorClipReadiness(clips: ClipInfo[]) {
    this._monitorClips.next(clips);
  }

  deleteProject(name: string) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation deleteProjectMutation($name: String!) {
          deleteProject(name: $name) {
            ok
          }
        }
      `,
      variables: {
          name: name
        },
        update: (store, {data}) => {
          const cache = store.readQuery<any>({
            query: viewerProjects,
          });
          if (cache?.viewer?.projects) {
            // Update the projects list cache:
            // 1. keep all projects outside the one deleted
            // 2. for the one deleted, remove it if it was already soft deleted
            // 3. otherwise keep it but show it as soft deleted
            const projects = cache.viewer.projects
            .filter(project => project.name !== name || project.deleted === false)
            .map(project => {
              if (project.name === name) {
                return {...project, deleted: true};
              }

              return project;
            });
            const updatedCache = {...cache, viewer: {...cache.viewer, projects: projects}};
            store.writeQuery({
              query: viewerProjects,
              data: updatedCache
            });
          }
        }
    });
  }

  updateProject(input: UpdateProjectInput) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updateProjectMutation($input: UpdateProjectInput!) {
          updateProject(input: $input) {
            project {
              id
              name
              description
              public
              imageUri
              unlistedAccess
              configuration
            }
          }
        }
      `,
      variables: {
          input: input
        }
    });
  }

  removeProjectMember(input: ProjectMemberInput) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation removeProjectMemberMutation($input: ProjectMemberInput!) {
          removeProjectMember(input: $input) {
            project {
              id,
              members {
                role,
                user {
                  username,
                  avatar
                }
              }
            },
            result
          }
        }
      `,
      variables: {
          input: input
        }
    });
  }

  addProjectMember(input: ProjectMemberInput) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation addProjectMemberMutation($input: ProjectMemberInput!) {
          addProjectMember(input: $input) {
            project {
              id,
              isAdmin,
              members {
                role,
                user {
                  username,
                  avatar
                }
              }
            },
            result,
            link
          }
        }
      `,
      variables: {
          input: input
        }
    });
  }

  addRemoteFileToZip(url: string, filename: string, zip: any) {
    const JSZipUtils = require('jszip-utils');
    const JSZip = require('jszip');

    // loading a file and add it in a zip file
    return new Promise ( (resolve) => JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        throw err; // or handle the error
      }

      zip.file(filename, data, {binary: true});
      resolve(true);
    }));
  }

  downloadUriQuery(id: string) {
    return this.apollo.query<any>({
      query: gql`
        query downloadUri($id: ID!) {
          node(id: $id) {
            ... on MocapClip {
              id
              originalDataDownloadUri,
              originalFileName
            }
          }
        }
      `,
      variables: { id: id }
    });
  }

  downloadClipsAsZip(ids: string[], zipFilename: string): Promise<boolean> {

    const JSZip = require('jszip');
    const FileSaver = require('file-saver');

    return new Promise( (resolve) => {

      const zipFile = new JSZip();
      const promiseList = [];

      for (const id of ids) {
        this.downloadUriQuery(id).subscribe( ({data}) => {
          const downloadUri = data.node.originalDataDownloadUri;
          const filename = data.node.originalFileName;
          promiseList.push(this.addRemoteFileToZip(downloadUri, filename, zipFile));
          if (promiseList.length == ids.length) {
            Promise.all(promiseList).then( () => {
              zipFile.generateAsync({type: "blob"}).then( (blob) => {
                FileSaver.saveAs(blob, zipFilename);
                resolve(true);
              });
            });
          }
        });

      }
    });
  }

  deleteClips(projectId: string, clipIds: string[]): Observable<boolean> {
    return forkJoin(clipIds.map(clipId => {
      return this.apollo.mutate({
        mutation: gql`
        mutation deleteClip($clipId: String!) {
          deleteClip(clipId: $clipId) {
            ok
          }
        }
      `,
        variables: {
          clipId: clipId
        },
        update: (store, {data: {deleteClip}}: {data: {deleteClip: {ok: boolean}}}) => {
          if (deleteClip.ok) {
            const cache = store.readQuery<any>({
              query: ProjectDataByIdQuery,
              variables: {
                id: projectId
              }
            });
            const updatedEdges = cache.node.clips.edges.filter(
              edge => edge.node.id !== clipId);
            const updatedClips = {...cache.node.clips, edges: updatedEdges};
            const updatedNode = {...cache.node, clips: updatedClips};
            const updatedCache = {...cache, node: updatedNode};
            store.writeQuery({
              query: ProjectDataByIdQuery,
              variables: {
                id: projectId
              },
              data: updatedCache
            });
          }
        }
      });
    })).pipe(
      map(results => results.reduce((acc, cur) => acc || cur.data.deleteClip.ok, true))
    );
  }

  private startClipMonitor(): void {
    this._monitorClips.pipe(
      switchMap(clips => {
        return from(clips).pipe(
          filter(clip => !clip.dataReady),
          mergeMap(clip => {
            return this.apollo.watchQuery<any>({
              query: gql`
                query getClipDataReady($clip: ID!) {
                  node(id: $clip) {
                    ... on MocapClip {
                      id
                      dataReady
                    }
                  }
                }
              `,
              variables: {
                clip: clip.id
              },
              pollInterval: pollConfig.LONG
            }).valueChanges.pipe(
              map(({data}) => data.node.dataReady),
              takeWhile(ready => !ready)
            );
          })
        );
      })
    ).subscribe();
  }
}
