<div style="width: 100%; height: 4px; background-color: #0ad3bd;">
</div>
<div class="main-content px-3 m-3" style="max-width: 100%; margin-left: 150px;">
  <svg-icon src="/assets/moveshelf_icon.svg" class="svg-clickable-info" style="width: 24px;"></svg-icon>&nbsp;
  <svg-icon class="noborder mr-3" src="/assets/moveshelf_wordmark.svg"
    style="cursor: pointer;  padding-top: 3px; fill: #fff; width: 138px; margin-left: 10px;"></svg-icon>
  <div style="margin-left: 100px; margin-top: 30px">
    <div class="row">
      <img alt="SMK logo" src="./assets/smkLogo.png" width="230px" style="margin-left: 130px; margin-top: 25px; margin-bottom: 15px; border-radius: 5px;">
      </div>
    <div class="row">
        <h2 class="col">Home-monitoring &#64;SMK
        <span *ngIf="showSettings" style="color: #777; margin-left: 10px;" (click)="showModal(modal)">
          <i class="fa fa-cog clickable-info"></i>
        </span>
      </h2>
    </div>
    <div *ngIf="isEditingConfigData" class="card mb-3" style="max-width: 550px;">
      <div class="card w-100" style="padding-left: 20px; padding-right: 50px;">
        <div class="card-body p-2">
          <form *ngIf="form" [formGroup]="form" (ngSubmit)="updateSettings()">
            <div class="form-row px-2 pt-2">
              <button class="btn btn-primary m-2 mb-3" type="button" (click)="downloadBackend()">
                <span > Download and start local server</span>
              </button>
              <small id="exe_explanation" class="form-text text-muted mb-4">
                <i>Important: Click the button, then double click the downloaded *.bat file, allow the installers to run and refresh the page.</i>
              </small>
            </div>
            <div>
              <label class="mb-3">Project configuration</label>
            </div>
            <div class="form-row px-2" style="max-width: 200px;">
              <label class="text-muted">Patient Name</label>
              <input type="text" class="form-control pt-1" formControlName="patientName" id="patientName"/>
            </div>
            <div class="form-row px-2 pt-2">
              <label class="text-muted">Project Name</label>
              <input type="text" class="form-control pt-1" formControlName="projectName" id="projectName"/>
            </div>
            <div class="form-row px-2 pt-2">
              <label class="text-muted">API secret key</label>
              <input type="text" class="form-control pt-1" formControlName="apiKey" id="apiKey"/>
            </div>
            <div>
              <label class="mb-2 mt-3">Sensor configuration</label>
            </div>
            <div class="form-row px-2 pt-2" style="max-width: 200px;">
              <select class="custom-select mb-3" [value]="sensorTypes" (change)="changeSensorType($event)" formControlName="sensorType" id="sensorType">
                <option value="">Choose sensor type</option>
                <option *ngFor="let s of sensorTypes" [ngValue]="s">{{ s }} </option>
              </select>
            </div>
            <div class="form-row px-2 pt-2" style="max-width: 200px;">
              <label class="text-muted">Left foot sensor ID*</label>
              <input class="form-check-input" *ngIf="selectedSensor==='GaitUp'" type="checkbox" checked readonly onclick="return false" />
              <input type="text" class="form-control pt-1" formControlName="lfId" id="lfId"/>
            </div>
            <div class="form-row px-2 pt-2" style="max-width: 200px;">
              <label class="text-muted">Right foot sensor ID</label>
              <input class="form-check-input" *ngIf="selectedSensor==='GaitUp'" type="checkbox" [checked]="rfEnabled" (change)="changeRfEnabled($event)" />
              <input *ngIf="rfEnabled===true || selectedSensor==='APDM'" type="text" class="form-control pt-1" formControlName="rfId" id="rfId"/>
            </div>
            <div class="form-row px-2 pt-2" style="max-width: 200px;">
              <label class="text-muted">Lumbar sensor ID</label>
              <input class="form-check-input" *ngIf="selectedSensor==='GaitUp'" type="checkbox" [checked]="lumbarEnabled" (change)="changeLumbarEnabled($event)" />
              <input *ngIf="lumbarEnabled===true  || selectedSensor==='APDM'" type="text" class="form-control pt-1" formControlName="lumbarId" id="lumbarId"/>
            </div>
            <div class="form-row px-2 pt-2" style="max-width: 300px; margin-top: 20px;">
              <label class="text-muted">Local storage path (readonly)</label>
              <input type="text" class="form-control pt-1" formControlName="storageLocation" id="storageLocation" readonly/>
            </div>
            <div class="form-row px-2 pt-2" style="max-width: 300px;">
              <label class="text-muted">Condition name (readonly)</label>
              <input type="text" class="form-control pt-1" formControlName="conditionName" id="conditionName" readonly/>
            </div>
            <div class="form-row px-2 pt-2">
              <label class="text-muted">API url (readonly)</label>
              <input type="text" class="form-control pt-1" formControlName="apiUrl" id="apiUrl" readonly/>
            </div>
            <div>
              <label class="mb-2 mt-3">Debug info</label>
            </div>
            <div class="form-row px-2 pt-2" *ngIf="debugMsg.length > 0">
              <i class="fa fa-warning mr-2"></i>
              <span>{{debugMsg}}</span>
            </div>
            <div class="form-row px-2 pt-2" *ngIf="lastUpload.mySession.length > 0">
              <span>Last upload - Session: {{lastUpload.mySession}}, Condition: {{lastUpload.myCondition}}, Trial: {{lastUpload.myTrial}}</span>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" [disabled]="form.invalid ">
                <span *ngIf="!isSubmitted else spinner"><i class="fa"></i>Save settings</span>
              </button>
              <button type="button" class="btn btn-secondary" (click)="closeSettings()">Cancel</button>
            </div>
          </form>

        </div>

      </div>
    </div>
    <div *ngIf="configOk else noConfig">
      <div class="row" style="margin-top: 15px; margin-bottom: 30px">
        <h4 class="col">Welcome {{patientNameDisplay}}</h4>
      </div>

      <div style="margin-bottom: 40px; width: 100%">
        <span>Sensors detected ({{nSensors}} sensors required) </span>
      </div>
      <div *ngIf="sensorsList.length === 0" class="p-3">
        <i class="text-muted">No sensor connected</i>
      </div>

      <div *ngFor="let s of sensorsList" class="list-group-item list-group-item p-3" style="max-width: 300px;">
        <div>
          <div>
            <span><i class="fa fa-folder mr-2"></i>{{s.name}}</span><span><i [class]="s.idMatching ? 'fa fa-check-square': 'fa fa-exclamation-circle'" [style.color] = "s.idMatching ? 'green' : 'red'" style="float: right"></i> </span>
          </div>
        </div>

      </div>

      <div style="margin-top: 40px; max-width: 550px">
        <button class="btn btn-primary m-2 mb-3" type="submit" [disabled]="!backendAvailable || !detectedSensorsOk" (click)="handleSensorData()">
          <span *ngIf="!backendProcessing else spinner"><i class="fa"></i>
            <span *ngIf="detectedSensorsOk">Import new data</span>
            <span *ngIf="!detectedSensorsOk">Detected sensors not matching configuration</span>
          </span>
        </button>
        <p *ngIf="progressMsg.length > 0" style="background-color: #444; padding: 15px; color: #aaa;">
          <i class="fa fa-info-circle mr-2"></i>
          <span>{{progressMsg}}
          </span>
        </p>
      </div>


      <div class="row" style="margin-top: 70px; margin-bottom: 10px">
        <h5 class="col" style="color: #999;">Debug information</h5>
      </div>
      <div style="max-width: 1000px">
        <p *ngIf="debugMsg.length > 0" style="background-color: #444; padding: 15px; color: #aaa;">
          <i class="fa fa-warning mr-2"></i>
          <span>{{debugMsg}}
          </span>
        </p>
        <!--<p *ngIf="backendAvailable">Local homemonitoring status is {{this.serverStatus}}</p>

        <p *ngIf="!backendAvailable">Start local homemonitoring server by double click on the executable</p>-->

        <!--<button class="btn btn-primary mr-2" (click)="this.pingLocalHomeMonitoringServer()">Contact home monitoring</button>
        <button class="btn btn-primary" (click)="this.pingLocalHomeMonitoringServerLocal()">Contact local home monitoring</button>-->
      </div>
    </div>
    <ng-template #spinner>
      <i class="fa fa-spinner fa-spin"></i>
    </ng-template>

    <ng-template class="modal fade" #modal>
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Provide passcode</h4>
        </div>
        <form *ngIf="formPasscode" [formGroup]="formPasscode" (ngSubmit)="checkPasscode()">
          <div class="modal-body">
            <div class="form-group">
              <label for="passCode">Passcode</label>
              <input type="text" class="form-control" formControlName="passCode" placeholder="4 digits" autofocus required/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="hideModal()">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="formPasscode.invalid">Open settings</button>
          </div>
        </form>
      </div>
    </ng-template>

  </div>


</div>

<ng-template #noConfig>
  <div style="margin-top: 40px; max-width: 550px">
    <button *ngIf="!backendAvailable" class="btn btn-primary m-2 mb-3" type="submit" (click)="startBackend()">
      Start local server
    </button>
    <p style="background-color: #444; padding: 15px; color: #aaa;">
      <span *ngIf="!backendAvailable">Start the application by clicking "Start local server", double-click the downloaded file (keep window open!) and refresh the page. <br> <br> If this does not work, please contact support.</span>
      <span *ngIf="backendAvailable">Local server found, but configuration not set correctly, please contact support.</span>
    </p>
  </div>
</ng-template>

