import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { ResumableUploadService } from '../core/resumable-upload.service';
import { TimeSelection } from '../core/time-selection';
import { EnvironmentService } from './services/environment.service';



@Component({
  selector: 'app-support-request-dialog',
  templateUrl: './support-request-dialog.component.html',
  styleUrls: ['./support-request-dialog.component.scss']
})
export class SupportRequestDialogComponent implements OnInit {
  file: File;
  filename: string;
  gcsObject: string;
  uploadProgress: number;
  selection: TimeSelection;

  requestSubmitted = new Subject<null>();

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private modal: BsModalRef,
    public auth: AuthService,
    private uploader: ResumableUploadService,
    private environment: EnvironmentService,
  ) {
    this.form = this.formBuilder.group({
      system: [ '', Validators.required ],
      software: [ '', Validators.required ],
      comment: ''
    });
  }

  ngOnInit() {
  }

  public submit() {
    if (this.file) {
      this.uploadData();
    } else {
      // TODO: handle case of clip ID...
    }
  }

  public cancel() {
    this.modal.hide();
  }

  private submitSupportDetails() {
    const token = this.auth.getAuthToken();
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
    };
    const upload = this.http.post(
      `${this.environment.getEnvironment().privateApiUrl}support_request/submit`,
      {
        system: this.form.get('system').value,
        software: this.form.get('software').value,
        comment: this.form.get('comment').value,
        filetype: this.filename.split('.').pop(),
        data: this.gcsObject,
        selection: this.selection
      },
      httpOptions
    ).subscribe(() => {
      this.requestSubmitted.next(null);
      this.requestSubmitted.complete();
      this.modal.hide();
    });
  }

  private uploadData() {
    const token = this.auth.getAuthToken();
    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
    };
    const upload = this.http.get<{uri: string}>(
      `${this.environment.getEnvironment().privateApiUrl}support_request/upload_uri`,
      httpOptions
    ).pipe(
      switchMap(response => {
        const uri = response.uri;
        const req = new HttpRequest('PUT', response.uri, this.file, {
          reportProgress: true
        });
        return this.uploader.upload(req);
      })
    ).subscribe(evt => {
      switch (evt.type) {
        case HttpEventType.Sent:
          this.uploadProgress = 0;
          break;

        case HttpEventType.UploadProgress:
          this.uploadProgress = evt.loaded / evt.total;
          break;

        case HttpEventType.Response:
          this.uploadProgress = 1;
          this.gcsObject = evt.body.name;
          this.filename = this.file.name;
          this.submitSupportDetails();
          break;
      }
    });
  }
}
