
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { filter, Observable, Subscription } from 'rxjs';
import { EChartsService } from '../chart/echarts.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { KeyboardHandlerService } from '../services/keyboard-handler.service';
import { VideoEvents, VideoEventsService } from '../services/video-events.service';
import { ChartLayoutTypes, LeftRightService, ReportLayoutConfig } from './left-right.service';

export interface TileUpdateIdsAndType {
  oldId: string,
  newId: string,
  type?: ChartLayoutTypes,
}

@Component({
  template: '',
  providers: [EChartsService]
})
export class LeftRight implements OnInit, OnDestroy {

  @Input() playhead = false;
  @Input() selectedChartFilter: string;
  @Input() currentTrialName: string = "";
  @Output() updatedLayout: EventEmitter<ReportLayoutConfig> = new EventEmitter<ReportLayoutConfig>();

  @ViewChildren('leftTile')
  leftTile: QueryList<ElementRef>;
  @ViewChildren('rightTile')
  rightTile: QueryList<ElementRef>;

  private subs: Subscription[] = [];
  private navEnd: Observable<NavigationEnd>;
  public playbackControl: PlaybackControlService;
  public showLeftRight = false;
  public canViewLeftRight = true;
  public videosMaxHeight: number;
  protected playbackSpeed: number;
  public isLastTileMultiChart: boolean = false;

  constructor(protected playbackGlobal: GlobalPlaybackControlService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected readonly featureFlags: FeatureFlagsService,
    public leftRightService: LeftRightService,
    protected eChartsService: EChartsService,
    protected readonly videoEvents: VideoEventsService,
    protected keyboardHandlerService: KeyboardHandlerService,
    ) {
    this.navEnd = this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit(): void {
    this.canViewLeftRight = this.featureFlags.get('canViewLeftRight') !== false;
    this.leftRightService.enabled = this.canViewLeftRight;

    this.subs.push(this.playbackGlobal.playbackControl.subscribe(
      (service: PlaybackControlService) => {
        this.playbackControl = service;
      })
    );

    this.subs.push(this.playbackGlobal.playbackSpeed.subscribe((value) => {
      this.playbackSpeed = value;
    }));

    this.subs.push(this.leftRightService.maximizer.subscribe(() => {
      this.checkLastTileMultiChart();
      this.showLeftRightView();
    }));

    this.subs.push(this.navEnd.subscribe(() => {
      this.leftRightService.clear();
    }));

  }

  @HostListener('document:keyup.s', ['$event'])
  onSKeyUpHandler(event: any): void {
    if (this.keyboardHandlerService.isEventFromInput(event)) {
      return;
    }
    this.leftRightService.maximize();
  }

  showLeftRightView(): void {
    this.playbackGlobal.controlsFullscreen.next(true);
    this.leftRightService.markPrimaryVideo();
    this.leftRightService.setInitialTrialNamesForVideoSync();
    this.showLeftRight = true;
    this.leftRightService.isMaximized = true;
    this.videoEvents.emit(VideoEvents.OPEN_SPLIT_VIEW);

  }

  protected saveLayout(layoutName: string): void {
    const savedLayout: ReportLayoutConfig = {
      layoutName: layoutName,
      selectedTrialName: this.currentTrialName,
      leftTileIds: this.leftRightService.leftTileIds,
      rightTileIds: this.leftRightService.rightTileIds
    };
    this.updatedLayout.emit(savedLayout);
  }

  @HostListener('document:keyup.escape', ['$event'])
  hide(): void {
    this.playbackGlobal.controlsFullscreen.next(false);
    this.showLeftRight = false;
    this.leftRightService.isMaximized = false;
    this.videoEvents.emit(VideoEvents.CLOSE_SPLIT_VIEW);
    this.leftRightService.zoomRightChart = false;
    this.leftRightService.xZoomStartStored = -1;
    this.leftRightService.xZoomOffset = -1;
  }

  ngOnDestroy(): void {
    this.hide();
    this.leftRightService.clear();
    this.leftRightService.enabled = false;
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }

  public getLeftTilePercentage(): number {
    return 100 / this.leftRightService.getLeftTiles()?.length || 100;
  }

  public getRightTilePercentage(): number {
    return 100 / this.leftRightService.getRightTiles()?.length || 100;
  }

  public zoomChartOnPlayhead(): boolean {
    if (this.featureFlags.get('enableZoomOnVideoLength') === true) {
      return this.leftRightService.isChartAndVideo();
    }
    return false;
  }

  public maxHeightLeftTiles(): void {
    this.getTileMaxHeight(this.leftTile);
  }

  public maxHeightRightTiles(): void {
    this.getTileMaxHeight(this.rightTile);
  }

  public getTileMaxHeight(tiles: QueryList<ElementRef>): void {
    if (!tiles || !tiles.first) {
      return;
    }

    let maxHeight = 0;
    for (const elem of tiles?.toArray()) {
      const videoElement = elem.nativeElement;
      maxHeight = videoElement.offsetHeight > maxHeight ? videoElement.offsetHeight : maxHeight;
    }
    this.videosMaxHeight = maxHeight;
  }

  handleChangedTile(changedTile: TileUpdateIdsAndType, leftRight: 'left' | 'right'): void {
    this.leftRightService.updateTileId(changedTile, leftRight);
  }

  private checkLastTileMultiChart(): void {
    const rightTiles = this.leftRightService.getCopiedRightTiles();
    if (rightTiles.length > 0) {
      if (rightTiles[rightTiles.length-1]['dataType'] != undefined && (rightTiles[rightTiles.length-1]['dataType'] === 'multi' ||  rightTiles[rightTiles.length-1]['dataType'] === 'multi-report')) {
        this.isLastTileMultiChart =  true;
        return;
      }
    }
    this.isLastTileMultiChart =  false;
  }
}
