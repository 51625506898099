import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AuthService } from "app/core/auth.service";
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import { EnvironmentService } from "../environment.service";
import { OrganizationService } from "../organization/organization.service";
import { AnalyticsConfig, projectsConfig } from './analytics.config';
import { AnalyticEvent } from "./analytics.events";
import { isPlatformServer } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private readonly mixpanelInstance: OverridedMixpanel = mixpanel;
  private config: AnalyticsConfig;
  private warningsDisplayed = false;

  private isIdentified = false;
  private isInitialized: Promise<boolean>;

  public constructor(
    private readonly authService: AuthService,
    private readonly orgService: OrganizationService,
    private environment: EnvironmentService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this
    try {
      this.isInitialized = this.init();
    } catch (serviceInitError) {
      console.error('Error while trying to initialize analytics. The service will be disabled.');
    }
  }

  private async init(): Promise<boolean> {
    if(!isPlatformServer(this.platformId)) {
      const org = await this.orgService.getCurrentOrganization();

      if (org == null) {
        console.debug(`setting current analytics for ${window.location.hostname} domain`);
        const subdomains = window.location.hostname.split('.');
        if (subdomains.includes('us')) {
          this.config = projectsConfig['app_us'][this.environment.getEnvironment().env];
        } else {
          this.config = projectsConfig['app'][this.environment.getEnvironment().env];
        }
      } else {
        console.debug('setting current analytics org as', org);
        try {
          this.config = projectsConfig[org.domain][this.environment.getEnvironment().env];
        } catch (configurationError) {
          console.error('Cannot find analytics configuration for org:', org);
          return;
        }
      }


      if (this.config?.enabled === false || !this.config?.token || !this.config?.options) {
        // Configuration is missing or disabled, disable the service
        console.debug('Analytics disabled.');
        return false;
      }

      // Initialize the service
      console.debug('init analytics service', this.config);
      try {
        this.mixpanelInstance.init(
          this.config.token,
          this.config.options
        );
      } catch (initError) {
        if (initError instanceof TypeError) {
          console.error('Error while trying to read analytics configuration:', initError);
        } else {
          console.error('Cannot initialize analytics service, error:', initError);
        }
        return;
      }

      return true;
    }

    return;
  }

  /**
   * Tracks an analytics event. This also tries to identify the user via auth.service
   * if available.
   * If `requiresIdentification` is left to `true`, the call will await for the authentication service
   * to be able to establish a user identity before proceeding. It is used for events that can't be
   * performed by unidentified users.
   * Note: this method is async but usages can opt to not await it to not disrupt ux in case of
   *   errors
   */
  public async trackEvent(event: AnalyticEvent, requiresIdentification: boolean = true): Promise<void> {
    console.debug(`Sending event ${event}`);
    if (requiresIdentification) {
      await this.authService.identified;
    }
    if (!await this.isInitialized) {
      if (!this.warningsDisplayed) {
        console.warn('Analytics service not initialized, events will not be tracked');
        this.warningsDisplayed = true;
      }
      return;
    }
    if (!this.isIdentified) {
      await this.identify();
    }
    try {
      await this.mixpanelInstance.track(
        event,
        this.config.baseEventPayload,
      );
    } catch (trackError) {
      console.error(`Error while tracking event "${event}".
        Make sure the service is initialized correctly.`);
    }
  }

  /**
   * Attempts to identify the current session through auth.service
   */
  private async identify(): Promise<void> {
    // Handle identity if available
    const identity = this.authService.getAuthenticatedUserId();
    if (!identity) {
      console.debug('User is anonymous, no analytics identification.');
      return;
    }
    // If there is at least one identity available, identify the current session
    console.debug('Identifying user with:', identity);
    try {
      await this.mixpanelInstance.identify(identity);
      await this.mixpanelInstance.people.set('Role', this.authService.getAuthenticatedUserRole());
    } catch (identificationError) {
      console.error(`Error while trying to identify the current session as "${identity}".
        Analytics might continue to work without identification.`);
    }
    this.isIdentified = true;
  }
}
