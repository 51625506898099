<div *ngIf="showLeftRight && canViewLeftRight" class="left-right">
  <button
    *ngIf="!this.clickedConditionSummary && this.canEdit"
    class="btn btn-small btn-primary save-layout-button"
    (click)="openModal(saveLayoutModal, 'dialog-centered')"
    [disabled]="this.savingTrial">Save layout <i
      class="fa fa-save ml-2">
    </i><app-spinner *ngIf="this.savingTrial" [size]="'small'"></app-spinner>
  </button>
  <i class="clickable-success fa fa-compress close-left-right-button" aria-hidden="true" (click)="hide()"></i>
  <div class="d-flex align-items-center h-100" style="padding-bottom: 50px;">
    <div class="h-100" [ngClass]="this.zoomRightChart() ? 'short-tile' : 'tile-column'">
      <ng-container *ngFor="let tile of leftRightService.getCopiedLeftTiles()">
        <div class="h-{{this.getLeftTilePercentage()}}" #leftTile>
          <div *ngIf="this.leftRightService.isTileVideo(tile)" class="d-inline">
            <app-video-tile [tile]="tile"
            [playbackControl]="playbackControl"
            [playbackSpeed]="playbackSpeed"
            [videosMaxHeight]="videosMaxHeight" [currentTrialName]="currentTrialName" [clipList]="clipList"
              (renderedEvent)="maxHeightLeftTiles()" (initialSyncEvent)="this.selectEventForSync($event)"
              (tileChanged)="this.handleChangedTile($event, 'left')"></app-video-tile>
          </div>
          <ng-container *ngIf="this.leftRightService.isTileChart(tile)">
            <app-chart-tile
            [tile]="tile"
            [playhead]="playhead"
            [playbackControl]="playbackControl"
            [selectedChartFilter]="this.selectedChartFilter"
            [zoomChartOnPlayhead]="this.zoomChartOnPlayhead()"
            [chartHeight]="getLeftTilePercentage()"
            (tileChanged)="this.handleChangedTile($event, 'left')"
            ></app-chart-tile>
          </ng-container>
          <div class="clip-loader d-inline" style="height: 300px;" *ngIf="this.leftRightService.isTileClipLoader(tile)">
            <app-splitscreen-clip-loader [noVideo]="false" [clipId]="tile.clipTrcPath" [noCharts]="true" [showFullscreenIcon]="false" [moveFollowCam]="false"
              [autoLoad]="true" class="card-img-top clip-lg"></app-splitscreen-clip-loader>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="h-100" [ngClass]="this.zoomRightChart() ? 'long-tile' : 'tile-column'">
      <i *ngIf="this.canExpandRightChart() && !this.zoomRightChart()" class="clickable-success fa fa-angle-double-left mt-2 ml-2 expand-chart-percentage-button" aria-hidden="true" (click)="toggleExpandRightChart()"></i>
      <i *ngIf="this.canExpandRightChart() && this.zoomRightChart()" class="clickable-success fa fa-angle-double-right mt-2 ml-2 expand-chart-percentage-button" aria-hidden="true" (click)="toggleExpandRightChart()"></i>
      <ng-container *ngFor="let tile of leftRightService.getCopiedRightTiles(), let i = index">
        <div class="h-{{this.getRightTilePercentage()}}" #rightTile>
          <div *ngIf="this.leftRightService.isTileVideo(tile)" class="d-inline">
            <app-video-tile [tile]="tile" [playbackControl]="playbackControl"
            [playbackSpeed]="playbackSpeed"
            [videosMaxHeight]="videosMaxHeight" [currentTrialName]="currentTrialName" [clipList]="clipList"
              (renderedEvent)="maxHeightRightTiles()" (initialSyncEvent)="this.selectEventForSync($event)"
              (tileChanged)="this.handleChangedTile($event, 'right')"></app-video-tile>
          </div>
          <ng-container *ngIf="this.leftRightService.isTileChart(tile)">
            <app-chart-tile
              [tile]="tile"
              [playhead]="playhead"
              [playbackControl]="playbackControl"
              [selectedChartFilter]="this.selectedChartFilter"
              [zoomChartOnPlayhead]="this.zoomChartOnPlayhead()"
              [zoomRightChart]="this.leftRightService.zoomRightChart"
              [lastTile]="i+1 === leftRightService.getCopiedRightTiles().length"
              [chartHeight]="getRightTilePercentage()"
              (handleNewTile)="i+1 === leftRightService.getCopiedRightTiles().length ? this.handleNewTile($event) : null"
              (tileChanged)="this.handleChangedTile($event, 'right')"
            ></app-chart-tile>
          </ng-container>
          <div class="clip-loader d-inline" style="height: 300px;" *ngIf="this.leftRightService.isTileClipLoader(tile)">
            <app-splitscreen-clip-loader [noVideo]="false" [clipId]="tile.clipTrcPath" [noCharts]="true"  [showFullscreenIcon]="false" [moveFollowCam]="false"
              [autoLoad]="true" class="card-img-top clip-lg"></app-splitscreen-clip-loader>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="leftRightService.hasCharts()">
    <app-averages-switch *ngIf="canToggleConditionAverages"
                            [ngClass]="{
                                  'left-right-togglers': this.getRightTilePercentage() === 100 && !isLastTileMultiChart,
                                  'left-right-togglers-4-charts': this.getRightTilePercentage() !== 100 && !isLastTileMultiChart,
                                  'left-right-multichart-togglers': isLastTileMultiChart
                                }"></app-averages-switch>
    <app-left-right-switch  [ngClass]="{
                                  'left-right-togglers': this.getRightTilePercentage() === 100 && !isLastTileMultiChart,
                                  'left-right-togglers-4-charts': this.getRightTilePercentage() !== 100 && !isLastTileMultiChart,
                                  'left-right-multichart-togglers': isLastTileMultiChart
                                }"></app-left-right-switch>
  </ng-container>
  <app-sync-dropdown *ngIf="this.leftRightService.cyclesForSync.length > 0" class="sync-dropdown"
    [syncList]="this.leftRightService.cyclesForSync" [currentSync]="this.leftRightService.cyclesForSync[0]"
    (selectedSyncDropdownElement)="this.selectEventForSync($event)" (keyPress)="this.keyPressing($event)">
  </app-sync-dropdown>
</div>

<ng-template #saveLayoutModal>
  <app-save-layout-modal
    *ngIf="!this.clickedConditionSummary"
    class="modal-component"
    (closeModalEvent)="closeModal()"
    (saveLayoutEvent)="saveLayoutEvent($event)"
  ></app-save-layout-modal>
</ng-template>
