import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Directive, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ProjectMenuComponent } from 'app/projects/project-menu.component';
import { SubjectContextService } from 'app/projects/subject-context/subject-context.service';
import gql from 'graphql-tag';
import { Subscription } from 'rxjs';
import { AuthService, AuthState } from '../core/auth.service';
import { UiControlService } from '../core/ui-control.service';
import { SidebarService } from './sidebar.service';


@Directive()
export abstract class RootComponent implements OnInit, OnDestroy {
  public showHeader = true;
  public authenticatedUser: string;
  public navbarBtn: string = "btn-primary";
  public navbarBg: string = 'bg-primary';
  public avatarUrl: string;
  private subs: Subscription[] = [];
  public collapse: boolean = true;
  public collapseLeftSidebar: boolean = false;
  public isProjectArea: boolean = false;

  public showNotifications: boolean = true;

  constructor(
    public authService: AuthService,
    protected apollo: Apollo,
    protected router: Router,
    public subjectContext: SubjectContextService,
    public sidebarService: SidebarService,
    ) { }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
  ngOnInit() {
    this.subs.push(this.authService.state.subscribe(state => {
      this.authenticatedUser = this.authService.getAuthenticatedUsername();

      if (state === AuthState.LoggedIn) {
        if (this.router.url === '/home' || this.router.url === '')
          this.router.navigate(['dashboard']);
        }
    }));
    this.subs.push(this.apollo.watchQuery<any>({
      query: gql`
        query {
          viewer {
            id
            avatar
            username
          }
        }
      `,
      fetchPolicy: 'cache-only'
    }).valueChanges.subscribe(({ data }) => {
      this.avatarUrl = data && data.viewer ? data.viewer.avatar : null;
    }));
  }

  public toggleCollapse() {
    this.collapse = !this.collapse;
    this.showNotifications = !this.collapse;
  }

  public isTesterAuthenticathed() {
    return this.authService.isTesterAuthenticated();
  }

  public logIn() {
    this.authService.stableState.then( (state) => {
      const name = this.authService.getAuthenticatedUsername();
      if (state == AuthState.LoggedIn)
        this.router.navigate( ['dashboard'] );
      else
        this.authService.login();
    });
  }

  public logOut() {
    this.authService.logOut();
    /*this.authService.logOut().then((value) => {
      this.router.navigate(['home']);
    }).catch(() => {
      this.router.navigate(['home']);
    });*/
  }
}

@Component({
  selector: 'app-web-app-root',
  templateUrl: './web-app-root.component.html',
  styleUrls: ['./web-app-root.component.scss']
})
export class WebAppRootComponent extends RootComponent {
  headerSub: Subscription;
  constructor(@Inject(AuthService) authService: AuthService, @Inject(Apollo) apollo: Apollo, @Inject(Router) router: Router,
  @Inject(PLATFORM_ID) private platformId, @Inject(DOCUMENT) private document: Document, private uiService: UiControlService, @Inject(SubjectContextService) subjectContextService: SubjectContextService, @Inject(SidebarService) sidebarService) {
    super(authService, apollo, router, subjectContextService, sidebarService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (isPlatformBrowser(this.platformId) && this.document) {
      const pipedriveBot = this.document.getElementById("pipedrive-chat-holder");
      if (pipedriveBot) {
        pipedriveBot.style.display = 'none';
        console.log('pipedrive removed');
      }
    }

    this.headerSub = this.uiService.monitorHeaderHide().subscribe((value: boolean) => {
      this.showHeader = !value;
    });
  }

  onActivate(component): void {
    if (component instanceof ProjectMenuComponent) {
      this.isProjectArea = true;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.headerSub)
      this.headerSub.unsubscribe();
  }

  public isSubjectLock(): boolean {
    return this.subjectContext.getSubjectLock() !== null;
  }
}
