import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BackendService } from "app/shared/services/backend/backend.service";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

interface HomeMonitoringResponse {
  status: string,
  data?: Record<string,string>,
  error?: string,
}

@Component({
  selector: 'app-homemonitoring',
  templateUrl: './homemonitoring.component.html',
})
export class HomeMonitoringComponent implements OnInit {
  private baseUrl = 'http://homemonitoring:5002';
  private localUrl = 'http://127.0.0.1:5002';
  private localStoragePath = 'C:\\temp\\homemonitoring';
  private apiUrl = 'https://api.staging.moveshelf.com/graphql'; // 'https://api.moveshelf.com/graphql'
  private conditionName = 'homemonitoring';

  public serverStatus = 'unknown';
  sensorsList: any[];
  public backendProcessing = false;
  public backendAvailable = false;
  public detectedSensorsOk = false;
  showSettings = true;
  isEditingConfigData = false;
  isSubmitted = false;
  configOk = false;
  public debugMsg = '';
  public progressMsg = '';
  public lastUpload = {mySession: '', myCondition: '', myTrial: ''};
  form: UntypedFormGroup;
  formPasscode: UntypedFormGroup;
  private modal: BsModalRef;

  nSensors = 3;
  selectedSensor = '';
  lfEnabled =  true; // this should always be true!
  rfEnabled = true;
  lumbarEnabled = true;

  patientNameDisplay: string;
  sensorTypes = ['APDM','GaitUp'];

  formSpec = {
    patientName: ['', Validators.compose([
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9_-]*$/),
      Validators.maxLength(32)
    ])],
    projectName: ['', Validators.compose([
      Validators.required,
      Validators.maxLength(64)
    ])],
    sensorType: ['', Validators.required],
    rfId: ['', Validators.compose([
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9_-]*$/),
      Validators.maxLength(32)
    ])],
    lfId: ['', Validators.compose([
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9_-]*$/),
      Validators.maxLength(32)
    ])],
    lumbarId: ['', Validators.compose([
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9_-]*$/),
      Validators.maxLength(32)
    ])],
    apiKey: ['', Validators.compose([
      Validators.required,
      Validators.maxLength(64)
    ])],
    storageLocation: ['', Validators.required],
    apiUrl: ['', Validators.required],
    conditionName: ['', Validators.required]

  };

  constructor(
    private readonly backendService: BackendService,
    private formBuilder: UntypedFormBuilder,
    @Inject(PLATFORM_ID) private platformId: string,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {

    this.sensorsList = [];
    this.isSubmitted = false;

    const refreshInterval = 1; // s
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.checkUsbConnection();
      }, refreshInterval * 1000); // ms
    }

    this.form = this.formBuilder.group(this.formSpec);
    this.form.patchValue({'storageLocation': this.localStoragePath});
    this.form.patchValue({'apiUrl': this.apiUrl});
    this.form.patchValue({'conditionName': this.conditionName});
    this.form.patchValue({'sensorType': ''});

    this.getConfig();

    this.formPasscode = this.formBuilder.group({
      passCode: ['', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ])],
    });

  }

  public async pingLocalHomeMonitoringServer(): Promise<boolean> {
    const serverHealthStatus = await this.getResponse(this.baseUrl);
    this.serverStatus = serverHealthStatus.status;

    return serverHealthStatus.status !== 'KO';
  }

  public async pingLocalHomeMonitoringServerLocal(): Promise<boolean> {
    this.clearDebugMsg();
    const serverHealthStatus = await this.getResponse(this.localUrl);
    this.serverStatus = serverHealthStatus.status;
    return serverHealthStatus.status !== 'KO';
  }

  private async getResponse(url: string): Promise<HomeMonitoringResponse> {
    this.clearDebugMsg();
    try {
      return await this.backendService.externalGet<HomeMonitoringResponse>(url);
    } catch (callException) {
      this.debugMsg = 'Local server cannnot be contacted: ' + callException.message;
      return { status: 'KO', data: {}, error: callException.message };
    }
  }

  private async postRequest(url: string, body: Record<string, unknown>): Promise<HomeMonitoringResponse> {
    this.clearDebugMsg();
    try {
      return await this.backendService.externalPost<HomeMonitoringResponse>(url, body);
    } catch (callException) {
      this.debugMsg = 'Error while applying post request: ' + callException.message;
    }
  }

  public async handleSensorData(): Promise<void> {
    this.backendProcessing = true;

    this.progressMsg = 'Data import: step 1/4: Gathering data';
    const response1 = await this.postRequest(this.localUrl + '/get_files_to_copy', {storageLocation: this.localStoragePath, apiUrl: this.apiUrl, conditionName: this.conditionName});
    if (response1 && response1.status === 'OK') {
      if (Number(response1.data.nFiles) > 0) {
        this.progressMsg = 'Data import: step 2/4: Store data locally';
        const myData = response1.data;
        myData['deleteFilesAfterCopy'] = 'false';
        const response2 = await this.postRequest(this.localUrl + '/store_trials_to_folder', {storageLocation: this.localStoragePath, apiUrl: this.apiUrl, conditionName: this.conditionName, data: myData});
        if (response2 && response2.status === 'OK') {
          this.progressMsg = 'Data import: step 3/4: Convert raw data';
          const response3 = await this.postRequest(this.localUrl + '/convert_raw_data', {storageLocation: this.localStoragePath, apiUrl: this.apiUrl, conditionName: this.conditionName, data: response2.data});
          if (response3 && response3.status === 'OK') {
            this.progressMsg = 'Data import: step 4/4: Upload to Moveshelf';
            const response4 = await this.postRequest(this.localUrl + '/upload_trials_to_moveshelf', {storageLocation: this.localStoragePath, apiUrl: this.apiUrl, conditionName: this.conditionName, data: response3.data});
            if (response4 && response4.status === 'OK') {
              this.lastUpload.mySession = response4.data['mySession'];
              this.lastUpload.myCondition = response4.data['myCondition'];
              this.lastUpload.myTrial = response4.data['myTrial'];

              this.progressMsg = 'Data import: Done';
            } else {
              this.progressMsg = 'Data import - error: Upload failed';
              this.lastUpload.mySession = '';
              this.lastUpload.myCondition = '';
              this.lastUpload.myTrial = '';
            }
          } else {
            this.progressMsg = 'Data import - error: Conversion failed';
          }
        } else {
          this.progressMsg = 'Data import - error: File local storage failed';
        }
      } else {
        this.progressMsg = 'Data import - error: No files to convert';
      }
    } else {
      this.progressMsg = 'Data import - error: Failed to get files to copy.';
    }

    setTimeout( ()=> {
      this.progressMsg = '';
    }, 5000);

    this.backendProcessing = false;
  }

  public async startBackend(): Promise<void> {
    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = 'start_homemonitoring_server.bat'; // the file name
    dlink.href = 'https://github.com/moveshelf/hm_exe_download/releases/download/hm_exe_release/start_homemonitoring_server.bat';
    dlink.click(); // this will trigger the dialog window
    dlink.remove();
    this.debugMsg = 'Please execute (double-click): ' + dlink.download + ' and refresh page.';
  }

  public async downloadBackend(): Promise<void> {
    const dlink: HTMLAnchorElement = document.createElement('a');
    dlink.download = 'install_homemonitoring_server.bat'; // the file name
    dlink.href = 'https://github.com/moveshelf/hm_exe_download/releases/download/hm_exe_release/install_homemonitoring_server.bat';
    dlink.click(); // this will trigger the dialog window
    dlink.remove();
    this.debugMsg = 'Please execute (double-click): ' + dlink.download + ' and refresh page.';
  }

  private clearDebugMsg(): void {
    this.debugMsg = '';
   }
  private async getConfig(): Promise<void> {
    this.clearDebugMsg();
    const serverOk = await this.pingLocalHomeMonitoringServerLocal();
    this.backendAvailable = serverOk;
    if (serverOk) {
      const response = await this.postRequest(this.localUrl + '/get_config', {storageLocation: this.localStoragePath, apiUrl: this.apiUrl});
      if (response && response.status === 'OK' &&  response['data']) {
        this.configOk = true;
        this.debugMsg = 'Backend found, configuration OK.';

        this.form.patchValue({'patientName': response.data['patientName']});
        this.form.patchValue({'projectName': response.data['projectName']});
        this.form.patchValue({'sensorType': response.data['sensorType']});
        const mySensors = response.data['sensors'];
        let rf = '';

        let lf = '';
        let lumbar = '';
        let nSensors = 0;
        if (mySensors['rightfoot']) {
          rf = mySensors['rightfoot'];
          nSensors++;
        }
        if (mySensors['leftfoot']) {
          lf = mySensors['leftfoot'];
          nSensors++;
        }
        if (mySensors['lumbar']) {
          lumbar = mySensors['lumbar'];
          nSensors++;
        }
        this.nSensors = nSensors;

        this.form.patchValue({'rfId': rf});
        this.form.patchValue({'lfId': lf});
        this.form.patchValue({'lumbarId': lumbar});
        this.lfEnabled = lf.length > 0;
        this.rfEnabled = rf.length > 0;
        this.lumbarEnabled = lumbar.length > 0;
        this.form.patchValue({'apiKey': response.data['apiKey']});

        this.selectedSensor = response.data['sensorType'];

        this.setPatientNameDisplayFromMetadata(response.data);

      } else {
        this.debugMsg = 'Configuration error, status: ' + response.status;
        this.configOk = false;
      }
    } else {
      this.debugMsg = 'Backend not available, did you download the executable (in settings)?';
    }

   }

  async updateSettings(): Promise<void> {
    this.isSubmitted = true;
    const mySensors = {};
    let nSensors = 0;
    if (this.selectedSensor === 'APDM') {
      this.rfEnabled = true;
      this.lfEnabled = true;
      this.lumbarEnabled = true;
    }

    if (this.rfEnabled === true) {
      mySensors['rightfoot'] = this.form.get('rfId').value;
      nSensors++;
    }
    if (this.lfEnabled === true) {
      mySensors['leftfoot'] = this.form.get('lfId').value;
      nSensors++;
    }
    if (this.lumbarEnabled === true) {
      mySensors['lumbar'] = this.form.get('lumbarId').value;
      nSensors++;
    }
    this.nSensors = nSensors;
    const body = {
      storageLocation: this.localStoragePath,
      apiUrl: this.apiUrl,
      conditionName: this.conditionName,
      patientName: this.form.get('patientName').value,
      projectName: this.form.get('projectName').value,
      sensorType: this.form.get('sensorType').value,
      apiKey: this.form.get('apiKey').value,
      sensors: mySensors
    };

    const response = await this.postRequest(this.localUrl + '/store_config', body);
    this.setPatientNameDisplayFromMetadata(response['data']);
    if (response['status'] === 'OK') {
      this.configOk = true;
      this.debugMsg = 'Configuration successfully stored.';
    } else {
      this.debugMsg = 'Unable to store configuration in backend, be sure to have all settings prepared and have the subject available on Moveshelf';
      this.configOk = false;
    }

    this.form.markAsPristine();
    this.isEditingConfigData = false;
    this.isSubmitted = false;
  }

  async checkUsbConnection(): Promise<void> {
    const sensorsList = [];
    if (this.configOk) {
      const response = await this.backendService.externalGet<HomeMonitoringResponse>(this.localUrl + '/get_connected_sensors');
      if (response['sensors'] && response['sensors']['name'] &&  response['sensors']['name'].length > 0 ) {
        for (let iSensor = 0; iSensor < response['sensors']['name'].length; iSensor++) {
          const curSensorName = response['sensors']['name'][iSensor];
          if (curSensorName === this.form.get('rfId').value || curSensorName === this.form.get('lfId').value || curSensorName === this.form.get('lumbarId').value ) {
            sensorsList.push({name: curSensorName, idMatching: true, enabled: true});
          } else {
            sensorsList.push({name: curSensorName, idMatching: false, enabled: true});
          }
        }
        this.clearDebugMsg();
      } else {
        this.debugMsg = 'Scanning USB failed, did you connect the sensors?';
      }
    }
    this.sensorsList = sensorsList;
    const filteredList = this.sensorsList.filter(o => o.idMatching === true);

    this.detectedSensorsOk = filteredList.length === this.nSensors;
  }

  setPatientNameDisplayFromMetadata(data: Record<string, string>): void {
    if (data['patientMetadata'] && data['patientMetadata']['name']) {
      this.patientNameDisplay = data['patientMetadata']['name'];
    } else {
      this.patientNameDisplay = 'Subject metadata (name) not filled yet';
    }
  }

  changeSensorType(e: any): void {
    const sensorType = e.target.value.substring(e.target.value.indexOf(': ') + 2);
    this.form.patchValue({'sensorType': sensorType});
    this.selectedSensor = sensorType;
  }

  changeRfEnabled(e: any): void {
    this.rfEnabled = e.target.checked;
  }

  changeLumbarEnabled(e: any): void {
    this.lumbarEnabled = e.target.checked;
  }
  checkPasscode(): void {
    const passCode = this.formPasscode.get('passCode').value;
    if (passCode === '5555') {
      this.isEditingConfigData = true;
      this.formPasscode.patchValue({'passCode': ''});
    }
    this.hideModal();
  }

  showModal(template: TemplateRef<any>): void {
    this.modal = this.modalService.show(template, {
      ignoreBackdropClick: true,
      keyboard: false});
  }

  hideModal(): void {
    this.modal.hide();
  }

  closeSettings(): void {
    this.isEditingConfigData = false;
  }
}
