import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sample } from 'app/shared/chart/chart.types';
import { BehaviorSubject, Observable } from 'rxjs';


export interface SubjectInfo {
  ehrId: string,
  code: string,
  name: string,
  age: number,
  gender: 'Male' | 'Female'
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

@Injectable({
  providedIn: 'root'
})
export class LongitudinalChartService {

  peerGroupCurveByPoint = [
    {
      name: "OA_default",
      values: [
        {
          measure: 0.988,
          days: -1,
        },
        {
          measure: 0.958,
          days: 60,
        },
        {
          measure: 1.188,
          days: 450,
        },
      ],
    },
    {
      name: "OA_movita",
      values: [
        {
          measure: 0.988,
          days: -7,
        },
        {
          measure: 0.858,
          days: 30,
        },
        {
          measure: 1.388,
          days: 200,
        },
      ],
    },
  ];


  subjectColor = '#00adf9';
  peerColor = '#00cdc9';
  scatterColor = '#00cdc9';
  referenceColors = ['#ffffff', '#fca103', '#0331fc' ];           //ref mid/low/high
  defaultColors = [this.referenceColors[0], this.peerColor, this.subjectColor];

  height = 50;
  width = 800;
  outOfRect = this.height * 0.1;
  offY = 70;
  offX = 20;
  canvasHeight = 630;
  canvasWidth = 1200;
  nSpeedsMax = 6;               // max number of bars
  maxSpeed = 6;                 // max speed on x axis
  spacingBetweenBoxes = 25;
  labelSpaceX = 180; // space for the horizontal labels on the Y axis
  labelSpaceY = 35;   // space for numbers and ticks under the X axis
  labelSpaceY_top = 35; // space for ref labels at the top
  offsetY = 20; // space offset under the scale spacing
  offsetX = 50; // space offset after arrow of X axis

  base64ImageDict = {};

  public interventionDate: Date;
  private imageUrl = '/assets/speedmeasure.svg';
  private imageSubject = new BehaviorSubject<HTMLImageElement | null>(null);

  constructor(
    private http: HttpClient
  ) {
    this.loadImage();
  }

  private loadImage() {
    this.http.get(this.imageUrl, { responseType: 'blob' }).subscribe((response) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => this.imageSubject.next(img);
      };
      reader.readAsDataURL(response);
    });
  }

  public getImage(): Observable<HTMLImageElement>  {
    return this.imageSubject.asObservable();
  }

  getHcValue(): number {
    return 1.238; //m/s, healthyAverage
  }

  public fillProgressionRefValues(chartValue: Sample, yLabel: string, age: number, unitConversion: number): void {
    const refValueRecommended = this.getProgressionRefValue(yLabel, '', age) * unitConversion;
    const refValueLow = this.getProgressionRefValue(yLabel, 'low', age) * unitConversion;
    const refValueHigh = this.getProgressionRefValue(yLabel, 'high', age) * unitConversion;
    if (refValueRecommended !== undefined && !isNaN(refValueRecommended)) {
      chartValue["ref-recommended"] = refValueRecommended;
    }
    if (refValueLow !== undefined && !isNaN(refValueLow)) {
      chartValue["ref-low"] = refValueLow;
    }
    if (refValueHigh !== undefined && !isNaN(refValueHigh)) {
      chartValue["ref-high"] = refValueHigh;
    }
  }
  
  getGaitSpeedRefValues(age: number, gender: 'Male' | 'Female'): {mean: number, sd: number} {
    if (age === undefined) {
      age = 0;
    }

    let mean = 0;
    let sd = 0;
    if (age >= 70) {
      // male: 3,132 (0,900), female: 3,06 (0,792)
      if (gender === 'Female') {
        mean = 3.06;
        sd = 0.792;
      } else if (gender === 'Male') {
        mean = 3.132;
        sd = 0.9;
      } else {
        mean = (3.06 + 3.132)/2;
        sd = (0.792 + 0.9)/2;
      }
    } else if (age >= 60) {
      // male: 4,032 (0,468), female: 4,104 (0,432)
      if (gender === 'Female') {
        mean = 4.104;
        sd = 0.432;
      } else if (gender === 'Male') {
        mean = 4.032;
        sd = 0.468;
      } else {
        mean = (4.104 + 4.032)/2;
        sd = (0.432 + 0.468)/2;
      }
    } else if (age >= 50) {
      // male: 4,500 (0,648), female: 4,284 (0,324)
      if (gender === 'Female') {
        mean = 4.284;
        sd = 0.324;
      } else if (gender === 'Male') {
        mean = 4.5;
        sd = 0.648;
      } else {
        mean = (4.284 + 4.5)/2;
        sd = (0.324 + 0.648)/2;
      }
    } else {
      // 40 - 49, infact all below 50
      // male: 4,428 (0,576), female 3,816 (0,396)
      if (gender === 'Female') {
        mean = 3.816;
        sd = 0.396;
      } else if (gender === 'Male') {
        mean = 4.428;
        sd = 0.576;
      } else {
        mean = (3.816 + 4.428)/2;
        sd = (0.396 + 0.576)/2;
      }
    }
    return {mean, sd};
  }  

  getProgressionRefValue(yLabel: string, refStr: string, age: number): number {
    let subjectAge: number = 40;    // set default to 40 years
    if (age !== undefined && age > 0) {
      subjectAge = age;
    }

    if (yLabel.toLowerCase().includes('steps')) {
      if (refStr === 'low') {
        if (age >= 60) {
          return 3000;
        } else {
          return 5000;
        }
      } else if (refStr === 'high') {
        return NaN;
      } else {
        if (age >= 60) {
          return 7000;
        } else {
          return 9000;
        }
      }
    } else if (yLabel.toLowerCase().includes('movement intensity')) {
      if (refStr === 'low' || refStr === 'high') {
        return NaN;
      } else {
        if (age >= 81) {
          return 0.023*1000;
        } else if (age >= 71) {
          return 0.0308*1000;
        } else if (age >= 61) {
          return 0.0378*1000;
        } else if (age >= 51) {
          return 0.041*1000;
        } else {
          return 0.0448*1000;
        }
      }
    } else if (yLabel.toLowerCase().includes('walking duration')) {
      if (refStr === 'low' || refStr === 'high') {
        return NaN;
      } else {
        if (age >= 81) {
          return 156;
        } else if (age >= 71) {
          return 269;
        } else if (age >= 61) {
          return 354;
        } else if (age >= 51) {
          return 365;
        } else {
          return 371;
        }
      }
    } else {
      // use speed ref as default
      if (refStr === 'low') {
        return 0.62;  // low ref
      } else {
        return NaN;
      }
    }
  }

  setInterventionDate(date: Date): void {
    this.interventionDate = date;
  }

  getPeerGroupValue(days: number, peerGroupStr?: string): number {
    let peerGroupCurveByPoint = this.peerGroupCurveByPoint[0].values;
    if (peerGroupStr && this.peerGroupCurveByPoint.some(i => i.name === peerGroupStr)) {
      const tmp = this.peerGroupCurveByPoint.find( i => i.name === peerGroupStr);
      if (tmp) {
        peerGroupCurveByPoint = tmp.values;
      }
    }

    for (let i=0; i<peerGroupCurveByPoint.length; i++) {
      if (peerGroupCurveByPoint[i].days > days) {
        const nextPoint = peerGroupCurveByPoint[i];
        if (i==0)
          return nextPoint.measure; //return first if earlier than first
        else {
          const previousPoint = peerGroupCurveByPoint[i-1];
          const daysGap = nextPoint.days - previousPoint.days;
          return (nextPoint.measure * (days - previousPoint.days)/daysGap + (previousPoint.measure * (nextPoint.days - days)/daysGap));
        }
      }
    }
    return peerGroupCurveByPoint[(peerGroupCurveByPoint.length - 1)].measure; //return last if later than last
  }

  getChartExplanationText(title: string): string {
    // get in configuration?
    if (title.toLowerCase().includes('speed')) {
      return '\nDe loopsnelheid geeft aan hoe snel u gemiddeld buiten loopt. Uw loopsnelheid geeft een indicatie van uw fysieke functioneren. Lopen vereist een mate van belastbaarheid van de knie/heup, zoals een goede spierkracht, coördinatie, en mobiliteit. Veranderingen hierin kunnen van invloed zijn op de loopsnelheid. \n\nDe MoveMonitor registreert uw loopsnelheid wanneer u minimaal 50 keer heeft gelopen op een dag met een minimale loopduur van 8 seconden. Dit wordt gedaan om de nauwkeurigheid van de meting te verhogen.\n\n';
    } else if (title.toLowerCase().includes('steps')) {
      return '\nStappen per dag is het aantal stappen wat u per dag heeft genomen.  Het aantal stappen per dag wordt vaak gebruikt om inzicht te krijgen in uw algemene fysieke activiteit. Lopen vereist een mate van kracht, coördinatie en uithoudingsvermogen. Veranderingen hierin kunnen van invloed zijn op het aantal stappen per dag. \n\nDe MoveMonitor registreert het aantal stappen bij elke loopactiviteit. Het gemiddelde aantal stappen per dag wordt hier weergegeven.\n\n';
    } else if (title.toLowerCase().includes('walking duration')) {
      return '\nDe langste loopepisode is de langste onafgebroken loopduur die u gemiddeld per dag heeft gelopen.  Deze langste loopepisode geeft een indicatie van uw actieradius; de afstand die u kunt afleggen zonder pauze te hoeven nemen. \n\nDe MoveMonitor registreert elke loopepisode en neemt per dag de langste loopepisode. Vervolgens wordt het gemiddelde over de gemeten dagen weergegeven als de langste loopepisode.\n\n';
    } else if (title.toLowerCase().includes('movement intensity')) {
      return '\nDe beweegintensiteit wordt afgeleid van het versnellingssignaal en geeft een indicatie van de kracht van de bewegingen gedurende de dag. De beweegintensiteit kan gezien worden als een maat van fysieke inspanning die u levert gedurende de dag. \n\nDe MoveMonitor registreert de beweegintensiteit gedurende de hele dag wanneer deze gedragen wordt. De gemiddelde bewegingsintensiteit per gemeten dag wordt hier weergegeven.\n\n';
    } else {
      return '';
    }
  }

  getSubjectName(subjectInfo: SubjectInfo): string {
    let name = subjectInfo.code; //always defined

    if (subjectInfo.ehrId) //takes priority if defined
      name = subjectInfo.ehrId;

    if (subjectInfo.name) //takes priority if defined
      name = subjectInfo.name;

    return name;
  }

  // a and b are javascript Date objects
  dateDiffInDays(a, b): number {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  showCanvasRuler(ctx: any, canvas: any): void {
    // BEGIN debug rulers
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'white';
    // Draw X-axis ruler
    ctx.font = "8px Arial";
    for (let x = 0; x <= canvas.width; x += 20) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, 10);
      ctx.fillText(x, x - 5, 20);
    }
    // Draw Y-axis ruler
    for (let y = 0; y <= canvas.height; y += 20) {
      ctx.moveTo(0, y);
      ctx.lineTo(10, y);
      ctx.fillText(y, 12, y + 5);
    }
    ctx.stroke();
    // END
  }

  drawTextAbs(text: string, xPos: number, yPos: number, fontSize = 30, color = "white", textAlign = "start", ctx: any): void {
    ctx.font = fontSize + "px Arial";
    ctx.fillStyle = color;
    ctx.textAlign = textAlign;
    const textWidth = ctx.measureText(text).width;

    if (textAlign == "end") {
      ctx.clearRect(xPos - textWidth,  yPos - fontSize, textWidth, fontSize);
      ctx.fillText(text, xPos, yPos);
    } else {
      ctx.clearRect(xPos - textWidth/2,  yPos - fontSize, textWidth, fontSize);
      ctx.fillText(text, xPos - textWidth/2, yPos);
    }
  }

  drawText(xCoord, text, fontSize = 30, isBottom = true, offset=0, color = "white", ctx: any): void {


    const maxValue = 6;
    if (xCoord > maxValue)
      xCoord = maxValue;

    const isCentered = true;

    let xPosition = xCoord * this.width/maxValue + this.offX;

    if (isCentered)
      xPosition = xPosition - Math.floor(fontSize/3.5*text.length);


    let yPosition = this.offY;
    const maxFontSize = 30;

    if (isBottom)
      yPosition = yPosition + this.height + this.outOfRect*2 + maxFontSize + offset;
    else
      yPosition = yPosition - Math.floor(maxFontSize/2) + offset;


    ctx.font = fontSize + "px Arial";
    ctx.fillStyle = color;

    const width = ctx.measureText(text).width;
    ctx.clearRect(xPosition, yPosition - fontSize , width, fontSize*1.3);

    ctx.fillText(text, xPosition, yPosition);
  }

  drawXarrowHead(xPos: number, yPos: number, lineWidth = 2, color = "white", ctx: any): void {
     ctx.lineWidth = lineWidth;
     ctx.fillStyle = color;
     ctx.beginPath();
     ctx.moveTo(xPos - 20, yPos -5);
     ctx.lineTo(xPos, yPos);
     ctx.lineTo(xPos - 20, yPos + 5);
     ctx.fill();
  }

  drawHorLineAbs(xPos: number, yPos: number, lineLength: number, lineWidth = 2, color = "white", pattern = [], ctx: any): void {
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.setLineDash(pattern);

    ctx.beginPath();
    ctx.moveTo(xPos, yPos);
    ctx.lineTo(xPos + lineLength, yPos);
    ctx.stroke();
  }

  drawHorLine(yCoord, lineWidth = 2, color = "white", pattern = [], extraLength = 0, ctx: any): void {
    const linePosition = yCoord + this.offY;

    // check if we want to add offy here. Decide how to build te figure en then build based on number of speeds. OK speed is one of the entries...

    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(linePosition, this.offY - extraLength);
    ctx.moveTo(this.offX - extraLength, linePosition);

    ctx.strokeStyle = color;
    ctx.setLineDash(pattern);
    ctx.lineTo(this.width +20 + this.offX + extraLength, linePosition);
    ctx.stroke();
  }

  getXcoordFromSpeed(speed: number): number {
    return speed*this.width/this.maxSpeed + this.labelSpaceX + this.offX;
  }

  drawVertLineAbs(xCoord, yCoord, dy, lineWidth = 2, color = "white", pattern = [], ctx: any ): void {
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(xCoord, yCoord);
    ctx.lineTo(xCoord, yCoord + dy);
    ctx.strokeStyle = color;
    ctx.setLineDash(pattern);
    ctx.stroke();
  }

  drawVertLine(xCoord, lineWidth = 2, color = "white", pattern = [], extraLength = 0, ctx: any) {

    const maxValue = 6;
    if (xCoord > maxValue)
      xCoord = maxValue;


    const linePosition = xCoord * this.width/maxValue + this.offX;

    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(linePosition, this.offY - extraLength);
    ctx.strokeStyle = color;
    ctx.setLineDash(pattern);
    ctx.lineTo(linePosition, this.height + this.outOfRect *2 + this.offY + extraLength);
    ctx.stroke();
  }

  drawVertReference(speed: number, label: string, yPosRef: number, dyRef: number, refColor: string, ctx: any, fontSize: number, labelOffset: number, isSecondReference: boolean = false): void {
    this.drawVertLineAbs(this.getXcoordFromSpeed(speed), yPosRef, dyRef, 1, refColor, [2, 2], ctx);
    const xCoordLabel = this.getXcoordFromSpeed(speed) + labelOffset;
    // this.drawTextAbs(speed.toFixed(2), this.getXcoordFromSpeed(speed), yPosTextRef, fontSize, refColor, "start", ctx);
    if (!isSecondReference) {
      this.drawTextAbs(label, xCoordLabel , fontSize + this.labelSpaceY_top, fontSize, refColor, "start", ctx);
    } else {
      this.drawTextAbs(label, xCoordLabel , fontSize, fontSize, refColor, "start", ctx);
    }
  }
}
