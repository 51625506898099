import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, IterableDiffer, IterableDiffers, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ColorService } from 'app/shared/services/color-service/color-service.service';
import { KeyboardHandlerService } from 'app/shared/services/keyboard-handler.service';
import fscreen from 'fscreen';
import { Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
// import './three-global';
import 'three';
// import { OrbitControls } from 'three-orbitcontrols-ts';
import { MediaData } from 'app/shared/multi-media-player/media-player/media-player.types';
import Stats from 'three/examples/js/libs/stats.min';
import "../../js/Reflector";
import "../../js/Refractor";
import "../../js/Water2";
import { ClipOptionsService } from '../core/clip-options.service';
import { GlobalPlaybackControlService } from '../core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from '../core/playback-controls/playback-control.service';
import { PlaybackMode } from '../core/playback-mode.enum';
import { PoseComparisonService } from '../core/pose-comparison.service';
import { ThreeJsRendererService } from '../core/three-js-renderer.service';
import { ThreeJsScene } from '../core/three-js-scene';
import { TimeSelection } from '../core/time-selection';
import { TimecodeService } from '../core/timecode.service';
import { WebvrService } from '../core/webvr.service';
import { WindowService } from '../core/window.service';
import './BVHLoader';
import { ClipLoaderService } from './clip-loader/clip-loader.service';
import { AidDataType, ClipPlayerInput, ClipPlayerOptions, CoordinatesType, MaterialModifier } from './clip-player';
import './DRACOLoader';
import './FBXAnimationLoader';
import './FBXLoader';
import { ForcePlateRenderer } from './force-plate-renderer';
import './GLTFLoader';
import { MocapAction } from './mocap-action';
import './OrbitControls';
import { PosenetSkeletonHelper } from './posenet-skeleton-helper';
import './TGALoader';
import * as THREE from './three-global';
import './TRCLoader';
import { VrController } from './vr-controller';
import './zlib-global';
import './zlib.min';
import { VideoDataForOverlay } from 'app/shared/multi-chart/trial-charts.service';

const THREE = require('three');

const TOUCH_DELAY = 100;
declare const require: any;

enum CameraTypeEnum {
  CameraTypePerspective = 0,
  CameraTypeOrthoRight = 1,
  CameraTypeOrthoForward = 2,
  CameraTypeOrthoLeft = 3,
  CameraTypeOrthoBackward = 4,
  CameraTypeOrthoTop = 5,
  CameraTypeOrthoBottom = 1,
}

const cameraFov = 55;
const cameraInit = {
  origin: { x: 3, y: 1.7, z: 3 },
  target: { x: 0, y: 0.8, z: 0 }
};

@Component({
  standalone: true,
  template: '',
})
export abstract class AMocapClipComponent implements OnInit, OnDestroy, ThreeJsScene {

  _animationClips: ClipPlayerInput[] = [];
  @Input() set animationClips(data: ClipPlayerInput[]) {
    this._animationClips = data;
    /*if(this.inited)
    {
      //should make sure are not double loaded
      for(let i=0; i<data.length; i++) {
        let animationClip = data[i];
        this.createNewAction(animationClip);
      }
    }*/
  }

  @Input() autoPlay: boolean = false;
  @Input() clipId: string = undefined;
  @Input() includeEditLink = false;
  @Input() includeShareButton = false;
  @Input() allowSelectionEditing = false;
  @Input() hideControls: boolean = false;
  @Input() noBack: boolean = false;
  @Input() noCharts: boolean = false;
  @Input() noVideo: boolean = false;
  @Input() globalTimebar: boolean = false;
  @Input() showFullscreenIcon: boolean = true;
  @Input() moveFollowCam: boolean = false;
  @Input() changeSize: boolean = false;
  @Input() isInsight: boolean = false;

  @Input() set selection(selection: TimeSelection) {
    this.playbackControl.selection.next(selection);
  }
  get selection(): TimeSelection {
    return this.playbackControl.selection.value;
  }
  @Output() selectionChange: EventEmitter<TimeSelection> = new EventEmitter<TimeSelection>();

  @Output() share = new EventEmitter<void>();

  @Output() animationPlayed = new EventEmitter<void>();

  @ViewChild('scene', { static: true })
  sceneRef: ElementRef;

  @ViewChild('container', { static: true }) container: ElementRef;

  @ViewChild('media', { static: true })
  media: any;
  videoTexture: any;
  videoScreen: any;
  videoScreenEnabled: boolean = false;

  aspectRatio: number;
  scene: THREE.Scene;
  camera: THREE.Camera;
  cameraOrtho: THREE.Camera[] = [];
  cameraPersp: THREE.Camera;
  canvas: HTMLCanvasElement;
  private cameraGroup: THREE.Group;

  controls: THREE.OrbitControls;
  controlsOrtho: THREE.OrbitControls[] = [];
  controlsPersp: THREE.OrbitControls;

  activeCamera: CameraTypeEnum = 0;
  axisGroup: THREE.Group;

  clock: THREE.Clock;
  axesHelper: THREE.AxesHelper;

  private inited: boolean = false;
  private touchTimeout;

  stats: any;
  id: string;

  options: ClipPlayerOptions;

  defaultBasicLightPosition: THREE.Vector3;
  defaultComplexLightPosition: THREE.Vector3;

  shortcutKeyDown: boolean = false;

  public controlState: string = 'visible';
  public hideControlsTimeout: any;
  public fullScreenEnabled: boolean = false;

  public enableFollowCam: boolean;
  public vrDisplay: any;
  private vrController: VrController;
  private subs: Subscription[] = [];

  timeScaling: number = 1;

  videoIndex: number = 0;
  video_3D_Index: number = 0;
  chartIndex: number = 0;
  videoTracks: any[] = [];
  chartTracks: any[] = [];

  takeIndex: number = 0;
  takesNumber: number = 0;
  transparencyAvailable: boolean = false;

  timeScalingStates: any[] = [1, 0.5, 0.25, 0.1];
  timeScalingStateIndex: number = 0;
  bulletTimeStateIndex: number = 2;
  bulletTimePreviousStateIndex: number = undefined;

  actions: MocapAction[] = [];
  duration: number = undefined;
  rootBone: any;

  forcePlates: ForcePlateRenderer[] = [];
  animationClip: ClipPlayerInput;
  animationPlayedTimer: any;

  poseHelper: PosenetSkeletonHelper;
  ghostCount = 0;
  private iterableDiffer: IterableDiffer<any>;

  chartGroup: THREE.Group;

  private _playing: boolean;
  @Input() set playing(value: boolean) {
    if (this.inited) {
      value ? this.play() : this.pause();
    }
  }
  get playing(): boolean { return this._playing; }

  public playbackSpeed: number;

  private curCameraCalibration: VideoDataForOverlay;

  protected mocapActionService: any;

  constructor(
    protected readonly renderer: ThreeJsRendererService,
    protected readonly domRenderer: Renderer2,
    protected playbackControl: PlaybackControlService,
    protected readonly http: HttpClient,
    protected readonly window: WindowService,
    protected readonly comp: PoseComparisonService,
    protected readonly _iterableDiffers: IterableDiffers,
    protected readonly optionService: ClipOptionsService,
    protected readonly loader: ClipLoaderService,
    protected readonly playbackGlobal: GlobalPlaybackControlService,
    protected readonly timecodeService: TimecodeService,
    protected readonly colorService: ColorService,
    protected readonly webvr: WebvrService,
    protected readonly keyboardHandlerService: KeyboardHandlerService,
  ) {
    webvr.getVRDisplays().then(displays => {
      if (displays) {
        this.vrDisplay = displays[0];
      }
    });
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    this.domRenderer.listen('window', 'vrdisplaypresentchange', ev => {
      this.renderer.enableVR(this, ev.display.isPresenting, this.options.coordinates);

      for (const action of this.actions) {
        if (action.transparentMaterial) {
          if (!ev.display.isPresenting) {
            action.transparentMaterial.wireframe = true;
            action.transparentMaterial.opacity = 0.17;
          } else {
            action.transparentMaterial.wireframe = false;
            action.transparentMaterial.opacity = 0.2;
          }
        }
      }

      if (!ev.display.isPresenting) {
        if (this.vrController) {
          this.scene.remove(this.vrController.teleporter);
          this.setTimeScaling(this.getTimeScalingState());
          this.vrController = null;
        }
      }
    });
  }

  ngOnInit(): void {
    if (!this.globalTimebar) {
      this.playbackGlobal.setPlaybackControl(this.playbackControl);
    } else {
      this.subs.push(this.playbackGlobal.playbackControl.subscribe(
        (service: PlaybackControlService) => {
          this.playbackControl = service;
        })
      );
      this.subs.push(this.playbackGlobal.playbackSpeed.subscribe((value) => {
        this.playbackSpeed = value;
        this.media.setPlaybackRate(value);
      }));
    }
    // @see polyb#21 Removed support for timecode service from here
    // this.timecodeService.clipId = this.clipId;

    this.subs.push(this.optionService.newOptionsAvailable.subscribe(() => {
      for (const action of this.actions) {
        const visibility = this.optionService.getVisibility(action.clipId);
        if (visibility != undefined) {
          action.toggleVisibility(visibility);
        }
      }
    }));
    this.options = this.getClipOptions(this._animationClips[0]);

    this.defaultBasicLightPosition = new THREE.Vector3(0, 1, 0);
    this.defaultComplexLightPosition = new THREE.Vector3(-5, 10, 5);
    /*if(this.options.coordinates == CoordinatesType.zUp)
    {
      this.defaultBasicLightPosition = new THREE.Vector3(0, 0, 1);
      this.defaultComplexLightPosition = new THREE.Vector3(-5, -5, 10);
    }*/

    this.optionService.setDataCharts([]);
    this.initScene();

    if (this.options.timeRamps.length > 0) {
      this.timeScalingStates.unshift(99);
      this.bulletTimeStateIndex++;
    }

    if (this.options.aidData.length > 0) {
      for (const aid of this.options.aidData) {

        if (!aid.data.dataUrl || aid.data.dataUrl == "") {
          continue;
        }
        const forcePlate = new ForcePlateRenderer();

        switch (aid.type) {
          case AidDataType.forceAidDataType:
            forcePlate.loadData(this.http, aid.data);
            this.forcePlates.push(forcePlate);
            this.scene.add(forcePlate.getGroup());
            break;
          case AidDataType.videoAidDataType:
            break;
          case AidDataType.audioAidDataType:
            break;
          case AidDataType.chartAidDataType:
            // this.chart.loadChartData(aid.data);
            break;
        }
      }
    }

    this.subs.push(this.playbackControl.playbackTime.subscribe(time => {
      if (!this.isActionAvailable()) {
        return;
      }

      for (const action of this.actions) {
        action.update(time);
      }

      if (this.timeScalingStates[this.timeScalingStateIndex] == 99) {
        this.updateAutotime(time);
      }

      if (this.enableFollowCam) {
        this.updateFollowCamPosition();
      }

      if (this.options.enableGhost) {
        for (const a of this.actions) {
          a.updatePlaceholderPositions();
        }
      }

      this.renderer.render(this);
    }));

    this.subs.push(this.playbackControl.mode.subscribe(mode => {
      if (!this.isActionAvailable()) {
        return;
      }

      switch (mode) {
        case PlaybackMode.Paused:
          this.renderer.deactivateScene(this);
          this.clock.stop();
          this._playing = false;
          break;
        case PlaybackMode.Seeking:
          this.clock.start();
          this.renderer.activateScene(this);
          this._playing = true;
          break;
        case PlaybackMode.Playing:
        default:
          this.clock.start();
          this.renderer.activateScene(this);
          this._playing = true;
      }
    }));

    this.subs.push(this.playbackControl.selection.subscribe(s => {
      this.selectionChange.emit(s);
      this.showControls();
    }));

  }

  public getTimeScalingState() {
    return this.timeScalingStates[this.timeScalingStateIndex];
  }

  public selectNextTimeScalingState(newStateIndex?: number): void {
    if (newStateIndex == undefined) {
      newStateIndex = this.timeScalingStateIndex + 1;
    }

    if (newStateIndex >= this.timeScalingStates.length) {
      newStateIndex = 0;
    }

    const targetTimeScaling = this.timeScalingStates[newStateIndex];
    this.timeScalingStateIndex = newStateIndex;
    if (targetTimeScaling > 0 && targetTimeScaling <= 3) {
      this.setTimeScaling(targetTimeScaling);
    } else {
      this.setTimeScaling(1);
    }
  }

  public updateAutotime(time): void {
    const timeRamps: any[] = this.options.timeRamps;

    if (timeRamps.length == 0) {
      return;
    }

    let currentRamp: any;
    let tempScaling = 1;
    for (const ramp of timeRamps) {
      if (time >= ramp.startTime && time < ramp.startTime + ramp.duration) {
        currentRamp = ramp;
        break;
      }
      if (time >= (ramp.startTime + ramp.duration)) {
        tempScaling = ramp.targetValue;
      }
    }

    if (!currentRamp) {
      this.setTimeScaling(tempScaling);
      return;
    }

    if (!currentRamp.startValue) {
      currentRamp.startValue = this.timeScaling;
    }

    const endTime = currentRamp.startTime + currentRamp.duration;
    const deltaTime = (endTime - time) / currentRamp.duration;
    tempScaling = (currentRamp.startValue * (deltaTime) + currentRamp.targetValue * (1 - deltaTime));
    if (tempScaling < 0) {
      tempScaling = 0;
    }

    if (tempScaling > 1) {
      tempScaling = 1;
    }

    this.setTimeScaling(tempScaling);
  }

  public cancelHideControls(): void {
    clearTimeout(this.hideControlsTimeout);
  }

  public showControls(): void {
    clearTimeout(this.hideControlsTimeout);
    this.controlState = 'visible';
    this.hideControlsTimeout = setTimeout(() => {
      if (!this.changeSize && !(this.isInsight && this.fullScreenEnabled)) {
        this.controlState = 'hidden';
      }
    }, 2000);
  }

  @HostListener('document:keyup.escape', ['$event'])
  onKeyupHandler(): void {
    if (this.fullScreenEnabled) {
      this.toggleFullscreen();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.renderer.updateSize(this, !this._playing);
  }

  public toggleFullscreen(): void {
    if (fscreen.fullscreenEnabled === false) {
      console.error('Fullscreen disabled.');
      return;
    }
    if (this.fullScreenEnabled === false) {
      this.domRenderer.addClass(this.container.nativeElement, 'fullscreen');
      if (this.isInsight) {
        this.domRenderer.addClass(this.container.nativeElement, 'fullscreen-insight');
      }
      this.fullScreenEnabled = true;
      this.playbackGlobal.controlsFullscreen.next(true);
    } else {
      this.domRenderer.removeClass(this.container.nativeElement, 'fullscreen');
      this.domRenderer.removeClass(this.container.nativeElement, 'fullscreen-insight');
      this.fullScreenEnabled = false;
      this.playbackGlobal.controlsFullscreen.next(false);
    }
    this.renderer.updateSize(this, !this._playing); // should actually be called one the fullscreen event is handled
  }

  public screenshot(): string {
    const wasPlaying = this.playing;
    this.pause();

    const screenshot = this.canvas.toDataURL('image/jpeg');

    if (wasPlaying) {
      this.play();
    }

    return screenshot;
  }

  isActionAvailable(): boolean {
    return this.actions.length > 0;
  }

  addPoseHelper(pose: any, color: number = 0x00ff00): void {
    if (this.poseHelper) {
      this.scene.remove(this.poseHelper.getSkeletonGroup());
    }
    this.poseHelper = new PosenetSkeletonHelper(color);
    this.scene.add(this.poseHelper.getSkeletonGroup());

    this.poseHelper.createPoints();
    this.poseHelper.update(undefined, pose);

    if (!this._playing) {
      this.renderer.render(this);
    }

  }

  // **blob to dataURL**
  private blobToDataURL(blob, callback) {
    const a = new FileReader();
    a.onload = function (e) { callback((e as any).target.result); };
    a.readAsDataURL(blob);
  }

  public animatedScreenshot(): Promise<string> {
    const GIF = require("./gif");
    return new Promise((resolve) => {
      const gif = new GIF({
        workers: 2,
        quality: 10,
        workerScript: './js/gif.worker.js'
      });

      let count = 0;
      const timer = setInterval(() => {
        gif.addFrame(this.canvas, { delay: 300 });
        count++;
        if (count > 10) {
          clearInterval(timer);
          gif.render();
        }
      }, 100);

      gif.on('finished', (blob) => {
        this.blobToDataURL(blob, resolve);
      });
    });
  }

  initScene(): void {
    // fscreen.addEventListener('fullscreenchange', this.onResize, false);
    this.canvas = this.sceneRef.nativeElement;
    this.aspectRatio = 1.78;
    this._playing = false;

    this.createScene();
    if (this.options.floorColorHex != 'none') {
      this.createFloor();
    }

    setTimeout(() => {
      this.initActions();
      setTimeout(() => {
        if (this.duration == 0 || this.duration == undefined) {
          this.renderer.deactivateScene(this);
        }
      }, 2000);
    }, 35);

    this.renderer.activateScene(this);
    this.renderer.addScene(this);
    this.inited = true;
    this.canvas.addEventListener('mousedown', this.startInteraction);
    this.canvas.addEventListener('touchstart', this.startInteraction);
    this.controls.addEventListener("change", (event) => {
      this.getControlsZoom(event);
    })
  }

  initActions(): void {
    this.addAnimations();
  }

  addAnimations(): void {
    // should make sure are not double loaded
    for (const animation of this._animationClips) {
      let present = false;
      for (const action of this.actions) {
        if (action.clipId == animation.id) {
          present = true;
          break;
        }
      }
      if (!present) {
        this.createNewAction(animation);
      }
    }
  }

  getClipOptions(animationClip: ClipPlayerInput) {
    const defaultMeshMaterial = {
      index: 0, type: 2, options: {
        color: 0xa0a0a0,
        shininess: 100, // 0-100, lower value use solid specular color (not 'shiny' but visible)
        specular: 0xe0e0e0, // default, this could also be dependent of floor color
        flatShading: true,
        skinning: true,
        transparent: true,
        opacity: 1
      }
    };

    const options = {
      applyMesh: false,
      meshAssetUnits: 0.1, // dm if not provided
      meshAssetUri: 'assets/man_v7c.JD',
      replaceMeshMaterials: MaterialModifier.nochange,
      meshMaterials: [defaultMeshMaterial],
      initTransparent: false,
      skyColorHex: 0x262626 as any, // 0x3f3f3f,
      floorColorHex: 0x404040 as any, // 0x808080,
      useFog: true,
      lightComplexity: 1,
      cameraInit: cameraInit,
      enableFollowCam: true,
      showCameras: true,
      aidData: [],
      renamePrefix: { sourcePrefix: "ISL", destPrefix: "" }, // hardcode to have files from IVO work without custom options
      coordinates: CoordinatesType.yUp,
      timeRamps: [],
      enableOpticalSegments: false,
      segmentsModelUri: "",
      forcePlateState: 1,
      enableGhost: false,
      placeholderColor: 0xff7300,
      ghostOptions: [],
      animationUnits: 0,
      skeletonUnits: 0,
      loadAnyModel: false,
      realHeight: 1.8,
      emgChannels: [],
      charts: { enablePlayerCharts: true },
      video: { enablePlayerVideo: true },
      plateOptions: { coordinatesScale: 0.001 }
      // playerTheme: 'light'
      // charts: { selectedId: "angles#5", visible: true, enableKinematic: true, enableKinetic: true }
    };

    if (animationClip && animationClip.customOptions !== undefined) {
      for (const property in animationClip.customOptions) {
        options[property] = animationClip.customOptions[property];
      }
    }

    if (this.noBack) {
      options.skyColorHex = "none";
      options.floorColorHex = "none";
    }

    if (this.noCharts) {
      options.charts.enablePlayerCharts = false;
    }

    if (this.noVideo) {
      options.video.enablePlayerVideo = false;
    }

    return options;

  }

  createChildAction(parentAction: MocapAction, animationClip: ClipPlayerInput): void {
    const id = parentAction.clipId ? parentAction.clipId : this.clipId;
    const trackNo = animationClip.trackNo != undefined ? animationClip.trackNo : 0;

    const placeholderColors = [0xffff00, 0x00ffff, 0xff0000, 0xff00ff];
    const ghostNumber = trackNo - 1;

    const ghostOptions = {
      opacity: 0.25,
      offset: { x: -0.7, y: 0, z: 0 },
      clipOptions: {
        placeHolderColor: (ghostNumber < placeholderColors.length) ? placeholderColors[ghostNumber] : 0xeeeeee,
      }
    };

    const customGhostOptions = this.options.ghostOptions;
    if (ghostNumber < customGhostOptions.length) {
      for (const opt in customGhostOptions[ghostNumber]) {
        ghostOptions[opt] = customGhostOptions[ghostNumber][opt];
      }
    }

    const action = new MocapAction(id, this.comp, this.loader, this.playbackControl, ghostOptions.opacity, this.colorService);
    this.scene.add(action.getActionGroup());

    const options = animationClip.customOptions ? this.getClipOptions(animationClip) : parentAction.options;
    for (const opt in ghostOptions.clipOptions) {
      options[opt] = ghostOptions.clipOptions[opt];
    }

    action.initAction(animationClip, options, this.http, this.mocapActionService).then((duration) => {
      if (action.motionClips && action.motionClips.length > this.takesNumber) {
        this.takesNumber = action.motionClips.length;
      }

      if (duration > this.duration || this.duration == undefined) {
        this.changeDuration(duration);
      }

      this.transparencyAvailable = action.isTransparencyAvailable();

      if (this.options.enableGhost) {
        action.setPlaceholderColors(ghostOptions.clipOptions.placeHolderColor);
        const offset = { x: ghostOptions.offset.x * trackNo, y: ghostOptions.offset.y * trackNo, z: ghostOptions.offset.z * trackNo };
        action.moveActionGroup(offset);
      }
    });

    this.actions.push(action);
  }

  createNewAction(animationClip: ClipPlayerInput): void {
    const id = animationClip.id ? animationClip.id : this.clipId;
    const action = new MocapAction(id, this.comp, this.loader, this.playbackControl, undefined, this.colorService);

    const options = this.getClipOptions(animationClip);

    this.subs.push(action.chartTracksObserver.subscribe((chartDataTracks) => {
      let selectedId;
      for (const t of chartDataTracks) {
        for (const tt of t.tracks) {
          let found = false;
          for (const c of this.chartTracks) {
            if (c.id == tt.id) {
              found = true;
              break;
            }
          }
          if (this.options.charts && this.options.charts.selectedId == tt.id) {
            selectedId = this.options.charts.selectedId;
          }
          if (!found) {
            this.chartTracks.push(tt);
          }
        }
      }

      this.optionService.setDataCharts(chartDataTracks, selectedId);

      if (this.options.charts && this.options.charts.visible) {
        // this.chart.toggleVisibility(true);
      }

    }));

    this.subs.push(action.motionTracksObserver.subscribe((motion) => {
      this.createChildAction(action, motion);
    }));

    this.subs.push(action.videoTracksObserver.subscribe((videoTracks) => {
      this.videoTracks = videoTracks;

      if (!this.isVideoAvailable() && this.videoTracks.length > 0) {
        this.videoIndex = 0;
        this.createVideoComp(this.videoTracks[this.videoIndex].options);

      }
    }));

    this.scene.add(action.getActionGroup());

    action.initAction(animationClip, options, this.http, this.mocapActionService).then((duration) => {
      if (duration > this.duration || this.duration == undefined) {
        this.changeDuration(duration);
      }

      if (this.options.enableGhost) {
        action.setPlaceholderColors(this.options.placeholderColor);
      }

      if (this.autoPlay && duration > 0) {
        this.play();
        if (this.animationPlayedTimer == undefined) {
          this.animationPlayedTimer = setTimeout(() => this.animationPlayed.emit(), 3000);
        }
      }

      if (this.enableFollowCam) {
        action.updateFollowCamPosition(this.controls);
      }

      if (action.motionClips && action.motionClips.length > this.takesNumber) {
        this.takesNumber = action.motionClips.length;
      }

      this.transparencyAvailable = action.isTransparencyAvailable();
    });

    this.actions.push(action);
    // return action;
  }

  changeDuration(duration: number): void {
    this.duration = duration;

    if (duration == 0) {
      return;
    }

    this.playbackControl.clipDuration = duration;
    this.playbackControl.timebarDuration = duration;

    /*if(this.isChartAvailable())
      this.chart.setDefaultLimits(0, this.duration);*/
  }

  ngOnDestroy(): void {
    this.renderer.removeScene(this);
    this.canvas.removeEventListener('mousedown', this.startInteraction);
    this.canvas.removeEventListener('touchstart', this.startInteraction);
    this.controls.removeEventListener('change', (event) => {});
    this.subs.forEach(sub => sub.unsubscribe());
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:keydown', ['$event'])
  handleKeyDownUpEvent(event: any): void {
    if (this.keyboardHandlerService.isEventFromInput(event)) {
      return;
    }
    this.shortcutKeyDown = event.shiftKey;
    if (this.shortcutKeyDown && event.key.toLowerCase() == 'b' && event.type == "keydown") {
      if (this.bulletTimePreviousStateIndex == undefined) {
        this.bulletTimePreviousStateIndex = this.timeScalingStateIndex;
        this.selectNextTimeScalingState(this.bulletTimeStateIndex);
      }
    } else if (event.key.toLowerCase() == 'b' && event.type == "keyup") {
      if (this.bulletTimePreviousStateIndex != undefined) {
        this.selectNextTimeScalingState(this.bulletTimePreviousStateIndex);
        this.bulletTimePreviousStateIndex = undefined;
      }
    }
  }

  setTimeScaling(value: number): void {
    this.playbackGlobal.setPlaybackSpeed(value);

    this.timeScaling = value;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: any): void {
    const x = event.key;
    if (this.keyboardHandlerService.isEventFromInput(event)) {
      return;
    }
    if (this.shortcutKeyDown) {
      switch (x.toLowerCase()) {
        case 'q':
          this.toggleStats();
          break;
        case 'p':
          for (const action of this.actions) {
            action.togglePosenetHelper();
          }
          break;
        case 's':
          for (const action of this.actions) {
            action.toggleSkeleton();
          }
          this.axesHelper.visible = !this.axesHelper.visible;
          break;
        case 't':
          this.toggleTransparency();
          break;
        case 'm':
          for (const action of this.actions) {
            action.toggleMarkers();
          }
          break;
        case 'l':
          for (const action of this.actions) {
            action.toggleOpticalSegments();
          }
          break;
        case '>':
          for (const action of this.actions) {
            action.toggleMarkersTrajectory();
          }
          break;
        case 'h':
          for (const action of this.actions) {
            action.toggleForcePlates();
          }
          break;
        case 'n':
          this.selectNextTake();
          break;
        case 'c':
          for (const action of this.actions) {
            action.toggleCameras();
          }

          // this.getCameraData();
          break;
        case ')':
          this.toggleCamera();
          break;
        case 'w':
          for (const action of this.actions) {
            action.toggleActivationSamples();
          }
          break;
        case 'v':
          this.selectNextVideo();
          break;
        case 'f':
          this.selectNextChart();
          break;
        case 'x':
          this.timeScalingStates.unshift(3);
          this.bulletTimeStateIndex++;
          this.selectNextTimeScalingState(0);
          break;
      }

      if (!this._playing) {
        this.renderer.render(this);
      }
    } else {
      /*if( (event.charCode ? event.charCode : event.keyCode) == 32){
          this.playbackControl.togglePlayPause();
          event.preventDefault();
      }
      let framerate = this.timecodeService.getFramerate();
      let timeJumpSeconds = 1;
      if(framerate != -1)
        timeJumpSeconds *= 1 / framerate;*/   // currently broken jump for different framerates

      switch (x.toLowerCase()) {
        case 'f':
          this.toggleFollowCam();
          break;
          /*case 'j':
            this.playbackControl.timeDeltaMove(-timeJumpSeconds);
            break;
          case 'l':
            this.playbackControl.timeDeltaMove(timeJumpSeconds);
            break;
          case 'k':
            this.playbackControl.togglePlayPause();*/
          break;
      }
    }
  }

  private startInteraction = (evt) => {
    const activate = (eventHandler) => {
      if (!this._playing) {
        this.renderer.activateScene(this);
      }
      this.controlsPersp.enableRotate = true;
      eventHandler(evt);
    };

    switch (evt.type) {
      case 'touchstart':
        if (!this._playing) {
          this.touchTimeout = setTimeout(() => {
            activate(this.controls.onTouchStart);
          }, TOUCH_DELAY);
        } else {
          activate(this.controls.onTouchStart);
        }
        break;
      case 'mousedown':
        activate(this.controls.onMouseDown);
        break;
    }
  }

  public hasValidCameraCalibrationData(): boolean {
    return this.videoTracks.some(x => x.cameraCalibration !== undefined);
  }

  private getCurrentCameraCalibration(): void {
    if (this.videoIndex < 0) {
      this.curCameraCalibration = undefined
    } else {
      const curVideo = this.videoTracks[this.videoIndex];
      const cameraCalibration = curVideo.cameraCalibration !== undefined ? curVideo.cameraCalibration : undefined;
      if (cameraCalibration !== undefined) {
        if (cameraCalibration.is_initialized) {
            this.curCameraCalibration = cameraCalibration;
        } else {
            // get lab orientation
            const labOriMarkers = this.actions && this.actions.length > 0 && this.actions[0]?.actors && this.actions[0].actors.length > 0 && this.actions[0].actors[0]?.markersContainer?.children && this.actions[0].actors[0].markersContainer.children.length > 0 && this.actions[0].actors[0]?.markersContainer.children.find(x => x.name === 'Lab orientation').position;
            const H_lab = new THREE.Matrix4();
            // disable the correction for lab orientation, this was used for an older example dataset in different lab of HvA.
            // if (labOriMarkers && Math.abs(labOriMarkers.z) < 0.01) {
            //     // assume lab orientation is horizontal, get cross yxz, with z [0,0,1]
            //     // Marker defines -y (To be verified!)
            //     const y = [-labOriMarkers.x, -labOriMarkers.y , 0];
            //     const x = [y[1], -y[0], 0];

            //     const z = [0, 0, 1];
            //     H_lab.set(x[0], x[1], x[2], 0, y[0], y[1], y[2], 0, z[0], z[1], z[2], 0, 0, 0, 0, 1);
            //     cameraCalibration.H_lab = [x, y, z];

            //     const dlt = new THREE.Matrix4();
            //     dlt.set( cameraCalibration.dlt_matrix[0][0], cameraCalibration.dlt_matrix[0][1], cameraCalibration.dlt_matrix[0][2], cameraCalibration.dlt_matrix[0][3],
            //       cameraCalibration.dlt_matrix[1][0], cameraCalibration.dlt_matrix[1][1], cameraCalibration.dlt_matrix[1][2], cameraCalibration.dlt_matrix[1][3],
            //       cameraCalibration.dlt_matrix[2][0], cameraCalibration.dlt_matrix[2][1], cameraCalibration.dlt_matrix[2][2], cameraCalibration.dlt_matrix[2][3],
            //       0, 0, 0, 1);

            //     // now also rotate dlt_matrix and p with this
            //     const k_inv = new THREE.Matrix4();
            //     k_inv.set( cameraCalibration.k_inv[0][0], cameraCalibration.k_inv[0][1], cameraCalibration.k_inv[0][2], 0,
            //       cameraCalibration.k_inv[1][0], cameraCalibration.k_inv[1][1], cameraCalibration.k_inv[1][2], 0,
            //       cameraCalibration.k_inv[2][0], cameraCalibration.k_inv[2][1], cameraCalibration.k_inv[2][2], 0,
            //       0, 0, 0, 1);

            //     const k = new THREE.Matrix4();
            //     k.set( cameraCalibration.k[0][0], cameraCalibration.k[0][1], cameraCalibration.k[0][2], 0,
            //       cameraCalibration.k[1][0], cameraCalibration.k[1][1], cameraCalibration.k[1][2], 0,
            //       cameraCalibration.k[2][0], cameraCalibration.k[2][1], cameraCalibration.k[2][2], 0,
            //       0, 0, 0, 1);

            //     // To get new DLT, first undo k matrix, then rotate global (post-multiply), then re-apply k
            //     dlt.premultiply(k_inv);
            //     dlt.multiply(H_lab);
            //     dlt.premultiply(k);


            //     cameraCalibration.dlt_matrix = [[dlt.elements[0], dlt.elements[4], dlt.elements[8], dlt.elements[12]],
            //     [dlt.elements[1], dlt.elements[5], dlt.elements[9], dlt.elements[13]],
            //     [dlt.elements[2], dlt.elements[6], dlt.elements[10], dlt.elements[14]],
            //     ];

            //     const s_temp = new THREE.Vector3(1,1,1);
            //     const q_gc_zup = new THREE.Quaternion(cameraCalibration.q_gc_zup[0],cameraCalibration.q_gc_zup[1],cameraCalibration.q_gc_zup[2],cameraCalibration.q_gc_zup[3]);
            //     const H_gc_zup = new THREE.Matrix4();
            //     const p_zup = new THREE.Vector3(cameraCalibration.position[0], cameraCalibration.position[1], cameraCalibration.position[2]);
            //     H_gc_zup.compose(p_zup, q_gc_zup, s_temp);
            //     H_gc_zup.premultiply(H_lab);
            //     H_gc_zup.decompose(p_zup, q_gc_zup, s_temp);
            //     cameraCalibration.position = [p_zup.x, p_zup.y, p_zup.z];
            //     cameraCalibration.q_gc_zup = [q_gc_zup.x, q_gc_zup.y, q_gc_zup.z, q_gc_zup.w];
            // }

            this.curCameraCalibration = cameraCalibration;
            const H_g1g = new THREE.Matrix4();
            H_g1g.set(1,0,0,0,
              0,0,1,0,
              0,-1,0,0,
              0,0,0,1);   // this is to go from z-up to y-up

            const H_corr_cam = new THREE.Matrix4();
            H_corr_cam.set(1,0,0,0,
              0,-1,0,0,
              0,0,-1,0,
              0,0,0,1);   // this brings camera frame to "screen", camera has y down z to front, then we bring y up and z to back so we have it aligned in neutral

            const q_gc_zup = new THREE.Quaternion(cameraCalibration.q_gc_zup[0],cameraCalibration.q_gc_zup[1],cameraCalibration.q_gc_zup[2],cameraCalibration.q_gc_zup[3]);
            const H_gc_zup = new THREE.Matrix4();
            H_gc_zup.compose(new THREE.Vector3(0,0,0), q_gc_zup, new THREE.Vector3(1,1,1));

            const H_g1c1 = H_gc_zup;
            H_g1c1.multiply(H_corr_cam);
            H_g1c1.premultiply(H_g1g);


            const q_g1c1 = new THREE.Quaternion();
            q_g1c1.setFromRotationMatrix(H_g1c1);
            // now get z-camera points through camera, get focal point
            const p_c = new THREE.Vector3(cameraCalibration.position[0]/1000, cameraCalibration.position[1]/1000, cameraCalibration.position[2]/1000);
            const p_c_yup: THREE.Vector = p_c.applyMatrix4(H_g1g);

            this.curCameraCalibration.q_gc_yup = [q_g1c1.x, q_g1c1.y, q_g1c1.z, q_g1c1.w];
            this.curCameraCalibration.p_c_yup = [p_c_yup.x, p_c_yup.y, p_c_yup.z];
            cameraCalibration.is_initialized = true;
        }
      } else {
        this.curCameraCalibration = undefined;
      }
    }
  }

  private getControlsZoom(evt) {
    // for normal 3D we have normal zoom (dolly), for camera projection we fix camera position and zoom in the image by changing fov
    if (this.video_3D_Index == 0 ) {
      this.camera.fov = cameraFov;
    } else if (this.curCameraCalibration) {
      const curPosition = evt.target.object.position;
      const fixPosition = this.curCameraCalibration.p_c_yup;
      const d = [curPosition.x - fixPosition[0], curPosition.y - fixPosition[1], curPosition.z - fixPosition[2]];
      const pg_cam = new THREE.Vector3( d[0], d[1], d[2]);
      const q_gc = new THREE.Quaternion(this.curCameraCalibration.q_gc_yup[0],this.curCameraCalibration.q_gc_yup[1],this.curCameraCalibration.q_gc_yup[2],this.curCameraCalibration.q_gc_yup[3])
      const H_cg = new THREE.Matrix4();
      H_cg.compose(new THREE.Vector3(0,0,0), q_gc.conjugate(), new THREE.Vector3(1,1,1));
      pg_cam.applyMatrix4(H_cg);

      let alpha = this.camera.fov /2;
      if (pg_cam.z < 0) {
        alpha = Math.max(2,alpha - 2);
      } else if (pg_cam.z > 0) {
        alpha = Math.min(cameraFov, alpha + 2);
      }
      this.camera.fov = alpha * 2;
      this.camera.position.set(this.curCameraCalibration.p_c_yup[0], this.curCameraCalibration.p_c_yup[1], this.curCameraCalibration.p_c_yup[2])
      this.camera.quaternion.set(this.curCameraCalibration.q_gc_yup[0], this.curCameraCalibration.q_gc_yup[1], this.curCameraCalibration.q_gc_yup[2], this.curCameraCalibration.q_gc_yup[3])
    }
    this.camera.updateProjectionMatrix();
  }
  public update(): void {
    this.renderer.render(this);
  }

  @HostListener('touchmove')
  private onTouchMove() {
    clearTimeout(this.touchTimeout);
  }

  @HostListener('mouseup')
  @HostListener('touchend')
  private stopInteraction() {
    this.controlsPersp.enableRotate = false;
    clearTimeout(this.touchTimeout);
    if (!this._playing) {
      this.renderer.deactivateScene(this);
    }
  }

  @HostListener('wheel')
  private onWheel() {
    if (!this._playing) {
      this.renderer.activateScene(this);
      setTimeout(() => {
        this.renderer.deactivateScene(this);
      }, 200);
    }
  }

  toggleCamera(): void {
    switch (this.activeCamera + 1) {
      case CameraTypeEnum.CameraTypePerspective:
        this.activeCamera = CameraTypeEnum.CameraTypePerspective;
        this.controls = this.controlsPersp;
        this.camera = this.cameraPersp;
        break;
      case CameraTypeEnum.CameraTypeOrthoRight:
        this.activeCamera = CameraTypeEnum.CameraTypeOrthoRight;
        this.controls = this.controlsOrtho[0];
        this.camera = this.cameraOrtho[0];
        break;
      case CameraTypeEnum.CameraTypeOrthoForward:
        this.activeCamera = CameraTypeEnum.CameraTypeOrthoForward;
        this.controls = this.controlsOrtho[1];
        this.camera = this.cameraOrtho[1];
        break;
      default:
        this.activeCamera = CameraTypeEnum.CameraTypePerspective;
        this.controls = this.controlsPersp;
        this.camera = this.cameraPersp;
    }

    if (this.activeCamera != CameraTypeEnum.CameraTypePerspective) {
      if (this.scene.fog) {
        this.scene.fog.near = 0.1;
        this.scene.fog.far = 0;
      }
      if (this.axisGroup) {
        this.axisGroup.visible = true;
      }
    } else {
      if (this.scene.fog) {
        this.scene.fog.near = 6.5;
        this.scene.fog.far = 80;
      }
      if (this.axisGroup) {
        this.axisGroup.visible = false;
      }
    }
  }

  toggleFollowCam(): void {
    this.enableFollowCam = !this.enableFollowCam;
    if (this.enableFollowCam) {
      this.updateFollowCamPosition();
    }

    if (!this._playing) {
      this.renderer.render(this);
    }
  }

  toggleTransparency(): void {
    for (const action of this.actions) {
      action.toggleTrasparency();
    }

    if (!this._playing) {
      this.renderer.render(this);
    }
  }

  enterVR(): void {
    this.renderer.enableVR(this, true, this.options.coordinates);
    this.vrDisplay.requestPresent([{ source: this.canvas }]);
    VrController.getController(this.renderer, this.options.coordinates).then(controller => {
      this.vrController = controller;
      this.scene.add(this.vrController.teleporter);
      this.vrController.axes.pipe(
        takeWhile(() => !!this.vrController),
        map(ax => ax[0])
      ).subscribe(ax => {
        const currentSpeed = this.getTimeScalingState();
        const value = ax === 0 ? currentSpeed : 2 * ax;
        this.setTimeScaling(value);
      });
    });
  }

  animate(): void {
    if (!this.globalTimebar) {
      const delta = this.clock.getDelta() * this.timeScaling;
      this.playbackControl.incrementTime(delta);
    }

    /*if(this.segmentsRenderer)
      this.segmentsRenderer.update();*/

    if (this.stats != undefined) {
      this.stats.update();
    }

    if (this.vrController) {
      this.vrController.update();
    }

    if (!this.isActionAvailable()) {
      this.renderer.render(this);
    }

    /*if(this.controls)
      this.controls.update();*/
  }

  private updateFollowCamPosition() {
    if (this.actions.length > 0) {
      this.actions[0].updateFollowCamPosition(this.controls);
    }
  }

  play(): void {
    this.playbackControl.play();
  }

  pause(): void {
    this.playbackControl.pause();
  }

  private addBasicLights() {
    const light = new THREE.HemisphereLight(0xffffff, 0x444444, 1.0);
    light.position.set(this.defaultBasicLightPosition.x, this.defaultBasicLightPosition.y, this.defaultBasicLightPosition.z);
    this.scene.add(light);
  }

  private addComplexLight() {
    const light = new THREE.DirectionalLight(0xffffff, 0.5);
    light.castShadow = true;
    // Set up shadow properties for the light
    if (this.options.lightComplexity > 1) {
      light.shadow.mapSize.width = 1024;
      light.shadow.mapSize.height = 1024;
    } else {
      light.shadow.mapSize.width = 512;
      light.shadow.mapSize.height = 512;
    }

    light.shadow.camera.near = 0.025;
    light.shadow.camera.far = 20;

    light.position.set(this.defaultComplexLightPosition.x, this.defaultComplexLightPosition.y, this.defaultComplexLightPosition.z);

    this.cameraGroup.add(light);
    this.cameraGroup.add(light.target);
  }

  private addStats() {
    this.stats = new Stats();
    this.stats.dom.style.zIndex = "20000";
    this.container.nativeElement.appendChild(this.stats.dom);
  }

  private toggleStats() {
    if (this.stats == undefined) {
      this.addStats();
    } else {
      if (this.stats.dom.style.visibility != 'hidden') {
        this.stats.dom.style.visibility = 'hidden';
      } else {
        this.stats.dom.style.visibility = '';
      }
    }
  }

  setDefaultCameraTarget(target: THREE.Vector3): void {
    for (const c of this.controlsOrtho) {
      c.target.copy(target);
      c.saveState();
      c.reset();
    }

    this.controlsPersp.target.copy(target);
    this.controlsPersp.saveState();
    this.controlsPersp.reset();
  }

  getCameraData() {
    const cameraData = {
      origin: { x: this.controls.object.position.x, y: this.controls.object.position.y, z: this.controls.object.position.z },
      target: { x: this.controls.target.x, y: this.controls.target.y, z: this.controls.target.z }
    };

    return cameraData;
  }

  addFog(fogColorHex: number): void {
    this.scene.fog = new THREE.Fog(fogColorHex, 6.5, 20);
  }

  createScene(): void {
    const view = {
      angle: cameraFov,
      aspect: this.aspectRatio,
      near: 0.01,
      far: 100,
    };

    this.scene = new THREE.Scene();
    if (this.options.skyColorHex != 'none') {
      this.scene.background = new THREE.Color(this.options.skyColorHex);
    }
    this.cameraPersp = new THREE.PerspectiveCamera(view.angle, view.aspect,
      view.near, view.far);

    const cameraOrthoRight = new THREE.OrthographicCamera(5 / - 2, 5 / 2, 3 / 2, 3 / - 2, 1, 1000);
    cameraOrthoRight.position.set(-3, 0.8, 0);

    const cameraOrthoForward = new THREE.OrthographicCamera(5 / - 2, 5 / 2, 3 / 2, 3 / - 2, 1, 1000);
    cameraOrthoForward.position.set(0, 0.8, 3);

    this.cameraOrtho.push(cameraOrthoRight);
    this.cameraOrtho.push(cameraOrthoForward);

    /*if(this.options.coordinates == CoordinatesType.zUp)
      this.camera.up.set( 0, 0, 1 );*/

    this.cameraGroup = new THREE.Group();

    this.cameraPersp.position.set(this.options.cameraInit.origin.x, this.options.cameraInit.origin.y, this.options.cameraInit.origin.z);

    for (const c of this.cameraOrtho) {
      this.cameraGroup.add(c);
    }

    this.cameraGroup.add(this.cameraPersp);

    this.camera = this.cameraPersp;
    this.enableFollowCam = this.options.enableFollowCam;

    this.controlsPersp = new THREE.OrbitControls(this.cameraPersp, this.sceneRef.nativeElement);
    this.controlsPersp.enableRotate = false;
    this.controlsPersp.enableDamping = false;

    for (const c of this.cameraOrtho) {
      const controls = new THREE.OrbitControls(c, this.sceneRef.nativeElement);
      controls.enableRotate = false;
      this.controlsOrtho.push(controls);
    }
    this.controls = this.controlsPersp;
    this.setDefaultCameraTarget(new THREE.Vector3(this.options.cameraInit.target.x, this.options.cameraInit.target.y, this.options.cameraInit.target.z));


    this.scene.add(this.cameraGroup);
    this.clock = new THREE.Clock(false);

    if (this.options.useFog && this.options.skyColorHex != 'none') {
      this.addFog(this.options.skyColorHex);
    }

    this.addBasicLights();
    if (this.options.lightComplexity > 0) {
      this.addComplexLight();
    }

    this.id = this.scene.id;
  }

  public isVideoAvailable(): boolean {
    return this.media ? this.media.isVideoAvailable() : false;
  }

  selectNextTake(): void {
    for (const action of this.actions) {
      this.takeIndex = action.selectNextTake();
    }
  }
  selectNextVideo() {
    // if we select next video, we toggle between 3D view and camera projections
    this.scene.remove(this.videoScreen);
    this.videoScreenEnabled = false;
    this.curCameraCalibration = undefined;
    if (this.hasValidCameraCalibrationData()) {
      this.video_3D_Index++;  // this is videoIndex + 1
      this.video_3D_Index = this.video_3D_Index >= this.videoTracks.length + 1 ? 0 : this.video_3D_Index;
      this.videoIndex = this.video_3D_Index > 0 ? this.video_3D_Index - 1 : -1;
      this.getCurrentCameraCalibration();
        
      if (this.curCameraCalibration === undefined) {
        // default behavior
        for (const action of this.actions) {
          action.toggleForcePlates(1);
        }
        // set camera to default
        this.camera.fov = cameraFov;
        this.camera.position.set(cameraInit.origin.x, cameraInit.origin.y, cameraInit.origin.z);
        this.camera.lookAt(cameraInit.target.x, cameraInit.target.y, cameraInit.target.z);
        this.controls.target.x = cameraInit.target.x;
        this.controls.target.y = cameraInit.target.y;
        this.controls.target.z = cameraInit.target.z;
        this.controls.saveState();
        this.controls.reset();
        this.enableFollowCam = true;
        this.camera.updateProjectionMatrix();
      } else {
        this.selectVideoTrack(this.videoIndex);
        // Don't plot force plates in 3D with overlay for now
        for (const action of this.actions) {
          action.toggleForcePlates(0);
        }
        this.camera.fov = cameraFov;
        this.camera.position.set(this.curCameraCalibration.p_c_yup[0], this.curCameraCalibration.p_c_yup[1], this.curCameraCalibration.p_c_yup[2])
        this.camera.quaternion.set(this.curCameraCalibration.q_gc_yup[0], this.curCameraCalibration.q_gc_yup[1], this.curCameraCalibration.q_gc_yup[2], this.curCameraCalibration.q_gc_yup[3])
        this.camera.updateProjectionMatrix()
        if (this.curCameraCalibration.p_s_g_3D !== undefined) {
          this.controls.target.x = this.curCameraCalibration.p_s_g_3D[0];
          this.controls.target.y = this.curCameraCalibration.p_s_g_3D[1];
          this.controls.target.z = this.curCameraCalibration.p_s_g_3D[2];
          this.controls.saveState();
          this.controls.reset();
        }

        // Don't allow to rotate cam, but only zoom
        this.enableFollowCam = false;
      }
    }
  }


  selectNextChart(): void {
    let index = this.chartIndex + 1;

    if (index >= this.chartTracks.length) {
      index = 0;
    }

    if (this.chartTracks.length > index) {
      this.optionService.chartSelectedId.next(this.chartTracks[index].id);
      // this.chart.setChartData(this.chartTracks[this.chartIndex]);
    }
  }
  selectVideoTrack(index: number) {
    if (this.media && index < this.videoTracks.length) {
      this.media.readMediaData(this.videoTracks[index].options);
    }
    this.addVideoScreen();
  }

  handleMediadataLoaded(): void {
    if (this.videoScreenEnabled) {
      const _currentTime = this.playbackControl.playbackTime.value;
      if (this.playbackControl.isPlaying()) {
        let newTime = _currentTime;
        if (this.videoScreenEnabled) {
          newTime = this.playbackControl.jumpToTimeBasedOnTimeJump(_currentTime); // pauses and seeks
        } else {
          this.playbackControl.jumpToTime(_currentTime); // pauses and seeks
        }
        setTimeout(() => {
          this.playbackControl.togglePlayPause(); // restarts the video with small timeout to allow for all to load.
        }, 500);
      } else {
        let newTime = _currentTime;
        if (this.videoScreenEnabled) {
          newTime = this.playbackControl.jumpToTimeBasedOnTimeJump(_currentTime); // pauses (although already paused in this else) and seek
        } else {
          this.playbackControl.jumpToTime(_currentTime); // pauses (although already paused in this else) and seek
        }
      }
    }
  }

  createVideoComp(data: MediaData) {
    this.media.createVideo(data,
      () => {
        if (!this._playing) {
          this.renderer.render(this);
        }
      })
      this.media.setPlaybackControl(false, this.media.mode, this.playbackControl);
      this.media.setPlaybackRate(this.playbackGlobal.playbackControl.getValue().getPlaybackSpeed());
  }

  createFloor(): void {
    const floorSize = 1000; // m
    let floorTileFrontAsset = 'assets/floor_tile.png';
    const floorTileBackAsset = 'assets/reverse_floor_tile.png';
    if (this.options.playerTheme == 'light') {
      floorTileFrontAsset = 'assets/floor_tile_light.png';
    }

    const configurations = [
      {
        asset: floorTileFrontAsset,
        side: THREE.FrontSide,
        shadow: true,
        transparent: false
      },
      {
        asset: floorTileBackAsset,
        side: THREE.BackSide,
        shadow: false,
        transparent: true
      }
    ];

    const floorGeometry = new THREE.PlaneBufferGeometry(floorSize, floorSize, 10, 10);

    for (const config of configurations) {
      const floorTexture = new THREE.TextureLoader().load(config.asset);
      floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
      floorTexture.repeat.set(floorSize, floorSize);
      floorTexture.anisotropy = this.renderer.maxAnisotropy;

      const material = new THREE.LineBasicMaterial({
        color: 0x5F5F5F,
      });

      const geometryAX = new THREE.Geometry();
      geometryAX.vertices.push(
        new THREE.Vector3(0, 0, -1000),
        new THREE.Vector3(0, 0, 1000)
      );

      const geometryAZ = new THREE.Geometry();
      geometryAZ.vertices.push(
        new THREE.Vector3(-1000, 0, 0),
        new THREE.Vector3(1000, 0, 0)
      );

      let floorColor = 0x404040;
      if (this.options.floorColorHex != 'none' && this.options.floorColorHex != 'water') {
        floorColor = this.options.floorColorHex;
      }

      if (this.options.playerTheme) {
        floorColor = undefined;
      }

      this.axisGroup = new THREE.Group();
      this.axisGroup.add(new THREE.Line(geometryAX, material));
      this.axisGroup.add(new THREE.Line(geometryAZ, material));
      this.axisGroup.visible = false;
      this.scene.add(this.axisGroup);

      const floorMaterial = new THREE.MeshPhongMaterial({
        color: floorColor,
        map: floorTexture,
        side: config.side,
        transparent: config.transparent,
        alphaTest: 0.5
      });

      const mesh = new THREE.Mesh(floorGeometry, floorMaterial);

      mesh.rotation.z = Math.PI;
      mesh.rotation.x = -Math.PI / 2;
      /*switch(this.options.coordinates) {
        case CoordinatesType.zUp:
          mesh.rotation.z = Math.PI;
          break;
        case CoordinatesType.yUp:
        default:
          mesh.rotation.x = -Math.PI/2;
          break;
      }*/
      mesh.receiveShadow = config.shadow;
      this.scene.add(mesh);

      if (this.options.floorColorHex == 'water') {
        mesh.position.y = - 1.7;
        this.addWater();
      }

      this.axesHelper = new THREE.AxesHelper(1);
      this.axesHelper.position.y += 0.005;
      this.axesHelper.visible = false;
      this.scene.add(this.axesHelper);

    }
  }

  addWater(): void {
    const params = {
      color: '#97a7a7', // '#dffefe',
      scale: 4,
      flowX: 1,
      flowY: 1
    };

    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    this.scene.add(ambientLight);

    const waterSize = 60;
    const waterGeometry = new THREE.PlaneBufferGeometry(waterSize, waterSize);

    const water = new THREE.Water(waterGeometry, {
      color: params.color,
      scale: params.scale,
      flowDirection: new THREE.Vector2(params.flowX, params.flowY),
      textureWidth: 1024,
      textureHeight: 1024,
      clipBias: -.005
    });
    water.position.y = -0.15;
    water.rotation.x = Math.PI * - 0.5;
    this.scene.add(water);
  }

  private addVideoScreen(): boolean {
    this.videoScreenEnabled = false;
    // in here, we calculate position and orientation of our screen on which we project video image
    if (this.curCameraCalibration === undefined) {
      return false;
    }

    // rotation
    let u: number;
    let v: number;
    let s_radial: number;

    const dlt = new THREE.Matrix4();
    dlt.set( this.curCameraCalibration.dlt_matrix[0][0], this.curCameraCalibration.dlt_matrix[0][1], this.curCameraCalibration.dlt_matrix[0][2], this.curCameraCalibration.dlt_matrix[0][3],
      this.curCameraCalibration.dlt_matrix[1][0], this.curCameraCalibration.dlt_matrix[1][1], this.curCameraCalibration.dlt_matrix[1][2], this.curCameraCalibration.dlt_matrix[1][3],
      this.curCameraCalibration.dlt_matrix[2][0], this.curCameraCalibration.dlt_matrix[2][1], this.curCameraCalibration.dlt_matrix[2][2], this.curCameraCalibration.dlt_matrix[2][3],
      0, 0, 0, 1);

    // now we project our video 100mm in front of the camera
    const curDist_z = 100;

    const r_g1g = new THREE.Matrix4();
    r_g1g.set(1,0,0,0,
      0,0,-1,0,
      0,1,0,0,
      0,0,0,1);

    const scale_x = 1000/(Math.min(...this.curCameraCalibration.sensor_size)/this.curCameraCalibration.pixel_scale)
    const curScale = curDist_z/this.curCameraCalibration.focal_point/scale_x;

    const q_gc = new THREE.Quaternion(this.curCameraCalibration.q_gc_zup[0],this.curCameraCalibration.q_gc_zup[1],this.curCameraCalibration.q_gc_zup[2],this.curCameraCalibration.q_gc_zup[3])
    const p_c_g = new THREE.Vector3(this.curCameraCalibration.position[0], this.curCameraCalibration.position[1], this.curCameraCalibration.position[2]);
    const Hz_cam = new THREE.Matrix4();
    Hz_cam.compose(new THREE.Vector3(0,0,0), q_gc, new THREE.Vector3(1,1,1));

    const newDist_z = curDist_z;
    // const newDist_z = curDist_z*this.curCameraCalibration.focal_point/Math.min(...this.curCameraCalibration.sensor_size)*this.curCameraCalibration.pixel_scale*1000;

    // By uncommenting the line below, we plot debug points to check the scaling on the screen
    // this.plotTestPointsOnScreen(curScale, curDist_z, newDist_z);

    // Now store screen position with y-up
    const p_screen = new THREE.Vector3( 0, 0, newDist_z);
    p_screen.applyMatrix4(Hz_cam);
    const p_screen_g = new THREE.Vector3(p_c_g.x + p_screen.x, p_c_g.y + p_screen.y, p_c_g.z + p_screen.z);
    const p_s_g_3D = new THREE.Vector3(p_screen_g.x/1000, p_screen_g.z/1000, -p_screen_g.y/1000);
    this.curCameraCalibration.p_s_g_3D = [p_s_g_3D.x, p_s_g_3D.y, p_s_g_3D.z];


    // x is max 1, y is max sensor_sizey/sensor_sizex => this is normalized to 1!
    const scaleFactor = Math.min(...this.curCameraCalibration.sensor_size);
    const u_normalized = this.curCameraCalibration.sensor_size[0]/scaleFactor;
    const v_normalized = this.curCameraCalibration.sensor_size[1]/scaleFactor;

    this.videoTexture = new THREE.VideoTexture(this.media.video);
    this.videoTexture.minFilter = THREE.LinearFilter;
    this.videoTexture.magFilter = THREE.LinearFilter;
    this.videoTexture.format = THREE.RGBFormat;

    const screenGeometry = new THREE.PlaneBufferGeometry(u_normalized, v_normalized, 100, 100);   // use 100x100 grid
    const screenMaterial = new THREE.MeshBasicMaterial({
      map: this.videoTexture,
      side: THREE.FrontSide,
      opacity: 0.6,
      transparent: true,
      wireframe: false,
    });

    this.videoScreen = new THREE.Mesh(screenGeometry, screenMaterial);

    // with this we can plot debug lines in glboal and on screen to check the scaling
    // this.addDebugLines(0, this.curCameraCalibration, curScale);
    // this.addDebugLines(5, this.curCameraCalibration, curScale);
    // this.addDebugLines(0, this.curCameraCalibration, curScale);
    // this.addDebugLines(3, this.curCameraCalibration, curScale);

    const uvs = this.videoScreen.geometry.attributes.uv.array
    const pos = this.videoScreen.geometry.attributes.position.array;

    let minX = 0;
    let maxX = 0;
    let minY = 0;
    let maxY = 0;
    for (let i=0; i<pos.length;i+=3) {
      minX = Math.min(minX, pos[i]);
      maxX = Math.max(maxX, pos[i]);
      minY = Math.min(minY, pos[i+1]);
      maxY = Math.max(maxY, pos[i+1]);
    }

    for ( let i = 0, j =0; i < uvs.length; i += 2, j+=3 ) {
      // first make sure our position is always positive
      pos[j] -= minX;
      pos[j+1] -= minY;

      // image has v down, we have y up, so use 1-v (and invert once principal is subtracted below)
      ({ u, v, s_radial } = this.applyradial_distortion((pos[j])*scaleFactor,(v_normalized - pos[j+1])*scaleFactor, minX, minY, this.curCameraCalibration));

      ({ u, v } = this.normalizeUV(u, v, this.curCameraCalibration))

      pos[j] = u*curScale;
      pos[j+1] =  - v*curScale;  // this is already normalized, hence no 1-
    }

    this.videoScreen.quaternion._x = this.curCameraCalibration.q_gc_yup[0];
    this.videoScreen.quaternion._y = this.curCameraCalibration.q_gc_yup[1];
    this.videoScreen.quaternion._z = this.curCameraCalibration.q_gc_yup[2];
    this.videoScreen.quaternion._w = this.curCameraCalibration.q_gc_yup[3];

    if (this.curCameraCalibration.p_s_g_3D !== undefined) {
      this.videoScreen.position.set(this.curCameraCalibration.p_s_g_3D[0], this.curCameraCalibration.p_s_g_3D[1], this.curCameraCalibration.p_s_g_3D[2]);
    }

    this.scene.add(this.videoScreen);
    this.videoScreenEnabled = true;

    return true;
  }

  private applyradial_distortion(u: number, v: number, minU: number, minV: number, cameraCalibration: VideoDataForOverlay) {
    const scaleFactor = Math.min(...cameraCalibration.sensor_size);
    const principal_point_x = cameraCalibration.principal_point[0];
    const principal_point_y = cameraCalibration.principal_point[1];

    let s_radial: number

    // check if we have vicon_radial model or general "OpenCV" distortion model
    if (cameraCalibration.vicon_radial !== undefined && cameraCalibration.vicon_radial !== null) {
      let pu = u;
      let pv = v;

      const dp_x = (pu - principal_point_x);
      const dp_y = (cameraCalibration.aspect_ratio*(pv - principal_point_y));

      const r_sqr = dp_x * dp_x + dp_y * dp_y;
      s_radial = 1 + cameraCalibration.vicon_radial[0]*r_sqr + cameraCalibration.vicon_radial[1]*r_sqr*r_sqr
      pu = s_radial*dp_x + principal_point_x;
      pv = (s_radial*dp_y + principal_point_y)/cameraCalibration.aspect_ratio;

      u = pu;
      v = pv;
    } else {
      const k1 = cameraCalibration.radial_distortion[0];
      const k2 = cameraCalibration.radial_distortion[1];
      const k3 = cameraCalibration.radial_distortion[2];
      const t1 = cameraCalibration.tangential_distortion[0];
      const t2 = cameraCalibration.tangential_distortion[1];
      const x1 = (u - cameraCalibration.principal_point[0])/(cameraCalibration.focal_point*cameraCalibration.pixel_scale);
      const y1 = (v - cameraCalibration.principal_point[1])/((cameraCalibration.focal_point*cameraCalibration.pixel_scale/cameraCalibration.aspect_ratio));

      let r_sqr = x1*x1 + y1*y1;

      s_radial = (1+k1*r_sqr+k2*r_sqr*r_sqr+k3*r_sqr*r_sqr*r_sqr);
      const x2 = s_radial*x1 + 2*t1*x1*y1 + t2*(r_sqr + 2*x1*x1);
      const y2 = s_radial*y1 + 2*t2*x1*y1 + t1*(r_sqr + 2*y1*y1);
      u = (cameraCalibration.focal_point*cameraCalibration.pixel_scale*x2 + cameraCalibration.principal_point[0]);
      v = ((cameraCalibration.focal_point*cameraCalibration.pixel_scale/cameraCalibration.aspect_ratio)*y2 + cameraCalibration.principal_point[1]);
    }
    return {u, v, s_radial};
  }

  private applyDlt(p: THREE.Vector3, cameraCalibration: VideoDataForOverlay) {
    const dum = new THREE.Vector3(p.x, p.y, p.z);
    const dlt = new THREE.Matrix4();
    dlt.set( cameraCalibration.dlt_matrix[0][0], cameraCalibration.dlt_matrix[0][1], cameraCalibration.dlt_matrix[0][2], cameraCalibration.dlt_matrix[0][3],
      cameraCalibration.dlt_matrix[1][0], cameraCalibration.dlt_matrix[1][1], cameraCalibration.dlt_matrix[1][2], cameraCalibration.dlt_matrix[1][3],
      cameraCalibration.dlt_matrix[2][0], cameraCalibration.dlt_matrix[2][1], cameraCalibration.dlt_matrix[2][2], cameraCalibration.dlt_matrix[2][3],
      0, 0, 0, 1);

    let u: number;
    let v: number;
    dum.applyMatrix4(dlt);
    u = dum.x/dum.z;
    v = dum.y/dum.z;

    return {u, v};
  }

  private normalizeUV(u: number, v: number, cameraCalibration: VideoDataForOverlay) {
    const scaleFactor = Math.min(...cameraCalibration.sensor_size);
    const principal_point_x = cameraCalibration.principal_point[0];
    const principal_point_y = cameraCalibration.principal_point[1];

    u = (u - principal_point_x)/scaleFactor;
    v = (v - principal_point_y)/scaleFactor;

    return {u, v};
  }

  private addDebugLines(iDir: number, cameraCalibration: VideoDataForOverlay, curScale: number) {
    // 0 is xy, 1 = xz, 2 = yx, 3 = yz, 4 = zx, 5 = zy
    let p: THREE.Vector3;
    let p1: THREE.Vector3;
    let p2: THREE.Vector3;
    let p1_: THREE.Vector3;
    let p2_: THREE.Vector3;
    let pi: THREE.Vector3;


    let u_: number;
    let v_: number;
    let x_: number;
    let y_: number;
    let z_: number;
    let u: number;
    let v: number;
    let points = [];
    let line: THREE.Line;
    let geometry: THREE.BufferGeometry;

    const materialBlue = new THREE.LineBasicMaterial({
      color: 0x0000ff
    });
    const materialRed = new THREE.LineBasicMaterial({
      color: 0xff0000
    });
    const materialGreen = new THREE.LineBasicMaterial({
      color: 0x00ff00
    });
    const scaleFactor = Math.min(...cameraCalibration.sensor_size);
    const u_normalized = cameraCalibration.sensor_size[0]/scaleFactor;
    const v_normalized = cameraCalibration.sensor_size[1]/scaleFactor;

    const principal_point_x = cameraCalibration.principal_point[0];
    const principal_point_y = cameraCalibration.sensor_size[1] - cameraCalibration.principal_point[1];

    const pScreen = cameraCalibration.p_s_g_3D !== undefined ? cameraCalibration.p_s_g_3D : [0,0,0];
    const H_screen_y_up = new THREE.Matrix4();
    H_screen_y_up.compose(new THREE.Vector3(pScreen[0], pScreen[1], pScreen[2]), new THREE.Quaternion(cameraCalibration.q_gc_yup[0],cameraCalibration.q_gc_yup[1],cameraCalibration.q_gc_yup[2],cameraCalibration.q_gc_yup[3]), new THREE.Vector3(1,1,1));

    // // add fp
    // points = [];
    // points.push(new THREE.Vector3((0)/1000, (0)/1000, (-508)/1000));
    // points.push(new THREE.Vector3((464)/1000, (0)/1000, (-508)/1000));
    // points.push(new THREE.Vector3((464)/1000,(0)/1000, (0)/1000));
    // points.push(new THREE.Vector3((0)/1000, (0)/1000, (0)/1000));
    // points.push(new THREE.Vector3((0)/1000, (0)/1000, (-508)/1000));
    // geometry = new THREE.BufferGeometry().setFromPoints( points );

    // line = new THREE.Line( geometry, materialBlue );
    // this.scene.add( line );

    // points = [];
    // points.push(new THREE.Vector3(87/1000, 0, 151/1000));
    // points.push(new THREE.Vector3(551/1000, 0, 151/1000));
    // points.push(new THREE.Vector3(551/1000, 0, 659/1000));
    // points.push(new THREE.Vector3(87/1000, 0, 659/1000));
    // points.push(new THREE.Vector3(87/1000, 0, 151/1000));
    // geometry = new THREE.BufferGeometry().setFromPoints( points );
    // line = new THREE.Line( geometry, materialBlue );
    // this.scene.add( line );

    // add screen
    points = [];
    p = new THREE.Vector3( 0, 0, 0 );
    x_ = (p.x*scaleFactor - principal_point_x)/scaleFactor*curScale;
    y_ = (p.y*scaleFactor - principal_point_y)/scaleFactor*curScale;
    z_ = 0*curScale;
    p = new THREE.Vector3( x_, y_, z_);
    p.applyMatrix4(H_screen_y_up);
    points.push(p);
    p = new THREE.Vector3( u_normalized, 0, 0 );
    x_ = (p.x*scaleFactor - principal_point_x)/scaleFactor*curScale;
    y_ = (p.y*scaleFactor - principal_point_y)/scaleFactor*curScale;
    z_ = 0*curScale;
    p = new THREE.Vector3( x_, y_, z_);
    p.applyMatrix4(H_screen_y_up);
    points.push(p);
    p = new THREE.Vector3( u_normalized, v_normalized, 0);
    x_ = (p.x*scaleFactor - principal_point_x)/scaleFactor*curScale;
    y_ = (p.y*scaleFactor - principal_point_y)/scaleFactor*curScale;
    z_ = 0*curScale;
    p = new THREE.Vector3( x_, y_, z_);
    p.applyMatrix4(H_screen_y_up);
    points.push(p);
    p = new THREE.Vector3( 0, v_normalized, 0);
    x_ = (p.x*scaleFactor - principal_point_x)/scaleFactor*curScale;
    y_ = (p.y*scaleFactor - principal_point_y)/scaleFactor*curScale;
    z_ = 0*curScale;
    p = new THREE.Vector3( x_, y_, z_);
    p.applyMatrix4(H_screen_y_up);
    points.push(p);
    p = new THREE.Vector3( 0, 0, 0);
    x_ = (p.x*scaleFactor - principal_point_x)/scaleFactor*curScale;
    y_ = (p.y*scaleFactor - principal_point_y)/scaleFactor*curScale;
    z_ = 0*curScale;
    p = new THREE.Vector3( x_, y_, z_);
    p.applyMatrix4(H_screen_y_up);
    points.push(p);

    geometry = new THREE.BufferGeometry().setFromPoints( points );

    line = new THREE.Line( geometry, materialGreen );
    this.scene.add( line );

    for (let j = -2; j<3; j++) {
      // first add line in world
      // 0 is xy, 1 = xz, 2 = yx, 3 = yz, 4 = zx, 5 = zy
      if (iDir == 0) {
        p1 = new THREE.Vector3(  -v_normalized, j, 0 );
        p2 = new THREE.Vector3( v_normalized, j,  0 );
      } else if (iDir == 1) {
        p1 = new THREE.Vector3(  -v_normalized, 0, j );
        p2 = new THREE.Vector3( v_normalized, 0,  j );
      } else if (iDir == 2) {
        p1 = new THREE.Vector3( j,  -v_normalized, 0 );
        p2 = new THREE.Vector3( j, v_normalized, 0 );
      } else if (iDir == 3) {
        p1 = new THREE.Vector3( 0,  -v_normalized, j );
        p2 = new THREE.Vector3( 0, v_normalized, j );
      } else if (iDir == 4) {
        p1 = new THREE.Vector3( j, 0,  -v_normalized );
        p2 = new THREE.Vector3( j, 0, v_normalized );
      } else {
        p1 = new THREE.Vector3( 0, j, -v_normalized );
        p2 = new THREE.Vector3( 0, j, v_normalized );
      }
      points = [];
      points.push(p1);
      points.push(p2);

      geometry = new THREE.BufferGeometry().setFromPoints( points );

      line = new THREE.Line( geometry, materialBlue );
      this.scene.add( line );

      // now add line on image
      // y = -z_, z = y
      points = [];
      p1_ = new THREE.Vector3(p1.x*1000, -p1.z*1000, p1.y*1000);
      p2_ = new THREE.Vector3(p2.x*1000, -p2.z*1000, p2.y*1000);

      ({ u, v } = this.applyDlt(p1_, cameraCalibration));
      ({ u, v} = this.normalizeUV(u, v, cameraCalibration));
      pi = new THREE.Vector3( u*curScale, -v*curScale, 0);
      pi.applyMatrix4(H_screen_y_up);
      points.push(pi);

      ({ u, v } = this.applyDlt(p2_, cameraCalibration));
      ({ u, v} = this.normalizeUV(u, v, cameraCalibration));
      pi = new THREE.Vector3( u*curScale, -v*curScale, 0);
      pi.applyMatrix4(H_screen_y_up);
      points.push(pi);

      geometry = new THREE.BufferGeometry().setFromPoints( points );

      line = new THREE.Line( geometry, materialRed );
      this.scene.add( line );
    }

  }

  private plotTestPointsOnScreen(curScale: number, dist_z_from_cam_cur: number, dist_z_from_cam_new: number): boolean {
    if (this.curCameraCalibration === undefined) {
      return false;
    }

    let points: number[] = [];
    let u: number;
    let v: number;

    const dlt = new THREE.Matrix4();
    dlt.set( this.curCameraCalibration.dlt_matrix[0][0], this.curCameraCalibration.dlt_matrix[0][1], this.curCameraCalibration.dlt_matrix[0][2], this.curCameraCalibration.dlt_matrix[0][3],
      this.curCameraCalibration.dlt_matrix[1][0], this.curCameraCalibration.dlt_matrix[1][1], this.curCameraCalibration.dlt_matrix[1][2], this.curCameraCalibration.dlt_matrix[1][3],
      this.curCameraCalibration.dlt_matrix[2][0], this.curCameraCalibration.dlt_matrix[2][1], this.curCameraCalibration.dlt_matrix[2][2], this.curCameraCalibration.dlt_matrix[2][3],
      0, 0, 0, 1);

    const r_g1g = new THREE.Matrix4(); // zup to yup
    r_g1g.set(1,0,0,0,
      0,0,-1,0,
      0,1,0,0,
      0,0,0,1);
    const q_gc = new THREE.Quaternion(this.curCameraCalibration.q_gc_zup[0],this.curCameraCalibration.q_gc_zup[1],this.curCameraCalibration.q_gc_zup[2],this.curCameraCalibration.q_gc_zup[3])
    const p_c_g = new THREE.Vector3(this.curCameraCalibration.position[0], this.curCameraCalibration.position[1], this.curCameraCalibration.position[2]);
    const Hz_cam = new THREE.Matrix4();
    Hz_cam.compose(new THREE.Vector3(0,0,0), q_gc, new THREE.Vector3(1,1,1));
    const Hz_cam_inv = new THREE.Matrix4();
    Hz_cam_inv.compose(new THREE.Vector3(0,0,0), q_gc.conjugate(), new THREE.Vector3(1,1,1));


    const p_screen_cur = new THREE.Vector3( 0, 0, dist_z_from_cam_cur);
    p_screen_cur.applyMatrix4(Hz_cam);
    const p_screen_cur_g = new THREE.Vector3(p_c_g.x + p_screen_cur.x, p_c_g.y + p_screen_cur.y, p_c_g.z + p_screen_cur.z);
    const H_screen_cur_y_up = new THREE.Matrix4();
    H_screen_cur_y_up.compose(new THREE.Vector3(p_screen_cur_g.x/1000, p_screen_cur_g.z/1000, -p_screen_cur_g.y/1000), new THREE.Quaternion(this.curCameraCalibration.q_gc_yup[0], this.curCameraCalibration.q_gc_yup[1], this.curCameraCalibration.q_gc_yup[2], this.curCameraCalibration.q_gc_yup[3]), new THREE.Vector3(1,1,1));
    const p_screen_new = new THREE.Vector3( 0, 0, dist_z_from_cam_new);
    p_screen_new.applyMatrix4(Hz_cam);
    const p_screen_new_g = new THREE.Vector3(p_c_g.x + p_screen_new.x, p_c_g.y + p_screen_new.y, p_c_g.z + p_screen_new.z);
    const H_screen_new_y_up = new THREE.Matrix4();
    H_screen_new_y_up.compose(new THREE.Vector3(p_screen_new_g.x/1000, p_screen_new_g.z/1000, -p_screen_new_g.y/1000), new THREE.Quaternion(this.curCameraCalibration.q_gc_yup[0], this.curCameraCalibration.q_gc_yup[1], this.curCameraCalibration.q_gc_yup[2], this.curCameraCalibration.q_gc_yup[3]), new THREE.Vector3(1,1,1));

    const curDistTest_z = 2*this.curCameraCalibration.focal_point;
    const curDist_z_screen = curScale*this.curCameraCalibration.focal_point;
    const testScale = curDist_z_screen/curDistTest_z;

    const x_add = 500*curScale;;
    const y_add = 500*curScale;
    const pz_test_far_g = new THREE.Vector3( x_add, y_add, curDistTest_z);  // this will be rotated

    pz_test_far_g.applyMatrix4(Hz_cam);
    const p_z_test_g = new THREE.Vector3(p_c_g.x + pz_test_far_g.x, p_c_g.y + pz_test_far_g.y, p_c_g.z + pz_test_far_g.z);

    // now get this back to camera coordinates and scale with screen dist to project using ray on image
    const pz_test_ray_screen_g = new THREE.Vector3(p_c_g.x + pz_test_far_g.x*testScale, p_c_g.y + pz_test_far_g.y*testScale, p_c_g.z + pz_test_far_g.z*testScale);

    // Now we plot this in 3D and on the image
    points = [];
    points.push(new THREE.Vector3(pz_test_far_g.x/1000, pz_test_far_g.z/1000, -pz_test_far_g.y/1000));
    let dotGeometry = new THREE.BufferGeometry().setFromPoints( points);
    let dotMaterial = new THREE.PointsMaterial({ size: 0.1, color: 0x00ff00 });
    let dot = new THREE.Points(dotGeometry, dotMaterial);
    this.scene.add(dot);

    // Now we plot this in 3D and on the image
    const p_ray_on_screen_y_up = new THREE.Vector3(pz_test_ray_screen_g.x/1000, pz_test_ray_screen_g.z/1000, -pz_test_ray_screen_g.y/1000)
    points = [];
    points.push(p_ray_on_screen_y_up);
    dotGeometry = new THREE.BufferGeometry().setFromPoints( points);
    dotMaterial = new THREE.PointsMaterial({ size: 0.01, color: 0x0000ff });
    dot = new THREE.Points(dotGeometry, dotMaterial);
    this.scene.add(dot);

    let s_radial;
    ({ u, v } = this.applyDlt(p_z_test_g, this.curCameraCalibration));
    ({ u, v, s_radial } = this.applyradial_distortion(u, v, 0, 0, this.curCameraCalibration));
    ({ u, v} = this.normalizeUV(u, v, this.curCameraCalibration));

    points = []
    const p_cur = new THREE.Vector3( u*curScale, -v*curScale, 0);
    p_cur.applyMatrix4(H_screen_cur_y_up);
    points.push(p_cur);

    dotGeometry = new THREE.BufferGeometry().setFromPoints( points);
    dotMaterial = new THREE.PointsMaterial({ size: 0.01, color: 0xff0000 });
    dot = new THREE.Points(dotGeometry, dotMaterial);

    this.scene.add(dot);

    points = [];
    const p_new = new THREE.Vector3( u*curScale, -v*curScale, 0);
    p_new.applyMatrix4(H_screen_new_y_up);
    points.push(p_new);

    dotGeometry = new THREE.BufferGeometry().setFromPoints( points);
    dotMaterial = new THREE.PointsMaterial({ size: 0.005, color: 0x00ff00 });
    dot = new THREE.Points(dotGeometry, dotMaterial);

    this.scene.add(dot);

    return true
  }

  private calculateScreenDistFromCam(curScale: number): number {
    let dist_z_from_cam = curScale*this.curCameraCalibration.focal_point;
    if (this.curCameraCalibration === undefined) {
      return dist_z_from_cam;
    }

    let u: number;
    let v: number;

    const dlt = new THREE.Matrix4();
    dlt.set( this.curCameraCalibration.dlt_matrix[0][0], this.curCameraCalibration.dlt_matrix[0][1], this.curCameraCalibration.dlt_matrix[0][2], this.curCameraCalibration.dlt_matrix[0][3],
      this.curCameraCalibration.dlt_matrix[1][0], this.curCameraCalibration.dlt_matrix[1][1], this.curCameraCalibration.dlt_matrix[1][2], this.curCameraCalibration.dlt_matrix[1][3],
      this.curCameraCalibration.dlt_matrix[2][0], this.curCameraCalibration.dlt_matrix[2][1], this.curCameraCalibration.dlt_matrix[2][2], this.curCameraCalibration.dlt_matrix[2][3],
      0, 0, 0, 1);

    // now we get a point 1m from camera
    // we add half image to x and y
    const r_g1g = new THREE.Matrix4();
    r_g1g.set(1,0,0,0,
      0,0,-1,0,
      0,1,0,0,
      0,0,0,1);
    const q_gc = new THREE.Quaternion(this.curCameraCalibration.q_gc_zup[0],this.curCameraCalibration.q_gc_zup[1],this.curCameraCalibration.q_gc_zup[2],this.curCameraCalibration.q_gc_zup[3])
    const p_c_g = new THREE.Vector3(this.curCameraCalibration.position[0], this.curCameraCalibration.position[1], this.curCameraCalibration.position[2]);
    const Hz_cam = new THREE.Matrix4();
    Hz_cam.compose(new THREE.Vector3(0,0,0), q_gc, new THREE.Vector3(1,1,1));
    const Hz_cam_inv = new THREE.Matrix4();
    Hz_cam_inv.compose(new THREE.Vector3(0,0,0), q_gc.conjugate(), new THREE.Vector3(1,1,1));


    const p_screen = new THREE.Vector3( 0, 0, dist_z_from_cam);
    p_screen.applyMatrix4(Hz_cam);
    const p_screen_g = new THREE.Vector3(p_c_g.x + p_screen.x, p_c_g.y + p_screen.y, p_c_g.z + p_screen.z);
    const H_screen_y_up = new THREE.Matrix4();
    H_screen_y_up.compose(new THREE.Vector3(p_screen_g.x/1000, p_screen_g.z/1000, -p_screen_g.y/1000), new THREE.Quaternion(this.curCameraCalibration.q_gc_yup[0], this.curCameraCalibration.q_gc_yup[1], this.curCameraCalibration.q_gc_yup[2], this.curCameraCalibration.q_gc_yup[3]), new THREE.Vector3(1,1,1));

    const curDistTest_z = 2*this.curCameraCalibration.focal_point;
    const curDist_z_screen = curScale*this.curCameraCalibration.focal_point;
    const testScale = curDist_z_screen/curDistTest_z;

    const x_add = 500*curScale;;
    const y_add = 500*curScale;
    const pz_test_far_g = new THREE.Vector3( x_add, y_add, curDistTest_z);  // this will be rotated

    pz_test_far_g.applyMatrix4(Hz_cam);
    const p_z_test_g = new THREE.Vector3(p_c_g.x + pz_test_far_g.x, p_c_g.y + pz_test_far_g.y, p_c_g.z + pz_test_far_g.z);

    // now get this back to camera coordinates and sceale with screen dist to project using ray on image
    const pz_test_ray_screen_g = new THREE.Vector3(p_c_g.x + pz_test_far_g.x*testScale, p_c_g.y + pz_test_far_g.y*testScale, p_c_g.z + pz_test_far_g.z*testScale);

    // Now we plot this in 3D and on the image
    const p_ray_on_screen_y_up = new THREE.Vector3(pz_test_ray_screen_g.x/1000, pz_test_ray_screen_g.z/1000, -pz_test_ray_screen_g.y/1000)

    let s_radial;
    ({ u, v } = this.applyDlt(p_z_test_g, this.curCameraCalibration));
    ({ u, v, s_radial } = this.applyradial_distortion(u, v, 0, 0, this.curCameraCalibration));
    ({ u, v} = this.normalizeUV(u, v, this.curCameraCalibration));
    const pi = new THREE.Vector3( u*curScale, -v*curScale, 0);
    pi.applyMatrix4(H_screen_y_up);
    const p_z_test_projected = pi.clone();

    const p_dlt_z_up = new THREE.Vector3(p_z_test_projected.x*1000 - p_c_g.x, -p_z_test_projected.z*1000 - p_c_g.y, p_z_test_projected.y*1000 - p_c_g.z)
    p_dlt_z_up.applyMatrix4(Hz_cam_inv);

    const p_ray_z_up = new THREE.Vector3(p_ray_on_screen_y_up.x*1000 - p_c_g.x, -p_ray_on_screen_y_up.z*1000 - p_c_g.y, p_ray_on_screen_y_up.y*1000 - p_c_g.z)
    p_ray_z_up.applyMatrix4(Hz_cam_inv);

    pz_test_far_g.applyMatrix4(Hz_cam_inv);

    dist_z_from_cam = pz_test_far_g.z/pz_test_far_g.x*p_dlt_z_up.x;

    // we allow for max 20% now
    if (Math.abs(1 - dist_z_from_cam/curDist_z_screen) > 0.2) {
      console.error('Scale calculation failed, will not apply new scale')
      dist_z_from_cam = curDist_z_screen;
    }
    return dist_z_from_cam;
  }
}
