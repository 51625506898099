<div *ngIf="errorCode" [ngSwitch]="errorCode" class="error-image">
  <p *ngSwitchCase="errors.Unauthorized" class="p-3">
  <i class="fa fa-lock"></i> You are not authorized to view this motion.
  </p>
  <p *ngSwitchCase="errors.Unavailable" class="p-3">
  <i class="fa fa-chain-broken"></i> The requested motion is no longer available.
  </p>
  <div *ngIf="includeLink">
    <a href="/home" target="_blank">
      <img id="home-link" src="/assets/logo_color.svg">
    </a>
  </div>
  <img src="assets/preview_xl.jpg"/>
</div>
