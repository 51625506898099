export enum NoraxonConfiguration {
    None,
    NoraxonLowerBody, //xx markers
    NoraxonFullBody //xx markers
}

export class Noraxon {

    markerList: any[];

    readonly chest = [ "Sternum xiphoid process", "7th cervical vertebrae", "Jugular notch", "10th thoracic vertebrae" ];

    readonly upperLimbLeft = ["Shoulder LT", "Elbow LT", "Ulnar styloid process LT", "Radial styloid process LT" ];
    readonly upperLimbRight = ["Shoulder RT", "Elbow RT", "Ulnar styloid process RT", "Radial styloid process RT"];

    readonly lowerLimbLeft = ["Hip LT", "Knee LT", "Ankle LT", "1st metatarsophalangeal joint LT", "5th metatarsophalangeal joint LT", "Foot toe LT"];
    readonly lowerLimbRight = ["Hip RT", "Knee RT", "Ankle RT", "1st metatarsophalangeal joint RT", "5th metatarsophalangeal joint RT", "Foot toe RT"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];

        segments.push(  { name: "torso", markerList:  ["Sternum xiphoid process", "7th cervical vertebrae", "Jugular notch", "10th thoracic vertebrae"], allConnected: true});

        segments.push(  { name: "leftUpArm", markerList:  ["Shoulder LT", "Elbow LT"], side: 1 });
        segments.push(  { name: "rightUpArm", markerList: ["Shoulder RT", "Elbow RT"], side: 2 });
        segments.push(  { name: "leftArm", markerList: [ "Elbow LT", "Ulnar styloid process LT", "Radial styloid process LT"], allConnected: true, side: 1 });
        segments.push(  { name: "rightArm", markerList: [ "Elbow RT", "Ulnar styloid process RT", "Radial styloid process RT"], allConnected: true, side: 2 });

        segments.push(  { name: "leftLeg", markerList: ["Hip LT", "Knee LT", "Ankle LT"], side: 1 });
        segments.push(  { name: "rightLeg", markerList: ["Hip RT", "Knee RT", "Ankle RT"], side: 2 });

        segments.push(  { name: "leftFoot1", markerList: ["1st metatarsophalangeal joint LT", "5th metatarsophalangeal joint LT", "Foot toe LT"], allConnected: true, side: 1 });
        segments.push(  { name: "leftFoot2", markerList: ["Ankle LT", "1st metatarsophalangeal joint LT", "5th metatarsophalangeal joint LT"], allConnected: true, side: 1 });
        segments.push(  { name: "rightFoot1", markerList: ["1st metatarsophalangeal joint RT", "5th metatarsophalangeal joint RT", "Foot toe RT"], allConnected: true, side: 2 });
        segments.push(  { name: "rightFoot2", markerList: ["Ankle RT", "1st metatarsophalangeal joint RT", "5th metatarsophalangeal joint RT"], allConnected: true, side: 2 });
        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];

        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      findConfiguration() {

        const fullBody = [
            this.chest,
            this.upperLimbLeft,
            this.upperLimbRight,
            this.lowerLimbLeft,
            this.lowerLimbRight
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return NoraxonConfiguration.NoraxonFullBody;
        }

        const lowerBody = [
            this.lowerLimbLeft,
            this.lowerLimbRight
        ];

        if (this.checkConfiguration(lowerBody).length <= 1) {
            return NoraxonConfiguration.NoraxonLowerBody;
        }

        return NoraxonConfiguration.None;
      }

}
