import { TemplateDefinition, TemplatesConfiguration } from '../charts-templates.service';

export const templatesConfiguration: TemplatesConfiguration = {
  kinematicsCharts: [
    {
      name: "Trunk",
      charts: [{
          title: 'Trunk tilt',
          hideChart: false,
          id: 'Trunk tilt',
          vLimits: [-30, 30],
          vAxis: 'Post   /   Ant' + '   [ \u00B0 ]',
          names: ['trunktil']
        },
        {
          title: 'Trunk lateroflexion',
          hideChart: false,
          id: 'Trunk flexion',
          vLimits: [-30, 30],
          vAxis: 'Left   /   Right' + '   [ \u00B0 ]',
          names: ['trunkflex']
        },
        {
          title: 'Trunk rotation',
          hideChart: false,
          id: 'Trunk rotation',
          vLimits: [-30, 30],
          vAxis: ' Back   /   For' + '   [\u00B0 ]',
          names: ['trunkrot']
        }
      ]
    },
    {
      name: "Pelvis",
      charts: [{
          title: 'Pelvic tilt',
          hideChart: false,
          id: 'Pelvic tilt',
          vLimits: [-30, 30],
          vAxis: 'Post   /   Ant' + '   [ \u00B0 ]',
          names: ['pelvictil', 'pelvisangles-x', 'pelvisangle-x'],
        },
        {
          title: 'Pelvic obliquity',
          hideChart: false,
          id: 'Pelvic obliquity',
          vLimits: [-30, 30],
          vAxis: 'Down   /   Up' + '   [ \u00B0 ]',
          names: ['pelvicobl', 'pelvisangles-y', 'pelvisangle-y'],
        },
        {
          title: 'Pelvic rotation',
          hideChart: false,
          id: 'Pelvic rotation',
          vLimits: [-50, 30],
          vAxis: ' Back   /   For' + '   [\u00B0 ]',
          names: ['pelvicrot', 'pelvisangles-z', 'pelvisangle-z'],
        }
      ]
    },
    {
      name: "Hip",
      charts: [{
          title: 'Hip flexion',
          hideChart: false,
          id: 'Hip flexion',
          vLimits: [-25, 75],
          vAxis: 'Extension   /   Flexion' + '   [ \u00B0 ]',
          names: ['hipangles-x', 'hangle-x', 'hipflex']
        },
        {
          title: 'Hip adduction',
          hideChart: false,
          id: 'Hip adduction',
          vLimits: [-30, 30],
          vAxis: 'Abd   /   adduction' + '   [ \u00B0 ]',
          names: ['hipangles-y', 'hangle-y', 'hipabad']
        },
        {
          title: 'Hip rotation',
          hideChart: false,
          id: 'Hip rotation',
          vLimits: [-30, 30],
          vAxis: ' External   /   internal' + '   [\u00B0 ]',
          names: ['hipangles-z', 'hangle-z', 'hiprot']
        }
      ]
    },
    {
      name: "Knee",
      charts: [{
          title: 'Knee flexion',
          hideChart: false,
          id: 'Knee flexion',
          vLimits: [-5, 95],
          vAxis: 'Extension   /   Flexion' + '   [ \u00B0 ]',
          names: ['kneeangles-x', 'kangle-x', 'kneeflex']
        },
        {
          title: 'Knee adduction',
          hideChart: false,
          id: 'Knee adduction',
          vLimits: [-30, 30],
          vAxis: 'Abd   /   adduction' + '   [ \u00B0 ]',
          names: ['kneeangles-y', 'kangle-y', 'kneeabad']
        },
        {
          title: 'Knee rotation',
          hideChart: false,
          id: 'Knee rotation',
          vLimits: [-30, 30],
          vAxis: ' External   /   internal' + '   [\u00B0 ]',
          names: ['kneeangles-z', 'kangle-z', 'kneerot']
        }
      ]
    },
    {
      name: "Ankle",
      charts: [{
          title: 'Ankle dorsiflexion',
          hideChart: false,
          id: 'Ankle dorsiflexion',
          vLimits: [-50, 50],
          vAxis: 'Extension   /   Flexion' + '   [ \u00B0 ]',
          names: ['ankleangles-x', 'aangle-x', 'ankleflex']
        },
        {
          title: 'Ankle pro/supination',
          hideChart: false,
          id: 'Ankle pro/supination',
          vLimits: [-30, 30],
          vAxis: 'Abd   /   adduction' + '   [ \u00B0 ]',
          names: ['ankleangles-y', 'aangle-y', 'anklepron']
        },
        {
          title: 'Ankle rotation',
          hideChart: false,
          id: 'Ankle rotation',
          vLimits: [-30, 30],
          vAxis: ' External   /   internal' + '   [\u00B0 ]',
          names: ['ankleangles-z', 'aangle-z', 'anklerot']
        }
      ]
    },
    {
      name: "Foot",
      charts: [{
        title: 'Foot progression - Z',
        hideChart: false,
        id: 'Foot progression - Z',
        vLimits: [-30, 30],
        vAxis: ' Degrees' + '   [\u00B0 ]',
        names: ['footprogressangles-z']
      }]
    }
  ],
  momentsCharts: [
    {
      name: "Hip",
      charts: [{
          title: 'Hip flexion',
          hideChart: false,
          id: 'Hip flexion',
          names: ['hipmoment-x', 'hmoment-x', 'hipflex'],
        },
        {
          title: 'Hip adduction',
          hideChart: false,
          id: 'Hip adduction',
          names: ['hipmoment-y', 'hmoment-y', 'hipabad'],
        },
        {
          title: 'Hip rotation',
          hideChart: false,
          id: 'Hip rotation',
          names: ['hipmoment-z', 'hmoment-z', 'hiprot'],
        },
      ]
    },
    {
      name: "Knee",
      charts: [{
          title: 'Knee flexion',
          hideChart: false,
          id: 'Knee flexion',
          names: ['kneemoment-x', 'kmoment-x', 'kneeflex'],
        },
        {
          title: 'Knee adduction',
          hideChart: false,
          id: 'Knee adduction',
          names: ['kneemoment-y', 'kmoment-y', 'kneeabad'],
        },
        {
          title: 'Knee rotation',
          hideChart: false,
          id: 'Knee rotation',
          names: ['kneemoment-z', 'kmoment-z', 'kneerot'],
        },
      ]
    },
    {
      name: "Ankle",
      charts: [{
          title: 'Ankle flexion',
          hideChart: false,
          id: 'Ankle flexion',
          names: ['anklemoment-x', 'amoment-x', 'ankleflex'],
        },
        {
          title: 'Ankle pronation',
          hideChart: false,
          id: 'Ankle pronation',
          names: ['anklemoment-y', 'amoment-y', 'anklepron'],
        },
        {
          title: 'Ankle rotation',
          hideChart: false,
          id: 'Ankle rotation',
          names: ['anklemoment-z', 'amoment-z', 'anklerot'],
        },
      ]
    }
  ],
  powersCharts: [
    {
      name: "Powers",
      charts: [{
          title: 'Hip',
          hideChart: false,
          id: 'Hip',
          names: ['hippower-z', 'hpower-z', 'hipflex'],
        },
        {
          title: 'Knee',
          hideChart: false,
          id: 'Knee',
          names: ['kneepower-z', 'kpower-z', 'kneeflex'],
        },
        {
          title: 'Ankle',
          hideChart: false,
          id: 'Ankle',
          names: ['anklepower-z', 'apower-z', 'ankleflex'],
        },
      ]
    }
  ],
  muscleLengthsVelocitiesCharts: [
    {
      name: "Muscle lengths",
      charts: [
        {
          title: 'Gluteus Maximus',
          hideChart: false,
          id: 'Gluteus Maximus Length',
          names: ['gluteusmaxlength'],
        },
        {
          title: 'Iliopsoas',
          hideChart: false,
          id: 'Iliopsoas Length',
          names: ['iliopsoaslength'],
        },{
          title: 'Rectus Femoris',
          hideChart: false,
          id: 'Rectus Femoris Length',
          names: ['rectfemlength'],
        },{
          title: 'Medial Hamstrings',
          hideChart: false,
          id: 'Medial Hamstrings Length',
          names: ['medhamstringlength'],
        },{
          title: 'Lateral Hamstrings',
          hideChart: false,
          id: 'Lateral Hamstrings Length',
          names: ['lathamstringlength'],
        },{
          title: 'Gastrocnemius',
          hideChart: false,
          id: 'Gastrocnemius Length',
          names: ['gastroclength'],
        },{
          title: 'Soleus',
          hideChart: false,
          id: 'Soleus',
          names: ['soleuslength'],
        },{
          title: 'Tibialis Posterior',
          hideChart: false,
          id: 'Tibialis Posterior Length',
          names: ['tibpostlength'],
        },{
          title: 'Peroneus',
          hideChart: false,
          id: 'Peroneus Length',
          names: ['peroneallength'],
        },{
          title: 'Vastus Lateralis',
          hideChart: false,
          id: 'Vastus Lateralis Length',
          names: ['vastlatlength'],
        }
      ]
    },
    {
      name: "Muscle velocities",
      charts: [
        {
          title: 'Gluteus Maximus',
          hideChart: false,
          id: 'Gluteus Maximus Velocities',
          names: ['gluteusmaxvel'],
        },
        {
          title: 'Iliopsoas',
          hideChart: false,
          id: 'Iliopsoas Velocities',
          names: ['iliopsoasvel'],
        },{
          title: 'Rectus Femoris',
          hideChart: false,
          id: 'Rectus Femoris Velocities',
          names: ['rectfemvel'],
        },{
          title: 'Medial Hamstrings',
          hideChart: false,
          id: 'Medial Hamstrings Velocities',
          names: ['medhamstringvel'],
        },{
          title: 'Lateral Hamstrings',
          hideChart: false,
          id: 'Lateral Hamstrings Velocities',
          names: ['lathamstringvel'],
        },{
          title: 'Gastrocnemius',
          hideChart: false,
          id: 'Gastrocnemius Velocities',
          names: ['gastrocvel'],
        },{
          title: 'Soleus',
          hideChart: false,
          id: 'Soleus',
          names: ['soleusvel'],
        },{
          title: 'Tibialis Posterior',
          hideChart: false,
          id: 'Tibialis Posterior Velocities',
          names: ['tibpostvel'],
        },{
          title: 'Peroneus',
          hideChart: false,
          id: 'Peroneus Velocities',
          names: ['peronealvel'],
        },{
          title: 'Vastus Lateralis',
          hideChart: false,
          id: 'Vastus Lateralis Velocities',
          names: ['vastlatvel'],
        }
      ]
    }
  ],
  forcesCharts: [
    {
      name: "Forces",
      charts: [{
          title: 'Left',
          hideChart: false,
          id: 'Left',
          names: ['left'],
        },
        {
          title: 'Right',
          hideChart: false,
          id: 'Right',
          names: ['right'],
        },
      ]
    }
  ],
  emgsCharts: [
    {
      name: "Gastrocnemius Medialis",
      charts: [{
        title: 'Gastrocnemius Medialis Left',
        hideChart: false,
        id: 'Gastrocnemius Medialis Left',
        names: ['l_gm', ' lgm', 'gm_l'],
        },
        {
          title: 'Gastrocnemius Medialis Right',
          hideChart: false,
          id: 'Gastrocnemius Medialis Right',
          names: ['r_gm', ' rgm', 'gm_r'],
        },
      ]
    },
    {
      name: "Gastrocnemius Lateralis",
      charts: [{
        title: 'Gastrocnemius Lateralis Left',
        hideChart: false,
        id: 'Gastrocnemius Lateralis Left',
        names: ['l_gl', ' lgl', 'gl_l'],
        },
        {
          title: 'Gastrocnemius Lateralis Right',
          hideChart: false,
          id: 'Gastrocnemius Lateralis Right',
          names: ['r_gl', ' rgl', 'gl_r'],
        },
      ]
    },
    {
      name: "Soleus",
      charts: [{
        title: 'Soleus Left',
        hideChart: false,
        id: 'Soleus Left',
        names: ['l_sl', ' lsl', 'sl_l'],
        },
        {
          title: 'Soleus Right',
          hideChart: false,
          id: 'Soleus Right',
          names: ['r_sl', ' rsl', 'sl_r'],
        },
      ]
    },
    {
      name: "SO",
      charts: [{
        title: 'SO left',
        hideChart: false,
        id: 'SO left',
        names: ['l_so', ' lso', 'so_l'],
        },
        {
          title: 'SO right',
          hideChart: false,
          id: 'SO right',
          names: ['r_so', ' rso', 'so_r'],
        },
      ]
    },
    {
      name: "Tibialis Anterior",
      charts: [{
        title: 'Tibialis Anterior Left',
        hideChart: false,
        id: 'Tibialis Anterior Left',
        names: ['l_ta', ' lta', 'ta_l'],
        },
        {
          title: 'Tibialis Anterior Right',
          hideChart: false,
          id: 'Tibialis Anterior Right',
          names: ['r_ta', ' rta', 'ta_r'],
        },
      ]
    },
    {
      name: "Tibialis Posterior",
      charts: [{
        title: 'Tibialis Posterior Left',
        hideChart: false,
        id: 'Tibialis Posterior Left',
        names: ['l_tp', ' ltp', 'tp_l'],
        },
        {
          title: 'Tibialis Posterior Right',
          hideChart: false,
          id: 'Tibialis Posterior Right',
          names: ['r_tp', ' rtp', 'tp_r'],
        },
      ]
    },
    {
      name: "Vastus Medialis",
      charts: [{
        title: 'Vastus Medialis Left',
        hideChart: false,
        id: 'Vastus Medialis Left',
        names: ['l_vm', ' lvm', 'vm_l'],
        },
        {
          title: 'Vastus Medialis Right',
          hideChart: false,
          id: 'Vastus Medialis Right',
          names: ['r_vm', ' rvm', 'vm_r'],
        },
      ]
    },
    {
      name: "Vastus Lateralis",
      charts: [{
        title: 'Vastus Lateralis Left',
        hideChart: false,
        id: 'Vastus Lateralis Left',
        names: ['l_vl', ' lvl', 'vl_l'],
        },
        {
          title: 'Vastus Lateralis Right',
          hideChart: false,
          id: 'Vastus Lateralis Right',
          names: ['r_vl', ' rvl', 'vl_r'],
        },
      ]
    },
    {
      name: "Rectus Femoris",
      charts: [{
        title: 'Rectus Femoris Left',
        hideChart: false,
        id: 'Rectus Femoris Left',
        names: ['l_rf', ' lrf', 'rf_l'],
        },
        {
          title: 'Rectus Femoris Right',
          hideChart: false,
          id: 'Rectus Femoris Right',
          names: ['r_rf', ' rrf', 'rf_r'],
        },
      ]
    },
    {
      name: "Semitendinosus",
      charts: [{
        title: 'Semitendinosus Left',
        hideChart: false,
        id: 'Semitendinosus Left',
        names: ['l_st', ' lst', 'st_l'],
        },
        {
          title: 'Semitendinosus Right',
          hideChart: false,
          id: 'Semitendinosus Right',
          names: ['r_st', ' rst', 'st_r'],
        },
      ]
    },
    {
      name: "Medial Hamstrings",
      charts: [{
        title: 'Medial Hamstrings Left',
        hideChart: false,
        id: 'Medial Hamstrings Left',
        names: ['l_hm', ' lhm', 'hm_l'],
        },
        {
          title: 'Medial Hamstrings Right',
          hideChart: false,
          id: 'Medial Hamstrings Right',
          names: ['r_hm', ' rhm', 'hm_r'],
        },
      ]
    },
    {
      name: "Adductor Longus",
      charts: [{
        title: 'Adductor Longus Left',
        hideChart: false,
        id: 'Adductor Longus Left',
        names: ['l_ad', ' lad', 'ad_l'],
        },
        {
          title: 'Adductor Longus Right',
          hideChart: false,
          id: 'Adductor Longus Right',
          names: ['r_ad', ' rad', 'ad_r'],
        },
      ]
    },
    {
      name: "Gluteus Maximus",
      charts: [{
        title: 'Gluteus Maximus Left',
        hideChart: false,
        id: 'Gluteus Maximus Left',
        names: ['l_ga', ' lga', 'ga_l'],
        },
        {
          title: 'Gluteus Maximus Right',
          hideChart: false,
          id: 'Gluteus Maximus Right',
          names: ['r_ga', ' rga', 'ga_r'],
        },
      ]
    },
    {
      name: "Gluteus Medius",
      charts: [{
        title: 'Gluteus Medius Left',
        hideChart: false,
        id: 'Gluteus Medius Left',
        names: ['l_ge', ' lge', 'ge_l'],
        },
        {
          title: 'Gluteus Medius Right',
          hideChart: false,
          id: 'Gluteus Medius Right',
          names: ['r_ge', ' rge', 'ge_r'],
        },
      ]
    },
    {
      name: "Peroneus Brevis",
      charts: [{
        title: 'Peroneus Brevis Left',
        hideChart: false,
        id: 'Peroneus Brevis Left',
        names: ['l_pb', ' lpb', 'pb_l'],
        },
        {
          title: 'Peroneus Brevis Right',
          hideChart: false,
          id: 'Peroneus Brevis Right',
          names: ['r_pb', ' rpb', 'pb_r'],
        },
      ]
    },
    {
      name: "Peroneus Longus",
      charts: [{
        title: 'Peroneus Longus Left',
        hideChart: false,
        id: 'Peroneus Longus Left',
        names: ['l_pl', ' lpl', 'pl_l'],
        },
        {
          title: 'Peroneus Longus Right',
          hideChart: false,
          id: 'Peroneus Longus Right',
          names: ['r_pl', ' rpl', 'pl_r'],
        },
      ]
    },
    {
      name: "Extensor Digitorum",
      charts: [{
        title: 'Extensor Digitorum Left',
        hideChart: false,
        id: 'Extensor Digitorum Left',
        names: ['l_ed', ' led', 'ed_l'],
        },
        {
          title: 'Extensor Digitorum Right',
          hideChart: false,
          id: 'Extensor Digitorum Right',
          names: ['r_ed', ' red', 'ed_r'],
        },
      ]
    }
  ],
  emgsConsistencyCharts: [
    {
      name: 'EMG consistency',
      charts: [
        {
          title: 'EMG consistency - left',
          hideChart: false,
          id: 'EMG consistency - left',
          names: [],
        },
        {
          title: 'EMG consistency - right',
          hideChart: false,
          id: 'EMG consistency - right',
          names: [],
        },
        {
          title: 'EMG consistency - noContext',
          hideChart: false,
          id: 'EMG consistency - noContext',
          names: [],
        }
      ]
    }
  ],
  trajectoriesCharts: [],
  combinedCharts: []
};

export const defaultTemplate: TemplateDefinition = {
  metadata: {
    label: 'default',
    id: 'default',
    version: 1,
  },
  configuration: templatesConfiguration,
};
