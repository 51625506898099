import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

type Pixel = number;
type Color = string;
export type ReferenceBar = {
  point1: ReferenceCoordinate;
  point2: ReferenceCoordinate;
  visible: boolean;
  color: Color;
};

export type ReferenceCoordinate = {
  x: number,
  y?: number
}

@Component({
  selector: 'app-reference-canvas',
  standalone: true,
  templateUrl: './reference-canvas.component.html',
  styleUrls: ['./reference-canvas.component.scss']
})
export class ReferenceCanvasComponent implements AfterViewInit {

  @ViewChild('reference_canvas', { static: true }) canvasRef: ElementRef;

  @Input() public set referenceBars(referenceBarsArray: ReferenceBar[]) {
    this.drawReferenceBars(referenceBarsArray);
  }
  @Input() public height: Pixel;
  @Input() public width: Pixel;
  @Input() public top: Pixel;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private setServiceAsReady: () => void;
  private serviceToBeReady: Promise<void> = new Promise<void>(resolve => this.setServiceAsReady = resolve);

  public ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext('2d');

    this.setServiceAsReady();
  }

  private async drawReferenceBars(referenceBarsArray: ReferenceBar[]): Promise<void> {
    await this.serviceToBeReady;

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    for (const referenceBar of referenceBarsArray) {
      this.drawReferenceBar(referenceBar);
    }
   }

  private drawReferenceBar(referenceBar: ReferenceBar): void {
    if (referenceBar.visible === false) {
      return;
    }
    const x1 = referenceBar.point1.x;
    const x2 = referenceBar.point2.x;

    this.context.save();
    this.context.beginPath();
    const linewidth = this.context.lineWidth = 1;
    this.context.strokeStyle = referenceBar.color;

    //we draw the reference bar using 5% of the total height of the chart, from the bottom line
    const y = this.canvas.height;
    const dy = this.canvas.height / 20;
    this.context.rect(x1+(linewidth/2), y, x2-x1-(linewidth/2), -dy);
    this.context.fillStyle = referenceBar.color;
    this.context.fill();
    this.context.stroke();
    this.context.restore();
  }

}
