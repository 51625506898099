import { HttpClient } from '@angular/common/http';
import * as THREE from 'three';
import { CoordinatesType } from './clip-player';


const MAX_FREQ_HZ = 200;

export interface Vector3 {
    x: number,
    y: number,
    z: number
}

export interface Vector4 {
    time: number,
    x: number,
    y: number,
    z: number
}

export interface ForceData {
    dataUrl: string,
    arrowColor?: string,
    coordinates?: CoordinatesType,
    forceScale?: number,
    coordinatesScale?: number,
    zUp?: boolean,
    axisEnable?: boolean[],
    plateOptions?: { color: string, size?: Vector3, position?: Vector3, corners?: any},
    labels?: {
        position?: { time: string, x: string, y: string, z: string},
        force?: { time: string, x: string, y: string, z: string}
    }
}
export class ForcePlateRenderer {

    data: ForceData;

    arrowHelper: any;
    forcePlateGroup: any;
    trailGroup: any;
    forcePlate: any;
    dataScale: number = 1;

    time: number = 0;
    dataIndex: number = 0;
    dataReady: boolean = false;

    visibilityState = 0;

    force: {
        source: Vector4[],
        value: Vector4[],
        axisEnable: boolean []
    }

    readonly thickness: number = 0.03;

    constructor() {

    }

    setData( data: any, options: any ) {
        if (options.corners) {
            options.plateOptions = { corners: options.corners };
            options.position = { x: 0, y: 0, z: 0};
        }

        this.initForcePlate(options);

        this.fillForceData(data, options);
        this.dataReady = true;
    }

    loadData(http: HttpClient, data: ForceData) {
        this.initForcePlate(data);

        http.get(this.data.dataUrl)
        .subscribe(res => {
            const parsedData = (res || { } ) as any;
            this.fillForceData(parsedData, parsedData.plateOptions);
            this.dataReady = true;
        });
    }

    initForcePlate(data?: ForceData) {
        this.createOptions(data);
        const dir = new THREE.Vector3( 0, 1, 0 );

        const geometry = new THREE.BoxGeometry( this.data.plateOptions.size.x, this.data.plateOptions.size.y, this.data.plateOptions.size.z);
        const position = this.data.plateOptions.position;

        const material = new THREE.MeshPhongMaterial( {color: this.data.plateOptions.color, side: THREE.DoubleSide } );
        this.forcePlate = new THREE.Mesh( geometry, material );

        const origin = new THREE.Vector3( position.x, position.y, position.z );
        const length = 1;
        const hex = this.data.arrowColor;

        if (this.data.plateOptions.corners) {
            const corners = this.data.plateOptions.corners;
            const order = [0, 1, 3, 2]; //made up sequence to make vertex sensible with threejs box geometry

            if (this.data.plateOptions.size.y < this.data.plateOptions.size.z) {
                //this.data.coordinatesScale = 1; //FIXME: hack for TDF, we should read this option
                dir.set(0, 1, 0); //this is actually an assumption for now
                for (let i=0; i< corners.length; i++) {
                    geometry.vertices[i*2].x = corners[order[i]].x * this.data.coordinatesScale;
                    geometry.vertices[i*2].y = i >= 2 ? -this.thickness/2 : this.thickness/2;
                    geometry.vertices[i*2].z = corners[order[i]].z * this.data.coordinatesScale;
                    geometry.vertices[i*2+1].x = corners[order[i]].x * this.data.coordinatesScale;
                    geometry.vertices[i*2+1].y = i >= 2 ? this.thickness/2 : -this.thickness/2;
                    geometry.vertices[i*2+1].z = corners[order[i]].z * this.data.coordinatesScale;
                }
            } else if (this.data.plateOptions.size.z < this.data.plateOptions.size.y) {
                dir.set(0, 0, 1);
                for (let i=0; i< corners.length; i++) {
                    geometry.vertices[i*2].x = corners[order[i]].x * this.data.coordinatesScale;
                    geometry.vertices[i*2].y = corners[order[i]].y * this.data.coordinatesScale;
                    geometry.vertices[i*2].z = i >= 2 ? -this.thickness/2 : this.thickness/2;
                    geometry.vertices[i*2+1].x = corners[order[i]].x * this.data.coordinatesScale;
                    geometry.vertices[i*2+1].y = corners[order[i]].y * this.data.coordinatesScale;
                    geometry.vertices[i*2+1].z = i >= 2 ? this.thickness/2 : -this.thickness/2;
                }
            }
            geometry.verticesNeedUpdate = true;
        } else {
            if (this.data.plateOptions.size.x < this.data.plateOptions.size.y)
                dir.set(1, 0, 0);

            if (this.data.plateOptions.size.z < this.data.plateOptions.size.y)
                dir.set(0, 0, 1);

            this.forcePlate.position.set(position.x, position.y, position.z);
        }

        // dir.normalize(); //already normalized
        this.arrowHelper = new THREE.ArrowHelper( dir, origin, length, hex );
        this.forcePlateGroup = new THREE.Group();
        this.trailGroup = new THREE.Group();
        this.trailGroup.visible = false;

        this.forcePlateGroup.add(this.forcePlate);
        this.forcePlateGroup.add(this.trailGroup);
        this.forcePlateGroup.add(this.arrowHelper);
    }

    fillForceData(data: any, options?: any) {
        let skip = 1;
        const avgNo = 50;
        let time = 0;

        if (data && data.length > avgNo) {
            const end = data[avgNo][this.data.labels.force.time];
            const start = data[1][this.data.labels.force.time];
            const freq = avgNo/(end - start);
            const decimate = Math.ceil(freq / MAX_FREQ_HZ);
            if (!isNaN(decimate) && decimate > 0)
                skip = decimate;
        }

        for (let i=0; i<data.length; i = i+skip) {
            const forceValue = {time: data[i][this.data.labels.force.time],
                x: data[i][this.data.labels.force.x]*this.data.forceScale,
                y: data[i][this.data.labels.force.y]*this.data.forceScale,
                z: data[i][this.data.labels.force.z]*this.data.forceScale };

            this.force.value.push(forceValue);

            const forceSource = {time: data[i][this.data.labels.position.time],
                x: data[i][this.data.labels.position.x]*this.data.coordinatesScale,
                y: data[i][this.data.labels.position.y]*this.data.coordinatesScale,
                z: data[i][this.data.labels.position.z]*this.data.coordinatesScale };

            this.force.source.push(forceSource);
            if (  data[i][this.data.labels.force.time] > time + 0.001) {
                const source = {position: {x: forceSource.x, y: forceSource.y, z: forceSource.z}};
                const target = {position: {x: forceSource.x + forceValue.x, y: forceSource.y + forceValue.y, z: forceSource.z + forceValue.z}};
                const seg = this.createSegment(source, target, 0xaaaaaa);
                this.trailGroup.add(seg);
                time = data[i][this.data.labels.force.time];
            }
        }

        if (!options || !options.position) {
            const position = {x: this.force.source[0].x, y: this.force.source[0].y, z: this.force.source[0].z};
            this.forcePlate.position.set(position.x, position.y, position.z);
        }
    }

    public createSegment(source, target, color) {

        const geometry = new THREE.Geometry();
        geometry.dynamic = true;
        geometry.vertices.push(source.position);
        geometry.vertices.push(target.position);
        geometry.verticesNeedUpdate = true;

        const material = new THREE.LineBasicMaterial({ color: color, transparent: true, opacity: 0.3, linewidth: 0.3 });
        const line = new THREE.Line( geometry, material );
        line.castShadow = false;
        line.frustumCulled = false;

        return line;
    }


    public createOptions(data?: ForceData) {
        this.data = {
            dataUrl: '',
            arrowColor: '#ff7300',
            forceScale: 0.0015,
            coordinatesScale: 0.001,
            zUp: false
        };

        for (const p in data) {
            this.data[p] = data[p];
        }

        this.data.plateOptions = {
            color: '#aaaaaa',
            size: this.data.zUp ? { x: 0.5, y: 0.5, z: this.thickness} : { x: 0.5, y: this.thickness, z: 0.5},
            position: {x: 0, y: 0, z: 0}
        };

        if (data) {
            for (const p in data.plateOptions) {
                this.data.plateOptions[p] = data.plateOptions[p];
            }
        }

        this.data.labels = { position: {
                time: 'time',
                x: 'x',
                y: 'y',
                z: 'z'
            },
            force: {
                time: 'time',
                x: 'Fx',
                y: 'Fy',
                z: 'Fz'
            }
        };

        if (data) {
            for (const p in data.labels) {
                this.data.labels[p] = data.labels[p];
            }
        }

        this.force = {
            source: [],
            value: [],
            axisEnable: [true, true, true]
        };

        if (data && data.axisEnable) {
            for (let i = 0; i< data.axisEnable.length; i++) {
                this.force.axisEnable[i] = data.axisEnable[i];
            }
        }
    }


    public toggleVisibility(state?: number) {

        if (state != undefined)
            this.visibilityState = state;
        else
            this.visibilityState++;

        if (this.visibilityState > 2)
            this.visibilityState = 0;

        if (!this.forcePlateGroup)
            return;

        switch (this.visibilityState) {
            case 0:
                this.forcePlateGroup.visible = false;
                this.trailGroup.visible = false;
                break;
            case 1:
                this.forcePlateGroup.visible = true;
                this.trailGroup.visible = false;
                break;
            case 2:
                this.forcePlateGroup.visible = true;
                this.trailGroup.visible = true;
                break;
        }
    }

    public setDataScale(scale: number) {
        this.dataScale = scale;
    }

    public getGroup() {
        return this.forcePlateGroup;
    }

    public update(time) {
        if (this.arrowHelper && this.dataReady) {

            const source = this.force.source;
            const values = this.force.value;
            const axis = this.force.axisEnable;

            while ((this.dataIndex + 1) < values.length && values[this.dataIndex + 1].time < time) {
              this.dataIndex++;
            }

            const dataSample = {x: axis[0] ? values[this.dataIndex].x : 0, y: axis[1] ? values[this.dataIndex].y : 0, z: axis[2] ? values[this.dataIndex].z : 0};
            //this should be linearly interpolated, the animation will do it for us
            const dir = new THREE.Vector3(dataSample.x, dataSample.y, dataSample.z);
            const length = dir.length();
            //normalize the direction vector (convert to vector of length 1)
            const eps = 0.0001;
            if (length > eps) {
                dir.normalize();
                this.arrowHelper.position.set(source[this.dataIndex].x, source[this.dataIndex].y, source[this.dataIndex].z);
                this.arrowHelper.setDirection(dir);
                this.arrowHelper.setLength(length);
            } else
                this.arrowHelper.setLength(eps);

            if ((this.dataIndex + 1) >= values.length || this.time > time)
              this.dataIndex = 0;

            this.time = time;
          }
    }
}
