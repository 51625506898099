import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { Subscription } from 'rxjs';
import { VideoEvents, VideoEventsService } from '../services/video-events.service';
import { MediaPlayerComponent } from './media-player/media-player.component';


@Component({
  selector: 'app-multi-media-player',
  templateUrl: './multi-media-player.component.html',
  styleUrls: ['./multi-media-player.component.scss']
})
export class MultiMediaPlayerComponent implements OnInit, OnDestroy {
  @Input() videoTracks: any[];
  @Input() hideControls = false;
  @Input() nextVideoEnabled = false;
  @Input() currentTrialName: string = "";

  @Output() durationEvent: EventEmitter<any> = new EventEmitter();

  private subs: Subscription[] = [];
  public playbackControl: PlaybackControlService;
  public playbackSpeed: 1;
  public showVideo = true;

  @ViewChildren('mediaPlayers')
  mediaPlayers: QueryList<MediaPlayerComponent>;
  public videosMaxHeight: number; // the max height available between video clips

  private rotationChangeInterval: NodeJS.Timeout;

  constructor(
    private playbackGlobal: GlobalPlaybackControlService,
    private readonly videoEvents: VideoEventsService,
  ) {
    const $videoEvents = this.videoEvents.getEventsObservable();
    $videoEvents.subscribe(event => {
      if (event === VideoEvents.OPEN_SPLIT_VIEW) {
        this.showVideo = false;
      }
      if (event === VideoEvents.CLOSE_SPLIT_VIEW) {
        this.showVideo = true;
      }
    });
  }

  ngOnInit() {

    this.subs.push(this.playbackGlobal.playbackControl.subscribe(
      (service: PlaybackControlService) => {
        this.playbackControl = service;
      })
    );

    this.subs.push(this.playbackGlobal.playbackSpeed.subscribe((value) => {
      this.playbackSpeed = value;
    }));

    // And start to correct rotated videos sizes to fit their containers
    this.createRotationChangeInterval();
  }

  ngOnDestroy() {
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }

  // bubble event up. it can be removed once video tracks are out of multichart
  newDurationValue(event: any) {
    this.durationEvent.emit(event);
  }

  /**
  * Scans all current videos to find the max height. Max height is then passed as input to all
  * children video elements in order to let them adjust themselves in case they need to be
  * transformed (e.g. rotations).
  * @see https://gitlab.com/moveshelf/mvp/-/issues/1084
  */
  public updateRotatedMediaPlayers(): void {
    // Don't do anything if we don't have mediaplayers yet
    if (!this.mediaPlayers || !this.mediaPlayers.first) {
      return;
    }

    let maxHeight = 0;
    // loop over media players and get the max height
    for (const player of this.mediaPlayers?.toArray()) {
      const videoElement = player.videoEl.nativeElement;
      maxHeight = videoElement.offsetHeight > maxHeight ? videoElement.offsetHeight : maxHeight;
    }

    this.videosMaxHeight = maxHeight;
  }

  /**
   * Continuously adapt rotated video sizes in order to fit within their container.
   * Creates a periodic check on rotated video.
   * This is useful to allow continuous check of video sizes even if components are
   * hidden/reloaded/destroyed (e.g. accordion hiding videos)
   */
  private createRotationChangeInterval(): void {
    // Avoid spawning multiple intervals
    if (!this.rotationChangeInterval) {
      this.rotationChangeInterval = setInterval(() => {
        this.updateRotatedMediaPlayers();
      }, 200);
    }
  }
}
