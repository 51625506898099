import gql from 'graphql-tag';
import { RelayNode } from '../graphql-types/relay-node';
import { ProjectMetadata, ProjectMetadataFragment } from './project-metadata';
import { UserMetadata, UserMetadataFragment } from './user-metadata';

export const ClipMetadataFragment = gql`
  fragment ClipMetadata on MocapClip {
    id,
    title,
    description,
    previewImageUri,
    canEdit,
    allowDownload,
    allowUnlistedAccess,
    license,
    views,
    likes,
    has3d,
    hasVideo,
    downloads,
    projectPath,
    likedByViewer,
    likedBy {
      id,
      displayName
    }
    created,
    customOptions,
    keyframes,
    project {
      ... ProjectMetadata
    }
    owner {
      ... UserMetadata
    }
    defaultSelection {
      start
      end
    }
    startTimecode {
      framerate
      timecode
    }
  }
  ${ProjectMetadataFragment}
  ${UserMetadataFragment}
`;

export class ClipMetadata extends RelayNode {
  canEdit: boolean;
  title: string;
  description: string;
  previewImageUri?: string;
  owner: UserMetadata;
  project: ProjectMetadata;
  allowDownload: boolean;
  allowUnlistedAccess: boolean;
  license: string;
  views: number;
  likes: number;
  downloads: number;
  likedByViewer: boolean;
  created: string;
  defaultSelection: {
    start: number;
    end: number;
  };
  keyframes: string;
  customOptions?: any;
}
