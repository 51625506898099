
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ProjectInfo } from 'app/projects/project-view.types';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, Observer, forkJoin, from, of as observableOf } from 'rxjs';
import {
  concatMap, flatMap, map, switchMap, tap
} from 'rxjs/operators';
import * as SparkMD5 from 'spark-md5';
import { MocapClip } from '../shared/mocap-clip';
import { AuthService, AuthState } from './auth.service';
import { ObservableFileReaderService } from './clip-upload/observable-file-reader.service';
import { calculateCrc32c } from './crc32c';
import { ResumableUploadService } from './resumable-upload.service';




interface FileInput {
  file: File;
  metadata: MocapClip;
}

interface ChecksummedFile extends FileInput {
  crc32c: string;
  md5: string;
}

export interface CreatedClip {
  metadata: MocapClip;
  file: File;
}

export interface Progress {
  pendingUploads: number;
  progress: number;
  currentUploadId: string;
}

interface ClipCreationResponse {
  clientId: string;
  uploadUrl: string;
  mocapClip: any;
}

interface ClipCreationInput {
  clientId: string;
  crc32c?: string;
  metadata: MocapClip;
}

export interface CreatedClipWithData {
  data?: {
    createClips: {
      response: ClipCreationResponse;
    }
  };
}

export interface FilePath extends File {
  filePath?: string;
}

@Injectable()
export class ClipUploaderService {

  private mutation = gql`
    mutation createClips($input: ClipCreationInput!) {
      createClips(input: $input) {
        response {
          clientId,
          uploadUrl,
          mocapClip {
            id,
            title,
            projectPath
          }
        }
      }
    }
  `;

  private projectQuery = gql`
    query {
      viewer {
        id
        projects {
          id,
          name
        }
      }
    }
  `;

  private inputFiles: Observer<{ project: string, files: ChecksummedFile[], metadata: MocapClip[], observer: Observer<CreatedClip[]> }>;
  private _pendingUploads: BehaviorSubject<Progress>;
  private _availableProjects: BehaviorSubject<ProjectInfo[]>;

  public lastProject: ProjectInfo;

  constructor(
    private apollo: Apollo,
    private authService: AuthService,
    private http: HttpClient,
    private reader: ObservableFileReaderService,
    private uploader: ResumableUploadService
  ) {
    this._pendingUploads = new BehaviorSubject<Progress>({ pendingUploads: 0, progress: 0, currentUploadId: null });

    Observable.create(observer => this.inputFiles = observer)
      .pipe(
        flatMap((inputs: { project, files, metadata, observer }) => this.createClipUploads(inputs)),
        tap(() => this.incrementPendingUploads()),
        concatMap(({ request, clipId }) => this.uploader.upload(request).pipe(
          map(evt => this.handleHttpEvent(clipId, evt))
        ))
      )
      .subscribe();

    this._availableProjects = new BehaviorSubject([]);
    this.authService.state.pipe(
      switchMap(state => {
        if (state === AuthState.LoggedIn) {
          return this.apollo.watchQuery<any>({
            query: this.projectQuery
          }).valueChanges.pipe(
            map(({ data }) => data.viewer.projects.map(proj => proj))
          );
        } else {
          return observableOf([]);
        }
      })).subscribe(this._availableProjects);
  }

  public get availableProjects(): Observable<ProjectInfo[]> {
    return this._availableProjects.asObservable();
  }

  public pendingUploads() {
    return this._pendingUploads.asObservable();
  }

  public uploadFiles(project: ProjectInfo, files: FilePath[], metadata?: MocapClip[]): Observable<CreatedClip[]> {
    this.lastProject = project;
    // console.log(this.lastProject);

    if (metadata == null) {
      metadata = files.map(f => ({
        title: f.name.split('.')[0],
        projectPath: f.filePath ? f.filePath : "/"
      }));
    }
    return Observable.create(observer => {
      forkJoin(files.map(f => this.checksumFile(f)))
        .subscribe(files => this.inputFiles.next({
          project: project.name,
          files: files,
          metadata: metadata,
          observer: observer
        }));
    });
  }

  private checksumFile(file: File): Observable<ChecksummedFile> {
    return this.reader.readFile(file).pipe(map(({ file, data }) => {
      return {
        file: file,
        crc32c: calculateCrc32c(new Uint8Array(data)),
        md5: SparkMD5.ArrayBuffer.hash(data)
      };
    }));
  }

  public createClip(project, metadata): Observable<CreatedClipWithData> {
    return this.apollo.mutate({
      mutation: this.mutation,
      variables: {
        input: {
          project: project,
          clips: [{ clientId: "manual", metadata: metadata }]
        }
      }
    });
  }

  private createClipUploads({ project, files, metadata, observer }) {
    const fileMap = new Map<string, File>();
    const clips = files.map((f, i) => {
      fileMap.set(f.md5, f.file);
      return { clientId: f.md5, crc32c: f.crc32c, filename: f.file.name, metadata: metadata[i] };
    });

    const input = {
      project: project,
      clips: clips
    };

    return this.apollo.mutate<{ createClips: { response: ClipCreationResponse[] } }>({
      mutation: this.mutation,
      variables: { input: input }
    }).pipe(flatMap(({ data }) => {
      const responseClips = data.createClips.response;

      observer.next(responseClips.map((clip, i) => {
        const file = fileMap.get(clip.clientId);
        return { metadata: clip.mocapClip, file: file };
      }));
      observer.complete();

      return from(responseClips).pipe(
        map((clip: ClipCreationResponse) => {
          const file = fileMap.get(clip.clientId);
          return {
            request: new HttpRequest('PUT', clip.uploadUrl, file, {
              reportProgress: true
            }),
            clipId: clip.mocapClip.id
          };
        })
      );
    }));
  }

  private handleHttpEvent(clipId: string, event: HttpEvent<any>, fileSize?: number) {
    if (event.type === HttpEventType.UploadProgress) {
      const prog = this._pendingUploads.value;
      prog.progress = event.loaded / event.total;
      prog.currentUploadId = clipId;
      this._pendingUploads.next(prog);
      return;
    } else if (event instanceof HttpResponse) {
      if ([200, 201].includes(event.status)) {
        this.decrementPendingUploads();
        return;
      }
    }
    return event;
  }

  private incrementPendingUploads() {
    const prog = this._pendingUploads.value;
    prog.pendingUploads += 1;
    this._pendingUploads.next(prog);
  }

  private decrementPendingUploads() {
    const prog = this._pendingUploads.value;
    prog.pendingUploads -= 1;
    prog.progress = 0;
    this._pendingUploads.next(prog);
  }
}
