<div class="floating-dropdown" [tooltip]="this.tile.primaryVideo === true ? 'This is the primary trial which leads the synchronization, and can\'t be changed.' : ''">
  <select class="video-selector" (change)="this.changeVideo($any($event.target))" [disabled]="this.tile.primaryVideo === true" (keydown)="keyPressing($event)">
    <ng-container *ngFor="let session of this.sessionAndConditionObject">
      <optgroup [label]="session.sessionName"></optgroup>
      <optgroup *ngFor="let condition of session.conditions" [label]="'&nbsp;&nbsp;&nbsp;&nbsp;' + condition.conditionName">
        <option *ngFor="let trial of condition.trials" [value]="trial.id" [disabled]="!trial.hasVideo" [selected]="trial.id === tile.id">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{trial.name}}
        </option>
      </optgroup>
    </ng-container>
  </select>
  <p *ngIf="showNoVideoLabel" class="mt-1">No videos found for this trial</p>
</div>
