
import * as THREE from 'three';

export class HeadAnimationHelpers {
    static isHeadBlendShapeAnimation(result: any): boolean {

        let hasMorph = false;

        for (const track of result.clips[0].tracks) {
          if (track.name.indexOf('morph') != -1)
            hasMorph = true;
        }

        if (!hasMorph)
          return false;

        for (const bone of result.skeleton.bones) {
          if (bone.name.indexOf('grp_eye')!= -1)
            return true;
        }

        return false;
      }

    static convertTracksForHead(sourceTracks: any[], targetTracksList: any[]) {
        const meshName = "head";

        for (const track of sourceTracks) {
          if (track.name.indexOf('quaternion') != -1) {
            if (track.name.indexOf('head') != -1) {
              targetTracksList.push( new THREE.QuaternionKeyframeTrack(meshName + ".quaternion", track.times, track.values));
            }
          } else if (track.name.indexOf('morph') != -1) {

            if (!track.userData || !track.userData.headMorphersFilter || track.userData.headMorphersFilter == 0) {
              let morphScale = 1;
              let nMorphers = 51; //number of iphonex blendshapes and morphtarget influences

              if (track.times.length > 0)
                nMorphers = track.values.length / track.times.length;

              for (let i=0;i < track.values.length; i++) {
                if (track.values[i] > 1) {
                  morphScale = 0.01; //need to convert deformation from percentage [0 - 100]
                  break;
                }
              }

              //https://github.com/mrdoob/three.js/issues/6465
              const selectedMorphers = [0, //blink right
                6, //brow inner up
                16, //brow outer up right
                48, //brow outer up left
                19, //jaw open
                20, //blink left
                35, //smile left
                46, //smile right
                37, //eye wide left
                1, //eye wide right
                32, //mouth up right
                47, //mouth up left
                /*23, //nose smeer right
                12, //nose smeer left
                11, //mouth funnel
                43, //mouth right
                17, //mouth left
                */
                //29, //cheekpuff
                //42, //pucker
                ];

              const isMorpherSlected = [];
              for (let i=0; i < nMorphers; i++)
                isMorpherSlected.push(false);

              for (let j=0; j<selectedMorphers.length; j++) {
                const index = selectedMorphers[j];
                isMorpherSlected[index] = true;
              }

              for (let i=0; i < nMorphers; i++) {
                for (let j=0; j< track.times.length; j++) {
                  const index = j*nMorphers + i;

                  if (isMorpherSlected[i]) {
                    track.values[index] *= morphScale;

                    if (track.values[index] < 0) //handle rounding errors
                      track.values[index] = 0;
                    else if (track.values[index] > 1)
                      track.values[index] = 1;
                  } else
                    track.values[index] = 0;
                }
              }
            }

            const morph = new THREE.NumberKeyframeTrack(meshName + ".morphTargetInfluences", track.times, track.values);
            morph.userData = { headMorphersFilter: 1 };
            targetTracksList.push(morph);
          } else {
            if (track.name.indexOf('head') != -1 && track.name.indexOf('position') != -1) {
              targetTracksList.push( new THREE.VectorKeyframeTrack(meshName + ".position", track.times, track.values));
            }
          }
        }
      }

}
