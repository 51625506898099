import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChartReadyEvent, ChartSelectEvent } from 'ng2-google-charts';
import { TrialChartsService } from './multi-chart/trial-charts.service';
import { ColorService } from './services/color-service/color-service.service';
interface DataTracks {
  name: string;
  data: any[];
  stdDataLabel?: string;
  xAxisDataLabel?: string;
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() set input(chartTracks) {
    this.setLayout('base');
    this.setChartData(chartTracks);
    this.visible = true;
  }

  @ViewChild('cchart', { static: true }) cchart;
  @ViewChild('container', { static: true }) container: ElementRef;

  constructor(
    private trialChartsService: TrialChartsService,
    private colorService: ColorService,
  ) { }

  chartData: any;

  chartInfo: {
    labels?: {
      title?: string,
      hAxisDescription?: string,
      vAxisDescription?: string,
      xAxisDataLabel?: string,
      vLimits?: any,
      hLimits?: any
    },
    series?: any,
    dataTracks?: DataTracks[],
    colors?: string[]
  };

  values: any[] = [];
  dataReady: boolean = false;
  annotationRowIndex: number;
  isFullscreen: boolean = false;
  baseLayout: string = 'thumb-top';
  visible: boolean = false;
  skipFactor = 1;
  chartReady: boolean = false;

  playerClass: string = this.baseLayout;

  ngOnInit() {

  }

  public setChartData(chartInfo: any) {
    this.createChart(chartInfo);
    this.prepareTableColumns(chartInfo);
    this.fillChartData(chartInfo.dataTracks);
  }

  prepareTableColumns(data: any) {
    this.chartData.dataTable.cols.push( {
      id: "1",
      label: data.labels.xAxisDataLabel,
      type: "number"
    });

    for (let i = 0; i < data.dataTracks.length; i++) {
      let chartId = (i * 3 + 2);
      const dataTrack = data.dataTracks[i];

      this.chartData.dataTable.cols.push( {
        id: chartId.toString(),
        label: dataTrack.name, // assumes only (t, v) pairs, eventually with std
        type: "number"
      });
      chartId++;
      if (dataTrack.stdDataLabel || dataTrack.yLabelPlus) {
        this.chartData.dataTable.cols.push( {
          id: chartId.toString(),
          label: dataTrack.name + '+',
          type: "number",
          role: 'interval'
          });
        chartId++;
        this.chartData.dataTable.cols.push( {
          id: chartId.toString(),
          label: dataTrack.name + '-',
          type: "number",
          role: 'interval'
          });
        }
        this.chartData.dataTable.cols.push({type: 'string', role: 'tooltip'});
      }
    }

  public createChart(chartInfo: any) {

    this.chartInfo = { };

    for (const p in chartInfo) {
      this.chartInfo[p] = chartInfo[p];
    }

    this.chartData =  {
      chartType: 'LineChart',
      dataTable: {
        cols: [],
        rows: []
      },
      options: {
        series: this.chartInfo.series ?? {},
        tooltip: {
          isHtml: true,
          ignoreBounds: true
        },
        'title': this.chartInfo.labels.title,
        'intervals': { 'style': 'area'},
        'titleTextStyle': {
          'color': '#fff',
          'fontSize': 14
        },
        'chartArea': {
          'width': '75%',
          'height': '60%',
        },
        'backgroundColor': {
          fill: 'transparent'
        },
        'legend': {
          'position': 'bottom',
          'textStyle': {
            'color': '#fff'
          }
        },
        'lineWidth': '1',
        'hAxis': {
          'ticks':  this.chartInfo.labels.xAxisDataLabel === "days" ? [ -30, 0, 90, 180, 400 ] : [ 1, 50, 100],
          'gridlines': { 'color': '#555' },
          'baselineColor': '#777',
          'textStyle': {
            'color': '#fff'
          },
          'title': this.chartInfo.labels.hAxisDescription,
          'titleTextStyle': {
            'color': '#fff'
          }
        },
        'vAxis': {
          'ticks':  [ 0, 1, 1.5 ],
          'gridlines': { 'color': '#555' },
          'baselineColor': '#777',
          'textStyle': {
            'color': '#fff'
          },
          'title': this.chartInfo.labels.vAxisDescription,
          'titleTextStyle': {
            'color': '#fff',
            'italic': false,
            'fontSize': 12
          }
        },
      'colors': this.chartInfo.colors ? this.chartInfo.colors : [this.colorService.right,
        this.colorService.cyan, this.colorService.blue ]
      },
      'annotations': [{
        'style': 'line',
        'textStyle': {
          'fontSize': 30,
          'color': "#000",
          'bold': true
        }
      }],
    };

    if (this.chartInfo.labels.vLimits) {
      const limits = this.chartInfo.labels.vLimits;
      this.chartData.options.vAxis.viewWindow = {
        'min': limits[0],
        'max': limits[1]
      };
  }

  if (this.chartInfo.labels.hLimits) {
    const limits = this.chartInfo.labels.hLimits;
    this.chartData.options.hAxis.viewWindow = {
      'min': limits[0],
      'max': limits[1]
    };
  }
  this.refresh();
}

public refresh() {
  if (this.visible && this.chartReady) {
    this.cchart.draw();
  }
}

select(event: ChartSelectEvent) {
  if (event.message !== "select")
    return;
}

resizeChart() {
  this.chartData.options.height = '' + this.container.nativeElement.clientHeight*0.9 + '';
  if (this.visible)
    this.cchart.draw();
}

@HostListener('window:resize', ['$event'])
onResize(event: any) {
  if (this.chartData && this.chartData.options)
    this.resizeChart();
}


toggleFullscreen() {
  if (!this.isFullscreen) {
    this.playerClass = 'fullscreen';
    this.chartData.options.hAxis.ticks = this.chartInfo.labels.xAxisDataLabel == 'days' ? [ -30, 0, 30, 90, 180, 400] : [ 1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    this.isFullscreen = true;
  } else {
    this.chartData.options.hAxis.ticks = this.chartInfo.labels.xAxisDataLabel == 'days' ? [ -30, 0, 30, 90, 180, 400] : [ 1, 50, 100];
    this.playerClass = this.baseLayout;
    this.isFullscreen = false;
  }
}

setLayout(layout: string) {
  this.baseLayout = layout;
  this.playerClass = this.baseLayout;
}

public isVisible() {
  return this.visible;
}

public toggleVisibility(enable?: boolean) {

  this.visible = enable != undefined ? enable : !this.visible;
  if (!this.chartReady)
    return;

  if (this.visible) {
    this.resizeChart();
    this.container.nativeElement.style.visibility = '';
  } else {
    this.container.nativeElement.style.visibility = 'hidden';
  }

}

public isChartAvailable() {
  return this.dataReady;
}

private fillChartData(dataTracks: any) {
  const firstTrackData = dataTracks[0].data;
  const timeLabel = this.chartInfo.labels.xAxisDataLabel;
  for (let j = 0; j < firstTrackData.length; j++) {
    const dataSample = this.trialChartsService.generateDataSample(dataTracks, this.chartInfo.labels, firstTrackData[j][timeLabel], j);
    this.chartData.dataTable.rows.push({c: dataSample});
  }

  /* prepare your chart here */
  this.dataReady = true;
  this.refresh();
}

public ready(event: ChartReadyEvent) {
  if (!this.chartReady) {
    this.chartReady = true;
    this.toggleVisibility(this.visible);
  }
}


}
