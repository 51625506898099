export enum PlugInGaitConfiguration {
    None,
    PlugInGaitFullBody, //39 markers
    PlugInGaitLowerBody, //19 markers
    PlugInGaitFullBodyFunctional, //43 Markers
    PlugInGaitLowerBodyFunctional, //20 Markers
}

export class PlugInGait {

    markerList: any[];

    readonly head = ["RFHD", "LFHD", "LBHD", "RBHD"];

    readonly upperLimbLeft = ["LSHO", "LUPA", "LELB", "LFRM", "LWRA", "LWRB", "LFIN"];
    readonly upperLimbRight = ["RSHO", "RUPA", "RELB", "RFRM", "RWRA", "RWRB", "RFIN"];

    readonly torso = ["CLAV", "STRN", "C7", "T10", "RBAK"];
    readonly pelvis = ["LASI", "RASI", "RPSI", "LPSI"];
    readonly pelvisAlt = ["LASI", "RASI", "SACR"];
    readonly lowerLimbLeft = ["LTHI", "LKNE", "LTIB"];
    readonly lowerLimbLeftAlt = ["LKAX", "LKD2", "LKD1"];
    readonly lowerLimbRight = ["RTHI", "RKNE", "RTIB"];
    readonly lowerLimbRightAlt = ["RKAX", "RKD2", "RKD1"];

    readonly leftFoot = ["LANK", "LHEE", "LTOE"]; //"LMED",
    readonly rightFoot = ["RANK", "RHEE", "RTOE"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];
        //if(this.hasAllMarkers(this.head))
          segments.push({ name: "head", markerList: this.head, allConnected: true});

        //if(this.hasAllMarkers(this.upperLimbRight))
        {
          segments.push({ name: "rightArm", markerList: ["RSHO", "RUPA", "RELB"], allConnected: true, side: 2 });
          segments.push({ name: "rightLowerArm", markerList: ["RELB", "RFRM", "RWRA", "RWRB"], allConnected: true, side: 2 });
          segments.push({ name: "rightHand", markerList: ["RWRA", "RWRB", "RFIN"], allConnected: true, side: 2 });
        }

        //if(this.hasAllMarkers(this.upperLimbLeft))
        {
          segments.push({ name: "leftArm", markerList: ["LSHO", "LUPA", "LELB"], allConnected: true, side: 1 });
          segments.push({ name: "leftLowerArm", markerList: ["LELB", "LFRM", "LWRA", "LWRB"], allConnected: true, side: 1 });
          segments.push({ name: "leftHand", markerList: ["LWRA", "LWRB", "LFIN"], allConnected: true, side: 1 });
        }

        //if(this.hasAllMarkers(this.pelvis))
          segments.push(  { name: "pelvis", markerList:  ["LASI", "RASI", "RPSI", "LPSI"], allConnected: true });

        //if(this.hasAllMarkers(this.torso))
          segments.push(  { name: "torso", markerList:  ["CLAV", "STRN", "C7", "T10", "RBAK"], allConnected: true });

        //if(this.hasAllMarkers(this.lowerLimbRight) && this.hasAllMarkers(this.pelvis) && this.hasAllMarkers(this.leftFoot))
        {
          segments.push(  { name: "leftLegUp", markerList:  ["LASI", "LPSI", "LTHI",  "LKNE"], allConnected: true, side: 1 });
          segments.push(  { name: "leftLeg", markerList:  ["LKNE", "LTIB", "LANK"], allConnected: true, side: 1 });
          segments.push(  { name: "leftFoot", markerList:  ["LANK", "LHEE", "LTOE"], allConnected: true, side: 1 });

          segments.push(  { name: "rightLegUp", markerList:  ["RASI", "RPSI", "RTHI", "RKNE"], allConnected: true, side: 2 });
          segments.push(  { name: "rightLeg", markerList:  ["RKNE", "RTIB", "RANK"], allConnected: true, side: 2 });
          segments.push(  { name: "rightFoot", markerList:  ["RANK", "RHEE", "RTOE"], allConnected: true, side: 2 });
        }

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];
        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      checkPluginGate() {

        const fullBody = [this.head,
          this.upperLimbLeft,
          this.upperLimbRight,
          this.torso,
          this.pelvis,
          this.lowerLimbLeft,
          this.lowerLimbRight,
          this.leftFoot,
          this.rightFoot
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return PlugInGaitConfiguration.PlugInGaitFullBody;
        }

        const lowerBody = [
            this.pelvis,
            this.lowerLimbLeft,
            this.lowerLimbRight,
            this.leftFoot,
            this.rightFoot
          ];

        if (this.checkConfiguration(lowerBody).length <= 1)
            return PlugInGaitConfiguration.PlugInGaitLowerBody;

        return PlugInGaitConfiguration.None;
      }

}
