
import { isPlatformBrowser } from '@angular/common';
import {
  Component, Inject, Input, OnInit, PLATFORM_ID, ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { CommentCreationInput, CommentUpdateService } from '../core/comment-update.service';
import { SelectionService } from '../core/selection.service';
import { TimeSelection } from '../core/time-selection';
import { pollConfig } from './services/polling.service';

const POLL_INTERVAL = pollConfig.LONG;
const COMMENTS_TO_LOAD = 5;

@Component({
  selector: 'app-comments-feed',
  templateUrl: './comments-feed.component.html',
  styleUrls: ['./comments-feed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentsFeedComponent implements OnInit {

  private _clipId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  @Input() set clip_id(clipId: string) {
    this._clipId.next(clipId);
    this.numberToLoad = COMMENTS_TO_LOAD;
  }
  get clip_id() { return this._clipId.value; }

  @Input()
  show_comments: boolean = false;
  placeholderText = "Share your feedback on the recorded movement...";

  comment_text: string = "";
  comments: any = [];
  subs: Subscription[] = [];
  clipPageInfo: any = { hasNextPage: false };
  numberToLoad: number = COMMENTS_TO_LOAD;
  pendingQuery: boolean = false;
  commentsNumberString: string = '';
  framerate: string; // Enumerator, i.e. 'FPS_24', 'FPS_25'
  baseTimecode: string;
  timeSelection: TimeSelection;

  parent_id: string;

  public includeTimeSelection = false;

  private queryRef: QueryRef<any>;
  private query = gql`
    query getCommentsQuery($id: ID!, $first: Int = 5, $cursor: String) {
      node(id: $id) {
        ... on MocapClip {
          id,
          startTimecode {
            framerate
            timecode
          }
          comments(first: $first, after: $cursor, order: DESCENDING) {
            pageInfo {
              hasNextPage,
              endCursor
            },
            edges {
              node {
                id,
                author {
                  username,
                  displayName,
                  avatar
                }
                comment,
                created
                timeSelection {
                  start
                  end
                }
              },
              cursor
            }
          }
        }
      }
    }
  `;

  private report_query = gql`
  query getReportCommentsQuery($id: ID!, $first: Int = 5, $cursor: String) {
    node(id: $id) {
      ... on Report {
        id,
        comments(first: $first, after: $cursor, order: DESCENDING) {
          pageInfo {
            hasNextPage,
            endCursor
          },
          edges {
            node {
              id,
              author {
                username,
                displayName,
                avatar
              }
              comment,
              created
            },
            cursor
          }
        }
      }
    }
  }
`;

  constructor(
    private apollo: Apollo,
    private commentUpdateService: CommentUpdateService,
    private router: Router,
    private authService: AuthService,
    private selectionService: SelectionService,
    @Inject(PLATFORM_ID) private platformId
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.subs.push(this.selectionService.selection.subscribe(s => {
        this.timeSelection = s;
        this.includeTimeSelection = (this.timeSelection) ? true :  false;
      }));


      this.subs.push(this._clipId.pipe(switchMap(clipId => {
        this.pendingQuery = true;
        this.comments = [];
        this.commentsNumberString = '0';

        let query = this.query;
        const temp_id = clipId.split(':');
        this.parent_id = clipId;

        if ( temp_id[0] == 'report') {
          query = this.report_query;
          this.parent_id = temp_id[1];
        }

        this.queryRef = this.apollo.watchQuery<any>({
          query: query,
          pollInterval: POLL_INTERVAL,
          variables: {
            id: this.parent_id
          }
        });
        return this.queryRef.valueChanges;
      })).subscribe(({data}) => {
        if (data.node.startTimecode) {
          this.framerate = data.node.startTimecode.framerate;
          this.baseTimecode = data.node.startTimecode.timecode;
        }
        this.comments = data.node.comments.edges;
        this.commentsNumberString = this.comments.length;
        this.clipPageInfo = data.node.comments.pageInfo;
        if (this.clipPageInfo.hasNextPage)
          this.commentsNumberString += '+';
        this.pendingQuery = false;
      }));
    } else {
      this.pendingQuery = true;
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  loadMore() {
    if (this.clipPageInfo.hasNextPage && !this.pendingQuery) {
      this.pendingQuery = true;
      this.numberToLoad += 5;

      this.queryRef.stopPolling();
      this.queryRef.setVariables({id: this.parent_id, first: this.numberToLoad});
      this.queryRef.startPolling(POLL_INTERVAL);
    }
  }

  toggleShowComments() {
    this.show_comments = !this.show_comments;
  }

  sendComment() {
    this.authService.login().then(loggedIn => {
      if (loggedIn && this.comment_text && this.comment_text != "") {
        const data: CommentCreationInput = {
          clipId: this.parent_id,
          comment: this.comment_text,
        };
        if (this.timeSelection && this.includeTimeSelection) {
          const ts = this.timeSelection;
          data.timeSelection = { start: ts.start, end: ts.end };
        }
        this.commentUpdateService.createCommentForClip(data).subscribe(() => this.comment_text = "");
      }
    });
  }

  onEnter() {
    this.sendComment();
  }
}
