import { Component } from '@angular/core';
import { LeftRightService } from './left-right.service';

@Component({
  selector: 'app-left-right-maximize',
  templateUrl: './left-right-maximize.component.html',
  styleUrls: ['./left-right-maximize.component.scss']
})
export class LeftRightMaximizeComponent {

  constructor(public leftRightService: LeftRightService) { }

  public tooltip(): string {
    if (this.leftRightService.canMaximize()) {
      return "Open split screen";
    } else {
      return "Select at least one element to enable";
    }
  }
}
