import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as rethrow, timer } from 'rxjs';
import {
  catchError, flatMap,
  map, retryWhen,
  scan
} from 'rxjs/operators';




@Injectable()
export class ResumableUploadService {

  constructor(private http: HttpClient) { }

  public upload(request: HttpRequest<File>) {
    return this.http.request(request)
      .pipe(
        map(event => this.handleHttpEvent(event)),
        catchError((error, caught) => {
          if (error.status == 404) {
            return rethrow(caught);
          }
          return this.resumeUpload(request);
        }),
        retryWhen(errors => {
          return errors.pipe(
            scan(retries => retries + 1, -1),
            flatMap(retries => this.backoffTimer(retries))
          );
        })
      );
  }

  private resumeUpload(originalRequest: HttpRequest<File>) {
    const req = originalRequest.clone({
      body: null,
      reportProgress: false,
      headers: new HttpHeaders().set('Content-Range', `bytes */${originalRequest.body.size}`)
    });

    return timer(1000 + Math.random() * 1000).pipe(flatMap(() => {
      let resumed = false;

      return this.http.request(req).pipe(
        map(event => this.handleHttpEvent(event)),
        catchError((response: HttpErrorResponse, caught) => {
          if (response.status == 308) {
            resumed = true;
            return this.continueUpload(originalRequest, response);
          }
          return rethrow(caught);
        }),
        retryWhen(errors => {
          return errors.pipe(
            scan(retries => resumed ? 0 : retries + 1, 0),
            flatMap(retries => {
              resumed = false;
              return this.backoffTimer(retries);
            })
          );
        })
      );
    }));
  }

  private backoffTimer(retries: number) {
    const maxBackoff = 32;
    const delaySeconds = Math.min(Math.pow(2, retries), maxBackoff) + Math.random();
    return timer(delaySeconds * 1000);
  }

  private continueUpload(originalRequest: HttpRequest<File>,
    response: HttpErrorResponse) {
    let uploaded = -1;

    if (response.headers.has('Range')) {
      const range = response.headers.get('Range');
      uploaded = Number(/bytes=\d+-(\d+)/.exec(range)[1]);
    }

    const originalSize = originalRequest.body.size;
    const req = originalRequest.clone({
      body: originalRequest.body.slice(uploaded + 1),
      headers: new HttpHeaders().set(
        'Content-Range',
        `bytes ${uploaded + 1}-${originalSize-1}/${originalSize}`
      )
    });

    return this.http.request(req).pipe(
      map(event => this.handleHttpEvent(event, uploaded, originalSize))
    );
  }

  private handleHttpEvent(event: HttpEvent<any>, previouslyUploaded = 0, fileSize?: number) {
    if (event.type === HttpEventType.UploadProgress) {
      event.loaded += previouslyUploaded;
      event.total = fileSize || event.total;
    }
    return event;
  }

}
