import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipControlsComponent } from './clip-controls.component';
import { TimebarComponent } from './timebar/timebar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule.forRoot(),
    FormsModule,
  ],
  declarations: [
    ClipControlsComponent,
    TimebarComponent,
  ],
  exports: [
    ClipControlsComponent,
    TimebarComponent,
  ],
  providers: [
  ]
})
export class ClipControlsModule { }
