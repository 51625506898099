// import './three-global';
import { HttpClient } from '@angular/common/http';
import { ColorService } from 'app/shared/services/color-service/color-service.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import 'three';
import { PlaybackControlService } from '../core/playback-controls/playback-control.service';
import { PoseComparisonService } from '../core/pose-comparison.service';
import { DataHelper } from '../shared/data-helper';
import { ActivationValueRenderer } from './activation-value-renderer';
import { BonesHelper } from "./BonesHelper";
import { BonesMeshHelper } from "./BonesMeshHelper";
import { BtsTech, BtsTechConfiguration } from './btstech';
import './BVHLoader';
import { ClipLoaderService } from './clip-loader/clip-loader.service';
import { AidDataType, ClipPlayerInput, ClipPlayerOptions, CoordinatesType, MaterialModifier, MotionTrack, MotionTrackProperty, OpticalSegmentGroup, VideoVisibilityEnum } from "./clip-player";
import './DRACOLoader';
import './FBXAnimationLoader';
import './FBXLoader';
import { ForcePlateRenderer } from "./force-plate-renderer";
import './GLTFLoader';
import { HeadAnimationHelpers } from './head-animation-helpers';
import { MocapActionService } from './mocap-action.service';
import { MotekHbm, MotekHbmConfiguration } from './motek';
import { MotionAnalysis, MotionAnalysisConfiguration } from './motion-analysis';
import { Noraxon, NoraxonConfiguration } from './noraxon';
import { OpticalSegmentRenderer } from "./optical-segment-renderer";
import { Optitrack, OptitrackConfiguration } from './optitrack';
import { PlugInGait, PlugInGaitConfiguration } from './plug-in-gait';
import { PosenetSkeletonHelper } from "./posenet-skeleton-helper";
import { Cgm2, Cgm2Congfiguration } from './pycgm2';
import { Qualisys, QualisysConfiguration } from './qualisys';
import { PlugInGaitBones, PlugInGaitBonesConfiguration } from './pig-bones'
import { ReferenceSystemHelper } from "./ReferenceSystemHelper";
import { SkeletonMathHelper } from "./skeleton-math-helper";
import { SkeletonHelper2 } from './SkeletonHelper2';
import { SttSystems, SttSystemsConfiguration } from './sttsystems';
import './TGALoader';
import * as THREE from './three-global';
import { TrailRenderer } from './TrailRenderer';
import './TRCLoader';
import './zlib-global';
import './zlib.min';
import { VideoDataForOverlay } from 'app/shared/multi-chart/trial-charts.service';
const THREE = require('three');


interface VideoTrack {
  id: string,
  options: any,
  videoName: string,
  cameraCalibration: VideoDataForOverlay
}

export class Actor {
    skeleton: any;
    rootIndex: number = 0;
    rootBone: THREE.Bone;
    placeHolder: THREE.Mesh;
    firstBone: number = 0;
    boneContainer: THREE.Group;
    referenceSystemHelper: ReferenceSystemHelper;
    bonesHelper: BonesHelper;
    skeletonMesh: any;
    bodyParts: any;
    applyMesh: boolean;
    markersContainer: THREE.Group;
    hasMarkerLabels?: boolean;
    bonesMeshHelper: BonesMeshHelper;
    segmentsRenderer: OpticalSegmentRenderer;
    posenetHelper?: PosenetSkeletonHelper;
    trails: any;
  }


export class MocapAction {
    private startTime = 0;
    mixer: THREE.AnimationMixer;
    animationGroup: THREE.AnimationObjectGroup;

    motionClips: THREE.AnimationClip[] = [];

    duration: number;

    isTrasparent: boolean = false;

    graphicalMesh: any;
    camerasContainer: any;

    bonesScaling: number = 1;
    meshToAnimationConversion: number = 1;

    forcePlates: ForcePlateRenderer[] = [];

    activationRenderer: ActivationValueRenderer[] = [];
    activationValueSampleGroup: THREE.Group;

    actors: Actor[] = [];

    transparentMaterial: THREE.MeshPhongMaterial;
    videoScreen: THREE.Mesh;

    public rootBone: THREE.Bone;

    http: HttpClient;
    mocapActionService: MocapActionService;
    animationClip: ClipPlayerInput;
    options: ClipPlayerOptions;

    chartTracks: any[] = [];
    chartTracksObserver: Subject<any[]> = new Subject();

    videoTracks: VideoTrack[] = [];
    videoTracksObserver: Subject<any[]> = new Subject();

    motionTracks: any[] = [];
    motionTracksObserver: Subject<any> = new Subject();

    takeIndex: number = 0;
    noitomTest: boolean = false;
    transparencyAvailable: boolean = false;

    comp: PoseComparisonService;
    clipId: string;
    loader: ClipLoaderService;
    control: PlaybackControlService;
    colorService: ColorService;
    actionGroup: THREE.Group;
    opacity: number = 1;

    materialMap: Map<string, any> = new Map();
    muscleList: any;

    constructor(
      clipId?: string,
      comp?: PoseComparisonService,
      loader?: ClipLoaderService,
      control?: PlaybackControlService,
      opacity: number = 1,
      colorService?: ColorService
      ) {
        this.clipId = clipId;
        this.comp = comp;
        this.loader = loader;
        this.control = control;
        this.colorService = colorService;
        this.opacity = opacity;

        this.actionGroup = new THREE.Group();
    }

    private convertAbsoluteTimeToRenderTime(time: number): number {
      if (time > this.duration + this.startTime) {
        time = this.duration + this.startTime - 0.01;
      }
      if (time < this.startTime) {
        time = 0;
      } else {
        time = time - this.startTime;
      }

      return time;
    }

    update(absoluteTime: number): void {
        const renderTime = this.convertAbsoluteTimeToRenderTime(absoluteTime);

        if (this.mixer) {
            const delta = renderTime - this.mixer.time;
            this.mixer.update(delta);

            if (this.forcePlates.length > 0) {
              for (const plate of this.forcePlates)
                plate.update(absoluteTime);
            }

            if (this.activationRenderer.length > 0) {
              for (const rend of this.activationRenderer)
                rend.update(renderTime);
            }

            for (const actor of this.actors) {
              if (actor.segmentsRenderer)
                  actor.segmentsRenderer.update();

              if (actor.posenetHelper) {
                  actor.posenetHelper.update(renderTime);
                  //actor.posenetHelper.compare(time);
              }

              if (actor.trails) {
                for (const trail of actor.trails) {
                  if (delta < 0)
                    trail.resetPoints();

                  if (delta > 0)
                    trail.update();
                }
              }
            }
        }
    }

    toggleVisibility(value?: boolean) {
      if (value != undefined)
        this.actionGroup.visible = value;
      else
        this.actionGroup.visible = !this.actionGroup.visible;
    }

    moveActionGroup(pos: {x: number, y: number, z: number}) {
      this.actionGroup.position.x += pos.x;
      this.actionGroup.position.y += pos.y;
      this.actionGroup.position.z += pos.z;
    }

    toggleOpticalSegments() {
        for (const actor of this.actors) {
            if (actor.segmentsRenderer)
              actor.segmentsRenderer.toggleVisibility();
          }
    }

    toggleMarkersTrajectory() {
      for (const actor of this.actors) {
        if (actor.trails == undefined) {
          actor.trails = [];

          for (const m of actor.markersContainer.children) {
            if (m.visible)
              actor.trails.push(new TrailRenderer(this.actionGroup, m, this.options.coordinates == CoordinatesType.zUp));
          }
        } else {
          for (const t of actor.trails)
            t.toggleVisibility();
        }
      }
    }

    toggleForcePlates(state?: number) {
        if (this.forcePlates.length > 0) {
            for (const plate of this.forcePlates)
              plate.toggleVisibility(state);
          }
    }

    toggleActivationSamples() {
      if (this.activationValueSampleGroup)
        this.activationValueSampleGroup.visible = !this.activationValueSampleGroup.visible;
    }


    isJointCenterSkeleton(markerList: any[]) {
      const jointCenterMarkers = [ "LSJC", "RSJC", "RHJC", "LHJC", "LKJC",
      "LAJC", "RKJC", "RAJC", "REJC", "RWJC", "LEJC", "LWJC"];
      for (const j of jointCenterMarkers) {
        if (SkeletonMathHelper.getMarkerWithName(markerList, j) == undefined) {
          return false;
        }
      }
      return true;
    }

    getFirstValidElement(list, index) {
      let count = 0;
      for (const e of list) {
        if (e != undefined && count++ == index)
          return e;
      }
    }

    togglePosenetHelper() {
        for (const actor of this.actors) {
            if (!actor.posenetHelper) {
              let actorJoints;

              if (actor.markersContainer) {
              const markerList = actor.markersContainer.children;
                if ( this.isJointCenterSkeleton(markerList) ) {
                  actorJoints = { nodes: [
                    { part: 'leftShoulder', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LSJC") } ,
                    { part: 'rightShoulder', obj: SkeletonMathHelper.getMarkerWithName(markerList, "RSJC") } ,
                    { part: 'rightHip', obj: SkeletonMathHelper.getMarkerWithName(markerList, "RHJC") },
                    { part: 'leftHip', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LHJC") },
                    { part: 'leftKnee', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LKJC") } ,
                    { part: 'leftAnkle', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LAJC") } ,
                    { part: 'rightKnee', obj: SkeletonMathHelper.getMarkerWithName(markerList, "RKJC") } ,
                    { part: 'rightAnkle', obj: SkeletonMathHelper.getMarkerWithName(markerList, "RAJC") } ,
                    { part: 'rightElbow', obj: SkeletonMathHelper.getMarkerWithName(markerList, "REJC") } ,
                    { part: 'rightWrist', obj: SkeletonMathHelper.getMarkerWithName(markerList, "RWJC") } ,
                    { part: 'leftElbow', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LEJC") } ,
                    { part: 'leftWrist', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LWJC") } ,
                    { part: 'leftHand', obj: SkeletonMathHelper.getMarkerWithName(markerList, "LHO") } ,
                    { part: 'rightHand', obj: SkeletonMathHelper.getMarkerWithName(markerList, "RHO") } ,
                    ],
                    chest: [0, 1, 2, 3], leftLeg: [3, 4, 5], rightLeg: [2, 6, 7], leftArm: [0, 10, 11], rightArm: [1, 8, 9]
                  };
                }
              }

              if (actor.bodyParts &&
                actor.bodyParts.leftArm.length > 2 &&
                actor.bodyParts.rightArm.length > 2 &&
                actor.bodyParts.rightLeg.length > 2 &&
                actor.bodyParts.leftLeg.length > 2 ) {
                let firstArmIndex = 0;
                const rightArmElements = actor.bodyParts.rightArm.length - SkeletonMathHelper.countUndefined(actor.bodyParts.rightArm);
                if (rightArmElements == 4)
                  firstArmIndex = 1;

                actorJoints = { nodes: [
                  { part: 'leftShoulder', obj: this.getFirstValidElement(actor.bodyParts.leftArm, firstArmIndex).bone },
                { part: 'rightShoulder', obj: this.getFirstValidElement(actor.bodyParts.rightArm, firstArmIndex).bone },
                { part: 'rightHip', obj: this.getFirstValidElement(actor.bodyParts.rightLeg, 0).bone },
                { part: 'leftHip', obj: this.getFirstValidElement(actor.bodyParts.leftLeg, 0).bone },
                { part: 'leftKnee', obj: this.getFirstValidElement(actor.bodyParts.leftLeg, 1).bone },
                { part: 'leftAnkle', obj: this.getFirstValidElement(actor.bodyParts.leftLeg, 2).bone },
                { part: 'rightKnee', obj: this.getFirstValidElement(actor.bodyParts.rightLeg, 1).bone },
                { part: 'rightAnkle', obj: this.getFirstValidElement(actor.bodyParts.rightLeg, 2).bone },
                { part: 'rightElbow', obj: this.getFirstValidElement(actor.bodyParts.rightArm, firstArmIndex + 1).bone },
                { part: 'rightWrist', obj: this.getFirstValidElement(actor.bodyParts.rightArm, firstArmIndex + 2).bone },
                { part: 'leftElbow', obj: this.getFirstValidElement(actor.bodyParts.leftArm, firstArmIndex + 1).bone },
                { part: 'leftWrist', obj: this.getFirstValidElement(actor.bodyParts.leftArm, firstArmIndex + 2).bone }],
                chest: [0, 1, 2, 3], leftLeg: [3, 4, 5], rightLeg: [2, 6, 7], leftArm: [0, 10, 11], rightArm: [1, 8, 9] };
              }

              if (actorJoints) {
                actor.posenetHelper = new PosenetSkeletonHelper( 0x00ff00 );
                const group = actor.posenetHelper.getSkeletonGroup();
                if (this.options.coordinates == CoordinatesType.zUp)
                  group.rotation.x = Math.PI / 2;

                this.actionGroup.add(group);
                actor.posenetHelper.createPoints(actorJoints);
                if (this.clipId && this.comp)
                  actor.posenetHelper.setServiceDetails(this.clipId, this.comp);

                actor.posenetHelper.update();
                if (actor.bonesMeshHelper)
                  actor.bonesMeshHelper.visible = false;
                if (actor.bonesHelper)
                  actor.bonesHelper.visible = false;
              }
            } else {
              if (actor.bonesMeshHelper)
                actor.bonesMeshHelper.visible = !actor.bonesMeshHelper.visible;
              if (actor.bonesHelper)
                actor.bonesHelper.visible = !actor.bonesHelper.visible;
              actor.posenetHelper.toggleVisibility();
            }
          }
    }

    public updatePlaceholderPositions() {
      for (const a of this.actors) {
        if (a.placeHolder && a.rootBone) {
          a.rootBone.getWorldPosition(a.placeHolder.position);
          a.placeHolder.position.y -= - 1.05;
        }
      }
    }

    public updateFollowCamPosition(controls: any) {
      if (this.rootBone) {
        const bonePos = new THREE.Vector3();
        this.rootBone.getWorldPosition(bonePos);
        bonePos.y = 0.8;
        controls.goTo(bonePos);
      }
    }

    markAsModified(clip: THREE.AnimationClip) {
        if (clip.name.indexOf('_modified') == -1)
          clip.name += '_modified';
    }

    isClipModified(clip: THREE.AnimationClip) {
    if (clip.name.indexOf('_modified') == -1)
        return false;
    return true;
    }

    scaleAnimationClip(clip: THREE.AnimationClip, scale: number) {
      for (const track of clip.tracks) {
          if (track.name.toLowerCase().indexOf("position")!==-1) {
            for (let j=0; j<track.times.length; j++) {
                track.values[j*3] *= scale;
                track.values[j*3 + 1] *= scale;
                track.values[j*3 + 2] *= scale;
            }
          }
      }
    }

    removeFirstFrame(clip:  THREE.AnimationClip) {
      for (const track of clip.tracks) {
          let trackElementSize = 3;
          if (track.name.indexOf('.quaternion') !== -1) {
          trackElementSize = 4;
          }

          if (track.times.length < 10)
          continue;

          track.times[0] = track.times[1];
          for (let i=0; i<trackElementSize; i++)
              track.values[i] = track.values[trackElementSize+i];
      }
    }

    copyRenameTrack(sourceTrack: MotionTrack, destTrack: MotionTrack, clip:  THREE.AnimationClip, keepSource: boolean) {
    const trackList = clip.tracks;

    for (let track of trackList) {
        const index = track.name.indexOf(sourceTrack.name);
        if (index !== -1) {
        {
        let properties = sourceTrack.properties;

        if (properties == undefined) { //try all known properties
            properties = [];
            for (const item in MotionTrackProperty) {
            if (MotionTrackProperty.hasOwnProperty(item) && !/^\d+$/.test(item)) {
                properties.push(item as MotionTrackProperty);
            }
            }
        }

        for (const property of properties) {
            if (track.name.toLowerCase().indexOf(property)!==-1) {
            const newTrackName = track.name.substring(0, index) + destTrack.name + track.name.substring(index + sourceTrack.name.length);
            const interp = track.getInterpolation();
            if (keepSource) {
                switch (property) {
                case MotionTrackProperty.Rotation:
                    track = new THREE.QuaternionKeyframeTrack(newTrackName, track.times, track.values, interp);
                    break;
                case MotionTrackProperty.Visible:
                    track = new THREE.BooleanKeyframeTrack(newTrackName, track.times, track.values);
                    break;
                case MotionTrackProperty.Position:
                case MotionTrackProperty.Scale:
                default:
                    track = new THREE.VectorKeyframeTrack(newTrackName, track.times, track.values, interp);
                    break;
                }
                trackList.push(track);
            } else
                track.name = newTrackName;

            break; //desired property is copied or renamed
            }
        }
        break; //all desired properties of source track are copied
        }

        }
    }

    }

    renameTrack(sourceTrack: MotionTrack, destTrack: MotionTrack, clip:  THREE.AnimationClip) {
        //let properties = [ MotionTrackProperty.Position, MotionTrackProperty.Rotation, MotionTrackProperty.Scale ];
        this.copyRenameTrack(sourceTrack, destTrack, clip, false);
    }

    copyTrackProperties(sourceTrack: MotionTrack, destTrack: MotionTrack, clip:  THREE.AnimationClip) {
    this.copyRenameTrack(sourceTrack, destTrack, clip, true);
    }

    getActionGroup() {
      return this.actionGroup;
    }

    private async parseCameraParams(dataUri: string): Promise<VideoDataForOverlay[]> {
      const res = await this.http.get(dataUri).toPromise();
      const parsedData = (res || {}) as any;
      return parsedData.cameraData ? parsedData.cameraData : parsedData.videoData;
    }

    async parseAdditionalData(additionalData: any, events?: any): Promise<void> {
      let trackNo = 1;
      let dataNo = 1;
      let useCycles = events != undefined;

      // first read camera calibration data if any
      let videoDataForOverlay: VideoDataForOverlay[] = []
      for (const data of additionalData) {
        if (data.uploadStatus === 'Complete'  && data.dataType === 'data' && data.originalFileName.toLowerCase().indexOf('camera_params.json') != -1) {
          videoDataForOverlay = await this.parseCameraParams(data.previewDataUri || data.dataUrl);
          break
        }
      }

      for (const data of additionalData) {
          if ( data.uploadStatus != 'Complete' )
            continue;

          if (data.dataType == 'camera') {
            const res = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
            const parsedData = res as any;
            const parser = new DOMParser();
            const document = parser.parseFromString(parsedData,"application/xml");
            const cameras = document.getElementsByTagName('Camera') as any;
            for (const c of cameras) {
              const name = c.attributes.getNamedItem("TYPE").value;
              for (const cc of c.children) {
                if (cc.localName == "KeyFrames") {
                  const keyframe = cc.getElementsByTagName('KeyFrame') as any;
                  const orientation = keyframe[0].attributes.getNamedItem("ORIENTATION").value.split(' ').map(parseFloat);
                  const position = keyframe[0].attributes.getNamedItem("POSITION").value.split(' ').map(parseFloat);

                  const meshSize = .1;
                  const geometry = new THREE.BoxGeometry(meshSize, meshSize, meshSize);

                  let color = 0x222222;

                  if (name.toLowerCase().indexOf('bonita') != -1)
                    color = 0xffaa00;

                  if (name.toLowerCase().indexOf('vue') != -1)
                    color = 0xeeeeee;

                  if (name.toLowerCase().indexOf('vero') != -1)
                    color = 0xaaff00;

                  const material = new THREE.MeshPhongMaterial(
                      { color: color,
                        transparent: true,
                        opacity: 0.5
                      });

                  const mesh = new THREE.Mesh(geometry, material);
                  mesh.position.x = position[0] / 1000;
                  mesh.position.y = position[1] / 1000;
                  mesh.position.z = position[2] / 1000;
                  //don't really know how to correctly parse this quat
                  //mesh.setRotationFromQuaternion( new THREE.Quaternion(orientation[0], orientation[1], orientation[2], orientation[3]));
                  mesh.setRotationFromQuaternion( new THREE.Quaternion(orientation[1], orientation[2], orientation[3], orientation[0]));
                  this.actionGroup.add(mesh);
                  break;
                }
              }
            }
          }

          if (data.dataType === 'motion') {
            const no = trackNo++;
            this.loader.loadClip(data).then( (clip) => {
              clip.trackNo = no;
              this.motionTracks.push(clip);
              this.motionTracksObserver.next(clip);
            });
          }
          if (data.dataType == 'event') {
            this.startTime = await this.mocapActionService.getStartTime(data.previewDataUri);
          }
          if (data.dataType == 'raw' && data.originalFileName.indexOf(".mox") != -1) {
            const parsedData = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
            const chartTracks = [];
            DataHelper.parseMoxData(parsedData, chartTracks);
            const chartGroup = { name: 'angles', tracks: chartTracks };
            this.chartTracks.push(chartGroup);

            if (this.chartTracks.length > 0)
              this.chartTracksObserver.next(this.chartTracks);
          }
          if (data.dataType == 'raw' && data.originalFileName.indexOf(".csv") != -1 && !(this.options.charts.enablePlayerCharts == false)) {
            const parsedData = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
            const chartTracks = [];
            const groupName = "CSV data " + dataNo++;
            const chartGroup = { name: groupName, tracks: chartTracks };
            DataHelper.parseCSV(parsedData, chartTracks, data.originalFileName.split('.')[0], groupName);
            this.chartTracks.push(chartGroup);

            if (this.chartTracks.length > 0)
              this.chartTracksObserver.next(this.chartTracks);
          }
          if (data.dataType == 'raw' && data.originalFileName.indexOf(".mvnx") != -1) {
            if (this.options.charts && this.options.charts.enablePlayerCharts == false)
              continue;

            const parsedData = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
            DataHelper.parseMvnxData(parsedData, this.chartTracks);
            if (this.chartTracks.length > 0)
              this.chartTracksObserver.next(this.chartTracks);
          }
          if (data.dataType == 'data') {
            /*let disableChart = false;
            for(let aid of this.options.aidData) {
                if (aid.data.dataId == data.id && aid.data.enableChart == false){
                  disableChart = true;
                  break;
                }
            }
            if(disableChart)
                continue;*/

            let isAnalog = false;
            if (data.originalFileName.toLowerCase().indexOf('analog.json') != -1) {
              isAnalog = true;
              if (this.options.charts && this.options.charts.enableAnalog == false)
                continue;
            }

            if (this.options.charts.enablePlayerCharts == false)
              continue;

            if (data.originalFileName.toLowerCase().indexOf('params.json') != -1)
              continue;

            if (data.originalFileName.toLowerCase().indexOf('angles.json') != -1 && (!this.options.charts || !this.options.charts.enableKinematic == false))
              continue;

            if (data.originalFileName.toLowerCase().indexOf('powers.json') != -1 && (this.options.charts && this.options.charts.enableKinetic == false))
              continue;

            if (data.originalFileName.toLowerCase().indexOf('moments.json') != -1 && (this.options.charts && this.options.charts.enableKinetic == false))
              continue;

            const res = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
            const parsedData = (res || { } ) as any;
            const match = new Map<string, string>();

            for (const c of this.options.emgChannels)
              match.set(c.channel, c.muscle);

            const chartTracks = [];

            const groupName = data.originalFileName.split('.')[0];

            for (let i=0; i< parsedData.data.length; i++) {
              const index = i;
              let isEmg = false;
              let colors = undefined;

              const dataLabels: any = { };
              let timeLabel = (this.options.charts && this.options.charts.timeLabel) ? this.options.charts.timeLabel : 'time';
              for (const p in parsedData.data[index].values[0]) {
                if (p.toLowerCase().indexOf('perc') != -1) {
                  timeLabel = 'perc';
                  useCycles = true;
                  break;
                }
              }
              for (const p in parsedData.data[index].values[0]) {
                if (p.toLowerCase().indexOf(timeLabel) != -1)
                  continue;
                dataLabels[p] = p;
                if (p.indexOf('rms') != -1) {
                  colors = ["#ff7300", "#00ff00"];
                  isEmg = true;
                }
              }

              if (isAnalog && !isEmg && (this.options.charts && !this.options.charts.enableAllAnalog))
                continue;

              const chartData = {
                id: groupName + "#" + index,//this.guidGenerator(),
                values: parsedData.data[index].values,
                events: events,
                colors: colors,
                labels: {
                  title: parsedData.data[index].label + " - " + parsedData.data[index].description,
                  hAxis: useCycles ? "Cycle [%]" : "Time [s]",
                  vAxis: "Value [" + parsedData.data[index].unit + "]",
                  time: timeLabel,
                  data: dataLabels
                } 
              };

              chartTracks.push(chartData);

              if (isEmg) {
                const geometry = new THREE.SphereGeometry( .05, 32, 32 );
                const material = new THREE.MeshPhongMaterial( {color: 0x00ff00, transparent: true } );
                const sphere = new THREE.Mesh( geometry, material );
                sphere.position.set(.15 * (index + 1) + 1, .3, .3);
                sphere.material.opacity = 0.6;

                const rend = new ActivationValueRenderer(0xff0000);
                const muscleName = match.get(parsedData.data[index].label);
                if ( muscleName != undefined) {
                  for (const m of this.muscleList) {
                    if (m.name == muscleName) {
                      rend.addObject(m);
                      m.material.opacity = 1;
                      rend.setData(parsedData.data[index].values, {time: "time", x: "rms"});
                      this.activationRenderer.push(rend);
                      break;
                    }
                  }
                } else {
                  rend.addObject(sphere);
                  rend.setData(parsedData.data[index].values, {time: "time", x: "rms"});

                  this.activationRenderer.push(rend);
                  if (!this.activationValueSampleGroup) {
                    this.activationValueSampleGroup = new THREE.Group();
                    this.activationValueSampleGroup.visible = false;
                    this.actionGroup.add(this.activationValueSampleGroup);
                  }

                  this.activationValueSampleGroup.add(sphere);
                }
              }
            }
            if (chartTracks.length > 0) {
              const chartGroup = { name: groupName, tracks: chartTracks };
              this.chartTracks.push(chartGroup);
              this.chartTracksObserver.next(this.chartTracks);
            }
          }
          if (data.dataType == 'force') {
            const res = await this.mocapActionService.getForceData(data.previewDataUri);
            this.handleForceData(res, data, events, useCycles);
          }
          if (data.dataType == 'video') {
            if (this.options.video.enablePlayerVideo == false)
              continue;

            const options = { dataUrl: data.previewDataUri || data.dataUrl, videoStyle: VideoVisibilityEnum.small, audio: true, timeOffset: 0, screenPosition: {x: 0, y: 2, z: -1}};
            for (const aid of this.options.aidData) {
                if (aid.type == AidDataType.videoAidDataType && aid.data.dataId == data.id) {
                for (const p in aid.data)
                    options[p] = aid.data[p];
                }
            }
            // console.log(this.videoTracksService)  // can we gte our shared data???
            this.videoTracks.push({id: data.id, options: options, videoName: data.originalFileName, cameraCalibration: undefined});  // make sure to get Insight videoName correct... 
          }
        }
        if (videoDataForOverlay.length > 0) {
          for (const videoTrack of this.videoTracks) {
            for (const cameraData of videoDataForOverlay) {
              const cameraNameWithoutExtension = cameraData.name.substring(0, cameraData.name.lastIndexOf('.')) || cameraData.name;             
              if (videoTrack.videoName.includes('.' + cameraNameWithoutExtension + '.') || videoTrack.videoName.includes('_' + cameraNameWithoutExtension )) {
                videoTrack.cameraCalibration = cameraData;
                videoTrack.cameraCalibration.is_initialized = false;
                break
              }
            }
          }
        }
        this.videoTracksObserver.next(this.videoTracks);
    }

    private handleForceData(res: any, data: any, events: any, useCycles: boolean): void {
      const parsedData = (res || {}) as any;
      const chartTracks = [];
      const groupName = data.originalFileName.split('.')[0];
      const colors = this.colorService.threeDPlayerForceOverlayVectors;
      for (let i=0; i< parsedData.forces.length; i++) {
        const force = parsedData.forces[i];
        const forcePlate = new ForcePlateRenderer();
        const color = i < colors.length ? colors[i] : Math.random()*0xffffff;
        const options = { zUp: (this.options.coordinates == CoordinatesType.zUp), arrowColor: color } as any;
        if ( this.options.skeletonUnits && this.options.skeletonUnits != 0 )
          options.coordinatesScale = this.options.skeletonUnits;

        if (parsedData.plates && parsedData.plates[i].corners)
            options.corners = parsedData.plates[i].corners;

        forcePlate.setData(force.force, options);
        this.forcePlates.push( forcePlate);
        this.actionGroup.add(forcePlate.getGroup());
        this.toggleForcePlates(0);

        if (this.options.charts.enablePlayerCharts == false)
          continue;

        if (this.options.charts && this.options.charts.enableKinetic == false)
          continue;

        const plateIndex = i;
        const chartData = {
            id: groupName + '#' + i,//this.guidGenerator(),
            values: parsedData.forces[plateIndex].force,
            events: events,
            labels: {
            title: "Force plate #" + (plateIndex + 1),
            hAxis: useCycles ? "Cycle [%]" : "Time [s]",
            vAxis: "Force [N]",
            time: "time",
            data: {
                x: "Fx",
                y: "Fy",
                z: "Fz"
            }
            } } ;

            chartTracks.push(chartData);
      }

      if (this.options.forcePlateState)
        this.toggleForcePlates(this.options.forcePlateState);

      if (chartTracks.length > 0) {
        const chartGroup = { name: groupName, tracks: chartTracks};
        this.chartTracks.push(chartGroup);
        this.chartTracksObserver.next(this.chartTracks);
      }
    }

    async initAction(animationClip: ClipPlayerInput, options: ClipPlayerOptions, http: HttpClient, mocapActionService: MocapActionService): Promise<number> {

        return new Promise( async (resolve) => {
            this.animationClip = animationClip;
            this.options = options;
            this.http = http;
            this.mocapActionService = mocapActionService;

            this.chartTracks = [];

            //this.actionGroup.rotation.z = Math.PI;

            if (this.options.meshAssetUri.indexOf('noitom')!== -1) {
                this.noitomTest = true;
                this.transparencyAvailable = true;
            }

            if (this.animationClip.animation && this.animationClip.animation.chartData) {
              if (!options.charts || options.charts.enablePlayerCharts !== false ) {
                const chartTracks = this.animationClip.animation.chartData;
                //let chartGroup = { name: 'angles', tracks: chartTracks };
                for (const c of chartTracks)
                  this.chartTracks.push(c);

                if (this.chartTracks.length > 0)
                  this.chartTracksObserver.next(this.chartTracks);
              }

            }

            if (this.animationClip.animation && this.animationClip.animation.forceData) {
              const forces = this.animationClip.animation.forceData;
              const colors = this.colorService.threeDPlayerForceOverlayVectors;
              for (let i=0; i< forces.length; i++) {
                const force = forces[i];
                const forcePlate = new ForcePlateRenderer();
                const color = i < colors.length ? colors[i] : Math.random()*0xffffff;

                const options = { zUp: (this.options.coordinates == CoordinatesType.zUp), arrowColor: color,  corners: force.corners} as any;
                if ( this.options.skeletonUnits && this.options.skeletonUnits != 0 )
                  options.coordinatesScale = this.options.skeletonUnits;
                forcePlate.setData(force.force, options);
                this.forcePlates.push( forcePlate);
                this.actionGroup.add( forcePlate.getGroup());
              }
            }
            this.startTime = await this.mocapActionService.getStartTime();

            const forceData = await this.mocapActionService.getForceData('');
            if (forceData) {
              // coming from polyB we set the skeleton units to 0 as on Mvp. Skeleton units are not '1' unless we're using 'tdf' files
              this.options.skeletonUnits = 0;
              this.handleForceData(forceData, {originalFileName: "force.json"}, undefined, false);
            }

            if (this.animationClip.additionalData) {
              if (options.charts && options.charts.useCycles) {
                for ( const data of this.animationClip.additionalData) {
                  if (data.dataType == 'event') {
                    const res = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
                    const parsedData = (res || {}) as any;
                    this.parseAdditionalData(this.animationClip.additionalData, parsedData.events);
                    //this.control.events.next(parsedData.events)
                  }
                }
              } else
                this.parseAdditionalData(this.animationClip.additionalData);
            }
          this.setAnimation(this.animationClip.animation).then( (duration) => { resolve(duration); } );
        });
    }

    isSkeletonSupported(skeleton: any): boolean {

      const requiredBones = ["Reference", "Hips", "Spine", "Spine1", "Spine2", "Spine3", "Neck", "Head", "RightShoulder",
      "RightArm", "RightForeArm","RightHand","LeftShoulder", "LeftArm","LeftForeArm", "LeftHand", "RightUpLeg", "RightLeg","RightFoot","RightToeBase", "LeftUpLeg", "LeftLeg", "LeftFoot", "LeftToeBase"];

      for (const bone of requiredBones) {
          if (SkeletonMathHelper.findBoneWithName(skeleton.bones, bone) == -1)
          return false;
      }
      return true;
    }

    isQualisysSkeleton(skeleton: any) {
      const requiredBones = ["Reference", "Head", "Hips", "LeftArm", "LeftFoot", "LeftForeArm", "LeftHand", "LeftLeg", "LeftShoulder",
      "LeftToeBase", "LeftUpLeg", "Neck", "RightArm", "RightFoot", "RightForeArm", "RightHand", "RightLeg", "RightShoulder", "RightToeBase", "RightUpLeg", "Spine", "Spine1", "Spine2"];

      for (const bone of requiredBones) {
        if (SkeletonMathHelper.findBoneWithName(skeleton.bones, bone) == -1)
        return false;
      }
      return true;
    }

    getMaterialsFromOptions(meshMaterials: any []) {
        for (const material of this.options.meshMaterials) {
          switch (material.type) {
            case (0):
              meshMaterials.push(new THREE.MeshBasicMaterial(material.options));
            break;
            case (1):
              meshMaterials.push(new THREE.MeshLambertMaterial(material.options));
            break;
            case (2):
              meshMaterials.push(new THREE.MeshToonMaterial(material.options));
            break;
            case (3):
              meshMaterials.push(new THREE.MeshPhongMaterial(material.options));
            break;
          }
        }
      }

      isTransparencyAvailable() {
        return this.transparencyAvailable;
      }

      loadJsonMesh(filename: string): Promise<THREE.Group> {
        return new Promise((resolve, reject) => {
          const jdLoader = new THREE.JDLoader();
          jdLoader.load(filename,
          (data) => {
            // data: { materials, jd_materials, geometries, boundingSphere }
            const meshContainer = new THREE.Group();
            let skeleton: any;
            let meshMaterials: any [] = [];
            if (this.options.replaceMeshMaterials == MaterialModifier.replace) {
              this.getMaterialsFromOptions(meshMaterials);
            } else
              meshMaterials = data.materials; //data.materials can be replaced by generated material

            if (this.options.replaceMeshMaterials == MaterialModifier.modify) {
              for (const material of this.options.meshMaterials) {
                const matToChange = data.materials[material.index];
                for (const option in material.options) {
                  let value: any;

                  if (option == 'color' || option == 'specular')
                    value = new THREE.Color(material.options[option]);
                  else
                    value = material.options[option];

                  matToChange[option] = value;
                }
              }
            }

            for (let i = 0; i < data.geometries.length; ++i) {
              const mesh = new THREE.SkinnedMesh(data.geometries[i], meshMaterials);
              mesh.castShadow = true;
              mesh.frustumCulled = false;
              meshContainer.add(mesh);

              skeleton = mesh.skeleton;
              this.animationGroup.add(mesh);
            }
            this.materialCopy(meshContainer);

            if (this.noitomTest) {
              this.getSkeletonData(skeleton); //noitom test uses skeleton data from mesh instead of the one from the animation
              this.createHelpers(skeleton);
            }

            this.actionGroup.add( meshContainer );
            resolve(meshContainer);
          });
        });
      }

      createPlaceHolder(actor: Actor) {
        const geometry = new THREE.ConeGeometry( .07, .13, 10 );
        const material = new THREE.MeshBasicMaterial( {color: 0xff7300, transparent: true, opacity: 0.5 } );
        const cone = new THREE.Mesh( geometry, material );
        cone.rotation.x = Math.PI;
        cone.visible = false;
        this.actionGroup.add( cone );
        actor.placeHolder = cone;
      }

      setPlaceholderColors(color: any) {
        for (const a of this.actors) {
          if (color != 'none')
            a.placeHolder.visible = true;
          a.placeHolder.material.color.setHex(color);
        }
      }

      getCameraModel(bone: any) {
        return new Promise<any>( resolve => {
        if (bone.name.toLowerCase().indexOf('oqus')!= -1) {
          const loader = new THREE.GLTFLoader();
          loader.load("assets/meshes/Oqus.glb", result => {

            const cameraModel = result.scene;
            bone.add( cameraModel ) ;

            bone.lookAt(0,0,0); //FIX ME: hacking orientation to be good looking
            resolve(bone);
          });
        } else if (bone.name.toLowerCase().indexOf('miqus')!= -1) {
          const loader = new THREE.GLTFLoader();
          loader.load("assets/meshes/Miqus.glb", result => {
            const cameraModel = result.scene;
            bone.add( cameraModel ) ;

            bone.lookAt(0,0,0); //FIX ME: hacking orientation to be good looking
            resolve(bone);
          });
        } else {
            const meshSize = 10;
            const geometry = new THREE.BoxGeometry(meshSize, meshSize, meshSize);
            const material = new THREE.MeshPhongMaterial(
                {color: 0xffaa00,
                  transparent: true,
                  opacity: 0.5});
            bone.add(new THREE.Mesh(geometry, material));
            resolve(bone);
          }
        });
      }

      async createHelpers(skeleton: any) {
        for (const actor of this.actors) {
          const skeletonHelper = new SkeletonHelper2( actor.rootBone , 0);

          actor.bodyParts = SkeletonMathHelper.inspectSkeleton( actor.skeleton.length >= 1 ? actor.skeleton : skeleton.bones );
          actor.skeletonMesh = skeletonHelper as any;
          actor.skeletonMesh.skeleton = skeleton; // allow animation mixer to bind to SkeletonHelper directly

          actor.boneContainer = new THREE.Group();
          if (actor.rootBone.isBone)
            actor.boneContainer.add(actor.rootBone);

          this.createPlaceHolder(actor);

          this.actionGroup.updateMatrixWorld();

          this.bonesScaling = 1;
          this.meshToAnimationConversion = 1;

          //make longest bone always equal to reference one, conceptually should be calculated once for the whole scene
          if (this.options.skeletonUnits == undefined || (this.options.skeletonUnits == 0) ) {
            if (this.animationClip.animation.userData && this.animationClip.animation.userData.bonesScaling) {
              this.bonesScaling = this.animationClip.animation.userData.bonesScaling; //avoid restimating scale if the animation is cached
            } else {
                const longestBoneInfo = SkeletonMathHelper.getLongestBoneInfo(actor.rootBone);
                if (longestBoneInfo.length > 0) {
                  let scale = SkeletonMathHelper.FEMUR_AVG_LENGTH_M / longestBoneInfo.length;
                  const cmScale = 0.01;
                  if (scale > 0.75 * cmScale && scale < 1.25 * cmScale)
                    scale = cmScale;
                  this.bonesScaling *= scale; //SkeletonMathHelper.FEMUR_AVG_LENGTH_M / longestBoneInfo.length;
                }

              this.animationClip.animation.userData = { bonesScaling: this.bonesScaling };
            }
          } else if (this.options.skeletonUnits > 0) {
            this.bonesScaling *= this.options.skeletonUnits;
          }

          if (this.options.applyMesh) {
            let animationUnits = this.options.meshAssetUnits; //animation is assumed to have the same unit of the asset
            const longestBoneLenght = SkeletonMathHelper.FEMUR_AVG_LENGTH_M / this.bonesScaling; //cached clips don't contain longestBoneInfo anymore

            if (longestBoneLenght > 0.5 * SkeletonMathHelper.FEMUR_AVG_LENGTH_M * 100 && longestBoneLenght < 2 * SkeletonMathHelper.FEMUR_AVG_LENGTH_M * 100)
              animationUnits = 0.01;

            if (animationUnits != this.options.meshAssetUnits) {
              this.meshToAnimationConversion = this.options.meshAssetUnits/animationUnits;
              this.bonesScaling *= this.meshToAnimationConversion; //account for follow-up animation scaling
            }
          }
        }

        let rotated = false;
        let colors = undefined;
        if (this.options.playerTheme == 'light') {
          colors = { left: 0x2725bF, right: 0x00b29d };
        }

        for (const actor of this.actors) {
          actor.boneContainer.scale.set(this.bonesScaling, this.bonesScaling, this.bonesScaling);

          if (this.options.coordinates == CoordinatesType.zUp && actor.boneContainer.children.length > 0) {
            actor.boneContainer.rotation.x = -Math.PI /2;
            rotated = true;
          }
          this.actionGroup.add(actor.boneContainer);

          const referenceSystemLength = 0.08; //m
          actor.referenceSystemHelper = new ReferenceSystemHelper(actor.rootBone, 0, referenceSystemLength * 1/this.bonesScaling);
          this.actionGroup.add(actor.referenceSystemHelper);

          actor.bonesHelper = new BonesHelper( actor.rootBone, actor.firstBone, 1/this.bonesScaling, this.opacity);

          actor.bonesHelper.setBodyPart(actor.bodyParts, colors);
          this.actionGroup.add( actor.bonesHelper );

          this.actionGroup.add(actor.skeletonMesh);
        }

        let globallyRotated = false;
        if (this.options.coordinates == CoordinatesType.zUp && !rotated) {
          this.actionGroup.rotation.x = - Math.PI/2;
          globallyRotated = true;
        }

        for (const actor of this.actors) {
          const bonesList = ( actor.skeleton.length > 3 ) ? actor.skeleton : skeleton.bones;

          for (let i=0; i < bonesList.length; i++) {
            const model = bonesList[i];
            //markers are not supported for multi actor
            if ( model.type == "Mesh" && model.userData.type && (
              model.userData.type === "Marker" ||
              model.userData.type === "mMarker" ||
              model.userData.type === "vMarker" ||  //(model.userData.type === "vMarker" && this.isCoM(model)) ||
              model.userData.type === "jcMarker"
              )) {
              if ( actor.markersContainer === undefined)
                actor.markersContainer = new THREE.Group();

              actor.markersContainer.add(model);
              if (this.options.coordinates == CoordinatesType.zUp && !globallyRotated) {
                actor.markersContainer.rotation.x = - Math.PI/2;
              }

              if ( model.userData.type === "mMarker" || model.userData.type === "vMarker" || model.userData.type === "jcMarker") {
                model.visible = false;
              }
              /*else {
                if(!actor.trails)
                  actor.trails = [];
                actor.trails.push(new TrailRenderer(this.actionGroup, model, this.options.coordinates == CoordinatesType.zUp));
              }*/
            }
            //cameras are assumed to be part of the scene
            if ( model.type.indexOf("Camera")!=-1 ) {
              if ( this.camerasContainer === undefined)
                this.camerasContainer = new THREE.Group();

              if (this.options.coordinates == CoordinatesType.zUp && !globallyRotated) {
                this.camerasContainer.rotation.x = - Math.PI/2;
              }

              const myModel = await this.getCameraModel(model);
              this.camerasContainer.add(myModel);

            }
          }

          if ( actor.markersContainer !== undefined ) {
            actor.markersContainer.scale.set(this.bonesScaling, this.bonesScaling, this.bonesScaling);
            this.actionGroup.add(actor.markersContainer);
          }

          const opticalSegments: OpticalSegmentGroup [] = [];

          if ( this.options.opticalSegmentsList != undefined) {
            for (const segment of this.options.opticalSegmentsList)
              opticalSegments.push(segment);
          }

          if (actor.markersContainer) {
            const markerList = actor.markersContainer.children;
            let configurationFound = false;

            if (this.options.segmentsModelUri != "") {
                const model = new Cgm2(markerList, this.http, this.options.segmentsModelUri);
                const configuration = await model.findConfiguration();
                if (configuration != Cgm2Congfiguration.None) {
                  const segments = await model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
            } else {
              if (!configurationFound) {
                const model = new PlugInGait(markerList);
                const configuration = model.checkPluginGate();
                if (configuration != PlugInGaitConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);

                  configurationFound = true;
                }
              }

              if (!configurationFound) {
                const model = new MotekHbm(markerList);
                const configuration = model.findConfiguration();
                if (configuration != MotekHbmConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              if (!configurationFound) {
                const model = new MotionAnalysis(markerList);
                const configuration = model.checkMotionAnalysis();
                if (configuration != MotionAnalysisConfiguration.None) {
                  const segments = model.getSegments(configuration);
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              if (!configurationFound) {
                const model = new Optitrack(markerList);
                const configuration = model.checkOptitrack();
                if (configuration != OptitrackConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              if (!configurationFound) {
                const model = new Qualisys(markerList);
                const configuration = model.findConfiguration();
                if (configuration != QualisysConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              if (!configurationFound) {
                const model = new SttSystems(markerList);
                const configuration = model.findConfiguration();
                if (configuration != SttSystemsConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              if (!configurationFound) {
                const model = new BtsTech(markerList);
                const configuration = model.findConfiguration();
                if (configuration != BtsTechConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              if (!configurationFound) {
                const model = new Noraxon(markerList);
                const configuration = model.findConfiguration();
                if (configuration != NoraxonConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }

              // Check for pig bones if we did not find any configuration yet
              if (!configurationFound) {
                const model = new PlugInGaitBones(markerList);
                const configuration = model.findConfiguration();
                if (configuration != PlugInGaitBonesConfiguration.None) {
                  const segments = model.getSegments();
                  for (const s of segments)
                    opticalSegments.push(s);
                }
              }
            }

            if (opticalSegments.length > 0) {
              actor.segmentsRenderer = new OpticalSegmentRenderer(actor.markersContainer.children, opticalSegments, this.colorService);

              if (this.options.enableOpticalSegments != undefined)
                actor.segmentsRenderer.toggleVisibility(this.options.enableOpticalSegments);
            }
          }

          if (actor.segmentsRenderer) {
            actor.segmentsRenderer.scale(this.bonesScaling);
            this.actionGroup.add(actor.segmentsRenderer.getSegmentsContainer());
          }

          if (this.camerasContainer !== undefined) {
            this.camerasContainer.visible = this.options.showCameras;
            this.camerasContainer.scale.set(this.bonesScaling, this.bonesScaling, this.bonesScaling);
            this.actionGroup.add(this.camerasContainer);
          }
        }

        if (this.options.markerTraces) {
          setTimeout( ()=> {
            this.toggleMarkersTrajectory();
          }, 300);
        }
      }

      toggleSkeleton() {
        for (const actor of this.actors) {
          if (actor.skeletonMesh !== undefined)
            actor.skeletonMesh.visible = !actor.skeletonMesh.visible;

          if (actor.referenceSystemHelper !== undefined)
            actor.referenceSystemHelper.visible = !actor.referenceSystemHelper.visible;
        }
      }

      toggleCameras() {
        if (this.camerasContainer !== undefined)
          this.camerasContainer.visible = !this.camerasContainer.visible;
      }

      createMarkerLabels(actor: any) {
        const loader = new THREE.FontLoader();
        loader.load( 'assets/helvetiker_regular.typeface.json', ( font ) => {

          for ( const child of actor.markersContainer.children) {
            const matLite = new THREE.MeshBasicMaterial( {
              color: 0xffffff,
              transparent: true,
              opacity: 0.7,
              side: THREE.DoubleSide
            } );

            const message = child.name;
            const shapes = font.generateShapes( message, .03 );
            const geometry = new THREE.ShapeBufferGeometry( shapes );
            const textMesh = new THREE.Mesh(geometry, matLite);
            textMesh.rotation.x = Math.PI/2;
            textMesh.rotation.y = Math.PI/2;
            textMesh.position.y += 0.02;

            child.add(textMesh);
          }
          actor.hasMarkerLabels = true;
        } );
      }
      toggleMarkers() {
        for (const actor of this.actors) {
          if (actor.markersContainer !== undefined)
            actor.markersContainer.visible = !actor.markersContainer.visible;
            if (!actor.hasMarkerLabels && actor.markersContainer.visible) {
                this.createMarkerLabels(actor);
            }
        }
      }

      toggleTrasparency(index?: number) {
        this.isTrasparent = !this.isTrasparent;

          if (this.isTrasparent) {
            for (let index = 0; index < this.actors.length; index++ ) {
              if (this.actors[index] && this.actors[index].bonesHelper)
                this.actors[index].bonesHelper.visible = true;
            }
            //wireframe material
            let transparentColor = 0xaaaaaa;
            if (this.options.playerTheme == 'light')
              transparentColor = 0x777777;

            if (!this.transparentMaterial) {
              this.transparentMaterial = new THREE.MeshPhongMaterial( {
                color: transparentColor,
                wireframe: true,
                flatShading: false,
                skinning : true,
                //morphTargets: true,
                transparent: true,
                opacity: 0.1
              } );
            }

            if (this.graphicalMesh)
              this.setMaterial(this.graphicalMesh, this.transparentMaterial);
          } else {
            for (let index = 0; index < this.actors.length; index++ ) {
              if (this.actors[index] && this.actors[index].bonesHelper)
                this.actors[index].bonesHelper.visible = false;
            }
            if (this.graphicalMesh)
              this.restoreMaterial(this.graphicalMesh);
          }
      }

      addSamePrefix(actor, bones) {
        if (actor.rootBone.name.indexOf(':') == -1)
          return;

        const prefix = actor.rootBone.name.split(':')[0];

        const toBeAdded = [];
        for (const b of bones) {
          if (b.name.indexOf(':') == -1)
            continue;

          let found = false;
          for (const bb of actor.skeleton) {
            if (bb.name == b.name) {
              found = true;
              break;
            }
          }
          if (!found) {
            const bPrefix = b.name.split(':')[0];
            if (prefix == bPrefix)
              toBeAdded.push(b);
          }
        }
        for (const b of toBeAdded)
          actor.skeleton.push(b);

      }

      getSkeletonData(skeleton: any) {
        this.actors = [];

        let rootNames = ["reference", "hips", "root", "pelvis", "centreofmass", "centerofmass", "pelv", "lasi", "hip"]; //ordered
        if (this.isQualisysSkeleton(skeleton) && !this.isSkeletonSupported(skeleton))
          rootNames = ["hips", "root", "pelvis", "reference", "centreofmass", "centerofmass", "pelv", "lasi", "hip"]; //ordered

        for (let lastIndex = 0; lastIndex < skeleton.bones.length; lastIndex++) {
          const rootIndex = SkeletonMathHelper.findRoot(skeleton.bones, lastIndex, rootNames);
          if (rootIndex == -1)
            continue;

          let rootBone = skeleton.bones[rootIndex];

          if (this.noitomTest)
            rootBone = rootBone.children[0];

          let found = false;
          for (const actor of this.actors) {

            let samePrefix = false;
            const newRoot = skeleton.bones[rootIndex];
            const currentRoot = skeleton.bones[actor.rootIndex];

            if (newRoot.name.indexOf(':')!=-1 && currentRoot.name.indexOf(':')!=-1) {
              const newRootActorName = newRoot.name.split(':')[0];
              const currentRootActorName = currentRoot.name.split(':')[0];
              samePrefix = (currentRootActorName == newRootActorName);
            }
            if ((rootIndex == actor.rootIndex || SkeletonMathHelper.checkChildren(actor.rootBone, rootBone)) || samePrefix) {
              found = true;
              break;
            }
          }

          if (found)
            continue;

          const actor: Actor = new Actor();

          actor.rootBone = rootBone;
          actor.rootIndex = rootIndex;

          lastIndex = rootIndex;

          actor.skeleton = [actor.rootBone];
          SkeletonMathHelper.addAllChildrens(actor.rootBone, actor.skeleton);

          actor.firstBone = 0;
          if (this.noitomTest)
            actor.firstBone = 1;

          this.actors.push(actor);

          /*if(this.actors.length > 1)
            this.enableFollowCam = false;*/
        }

        if (this.actors.length == 0) {
          const actor = new Actor();
          if (skeleton.bones && skeleton.bones.length > 0) {
            actor.rootBone = skeleton.bones[0];
            actor.skeleton = skeleton.bones;
            SkeletonMathHelper.addAllChildrens(actor.rootBone, actor.skeleton);
          } else {
            actor.rootBone = new THREE.Bone();
            actor.skeleton = [actor.rootBone];
          }
          this.actors.push(actor);
        }

        for (const actor of this.actors) {
          this.addSamePrefix(actor, skeleton.bones);
        }

        for (const b of skeleton.bones) {
          let found = false;
          for (const actor of this.actors) {
            if (found)
              break;

            for (const bb of actor.skeleton) {
              if (bb.name == b.name) {
                found = true;
                break;
              }
            }
          }
          if (!found)
            this.actors[0].skeleton.push(b);
        }

        this.rootBone = this.actors[0].rootBone;
      }

      private renameSkeletonPrefix(skeleton: any, sourcePrefix: string, destPrefix: string) {
        for (let i=0; i< skeleton.bones.length; i++) {
          skeleton.bones[i].name = skeleton.bones[i].name.replace(sourcePrefix, destPrefix);
        }
      }

      private renamePrefix(result: any, sourcePrefix: string, destPrefix: string) {
        this.renameSkeletonPrefix(result.skeleton,sourcePrefix, destPrefix);

        for (let takeIndex=0; takeIndex < result.clips.length; takeIndex++ )
            this.renameclipPrefix(result.clips[takeIndex], sourcePrefix, destPrefix);
      }

      private renameclipPrefix(clip: any, sourcePrefix: string, destPrefix: string) {
        for (const track of clip.tracks)
          track.name = track.name.replace(sourcePrefix, destPrefix);
      }

      private countVertices(mesh: any) {
        let vertexCount = 0;
        let triangleCount = 0;
        mesh.traverse( (child) => {
        if (child.isMesh && child.geometry && child.geometry.attributes) {
          vertexCount += child.geometry.attributes.position.count;
          if (child.geometry.index)
            triangleCount += child.geometry.index.count / 3;
        }
        });
      }

      private setMaterial(mesh: any, mat: any) {
        mesh.traverse( (child) => {
        if (child.isMesh) {
          if (child.geometry.name != "mvshlf-face")
            child.material = mat;
          }
        });
      }

      private restoreMaterial(mesh: any ) {
        mesh.traverse( (child) => {
        if (child.isMesh) {
          if (child.geometry.name != "mvshlf-face") {
            const mat = this.materialMap.get(child.uuid);
            if (mat)
              child.material = mat;
          }
          }
        });
      }

      private materialCopy(mesh: any) {
        mesh.traverse( (child) => {
          if (child.isMesh) {
            child.castShadow = true;
            if (this.options.lightComplexity > 1)
              child.receiveShadow = true;

            child.frustumCulled = false;
            this.materialMap.set(child.uuid, child.material);
          }
        });
      }

      private setAnimation(result: any): Promise<number> {

        if (result == undefined)
          return Promise.resolve(0);

        if (this.options.loadAnyModel && result.mesh) {
          return new Promise( (resolve) => {
            this.graphicalMesh = result.mesh;
            this.countVertices(result.mesh);

            this.actionGroup.add(this.graphicalMesh);

            //this.materialCopy(this.graphicalMesh);
            this.transparencyAvailable = true;

            const box = new THREE.Box3().setFromObject(this.graphicalMesh);
            const sizes = box.getSize(new THREE.Vector3());
            const center = box.getCenter(new THREE.Vector3());
            if (!this.animationClip.animation.userData || (this.animationClip.animation.userData && !this.animationClip.animation.userData.cached)) {
              const height = sizes.y;
              const realHeight = this.options.realHeight;
              const scale = realHeight / height;
              this.graphicalMesh.position.x += (this.graphicalMesh.position.x - center.x*scale);
              this.graphicalMesh.position.y += (this.graphicalMesh.position.y - center.y*scale + realHeight * 0.5);
              this.graphicalMesh.position.z += (this.graphicalMesh.position.z - center.z*scale);

              this.graphicalMesh.scale.set(scale, scale, scale);

              this.animationClip.animation.userData = { meshScaling: scale, cached: true };
            }

            if (result.skeleton.bones && result.skeleton.bones.length > 0) {
              const actor: Actor = new Actor();
              let colors = undefined;
              if (this.options.playerTheme == 'light') {
                colors = { left: 0x2725bF, right: 0x00b29d };
              }
              actor.bonesHelper = new BonesHelper( result.skeleton.bones[0], 0, 1/this.animationClip.animation.userData.meshScaling, 1);
              actor.bodyParts = SkeletonMathHelper.inspectSkeleton( result.skeleton.bones);
              actor.bonesHelper.setBodyPart(actor.bodyParts, colors);
              actor.bonesHelper.visible = false;
              this.actors.push(actor);
              this.actionGroup.add(actor.bonesHelper);
            }

            this.actionGroup.add(result.mesh);
            this.materialCopy(this.graphicalMesh);
            this.mixer = new THREE.AnimationMixer(result.mesh);
            for (const clip of result.clips) {
              if (clip.duration > 0)
                this.motionClips.push(clip);
            }

            if (this.motionClips && this.motionClips.length > 0) {
              this.selectAnimation(0);
              resolve(this.motionClips[0].duration);
            } else
              resolve(0);
          });
        } else {
        return new Promise( (resolve) => {

            if (result.mesh) {
              this.graphicalMesh = result.mesh;
              if (this.options.meshAssetUri.indexOf('ercole') != -1) {
                this.muscleList = [];

                for (let i=1; i< this.graphicalMesh.children.length; i++) {
                  if (i != 3)  //i==3 other muscles
                    this.muscleList.push(this.graphicalMesh.children[i]); //these can be showed to be active

                  this.graphicalMesh.children[i].material.opacity = 0.15;
                  this.graphicalMesh.children[i].material.transparent = true;
                }
                this.graphicalMesh.position.y += 1;
                this.graphicalMesh.rotation.x = Math.PI/2;
              }

              this.actionGroup.add(this.graphicalMesh);
              this.transparencyAvailable = true;
            }
            //make a copy of the animation structure before any manipulation
            //FIX ME copy animation values or account for that when caching clips

            //rename should happen for all the takes
            if (this.options.renamePrefix) {
              this.renamePrefix(result, this.options.renamePrefix.sourcePrefix, this.options.renamePrefix.destPrefix);
            }

            const iphoneXtest = HeadAnimationHelpers.isHeadBlendShapeAnimation(result);

            if (iphoneXtest) {
              const trackList = [];
              const clip = result.clips[0];
              HeadAnimationHelpers.convertTracksForHead(clip.tracks, trackList);
              const newClip = new THREE.AnimationClip(clip.name, clip.duration, trackList);
              this.removeFirstFrame(newClip);
              this.motionClips.push(newClip);
            } else {
            if (!this.noitomTest)
              this.getSkeletonData(result.skeleton);

            for (const clip of result.clips) {
                const trackList = [];
                for (const track of clip.tracks) {
                const interpolation = track.getInterpolation();
                if (track.name.indexOf('quaternion') != -1)
                    trackList.push(new THREE.QuaternionKeyframeTrack(track.name, track.times, track.values, interpolation));
                else if (track.name.indexOf('morph') != -1) { } else if (track.name.indexOf('visible') != -1)
                    trackList.push( new THREE.BooleanKeyframeTrack(track.name, track.times, track.values ));
                else
                    trackList.push( new THREE.VectorKeyframeTrack(track.name, track.times, track.values, interpolation ));
                }
                const newClip = new THREE.AnimationClip(clip.name, clip.duration, trackList);
                this.removeFirstFrame(newClip);
                this.motionClips.push(newClip);
            }
            }

            //this.duration = this.motionClips[0].duration;
            //this.playbackControl.clipDuration = this.motionClips[0].duration;

            if (iphoneXtest) {

            const faceAssetFBXUri = 'assets/meshes/head/head.gltf';
            const loader = new THREE.GLTFLoader();
            loader.load(faceAssetFBXUri, result => {
                const mesh = result.scene.children[0].children[0];
                const scale = 1000;
                mesh.scale.set(scale,scale,scale);
                mesh.translateY ( 10 );
                const group = new THREE.Group();
                group.position.y += 1;
                group.add(mesh);
                for (const child of mesh.children)
                child.visible = false;

                this.actionGroup.add(group);
                this.mixer = new THREE.AnimationMixer(mesh);

                const meshMaterials: any [] = [];
                this.getMaterialsFromOptions(meshMaterials);
                const material = meshMaterials[0];
                material.morphTargets = true;
                mesh.material = material;

                mesh.castShadow = true;
                this.mixer.clipAction(this.motionClips[0]).setEffectiveWeight(1.0).play();

                this.finalizeAnimation();
                resolve(this.duration);

            }, progress => {

            });
            } else {
            this.animationGroup = new THREE.AnimationObjectGroup();

            this.mixer = new THREE.AnimationMixer(this.animationGroup);

            if (this.isSkeletonSupported(result.skeleton) && !this.options.applyMesh && this.actors.length == 1) { //apply mesh if supported and not overridden
                this.options.applyMesh = true;
                this.options.initTransparent = true;
            }

            if (this.options.applyMesh) {
                this.loadJsonMesh(this.options.meshAssetUri).then((res) => {

                if (!this.noitomTest)
                    this.createHelpers(result.skeleton);

                this.graphicalMesh = res;

                if (this.noitomTest) {
                    let offset = this.motionClips[0].tracks[0].values[1]/100; //units in cm
                    offset -= 1.08; //length of legs in noitom mesh
                    res.position.y -= offset;
                    this.actors[0].boneContainer.position.y -= offset;
                    for (let i=0; i<this.motionClips.length; i++) {
                    for (let j = 2; j <this.motionClips[i].tracks.length; j++) {
                        const track = this.motionClips[i].tracks[j];
                        this.motionClips[i].tracks.splice(j, 1); //remove all the position tracks but the hips
                    }
                    }
                } else {
                    this.graphicalMesh.scale.set( this.bonesScaling,  this.bonesScaling,  this.bonesScaling);
                    if (this.meshToAnimationConversion != 1 && (this.animationClip.animation.userData && !this.animationClip.animation.userData.cached)) {
                        for (let i=0; i<this.motionClips.length; i++)
                        this.scaleAnimationClip(this.motionClips[i], 1/this.meshToAnimationConversion);
                    }
                }

                if ( this.options.duplicateTracksList != undefined) {
                    for (const track of this.options.duplicateTracksList) {
                    this.copyTrackProperties(track.sourceTrack, track.destinationTrack, this.motionClips[0]);
                    }
                    this.markAsModified(this.motionClips[0]);
                }

                this.materialCopy(this.graphicalMesh);

                this.toggleSkeleton();
                for (const actor of this.actors)
                  actor.bonesHelper.visible = false;
                if (this.options.initTransparent)
                    this.toggleTrasparency();

                this.finalizeAnimation();
                resolve(this.duration);
                });
            } else {
                if (!this.noitomTest) {
                    DataHelper.parseQualysisData(result.data, this.chartTracks);
                    this.chartTracksObserver.next(this.chartTracks);
                    this.createHelpers(result.skeleton);
                }

                if (this.graphicalMesh) {
                  for (const actor of this.actors)
                    actor.bonesHelper.visible = false;

                  if (this.options.animationUnits > 0 && (this.animationClip.animation.userData && !this.animationClip.animation.userData.cached)) {
                    for (let i=0; i<this.motionClips.length; i++)
                      this.scaleAnimationClip(this.motionClips[i], this.options.animationUnits);
                  }

                  const meshMaterials: any [] = [];
                  if (this.options.replaceMeshMaterials == MaterialModifier.replace &&
                    this.options.loadMeshFromFile) {
                    this.getMaterialsFromOptions(meshMaterials);
                    this.setMaterial(this.graphicalMesh, meshMaterials[0]);
                  }

                  this.materialCopy(this.graphicalMesh);
                } else {
                  for (const actor of this.actors) {
                    actor.bonesMeshHelper = new BonesMeshHelper(actor.rootBone, 0, 1/this.bonesScaling, this.opacity);
                    actor.bonesMeshHelper.setBodyPart(actor.bodyParts);
                    this.actionGroup.add(actor.bonesMeshHelper);
                  }
                }

                if (this.options.initTransparent)
                  this.toggleTrasparency();

                this.toggleSkeleton();
                this.finalizeAnimation();
                if (this.duration == undefined || this.duration == 0)
                  this.duration = 1;
                resolve(this.duration);
            }
            }
        });
      }
      }

      selectNextTake() {
        let nextIndex = this.takeIndex + 1;
        if (this.motionClips && nextIndex >= this.motionClips.length)
          nextIndex = 0;

        this.selectAnimation(nextIndex);
        return nextIndex;
      }

      selectAnimation(index: number) {
        if (index >= this.motionClips.length)
          return;

        this.takeIndex = index;
        this.mixer.time = 0;
        this.duration = this.motionClips[index].duration;

        this.mixer.stopAllAction();
        this.mixer.clipAction(this.motionClips[index]).setEffectiveWeight(1.0).play();
        this.mixer.update(0);

        /*if (this.enableFollowCam)
          this.updateFollowCamPosition();*/
      }

      markAsCached() {
        if (this.animationClip.animation.userData)
          this.animationClip.animation.userData.cached = true;
        else
          this.animationClip.animation.userData = { cached: true };
      }

      finalizeAnimation() {
        for (const actor of this.actors) {
          if (this.options.coordinates == CoordinatesType.zUp && this.options.loadMeshFromFile && this.options.skeletonUnits == 0.01)
            this.animationGroup.add(actor.rootBone); //FIXME: temporary hack for Petros
          this.animationGroup.add(actor.skeletonMesh);
        }

        this.selectAnimation(0);

        /*if(this.autoPlay)
          this.play();*/

        this.markAsCached();
      }

}

