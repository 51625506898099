<ul class="list-group list-group-flush">
<li class="list-group-item py-2 px-3"  style="background: #555555">
    <div class="form-group d-flex align-items-baseline m-0">
      <input type="text" class="form-control no-border" [(ngModel)]="comment_text" placeholder="{{placeholderText}}" style="margin-right: 5px;" (keyup.enter)="onEnter()">
      <i class="fa fa-paper-plane clickable" style="font-size: 16px; vertical-align:middle; float: right;" (click)="sendComment()"></i>
    </div>
    <span *ngIf="commentsNumberString != ''" [hidden]="show_comments" style="float: right;" class="badge badge-success">{{commentsNumberString}}</span>
    <div [hidden]="!timeSelection" class="my-2">
      <input type="checkbox" [(ngModel)]="includeTimeSelection" [disabled]="!timeSelection" name="includeTimeSelection" id="includeTimeSelection" />
      <label for="includeTimeSelection">
        Include time selection
      </label>
    </div>
  </li>
  <li class="list-group-item" *ngFor="let comment of comments">
    <app-comment *ngIf=comment.node [comment]=comment.node [baseTimecode]=baseTimecode [framerate]=framerate></app-comment>
  </li>
  <li class="list-group-item clickable" *ngIf="clipPageInfo.hasNextPage && !pendingQuery" (click)="loadMore()">Load more ...</li>
  <li class="list-group-item" *ngIf="pendingQuery">
    <i class="fa fa-spinner fa-spin"></i>
  </li>
</ul>
