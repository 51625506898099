import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'app/shared/services/organization/organization.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../core/auth.service';
import { UserUpdateService } from './user-update.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css'],
})
export class AuthCallbackComponent implements OnInit {
  public mode: string;
  public newPasswordError: string;
  public invalidOrExpiredCode: boolean = false;
  public userAccountDisabled: boolean = false;
  public userAccountNotFound: boolean = false;
  public showCompleteLogin: boolean = false;

  public emailAddress: string;
  public errorMessage: string = "";
  public textMessage: string = "";

  private code: string;
  private hidingPending: boolean = false;
  private redirect: string;
  private modal: BsModalRef;

  @ViewChild('passwordModal', { static: true }) passwordModal: BsModalRef;
  @ViewChild('recoverEmailModal', { static: true }) recoverEmailModal: BsModalRef;
  @ViewChild('verifiedEmailModal', { static: true }) verifiedEmailModal: BsModalRef;
  @ViewChild('redeemModal', { static: true }) redeemModal: BsModalRef;
  @ViewChild('tenantModal', { static: true }) tenantModal: BsModalRef;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserUpdateService,
    private modalService: BsModalService,
    private organizationService: OrganizationService,
    @Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      const continueUrl = queryParams.get('continueUrl');
      this.code = queryParams.get('oobCode');
      this.mode = queryParams.get('mode');
      this.redirect = continueUrl;

      if (isPlatformBrowser(this.platformId)) {
        this.organizationService.getCurrentOrganization().then(org => {
          if (org?.domain === undefined) {
            this.showModal(this.tenantModal);
          } else {
            this.showCallbackModal();
          }
        });
      }
    });
  }

  public showCallbackModal() {
    if (this.modal) {
      this.modal.hide();
    }
    if (this.mode === 'resetPassword') {
      this.showModal(this.passwordModal);
    }
    if (this.mode === 'recoverEmail') {
      this.showModal(this.recoverEmailModal);
      this.recoverEmailNow();
    }
    if (this.mode === 'verifyEmail') {
      this.showModal(this.verifiedEmailModal);
      this.authService.handleEmailVerification(this.code, this.redirect);
    }
    if (this.mode === 'redeemInvitation') {
      this.showModal(this.redeemModal);
    }
  }

  async recoverEmailNow() {
    this.errorMessage = "";
    try {
      const email = await this.authService.handleRecoverEmail(this.code);
      this.textMessage = "Congratulations, " +  email + " has been restored for your account. ";
      this.emailAddress = email;
      this.userService.updateUser({email: email}).subscribe();
      this.showCompleteLogin = true;
    } catch(error) {
      this.errorMessage = error;
    }
  }

  completeRestore(password: string) {
      this.errorMessage = "";
      this.textMessage = "";
      this.authService.submitLogin(this.emailAddress, password).then ( () => {
        this.hideModal();
      }).catch((error) => {
        switch (error.code) {
          case 'auth/user-disabled':
            this.errorMessage = 'Account disabled';
            break;
          case 'auth/invalid-email':
            this.errorMessage = `The email address ${this.emailAddress} is not registered with Moveshelf.com`;
            break;
          case 'auth/user-not-found':
            this.errorMessage = `The email address ${this.emailAddress} is not registered with Moveshelf.com`;
            break;
          case 'auth/wrong-password':
            this.errorMessage = 'Incorrect password';
            break;
          default:
            this.errorMessage = 'Unknown error';
            console.log(error);
        }
      });
  }


  public showModal(template, size: string = 'md') {
    this.modal = this.modalService.show(template, {
      class: 'modal-' + size,
      ignoreBackdropClick: true,
      keyboard: false
    });
    //this.modalService.onHide.subscribe(() => {} );
  }

  public hideModal(delay: number = 10) {
    if (isPlatformBrowser(this.platformId) && !this.hidingPending) {
      this.hidingPending = true;
      setTimeout( () => {
        this.modal.hide();
        this.hidingPending = false;
      }, delay)
    }
  }

  public redeemNow(redeemModal: any) {
    /*this.authService.submitRedeem(this.emailAddress).then ( () => {
      this.showRedeemModal = false;
      redeemModal.hide();
      //this.authService.handleRedeem();
    });*/

    //this.authService.removeOutletAndNavigate(this.redirect);
    this.hideModal();
    this.authService.handleRedeem(this.emailAddress, this.redirect);
  }

  public setPassword(newPassword: string) {
    this.newPasswordError = null;
    this.invalidOrExpiredCode = false;
    this.userAccountDisabled = false;
    this.userAccountNotFound = false;
    this.authService.completePasswordReset(newPassword, this.code, this.redirect).then( () => {

      this.hideModal();
    } )
      .catch(error => {
        console.log(error);
        switch (error.code) {
          case 'auth/expired-action-code':
          case 'auth/invalid-action-code':
            this.invalidOrExpiredCode = true;
            break;

          case 'auth/user-disabled':
            this.userAccountDisabled = true;
            break;

          case 'auth/user-not-found':
            this.userAccountNotFound = true;
            break;

          case 'auth/weak-password':
            this.newPasswordError = error.message;
            break;
          default:
            console.log(error);
        }
      });
  }
}
