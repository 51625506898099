import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

export enum MoreActionsEvent {
  Edit = "edit",
  Delete = "delete",
  Approve = "approve",
  Deny = "deny"
}

@Component({
  selector: 'app-more-actions',
  templateUrl: './more-actions.component.html',
  styleUrls: ['./more-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreActionsComponent implements OnInit {
  @Output() actionClick: EventEmitter<MoreActionsEvent> = new EventEmitter<MoreActionsEvent>();

  @Input() allowEdit: boolean = false;

  showApprove: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  onEditClick() {
    this.actionClick.emit(MoreActionsEvent.Edit);
  }

  showConfirm() {
    this.showApprove = true;
  }

  hideConfirm() {
    this.showApprove = false;
  }

  onDeleteClick() {
    this.actionClick.emit(MoreActionsEvent.Delete);
  }

  onApproveClick() {
    this.actionClick.emit(MoreActionsEvent.Approve);
  }

  onDenyClick() {
    this.actionClick.emit(MoreActionsEvent.Deny);
  }
}
