export enum OptitrackConfiguration {
    None,
    Optitrack37, //37 markers
    Optitrack41, //41 markers
    Optitrack50, //50 markers
    Optitrack54 //54
}

export class Optitrack {

    markerList: any[];

    readonly head = ["HeadTop", "HeadFront", "HeadSide"];
    readonly head50 = ["HeadTop", "HeadFront", "HeadLeft", "HeadRight"];

    readonly upperLimbLeft = ["LShoulderBack", "LShoulderTop", "LElbowOut", "LUArmHigh", "LWristIn", "LWristOut", "LHandOut"];
    readonly upperLimbRight = ["RShoulderBack", "RShoulderTop", "RElbowOut", "RUArmHigh", "RWristIn", "RWristOut", "RHandOut"];

    readonly upperLimbLeft54 = ["LShoulderBack", "LShoulderTop", "LElbowOut", "LUArmHigh", "LWristOut", "LFArm", "LHandOut", "LHandIn", "LThumb", "LIndex", "LPinky"];
    readonly upperLimbRight54 = ["RShoulderBack", "RShoulderTop", "RElbowOut", "RUArmHigh", "RWristOut", "RFArm", "RHandOut", "RHandIn", "RThumb", "RIndex", "RPinky"];

    readonly torso = ["Chest", "BackLeft", "BackRight", "BackTop"];
    readonly torso50 = ["ChestTop", "ChestLow", "BackLeft", "BackRight", "BackTop"];
    readonly pelvis = ["WaistLFront", "WaistRFront", "WaistLBack", "WaistRBack"];
    readonly pelvis50 = ["WaistLFront", "WaistRFront", "WaistLBack", "WaistRBack", "WaistCBack"];
    readonly pelvis54 = ["WaistRFront", "WaistLBack", "WaistRBack", "WaistCBack"];

    readonly lowerLimbLeft = ["LKneeOut", "LThigh", "LShin"];
    readonly lowerLimbLeft50 = ["LKneeOut", "LThighFront", "LThighSide", "LShin"];
    readonly lowerLimbRight = ["RKneeOut", "RThigh", "RShin"];
    readonly lowerLimbRight50 = ["RKneeOut", "RThighFront", "RThighSide" , "RShin"];

    readonly leftFoot = ["LAnkleOut", "LToeOut", "LToeIn"];
    readonly leftFoot41 = ["LAnkleOut", "LToeOut", "LToeIn", "LHeel", "LToeTip"];
    readonly rightFoot = ["RAnkleOut", "RToeOut", "RToeIn"];
    readonly rightFoot41 = ["RAnkleOut", "RToeOut", "RToeIn", "RHeel", "RToeTip"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];
        //if(this.hasAllMarkers(this.head))
          segments.push({ name: "head", markerList: ["HeadTop", "HeadFront", "HeadSide", "HeadLeft", "HeadRight"], allConnected: true});

        //if(this.hasAllMarkers(this.upperLimbRight))
        {
          segments.push({ name: "rightArm", markerList: ["RShoulderBack", "RShoulderTop", "RUArmHigh", "RElbowOut"], allConnected: true, side: 2 });
          segments.push({ name: "rightLowerArm", markerList: ["RElbowOut", "RFArm", "RWristOut"], allConnected: true, side: 2 });
          segments.push({ name: "rightHand", markerList: ["RWristIn", "RWristOut", "RHandIn", "RHandOut"], allConnected: true, side: 2 });
          segments.push({ name: "rightThumb", markerList: ["RWristOut", "RThumb"], side: 2 });
          segments.push({ name: "rightIndex", markerList: ["RHandIn", "RIndex"], side: 2 });
          segments.push({ name: "rightPinky", markerList: ["RHandOut", "RPinky"], side: 2 });
        }

        //if(this.hasAllMarkers(this.upperLimbLeft))
        {
          segments.push({ name: "leftArm", markerList: ["LShoulderBack", "LShoulderTop", "LUArmHigh", "LElbowOut"], allConnected: true, side: 1 });
          segments.push({ name: "leftLowerArm", markerList: ["LElbowOut", "LFArm", "LWristOut"], allConnected: true, side: 1 });
          segments.push({ name: "leftHand", markerList: ["LWristIn", "LWristOut", "LHandIn", "LHandOut"], allConnected: true, side: 1 });
          segments.push({ name: "leftThumb", markerList: ["LWristOut", "LThumb"], side: 1 });
          segments.push({ name: "leftIndex", markerList: ["LHandIn", "LIndex"], side: 1 });
          segments.push({ name: "leftPinky", markerList: ["LHandOut", "LPinky"], side: 1 });
        }

        //if(this.hasAllMarkers(this.pelvis))
          segments.push(  { name: "pelvis", markerList: ["WaistLFront", "WaistRFront", "WaistLBack", "WaistRBack", "WaistCBack"], allConnected: true });

        //if(this.hasAllMarkers(this.torso))
          segments.push(  { name: "torso", markerList:  ["Chest", "ChestLow", "ChestTop", "BackLeft", "BackRight", "BackTop"], allConnected: true });

        //if(this.hasAllMarkers(this.lowerLimbRight) && this.hasAllMarkers(this.pelvis) && this.hasAllMarkers(this.leftFoot))
        {
          segments.push(  { name: "leftLegUp", markerList:  ["WaistLFront", "WaistLBack", "LKneeOut", "LThigh", "LThighFront", "LThighSide"], allConnected: true, side: 1 });
          segments.push(  { name: "leftLeg", markerList:  ["LKneeOut", "LShin", "LAnkleOut"], allConnected: true, side: 1 });
          segments.push(  { name: "leftFoot", markerList:  ["LAnkleOut", "LToeOut", "LToeIn", "LHeel", "LToeTip"], allConnected: true, side: 1 });

          segments.push(  { name: "rightLegUp", markerList:  ["WaistRFront", "WaistRBack", "RKneeOut", "RThigh", "RThighFront", "RThighSide"], allConnected: true, side: 2 });
          segments.push(  { name: "rightLeg", markerList:  ["RKneeOut", "RShin", "RAnkleOut"], allConnected: true, side: 2 });
          segments.push(  { name: "rightFoot", markerList:  ["RAnkleOut", "RToeOut", "RToeIn", "RHeel", "RToeTip"], allConnected: true, side: 2 });
        }

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];
        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      checkOptitrack() {

        const fullBody54 = [
          this.head50,
          this.torso50,
          this.pelvis54,
          this.upperLimbLeft54,
          this.upperLimbRight54,
          this.lowerLimbLeft50,
          this.lowerLimbRight50,
          this.leftFoot41,
          this.rightFoot41
        ];

        if (this.checkConfiguration(fullBody54).length <= 1) {
            return OptitrackConfiguration.Optitrack54;
        }

        const fullBody50 = [
          this.head50,
          this.torso50,
          this.pelvis50,
          this.upperLimbLeft,
          this.upperLimbRight,
          this.lowerLimbLeft50,
          this.lowerLimbRight50,
          this.leftFoot41,
          this.rightFoot41
        ];

        if (this.checkConfiguration(fullBody50).length <= 1) {
            return OptitrackConfiguration.Optitrack50;
        }

        const fullBody41 = [
          this.head,
          this.torso,
          this.pelvis,
          this.upperLimbLeft,
          this.upperLimbRight,
          this.lowerLimbLeft,
          this.lowerLimbRight,
          this.leftFoot41,
          this.rightFoot41
        ];

        if (this.checkConfiguration(fullBody41).length <= 1) {
            return OptitrackConfiguration.Optitrack41;
        }

        const fullBody37 = [
          this.head,
          this.torso,
          this.pelvis,
          this.upperLimbLeft,
          this.upperLimbRight,
          this.lowerLimbLeft,
          this.lowerLimbRight,
          this.leftFoot,
          this.rightFoot
        ];

        if (this.checkConfiguration(fullBody37).length <= 1) {
            return OptitrackConfiguration.Optitrack37;
        }

        return OptitrackConfiguration.None;
      }

}
