import * as THREE from 'three';
import { PoseComparisonService } from "../core/pose-comparison.service";
import { OpticalSegmentRenderer } from "./optical-segment-renderer";

interface Joint {
    part: string,
    joint: any,
    obj3d: any,
}
export class PosenetSkeletonHelper {

    constructor(color: any = 0x00ff00) {
        this.color = color;
        this.skeletonGroup = new THREE.Group();
    }

    segments: any;
    spheresGroup: any;
    segmentsGroup: any;

    skeletonGroup: THREE.Group;

    public joints: Joint[];
    sphereMmaterial: any;
    color: any;

    clipId: string;
    comp: PoseComparisonService;

    defaultJoints = { nodes: [{ part: 'leftShoulder' },
    { part: 'rightShoulder' },
    { part: 'rightHip' },
    { part: 'leftHip' },
    { part: 'leftKnee' },
    { part: 'leftAnkle' },
    { part: 'rightKnee' },
    { part: 'rightAnkle' },
    { part: 'rightElbow' },
    { part: 'rightWrist' },
    { part: 'leftElbow' },
    { part: 'leftWrist' }],
    chest: [0, 1, 2, 3], leftLeg: [3, 4, 5], rightLeg: [2, 6, 7], leftArm: [0, 10, 11], rightArm: [1, 8, 9] };

    setServiceDetails(clipId: string, comp: PoseComparisonService) {
      this.clipId = clipId;
      this.comp = comp;
    }

    getSkeletonGroup() {
      return this.skeletonGroup;
    }

    createPoints(joints: any = this.defaultJoints) {
        const jointsColor = this.color;
        const lineColor = this.color;
        const size = 0.02;
        const geometry = new THREE.SphereGeometry( size, 32, 32 );
        this.sphereMmaterial = new THREE.MeshBasicMaterial( {color: jointsColor } );

        this.joints = [];
        this.spheresGroup = new THREE.Group();
        for (const joint of joints.nodes) {
          const sphere = new THREE.Mesh( geometry, this.sphereMmaterial );
          this.joints.push({ part: joint.part, joint: joint.obj, obj3d: sphere });
          this.spheresGroup.add(sphere);
        }

        this.skeletonGroup.add(this.spheresGroup);

        this.segments = [];

        const lines = this.segments;
        const p = this.joints;

        if (joints.chest && joints.chest.length > 0) {
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.chest[0]].obj3d, p[joints.chest[1]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.chest[1]].obj3d, p[joints.chest[2]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.chest[2]].obj3d, p[joints.chest[3]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.chest[3]].obj3d, p[joints.chest[0]].obj3d, lineColor));
        }

        if (joints.leftLeg && joints.leftLeg.length > 0) {
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.leftLeg[0]].obj3d, p[joints.leftLeg[1]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.leftLeg[1]].obj3d, p[joints.leftLeg[2]].obj3d, lineColor));
        }

        if (joints.rightLeg && joints.rightLeg.length > 0) {
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.rightLeg[0]].obj3d, p[joints.rightLeg[1]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.rightLeg[1]].obj3d, p[joints.rightLeg[2]].obj3d, lineColor));
        }

        if (joints.leftArm && joints.leftArm.length > 0) {
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.leftArm[0]].obj3d, p[joints.leftArm[1]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.leftArm[1]].obj3d, p[joints.leftArm[2]].obj3d, lineColor));
        }

        if (joints.rightArm && joints.rightArm.length > 0) {
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.rightArm[0]].obj3d, p[joints.rightArm[1]].obj3d, lineColor));
          lines.push(OpticalSegmentRenderer.createSegment(p[joints.rightArm[1]].obj3d, p[joints.rightArm[2]].obj3d, lineColor));
        }

        this.segmentsGroup = new THREE.Group();

        for (const line of lines)
          this.segmentsGroup.add(line);

        this.skeletonGroup.add( this.segmentsGroup );
      }

      toggleVisibility() {
        this.segmentsGroup.visible = !this.segmentsGroup.visible;
        this.spheresGroup.visible = !this.spheresGroup.visible;
      }

      update(time?: number, pose?: any) {
        for (const j of this.joints) {
            if (pose) {
                for (const pos of pose) {
                  if (j.part == pos.part)
                    j.obj3d.position.copy(pos.position);
                }
            } else if (j.joint)
                j.joint.getWorldPosition(j.obj3d.position);
        }

        for (const line of this.segments)
          line.geometry.verticesNeedUpdate = true;

        if (this.comp && time) {
          const keyframe = {time: time, pose: []};
          for (const j of this.joints) {
            const vec = new THREE.Vector3();
            vec.copy( j.obj3d.position);

            keyframe.pose.push( { part: j.part, position: vec });
          }
          this.comp.setKeyframes(this.clipId, [keyframe], true);
        }
      }

      compare(time: number) {

        const p = this.joints;

        for (let r=0; r < 360; r++) {
            const keyframe = {time: time, pose: []};
            const quaternion = new THREE.Quaternion();
            quaternion.setFromAxisAngle( new THREE.Vector3( 0, 1, 0 ), r * Math.PI / 180 );

            for (const j of p) {
              /*j.obj.position.applyQuaternion(quaternion);
              j.obj.updateMatrixWorld();

              let vec = new THREE.Vector3();
              vec.copy(j.obj.position)
              vec.project(this.camera);*/
              const vec = new THREE.Vector3();
              vec.copy( j.obj3d.position);
              vec.applyQuaternion(quaternion);

              keyframe.pose.push( { part: j.part, position: vec });
            }
            const value = this.comp.setKeyframes(this.clipId, [keyframe]);

            const th = 0.995;
            if (value >= th) {
              //this.pause()
              this.sphereMmaterial.color = new THREE.Color(0xff0000);
              console.log(value);
              break;
            } else
              this.sphereMmaterial.color = new THREE.Color(0xffff00);
          }
      }
}
