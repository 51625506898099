import { Component, Input, OnInit } from '@angular/core';
import { TrialChartsService } from './multi-chart/trial-charts.service';
import { ColorService } from './services/color-service/color-service.service';

@Component({
  selector: 'app-classifier',
  templateUrl: './classifier.component.html',
  styleUrls: ['./classifier.component.scss']
})
export class ClassifierComponent implements OnInit {

  @Input() predictions;

  predictionBarChart;


  constructor(private trialChartsService: TrialChartsService, private colorService: ColorService,) { }

  ngOnInit() {

    const classes = ['True equinus', 'Crouch', 'Jump', 'Apparent equinus'];

    const values = [];
    for ( let i=0; i<this.predictions.length; i++) {
      values.push( {"label": classes[i], "values": {"mean": this.predictions[i]} });
    }

    this.predictionBarChart = {
      id: "barChart",
      values: [values],
      labels: {
        title: ["Probability"],
        hAxis: "Class",
        vAxis: "Probability",
      },
      colors: [ this.colorService.right, this.colorService.cyan, this.colorService.gray]
    };
  }

}
