<div class="d-flex">
  <div class="mr-2">
    <img class="rounded-circle" width=36 src="{{comment.author.avatar}}" alt="{{comment.author.displayName}}" />
  </div>
  <div class="flex-fill">
    <!-- <p class=""> -->
    <div class="row">
    <div class="col small text-muted">
      {{comment.author.displayName}},
      <i>{{comment.created | date:'MMM d y HH:mm'}}</i>
      <br>
      <span *ngIf="comment.timeSelection" class="float-left mt-1 mb-2 clickable" (click)="setTimeSelection()">
        {{comment.timeSelection.start | timecodeFormatter:baseTimecode:framerate}}
        <ng-container *ngIf="comment.timeSelection.start != comment.timeSelection.end">
          &ndash;
          {{comment.timeSelection.end | timecodeFormatter:baseTimecode:framerate}}
        </ng-container>
      </span>
    </div>
    <div class="col-2 float-right">
      <app-more-actions style="float: right;" [allowEdit]=allowCommentEdit (actionClick)=moreActionsClick($event) #moreActions></app-more-actions>
    </div>
  </div>
    <div class="row">
    <div class="col" [innerHTML]="commentHtml" *ngIf="!moreActions.showApprove else textInput">
    </div>
    </div>
  </div>
</div>

<ng-template #textInput>
  <div class="form-group col">
    <textarea class="form-control no-border"[(ngModel)]="commentText" (keyup.enter)="onEnter()"></textarea></div>
</ng-template>
