<div class="checkbox-container d-flex">
  <input class="checkbox-element" type="checkbox" [formControl]="this.getControl()" (mousedown)="avoidFocus($event)" />
  <span class="fa-stack">
    <i class="fa fa-circle"
      [class]="control.value ? colorClass : 'text-secondary'"
      [tooltip]="tooltip"></i>
    <i class="fa fa-minus checkbox-chart-line"
      [class]="control.value ? colorClass : 'text-secondary'"></i>
  </span>
  <label [class]="{'text-muted': !control.value, 'wide-label': increaseLabelSize}">{{label}}</label>
</div>
