import { Component, EventEmitter, OnInit, Output } from "@angular/core";

export type GoniometerOptions = 'Left Knee' | 'Right Knee' | 'Left Ankle' | 'Right Ankle';

@Component({
  selector: 'app-goniometer-dropdown',
  templateUrl: './goniometer-dropdown.component.html',
  styleUrls: ['./goniometer-dropdown.component.scss']
})
export class GoniometerDropdownComponent implements OnInit {
  public options: GoniometerOptions[] = ['Left Knee', 'Right Knee'];
  public selectedOption: GoniometerOptions = this.options[0];
  @Output() public currentSelection: EventEmitter<GoniometerOptions> = new EventEmitter<GoniometerOptions>();
  
  // constructor() {}

  ngOnInit(): void {
    this.currentSelection.emit(this.selectedOption);
  }

  public onChangeSelection(event: any): void {
    this.selectedOption = event.target.value;
    this.currentSelection.emit(this.selectedOption);
  }
}