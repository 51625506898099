const THREE = require('three');
BonesHelper.prototype = Object.create( THREE.Mesh.prototype );
BonesHelper.prototype.constructor = BonesHelper;
BonesHelper.prototype.setBodyPart = setBodyPart;

BonesHelper.prototype.updateMatrixWorld = function () {

  var vector = new THREE.Vector3();
  var vectorParent = new THREE.Vector3();

  var boneMatrix = new THREE.Matrix4();
  var matrixWorldInv = new THREE.Matrix4();
  var matr = new THREE.Matrix4();

  var axis = new THREE.Vector3();

  return function updateMatrixWorld( force ) {

    var bones = this.bones;
    var geometry = this.geometry;
    var position = geometry.getAttribute( 'position' );
    var bonesSize = this.bonesSize;

    var localY = new THREE.Vector3();
    var localZ = new THREE.Vector3();
    var orthoX = new THREE.Vector3();
    var orthoY = new THREE.Vector3();
    var x = new THREE.Vector3();
    var y = new THREE.Vector3();

    matrixWorldInv.getInverse( this.root.matrixWorld );

    for ( var i = 0, j = 0, k = 0; i < bones.length; i ++ ) {

      var bone = bones[ i ];

      if ( bone.parent && bone.parent.isBone )
      {
        boneMatrix.multiplyMatrices( matrixWorldInv, bone.matrixWorld );
        vector.setFromMatrixPosition( boneMatrix );

        boneMatrix.multiplyMatrices( matrixWorldInv, bone.parent.matrixWorld );
        vectorParent.setFromMatrixPosition( boneMatrix );

        //var localX = new THREE.Vector3(boneMatrix.elements[0], boneMatrix.elements[1], boneMatrix.elements[2]);
        localY.set(boneMatrix.elements[4], boneMatrix.elements[5], boneMatrix.elements[6]);
        localZ.set(boneMatrix.elements[8], boneMatrix.elements[9], boneMatrix.elements[10]);

        axis.set(vector.x-vectorParent.x, vector.y-vectorParent.y, vector.z-vectorParent.z);
        axis.normalize();
        var dotZ = axis.dot(localZ);
        var dotY = axis.dot(localY);
        if(dotZ > 0.98) //the idea is only use this if axis || to localZ. Conceptually this should only be computed once to save computational time and to avoid odd jumps
        {
          orthoX.crossVectors(axis, localY);
        }
        else
        {
          orthoX.crossVectors(axis, localZ);
        }
        orthoX.normalize();
        orthoY.crossVectors(axis, orthoX);
        orthoY.normalize();

        //matr.lookAt(vectorParent, vector, new THREE.Vector3(0, 1, 0) );

        var lineLength = 0.015 * this.scaling; //default size
        if(bonesSize[j] != -1)
        {
          lineLength = bonesSize[j] * 0.1;
        }

        x.set(orthoX.x*lineLength, orthoX.y*lineLength, orthoX.z*lineLength);
        y.set(orthoY.x*lineLength, orthoY.y*lineLength, orthoY.z*lineLength);

        let sphereRadius = this.sphereRadius;

        if( this.isSmallBone(bonesSize[j]))
            sphereRadius = this.sphereRadius * (this.smallSphereScaling*1.2); //20% more to leave some slack air

        axis.x *= sphereRadius;
        axis.y *= sphereRadius;
        axis.z *= sphereRadius;

        position.setXYZ( k, vectorParent.x + x.x - y.x + axis.x, vectorParent.y + x.y - y.y + axis.y, vectorParent.z + x.z - y.z + axis.z);
        position.setXYZ( k + 1, vectorParent.x + x.x + y.x + axis.x, vectorParent.y + x.y + y.y + axis.y, vectorParent.z + x.z + y.z + axis.z);
        position.setXYZ( k + 2, vector.x - axis.x, vector.y - axis.y, vector.z - axis.z);


        position.setXYZ( k + 3, vectorParent.x + x.x + y.x + axis.x, vectorParent.y + x.y + y.y + axis.y, vectorParent.z + x.z + y.z + axis.z);
        position.setXYZ( k + 4, vectorParent.x + y.x - x.x + axis.x, vectorParent.y - x.y + y.y + axis.y, vectorParent.z - x.z + y.z + axis.z);
        position.setXYZ( k + 5, vector.x - axis.x, vector.y - axis.y, vector.z - axis.z);


        position.setXYZ( k + 6, vectorParent.x + y.x - x.x + axis.x, vectorParent.y - x.y + y.y + axis.y, vectorParent.z - x.z + y.z + axis.z);
        position.setXYZ( k + 7, vectorParent.x - x.x - y.x + axis.x, vectorParent.y - x.y - y.y + axis.y, vectorParent.z - x.z - y.z + axis.z);
        position.setXYZ( k + 8, vector.x - axis.x, vector.y - axis.y, vector.z - axis.z);


        position.setXYZ( k + 9, vectorParent.x - x.x - y.x + axis.x, vectorParent.y - x.y - y.y + axis.y, vectorParent.z - x.z - y.z + axis.z);
        position.setXYZ( k + 10, vectorParent.x + x.x - y.x + axis.x, vectorParent.y + x.y - y.y + axis.y, vectorParent.z + x.z - y.z + axis.z);
        position.setXYZ( k + 11, vector.x - axis.x, vector.y - axis.y, vector.z - axis.z);

        if(this.spheresGroup!= undefined)
          this.spheresGroup.children[j].position.set(vectorParent.x, vectorParent.y, vectorParent.z);

        k += 12;
        j += 1;
      }

    }

    geometry.getAttribute( 'position' ).needsUpdate = true;
    THREE.Object3D.prototype.updateMatrixWorld.call( this, force );

  };

}();

BonesHelper.prototype.changeColor = function changeColor()
{
  var bones = this.bones;
  var geometry = this.geometry;
  var color = geometry.getAttribute( 'color' );

  for ( var i = 0, k = 0; i < bones.length; i ++ ) {

    var bone = bones[ i ];

    if ( bone.parent && bone.parent.isBone )
    {
      color.setXYZ( k, 1, 0, 0);
      color.setXYZ( k + 1, 1, 0, 0);
      color.setXYZ( k + 2, 1, 0, 0);

      color.setXYZ( k + 3, 1, 0, 0);
      color.setXYZ( k + 4, 1, 0, 0);
      color.setXYZ( k + 5, 1, 0, 0);

      color.setXYZ( k + 6, 1, 0, 0);
      color.setXYZ( k + 7, 1, 0, 0);
      color.setXYZ( k + 8, 1, 0, 0);

      color.setXYZ( k + 9, 1, 0, 0);
      color.setXYZ( k + 10, 1, 0, 0);
      color.setXYZ( k + 11, 1, 0, 0);

      k += 12;
    }

  }

  geometry.getAttribute( 'color' ).needsUpdate = true;

};


BonesHelper.prototype.changeBoneColor = function changeColor(boneId, modColor)
{
  var geometry = this.geometry;
  var color = geometry.getAttribute( 'color' );

  var k = boneId*12;

  for(var kk=0; kk<12; kk++)
    color.setXYZ( k + kk, modColor.r, modColor.g, modColor.b);

  geometry.getAttribute( 'color' ).needsUpdate = true;

  if(this.spheresGroup!= undefined)
    this.spheresGroup.children[boneId].material.color.setRGB(modColor.r, modColor.g, modColor.b);

};

BonesHelper.prototype.modifyChildrenSize = function modifySize(bone, hierarchy, bonesSizeArray, k, size)
{
  var hierarchyParent = hierarchy[hierarchy.length-1];

  if (hierarchyParent != undefined)
  {
    for(var kk=0; kk<hierarchyParent.children.length; kk++)
    {
      if ((hierarchyParent.children[kk].name == bone.parent.name) || (hierarchyParent.bone.name == bone.parent.name) )
      {
        bonesSizeArray[k] = size;
        this.spheresGroup.children[k].scale.set(this.smallSphereScaling, this.smallSphereScaling, this.smallSphereScaling);
      }
    }
  }
}

function checkChildren(parentBone, bone) {
  var isChildOfParent = false;
  for(var i = 0; i< parentBone.children.length; i++) {
    var newParent = parentBone.children[i];

    if (bone.name !== "" && newParent.name !== "" &&  bone.name == newParent.name) {
      isChildOfParent = true;
    }
    isChildOfParent |= checkChildren(newParent, bone);
  }
  return isChildOfParent;
}

function browseHierarchyForChildren(parentBone, bone) {
  if (bone.name !== "" && parentBone.name !== "" && bone.name == parentBone.name)
    return true;

  return checkChildren(parentBone, bone);
}

function findBoneInHierarchy(boneToCheck, hierarchy)
{
  for(var b of hierarchy) {
    if (b === undefined)
      continue;

    if (b.bone.name !== "" && boneToCheck.name !== "" && b.bone.name == boneToCheck.name)
      return true;

    for(var ch of b.children)
    {
      if (b.bone.name !== "" && ch.name !== "" && ch.name == boneToCheck.name)
        return true;
    }
  }

  return false;
}

function setBodyPart(skeletonIds, colors)
{
  this.leftArm = skeletonIds.leftArm;
  this.leftLeg = skeletonIds.leftLeg;
  this.rightArm = skeletonIds.rightArm;
  this.rightLeg = skeletonIds.rightLeg;
  this.spine = skeletonIds.spine;

  var geometry = this.geometry;
  var bones = this.bones;

  var leftArm = this.leftArm;
  var leftLeg = this.leftLeg;
  var rightArm = this.rightArm;
  var rightLeg = this.rightLeg;
  var spine = this.spine;

  var smallScale = 0.05 * this.scaling;

  var leftColor = 0x0000ff;
  var rightColor = 0x00ff00;
  if(colors != undefined)
  {
    leftColor = colors.left;
    rightColor = colors.right;
  }

  var leftColor = new THREE.Color(leftColor);//0x2725bF);//0, 0, 1);
  var rightColor = new THREE.Color(rightColor); //0x00b29d);//0, 1, 0);

  var rightHand = rightArm[rightArm.length - 1];
  var leftHand = leftArm[leftArm.length - 1];

  var j = 0;
  for (var i = 0; i < bones.length; i ++ )
  {
    var bone = bones[i];

    if (bone.parent && bone.parent.isBone ) {
      var handTest = false;
      if(rightHand != undefined) {
        handTest |= checkChildren(rightHand.bone, bone.parent);
        handTest |= checkChildren(rightHand.bone, bone);
      }

      if(leftHand != undefined) {
        handTest |= checkChildren(leftHand.bone, bone.parent);
        handTest |= checkChildren(leftHand.bone, bone);
      }

      if(handTest) {
        this.bonesSize[j] = smallScale;
        this.spheresGroup.children[j].scale.set(this.smallSphereScaling, this.smallSphereScaling, this.smallSphereScaling);
      }

      var leftTest = findBoneInHierarchy(bone, leftArm) && findBoneInHierarchy(bone.parent, leftArm);
      var rightTest = findBoneInHierarchy(bone, rightArm) && findBoneInHierarchy(bone.parent, rightArm);

      leftTest |= findBoneInHierarchy(bone, leftLeg) && findBoneInHierarchy(bone.parent, leftLeg);
      rightTest |= findBoneInHierarchy(bone, rightLeg) && findBoneInHierarchy(bone.parent, rightLeg);

      if (rightTest)
        this.changeBoneColor(j, rightColor);

      if (leftTest)
        this.changeBoneColor(j, leftColor);
      j += 1;
    }

  }
}

BonesHelper.prototype.isSmallBone = function (boneSize)
{
  return boneSize < this.defaultBoneSize
}

function BonesHelper( object, firstBone, scaling, opacity ) {

  this.defaultBoneSize = 0.15*scaling;
  this.smallSphereScaling = 0.3;

  var fullBones = getBoneList( object );
  var bones = [];
  var geometry = new THREE.BufferGeometry();

  var vertices = [];
  var colors = [];
  var bonesSize = [];
  var color3 = new THREE.Color( 0.5, 0.5, 0.5);

  if(opacity < 1)
    this.transparent = true;
  else
    this.transparent = false;

  this.sphereJoints = true;

  if(this.sphereJoints)
  {
    this.sphereRadius = 0.02 * scaling;
    var sphereGeometry = new THREE.SphereGeometry( this.sphereRadius, 5, 5 ); //change to THREE.SphereBufferGeometry for better performance
    this.spheresGroup = new THREE.Group();
  }
  else
    this.sphereRadius = 0;

  for ( var i = firstBone; i < fullBones.length; i ++ ) {

    var bone = fullBones[ i ];
    bones.push(bone);

    if ( bone.parent && bone.parent.isBone ) {
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );

      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );

      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );

      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );
      colors.push( color3.r, color3.g, color3.b );

      bonesSize.push(this.defaultBoneSize);

      if(this.sphereJoints)
      {
        var sphereMaterial = new THREE.MeshPhongMaterial({
          color: color3,
          skinning : false,
          side: THREE.DoubleSide,
          flatShading: true,
          vertexColors: THREE.VertexColors,
          transparent: this.transparent,
          opacity: opacity
        } );

        var sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
        this.spheresGroup.add(sphere);
      }
    }

  }

  geometry.addAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
  geometry.addAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

  var material = new THREE.MeshPhongMaterial({
    //color: 0x777777,
    skinning : false,
    side: THREE.DoubleSide,
    flatShading: true,
    vertexColors: THREE.VertexColors,
    transparent: this.transparent,
    opacity: opacity
  } );

  THREE.Mesh.call( this, geometry, material );

  this.add(this.spheresGroup);
  this.castShadow = true;

  this.root = object;
  this.bones = bones;
  this.scaling = scaling;

  this.matrix = object.matrixWorld;
  this.matrixAutoUpdate = false;
  this.bonesSize = bonesSize;

}


function getBoneList( object ) {

  var boneList = [];

  if ( object && object.isBone ) {

    boneList.push( object );

  }

  for ( var i = 0; i < object.children.length; i ++ ) {

    boneList.push.apply( boneList, getBoneList( object.children[ i ] ) );

  }

  return boneList;

}

export { BonesHelper }
