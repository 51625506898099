import { Injectable } from '@angular/core';
import { AdditionalDataService } from 'app/core/additional-data.service';
import { ClipUploaderService, CreatedClipWithData } from 'app/core/clip-uploader.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { DirectoryOptions } from 'app/projects/project-info/project-info-data.service';
import { DirectoryUploadService, FileWrapper, JSONString } from './directory-upload.service';
import { UploadOptionEnum } from './upload-options.enum';
import { Trial } from './vicon-directory.service';

@Injectable({
  providedIn: 'root'
})
export class NoraxonDirectoryService extends DirectoryUploadService {
  private files: FileWrapper [];
  private options: DirectoryOptions = {
    filesFilter: ['.avi', '.ogv', '.mkv'],
    requireC3D: false,
    requireData: false,
    dataFileFormat: 'c3d',
  };
  private videoExtensions = ['.avi', '.ogv', '.mkv'];
  public UploadOptionEnum = UploadOptionEnum;

  constructor(
    private clipUploader: ClipUploaderService,
    private additionalData: AdditionalDataService,
  ) {
    super();
  }

  public getProviderName(): string {
    return 'Noraxon';
}

  public setFiles(newFiles: File[]): void {
    this.files = this.fileWrap(newFiles);
  }

  public setOptions(options: DirectoryOptions): void {
    if (typeof this.options === 'object') {
      this.options = {
        filesFilter: options.filesFilter || this.videoExtensions,
        requireC3D: options.requireC3D === true,
        requireData: options.requireData === true,
        dataFileFormat: options.dataFileFormat || 'c3d',
      };
    }
  }

  private async getTrialData(trialName: string, trialDate: Date): Promise<Trial> {
    return {
      name: trialName,
      notes: undefined,
      condition: 'Condition',
      forcePlates: undefined,
      plane: undefined,
      side: undefined,
      hasCharts: this.hasCharts(this.files, trialName, trialDate),
      hasVideo: false,
      date: undefined,
    };
  }

  public async getTrials(): Promise<Trial[]> {
    const contentFiles = this.files.filter(x => x.file.name == 'contents');
    const trialContentFiles = contentFiles.filter(x => x.path.indexOf('/records/') !== -1);

    const trials: Trial[] = [];
    for (const contents of trialContentFiles) {
      const trialDate = await this.getMeasurementDateFromContentFile(contents);
      const trial = await this.getTrialData(await this.getTrialNameFromContentFile(contents), trialDate);
      const trialFiles = this.files.filter(x => x.path == contents.path);
      trial.date = trialDate;
      trial.hasVideo = this.hasVideo(trialFiles);
      //trial.hasCharts = this.hasCharts(this.files, trial.name);

      trials.push(trial);
    }
    return trials;
  }

  private hasVideo(files: FileWrapper[]): boolean {
    return this.getVideosForTrialfiles(files).length > 0;
  }

  private hasCharts(files: FileWrapper[], trialName: string, trialDate: Date): boolean {
    return this.getDataFilesForTrialName(files, trialName, trialDate).length > 0;
  }

  private getDataFilesForTrialName(files: FileWrapper[], trialName: string, trialDate: Date): FileWrapper[] {
    let filteredFiles = files.filter(file => (file.file.name.replace(/\s/g, "").includes(`${trialName.replace(/\s/g, "")}.${this.options.dataFileFormat}`)));
    if (filteredFiles.length > 1) {
      filteredFiles = filteredFiles.filter(x => x.file.name.startsWith(this.dateToString(trialDate)));
    }
    return filteredFiles;
  }

  private getVideosForTrialfiles(files: FileWrapper[]): FileWrapper[] {
    return this.filterTrialFiles(files, this.videoExtensions);
  }

  private filterTrialFiles(files: FileWrapper[], validSuffixes: string[]): FileWrapper[] {
    const dataFile = files.filter(file => file.file.name === 'data');
    if (dataFile.length > 1) {
      console.error('Error: multiple data files found for the same trial');
    }
    return files.filter(file => this.isFileSuffixValid(file.file.name, validSuffixes)).concat(dataFile);
  }

  private async getUploadsForTrialName(files: FileWrapper[], trialName: string, trialDate: Date): Promise<FileWrapper[]> {
    const uploadFilesForTrial: FileWrapper[] = this.getDataFilesForTrialName(files, trialName, trialDate)
      .concat(this.filterTrialFiles(await this.getTrialFiles(trialName), this.options.filesFilter));
    /**
         * Filter files based on the uploadOption.
         * 1: split into video and non-video files.
         * 2: only return what is needed based on uploadOptions
         */
    if (this.uploadOption === this.UploadOptionEnum.VideosOnly || this.uploadOption === this.UploadOptionEnum.NonVideosOnly) {
      const { videos, nonVideos } = this.splitFilesByType(uploadFilesForTrial);
      if (this.uploadOption === this.UploadOptionEnum.VideosOnly) {
        return videos;
      } else {
        return nonVideos;
      }
    } else {
      return uploadFilesForTrial;
    }
  }

  private async getTrialFiles(trialName: string): Promise<FileWrapper[]> {
    const contentFiles = this.files.filter(x => x.file.name == 'contents');
    const trialContentFiles = contentFiles.filter(x => x.path.indexOf('/records/') !== -1);
    let contentFileIndex = -1;
    for (const [index, contentFile] of trialContentFiles.entries()) {
      if (await this.getTrialNameFromContentFile(contentFile) === trialName) {
        contentFileIndex = index;
        break;
      }
    }
    if (contentFileIndex !== -1) {
      return this.files.filter(x => x.path == trialContentFiles[contentFileIndex].path);
    }
  }

  private async getTrialNameFromContentFile(contentFile: FileWrapper): Promise<string> {
    const fileContent = await contentFile.file.text();
    return fileContent.split('name: "')[1].split('";')[0];
  }

  private async getMeasurementDateFromContentFile(contentFile: FileWrapper): Promise<Date> {
    const fileContent = await contentFile.file.text();
    const dateString = fileContent.split('dateOfMeasurement: "')[1].split('";')[0];
    const date = new Date(this.stringToDate(dateString));
    return date;
  }

  /**
   * Converts from "dd-MM-YYYY HH:mm:ss.SSS" to "YYYY-MM-ddTHH:MM:ss"
   */
  private stringToDate(dateString: string): string {
    const dayTimeSplit = dateString.split(' ');
    const dateSplit = dayTimeSplit[0].split('-');
    const timeSplit = dayTimeSplit[1].split('.');
    return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${timeSplit[0]}`;
  }

  /**
   * Converts from Date to "YYYY-MM-dd-HH-MM"
   */
  private dateToString(trialDate: Date): string {
    let month = (trialDate.getMonth() + 1).toString();
    if (+month < 9) {
      month = '0' + month;
    }
    let day = trialDate.getDate().toString();
    if (+day < 9) {
      day = '0' + day;
    }
    let hours = trialDate.getHours().toString();
    if (+hours < 9) {
      hours = '0' + day;
    }
    let minutes = trialDate.getMinutes().toString();
    if (+minutes < 9) {
      minutes = '0' + day;
    }
    return `${trialDate.getFullYear()}-${month}-${day}-${hours}-${minutes}`;
  }

  /**
   * Reads a serie of JSON stringified template settings and produce a full trial template
   * @returns a JSON string with a full trial template
   */
  private buildTrialTemplate(forcePlatesConfig: JSONString, side: JSONString, plane: JSONString): JSONString {
    if (!forcePlatesConfig && !side && !plane) {
      return undefined;
    }
    let contextFpStr = '';
    let sideStr = '';
    let planeStr = '';
    const commaStr = ', ';
    let addComma = false;
    if (forcePlatesConfig) {
      contextFpStr += `"contextForcePlates": ${forcePlatesConfig}`;
      addComma = true;
    }

    if (side) {
      if (addComma) {
        sideStr += commaStr;
      }
      sideStr += `"side": "${side}"`;
      addComma = true;
    }

    if (plane) {
      if (addComma) {
        planeStr += commaStr;
      }
      planeStr += `"plane": "${plane}"`;
      addComma = true;
    }

    return '{"trialTemplate": {' + contextFpStr + sideStr + planeStr + '}}';
  }

  public async upload(session: Session): Promise<void> {
    const clipsToCreate: Promise<CreatedClipWithData>[] = [];
    const trialDates: Date[] = [];
    for (const trial of await this.getTrials()) {
      const metadata = {
        title: trial.name,
        projectPath: `${session.projectPath}${trial.condition || 'Trials'}/`,
        description: trial.notes,
      };
      const trialTemplate = this.buildTrialTemplate(trial.forcePlates, trial.side, trial.plane);
      if (trialTemplate) {
        metadata['customOptions'] = trialTemplate;
      }
      clipsToCreate.push(this.clipUploader.createClip(session.project.name, metadata).toPromise());
      trialDates.push(trial.date);
      // Throttle down clips creation request by allowing at least 0.5s to pass. This avoids sending
      // all requests at once.
      await this.delay(500);
    }
    let index = 0;
    try {
      for await (const createdClip of clipsToCreate) {
        const clip = createdClip.data.createClips.response[0].mocapClip;
        const uploads = await this.getUploadsForTrialName(this.files, clip.title, trialDates[index]);
        await this.additionalData.uploadMultipleAdditionalDataWithoutType(clip.id, uploads).toPromise();
        index++;
      }
    } catch (clipOrDataCreationError) {
      // for..await will throw on the first clip creation exception and won't continue the process.
      // Errors on additional data creation are not currently blocking.
      console.error('Error while creating clip or data');
      console.debug('Error while creating clip or data', clipOrDataCreationError);
    }
  }

  public async getExpectedUploads(): Promise<File[]> {
    const expectedUploads: FileWrapper[] = [];
    for (const trial of await this.getTrials()) {
      expectedUploads.push(...await this.getUploadsForTrialName(this.files, trial.name, trial.date));
    }

    // Explicit casting is fine as uploads are accessing only the
    // `name` attribute, which is shared between the two signatures
    return expectedUploads as unknown as File[];
  }

  public async getSortedConditions(): Promise<string[]> {
    const conditionsArray: string[] = [];
    for (const trial of await this.getTrials()) {
      if (!conditionsArray.includes(trial.condition)) {
        conditionsArray.push(trial.condition);
      }
    }
    return conditionsArray;
  }

}
