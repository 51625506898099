/**
 * These are all available events to send to analytics service in the frontend.
 *
 * Note: the enum value (not the key) is what we will end up seeing in the dashboards
 *   so make sure it is written consistent and correct
 * Warning: be very careful about updating values, as they will be treated as new events!
 */
export enum AnalyticEvent {
  LOG_IN = 'Log In',
  GUEST_LOG_IN = 'Guest log In',
  SSO = 'SSO Log In',
  PAGE_VIEW = 'Page View',
  VIEW_PATIENTS_LIST = 'View Patients List',
  VIEW_PATIENT = 'View Patient',
  CREATE_PATIENT = 'Create a new patient',
  VIEW_REPORT = 'View Report',
  VIEW_TRIAL = 'View Trial',
  VIEW_DASHBOARD = 'View Dashboard',
  SESSION_EDIT = 'Session Edit',
  ENABLE_GONIOMETER = 'Enable Goniometer',
  DRAG_GONIOMETER = 'Drag Goniometer',
}
