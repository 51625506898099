import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SvgIconCacheService } from '../core/svg-icon-cache.service';
import { WindowService } from 'app/core/window.service';


@Component({
  standalone: true,
  selector: 'svg-icon',
  template: '<div [innerHtml]="svg"></div>',
  styles: [':host { display: inline-block; }']
})
export class SvgIconComponent implements OnInit, OnDestroy {
  @Input()
  public src: string;

  public svg: SafeHtml;

  private subs: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private window: WindowService,
    private cache: SvgIconCacheService
  ) { }

  ngOnInit() {
    if (this.window.checkWindowDefined) {
      const domain = `${this.window.getOrigin()}${this.src}`
      this.cache.getSvg(domain).subscribe(svg => {
        this.svg = this.sanitizer.bypassSecurityTrustHtml(svg);
      });
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
