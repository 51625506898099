import { Component, Input, Renderer2, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { Clip } from 'app/projects/report/report.types';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EChartsLegendService } from '../chart/echarts-legend.service';
import { EChartsService } from '../chart/echarts.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { KeyboardHandlerService } from '../services/keyboard-handler.service';
import { VideoEventsService } from '../services/video-events.service';
import { LeftRight } from './left-right';
import { LeftRightService, Tile } from './left-right.service';

@Component({
  selector: 'app-left-right',
  templateUrl: './left-right.component.html',
  styleUrls: ['./left-right.component.scss'],
  providers: [EChartsService, EChartsLegendService]
})
export class LeftRightComponent extends LeftRight {

  @Input() public canEdit: boolean = false;
  @Input() public canToggleConditionAverages: boolean = false;
  @Input() public clickedConditionSummary: boolean = false;
  @Input() public clipList: Clip[] = [];
  @Input() savingTrial: boolean;
  private modal: BsModalRef;

  constructor(protected playbackGlobal: GlobalPlaybackControlService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected featureFlags: FeatureFlagsService,
    public leftRightService: LeftRightService,
    protected echartsService: EChartsService,
    protected readonly videoEvents: VideoEventsService,
    protected keyboardHandlerService: KeyboardHandlerService,
    private renderer: Renderer2,
    private modalService: BsModalService,
  ) {
    super(
      playbackGlobal,
      route,
      router,
      featureFlags,
      leftRightService,
      echartsService,
      videoEvents,
      keyboardHandlerService,
    );
  }

  public showLeftRightView(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    super.showLeftRightView();
  }

  public hide(): void {
    this.renderer.removeStyle(document.body, 'overflow');
    super.hide();
  }

  public zoomRightChart(): boolean {
    return this.leftRightService.zoomRightChart;
  }

  public canExpandRightChart(): boolean {
    if (this.featureFlags.get('canExpandChartsInSplitscreen') !== true) {
      return false;
    }
    for (const tile of this.leftRightService.getRightTiles()) {
      if (!this.leftRightService.isTileChart(tile)) {
        return false;
      }
    }
    for (const tile of this.leftRightService.getLeftTiles()) {
      if (!this.leftRightService.isTileVideo(tile)) {
        return false;
      }
    }
    return true;
  }

  public selectEventForSync($event: HTMLSelectElement | string): void {
    const strToCompare = typeof $event === 'string' ? $event : $event.value;
    if (this.leftRightService.cyclesForSync.length > 0) {
      const cycleForSync = this.leftRightService.cyclesForSync.find(x => x === strToCompare);
      const pauseVideo = this.leftRightService.setVideoOffsetForSync(cycleForSync);
      if (pauseVideo) {
        this.playbackControl.pause();
        const tOffsetPrimary = this.leftRightService.getPrimaryOffset(cycleForSync);
        if (tOffsetPrimary > 0) {
          this.playbackControl.jumpToTimeBasedOnTimeJump(tOffsetPrimary);
        }
      }
    }
  }

  public toggleExpandRightChart(): void {
    this.leftRightService.zoomRightChart = !this.leftRightService.zoomRightChart;
    // this makes the video elements adjust their size after chaing css classes
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
  }

  public keyPressing($event: number): void {
    if ($event === 32) {
      this.playbackControl.togglePlayPause();
    }
  }

  public handleNewTile(newTile: Tile): void {
    if (newTile['dataType'] != undefined && (newTile['dataType'] === 'multi' || newTile['dataType'] === 'multi-report')) {
      this.isLastTileMultiChart = true;
    } else {
      this.isLastTileMultiChart = false;
    }
  }

  public openModal(template: TemplateRef<any>, optionClass: string): void {
    if (optionClass !== undefined) {
      this.modal = this.modalService.show(template, { class: 'modal-' + optionClass });
    } else {
      this.modal = this.modalService.show(template);
    }
  }

  public closeModal(): void {
    this.modal.hide();
  }

  public saveLayoutEvent(layoutName: string): void {
    this.saveLayout(layoutName);
    this.modal.hide();
  }
}
