import * as THREE from 'three';

import './three-global';

export interface BoneInfo {
  length: number;
  bone: THREE.Bone;
}

export class SkeletonMathHelper {
  static readonly FEMUR_AVG_LENGTH_M: number = 0.48; // average length of the femur in meters

  static diffPosition(bone: THREE.Bone, isMax: boolean, ax: string): number {

    const vector = new THREE.Vector3();
    bone.updateMatrixWorld();
    vector.setFromMatrixPosition(bone.matrixWorld);

    let childPosTemp = vector[ax];

    let sign = 1;
    if (isMax) {
      sign = -1;
    }

    for (const child of bone.children) {
      const posTemp = this.diffPosition(child as THREE.Bone, isMax, ax);
      if ((sign * posTemp) < (sign * childPosTemp)) {
        childPosTemp = posTemp;
      }
    }

    return childPosTemp;

  }

  static estimateHeight(root: THREE.Bone): number {
    let max, min, height;

    height = 0;
    const vec = { x: 0, y: 0, z: 0 };
    for (const ax in vec) {
      if (vec[ax]) {
        max = this.diffPosition(root, true, ax);
        min = this.diffPosition(root, false, ax);
        const height_temp = max - min;
        if (height_temp > height) {
          height = height_temp;
        }
      }
    }

    return height;
  }


  static boneParentLength(bone: THREE.Bone) {
    // should concteptually be working for any two bones in the skeleton
    // gives some odd problem if the animation has been loaded before
    // FIX ME: Understand this better.
    /*let position =  new THREE.Vector3();

    bone.updateMatrixWorld();
    position.setFromMatrixPosition( bone.matrixWorld );
    if(bone.parent != undefined)
    {
      let positionParent =  new THREE.Vector3();
      bone.parent.updateMatrixWorld();
      positionParent.setFromMatrixPosition( bone.parent.matrixWorld );
      return position.distanceTo(positionParent);;
    }
    else return -1;
    */

    // simplified math, should be OK for direct parent/child relation ship
    const position = new THREE.Vector3();
    position.copy(bone.position);
    if (bone.parent != undefined) {
      const positionParent = new THREE.Vector3();
      positionParent.set(0, 0, 0);
      return position.distanceTo(positionParent);
    } else { return -1; }
  }

  static getLongestBoneInfo(bone: THREE.Bone, level: number = 0): BoneInfo {
    let parentInfo = { length: 0, bone: undefined };
    if (level > 0) {
      parentInfo = { length: this.boneParentLength(bone), bone: bone.parent };
    }

    let longestBoneInfo = { length: 0, bone: undefined };

    if (bone.children.length == 0) {
      longestBoneInfo = parentInfo;
    } else {
      for (let i = 0; i < bone.children.length; ++i) {
        const temp = this.getLongestBoneInfo(bone.children[i] as THREE.Bone, level + 1);
        if (temp.length > longestBoneInfo.length) {
          longestBoneInfo = temp;
        }
      }

      if (parentInfo.length > longestBoneInfo.length) {
        longestBoneInfo = parentInfo;
      }
    }

    return longestBoneInfo;
  }

  static checkChildren(parentBone, bone) {
    if (!parentBone || !bone) {
      return false;
    }

    let isChildOfParent = false;
    for (let i = 0; i < parentBone.children.length; i++) {
      const newParent = parentBone.children[i];

      if (bone.name !== "" && newParent.name !== "" && bone.name == newParent.name) {
        isChildOfParent = true;
      }
      isChildOfParent = isChildOfParent || this.checkChildren(newParent, bone);
    }
    return isChildOfParent;
  }

  static findRoot(bones: THREE.Bone[], initBone: number = 0, rootNames: any[]): number {
    let rootIndex = -1;
    for (const name of rootNames) {
      const temp = this.findBoneWithName(bones, name, initBone);
      if (temp != -1) {
        rootIndex = temp;
        break;
      }
    }
    return rootIndex;
  }

  static findBoneWithName(bones: THREE.Bone[], name: string, initBone: number = 0, noCasing: boolean = false): number {
    for (let i = initBone; i < bones.length; i++) {
      if (noCasing) {
        if (bones[i].name.indexOf(name) != -1) {
          return i;
        } // found!
      } else {
        if (bones[i].name.toLowerCase().indexOf(name.toLowerCase()) != -1) {
          return i;
        } // found!
      }
    }

    return -1; // not found
  }

  static findBoneWithKeywords(bones, keywords, excludedBones, prefix) {
    let boneFound;
    for (let i = 0; i < bones.length; i++) {
      const boneName = bones[i].name.toLowerCase().replace(prefix, '');
      let excluded = false;
      for (let j = 0; j < excludedBones.length; j++) {
        if (excludedBones[j] != undefined && excludedBones[j].bone.name.toLowerCase() == boneName) {
          excluded = true;
          break;
        }
      }
      if (excluded) {
        continue;
      }

      if (this.checkKeywordsInName(boneName, keywords)) {
        boneFound = bones[i]; // found
        const temp = this.checkParent(boneFound, keywords);

        if (temp !== undefined) {
          let parentExcluded = false;
          for (let j = 0; j < excludedBones.length; j++) {
            if (excludedBones[j] != undefined && excludedBones[j].bone.name.toLowerCase() == temp.name.toLowerCase()) {
              parentExcluded = true;
            }
          }

          if (!parentExcluded) {
            boneFound = temp;
          }
        }
      }
    }

    return boneFound;
  }

  static checkKeywordsInName(name, keywords) {

    let found = true;
    const lowerCaseName = name.toLowerCase();
    for (let j = 0; j < keywords.length; j++) {
      if (lowerCaseName.indexOf(keywords[j]) != -1) {
        continue;
      }
      found = false;
    }
    return found;
  }

  static checkParent(bone, keywords) {
    let foundParent;
    if (bone.parent == undefined) {
      return undefined;
    }
    if (this.checkKeywordsInName(bone.parent.name, keywords)) {
      foundParent = bone.parent;
      const temp = this.checkParent(bone.parent, keywords);
      if (temp != undefined) {
        foundParent = temp;
      }
    }
    return foundParent;
  }

  static addAllChildrens(bone: THREE.Bone, children: THREE.Bone[]) {
    if ('children' in bone) {
      for (let i = 0; i < bone.children.length; i++) {
        children.push(bone.children[i] as THREE.Bone);
        this.addAllChildrens(bone.children[i] as THREE.Bone, children);
      }
    }
  }

  static findLimb(bones: THREE.Bone[], limbHierarchy: any[], text: string, prefix: string) {
    const limbs = [];
    for (let i = 0; i < limbHierarchy.length; i++) {
      limbs.push(undefined);
      for (let j = 0; j < limbHierarchy[i].length; j++) {
        const keywords = [text, limbHierarchy[i][j]];
        const temp = this.findBoneWithKeywords(bones, keywords, limbs, prefix); // cannot find duplicates
        if (temp != undefined) {
          const children = [];
          this.addAllChildrens(temp, children);
          limbs[i] = { bone: temp, children: children };
          break;
        }
      }
    }

    return limbs;
  }

  static findArm(bones: THREE.Bone[], text: string, prefix: string) {
    const shoulderName = ['collar', 'shoulder', 'clavicle']; // order matters! if collar is present, it's likely the correct one
    const armName = ['arm', 'shoulder', 'upperarm'];  // order matters! if arm is there, it's likely the correct one
    const forArmName = ['elbow', 'forearm', 'lowerarm'];
    const handName = ['wrist', 'hand'];

    const armHierarchy = [shoulderName, armName, forArmName, handName];

    const arm = this.findLimb(bones, armHierarchy, text, prefix);
    return arm;
  }

  static findLeg(bones, text: string, prefix: string) {
    const uplegName = ['upleg', 'hip', 'thigh', 'upperleg']; // sometimes also hip joint is defined !
    const legName = ['leg', 'knee', 'calf', 'lowerleg'];
    const footName = ['foot', 'ankle'];
    const toeName = ['toebase', 'toe', 'ball'];

    const hierarchy = [uplegName, legName, footName, toeName];

    const leg = this.findLimb(bones, hierarchy, text, prefix);
    return leg;
  }

  static findSpine(bones, prefix) {
    let spine = ['spine', 'chest']; // sometimes also hip joint is defined !
    const neck = ['neck'];
    const head = ['head'];

    const armHierarchy = [spine, neck, head];

    spine = this.findLimb(bones, armHierarchy, '', prefix);
    return spine;
  }


  static identifyPrefix(bones: any) {
    const array = [];
    for (const b of bones) {
      array.push(b.name);
    }

    let A = array.concat().sort(),
      a1 = A[0], a2 = A[A.length - 1], L = a1.length, i = 0;

    while (i < L && a1.charAt(i) === a2.charAt(i)) { i++; }
    return a1.substring(0, i);
  }

  static getMarkerWithName(markers: any, name: string): any {
    for (let i = 0; i < markers.length; i++) {
      let missing = false;
      if (markers[i].userData && markers[i].userData.type == 'mMarker') {
        missing = true;
      }

      if (!missing && markers[i].name.indexOf(name) != -1) {
        return markers[i];
      }
    }
    return undefined;
  }

  static countUndefined(list) {
    let count = 0;
    for (const e of list) {
      if (e == undefined) {
        count++;
      }
    }
    return count;
  }

  static inspectSkeleton(bones) {
    const prefix = SkeletonMathHelper.identifyPrefix(bones);
    const skeleton = {
      spine: [],
      leftArm: [],
      leftLeg: [],
      rightArm: [],
      rightLeg: [],
    };

    let rightArm = this.findArm(bones, 'right', prefix);
    if (rightArm[0] == undefined) {
      rightArm = this.findArm(bones, '_r', prefix);
    }
    if (rightArm[0] == undefined) {
      rightArm = this.findArm(bones, 'r_', prefix);
    }
    if (rightArm[0] == undefined) {
      rightArm = this.findArm(bones, 'right_', prefix);
    }

    if (rightArm.indexOf(undefined) == -1 || this.countUndefined(rightArm) == 1) {
      skeleton.rightArm = rightArm;
    }

    let leftArm = this.findArm(bones, 'left', prefix);
    if (leftArm[0] == undefined) {
      leftArm = this.findArm(bones, '_l', prefix);
    }
    if (leftArm[0] == undefined) {
      leftArm = this.findArm(bones, 'l_', prefix);
    }
    if (leftArm[0] == undefined) {
      leftArm = this.findArm(bones, 'left_', prefix);
    }
    if (leftArm.indexOf(undefined) == -1 || this.countUndefined(leftArm) == 1) {
      skeleton.leftArm = leftArm;
    }

    let rightLeg = this.findLeg(bones, 'right', prefix);
    if (rightLeg[0] == undefined) {
      rightLeg = this.findLeg(bones, '_r', prefix);
    }
    if (rightLeg[0] == undefined) {
      rightLeg = this.findLeg(bones, 'r_', prefix);
    }

    if (rightLeg.indexOf(undefined) == -1 || this.countUndefined(rightLeg) == 1) {
      skeleton.rightLeg = rightLeg;
    }

    let leftLeg = this.findLeg(bones, 'left', prefix);
    if (leftLeg[0] == undefined) {
      leftLeg = this.findLeg(bones, '_l', prefix);
    }
    if (leftLeg[0] == undefined) {
      leftLeg = this.findLeg(bones, 'l_', prefix);
    }
    if (leftLeg.indexOf(undefined) == -1 || this.countUndefined(leftLeg) == 1) {
      skeleton.leftLeg = leftLeg;
    }

    const spine = this.findSpine(bones, prefix);
    if (spine.indexOf(undefined) == -1) {
      skeleton.spine = spine;
    }

    return skeleton;
  }
}
