<div class="modal-header">
  <h4>Request support for <i>{{filename}}</i></h4>
</div>
<div class="modal-body">
  <p *ngIf="auth.isEnterpriseAuthenticated()">
    As an Enterprise user, please send us an email through
    support&#64;moveshelf.com. To help our team investigate the problem please
    supply details of the steps you took, what you expected to happen, and what
    actually happened.
  </p>
  <form *ngIf="!auth.isEnterpriseAuthenticated()" [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <div class="container">
      <p>By requesting support you acknowledge that Moveshelf's engineering
      team can inspect data.</p>
      <p>To help our team investigate the problem please supply details of the
      type of motion capture system, software tools used to produce the data,
      and any additional comments.</p>
      <div class="form-group">
        <label for="system">System*</label>
        <input class="form-control" type="text" formControlName="system" id="system" autofocus/>
      </div>
      <div class="form-group">
        <label for="software">Software*</label>
        <input class="form-control" type="text" formControlName="software" id="software"/>
      </div>
      <div class="form-group">
        <label for="comment">Comment</label>
        <textarea class="form-control" formControlName="comment" id="comment"></textarea>
      </div>
      <progressbar *ngIf="uploadProgress != null" type="success" [max]=1 [animate]=true [value]="uploadProgress"></progressbar>
    </div>
  </form>
</div>
<div *ngIf="!auth.isEnterpriseAuthenticated()" class="modal-footer">
  <button class="btn btn-secondary" type="button" (click)="cancel()">Cancel</button>
  <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Submit</button>
</div>

