export enum QualisysConfiguration {
    None,
    QualisysFullBody //xx markers
}

export class Qualisys {

    markerList: any[];

    readonly head = ["Front of Head", "Left Head", "Right Head"];

    readonly upperLimbLeft = ["Lt Arm Post", "Lt Arm Ant", "Lt Elbow", "Lt Forearm", "Lt Supa Wrist", "Lt Wrist Radius", "Lt 3rd Digita"];
    readonly upperLimbRight = ["Rt Arm Post", "Rt Arm Ant", "Rt Elbow", "Rt Ulna", "Rt Radius"];

    readonly pelvis = ["Rt PSIS", "Rt Lower PSIS", "Lt PSIS", "Lt Lower PSIS"];

    readonly torso = ["Rt Up Back", "Rt Lower Upper Back", "Lt Lower Upper Back"];// "Lt Up Back"]; //typo in their markerset
    readonly lowerLimbLeft = ["Lt Thi Up Post", "Lt Thi Up Ant", "Lt Lat Knee", "Lt Tibia"];
    readonly lowerLimbRight = ["Rt Thi Up Post", "Rt Thi Up Ant", "Rt Lat Knee", "Rt Tibia"];


    readonly leftFoot = [ "Lt Ankle", "Lt Heel", "Lt 5th Toe", "Lt 2nd Toe"];
    readonly rightFoot = [ "Rt Ankle", "Rt Heel", "Rt 5th Toe", "Rt 2nd Toe"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];

        segments.push({ name: "rightArm", markerList: ["Right Shoulder", "Rt Arm Post", "Rt Elbow", "Rt Arm Ant", "Right Shoulder"], allConnected: false, side: 2 });
        segments.push({ name: "rightLowerArm", markerList: ["Rt Elbow", "Rt Ulna", "Rt Radius"], allConnected: true, side: 2 });

        segments.push({ name: "leftArm", markerList: ["Left Shoulder", "Lt Arm Post", "Lt Elbow", "Lt Arm Ant", "Left Shoulder"], allConnected: false, side: 1 });
        segments.push({ name: "leftLowerArm", markerList: ["Lt Elbow", "Lt Forearm", "Lt Supa Wrist", "Lt Wrist Radius", "Lt 3rd Digita", "Lt Supa Wrist"], allConnected: false, side: 1 });

        segments.push({ name: "head", markerList: this.head, allConnected: true});

        segments.push(  { name: "pelvis", markerList:  ["Rt PSIS", "Rt Lower PSIS", "Lt Lower PSIS", "Lt PSIS", "Rt PSIS"], allConnected: false });
        segments.push(  { name: "torso", markerList:   ["Rt Up Back", "Rt Lower Upper Back", "Lt Lower Upper Back", "Lt Up Back", "Lt Lower Upper Back", "Lt Up back", "Rt Up Back"] });
        segments.push(  { name: "back", markerList:   ["Rt Lower Upper Back", "Lt PSIS", "Rt PSIS",  "Lt Lower Upper Back"], allConnected: false });

        segments.push(  { name: "leftLegUp", markerList: ["Lt Thi Up Post", "Lt Thi Up Ant", "Lt Tibia", "Lt Lat Knee", "Lt Thi Up Post"], allConnected: false, side: 1 });
        segments.push(  { name: "leftLeg", markerList:  ["Lt Lat Knee", "Lt Tibia", "Lt Ankle"], allConnected: true, side: 1 });
        segments.push(  { name: "leftFoot", markerList:  this.leftFoot, allConnected: true, side: 1 });

        segments.push(  { name: "rightLegUp", markerList: ["Rt Thi Up Post", "Rt Thi Up Ant", "Rt Tibia", "Rt Lat Knee", "Rt Thi Up Post"], allConnected: false, side: 2 });
        segments.push(  { name: "rightLeg", markerList: ["Rt Lat Knee", "Rt Tibia", "Rt Ankle"], allConnected: true, side: 2 });
        segments.push(  { name: "rightFoot", markerList:  this.rightFoot, allConnected: true, side: 2 });

        segments.push(  { name: "shaft", markerList:  ["Shaft 1", "Shaft 2", "Shaft 3", "Shaft 4"], allConnected: true });

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];

        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      findConfiguration() {

        const fullBody = [this.head,
            this.upperLimbLeft,
            this.upperLimbRight,
            this.torso,
            this.pelvis,
            this.lowerLimbLeft,
            this.lowerLimbRight,
            this.leftFoot,
            this.rightFoot
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return QualisysConfiguration.QualisysFullBody;
        }

        return QualisysConfiguration.None;
      }

}
