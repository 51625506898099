import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PlaybackControlService } from "app/core/playback-controls/playback-control.service";
import { DataTrack, LineChartGroup } from "app/shared/chart/chart.types";
import { TrialChartsService } from "app/shared/multi-chart/trial-charts.service";
import { TileUpdateIdsAndType } from "../../left-right";

@Component({
  template: ''
})
export class ChartTile {

  @Input() tile: DataTrack;
  @Input() playhead: boolean;
  @Input() playbackControl: PlaybackControlService;
  @Input() selectedChartFilter: string;
  @Input() zoomChartOnPlayhead: boolean = false;
  @Input() zoomRightChart: boolean = false;
  @Input() lastTile: boolean = false;
  @Input() chartHeight: number = 100;
  @Output() public tileChanged: EventEmitter<TileUpdateIdsAndType> = new EventEmitter<TileUpdateIdsAndType>();

  public constructor(
    protected readonly chartsService: TrialChartsService,
  ) { }

  public fetchCharts(): LineChartGroup[] {
    return this.chartsService.lineCharts;
  }

  public changeChart(chartDropdownElement: HTMLSelectElement): void {
    const chartNameParts = chartDropdownElement.value.split('----');
    const chartGroupName = chartNameParts[0];
    const chartTitle = chartNameParts[1];
    for (const chartGroup of this.fetchCharts()) {
      if (chartGroup.name === chartGroupName) {
        for (const chart of chartGroup.tracks) {
          if (chart.labels.title === chartTitle) {
            const changedTile: TileUpdateIdsAndType = {oldId: this.tile.originalId, newId: chart.originalId};
            this.tileChanged.emit(changedTile);
            this.tile = chart;
            return;
          }
        }
      }
    }
    console.error('Cannot find the selected chart!');
  }

  public zoomChartOnVideoPlayhead(): boolean {
    return this.zoomChartOnPlayhead && !this.tile.hasCycles;
  }

}
