export enum Cgm2Congfiguration {
    None,
    Cgm2FullBody //xx markers
}

export class Cgm2 {

    markerList: any[];
    http: any;

    //vstUrl = 'assets/pyCGM2-LowerLimb_CGM22.vst';
    vstUrl = 'assets/pyCGM2-FullBody_CGM25.vst';

    getMarkerList(http: any, url: string): Promise<any> {
        return new Promise( (resolve) => {
            http.get(url, {responseType: 'text'})
            .subscribe( (data) => {

                const markerList = [];

                const parser = new DOMParser();
                const document = parser.parseFromString(data,"application/xml");
                const markers = document.getElementsByTagName('Marker') as any;

                for (const m of markers) {
                    const name = m.attributes.getNamedItem("NAME").value;
                    const required = m.attributes.getNamedItem("STATUS").value == "required";
                    if (required)
                        markerList.push(name);
                }
                resolve(markerList);
            });
        });
    }

    getSegmentList(http: any, url: string): Promise<any> {
        return new Promise( (resolve) => {
            http.get(url, {responseType: 'text'})
            .subscribe( (data) => {

                const segmentsList = [];

                const parser = new DOMParser();
                const document = parser.parseFromString(data,"application/xml");
                const markers = document.getElementsByTagName('Stick') as any;

                for (const m of markers) {
                    const marker1 = m.attributes.getNamedItem("MARKER1").value;
                    const marker2 = m.attributes.getNamedItem("MARKER2").value;
                    const a = m.attributes.getNamedItem("RGB1").value.split(' ');
                    const color = "rgb(" + a[0] + "," + a[1] + "," + a[2] + ")";

                    segmentsList.push({ name: marker1+marker2, markerList: [marker1, marker2], allConnected: false, color: color });
                }
                resolve(segmentsList);
            });
        });
    }

    async getMarkers(http: any) {


        const segmentList = await this.getSegmentList(http, this.vstUrl);
    }

    constructor(markerList: any[], http: any, url: string) {

        this.http = http;
        this.markerList = markerList;
        if (url !== "")
            this.vstUrl = url;
    }

      missingMarkers(limb: any[]) {
        const missing = [];

        return missing;
      }

      async getSegments() {

        const segments = await this.getSegmentList(this.http, this.vstUrl);
        return segments;

      }

      checkConfiguration(requiredMarkerList: any[]) {

        const missingMarkers = [];

        for (const rM of requiredMarkerList) {
            let missing = true;
            for (const m of this.markerList) {
                if (m.name.indexOf(rM) != -1) {
                    missing = false;
                    break;
                }
            }
            if (missing)
                missingMarkers.push(rM);
        }

        return missingMarkers;
      }

      async findConfiguration() {

        const requiredMarkerList = await this.getMarkerList(this.http, this.vstUrl);

        if (this.checkConfiguration(requiredMarkerList).length <= 1) {
            return Cgm2Congfiguration.Cgm2FullBody;
        }

        return Cgm2Congfiguration.None;
      }

}
