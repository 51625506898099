import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { AdditionalDataInfo, AdditionalDataService } from 'app/core/additional-data.service';
import { MocapClip } from 'app/shared/mocap-clip';
import { AnalyticEvent, AnalyticsService } from 'app/shared/services/analytics';
import { ChartsTemplatesService, TemplateDefinition, TemplateType } from 'app/shared/services/charts-templates/charts-templates.service';
import { FeatureFlagsService } from 'app/shared/services/feature-flags.service';
import { OrganizationService } from 'app/shared/services/organization/organization.service';
import { pollConfig } from 'app/shared/services/polling.service';
import { SessionMetadataTabsService } from 'app/shared/services/session-metadata-tabs.sevice';
import gql from 'graphql-tag';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { ClipUpdateService } from '../../core/clip-update.service';
import { ClipUploaderService } from '../../core/clip-uploader.service';
import { MetaControlService } from '../../core/meta-control.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog.component';
import { createReportMutation, deleteNormMutation, normMutation, updateNormMutation } from '../../shared/queries';
import { ClipService } from '../clip.service';
import { Condition, ConditionsService } from '../conditions.service';
import { SessionService } from '../patient-view/create-session/session.service';
import { Session, SessionMetadata } from '../patient-view/create-session/session.types';
import { ProcessingStatus, ProcessingStatusEnum } from '../processor/processors';
import { AutomaticReportConfiguration, AutomaticReportTypes, ChartTemplatesOverride, EnableAutomaticReports, ProjectInfo as ProjectConfig } from '../project-info/project-info-data.service';
import { ProjectInfoService } from '../project-info/project-info.service';
import { ReportDataService } from '../report/report-data.service';
import { TrialTemplate } from '../trial-template.service';
import { CreateReportData} from './create-report-dialog.component';
import { EditingInteractions } from './session-editor/session-editor.component';
import { SessionTab } from './session-tabs/session-tab.enum';
import { ConditionComparison, ReportEvent, SelectedClip, SelectedCondition, SessionComparison } from '../report-utils/report-utils.service';


export const SessionByIdQuery = gql`
  query getSession($id: ID!) {
    node(id: $id) {
      ... on Session {
        id,
        projectPath,
        metadata,
        date,
        project {
          id
          name
          canEdit
          configuration
          norms {
            id,
            name
          }
        }
        clips {
          id
          title
          created
          projectPath
          uploadStatus
          hasCharts
          hasVideo
          customOptions
          outdated
          additionalData {
            id
            uploadStatus
            originalFileName
            dataType
            outdated
          }
        }
        norms {
          id
          name
          uploadStatus
          projectPath
          clips {
            id
            title
          }
        }
        patient {
          id
          name
        }
      }
    }
  }
`;

export const SessionsPerPatient = gql`
  query getSessionPerPatient($id: ID!) {
    node(id: $id) {
      ... on Patient {
        id,
        sessions {
          id
          projectPath
          date
          clips {
            id
            title
            created
            uploadStatus
            projectPath
            additionalData {
              id
              uploadStatus
              originalFileName
              dataType
              outdated
            }
          }
          norms {
            id
            name
            uploadStatus
            projectPath
          }
        }
      }
    }
  }
`;


@Component({
  selector: 'app-session-view',
  templateUrl: './session-view.component.html',
  styleUrls: ['./session-view.component.scss'],
  providers: [FeatureFlagsService, ChartsTemplatesService, { provide: 'templateType', useValue: TemplateType.REPORT }]
})
export class SessionViewComponent implements OnInit, OnDestroy {

  public tabs = SessionTab;
  public tabVisualization: boolean = false;
  @ViewChild('tabLinks') tabLinks: TabsetComponent;
  public tabWithoutProcessors: boolean = false;

  public session: Session;
  public $sessionChanges = new BehaviorSubject<Session>(undefined);
  public $cancelEditMode = new BehaviorSubject<boolean>(false);
  public $refreshMetadata = new BehaviorSubject<boolean>(false);
  otherSessions = [];
  projectId: string = "";
  tests: Condition[] = [];
  private fullConditionsList: Condition[] = [];
  patient: any = {};
  sessionName: string = "";
  defaultConditionName: string = 'Single trials/';

  newConditionName: string = "";
  selectedNorms: Record<string, boolean> = {};
  expanded: Record<string, boolean> = {};
  selectedClipsAndData: Record<string, string[]> = {};
  public selectedConditions: Record<string, boolean> = {};
  subs: Subscription[] = [];
  bsModalRef: BsModalRef;

  editExpanded = false;
  failureMessage: string = "";
  warningMessage: string = "";
  hasWarning: boolean = false;

  editorTitle = "Condition name:";
  processingFailed: boolean = false;
  pendingFiles = [];
  pendingRequests = 0;
  stillProcessing = false;
  errors = [];
  public savingMetadata: boolean = false;
  public dataSetTabEnabled = false;
  public evaluationTabEnabled = false;
  public readonly supportedFileTypes = ".bvh,.fbx,.tdf,.c3d,.trc,.csv,.glb,.mox,.xlsx,.avi,.mov,.pdf";
  public projectInfo: ProjectConfig;

  public sessionUpdateErrorMessage: string;
  public conditionCreateErrorMessage: string = '';

  public canUseProcessors = false;
  public readonly clipSelectionEvent = new EventEmitter<string>();
  public readonly clipDeSelectionEvent = new EventEmitter<string>();
  public readonly clipSelectionOtherSessionEvent = new EventEmitter<string>();
  public readonly clipDeSelectionOtherSessionEvent = new EventEmitter<string>();

  public clipNumber: number = 0;
  public expectedUploads: File[] = [];
  public isUploadInProgress: boolean = false;
  public isEditingMetadata: boolean = false;
  public tabVisualizationSet: boolean = false;

  public infoToSave = {};
  public nDataReceived: number = 0;
  public sessionMetadataTabs = [];
  public activeTab: string;
  public references: any[] = [];
  public gaitScriptEvaluations: string[] = [];
  public gaitAnalysisReports: Condition[] = [];
  public selectedReportGroups: Record<string, boolean> = {};
  public selectedGaitReportTrials: Record<string, boolean> = {};
  public subjectInfoTemplateName: string = '';

  constructor(
    public readonly auth: AuthService,
    protected readonly additionalDataService: AdditionalDataService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    protected readonly apollo: Apollo,
    protected readonly clipUploader: ClipUploaderService,
    protected readonly clipUpdater: ClipUpdateService,
    protected readonly clipService: ClipService,
    protected readonly meta: MetaControlService,
    protected readonly modalService: BsModalService,
    protected readonly conditionsService: ConditionsService,
    protected readonly sessionService: SessionService,
    protected readonly orgService: OrganizationService,
    protected readonly featureFlagsService: FeatureFlagsService,
    protected readonly chartsTemplatesService: ChartsTemplatesService,
    protected readonly analyticsService: AnalyticsService,
    protected readonly projectService: ProjectInfoService,
    protected readonly sessionMetadataTabsService: SessionMetadataTabsService,
    protected readonly reportDataService: ReportDataService,
  ) {
    this.reportDataService.initializeServices(this.chartsTemplatesService, this.featureFlagsService);
  }

  goToClip(clip_id: string): void {
    this.router.navigate(['/project/' + this.projectId + '/trial/' + clip_id]);
  }

  createNew(name?: string): void {
    const testName = name ? (name + '/') : this.defaultConditionName;
    let test;
    for (const t of this.tests) {
      if (t.path == testName) {
        test = t;
        break;
      }
    }
    if (!test) {
      test = { path: testName, clips: [] };
    }
    this.addNewTrial(test);
  }

  addNewTrial(test): void {
    if (!test) {
      return;
    }

    const trialCount = test.clips.length;
    let trialNumber = trialCount;

    for (const c of test.clips) {
      if (c.title.split('-').length > 1) {
        trialNumber = Math.max(trialNumber, parseInt(c.title.split('-')[1]));
      }
    }

    const candidateName = "Trial-" + (trialNumber + 1);
    const metadata = {
      title: candidateName,
      projectPath: this.session.projectPath + test.path,
    };
    this.clipUploader.createClip(this.session.project.name, metadata).subscribe((data) => {
      this.expanded[data.data.createClips.response[0].mocapClip.id] = true;
    });
  }

  deleteClips(): void {
    for (const clipId of this.selectedIds()) {
      this.deleteClip(clipId);
    }
    for (const normId of this.selectedAvgIds()) {
      this.deleteNorm(normId);
    }
  }

  deleteGaitReports(): void {
    for (const clipId of Object.keys(this.selectedGaitReportTrials)) {
      if (this.selectedGaitReportTrials[clipId]) {
        this.deleteClip(clipId);
      }
    }
  }

  openDeleteConfirmationModal(): void {
    const initialState = {
      question: 'Are you sure you want to delete these clips?',
      btnCancelText: 'No, cancel',
      btnOkText: 'Yes, delete',
    };
    this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState });
    this.bsModalRef.content.confirmed.subscribe(() => this.deleteClips());
  }

  openDeleteReportConfirmationModal(): void {
    const initialState = {
      question: 'Are you sure you want to delete these reports?',
      btnCancelText: 'No, cancel',
      btnOkText: 'Yes, delete',
    };
    this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState });
    this.bsModalRef.content.confirmed.subscribe(() => this.deleteGaitReports());
  }

  deleteClip(id: string): void {
    delete this.selectedClipsAndData[id];
    this.clipService.deleteClips(
      this.session.project.id,
      [id]
    ).subscribe(() => undefined);
  }


  updateNorm(id: string, path: string): void {
    this.apollo.mutate({
      mutation: updateNormMutation,
      variables: {
        "normId": id,
        "projectPath": path
      }
    }).subscribe(() => undefined);
  }

  deleteNorm(id: string): void {
    delete this.selectedNorms[id];
    this.apollo.mutate({
      mutation: deleteNormMutation,
      variables: {
        "normId": id
      }
    }).subscribe(() => undefined);
  }

  average(condition): void {
    const clipIds = condition.clips.map(clip => clip.id);
    if (condition.norms.length > 0) {
      this.deleteNorm(condition.norms[0].id);
    }
    this.apollo.mutate({
      mutation: normMutation,
      variables: {
        "project": this.session.project.name,
        "projectPath": condition.clips[0].projectPath,
        "name": "Average",
        "clips": clipIds
      }
    }).subscribe();
  }

  private isGaitReport(value: string): boolean {
    // Check if value contains 'gait analysis reports'
    return value.toLowerCase().includes("gait analysis reports");
  }

  private isAdditionalFiles(value: string): boolean {
    // Check if value contains 'additional files'
    return value.toLowerCase().includes("additional files");
  }

  private checkConditionNameInList(reportConfig: AutomaticReportConfiguration, conditionName: string): boolean {
    // If condition name is an additional file or gait report, return false
    if (!this.isAdditionalFiles(conditionName) && !this.isGaitReport(conditionName)) {
      
      // Check if condition name is in the list of condition names
      if (reportConfig?.conditionNames && reportConfig.conditionNames.length > 0) {
        for (const cName of reportConfig.conditionNames) {
          if (cName.toLowerCase() === conditionName.toLowerCase()) {
            return true;
          }
        }
      }
    }
    return false;
  }

  async createReport(reportEvent: ReportEvent): Promise<void> {
    if (reportEvent.conditionSummary) {
      const layoutType: string = "ConditionSummary";
      const customOptions = { layoutType: layoutType };
      let metadata = {};
      if (reportEvent.template === '') {
        metadata = this.chartsTemplatesService.setInitialChartsTemplate(this.featureFlagsService.get('overrideChartTemplates') as ChartTemplatesOverride, this.featureFlagsService.get('availableChartsTemplates') as TemplateDefinition[] ?? [], true);
        customOptions['reportConfig'] = this.chartsTemplatesService.getCurrentTemplate();
      } else {
        metadata = (reportEvent.template as TemplateDefinition).metadata;
        customOptions['reportConfig'] = (reportEvent.template as TemplateDefinition).configuration;
      }
      if (metadata) {
        customOptions['metadata'] = metadata;
      }
      if (reportEvent.multipleSummary) {
        for (const [index, condition] of reportEvent.conditions.entries()) {
          const conditionClips: string[] = [];
          const conditionIndex = this.fullConditionsList.findIndex(
            (x) => x.fullPath === condition.path
          );
          this.fullConditionsList[conditionIndex].clips.forEach((clip) => {
            if (Object.keys(this.selectedClipsAndData).indexOf(clip.id) !== -1) {
              conditionClips.push(clip.id);
            }
          });
          const sessionPath = this.fullConditionsList[conditionIndex].fullPath.split(this.fullConditionsList[conditionIndex].path)[0];
          const sessionForCondition = this.session.projectPath === sessionPath ? this.session : this.otherSessions.some(s => s.projectPath === sessionPath) ? this.otherSessions.find(s => s.projectPath === sessionPath) : this.session;
          this.createReportMutation(condition.title, customOptions, conditionClips, sessionForCondition.projectPath, layoutType,
            sessionForCondition.id, reportEvent.reference !== '' ? reportEvent.reference : null, reportEvent?.navigateToReport !== undefined ? reportEvent?.navigateToReport === true && index === 0 : index === 0);
        }
      } else {
        this.createReportMutation(reportEvent.title, customOptions, this.selectedIds(), this.session.projectPath, layoutType,
          this.session.id, reportEvent.reference !== '' ? reportEvent.reference : null, reportEvent?.navigateToReport !== undefined ? reportEvent.navigateToReport : true);
      }
    } else if (!reportEvent.automaticReportsTitle) {
      let layoutType: string = "GaitReport";
      if (await this.auth.isSMKAuthenticated()) {
        layoutType = "SpatioTemporal";
      }
      const customOptions = { layoutType: layoutType };
      let metadata = {};
      if (reportEvent.template === '') {
        metadata = this.chartsTemplatesService.setInitialChartsTemplate(this.featureFlagsService.get('overrideChartTemplates') as ChartTemplatesOverride, this.featureFlagsService.get('availableChartsTemplates') as TemplateDefinition[] ?? [], true);
        customOptions['reportConfig'] = this.chartsTemplatesService.getCurrentTemplate();
      } else {
        metadata = (reportEvent.template as TemplateDefinition).metadata;
        customOptions['reportConfig'] = (reportEvent.template as TemplateDefinition).configuration;
      }
      if (metadata) {
        customOptions['metadata'] = metadata;
      }
      this.createReportMutation (
        reportEvent.title, 
        customOptions, 
        this.selectedIds(), 
        this.session.projectPath, 
        layoutType,
        this.session.id, 
        reportEvent.reference !== '' ? reportEvent.reference : null, 
        reportEvent?.navigateToReport !== undefined ? reportEvent.navigateToReport : true
      );
    } else {
      // cycling each automatic report configuration object
      this.processCurrentSessionConditionSummaries(reportEvent);
      this.processReportComparisons(reportEvent, AutomaticReportTypes.currentSessionComparison);
      this.processReportComparisons(reportEvent, AutomaticReportTypes.currentVsPreviousSessionComparison);
    }
  }
  private processCurrentSessionConditionSummaries(reportEvent: ReportEvent): void {
    // For each condition summary, get the clips and create the report
    for (const summary of reportEvent.automaticReportConditions.currentSessionConditionSummaries) {

      this.fullConditionsList.forEach((condition => {
        
        // Find the condition based on the path
        if (condition.fullPath === summary.condition.path) {
          let reportName = this.conditionsService.splitProjectPath(condition.fullPath).conditionName;
          for (const key in reportEvent.automaticReportsTitle) {
            if (key.toString().startsWith(AutomaticReportTypes.currentSessionConditionSummaries)) {
              if (key.toString().includes(reportName)) {
                reportName = reportEvent.automaticReportsTitle[key];
                break;
              }
            }
          }
          
          // Create the report
          this.createReportMutation(
            reportName,
            this.getCustomOption(summary.templateId, "ConditionSummary"),
            condition.clips.map(clip => clip.id),
            this.session.projectPath,
            "ConditionSummary",
            this.session.id,
            reportEvent.reference !== '' ? reportEvent.reference : null,
            reportEvent?.navigateToReport !== undefined ? reportEvent.navigateToReport : true
          );
        }
      }));
    }
  }

  private processReportComparisons(reportEvent: ReportEvent, reportType: AutomaticReportTypes) {
    
    let comparisons: SessionComparison[] | ConditionComparison[] = [];

    // Based on the report type, get the comparisons list
    switch (reportType) {
      case AutomaticReportTypes.currentSessionComparison:
        comparisons = reportEvent.automaticReportConditions.currentSessionComparison;
        break;
      case AutomaticReportTypes.currentVsPreviousSessionComparison:
        comparisons = reportEvent.automaticReportConditions.currentVsPreviousSessionComparison;
        break;
      default:
        console.error('Invalid report type');
        break;
    }
    
    // For each comparison, get the conditions and create the report
    comparisons.forEach((comparison, index) => {
        const reportClipIds = [];
        let conditions = [];

        // Get conditions based on the report type
        switch (reportType) {
          case AutomaticReportTypes.currentSessionComparison:
            conditions = comparison.conditions;
            break;
          case AutomaticReportTypes.currentVsPreviousSessionComparison:
            conditions = [comparison.currentSessionCondition, comparison.previousSessionCondition];
            break;
        }

        // For each condition, get the clips and add to the reportClipIds list
        conditions.forEach(sessionComparison => {
            this.fullConditionsList.forEach(condition => {
                if (condition.fullPath === sessionComparison.path) {
                    reportClipIds.push(...condition.clips.map(clip => clip.id));
                }
            });
        });

        // Create the report
        this.createReportMutation(
            reportEvent.automaticReportsTitle[reportType + index],
            this.getCustomOption(comparison.templateId, "GaitReport"),
            reportClipIds,
            this.session.projectPath,
            "GaitReport",
            this.session.id,
            reportEvent.reference !== '' ? reportEvent.reference : null,
            reportEvent?.navigateToReport !== undefined ? reportEvent.navigateToReport : true
        );
    });
}

  private getCustomOption( templateId: string, layoutType: string) {
    const customOptions = { layoutType: layoutType };
    let metadata = {};

    // Set template from reportEvent
    if (!templateId) {
      metadata = this.chartsTemplatesService.setInitialChartsTemplate(
        this.featureFlagsService.get('overrideChartTemplates') as ChartTemplatesOverride, this.featureFlagsService.get('availableChartsTemplates') as TemplateDefinition[] ?? 
        [], 
        true
      );
      customOptions['reportConfig'] = this.chartsTemplatesService.getCurrentTemplate();
    } else {
      const selectedTemplate = this.getTemplateFromId(templateId);
      metadata = selectedTemplate.metadata;
      customOptions['reportConfig'] = (selectedTemplate as TemplateDefinition).configuration;
    }

    // Set metadata from option
    if (metadata) {
      customOptions['metadata'] = metadata;
    }
  }

  private getTemplateFromId(templateId: string): TemplateDefinition {
    const emptyTemplate: TemplateDefinition = {
      metadata: { id: 'undefined', label: 'Default', version: 0, mergeTemplate: true },
      configuration: undefined,
    };
    // Load available templates from the project configuration
    const projectBaseTemplates: TemplateDefinition[] = this.featureFlagsService.get('availableChartsTemplates') as TemplateDefinition[] ?? [];
    const chartTemplatesOverride: ChartTemplatesOverride = this.featureFlagsService.get('overrideChartTemplates') as ChartTemplatesOverride;
    const availableTemplates = this.chartsTemplatesService.getAvailableTemplates(chartTemplatesOverride, projectBaseTemplates, emptyTemplate);
    return availableTemplates.find(template => template.metadata.id === templateId) || emptyTemplate;
  }

  private createReportMutation(title: string, customOptions: any, clipIds: string[], projectPath: string, layoutType: string, sessionId: string, normId: string, navigate: boolean, loadReportData: boolean = false): void {
    this.apollo.mutate<{ createReport: { report: { id: string } } }>({
      mutation: createReportMutation,
      variables: {
        "project": this.session.project.name,
        "title": title,
        "customOptions": JSON.stringify(customOptions),
        "clipIds": clipIds,
        "avgIds": this.selectedAvgIds(),
        "projectPath": projectPath,
        "layoutType": layoutType,
        "patientId": this.patient.id,
        "sessionId": sessionId,
        "normId": normId,
      }
    }).subscribe(({ data }) => {
      if (navigate) {
        const reportId = data.createReport.report.id;
        this.router.navigate(['project/' + this.projectId + '/report/' + reportId]);
      }
      if (loadReportData) {
        const isConditionSummary = layoutType === "ConditionSummary";
        // retrieve all report clips with full data
        const fullClips = [];
        for (const t of [...this.tests, ...this.getFullConditionsList()]) {
          for (const c of t.clips) {
            if (clipIds.includes(c.id)) {
              fullClips.push(c);
            }
          }
        }
        this.reportDataService.loadAllData(fullClips, this.session, this.patient.id, undefined, [],
          layoutType === "ConditionSummary", data.createReport.report.id, data.createReport.report['title'], this.session.id, [], false);
      }
    },
      err => {
        this.conditionCreateErrorMessage = 'Error creating a new report';
        if (err.toString().includes(`Data too long for column 'title'`)) {
          this.conditionCreateErrorMessage += ': Title too long';
        }
      });
  }

  private onlyTrueIds(obj: Record<string, boolean>) {
    const ids = [];
    for (const id in obj) {
      if (obj[id]) {
        ids.push(id);
      }
    }
    return ids;
  }

  public selectedIds() {
    return Object.keys(this.selectedClipsAndData);
  }

  public getSelectedData(): string[] {
    let selectedData = [];
    for (const clip in this.selectedClipsAndData) {
      if (this.selectedClipsAndData[clip].length > 0) {
        selectedData = selectedData.concat(this.selectedClipsAndData[clip]);
      }
    }
    return selectedData;
  }

  private selectedAvgIds() {
    return this.onlyTrueIds(this.selectedNorms);
  }

  public moveTrialsHere(conditionFullPath: string): void {
    const ids = this.selectedIds();
    const targetMetadata: any = {};
    targetMetadata.projectPath = conditionFullPath;

    for (const clipId of ids) {
      this.clipUpdater.updateClip({
        id: clipId,
        metadata: targetMetadata
      }).subscribe();
    }
  }

  async ngOnInit(): Promise<void> {
    this.projectInfo = this.route.snapshot.data.projectInfo;
    this.meta.updateTitleDescription('Session | Moveshelf');

    this.route.paramMap.subscribe(params => {
      const sessionId = params.get("sessionId");
      this.projectId = params.get("projectId");

      this.selectedClipsAndData = {};
      this.selectedGaitReportTrials = {};
      this.selectedReportGroups = {};
      this.processingFailed = false;
      this.failureMessage = "";
      this.hasWarning = false;
      this.warningMessage = "";

      this.subs.push(this.apollo.watchQuery<any>({
        query: SessionByIdQuery,
        pollInterval: pollConfig.DEFAULT,
        variables: {
          id: sessionId
        },
      }).valueChanges.subscribe(({ data }) => {
        this.handleSessionDataQuery(data);
      }));
    });

    this.canUseProcessors = this.auth.isTesterAuthenticated() || (await this.orgService.getCurrentOrganization())?.configuration?.processorsEnabled;
  }

  async fillTrialTemplateForAdditionalData(sessionMetadata: SessionMetadata): Promise<void> {
    for (const test of this.tests) {
      for (let clip of test.clips) {
        const newCustomOptionsObject = clip.customOptions ? JSON.parse(clip.customOptions) : {};
        const trialTemplate: TrialTemplate = newCustomOptionsObject?.trialTemplate || {} as TrialTemplate;
        const sideAdditionalDataFromClip = trialTemplate?.sideAdditionalData || {} as Record<string, string>;
        const trialSideFromUpload = sessionMetadata?.uploadConfig?.trialSideSelection?.[clip.title];
        const sideDefinitionFromUpload: string = trialSideFromUpload !== undefined ? trialSideFromUpload : undefined;

        let updateClip = false;
        let hasGcd = false;
        const gcdLeftCheck = /-l\d.gcd$/;
        const gcdRightCheck = /-r\d.gcd$/;
        const sideAdditionalData: Record<string, string> = {};
        for (const additionalData of clip.additionalData) {
          if (additionalData.originalFileName.toLowerCase().endsWith('.gcd')) {//} && additionalData.uploadStatus === UploadStatus.Complete) {
            hasGcd = true;
            // Check if we have it defined already, copy otherwise
            if (!Object.keys(sideAdditionalDataFromClip).includes(additionalData.id)) {
              updateClip = true;
              // Extract data context, default left+right, L1/L2/... or 'left' for left or R1/R2/... or 'right' for right
              const hasLeft = sideDefinitionFromUpload !== undefined ? sideDefinitionFromUpload.includes('left') : gcdLeftCheck.test(additionalData.originalFileName.toLowerCase()) || additionalData.originalFileName.toLowerCase().includes('left');
              const hasRight = sideDefinitionFromUpload !== undefined ? sideDefinitionFromUpload.includes('right') : gcdRightCheck.test(additionalData.originalFileName.toLowerCase()) || additionalData.originalFileName.toLowerCase().includes('right');
              if (hasLeft && !hasRight) {
                sideAdditionalData[additionalData.id] = 'left';
              } else if (!hasLeft && hasRight) {
                sideAdditionalData[additionalData.id] = 'right';
              } else if ((hasLeft && hasRight) || sideDefinitionFromUpload === undefined) {
                // default is left+right, but only if we have it not defined in upload config
                sideAdditionalData[additionalData.id] = 'left+right';
              }
            } else {
              sideAdditionalData[additionalData.id] = sideAdditionalDataFromClip[additionalData.id];
            }
          }
        }

        if (Object.keys(sideAdditionalData).length !== Object.keys(sideAdditionalDataFromClip).length) {
          updateClip = true;
        }

        const markedLeft = Object.values(sideAdditionalData).some(item => item.includes("left"));
        const markedRight = Object.values(sideAdditionalData).some(item => item.includes("right"));

        if (hasGcd) {
          let trialSide = '';
          if (markedLeft && markedRight) {
            trialSide = 'left+right';
          } else if (markedLeft) {
            trialSide = 'left';
          } else if (markedRight) {
            trialSide = 'right';
          }

          if (JSON.stringify(trialTemplate?.side) !== JSON.stringify(trialSide)) {
            trialTemplate.side = trialSide;
            updateClip = true;
          }

          trialTemplate.sideAdditionalData = sideAdditionalData;
          if (updateClip) {
            newCustomOptionsObject.trialTemplate = trialTemplate;
            clip = { ...clip, customOptions: JSON.stringify(newCustomOptionsObject) };
            await firstValueFrom(this.clipUpdater.updateClip({
              id: clip.id,
              customOptions: clip.customOptions,
              metadata: {}
            }));
          }
        }
      }
    }
  }

  private async handleSessionDataQuery(data: any): Promise<void> {
    let session = data.node as Session;
    this.references = session.project.norms;
    this.tests = [];
    this.gaitAnalysisReports = [];
    this.sessionName = this.extractSessionName(session);
    // Metadata comes from the db as a JSON string, parse it before using it
    const metadata: SessionMetadata = JSON.parse(session.metadata as string);

    // Add the name and metadata to the session
    session = { ...session, name: this.sessionName, metadata };
    this.patient = session.patient;
    this.conditionsService.getConditionsFromSession(session, this.tests);
    this.getSessionsPerPatient(this.patient.id);
    this.session = session;
    this.$sessionChanges.next(session);
    this.selectedClipsAndData = {};
    this.selectedGaitReportTrials = {};
    this.selectedReportGroups = {};
    // Sort currently loaded conditions
    this.tests = this.sessionService.sortConditions(metadata, this.tests);
    this.dataSetTabEnabled = session.project.canEdit === true;
    // this is needed as it loads the template and logics, even if we may set it back to false later
    this.evaluationTabEnabled = true;
    if (this.canSelectMarkedTrials()) {
      this.fillTrialTemplateForAdditionalData(metadata);
    }
    this.gaitScriptEvaluations = this.getGaitScriptEvaluations(metadata);
    this.findGaitAnalysisReports(this.tests);
    this.tests = this.tests.filter(t => this.gaitAnalysisReports.findIndex(r => r.path === t.path) === -1);
    const template = this.projectService.getProjectTemplate(session.project);
    this.subjectInfoTemplateName = this.projectService.getSubjectInfoTemplateName(session.project);
    this.sessionMetadataTabs = template.sessionMetadataTabs ? template.sessionMetadataTabs : [];
    // tab enabling and routing
    this.tabVisualization = metadata?.biomechanical_evaluation?.biomechanical_evaluation !== undefined || this.sessionMetadataTabs.length > 0 || this.canUseGaitScriptEvaluation();
    this.evaluationTabEnabled = metadata?.biomechanical_evaluation?.biomechanical_evaluation !== undefined;
    if (this.tabVisualization && !this.tabVisualizationSet) {
      this.route.fragment.subscribe((fragment: string) => {
        const selectedTab = this.tabLinks.tabs.filter(f => f.id == fragment)[0] || this.tabLinks.tabs[0];
        if (!this.dataSetTabEnabled) {
          this.tabLinks.removeTab(this.tabLinks.tabs.find(f => f.id == SessionTab.Dataset));
        }
        selectedTab.active = true;
        this.tabWithoutProcessors = selectedTab.id === 'evaluation';
        this.tabVisualizationSet = true;
      });
    }
  }

  getSessionsPerPatient(patientId: string): void {
    this.apollo.query<any>({
      query: SessionsPerPatient,
      variables: {
        id: patientId
      },
    }).subscribe(({ data }) => {
      this.otherSessions = [];
      for (const s of data.node.sessions) {
        if (s.id != this.session.id) {
          const session = { ...s, tests: [] };
          this.conditionsService.getConditionsFromSession(s, session.tests);
          this.otherSessions.push(session);
        }
      }
      this.sessionService.sortSessions(this.otherSessions);
      this.getClipNumber();
      this.fullConditionsList = [...this.tests, ...this.getFullConditionsList()];
      this.checkAllConditions();
    });
  }

  goToSession(id: string): void {
    this.router.navigate(['/project/' + this.projectId + '/session/' + id]);
  }

  isEditing() {
    return this.editExpanded;
  }
  expandEdit(): void {
    this.editExpanded = !this.editExpanded;
  }

  selectedCount(): number {
    return this.selectedIds().length + this.selectedAvgIds().length;
  }

  selectedTrialsCount(): number {
    return this.selectedIds().length;
  }

  uploadFiles(files: any, path: string): void {
    for (const file of files) {
      this.pendingFiles.push(file);
    }

    this.pendingRequests++;
    setTimeout(() => {
      this.pendingRequests--;
      if (this.pendingRequests == 0) {
        this.flushFileUpload(path);
      }
    }, 200);
  }

  flushFileUpload(path: string): void {
    const files = this.pendingFiles;
    console.log('creating clip...');
    // check for project details
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
    }

    if (files.length > 0) {
      const metadata = files.map(f => ({
        title: f.name.split('.')[0],
        projectPath: path
      }));
      this.clipUploader.uploadFiles(this.session.project, files, metadata).subscribe();
    }
    this.pendingFiles = [];
    this.pendingRequests = 0;
  }


  /** 
  * Processes the event from the processor (the event object comes as an output
   * from the app-processor component). The event object contains the status of 
   * the processing and any errors or warnings that may have occurred.
   * - If the processing status is failed, set the flag processingFailed to true
   * - Otherwise, set it to false.
   * 
   * This flag is then used to display error messages in the UI.
   * 
   * @param event the event object from the processor
   * @returns 
   */
  public handleProcessorEvent(event: ProcessingStatus): void {
    if (event.status === ProcessingStatusEnum.FAILED) {
      this.processingFailed = true;
      this.failureMessage = event.errors[0];
      return;
    } else if (event.status === ProcessingStatusEnum.COMPLETED && event.warnings !== undefined && event.warnings.length > 0) {
      this.hasWarning = true;
      this.warningMessage = event.warnings;
    }
    this.processingFailed = false;
  }

  onClosed(): void {
    this.processingFailed = false;
    this.hasWarning = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private async sessionQuery(sessionId: string): Promise<ApolloQueryResult<any>> {
    return await firstValueFrom(
      this.apollo.query<any>({
        query: SessionByIdQuery,
        variables: {
          id: sessionId,
        },
        fetchPolicy: 'network-only',
      })
    );
  }

  public async updateSession(payload: Session): Promise<void> {
    this.savingMetadata = true;
    try {
      const res = await this.sessionQuery(this.session.id);
      if (res.data) {
        const session = res.data.node as Session;
        if (session?.metadata) {
          const sessionMetadata = JSON.parse(session.metadata as string);
          payload.metadata.gaitScriptEvaluation = sessionMetadata.gaitScriptEvaluation;
        }
      }
      await this.sessionService.update(this.session.id, payload.name, payload.metadata, payload.date);
    } catch (sessionUpdateError) {
      this.sessionUpdateErrorMessage = 'Error while updating session';
    }

    this.analyticsService.trackEvent(AnalyticEvent.SESSION_EDIT);
    this.savingMetadata = false;
  }

  public extractSessionName(session: Session): string {
    return this.sessionService.extractSessionNameFromSession(session);
  }

  public changeTab(item: string): void {
    this.activeTab = item;
    if (!this.isMetadataTab(item)) {
      this.router.navigate([this.router.url.split('#')[0]], { fragment: item != '' ? item : null });
    } else {
      this.tabWithoutProcessors = true;
    }
    // if on tab "dataset", show processors
    // More info: https://gitlab.com/moveshelf/mvp/-/issues/3201#note_1944146473
    if (item === 'dataset') {
      this.tabWithoutProcessors = false;
    }
  }

  public handleClipSelectionOtherSession(event: { parentClip: { id: string }, selected: boolean, session: Session }): void {
    this.updateClipAndDataSelectionAll(event.parentClip, event.selected, event.session);
  }

  public handleClipSelection(clip: { id: string }, selected: any): void {
    this.updateClipAndDataSelectionAll(clip, this.isClipSelectedFromEvent(selected));
  }

  public handleGaitReportTrialSelection(clip: { id: string }, condition: Condition): void {
    const updatedValue = !this.selectedGaitReportTrials[clip.id];
    this.updateGaitReportTrialSelection(clip, updatedValue);
    if (updatedValue === true) {
      this.selectedReportGroups[condition.fullPath] = true;
    } else {
      this.selectedReportGroups[condition.fullPath] = this.selectedReportsCount() > 0;
    }
  }

  protected isClipSelectedFromEvent(selected: any): boolean {
    return selected === true || (selected.target?.checked === true);
  }

  public handleDataSelection(data: AdditionalDataInfo, parentClip: { id: string }): void {
    this.updateClipAndDataSelection(data, parentClip);
  }

  public isMetadataTab(name: string): boolean {
    return this.sessionMetadataTabs.filter(x => x.name == name).length != 0;
  }

  public updateClipAndDataSelection(data: AdditionalDataInfo, parentClip: { id: string }): void {
    // maintain relation between selected data and clips to be selected.
    if (data.selected === true) {
      if (this.selectedClipsAndData[parentClip.id] === undefined) {
        this.selectedClipsAndData[parentClip.id] = [];
      }
      if (!this.selectedClipsAndData[parentClip.id].includes(data.id)) {
        this.selectedClipsAndData[parentClip.id].push(data.id);
      }
    } else {
      if (this.selectedClipsAndData[parentClip.id] !== undefined) {
        this.selectedClipsAndData[parentClip.id] = this.selectedClipsAndData[parentClip.id].filter(obj => { return obj !== data.id });
      }
    }
    this.checkConditionSelection(parentClip);
  }

  public updateClipAndDataSelectionAll(parentClip: { id: string }, allSelected: boolean, otherSession?: Session) {
    let clip: MocapClip[] = [];
    if (this.session?.clips) {
      clip = this.session.clips.filter(x => x.id == parentClip.id);
    }
    if (otherSession && otherSession.clips) {
      clip = otherSession.clips.filter(x => x.id == parentClip.id);
    }

    if (clip.length > 0 && clip[0].additionalData && clip[0].additionalData.length > 0) {
      for (const additionalData of clip[0].additionalData) {
        const copy = JSON.parse(JSON.stringify(additionalData));
        copy.selected = allSelected;
        this.updateClipAndDataSelection(copy, parentClip);
      }
    }

    if (allSelected) {
      if (this.selectedClipsAndData[parentClip.id] === undefined) {
        this.selectedClipsAndData[parentClip.id] = [];
      }
      this.clipSelectionEvent.emit(parentClip.id);
    } else {
      this.clipDeSelectionEvent.emit(parentClip.id);
      delete this.selectedClipsAndData[parentClip.id];
    }
  }

  public updateGaitReportTrialSelection(clip: { id: string }, updatedValue: boolean): void {
    this.selectedGaitReportTrials[clip.id] = updatedValue;
  }

  public eventHandleDataSelection(event: { data: AdditionalDataInfo, parentClip: { id: string } }): void {
    this.handleDataSelection(event.data, event.parentClip);
  }

  public selectMarkedTrialsEvent(selectMarkedTrials: boolean): void {
    for (const test of this.tests) {
      for (const clip of test.clips) {
        const customOptionsObject = clip.customOptions ? JSON.parse(clip.customOptions) : {};
        const sideAdditionalData = customOptionsObject?.trialTemplate?.sideAdditionalData || {} as Record<string, string>;

        let trialMarked = false;
        for (const id in sideAdditionalData) {
          if (selectMarkedTrials === true && (sideAdditionalData[id].includes('left') || sideAdditionalData[id].includes('right'))) {
            trialMarked = true;
          }
        }

        this.updateClipAndDataSelectionAll(clip, trialMarked);
      }
    }
  }

  public selectAllEvent(selectAll: boolean): void {
    this.tests.forEach((test) => {
      test.clips.forEach((clip => {
        this.updateClipAndDataSelectionAll(clip, selectAll);
      }));
    });
    this.otherSessions.forEach((session) => {
      session.tests.forEach((test) => {
        test.clips.forEach((clip => {
          this.updateClipAndDataSelectionAll(clip, selectAll, session);
          if (selectAll) {
            this.clipSelectionOtherSessionEvent.emit(clip.id);
          } else {
            this.clipDeSelectionOtherSessionEvent.emit(clip.id);
          }
        }));
      });
    });
  }

  public getClipNumber(): void {
    this.clipNumber = 0;
    this.tests.forEach((test) => {
      test.clips.forEach((clip => {
        this.clipNumber++;
      }));
    });
    this.otherSessions.forEach((session) => {
      session.tests.forEach((test) => {
        test.clips.forEach((clip => {
          this.clipNumber++;
        }));
      });
    });
  }

  public allDataSelected(): boolean {
    return Object.keys(this.selectedClipsAndData).length == this.clipNumber;
  }

  public expectedUploadsEvent(event: File[]): void {
    this.expectedUploads = event;
  }

  public fileDeleted($event: string): void {
    const deletedIndex = this.expectedUploads.findIndex(x => x.name === $event);
    if (deletedIndex !== -1) {
      this.expectedUploads.splice(deletedIndex, 1);
    }
  }

  public canEditCurrentProject(): boolean {
    return this.session?.project?.canEdit === true;
  }

  public uploadInProgressEvent($event: boolean): void {
    this.isUploadInProgress = $event;
  }

  public canSelectMarkedTrials(): boolean {
    return !!this.featureFlagsService.get('canSelectTrialsAsLeftRight') || !!this.featureFlagsService.get('canPerformBiomechEvaluation');
  }

  goToCondition(t: Condition): void {
    const conditionName = t.path.replace('/', '');
    this.router.navigate(['project/' + this.projectId + '/session/' + this.session.id + '/condition/' + conditionName]);
  }

  public canClickConditions(): boolean {
    return !!this.featureFlagsService.get('canClickConditions');
  }

  public async toggleMetadataEdit($event: EditingInteractions): Promise<void> {
    switch ($event) {
      case 'cancel':
        this.isEditingMetadata = false;
        this.sessionMetadataTabsService.clearMetadataObject();
        this.$cancelEditMode.next(true);
        break;
      case 'edit':
        this.isEditingMetadata = true;
        if (this.sessionMetadataTabs.length > 0 && !this.isMetadataTab(this.activeTab)) {
          const selectedTab = this.tabLinks.tabs.filter(f => f.id == this.sessionMetadataTabs[0])[0] || this.tabLinks.tabs[2];
          selectedTab.active = true;
        }
        break;
      case 'save':
        this.isEditingMetadata = false;
        // eslint-disable-next-line no-case-declarations
        const res = await this.sessionMetadataTabsService.saveMetadata(this.session);
        if (res) {
          this.sessionUpdateErrorMessage = res;
        } else {
          this.$refreshMetadata.next(true);
        }
        break;
    }
  }

  public hideCreateReportButton(): boolean {
    return !!this.featureFlagsService.get('hideCreateReportButton');
  }

  public handleAllConditionClips(condition: Condition, selected: any): void {
    condition.clips.forEach((clip => {
      this.updateClipAndDataSelectionAll(clip, this.isClipSelectedFromEvent(selected));
    }));
  }

  public handleAllReportsSelection(condition: Condition): void {
    const updatedValue = !this.selectedReportGroups[condition.fullPath];
    this.selectedReportGroups[condition.fullPath] = updatedValue;
    this.gaitAnalysisReports.forEach((report => {
      for (const clip of report.clips) {
        this.updateGaitReportTrialSelection(clip, updatedValue);
      }
    }));
  }

  public selectedReportsCount(): number {
    let selectedGaitReportTrialCount = 0;
    for (const clipId of Object.keys(this.selectedGaitReportTrials)) {
      if (this.selectedGaitReportTrials[clipId] === true) {
        selectedGaitReportTrialCount++;
      }
    }
    return selectedGaitReportTrialCount;
  }

  private getFullConditionsList(): Condition[] {
    const testList = [];
    this.otherSessions.forEach((session) => {
      session.tests.forEach((test) => {
        testList.push(test);
      });
    });
    return testList;
  }

  public checkAllConditions(): void {
    this.fullConditionsList.forEach((condition => {
      condition.clips.forEach(clip => {
        this.checkConditionSelection(clip);
      });
    }));
  }

  public checkConditionSelection(clip: any): void {
    let clipsSelected = 0;
    const conditionId = this.fullConditionsList.findIndex(x => x.clips.findIndex(a => a.id === clip.id) !== -1);
    if (conditionId !== -1) {
      this.fullConditionsList[conditionId].clips.forEach((clip) => {
        if (this.selectedClipsAndData[clip.id] !== undefined && this.selectedClipsAndData[clip.id].length > 0) {
          clipsSelected++;
        }
      });
      this.selectedConditions[this.fullConditionsList[conditionId].fullPath] = clipsSelected > 0;
    }
  }

  public openGaitEvaluation(): void {
    window.open('../evaluation/project/' + this.projectId + '/session/' + this.session.id, '_blank');
  }

  public canUseGaitScriptEvaluation(): boolean {
    return !!this.featureFlagsService.get('enableGaitScriptEvaluation');
  }

  public canPerformBiomechEvaluation(): boolean {
    return !!this.featureFlagsService.get('canPerformBiomechEvaluation');
  }

  public getCreateReportData(): CreateReportData {
    
    const selectedClipIds = this.selectedIds();

    const createReportData: CreateReportData = { 
      selectedConditions: [], 
      references: this.references, 
      currentSession: '', 
      previousSession: '', 
      automaticReportEnabled: false 
    };
    
    this.fullConditionsList.forEach((condition => {
      const conditionClips: SelectedClip[] = [];

      condition.clips.forEach(clip => {
        // if no clips are selected, add all clips
        if (selectedClipIds.indexOf(clip.id) !== -1 || selectedClipIds.length === 0) {
          conditionClips.push({ id: clip.id, conditionPath: clip.projectPath, title: clip.title });
        }
      });

      if (conditionClips.length > 0) {

        // skip conditions that contain additional files
        if (this.isAdditionalFiles(conditionClips[0].conditionPath)) {
          return;
        }

        createReportData.selectedConditions.push({ 
          path: condition.fullPath, 
          conditionName: this.conditionsService.splitProjectPath(condition.fullPath).conditionName, 
          sessionName: this.conditionsService.splitProjectPath(condition.fullPath).sessionName,
          clips: conditionClips 
        });
      }
    }));

    // get all sessions having a date prior to the current session
    const previousSessions = this.otherSessions.filter(x => this.sessionService.compareSessionsByDate(x, this.session) > 0);
    // between the other sessions with a date before the current session, get the one with the most recent date
    createReportData.currentSession = this.session.name;
    if (previousSessions.length > 0) {
      createReportData.previousSession = this.extractSessionName(previousSessions.reduce((prev, curr) => { return this.sessionService.compareSessionsByDate(prev, curr) > 0 ? curr : prev; }));
    }

    const automaticReportConfig = this.getAutomaticReportConfig();
   
    // handling all automatic report configurations
    if (automaticReportConfig.length > 0) {

      // Set the automatic report configurations to the create report data
      createReportData.automaticReportConfig = automaticReportConfig;
      createReportData.automaticReportEnabled = true;
      
      // Get the automatic report conditions
      const automaticReportConditions = { 
        currentSessionConditionSummaries: [], 
        currentVsPreviousSessionComparison: [],
        currentSessionComparison: [],
      };
      
      // Create a set of selected conditions for the current and previous session
      const previousSessionConditions = new Set<SelectedCondition>();
      const currentSessionConditions = new Set<SelectedCondition>();

      // Obtain the selected conditions for the current and previous session
      // If no previous session is available, only the current session conditions are considered
      for (const selectedCondition of createReportData.selectedConditions) {
        if (selectedCondition.sessionName === this.session.name) {
          currentSessionConditions.add(selectedCondition);
        } else if (createReportData.previousSession !== '' && selectedCondition.sessionName === createReportData.previousSession) {
          previousSessionConditions.add(selectedCondition);
        }
      }

      // Loop through the automatic report configurations
      for (const config of automaticReportConfig) {
        
        // Filter the conditions by the condition names specified in the configuration
        // If no condition names are specified, all conditions are considered
        const filteredCurrentSessionConditions = this.filterConditionsByConditionName(config, currentSessionConditions);

        switch (config.type) {
          case AutomaticReportTypes.currentVsPreviousSessionComparison:
            // Condition names must be specified for current vs previous session comparison (only one condition name per configuration)
            
            if (config.conditionNames?.length !== 1) {
              // If more than one condition name is specified, skip the report creation
              break;
            }
            
            // If previous session is available and only one condition name is specified in the configuration,
            // compare the conditions between the current and previous session
            if (previousSessionConditions.size > 0) {
              const filteredPreviousSessionConditions = this.filterConditionsByConditionName(config, previousSessionConditions);
              
              // Remove conditions that are no match between the current and previous session
              for (const currentCondition of filteredCurrentSessionConditions) {
                for (const previousConditions of filteredPreviousSessionConditions) {
                  if (currentCondition.conditionName === previousConditions.conditionName) {
                    automaticReportConditions.currentVsPreviousSessionComparison.push(
                      {
                        title: config.title || `${currentCondition.conditionName} - ${currentCondition.sessionName} vs ${previousConditions.sessionName}`,
                        templateId: config.templateId,
                        currentSessionCondition: currentCondition, 
                        previousSessionCondition: previousConditions 
                      }
                    );
                    break;
                  }
                }
              }
            }
            break;
          case AutomaticReportTypes.currentSessionConditionSummaries:
              for (const currentCondition of filteredCurrentSessionConditions) {
                automaticReportConditions.currentSessionConditionSummaries.push(
                  { 
                    title: currentCondition.conditionName,
                    templateId: config.templateId,
                    condition: currentCondition
                  }
                );
              }
            break;
          case AutomaticReportTypes.currentSessionComparison:

            // If no condition names are specified, all conditions are considered
            if (config.conditionNames?.length === 0) {
              // If number of conditions is less than or equal to 1, skip the report creation
              if (filteredCurrentSessionConditions.size <= 1) {
                break
              }
              
              // Create a comparison report for all conditions in the current session
              automaticReportConditions.currentSessionComparison.push({ 
                title: config.title || config.conditionNames.join(' & '),
                templateId: config.templateId,
                conditions: filteredCurrentSessionConditions 
              });
            } else {
              // If condition names are specified in the configuration, filter the conditions by the condition names
              const numberConditionFound = Array.from(filteredCurrentSessionConditions).filter(
                condition => config.conditionNames.includes(condition.conditionName)
              );

              // If number of conditions found is equal to the number of condition names specified in the configuration,
              if ( numberConditionFound.length === config.conditionNames.length ) {

                // Create a comparison report for the conditions found
                automaticReportConditions.currentSessionComparison.push({ 
                  title: config.title || config.conditionNames.join(' & '),
                  templateId: config.templateId,
                  conditions: filteredCurrentSessionConditions 
                });
              }
            }
            break;
          default:
            console.error('Automatic report type not supported');
            break;
        }
      }
      
      // Set the automatic report conditions to the create report data
      createReportData.automaticReportConditions = automaticReportConditions;

    }

    return createReportData;
  }

  private getAutomaticReportConfig(): AutomaticReportConfiguration[] {
    const config = this.featureFlagsService.get('automaticReportConfiguration') as EnableAutomaticReports;
    if (config && config.enabled) {
      // convert the config as a single element array if only a string is provided
      for (const reportConfig of config.automaticReportConfiguration) {
        if (!Array.isArray(reportConfig.conditionNames)) {
          if (reportConfig.conditionNames === '' || reportConfig.conditionNames === undefined) {
            reportConfig.conditionNames = [];
          } else {
            reportConfig.conditionNames = [reportConfig.conditionNames];
          }
        }
      }
      return config.automaticReportConfiguration;
    } else {
      return [];
    }
  }

  private getGaitScriptEvaluations(metadata: SessionMetadata): string[] {
    const evaluations: string[] = [];
    if (metadata?.gaitScriptEvaluation) {
      metadata.gaitScriptEvaluation.forEach(evaluation => {
        evaluations.push(evaluation['id']);
      });
    }
    return evaluations;
  }

  private findGaitAnalysisReports(conditions: Condition[]): void {
    for (const condition of conditions) {
      if (condition.path.toLowerCase().includes('gait analysis reports')) {
        this.gaitAnalysisReports.push(condition);
      }
    }
  }

  private filterConditionsByConditionName(config: any, conditions: Set<SelectedCondition>): Set<SelectedCondition> {
    // If condition names are specified in the configuration, filter the conditions by the condition names
    if (config.conditionNames?.length > 0) {
      const filteredConditions = new Set<SelectedCondition>();
      for (const condition of conditions) {
        if (this.checkConditionNameInList(config, condition.conditionName)) {
          filteredConditions.add(condition);
        }
      }
      return filteredConditions;
    }

    return conditions;
  }
  
}
