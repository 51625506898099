import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectInfo } from 'app/projects/project-view.types';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subscription } from 'rxjs';
import { ClipUploaderService } from '../core/clip-uploader.service';
import { TimeSelection } from '../core/time-selection';
import { ClipLoaderService } from '../moveshelf-3dplayer/clip-loader/clip-loader.service';
import { MocapClip } from '../shared/mocap-clip';
import { SupportRequestDialogComponent } from '../shared/support-request-dialog.component';

export class Info {
  public supportRequested = false;
  public selection: TimeSelection;
  public file: File;

  constructor(file?: File) {
    this.file = file;
  }
}

class UploadData extends Info {
  public metadata: MocapClip;
  public clip: any;
}

export class ErrorInfo extends Info {
  public error: string;

  constructor(error: string, file?: File) {
    super(file);
    this.error = error;
  }
}

@Component({
  selector: 'app-clip-uploader',
  templateUrl: './clip-uploader.component.html',
  styleUrls: ['./clip-uploader.component.scss']
})
export class ClipUploaderComponent implements OnInit, OnDestroy {

  public project: string;
  public projects: ProjectInfo[];
  public uploads: Array<UploadData>;
  public loading: boolean = false;
  public errors: Array<ErrorInfo>;

  private subscriptions: Subscription[] = [];
  private modal: BsModalRef;

  constructor(private uploader: ClipUploaderService,
    private loader: ClipLoaderService,
    private modalService: BsModalService
  ) {
    this.uploads = new Array<UploadData>();
    this.errors = new Array<ErrorInfo>();
  }

  public get acceptFiles(): string {
    return this.loader.supportedFileTypes;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.uploader.availableProjects.subscribe(projects => {
        this.projects = projects;
        if(this.projects.length > 0)
          this.project = this.projects[0].name;
      })
    );
  }

  hideModal() {
    this.modal.hide();
  }

  onModalHidden() {
    this.uploads.length = 0;
    this.errors.length = 0;
  }


  findProjectByName(projectName: string): ProjectInfo {
    for (const project of this.projects) {
      if (project.name.indexOf(projectName)!== -1) //only check that prefix matches
        return project;
    }
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public showModal(template) {
    this.modal = this.modalService.show(template, { class: 'modal-lg' } );
    this.modalService.onHide.subscribe(() => this.onModalHidden() );
  }


  public addFiles(newFiles) {
    newFiles = Array.from(newFiles); // Handle FileList
    newFiles.forEach(f => {
      this.loading = true;
      this.loader.loadFile(f).then(clip => {
        this.loading = false;
        this.uploads.push({
          file: f,
          metadata: {title: f.name.split('.')[0]},
          clip: clip,
          supportRequested: false,
          selection: null
        });
      }).catch(reason => {
        this.loading = false;
        this.errors.push(new ErrorInfo(reason, f));
      });
    });
  }

  public rejectFiles(newFiles) {
    newFiles = Array.from(newFiles); // Handle FileList
    newFiles.forEach(f => {
      const ext = f.name.split('.').pop();
      this.errors.push(new ErrorInfo(`Could not open "${f.name}" as currently Moveshelf does
        not support ${ext.toUpperCase()} files.`))
    })
  }

  public uploadFiles() {
    this.modal.hide();
    const files = this.uploads.map(upload => upload.file);
    const metadata = this.uploads.map(upload => upload.metadata);

    const p = this.findProjectByName(this.project);
    this.uploader.uploadFiles(p, files, metadata,)
      .subscribe(() => this.modal.hide());
  }

  public requestSupport(info: Info) {
    const supportModal = this.modalService.show(SupportRequestDialogComponent, {
      initialState: {
        file: info.file,
        filename: info.file.name,
        selection: info.selection
      }
    });

    supportModal.content.requestSubmitted.subscribe(() => {
      info.supportRequested = true;
    })
  }

  public clearError(index: number) {
    this.errors.splice(index, 1);
  }

  public deleteTab(id: number, tabset: TabsetComponent) {
    this.uploads.splice(id, 1);
    tabset.removeTab(tabset.tabs[id]);
  }

  public trackByAnimationId(index, item) {
    return item.clip.animation.clips[0].uuid;
  }
}

