<header [hidden]="!showHeader" style="min-height: 50px; z-index: 5">
  <nav [ngClass]="'navbar navbar-dark navbar-expand-lg d-flex justify-content-start ' + navbarBg " style="position: fixed; width: 100%;">
    <a class="navbar-brand mr-3" routerLink="/home" [class.disabled]="isSubjectLock() ? true : null">
      <svg-icon src="/assets/moveshelf_icon.svg" class="svg-clickable" style="width: 24px;"></svg-icon>
    </a>
    <svg-icon class="navbar-brand noborder d-none d-sm-block" [class.disabled]="isSubjectLock() ? true : null" src="/assets/moveshelf_wordmark.svg" routerLink="/home"
      style="cursor: pointer;  padding-bottom: 10px; fill: #fff; width: 138px;"></svg-icon>
    <div class="navbar-nav ml-5 mr-auto">
    </div>
    <ul class="navbar-nav d-none d-lg-inline-flex">
      <li *ngIf="authenticatedUser && !isSubjectLock()" class="nav-item mr-2 ml-2" style="margin-top: 1px">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
      </li>
      <li *ngIf="authenticatedUser" class="nav-item mr-2 ml-2" style="margin-top: 1px">
        <a class="nav-link" href="https://moveshelf.com/docs/" target="_blank">Help</a>
      </li>
    </ul>
    <ng-container *ngIf="authenticatedUser; then avatartoggler else linetoggler"></ng-container>
    <div class="navbar-collapse" style="flex-grow: 0" [collapse]="collapse">
      <ul class="navbar-nav">
        <li *ngIf="authenticatedUser" class="nav-item my-auto d-xs-block d-lg-none">
          <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
            <i class="fa fa-tachometer mr-2" aria-hidden="true"></i><span>Dashboard</span></a>
        </li>
        <li *ngIf="authenticatedUser" class="nav-item my-auto d-xs-block d-lg-none">
          <a class="nav-link" href="https://moveshelf.com/docs/" target="_blank">
            <i class="fa fa-question-circle mr-2" aria-hidden="true"></i><span>Help</span>
          </a>
        </li>
        <li *ngIf="authenticatedUser" class="nav-item d-xs-block d-lg-none">
          <a class="nav-link" [routerLink]="['/profile', authenticatedUser ]" routerLinkActive="active">
            <i class="fa fa-user-o mr-2"></i>profile
          </a>
        </li>
        <li *ngIf="authenticatedUser" class="nav-item nav-link my-auto d-xs-block">
          <app-notifications *ngIf="authenticatedUser && showNotifications" [username]="authenticatedUser"></app-notifications>
        </li>
        <li class="nav-item my-auto d-none d-lg-inline" style="position: relative" *ngIf="authenticatedUser">
          <div class="btn-group" dropdown>
            <button dropdownToggle [ngClass]="'btn nav-link my-auto p-1 rounded ' + navbarBtn">
              <img id="button-basic" class="rounded-circle mr-2 d-none d-lg-inline" width="28" height="28"
                [src]="avatarUrl" style="cursor: pointer" />
              <i class="fa fa-angle-down"></i>
            </button>
            <div id="button-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right bg-primary mt-1 pl-2"
              role="menu" style="border: 1px solid rgba(255,255,255, .5);">
        <li *ngIf="authenticatedUser" class="nav-item">
          <a class="nav-link my-auto" [routerLink]="['/profile', authenticatedUser ]" routerLinkActive="active"><i
              class="fa fa-user-o mr-2"></i>profile</a>
          <span class="nav-link my-auto" style="cursor: pointer" (click)="logOut()">
            <i class="fa fa-sign-out mr-2" aria-hidden="true"></i>Sign out
          </span>
        </li>
    </div>
    </div>
    </li>
    <li *ngIf="!authenticatedUser" class="nav-item my-auto d-xs-block d-lg-none">
      <a class="nav-link" routerLink="/home" routerLinkActive="active">
        <i class="fa fa-home mr-1" aria-hidden="true"></i><span>Home</span></a>
    </li>
    <li *ngIf="authenticatedUser" class="nav-link my-auto d-xs-block d-lg-none" (click)="logOut()">
      <i class="fa fa-sign-out mr-2" aria-hidden="true"></i>Sign out
    </li>
    <li class="nav-item my-auto" *ngIf="!authenticatedUser; then login"></li>
    </ul>
    </div>
  </nav>
</header>

<app-upload-banner></app-upload-banner>
<div class="container-fluid">
  <div class="row w-100 flex-nowrap">
    <div class="col-lg-2 d-none sidebar" [ngClass]="!sidebarService.collapseLeftSidebar ? 'd-lg-block' : 'd-lg-none'">
      <div class="ml-3 mt-3">
        <router-outlet name="left" (activate)="onActivate($event)"></router-outlet>
        <router-outlet name="subject-tree"></router-outlet>
      </div>
    </div>
  <div class="sidebar-border d-none d-lg-block" *ngIf="isProjectArea">
    <div class="close-sidebar-button" [ngClass]="{'closed': sidebarService.collapseLeftSidebar}">
      <span class="btn btn-primary p-2 fa" [ngClass]="!sidebarService.collapseLeftSidebar ? 'fa-angle-double-left' : 'fa-angle-double-right'" (click)="sidebarService.toggleLeftSidebar()"></span>
    </div>
  </div>
    <div class="col-12 col-lg">
      <router-outlet name="subject-context"></router-outlet>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>


<router-outlet name="overlay"></router-outlet>

<ng-template #login>
  <span class="nav-link my-auto" style="cursor: pointer" (click)="logIn()">
    <i class="fa fa-user-o mr-2"></i>Sign in</span>
</ng-template>

<ng-template #logout>
  <span class="nav-link my-auto" (click)="logOut()">
    <i class="fa fa-sign-out mr-2" aria-hidden="true"></i>Sign out</span>
</ng-template>

<ng-template #linetoggler>
  <button class="navbar-toggler noborder pr-0" type="button" (click)="toggleCollapse();">
    <span class="navbar-toggler-icon"></span>
  </button>
</ng-template>

<ng-template #avatartoggler>
  <button class="navbar-toggler noborder pr-0" type="button" (click)="toggleCollapse();">
    <img class="rounded-circle" width="28" [src]="avatarUrl" />
  </button>
</ng-template>
