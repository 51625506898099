export enum BtsTechConfiguration {
    None,
    BtsFullBody,
    BtsTechGolf, //xx markers
    BtsTechGolfWithStick //xx markers
}

export class BtsTech {

    markerList: any[];

    readonly head = ["Lead Cheek", "Back Cheek"];

    readonly upperLimbLeft = ["Lead Arc", "Lead Elbow", "Lead Ulna", "Lead Radius"];
    readonly upperLimbRight = ["Back Arc", "Back Elbow", "Back Ulna", "Back Radius"];

    readonly pelvis = ["Sacrum", "Lead Asis", "Back Asis"];
    readonly torso = ["C7", "Lead Arc", "Back Arc"];

    readonly lowerLimbLeft = ["Lead Asis", "Lead Knee", "Lead Ankle", "Lead Heel", "Lead Toe Out"];
    readonly lowerLimbRight = ["Back Asis", "Back Knee", "Back Ankle", "Back Heel", "Back Toe Out"];

    readonly stick = ["Stick Top", "Stick Middle", "Bottom Heel", "Bottom Toe"]; //Ball


    readonly headAlt = [ "head", "l head", "r head" ];
    readonly upperLimbRightAlt = [ "r should", "r up arm", "r elbow" , "r radius", "r ulna", "r hand" ];
    readonly torsoAlt =[ "sternum", "c7",  "t8"];
    readonly uppperLimbLeftAlt = [ "l should", "l up arm", "l elbow", "l radius", "l ulna", "l hand" ];
    readonly pelvisAlt = [ "sacrum", "r asis", "l asis" ];
    readonly lowerLimbRightAlt = [ "r knee 1", "r bar 1", "r bar 2", "r heel", "r mall", "r met 1", "r met 5" ];
    readonly lowerLimbLeftAlt = [ "l knee 1", "l bar 1", "l bar 2", "l heel", "l mall", "l met 1", "l met 5" ];


    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];

        segments.push({ name: "head", markerList: [  "Lead Cheek", "Back Cheek" ], allConnected: true});
        segments.push(  { name: "leftUpLeg", markerList:  ["Sacrum", "Lead Asis", "Lead Knee"], allConnected: true, side: 1 });
        segments.push(  { name: "leftLeg", markerList:  ["Lead Knee", "Lead Ankle"], allConnected: false, side: 1 });
        segments.push(  { name: "rightLeg", markerList:  ["Sacrum", "Back Asis", "Back Knee"], allConnected: true, side: 2 });
        segments.push(  { name: "rightLeg", markerList:  ["Back Knee", "Back Ankle"], allConnected: false, side: 2 });
        segments.push(  { name: "pelvis", markerList:  ["Lead Asis", "Back Asis", "Sacrum"], allConnected: true });
        segments.push(  { name: "leftFoot", markerList:  ["Lead Ankle", "Lead Heel", "Lead Toe Out"], allConnected: true, side: 1 });
        segments.push(  { name: "rightFoot", markerList:  ["Back Ankle", "Back Heel", "Back Toe Out"], allConnected: true, side: 2 });

        segments.push({ name: "rightUpArm", markerList: ["Back Arc", "Back Elbow"], allConnected: false, side: 2 });
        segments.push({ name: "rightArm", markerList: ["Back Elbow", "Back Ulna", "Back Radius"], allConnected: true, side: 2 });
        segments.push({ name: "leftUpArm", markerList: ["Lead Arc", "Lead Elbow"], allConnected: false, side: 1 });
        segments.push({ name: "leftArm", markerList: ["Lead Elbow", "Lead Ulna", "Lead Radius"], allConnected: true, side: 1 });

        segments.push(  { name: "torso", markerList: ["Back Arc", "Lead Arc", "C7"], allConnected: true });
        segments.push({ name: "torso2", markerList: [ "Back Arc", "Lead Arc", "Lead Asis", "Back Asis", "Back Arc" ], allConnected: false});
        segments.push({ name: "back", markerList: [ "C7", "Sacrum" ] });

        segments.push(  { name: "stick", markerList:  ["Stick Top", "Stick Middle", "Stick Bottom Heel", "Stick Bottom Toe"], allConnected: false });


        segments.push({ name: "headAlt", markerList: this.headAlt, allConnected: true});
        segments.push({ name: "pelvisAlt", markerList: [ "sacrum", "r asis", "l asis" ], allConnected: true});
        segments.push({ name: "torsoAlt", markerList: [ "sternum", "c7",  "t8", "r should", "l should" ], allConnected: true});
        segments.push({ name: "rightUpArmAlt", markerList: [ "r should", "r up arm", "r elbow" ], allConnected: true, side: 2 });
        segments.push({ name: "rightArmAlt", markerList: [ "r elbow" , "r radius", "r ulna" ], allConnected: true, side: 2 });
        segments.push({ name: "rightHandAlt", markerList: [ "r radius", "r ulna", "r hand" ], allConnected: true, side: 2 });

        segments.push({ name: "leftUpArmAlt", markerList: [ "l should", "l up arm", "l elbow" ], allConnected: true, side: 1 });
        segments.push({ name: "leftArmAlt", markerList: [ "l elbow" , "l radius", "l ulna" ], allConnected: true, side: 1 });
        segments.push({ name: "leftHandAlt", markerList: [ "l radius", "l ulna", "l hand" ], allConnected: true, side: 1 });

        segments.push(  { name: "leftUpLegAlt", markerList:  [ "sacrum", "l knee 1", "l bar 1", "l asis" ], allConnected: true, side: 1 });
        segments.push(  { name: "rightUpLegAlt", markerList:  [ "sacrum", "r knee 1", "r bar 1", "r asis" ], allConnected: true, side: 2 });
        segments.push(  { name: "rightLegAlt", markerList:  [ "r knee 1", "r bar 2", "r mall" ], allConnected: true, side: 2 });
        segments.push(  { name: "leftLegAlt", markerList:  [ "l knee 1", "l bar 2", "l mall" ], allConnected: true, side: 1 });

        segments.push(  { name: "leftFoot", markerList:  [ "l heel", "l mall", "l met 1", "l met 5" ], allConnected: true, side: 1 });
        segments.push(  { name: "rightFoot", markerList:  [ "r heel", "r mall", "r met 1", "r met 5" ], allConnected: true, side: 2 });

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];

        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      findConfiguration() {

        let fullBody = [this.head,
            this.upperLimbLeft,
            this.upperLimbRight,
            this.torso,
            this.pelvis,
            this.lowerLimbLeft,
            this.lowerLimbRight,
            this.stick
        ];

        if (this.checkConfiguration(fullBody).length <= 1)
            return BtsTechConfiguration.BtsTechGolfWithStick;

        fullBody = [this.head,
            this.upperLimbLeft,
            this.upperLimbRight,
            this.torso,
            this.pelvis,
            this.lowerLimbLeft,
            this.lowerLimbRight,
        ];

        if (this.checkConfiguration(fullBody).length <= 1)
            return BtsTechConfiguration.BtsTechGolf;

        fullBody = [ this.headAlt,
           this.upperLimbRightAlt,
           this.torsoAlt,
           this.uppperLimbLeftAlt,
           this.pelvisAlt,
           this.lowerLimbRightAlt,
           this.lowerLimbLeftAlt ];

        if (this.checkConfiguration(fullBody).length <= 1)
           return BtsTechConfiguration.BtsFullBody;

        return BtsTechConfiguration.None;
      }

}
