import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { LRUMap } from 'lru_map';
import { Subscription } from 'rxjs';
import * as THREE from 'three';
import { WebvrService } from '../core/webvr.service';
import { WindowService } from '../core/window.service';
import { CoordinatesType } from '../moveshelf-3dplayer/clip-player';
import { ThreeJsScene } from './three-js-scene';


const RENDERER_CACHE_SIZE = 5;

@Injectable()
export class ThreeJsRendererService implements OnDestroy {

  private scenes: Map<number, ThreeJsScene>;
  private activeScenes: Map<number, ThreeJsScene>;
  private activeScenesStack: Array<Map<number, ThreeJsScene>>;
  private subscriptions: Subscription[] = [];
  private renderers: LRUMap<number, THREE.WebGLRenderer>;
  private prevVrCamUpdate: number;
  private vrCameraGroup: THREE.Group;
  private _maxAnisotropy: number;

  constructor(private zone: NgZone,
    private window: WindowService,
    private webvr: WebvrService
  ) {
    this.scenes = new Map<number, ThreeJsScene>();
    this.activeScenes = new Map<number, ThreeJsScene>();
    this.activeScenesStack = new Array<Map<number, ThreeJsScene>>();
    this.renderers = new LRUMap<number, THREE.WebGLRenderer>(RENDERER_CACHE_SIZE);

    const tmp = new THREE.WebGLRenderer({
      antialias: true,
    });
    this._maxAnisotropy = tmp.capabilities.getMaxAnisotropy();
    tmp.dispose();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get maxAnisotropy(): number {
    return this._maxAnisotropy;
  }

  activateScene(scene: ThreeJsScene) {
    this.activeScenes.set(scene.scene.id, scene);

    this.zone.runOutsideAngular(() => {
      const renderer = this.getRenderer(scene);
      renderer.setAnimationLoop(() => scene.animate());
    });
  }

  deactivateScene(scene: ThreeJsScene) {
    this.getRenderer(scene).setAnimationLoop(null);
    this.activeScenes.delete(scene.scene.id);
  }

  addScene(scene: ThreeJsScene) {
    this.scenes.set(scene.scene.id, scene);
    this.render(scene);
  }

  removeScene(scene: ThreeJsScene) {
    this.deactivateScene(scene);
    const renderer = this.renderers.get(scene.scene.id);
    if (renderer) { renderer.dispose(); }
    this.renderers.delete(scene.scene.id);
    this.scenes.delete(scene.scene.id);
  }


  enableVR(scene: ThreeJsScene, enable: boolean, coord?: CoordinatesType) {
    const renderer = this.getRenderer(scene);
    if (enable) {
      this.webvr.getVRDisplays().then(displays => {
        this.vrCameraGroup = new THREE.Group();
        const target = new THREE.Object3D();
        this.vrCameraGroup.add(target);
        this.resetVrCameraPosition(scene, coord);

        renderer.vr.setPoseTarget(target);
        renderer.vr.setDevice(displays[0]);
        renderer.vr.enabled = true;
      });
    } else {
        renderer.vr.setDevice(null);
        renderer.vr.enabled = false;
    }
  }

  get vrCameraPosition() {
    return this.vrCameraGroup.position;
  }

  set vrCameraPosition(pos: THREE.Vector3) {
    if (this.vrCameraGroup) {
      this.vrCameraGroup.position.copy(pos);
      this.vrCameraGroup.updateMatrixWorld();
    }
  }

  private getRenderer(scene: ThreeJsScene): THREE.WebGLRenderer {
    return this.renderers.get(scene.scene.id) || this.createRenderer(scene);
  }

  private createRenderer(scene: ThreeJsScene) {
    const renderer = new THREE.WebGLRenderer({
      canvas: scene.canvas,
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true
    });

    if (scene.options.lightComplexity > 0) {
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFShadowMap;
      renderer.gammaInput = true;
      renderer.gammaOutput = true;
      if (scene.options.lightComplexity > 1)
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    }

    renderer.setPixelRatio(this.window.devicePixelRatio);
    if (this.renderers.size == RENDERER_CACHE_SIZE) {
      const [id, oldest]= this.renderers.shift();
      oldest.dispose();
    }
    this.renderers.set(scene.scene.id, renderer);
    this.updateSize(scene, true);
    return renderer;
  }

  public updateSize(scene: ThreeJsScene, forceRender: boolean = false) {
    const renderer = this.getRenderer(scene);

    const vrDevice = renderer.vr.getDevice();
    if (vrDevice && vrDevice.isPresenting) {
      return;
    }

    if (scene == undefined)
      this.activeScenes.forEach(scene => this.updateSize(scene));

    const rect = scene.canvas.getBoundingClientRect();
    const height = rect.height || rect.width / scene.aspectRatio;
    const aspect = rect.width / height || scene.aspectRatio;
    scene.camera.aspect = aspect;
    scene.aspectRatio = aspect;
    scene.camera.updateProjectionMatrix();
    renderer.setSize(rect.width, height, false);
    renderer.sortObjects = false; /*currently solves odd ordering problem between skeleton and gridhelper, should be better to only to this once*/
    if (forceRender)
      renderer.render(scene.scene, scene.camera);
  }

  public render(scene: ThreeJsScene) {
    const rect = scene.canvas.getBoundingClientRect();
    const renderer = this.getRenderer(scene);
    if ((rect.width && Math.floor(rect.width * this.window.devicePixelRatio) != scene.canvas.width) ||
      (rect.height && Math.floor(rect.height * this.window.devicePixelRatio) != scene.canvas.height)) {
      this.updateSize(scene); // only used here for fullscreen on mobile (tested in simulation), all other cases should be handled from updateSize
    }
    renderer.render(scene.scene, scene.camera);

    /*
    renderer.setClearColor( 0x222222, 1 );
    renderer.clearDepth(); // important!
    renderer.setScissorTest( true );
    renderer.setScissor( 20, 220, 200, 200 );
    renderer.setViewport( 20, 220, 200, 200 );

    renderer.render( scene.scene, scene.camera );
    renderer.setScissorTest( false );
    */
  }

  private resetVrCameraPosition(scene: ThreeJsScene, coord?: CoordinatesType) {
    if (scene.rootBone)
      scene.rootBone.getWorldPosition(this.vrCameraGroup.position);

    /*if (coord == CoordinatesType.zUp) {
      this.vrCameraGroup.position.sub(new THREE.Vector3(0,0,0));
      this.vrCameraGroup.position.z = 0;
      this.vrCameraGroup.rotation.x = 1.57;
    }
    else */
    {
      this.vrCameraGroup.position.sub(new THREE.Vector3(0,0,-3));
      this.vrCameraGroup.position.y = 0;
    }

    this.vrCameraGroup.updateMatrixWorld();
  }
}
