import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConditionAverageToggleService } from 'app/shared/services/condition-average-toggle/condition-average-toggle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-averages-switch',
  templateUrl: './averages-switch.component.html',
  styleUrls: ['./averages-switch.component.scss'],
})
export class AveragesSwitchComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  private subs: Subscription[] = [];

  @Input() startingValue: boolean = false;

  constructor (
    private formBuilder: FormBuilder,
    protected readonly averageToggleService: ConditionAverageToggleService,
  ) {}


  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      averages: [this.startingValue]
    });

    this.subs.push(this.formGroup.valueChanges.subscribe(() => {
      this.averageToggleService.setStatus(this.formGroup.get('averages').value);
    }));

    this.subs.push(this.averageToggleService.averageToggleStatus.subscribe((status) => {
      this.formGroup.patchValue({'averages': status}, {onlySelf: true, emitEvent: false});
    }));
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
