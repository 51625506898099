import { Injectable } from '@angular/core';
import { AdditionalDataService } from 'app/core/additional-data.service';
import { ClipUploaderService, CreatedClipWithData } from 'app/core/clip-uploader.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { DirectoryOptions } from 'app/projects/project-info/project-info-data.service';
import { DirectoryUploadService, FileWrapper } from './directory-upload.service';
import { Trial } from "./vicon-directory.service";

const grailFileRegex = /^(.*?)_.*\.avi/; // regex to retrieve GRAIL files: only videos named with a _ in the name

@Injectable({
  providedIn: 'root'
})
export class GrailDirectoryService extends DirectoryUploadService {
  private files: FileWrapper[];

  constructor(
    private readonly clipUploader: ClipUploaderService,
    private readonly additionalData: AdditionalDataService,
  ) {
    super();
  }

  public getProviderName(): string {
    return 'GRAIL';
  }

  public setFiles(newFiles: File[]): void {
    this.files = this.fileWrap(newFiles);
  }


  /**
   * Not necessary for GRAIL but implementation is required as we are extending abstract class DirectoryUploadService.
   * @param options
   * @returns
   */
  public setOptions(options: DirectoryOptions): void {
    return;
  }

  /**
   * Returns a list of trials. A GRAIL directory import can have multiple trials. There is no information about conditions,
   * so they are grouped together into a hard-coded "GRAIL" condition.
   * @returns a list of trials
   */
  public async getTrials(): Promise<Trial[]> {
    const trialFiles = this.groupGrailFiles(grailFileRegex);
    const trials: Trial[] = [];
    for (const trialName in trialFiles) {
      trials.push({
        name: trialName,
        notes: '',
        condition: 'GRAIL',
        forcePlates: '',
        plane: '',
        side: '',
        hasCharts: false,
        hasVideo: true, // GRAIL has videos (we are filtering by .avi files)
        date: new Date(Date.UTC(2000, 1, 1, 0, 0, 0)), // we could try to infer the date from the trailName, but it's not requested at this time.
      });
    }
    return trials;
  }


  /**
   * Returns a list of files grouped by regex. Used to group GRAIL files in trials defined by the name.
   * @param files the list of files
   * @returns
   */
  private groupGrailFiles(regex: RegExp): Record<string, File[]> {
    const groups: Record<string, File[]> = {};
    this.files.forEach((file) => {
      const match = regex.exec(file.name);
      if (match) {
        const prefix = match[1];
        if (groups[prefix]) {
          groups[prefix].push(file.file);
        } else {
          groups[prefix] = [file.file];
        }
      }
    });
    return groups;
  }

  public async upload(session: Session): Promise<void> {
    const clipsToCreate: Promise<CreatedClipWithData>[] = [];

    for (const trial of await this.getTrials()) {
      const metadata = {
        title: trial.name,
        projectPath: `${session.projectPath}${trial.condition || 'Trials'}/`,
        description: trial.notes,
      };
      clipsToCreate.push(this.clipUploader.createClip(session.project.name, metadata).toPromise());
      // Throttle down clips creation request by allowing at least 0.5s to pass. This avoids sending
      // all requests at once.
      await this.delay(500);
    }

    try {
      for await (const createdClip of clipsToCreate) {
        const clip = createdClip.data.createClips.response[0].mocapClip;
        const uploads = this.groupGrailFiles(grailFileRegex)[clip.title];
        await this.additionalData.uploadMultipleAdditionalDataWithoutType(clip.id, uploads).toPromise();
      }
    } catch (clipOrDataCreationError) {
      // for..await will throw on the first clip creation exception and won't continue the process.
      // Errors on additional data creation are not currently blocking.
      console.error('Error while creating clip or data');
      console.debug('Error while creating clip or data', clipOrDataCreationError);
    }
  }

  // returns the list of files to upload
  public async getExpectedUploads(): Promise<File[]> {
    const expectedUploads: File[] = [];

    const trialFiles = this.groupGrailFiles(grailFileRegex);
    for (const trialName in trialFiles) {
      expectedUploads.push(...trialFiles[trialName]);
    }
    return expectedUploads;
  }

  /**
   * Loops through current trials and generate a list of the current conditions sorted by date
   * @see #2185 for details on logic
   */
  public async getSortedConditions(): Promise<string[]> {
    const trials = await this.getTrials();
    const conditionsDates = new Map<string, Date>();

    // Map each condition with the earliest trial's date it contains
    for (const trial of trials) {
      if (!conditionsDates.get(trial.condition)) {
        conditionsDates.set(trial.condition, trial.date);
      } else if (conditionsDates.get(trial.condition) > trial.date) {
        conditionsDates.set(trial.condition, trial.date);
      } else {
        continue;
      }
    }

    // Sort the mapped condition by date and return their names only, as an array
    const sortedConditions = [...conditionsDates.entries()].sort((c1, c2) => {
      return c1[1] < c2[1] ? -1 : 1;
    });
    const sortedConditionsArray = sortedConditions.map(tuple => {
      return tuple[0];
    });

    return sortedConditionsArray;
  }
}
