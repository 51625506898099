import { Injectable } from '@angular/core';
import { MediaData } from './media-player.types';

@Injectable({
  providedIn: 'root',
})
export class VideoTrackService {

  private tracks: MediaData[] = [];
  private allTracks: MediaData[] = [];

  public setTracks(tracks: MediaData[]): void {
    this.tracks = tracks;
  }

  public setAllTracks(tracks: MediaData[]): void {
    this.allTracks = tracks;
  }

  public getTracks(): MediaData[] {
    return this.tracks;
  }

  public getOtherTrialTracks(): MediaData[] {
    // Return tracks that are not in the current trial https://stackoverflow.com/a/33034768
    return this.allTracks.filter(x => !this.tracks.includes(x))
                        .concat(this.tracks.filter(x => !this.allTracks.includes(x)));
  }

  public getTracksById(clipId: string): MediaData[] {
    return this.allTracks.filter(x => x.id == clipId);
  }

  public getNextTrack(track: MediaData): MediaData {
    const currentIndex = this.tracks.findIndex(x => x.originalFileName === track.originalFileName && x.dataUrl === track.dataUrl);
    const nextIndex = (currentIndex + 1) % this.tracks.length;
    return this.tracks[nextIndex];
  }

  public getNextTrackById(track: MediaData, clipId: string): MediaData {
    const trialTracks = this.allTracks.filter(x => x.id == clipId);
    const currentIndex = trialTracks.findIndex(x => x.originalFileName === track.originalFileName && x.dataUrl === track.dataUrl);
    const nextIndex = (currentIndex + 1) % trialTracks.length;
    return trialTracks[nextIndex];
  }
}
