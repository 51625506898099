import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AdditionalDataService, EventsRawAdditionalData } from 'app/core/additional-data.service';
import { ClipUpdateService } from 'app/core/clip-update.service';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { WindowService } from 'app/core/window.service';
import { ClipLoaderService } from 'app/moveshelf-3dplayer/clip-loader/clip-loader.service';
import { ClipPlayerInput, VideoVisibilityEnum } from 'app/moveshelf-3dplayer/clip-player';
import { DataTrack, GaitParameterTable, LineChartGroup, LineChartMajorGroup, ReferenceData } from 'app/shared/chart/chart.types';
import { EmgReferenceBarsService, EmgReferenceDataOutput } from 'app/shared/chart/emg-overlay/emg-reference-bars.service';
import { DataContext } from 'app/shared/data-helper';
import { LeftRightService } from 'app/shared/left-right/left-right.service';
import { MultiChartDataService } from 'app/shared/multi-chart/multi-chart-data.service';
import { GaitMeasureArray, RepresentativeClipPathsCondition, TrialChartsService, TrialTracksGroup } from 'app/shared/multi-chart/trial-charts.service';
import { createReportChartsJsonsMutation } from 'app/shared/mutations';
import { ReportByIdForSessionQuery } from 'app/shared/queries';
import { BackendService } from 'app/shared/services/backend/backend.service';
import { ChartToggleService } from 'app/shared/services/chart-toggle/chart-toggle.service';
import { ChartTemplateGroup, ChartsTemplatesService, TemplateMetadata } from 'app/shared/services/charts-templates/charts-templates.service';
import { ColorService } from 'app/shared/services/color-service/color-service.service';
import { FeatureFlagsService } from 'app/shared/services/feature-flags.service';
import { firstValueFrom } from 'rxjs';
import { ConditionsService } from '../conditions.service';
import { ConditionObject, SessionAndConditionObject, SessionService } from '../patient-view/create-session/session.service';
import { ChartsJson, Session, SessionMetadata } from '../patient-view/create-session/session.types';
import { WordExporterType } from '../processor/processor.service';
import { AutomaticReportTypes } from '../project-info/project-info-data.service';
import { SessionByIdQuery } from '../session-view/session-view.component';
import { SubjectContextService } from '../subject-context/subject-context.service';
import { TrialTemplate, TrialTemplateService } from '../trial-template.service';
import { GaitParamsDataSource } from './report.component';
import { Clip, SideSelection } from './report.types';

type NewType = LineChartMajorGroup;

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {
  public lineChartMajorGroups: LineChartMajorGroup[] = [];
  public kinematicTracks: TrialTracksGroup = {};
  public momentTracks: TrialTracksGroup = {};
  public powerTracks: TrialTracksGroup = {};    // Consider to move these into array of Tracks, now easier to search and replace/merge
  public lengthVelocityTracks: TrialTracksGroup = {};
  public emgTracks: TrialTracksGroup = {};
  public forceTracks: TrialTracksGroup = {};
  public combinedTracks: TrialTracksGroup = {};
  public referenceData: ReferenceData = { paramsData: undefined, kinematicsData: undefined, momentData: undefined, powerData: undefined, forceData: undefined, emgsData: undefined, musclesData: undefined };
  public multiChartTiles: Record<string, DataTrack> = {};
  public cliphasDataForBodyWeightWarningIcon: Map<string, boolean> = new Map<string, boolean>();
  public dataTableTracks: GaitParameterTable[] = [];
  public references: any[] = [];
  public clips: Clip[] = [];
  public loading: boolean = false;
  public forceReload: boolean = false;
  timeSeriesErrorMessage: string = '';
  public minVideoFramerate: number = 0;
  public subjectWeightLatest: number;
  public bodyweightWarningIcon: Map<string, boolean> = new Map<string, boolean>();
  private gcdParamsData = []; // add type
  public errors: string[] = [];
  allVideoTracks = [];
  allDocTracks = [];
  allImgTracks = [];
  allEventTracks = [];
  videoTracksForId = [];

  public currentTemplateMetadata: TemplateMetadata;
  parsedTrialTemplate: TrialTemplate;
  selectedReference = "";

  public templateService: ChartsTemplatesService;
  private featureFlagsService: FeatureFlagsService;
  public reportClipGroups: Record<string, Clip[]> = {};

  constructor(
    protected trialChartsService: TrialChartsService,
    protected http: HttpClient,
    protected readonly windowService: WindowService,
    protected readonly trialTemplateService: TrialTemplateService,
    protected emgReferenceBarsService: EmgReferenceBarsService,
    protected conditionsService: ConditionsService,
    protected readonly sessionService: SessionService,
    public leftRightService: LeftRightService,
    protected apollo: Apollo,
    protected colorService: ColorService,
    protected chartToggleService: ChartToggleService,
    protected multiChartDataService: MultiChartDataService,
    protected clipLoaderService: ClipLoaderService,
    protected subjectService: SubjectContextService,
    protected readonly backendService: BackendService,
    protected readonly additionalDataService: AdditionalDataService,
    public playbackControlMaster: PlaybackControlService,
    protected clipUpdateService: ClipUpdateService,
  ) { }

  public initializeServices(templateService: ChartsTemplatesService, featureFlagsService: FeatureFlagsService): void {
    this.templateService = templateService;
    this.featureFlagsService = featureFlagsService;
  }

  public async loadAllData(clips: any, session: Session, subjectId: string, normId: string, averages: any[], isConditionSummary: boolean, reportId: string, reportName: string, sessionId: string, reportsInProject: any[], skipSessionMetadataUpdateForJsons: boolean): Promise<ChartsJson> {
    this.clips = [];
    this.allVideoTracks = [];
    this.allDocTracks = [];
    this.allImgTracks = [];
    this.allEventTracks = [];
    this.videoTracksForId = [];
    this.trialChartsService.isConditionSummary = isConditionSummary === true;
    this.fillClipData(clips);
    this.groupClipsForReport();
    this.chartToggleService.resetStatus();

    await this.updateReferenceWithId(normId);
    const chartsJsonObject = await this.loadChartsForIds(session, subjectId, averages, isConditionSummary, reportId, reportName, sessionId, reportsInProject, skipSessionMetadataUpdateForJsons);
    return chartsJsonObject;
  }

  /**
   * Groups the clips by project path. This is useful for showing the clips
   * as groups in report view.
   */
  private groupClipsForReport(): void {
    this.reportClipGroups = this.clips.reduce((acc, clip) => {
      const conditionName = clip.projectPath;
      if (acc[conditionName]) {
        acc[conditionName].push(clip);
      } else {
        acc[conditionName] = [clip];
      }
      return acc;
    }, {});
  }

  private fillClipData(clips: any): void {
    for (const clip of clips) {
      this.clips.push({
        id: clip.id,
        title: clip.title,
        name: this.trialTemplateService.getUpdatedClipTitleFromTemplate(clip),
        has3d: clip.has3d,
        hasVideo: clip.hasVideo,
        hasCharts: clip.hasCharts,
        projectPath: clip.projectPath.length > 0 && !clip.projectPath.endsWith('/') ? clip.projectPath + '/' : clip.projectPath,  // make sure we end with '/'
        defaultSelection: clip.defaultSelection,
        additionalData: clip.additionalData,
        customOptions: clip.customOptions ? clip.customOptions : {},
      });
    }
    this.sortClipsByProjectPath();
    this.sortAdditionalDataForClipsByName();
  }

  private sortClipsByProjectPath(): void {
    // sort clips by name to get ordering based on session > condition
    this.clips.sort((a, b) => a.projectPath.toLowerCase() + a.name.toLowerCase() < b.projectPath.toLowerCase() + b.name.toLowerCase() ? -1 : a.projectPath.toLowerCase() + a.name.toLowerCase() > b.projectPath.toLowerCase() + b.name.toLowerCase() ? 1 : 0);
  }

  private sortAdditionalDataForClipsByName(): void {
    for (const clip of this.clips) {
      clip.additionalData = [...clip.additionalData].sort((a, b) => a.originalFileName < b.originalFileName ? -1 : a.originalFileName > b.originalFileName ? 1 : 0);
    }
  }

  async loadChartsForIds(session: Session, subjectId: string, averages: any[], isConditionSummary: boolean, reportId: string, reportName: string, sessionId: string, reportsInProject: any[], skipSessionMetadataUpdateForJsons: boolean): Promise<ChartsJson> {
    const gaitParamsDataSources: GaitParamsDataSource[] = [];
    const trialIds: string[] = [];
    const descriptions: string[] = [];
    const subjectWeights: number[] = [];
    this.loading = true;
    this.timeSeriesErrorMessage = '';

    // Load all the report's clips in parallel
    const clipLoaders: Promise<ClipPlayerInput>[] = [];
    const trialNamesInReport: string[] = [];
    for (const c of this.clips) {
      const id = c.id;
      const description = this.conditionsService.formattedProjectPath(c.projectPath) + c.title;

      descriptions.push(description);
      trialIds.push(c.id);
      clipLoaders.push(this.clipLoaderService.loadClipId(id));
      const paramsObject: GaitParamsDataSource = { id: id, url: undefined, description: description, gcdName: undefined };
      gaitParamsDataSources.push(paramsObject);
      trialNamesInReport.push(this.conditionsService.formattedProjectPath(c.projectPath) + c.title);
    }
    this.trialChartsService.trialNamesReport = trialNamesInReport;
    let loadedClips = [];
    try {
      loadedClips = await Promise.all(clipLoaders);
    } catch (clipLoadError) {
      this.errors.push('There was an error while loading graphs content, please refresh the page or contact an administrator.');
      this.loading = false;
      return;
    }

    this.minVideoFramerate = 0;
    let framerateValues = 0;
    let minFramerate = 1000;

    // Parse the report's clip in order
    for (const clip of loadedClips) {
      let videoTracksForTrial = [];
      let subjectWeight;
      const id = clip.id;
      if (!clip || !clip.additionalData) {
        continue;
      }

      let clipFullName = '';
      // find clip title including project path to be used for video sync
      for (const clip_ of this.clips) {
        if (clip_.id == id) {
          clipFullName = this.conditionsService.formattedProjectPath(clip_.projectPath) + clip_.title;
          break;
        }
      }
      this.bodyweightWarningIcon.set(clip.id, false);
      this.cliphasDataForBodyWeightWarningIcon.set(clip.id, false);

      let date = this.sessionService.extractSessionNameFromSession(session);
      date = date.split(' ')[0];
      if (subjectId && !isNaN(Date.parse(date))) {
        const subjectWeightFromDate = await this.subjectService.getSubjectWeightByDate(subjectId, date);
        if (subjectWeightFromDate && subjectWeightFromDate > 0) {
          subjectWeight = subjectWeightFromDate;
        }
      }
      const bodyPoseFiles = [];
      if (this.featureFlagsService.get('enableGoniometer') === true) {
        for (const additionalData of clip.additionalData) {
          if (additionalData.dataType === 'data' && additionalData.originalFileName.toLowerCase().startsWith('bodypose_')) {
            bodyPoseFiles.push(additionalData);
          }
        }
      }
      this.gcdParamsData = [];
      for (const d of clip.additionalData) {
        if (d.dataType === 'data') {
          if (d.originalFileName.indexOf('params') != -1) {
            if (d.originalFileName.indexOf('gcd_params') !== -1) {
              // gcd params
              // Note, for gcd we currently only use the last item found in case we have multiple gcd files!
              const parsedData = await this.getDatafromUri(d.previewDataUri) as any;
              const data = parsedData.data || [];
              const gcdName = d.originalFileName.split('_')[0].replace('<<<', '').replace('>>>', '');
              this.gcdParamsData.push({ name: gcdName, params: parsedData.data || [] });
            } else {
              const paramsSource = gaitParamsDataSources.find(x => x.id === clip.id);
              if (paramsSource !== undefined) {
                const isGcdData = d.originalFileName.split('_')[0].includes('<<<');
                const gcdName = d.originalFileName.split('_')[0].replace('<<<', '').replace('>>>', '');
                paramsSource.url = d.previewDataUri;
                paramsSource.gcdName = isGcdData ? gcdName : undefined;
              }
            }
          }
        }

        if (d.dataType === 'data' && d.originalFileName.toLowerCase().indexOf('subject_metadata') !== -1) {
          const additionalDataResult = await this.backendService.externalGet<EventsRawAdditionalData>(d.previewDataUri);
          if (additionalDataResult['data'] && additionalDataResult['data'].length > 0) {
            const params = additionalDataResult['data'];
            for (const param of params) {
              if (param['label'] && param['label'] === 'Bodymass' && param['value']) {
                const myWeight = param['value'] * 9.81;
                subjectWeight = myWeight;
              }
            }
          }
        }

        if (d.dataType === 'video') {
          let videoName = d.originalFileName;
          if (videoName) {
            videoName = videoName.split('.')[0];
          }
          const videoOptions = {
            id: id,
            filename: videoName,
            originalFileName: d.originalFileName,
            dataUrl: d.previewDataUri,
            videoStyle: VideoVisibilityEnum.small,
            audio: true,
            timeOffset: 0,
            uploadStatus: d.uploadStatus,
            trialNameInReport: clipFullName
          };
          if (d.framerate) {
            const framerate = this.additionalDataService.getFramerate(d);
            framerateValues++;
            if (minFramerate > framerate) {
              minFramerate = framerate;
            }
          }

          // check if there is a bodyPose associated with the video
          if (this.featureFlagsService.get('enableGoniometer') === true && d.originalFileName !== undefined) {
            const bodyPoseFileName = 'bodypose_' + d.originalFileName.substring(0, d.originalFileName.lastIndexOf('.'));
            const bodyPoseFound = bodyPoseFiles.find(f => f.originalFileName.startsWith(bodyPoseFileName));
            if (bodyPoseFound) {
              videoOptions['bodyPose'] = {
                dataUrl: bodyPoseFound.previewDataUri,
                originalFilename: bodyPoseFound.originalFileName,
              };
            }
          }
          videoTracksForTrial.push(videoOptions);
        }

        if (d.dataType === 'doc') {
          let docName = d.originalFileName;
          if (docName) {
            docName = docName.split('.')[0];
          }
          const docOptions = { id: id, filename: docName, url: d.previewDataUri + "#toolbar=0" };
          this.allDocTracks.push(docOptions);
        }

        if (d.dataType === 'img') {
          let imgName = d.originalFileName;
          if (imgName) {
            imgName = imgName.split('.')[0];
          }
          const imgOptions = { id: id, filename: imgName, url: d.previewDataUri };
          this.allImgTracks.push(imgOptions);
        }

        if (d.dataType === 'event') {
          const eventsData = await this.additionalDataService.fetchAdditionalData(d);
          if (eventsData) {
            this.newDurationValue(eventsData.duration, false);
            this.allEventTracks.push({ events: eventsData.events, id: id });
            this.playbackControlMaster.events.next(eventsData.events);
          }
        }
      }
      // Store subject weight per trial
      if (subjectWeight !== undefined) {
        subjectWeights.push(subjectWeight);
      } else if (this.subjectWeightLatest !== undefined) {
        subjectWeights.push(this.subjectWeightLatest);
        this.bodyweightWarningIcon.set(clip.id, true);
      } else {
        subjectWeights.push(0);
      }

      let trialTemplate: string;
      if (videoTracksForTrial.length > 0) {
        // Check if a video order is specified
        if (clip?.customOptions && clip.customOptions?.trialTemplate) {
          trialTemplate = JSON.stringify(clip.customOptions.trialTemplate);
        }
      }

      const parsedTrialTemplate = this.trialTemplateService.mergedTrialTemplateAsJSON(this.featureFlagsService.getAll(), trialTemplate);
      const videoOrder = parsedTrialTemplate?.videoOrder;
      if (videoOrder) {
        videoTracksForTrial = this.trialTemplateService.orderVideosByMetadata(videoOrder, videoTracksForTrial);
      }
      this.allVideoTracks = this.allVideoTracks.concat(videoTracksForTrial);
    }

    if (framerateValues > 0 && framerateValues == this.allVideoTracks.length) {
      this.minVideoFramerate = minFramerate;
    }

    const chartsJsonObject = await this.getReportData(trialIds, descriptions, subjectWeights, gaitParamsDataSources, averages, isConditionSummary, reportId, reportName, sessionId, reportsInProject, skipSessionMetadataUpdateForJsons);
    this.loading = false;
    this.forceReload = false
    return chartsJsonObject;
  }


  public async getReportData(trialIds: string[], descriptions: string[], subjectWeights: number[], gaitParamsDataSources: GaitParamsDataSource[], averages: any[], isConditionsummary: boolean, reportId: string, reportName: string, sessionId: string, reportsInProject: any[], skipSessionMetadataUpdateForJsons: boolean): Promise<ChartsJson> {
    this.lineChartMajorGroups = [];
    this.kinematicTracks = {};
    this.momentTracks = {};
    this.powerTracks = {};
    this.lengthVelocityTracks = {};
    this.emgTracks = {};
    this.forceTracks = {};
    this.combinedTracks = {};
    this.dataTableTracks = [];
    this.trialChartsService.representativeClipPathsInReport = []

    const defaultTimeSeries = this.currentTemplateMetadata?.defaultTimeSeries !== undefined ? this.currentTemplateMetadata?.defaultTimeSeries : false;
    const paramsStructure = [];
    for (const a of averages) {
      const myData = await this.getDatafromUri(a.previewUri) as any;

      if (!defaultTimeSeries && myData?.kinematics?.data?.length > 0) {
        const trackGroup = this.trialChartsService.addDataTrack(myData.kinematics.data, '<<<average>>>', 'Angle [°]', false);
        this.trialChartsService.parseTracks([trackGroup], 0, this.kinematicTracks, this.templateService.getTemplateFor('kinematicsCharts'), trackGroup.name, true, true);
      }
      if (!defaultTimeSeries && myData?.moments?.data?.length > 0) {
        const trackGroup = this.trialChartsService.addDataTrack(myData.moments.data, '<<<average>>>', 'Moment [Nmm]', true);
        this.trialChartsService.parseTracks([trackGroup], 0, this.momentTracks, this.templateService.getTemplateFor('momentsCharts'), trackGroup.name, true, true);
      }
      if (!defaultTimeSeries && myData?.powers?.data?.length > 0) {
        const trackGroup = this.trialChartsService.addDataTrack(myData.powers.data, '<<<average>>>', 'Power [W]', true);
        this.trialChartsService.parseTracks([trackGroup], 0, this.powerTracks, this.templateService.getTemplateFor('powersCharts'), trackGroup.name, true, true);
      }
      this.trialChartsService.addGaitParams(myData.params, a.name, paramsStructure);
    }

    await this.getTrialLines(trialIds, descriptions, subjectWeights, defaultTimeSeries, paramsStructure);

    if (gaitParamsDataSources.length > 0 || paramsStructure.length > 0) {
      await this.getGaitParameters(gaitParamsDataSources, paramsStructure, isConditionsummary);
    }

    // only apply reference data if we have cycles
    let emgRefBarData = undefined;
    const refChartsGroupCombined: LineChartGroup[] = [];
    const createCopies = this.featureFlagsService.get('enableCombinedChart') === true;
    if (!defaultTimeSeries) {
      if (this.referenceData && this.referenceData.kinematicsData && this.referenceData.kinematicsData.data.length !== 0) {
        const refGroup = this.trialChartsService.addDataTrack(this.referenceData.kinematicsData.data, '<<<reference>>>', 'Angle [°]', false, this.referenceData.paramsData);
        if (createCopies) {
          const refGroupCopy = JSON.parse(JSON.stringify(refGroup));
          refGroupCopy.name = '<<<reference-kinematics>>>';
          refChartsGroupCombined.push(refGroupCopy);
        }
        this.trialChartsService.parseTracks([refGroup], 0, this.kinematicTracks, this.templateService.getTemplateFor('kinematicsCharts'), refGroup.name, false, true);
      }

      if (this.referenceData && this.referenceData.momentData && this.referenceData.momentData.data.length !== 0) {
        const refGroup = this.trialChartsService.addDataTrack(this.referenceData.momentData.data, '<<<reference>>>', 'Moment [Nmm]', true, this.referenceData.paramsData);
        if (createCopies) {
          const refGroupCopy = JSON.parse(JSON.stringify(refGroup));
          refGroupCopy.name = '<<<reference-moments>>>';
          refChartsGroupCombined.push(refGroupCopy);
        }
        this.trialChartsService.parseTracks([refGroup], 0, this.momentTracks, this.templateService.getTemplateFor('momentsCharts'), refGroup.name, false, true);
      }

      if (this.referenceData && this.referenceData.powerData && this.referenceData.powerData.data.length !== 0) {
        const refGroup = this.trialChartsService.addDataTrack(this.referenceData.powerData.data, '<<<reference>>>', 'Power [W]', true, this.referenceData.paramsData);
        if (createCopies) {
          const refGroupCopy = JSON.parse(JSON.stringify(refGroup));
          refGroupCopy.name = '<<<reference-powers>>>';
          refChartsGroupCombined.push(refGroupCopy);
        }
        this.trialChartsService.parseTracks([refGroup], 0, this.powerTracks, this.templateService.getTemplateFor('powersCharts'), refGroup.name, false, true);
      }

      if (this.referenceData && this.referenceData.musclesData && this.referenceData.musclesData.data.length !== 0) {
        const refGroup = this.trialChartsService.addDataTrack(this.referenceData.musclesData.data, '<<<reference>>>', 'Muscle Lengths and Velocities [-]', true, this.referenceData.paramsData);
        if (createCopies) {
          const refGroupCopy = JSON.parse(JSON.stringify(refGroup));
          refGroupCopy.name = '<<<reference-muscles>>>';
          refChartsGroupCombined.push(refGroupCopy);
        }
        this.trialChartsService.parseTracks([refGroup], 0, this.lengthVelocityTracks, this.templateService.getTemplateFor('muscleLengthsVelocitiesCharts'), refGroup.name, false, true);
      }

      // if emg reference trial template flag, display hard coded EMG ref
      if (!defaultTimeSeries && this.parsedTrialTemplate?.applyEmgReferenceBars !== undefined) {
        emgRefBarData = this.emgReferenceBarsService.getEmgReferenceBarData(this.parsedTrialTemplate.applyEmgReferenceBars);
      } else if (
        this.referenceData &&
        this.referenceData.emgsData &&
        this.referenceData.emgsData.data.length !== 0) {

        const emgChartsTemplate = this.templateService.getTemplateFor('emgsCharts');
        /**
         * get the emg reference data unit from the template, using the first
         * chart's vaxis unit (we assume all the charts have the same unit).
         */
        let emgUnit = emgChartsTemplate[0]?.charts[0]?.vAxis || '[uV]';
        if (!emgUnit.startsWith('[')) {
          emgUnit = '[' + emgUnit;
        }
        if (!emgUnit.endsWith(']')) {
          emgUnit = emgUnit + ']';
        }
        const refGroup = this.trialChartsService.addDataTrack(this.referenceData.emgsData.data, '<<<reference>>>', 'EMG ' + emgUnit, true, this.referenceData.paramsData);
        if (createCopies) {
          const refGroupCopy = JSON.parse(JSON.stringify(refGroup));
          refGroupCopy.name = '<<<reference-emg>>>';
          refChartsGroupCombined.push(refGroupCopy);
        }
        // refGroup contains all the reference data tracks for EMG
        // this.emgTracks is currently populated with the EMG tracks to show and will be integrated with correct EMG reference tracks
        // this.templateService.getTemplateFor('emgsCharts') represents the template that tells how we want to show the chart
        // check if we need to interpolate ref data for EMG
        // for cycles we expect 100/101 samples or >1000 samples (based on analog frequency)
        let trackFound = false;
        const xLabel = 'perc';
        let nSamplesRefEmg = -1;
        let xMinRef = -1;
        for (const track of refGroup.tracks) {
          if (track?.hasCycles === true && track.values[0][xLabel] !== undefined) {
            nSamplesRefEmg = track.values.length;
            xMinRef = track.values[0][xLabel];
            break;
          }
        }

        trackFound = false;
        let nSamplesEmg = -1;
        let xMin = -1;
        for (const trackGroup in this.emgTracks) {
          if (trackFound) {
            break;
          }
          for (const trackName in this.emgTracks[trackGroup]) {
            const track: DataTrack = this.emgTracks[trackGroup][trackName];
            if (track?.hasCycles === true && track.values[0][xLabel] !== undefined) {
              nSamplesEmg = track.values.length;
              xMin = track.values[0][xLabel];
              trackFound = true;
              break;
            }
          }
        }

        // we want to interpolate for 1000/1001 cycle values wrt 100/101 cycle values
        if (nSamplesEmg > -1 && nSamplesRefEmg > -1 && nSamplesEmg > 5 * nSamplesRefEmg) {
          const startAtZero = xMin == 0 && xMinRef > 0;
          const nSamplesInterp = Math.round(nSamplesEmg / nSamplesRefEmg);
          for (const track of refGroup.tracks) {
            track.values = this.trialChartsService.interpolateCycleTrack(track.values, nSamplesInterp, xLabel, startAtZero, nSamplesEmg);
          }
        }
        this.trialChartsService.parseTracks([refGroup], 0, this.emgTracks, emgChartsTemplate, refGroup.name, false, true);
      }

      if (this.referenceData && this.referenceData.forceData && this.referenceData.forceData.data.length !== 0) {
        const refGroup = this.trialChartsService.addDataTrack(this.referenceData.forceData.data, '<<<reference>>>', 'Force [%BW]', true, this.referenceData.paramsData);
        const myRefTrackLeft = this.trialChartsService.prepareForceRefTrack(refGroup, 'perc');

        // split force ref, so we get it in left and right
        const refTracksLeft: DataTrack[] = [];
        const refTracksRight: DataTrack[] = [];
        const myRefTrackRight = JSON.parse(JSON.stringify(myRefTrackLeft)); // deep copy
        myRefTrackLeft.labels.title = 'reference (left)';
        myRefTrackRight.labels.title = 'reference (right)';
        refTracksLeft.push(myRefTrackLeft);
        refTracksRight.push(myRefTrackRight);

        // NOTE: we have skipped adding force reference to combined chart since we split in left/right
        refGroup.tracks = refTracksRight;
        this.trialChartsService.parseTracks([refGroup], 0, this.forceTracks, this.templateService.getTemplateFor('forcesCharts'), refGroup.name, false, true);
        refGroup.tracks = refTracksLeft;
        this.trialChartsService.parseTracks([refGroup], 0, this.forceTracks, this.templateService.getTemplateFor('forcesCharts'), refGroup.name, false, true);
      }

      if (this.featureFlagsService.get('enableCombinedChart') === true) {
        this.trialChartsService.parseTracks(refChartsGroupCombined, undefined, this.combinedTracks, this.templateService.getTemplateFor('combinedCharts'), '<<<reference>>>', false, true);
      }
    }

    const applyChartTemplate = !this.templateService.isNoTemplateSelected(this.currentTemplateMetadata);
    if (applyChartTemplate) {
      if (Object.values(this.combinedTracks).length > 0) {
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.combinedTracks, this.templateService.getTemplateFor('combinedCharts'), null, this.templateService.getChartNameFor('combinedCharts'));
      }
      if (Object.values(this.kinematicTracks).length > 0) {
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.kinematicTracks, this.templateService.getTemplateFor('kinematicsCharts'), null, this.templateService.getChartNameFor('kinematicsCharts'));
      }
      if (Object.values(this.momentTracks).length > 0) {
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.momentTracks, this.templateService.getTemplateFor('momentsCharts'), null, this.templateService.getChartNameFor('momentsCharts'));
      }
      if (Object.values(this.powerTracks).length > 0) {
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.powerTracks, this.templateService.getTemplateFor('powersCharts'), null, this.templateService.getChartNameFor('powersCharts'));
      }
      if (Object.values(this.lengthVelocityTracks).length > 0) {
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.lengthVelocityTracks, this.templateService.getTemplateFor('muscleLengthsVelocitiesCharts'), null, this.templateService.getChartNameFor('muscleLengthsVelocitiesCharts'));
      }
      if (Object.values(this.forceTracks).length > 0) {
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.forceTracks, this.templateService.getTemplateFor('forcesCharts'), null, this.templateService.getChartNameFor('forcesCharts'));
      }
      if (Object.values(this.emgTracks).length > 0) {
        if (emgRefBarData) {
          this.parseEmgRefBarData(emgRefBarData);
        }
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.emgTracks, this.templateService.getTemplateFor('emgsCharts'), null, this.templateService.getChartNameFor('emgsCharts'));
      }
    }

    // initialize representative trials.
    if (this.canSelectRepresentativeTrial()) {
      await this.initializeRepresentativeTrials();
    }

    // We set averages per condition to true here to make the available in charts data, this is disabled below
    this.trialChartsService.applyAveragePerCondition = true;
    this.trialChartsService.showAveragesForCycles = true;

    let sessionAndConditionObject: SessionAndConditionObject[] = [];
    for (const clip of this.clips) {
      const splittedClip = this.conditionsService.splitProjectPath(clip.projectPath);
      if (splittedClip) {
        clip.projectName = splittedClip.projectName;
        clip.sessionName = splittedClip.sessionName;
        clip.conditionName = splittedClip.conditionName;
        // check per clip if repr
        if (clip.customOptions) {
          const customOptions = typeof clip.customOptions === 'string' ? JSON.parse(clip.customOptions) : clip.customOptions;
          let curRepresentativeClipPaths: RepresentativeClipPathsCondition = this.trialChartsService.representativeClipPathsInReport.find(x => x.sessionName === clip.sessionName && x.conditionName === clip.conditionName);
          const clipHasTrialLeft = customOptions.trialTemplate?.representativeTrial?.left === true
          const clipHasTrialRight = customOptions.trialTemplate?.representativeTrial?.right === true
          if (clipHasTrialLeft || clipHasTrialRight) {
            if (curRepresentativeClipPaths === undefined) {
              curRepresentativeClipPaths = { sessionName: clip.sessionName, conditionName: clip.conditionName, representativeClipPaths: { left: undefined, right: undefined } };
              this.trialChartsService.representativeClipPathsInReport.push(curRepresentativeClipPaths)
            }
            if (clipHasTrialLeft) {
              curRepresentativeClipPaths.representativeClipPaths.left = clip.sessionName + ' > ' + clip.conditionName + ' > ' + clip.title;
            }
            if (clipHasTrialRight) {
              curRepresentativeClipPaths.representativeClipPaths.right = clip.sessionName + ' > ' + clip.conditionName + ' > ' + clip.title;
            }
          }
        }
      }
    }
    const sessions = this.sessionService.groupClipsByAtrr(this.clips, 'sessionName');
    sessionAndConditionObject = this.sessionService.createSessionAndConditionObject(sessions, this.getReferenceName());

    this.templateService.replaceChartNames(this.lineChartMajorGroups, this.currentTemplateMetadata);
    this.templateService.setForceComponentNames(this.currentTemplateMetadata);
    let hasToStoreCycleTimes = true;
    const sharedTracks: LineChartGroup[] = [];
    for (const comparisonGroup of this.lineChartMajorGroups) {
      const tracksWithoutGroups: DataTrack[] = [];
      for (const group of comparisonGroup.groups) {
        for (let track of group.tracks) {
          track = this.trialChartsService.averagePerCondition(track, sessionAndConditionObject, comparisonGroup.name);
          // call filter track data to make allowConditionAvgToggle available for template
          if (track?.hasCycles === true) {
            track = this.trialChartsService.filterTrackData(track);
          }
          tracksWithoutGroups.push(track);
          // store cycle times for video sync
          if (hasToStoreCycleTimes && track?.cycleTimes !== undefined) {
            this.leftRightService.cycleTimes = track.cycleTimes;
            hasToStoreCycleTimes = false;
          }
        }
      }
      sharedTracks.push({ name: comparisonGroup.name, tracks: tracksWithoutGroups });
    }

    const sortTrackLabels = false;      // we always apply the chart template here, sorting is already done while parsing tracks
    this.trialChartsService.setLineCharts(sharedTracks, sortTrackLabels);
    this.chartToggleService.showLeftToggle = this.trialChartsService.hasContextInChart(this.lineChartMajorGroups, DataContext.leftSide);
    this.chartToggleService.showRightToggle = this.trialChartsService.hasContextInChart(this.lineChartMajorGroups, DataContext.rightSide);
    this.multiChartDataService.setLineChartMajorGroups(this.lineChartMajorGroups);
    this.multiChartTiles = this.multiChartDataService.getMultiChartTiles(reportId, this.trialChartsService.getChartMode());



    // store charts json for actual report
    let chartsJsonObject: ChartsJson;
    if (!!this.featureFlagsService.get('canExportGaitEvaluationReport') === true && reportId !== undefined) {
      const { blobKey, chartsUrl } = await this.storeChartsJson(sessionId, reportId, this.gcdParamsData);

      if ( chartsUrl !== undefined ) {
        const hasMultipleSessions = sessionAndConditionObject.length > 1;
        const reportType: AutomaticReportTypes = isConditionsummary ? AutomaticReportTypes.currentSessionConditionSummaries : hasMultipleSessions ? AutomaticReportTypes.currentVsPreviousSessionComparison : AutomaticReportTypes.currentSessionComparison

         // only store sessionName and conditionName
         const sessionAndConditionObjectToStore: SessionAndConditionObject[] = [];
         for (const sessionAndConditionObjectItem of sessionAndConditionObject) {
           const newSessionAndConditionObject: SessionAndConditionObject = {sessionName: sessionAndConditionObjectItem.sessionName, conditions: [], referenceDataSetName: sessionAndConditionObjectItem.referenceDataSetName};
           for (const sessionAndConditionObjectCondition of sessionAndConditionObjectItem.conditions) {
             const newCondition: ConditionObject = {conditionName: sessionAndConditionObjectCondition.conditionName, trials: undefined}
             newSessionAndConditionObject.conditions.push(newCondition);
           }
           sessionAndConditionObjectToStore.push(newSessionAndConditionObject);
        }
        // then add new
        chartsJsonObject = {
          reportId: reportId,
          reportName: reportName,
          chartsJsonTempUrl: chartsUrl,
          reportType: reportType,
          blobKey: blobKey,
          representativeClipPathsInReport: JSON.parse(JSON.stringify(this.trialChartsService.representativeClipPathsInReport)), // deep copy
          sessionAndConditionObjects: sessionAndConditionObjectToStore
        };

        if (!skipSessionMetadataUpdateForJsons) {
          const res = await firstValueFrom(this.apollo.query<any>({
            query: SessionByIdQuery,
            variables: {
              id: sessionId
            },
          }));

          let sessionMetadata: SessionMetadata = {};
          let sessionName: string;

          if (res.data?.node) {
            const session: Session = res.data.node;
            sessionName = this.sessionService.extractSessionNameFromSession(session);
            sessionMetadata = JSON.parse(session.metadata as string);
          }

          if (sessionMetadata === null) {
            sessionMetadata = {};     // make sure we have an object in any case
          }

          if (sessionMetadata?.chartsJsons && sessionMetadata.chartsJsons.length > 0) {
            const reportIds = reportsInProject.length > 0 ? reportsInProject.map(x => x.id) : [];

            // only remain with the ids in the object and in current session
            const idsToRemove: string[] = [];
            for (const chartJson of sessionMetadata.chartsJsons) {
              if (!reportIds.includes(chartJson.reportId)) {
                idsToRemove.push(chartJson.reportId);
              } else {
                const resCurrentReport = await firstValueFrom(this.apollo.query<any>({
                  query: ReportByIdForSessionQuery,
                  variables: {
                    id: chartJson.reportId
                  },
                }));
                if (resCurrentReport.data?.node?.session?.id) {
                  const sessionIdForReport = resCurrentReport.data.node.session.id;
                  if (sessionIdForReport !== sessionId) {
                    idsToRemove.push(chartJson.reportId);
                  }
                }
              }
            }
            sessionMetadata.chartsJsons = sessionMetadata.chartsJsons.filter(i => !idsToRemove.includes(i.reportId));
          } else {
            sessionMetadata.chartsJsons = [];
          }
          

          // first remove existing for this report (if we did not have it yet, this will pass)
          sessionMetadata.chartsJsons = sessionMetadata.chartsJsons.filter(item => item.reportId !== reportId);
          sessionMetadata.chartsJsons.push(chartsJsonObject);
          await this.sessionService.update(sessionId, sessionName, sessionMetadata);
          chartsJsonObject = undefined;     // if we handled the json object, do not return it.
        }
      }
    }

    this.trialChartsService.applyAveragePerCondition = false;
    this.trialChartsService.showAveragesForCycles = false;

    return chartsJsonObject;
  }


  /**
   * For each group of trials, checks if there is a representative trial for the
   * left and right sides. If there is no representative trial for a side, the
   * first clip in the group will be set as the representative trial for that
   * side.
   */
  private async initializeRepresentativeTrials(): Promise<void> {
    // for each group in this.reportClipGroups, check
    // if there is a representative trial for the left and right sides
    for (const groupName in this.reportClipGroups) {
      const group = this.reportClipGroups[groupName];
      // get the representative trials for the left and right sides
      const representativeTrials = this.getAllRepresentativeTrialsForGroup(group);
      if (representativeTrials.left.length === 0 ||
        representativeTrials.left.length > 1) {
        // if there is no representative trial for the left side, or multiple
        // ones, set the first clip in the group as the representative trial for
        // the left side
        const newRepresentativeTrial: Clip =
          representativeTrials.left.length === 0 ?
            group[0] : representativeTrials.left[0];
        await this.updateRepresentativeTrial(
          group,
          newRepresentativeTrial.id,
          SideSelection.LEFT
        );
        if (representativeTrials.left.length > 1) {
          const errorMsg = `Multiple representative trials for the left side
          found in group ${groupName}. Because of this, the first trial
          (${newRepresentativeTrial.name}) will be enforced as the representative trial
           for the left side.`;
          console.error(errorMsg);
          this.errors.push(errorMsg);
        }
      }

      if (representativeTrials.right.length === 0 ||
        representativeTrials.right.length > 1) {

        const newRepresentativeTrial: Clip =
          representativeTrials.right.length === 0 ?
            group[0] : representativeTrials.right[0];
        // if there is no representative trial for the right side, or multiple
        // ones, set the first clip in the group as the representative trial for
        // the right side
        await this.updateRepresentativeTrial(
          group,
          newRepresentativeTrial.id,
          SideSelection.RIGHT
        );
        if (representativeTrials.right.length > 1) {
          const errorMsg = `Multiple representative trials for the right side
          found in group ${groupName}. Because of this, the first trial
          (${newRepresentativeTrial.name}) is enforced as the representative trial
           for the right side.`;
          console.error(errorMsg);
          this.errors.push(errorMsg);
        }
      }
    }
  }


  /**
   * Updates the selection of a representative trial for a side (left or right).
   * This function will update the custom options of the selected clip to set it
   * as the representative trial for the selected side. It will also remove the
   * representative trial flag from ALL the other trials of the group for the
   * selected side. This is to ensure that only one trial is selected as the
   * representative trial for a side.
   * NOTE: this can likely be improved by updating all the clips in the group
   * at once, instead of updating them one by one, or, at the very least, avoid
   * unnecessary updates.
   *
   * @param group a group of clips
   * @param clipId the id of the clip that was selected as the new
   *               representative trial.
   * @param sideSelection the side for which the representative trial was
   *                      selected (left or right).
   * @returns void
   */
  public async updateRepresentativeTrial(
    group: Clip[],
    clipId: string,
    sideSelection: SideSelection
  ): Promise<void> {
    const newRepresentativeTrial = group.find(c => c.id === clipId);
    if (!newRepresentativeTrial) {
      this.errors.push('Whoops! There was an error while updating the representative trial. Try refreshing the page.');
      console.error(`Whoops, this shouldn't have happened.`);
      console.error(`Couldn't find clip with id ${clipId} in the group.`);
      console.error(`Current group: ${group.map(c => c.id).join(', ')}`);
      return;
    }

    // removes the representative trial flag from the other trials in the group
    const otherRepresentativeTrials = group.filter(c => c.id !== clipId);
    for (const otherTrial of otherRepresentativeTrials) {
      const opts = typeof otherTrial.customOptions === 'string' ?
        JSON.parse(otherTrial.customOptions) :
        otherTrial.customOptions;
      // update the custom options only if the representative trial flag is set,
      // to avoid unnecessary updates.
      if (opts.trialTemplate?.representativeTrial?.[sideSelection]) {
        delete opts.trialTemplate?.representativeTrial?.[sideSelection];
        otherTrial.customOptions = JSON.stringify(opts);
        const clip = this.clips.find(c => c.id === otherTrial.id);
        if (clip) {
          clip.customOptions = otherTrial.customOptions;
        }
        try {
          await firstValueFrom(this.clipUpdateService.updateClip({
            id: otherTrial.id,
            customOptions: otherTrial.customOptions,
            metadata: {} // this means do not update metadata
          }));
        } catch (error) {
          this.errors.push('Whoops! There was an error while updating the representative trial. Try refreshing the page.');
          console.error(`Failed to update other trial: ${otherTrial.id}`);
          console.error(error);
        }
      }
    }
    // set the representative trial flag to the new representative trial
    const newCustomOptions = typeof newRepresentativeTrial.
      customOptions === 'string' ?
      JSON.parse(newRepresentativeTrial.customOptions) :
      newRepresentativeTrial.customOptions;
    // if trialTemplate is not set, set it to an empty object
    newCustomOptions.trialTemplate = newCustomOptions.trialTemplate || {};
    // if representativeTrial is not set, set it to an empty object
    newCustomOptions.trialTemplate.representativeTrial = newCustomOptions.
      trialTemplate.representativeTrial || {};

    // if the representative trial flag is already set, do nothing
    if (newCustomOptions.trialTemplate.representativeTrial[sideSelection] === true) {
      const clip = this.clips.find(c => c.id === newRepresentativeTrial.id);
      if (clip) {
        clip.customOptions = JSON.stringify(newCustomOptions);
      }
      return;
    }

    newCustomOptions.trialTemplate.representativeTrial[sideSelection] = true;
    newRepresentativeTrial.customOptions = JSON.stringify(newCustomOptions);
    const clip = this.clips.find(c => c.id === newRepresentativeTrial.id);
    if (clip) {
      clip.customOptions = JSON.stringify(newCustomOptions);
    }

    // update the clips in the DB
    try {
      await firstValueFrom(this.clipUpdateService.updateClip({
        id: newRepresentativeTrial.id,
        customOptions: newRepresentativeTrial.customOptions,
        metadata: {} // this means do not update metadata
      }));
    } catch (error) {
      this.errors.push('Whoops! There was an error while updating the representative trial. Try refreshing the page.');
      console.error(`Failed to update new representative trial: ${newRepresentativeTrial.id}`);
      console.error(error);
    }
  }

  /**
   * Find ALL the trials marked as representative for the left and right sides
   * in a group of clips.
   *
   * @param clips A group of clips.
   * @returns An object with the representative trials for the left and right
   *          sides in the group
   */
  public getAllRepresentativeTrialsForGroup(
    clips: Clip[]
  ): { left: Clip[], right: Clip[] } {

    const rp: { left: Clip[], right: Clip[] } = {
      left: [],
      right: []
    };

    for (const clip of clips) {
      /**
       * if custom options are not set or empty object (not a string),
       * skip this clip
      */
      if (!clip.customOptions) {
        continue;
      }
      // parse if custom options are string, otherwise use object
      // this is necessary as if custom option is NULL on the DB, custom
      // option here will be an empty object {} instead of undefined
      const customOptions = typeof clip.customOptions === 'string' ?
        JSON.parse(clip.customOptions) : clip.customOptions;

      /**
       * Skip if representative trial for the left side was already found. (if
       * it was found, it will be the first one of the group). If not, check if
       * the trial is the representative trial for the left side.
      */
      if (customOptions.trialTemplate?.representativeTrial?.left === true) {
        rp.left.push(clip);
      }
      /**
       * Skip if representative trial for the right side was already found. (if
       * it was found, it will be the first one of the group). If not, check if
       * the trial is the representative trial for the right side.
       */
      if (customOptions.trialTemplate?.representativeTrial?.right === true) {
        rp.right.push(clip);
      }
    }
    return rp;
  }


  async getTrialLines(trialIds: string[], descriptions: string[], subjectWeights: number[], defaultTimeSeries: boolean, paramsStructure: any[]): Promise<void> {
    this.lineChartMajorGroups = [];
    const applyChartTemplate = !this.templateService.isNoTemplateSelected(this.currentTemplateMetadata);
    this.trialChartsService.moxEventClips = [];

    const defaultTrialTemplate = this.trialTemplateService.mergedTrialTemplateAsJSON(this.featureFlagsService.getAll(), undefined);
    this.trialChartsService.disabledLines = {};
    for (let i = 0; i < trialIds.length; i++) {
      const trialId = trialIds[i];
      const clipData = await this.trialChartsService.getDataFromId(
        trialId,
        {
          skipMox: !!this.featureFlagsService.get('skipMoxDownloadAndParseForCharts'),
          fetchTimeBasisCharts: defaultTimeSeries,
          applyChartTemplate: applyChartTemplate,
          cameraNamesForDlt: defaultTrialTemplate?.cameraNamesForDltMox,
          applyRegularGcdCycles: !!this.featureFlagsService.get('canPerformBiomechEvaluation') === false,
        },
        this.forceReload,
      );
      const tracks = clipData.tracks;

      let trialTemplateStr: string = undefined;
      if (clipData?.customOptions) {
        if (clipData.customOptions?.trialTemplate) {
          trialTemplateStr = JSON.stringify(clipData.customOptions.trialTemplate);
        }
      }

      this.parsedTrialTemplate = this.trialTemplateService.mergedTrialTemplateAsJSON(this.featureFlagsService.getAll(), trialTemplateStr);

      // TODO: use and handle trial and chart template.
      this.trialChartsService.extractForcePlateContext(tracks.charts, this.parsedTrialTemplate);
      const charts = this.trialTemplateService.filterCharts(tracks.charts, this.parsedTrialTemplate, this.trialChartsService.getChartMode(), defaultTimeSeries);
      const createCopies = this.featureFlagsService.get('enableCombinedChart') === true;
      const chartsCopy: LineChartGroup[] = createCopies ? JSON.parse(JSON.stringify(charts)) : [];   // deep copy
      const isMoxClip = this.clips.find(x => x.id === trialId).additionalData?.filter(additionalData => additionalData.originalFileName?.endsWith('.mox')).length > 0;
      if (isMoxClip && tracks?.barCharts !== undefined && tracks.barCharts.length > 0) {
        for (const barChart of tracks.barCharts) {
          paramsStructure.push({ data: barChart.tracks.values[0], description: descriptions[i] });
        }
      }
      // if available, apply subjectWeight per trial
      if (subjectWeights[i] > 0) {
        this.trialChartsService.subjectWeight = subjectWeights[i];
      } else {
        this.trialChartsService.subjectWeight = undefined;
      }

      for (const trackGroup of charts) {
        if (this.colorService.groupNamesForBodyMassWarningIcon.indexOf(trackGroup.name) !== -1) {
          this.cliphasDataForBodyWeightWarningIcon.set(trialId, true);
          break;
        }
      }

      if (applyChartTemplate) {
        let index = -1;
        index = charts.findIndex(trackGroup => trackGroup.name === 'Kinematics');
        if (index > -1) {
          charts[index].tracks = this.trialChartsService.prepareTrackData(charts[index], applyChartTemplate, this.parsedTrialTemplate, trialId);
          if (createCopies) {
            chartsCopy[index].tracks = JSON.parse(JSON.stringify(charts[index].tracks)); // deep copy
          }
          if (charts[index].tracks.length > 0) {
            this.trialChartsService.parseTracks(charts, index, this.kinematicTracks, this.templateService.getTemplateFor('kinematicsCharts'), descriptions[i], true, true);
          }
        }

        index = charts.findIndex(trackGroup => trackGroup.name === 'Moments');
        if (index > -1) {
          charts[index].tracks = this.trialChartsService.prepareTrackData(charts[index], applyChartTemplate, this.parsedTrialTemplate, trialId);
          if (createCopies) {
            chartsCopy[index].tracks = JSON.parse(JSON.stringify(charts[index].tracks)); // deep copy
          }
          if (charts[index].tracks.length > 0) {
            this.trialChartsService.parseTracks(charts, index, this.momentTracks, this.templateService.getTemplateFor('momentsCharts'), descriptions[i], true, true);
          }
        }

        index = charts.findIndex(trackGroup => trackGroup.name === 'Powers');
        if (index > -1) {
          charts[index].tracks = this.trialChartsService.prepareTrackData(charts[index], applyChartTemplate, this.parsedTrialTemplate, trialId);
          if (createCopies) {
            chartsCopy[index].tracks = JSON.parse(JSON.stringify(charts[index].tracks)); // deep copy
          }
          if (charts[index].tracks.length > 0) {
            this.trialChartsService.parseTracks(charts, index, this.powerTracks, this.templateService.getTemplateFor('powersCharts'), descriptions[i], true, true);
          }
        }

        index = charts.findIndex(trackGroup => trackGroup.name === 'Muscle lengths and velocities');
        if (index > -1) {
          charts[index].tracks = this.trialChartsService.prepareTrackData(charts[index], applyChartTemplate, this.parsedTrialTemplate, trialId);
          if (createCopies) {
            chartsCopy[index].tracks = JSON.parse(JSON.stringify(charts[index].tracks)); // deep copy
          }
          if (charts[index].tracks.length > 0) {
            this.trialChartsService.parseTracks(charts, index, this.lengthVelocityTracks, this.templateService.getTemplateFor('muscleLengthsVelocitiesCharts'), descriptions[i], true, true);
          }
        }

        index = charts.findIndex(trackGroup => trackGroup.name === 'EMG');
        if (index > -1) {
          charts[index].tracks = this.trialChartsService.prepareTrackData(charts[index], applyChartTemplate, this.parsedTrialTemplate, trialId);
          if (createCopies) {
            chartsCopy[index].tracks = JSON.parse(JSON.stringify(charts[index].tracks)); // deep copy
          }
          if (charts[index].tracks.length > 0) {
            const isGcd = charts[index].tracks[0].originalId.includes('<<<') && charts[index].tracks[0].originalId.includes('>>>');
            const findNearestSample = !defaultTimeSeries && !isGcd;
            // charts[index] contains all the raw tracks related for EMG (inside .tracks)
            // this.emgTracks is empty and will be populated with the tracks we want to show in the chart (currently empty here)
            // this.templateService.getTemplateFor('emgsCharts') represents the template that tells how we want to show the chart
            this.trialChartsService.parseTracks(charts, index, this.emgTracks, this.templateService.getTemplateFor('emgsCharts'), descriptions[i], true, true, findNearestSample);
          }
        }

        index = charts.findIndex(trackGroup => trackGroup.name === 'Forces');
        if (index > -1) {
          charts[index].tracks = this.trialChartsService.prepareTrackData(charts[index], applyChartTemplate, this.parsedTrialTemplate, trialId);
          if (createCopies) {
            chartsCopy[index].tracks = JSON.parse(JSON.stringify(charts[index].tracks)); // deep copy
          }
          if (charts[index].tracks.length > 0) {
            this.trialChartsService.parseTracks(charts, index, this.forceTracks, this.templateService.getTemplateFor('forcesCharts'), descriptions[i], true, true);
          }
        }

        if (this.featureFlagsService.get('enableCombinedChart') === true) {
          // now all tracks are prepared, we use the results for the combined track
          this.trialChartsService.parseTracks(chartsCopy, undefined, this.combinedTracks, this.templateService.getTemplateFor('combinedCharts'), descriptions[i], true, true);
        }
      } else {
        // this should only be for single trials! For now we'll show a warning message if we have more than 1 trial
        if (i > 0) {
          console.log('Multiple trials were found for this report without applying a template, only the first trial is shown.');
        } else {
          // If we don't apply the chart template, we show the data of each single track
          const emptyTrack: TrialTracksGroup = {};
          const emptyChart: ChartTemplateGroup[] = [];
          const chartNames = charts.map(chart => chart.name);
          const preferredCharts = ['Kinematics', 'Moments', 'Powers', 'Forces', 'Muscle lengths and velocities', 'Force plates', 'EMG', 'Trajectories'];
          const preferredChartsToFill = chartNames.filter(function (obj) {
            return preferredCharts.indexOf(obj) !== -1;
          });
          preferredChartsToFill.sort((a, b) => preferredCharts.indexOf(a) - preferredCharts.indexOf(b)); // sort according to preferred charts
          for (const chart of preferredChartsToFill) {
            this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, emptyTrack, emptyChart, charts, chart);
          }

          const additionalCharts = chartNames.filter(function (obj) {
            return preferredCharts.indexOf(obj) === -1;
          });
          for (const additionalChart of additionalCharts) {
            this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, emptyTrack, emptyChart, charts, additionalChart);
          }
        }
      }
    }

    this.trialChartsService.resetLineStyles();
  }


  /**
   * Returns true if the user can select a representative trial for the report.
   * This is based on the feature flag `canExportGaitEvaluationReport`.
   * See https://gitlab.com/moveshelf/mvp/-/issues/3095#note_1902852198
   * @returns boolean
   */
  public canSelectRepresentativeTrial(): boolean {
    return !!this.featureFlagsService.get('canExportGaitEvaluationReport');
  }

  public async storeChartsJson(sessionId: string, reportId: string, gcdParamsData: any[]): Promise<{ blobKey: string, chartsUrl: string }> {

    let chartsUrlResponse;

    try {
      const gaitParamTableRows = this.dataTableTracks.length > 0 ? this.trialChartsService.getGaitParamsTableRows(this.dataTableTracks[0], this.groupByCondition())[0] : [];

      if (this.lineChartMajorGroups.length === 0 && gaitParamTableRows.length === 0) {
        return { blobKey: undefined, chartsUrl: undefined };
      }

      const chartsJsonStr = JSON.stringify([...this.lineChartMajorGroups, { name: "GCD params", data: gcdParamsData }, { name: 'Spatiotemporal parameters', rows: gaitParamTableRows}]);

      chartsUrlResponse = await firstValueFrom(this.apollo.mutate<{ generateReportChartsJsons: { blobKey: string, chartUrl: string } }>(
        {
          mutation: createReportChartsJsonsMutation,
          variables: {
            exporterType: WordExporterType.StoreChartJsonFiles,
            sessionId: sessionId,
            chartsJson: chartsJsonStr,
            reportId: reportId
          }
        }
      ));
    } catch (jsonStorageError) {
      console.error('Error while trying to store charts json files', jsonStorageError);
      this.windowService.alert(`Error while storing charts json files: ${jsonStorageError}`);
      return { blobKey: undefined, chartsUrl: undefined };
    }
    const blobKey = chartsUrlResponse.data?.generateReportChartsJsons?.blobKey;
    const chartsUrl = chartsUrlResponse.data?.generateReportChartsJsons?.chartUrl;

    return { blobKey, chartsUrl };
  }

  public groupByCondition(): boolean {
    return this.clips.length > 1 && !this.trialChartsService.isConditionSummary;
  }

  public parseEmgRefBarData(emgRefBarData: EmgReferenceDataOutput[]): void {
    for (const key in this.emgTracks) {
      const refDataIndex = emgRefBarData.findIndex(x => x.label === key);
      if (refDataIndex !== -1) {
        for (const chart in this.emgTracks[key]) {
          // check if emgRefBarData is present
          this.emgTracks[key][chart].referenceBars = emgRefBarData[refDataIndex];
        }
      }
    }
  }

  async updateReferenceWithId(id: string) {
    this.referenceData = undefined;
    this.selectedReference = id;

    const ids = [];
    for (const r of this.references) {
      if (r.id == id) {
        const myData = await this.getDatafromUri(r.previewUri) as any;
        this.referenceData = {
          paramsData: myData.params,
          kinematicsData: myData.kinematics,
          momentData: myData.moments,
          powerData: myData.powers,
          forceData: myData.forces,
          emgsData: myData.emgs,
          musclesData: myData.muscles
        };
        break;
      }
    }
  }

  async getGaitParameters(gaitParamsDataSources: GaitParamsDataSource[], paramsStructure: any[], isConditionsummary: boolean): Promise<void> {
    for (const gaitParamsDataSource of gaitParamsDataSources) {
      if (gaitParamsDataSource.url !== undefined) {
        const parsedData = await this.getDatafromUri(gaitParamsDataSource.url) as any;
        this.trialChartsService.addGaitParams(parsedData, gaitParamsDataSource.description, paramsStructure, gaitParamsDataSource.id, gaitParamsDataSource.gcdName);
      }
    }

    const hasReference = this.referenceData && this.referenceData.paramsData;
    if (hasReference) {
      const referenceGaitParams = this.referenceData.paramsData;
      this.trialChartsService.addGaitParams(referenceGaitParams, 'reference', paramsStructure);
    }

    const dataTracks: GaitMeasureArray[] = [];
    const descriptions = [];
    for (const o of paramsStructure) {
      dataTracks.push(o.data);
      descriptions.push(o.description);
    }

    if (dataTracks.length > 0) {
      const chartData = {
        id: "barChart",
        values: dataTracks,
        labels: {
          title: descriptions,
        },
      };

      if (isConditionsummary) {
        const splittedClipPath = this.conditionsService.splitProjectPath(this.clips[0].projectPath);
        const conditionDescription = splittedClipPath.sessionName + ' > ' + splittedClipPath.conditionName;
        let iRefStartIndex = -1;
        chartData.labels.title = [conditionDescription];
        if (hasReference) {
          iRefStartIndex = descriptions.findIndex(x => x.split(" > ").length == 1);
          chartData.labels.title.push('reference');
        }
        chartData.values = this.trialChartsService.getAverageMeasureData(dataTracks, iRefStartIndex);
      }

      this.dataTableTracks.push({ name: 'Spatiotemporal parameters', tracks: chartData, expanded: false });
    }
  }

  public getDatafromUri(dataUri: string, isMox = false) {
    let responseType;
    responseType = 'json' as 'json';
    if (isMox) {
      responseType = 'text' as 'text';
    }

    return new Promise((resolve) => {
      this.http.get(dataUri, { responseType: responseType })
        .subscribe(res => {
          const parsedData = (res || {}) as any;
          resolve(parsedData);
        });
    });
  }

  newDurationValue(duration: number, eventFromVideo: boolean): void {
    const clipDuration = eventFromVideo ? duration + this.trialChartsService.playbarOffset : duration;
    this.playbackControlMaster.setClipDuration(clipDuration);
    if (eventFromVideo) {
      this.playbackControlMaster.setVideoDuration(duration);
    }
    this.playbackControlMaster.timebarDuration = clipDuration;
  }

  public isClipHidden(clip: Clip): boolean {
    const currentTrialToggleStatus = this.chartToggleService.trialToggleStatus.value;
    const trialPath = clip.sessionName + ' > ' + clip.conditionName + ' > ' + clip.title;
    const index = currentTrialToggleStatus.findIndex((s) => s.trialPath === trialPath);
    if (index === -1) {
      return false;
    } else {
      return currentTrialToggleStatus[index].hideTrial;
    }
  }

  // toggle the clip visibility on trial toggle service
  public setClipVisibility(clip: Clip): void {
    const currentTrialToggleStatus = this.chartToggleService.trialToggleStatus.value;
    const trialPath = clip.sessionName + ' > ' + clip.conditionName + ' > ' + clip.title;
    const index = currentTrialToggleStatus.findIndex((s) => s.trialPath === trialPath);
    if (index === -1) {
      currentTrialToggleStatus.push({ trialPath, hideTrial: true });
    } else {
      currentTrialToggleStatus[index].hideTrial = !currentTrialToggleStatus[index].hideTrial;
    }
    this.chartToggleService.setTrialToggleStatus(currentTrialToggleStatus);
  }

  public getReferenceName() {
    if (!this.references) {
      return undefined;
    }

    for (const r of this.references) {
      if (r.id == this.selectedReference) {
        return r.name;
      }
    }
    return undefined;
  }
}
