import { Injectable } from '@angular/core';
import { RotationOptions, VideoRotationCSSProps } from '../media-player.types';

@Injectable()
export class MediaPlayerService {

  /**
   * Produce a set of CSS properties for a particular video rotation
   * @returns CSS properties for a specific video rotation
   */
  public getRotationStyle(rotationOptions: RotationOptions, maxWidth: number): VideoRotationCSSProps {
    if (rotationOptions?.rotate) {
      return {
        'max-width.px': maxWidth,
        'transform': `rotate(${rotationOptions.rotation}deg)`,
        '-webkit-transform': `rotate(${rotationOptions.rotation}deg)`,
        '-o-transform': `rotate(${rotationOptions.rotation}deg)`,
        '-ms-transform': `rotate(${rotationOptions.rotation}deg)`,
      };
    }
    return {};
  }

}
