import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export type SubjectLockModalMetadata = {
  question: string,
  btnCancelText: string,
  btnOkText: string,
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  question: string;
  description: string;
  btnOkText: string;
  btnCancelText: string;
  autoHideOnConfirm: boolean = true;

  @Output() confirmed: EventEmitter<boolean> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  triggerConfirm(): void {
    if (this.autoHideOnConfirm) {
      this.bsModalRef.hide();
    }
    this.confirmed.emit(true);
  }

  triggerCancel(): void {
    this.bsModalRef.hide();
  }
}
