import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TemplateDefinition, TemplatesConfiguration } from 'app/shared/services/charts-templates/charts-templates.service';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrialTemplate } from '../trial-template.service';


const ProjectConfigByIdQuery = gql`
  query getProjectConfig($id: ID!) {
    node(id: $id) {
      ... on Project {
        id,
        configuration,
      }
    }
  }
`;

export interface DirectoryOptions {
  /**
   * @deprecated Please use "requireData" instead
   */
  requireC3D?: boolean;
  /**
   * Only proceed with the upload if data files are present in the trial
   */
  requireData?: boolean;
  /**
   * Specify what data format should be used for the trials. Default is "c3d"
   */
  dataFileFormat?: undefined | 'c3d' | 'TheiaKinematics.c3d' | 'gcd' | 'raw.mox' | '.mox';
  /**
   * Specify extensions for additional files to upload. Defaults to [".avi"]
   */
  filesFilter: string[];
  /**
   * Specify a string to look for in the session name, to set this folder type as the default one
   */
  sessionLabelFilter?: string[];
}

export interface ProgressionAnalysisOptions {
  averageTrial: boolean;
  parameterFilter: string[];
  subjectMetaDataParameters: string[];
  hideProgressionCharts?: boolean;
  showWalkingManSpeedChart?: boolean;
}

export interface ChartTemplatesOverride {
  enabled: boolean;
  defaultChartsTemplateId: string;    // If we set a default charts template id, we try to find it in the list of base templates. If not found, fall back to overrides.
  override: TemplatesConfiguration;
}

export interface NormalizeKinetics {
  enabled: boolean;
  normalizeToBodyMassInsteadOfWeight: boolean,
}

export interface PatientsSortOptions {
  field: 'updated' | 'created';
  order: 'asc' | 'desc';
}

export enum AutomaticReportTypes {
  currentSessionConditionSummaries = 'currentSessionConditionSummaries',
  currentVsPreviousSessionComparison = 'currentVsPreviousSessionComparison',
  currentSessionComparison = 'currentSessionComparison',
}

export interface EnableAutomaticReports {
  enabled: boolean;
  automaticReportConfiguration: AutomaticReportConfiguration[];
}

export interface AutomaticReportConfiguration {
  type: AutomaticReportTypes,
  templateId?: string,
  title?: string,
  conditionNames?: string[],
}

export interface ProjectInfo {
  canAnonymizeVideos: boolean;
  canPerformKinematics: boolean;
  canImportArqiveSessions: boolean;
  canUploadViconDirectory: boolean | DirectoryOptions;
  canUploadNoraxonDirectory: boolean | DirectoryOptions;
  canUploadTheiaDirectory: boolean | DirectoryOptions;
  skipMoxDownloadAndParseForCharts: boolean;
  defaultTrialTemplate: TrialTemplate;
  canPublishSessions: boolean;
  canViewLeftRight: boolean;
  canEditSessionMetadata: boolean;
  canPerformBiomechEvaluation: boolean;
  canSelectTrialsAsLeftRight: boolean;
  canProcessGCDFiles: boolean;
  canPerformProgressionAnalysis: boolean | ProgressionAnalysisOptions;
  overrideChartTemplates: ChartTemplatesOverride;
  normalizeKinetics: NormalizeKinetics;
  applyChartTemplateToTrial: boolean;
  canReprocessC3DFiles: boolean;
  enableLongitudinalChartsProcessor: boolean;
  patientsSort: PatientsSortOptions;
  enableAverageButton: boolean;
  enableZoomOnVideoLength: boolean;
  availableChartsTemplates?: TemplateDefinition[];
  showForceOverlay: boolean;
  enableGoniometer: boolean;
  canClickConditions: boolean;
  canExpandChartsInSplitscreen: boolean;
  hideCreateReportButton: boolean;
  showGaitAnalysisResults: boolean;
  canGenerateQrCode: boolean;
  showEhrIntegrationPanel: boolean;
  enableSplitscreenTrialDropdown: boolean;
  showUploadOptions: boolean;
  canUploadGRAILDirectory: boolean | DirectoryOptions;
  canUploadMoxDirectory: DirectoryOptions;
  enableGaitScriptEvaluation: boolean;
  enableSubjectSharing: boolean;
  enableSAGARuler: boolean;
  enableAutomaticGoniometer: boolean;
  enableCombinedChart: boolean;
  canExportGaitEvaluationReport: boolean;
  automaticReportConfiguration?: EnableAutomaticReports;
}

export type FeatureFlags = Partial<ProjectInfo>;

export interface ProjectConfiguration {
  featureFlags: FeatureFlags;
  defaultTrialTemplate: TrialTemplate;
  subjectInfoTemplate: string;  // string indicating which specific template should be used, e.g. GaitTool
}

type SerializedProjectConfiguration = string;

@Injectable({
  providedIn: 'root'
})
export class ProjectInfoDataService {

  constructor(private apollo: Apollo) { }

  public getProjectInfoById(projectId: string): Observable<ProjectInfo> {
    return this.apollo.query<{ node: { configuration: SerializedProjectConfiguration } }>({
      query: ProjectConfigByIdQuery,
      variables: {
        id: projectId
      },
    }).pipe(
      map(({ data }) => {
        const configuration: ProjectConfiguration = JSON.parse(data.node.configuration);
        return {
          canAnonymizeVideos: configuration?.featureFlags?.canAnonymizeVideos,
          canPerformKinematics: configuration?.featureFlags?.canPerformKinematics,
          canImportArqiveSessions: configuration?.featureFlags?.canImportArqiveSessions,
          canUploadViconDirectory: configuration?.featureFlags?.canUploadViconDirectory,
          canUploadNoraxonDirectory: configuration?.featureFlags?.canUploadNoraxonDirectory,
          canUploadTheiaDirectory: configuration?.featureFlags?.canUploadTheiaDirectory,
          skipMoxDownloadAndParseForCharts: configuration?.featureFlags?.skipMoxDownloadAndParseForCharts,
          defaultTrialTemplate: configuration?.defaultTrialTemplate,
          canPublishSessions: configuration?.featureFlags?.canPublishSessions,
          canViewLeftRight: configuration?.featureFlags?.canViewLeftRight,
          canEditSessionMetadata: configuration?.featureFlags?.canEditSessionMetadata,
          canPerformBiomechEvaluation: configuration?.featureFlags?.canPerformBiomechEvaluation,
          canSelectTrialsAsLeftRight: configuration?.featureFlags?.canSelectTrialsAsLeftRight,
          canProcessGCDFiles: configuration?.featureFlags?.canProcessGCDFiles,
          canReprocessC3DFiles: configuration?.featureFlags?.canReprocessC3DFiles,
          canPerformProgressionAnalysis: configuration?.featureFlags?.canPerformProgressionAnalysis,
          overrideChartTemplates: configuration?.featureFlags?.overrideChartTemplates,
          normalizeKinetics: configuration?.featureFlags?.normalizeKinetics,
          applyChartTemplateToTrial: configuration?.featureFlags?.applyChartTemplateToTrial,
          enableLongitudinalChartsProcessor: configuration?.featureFlags?.enableLongitudinalChartsProcessor,
          patientsSort: configuration?.featureFlags?.patientsSort,
          enableAverageButton: configuration?.featureFlags?.enableAverageButton,
          enableZoomOnVideoLength: configuration?.featureFlags?.enableZoomOnVideoLength,
          availableChartsTemplates: configuration?.featureFlags?.availableChartsTemplates,
          showForceOverlay: configuration?.featureFlags?.showForceOverlay,
          enableGoniometer: configuration?.featureFlags?.enableGoniometer,
          canClickConditions: configuration?.featureFlags?.canClickConditions,
          canExpandChartsInSplitscreen: configuration?.featureFlags?.canExpandChartsInSplitscreen,
          hideCreateReportButton: configuration?.featureFlags?.hideCreateReportButton,
          showGaitAnalysisResults: configuration?.featureFlags?.showGaitAnalysisResults,
          canGenerateQrCode: configuration?.featureFlags?.canGenerateQrCode,
          showEhrIntegrationPanel: configuration?.featureFlags?.showEhrIntegrationPanel,
          enableSplitscreenTrialDropdown: configuration?.featureFlags?.enableSplitscreenTrialDropdown,
          showUploadOptions: configuration?.featureFlags?.showUploadOptions,
          canUploadGRAILDirectory: configuration?.featureFlags?.canUploadGRAILDirectory,
          canUploadMoxDirectory: configuration?.featureFlags?.canUploadMoxDirectory,
          enableGaitScriptEvaluation: configuration?.featureFlags?.enableGaitScriptEvaluation,
          enableSubjectSharing: configuration?.featureFlags?.enableSubjectSharing,
          enableSAGARuler: configuration?.featureFlags?.enableSAGARuler,
          enableAutomaticGoniometer: configuration?.featureFlags?.enableAutomaticGoniometer,
          enableCombinedChart: configuration?.featureFlags?.enableCombinedChart,
          canExportGaitEvaluationReport: configuration?.featureFlags?.canExportGaitEvaluationReport,
          automaticReportConfiguration: configuration?.featureFlags?.automaticReportConfiguration,
        };
      }));
  }
}
