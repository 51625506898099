import { Injectable } from '@angular/core';
import { OverlayConfiguration } from '../media-player.types';

@Injectable({
  providedIn: 'root',
})
export class MediaPlayerOverlayService {

  public getVideoElementData = (overlayConfiguration: OverlayConfiguration, videoElem: HTMLVideoElement): OverlayConfiguration => {
    const videoRatio = videoElem.videoWidth / videoElem.videoHeight;
    // The width and height of the original video element
    overlayConfiguration.originalVideoWidth = videoElem.videoWidth;
    overlayConfiguration.originalVideoHeight = videoElem.videoHeight;
    // width and height of the rendered video on page
    overlayConfiguration.overlayWidth = videoElem.offsetWidth;
    overlayConfiguration.overlayHeight = overlayConfiguration.overlayWidth / videoRatio;
    overlayConfiguration.canvasLeftOffset = 0;
    overlayConfiguration.offsetTop = 0;
    // different logic when video is resized bigger than the browser window
    if (overlayConfiguration.overlayHeight > window.innerHeight) {
      overlayConfiguration.overlayHeight = videoElem.offsetHeight;
      const maximumWidth = overlayConfiguration.overlayHeight * videoRatio;
      overlayConfiguration.canvasLeftOffset = Math.floor((overlayConfiguration.overlayWidth - maximumWidth) / 2);
      overlayConfiguration.overlayWidth = maximumWidth;
    }
    if(videoElem.duration !== undefined && !Number.isNaN(videoElem.duration)) {
      overlayConfiguration.totalVideoDuration = videoElem.duration;
    }
    overlayConfiguration.videoElementHeight = videoElem.offsetHeight;
    // if the videoElement is resized shorter than its original ratio
    if ( overlayConfiguration.videoElementHeight < overlayConfiguration.overlayHeight) {
      overlayConfiguration.overlayHeight = overlayConfiguration.videoElementHeight;
    }
    // if the videoElement has empty borders I set the offset for canvas
    if ( overlayConfiguration.overlayWidth > overlayConfiguration.overlayHeight * videoRatio) {
      const maximumWidth = overlayConfiguration.overlayHeight * videoRatio;
      overlayConfiguration.canvasLeftOffset = Math.floor((overlayConfiguration.overlayWidth - maximumWidth) / 2);
      overlayConfiguration.overlayWidth = maximumWidth;
    }
    overlayConfiguration.offsetTop = videoElem.offsetTop;
    return overlayConfiguration;
  };
}
