import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { AnalyticEvent, AnalyticsService } from 'app/shared/services/analytics';
import { delay, Subscription, take } from 'rxjs';
import { MediaData } from '../media-player.types';

type Pixel = number;
type Point = {x: Pixel; y: Pixel};
type Degree = number;
export type DegreeConvention = {degree: Degree, complement: Degree};

const gonioOptions = {
  "Left Knee": {
    "hip": 11,
    "knee": 13,
    "ankle": 15,
  },
  "Right Knee": {
    "hip": 12,
    "knee": 14,
    "ankle": 16,
  }
};

const IDX_Y = 0;
const IDX_X = 1;
const IDX_CONFIDENCE = 2;
const EXPECTED_POINTS = 3;

/**
 * Renders a draggable 3-points goniometer onto a canvas
 */
@Component({
  selector: 'app-goniometer-overlay',
  templateUrl: './goniometer-overlay.component.html',
  styleUrls: ['./goniometer-overlay.component.scss']
})
export class GoniometerOverlayComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @ViewChild('goniometer_canvas', { static: true }) canvasRef: ElementRef;

  /** When a new angle is measured, it is outputted to parent for display */
  @Output() currentAngle: EventEmitter<DegreeConvention> = new EventEmitter<DegreeConvention>();
  @Output() hasBodyPose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() currentVideo: MediaData;
  @Input() selectedGonioOption: string;
  @Input() public overlayWidth: number;
  @Input() public overlayHeight: number;
  @Input() public originalVideoWidth: number;
  @Input() public originalVideoHeight: number;
  @Input() public videoElementHeight: number;
  @Input() public canvasLeftOffset: number;
  @Input() public offsetTop: number;
  @Input() public enableAutomaticGonio: boolean = false;

  public canvasTopPosition: number = 0;

  /** Current absolute mouse position onto the canvas */
  private mousePosition: Point = {x: undefined, y: undefined};

  /** List of up to 3 points selected on the gonio */
  private points: Point[] = [];
  /** The currently selected point during a drag and drop, if any */
  private selectedPoint: Point | undefined;
  /** Update clock for redrawing points during a drag */
  private pointDragRedrawInterval;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private parsedBodyPose: any;
  private lastTime: number = -1;

  private subs: Subscription[] = [];
  private playbackService: PlaybackControlService;

  constructor(
    private http: HttpClient,
    private playbackGlobal: GlobalPlaybackControlService,
    private analyticsService: AnalyticsService,
  ) {}

  public ngOnInit(): void {
    if (this.enableAutomaticGonio) {
      this.subs.push(this.playbackGlobal.playbackControl.subscribe((service) => {
        this.playbackService = service;
        this.setPlaybackControl(service);
      }));
    }
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }


  public ngOnChanges(changes: SimpleChanges): void {

    this.canvasTopPosition = this.offsetTop;
    if (this.videoElementHeight > this.overlayHeight) {
      this.canvasTopPosition += Math.floor((this.videoElementHeight - this.overlayHeight) / 2);
    }
    if (this.playbackService && !!this.context && this.enableAutomaticGonio) {
      this.playbackService.playbackTime.pipe(delay(100), take(1)).subscribe((time: number) => {
        this.applyNewGonioOverlay(time);
      });
    }
    if (changes.currentVideo && this.enableAutomaticGonio) {
      this.getBodyPoseData();
    }
  }

  public ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext("2d");
    this.canvas.width = this.canvas.getBoundingClientRect().width;
    this.canvas.height = this.canvas.getBoundingClientRect().height;

    // Set default starting points to plausible location
    this.points = [
      {x: this.canvas.width*0.5, y: this.canvas.height*0.5},
      {x: this.canvas.width*0.45, y: this.canvas.height*0.7},
      {x: this.canvas.width*0.5, y: this.canvas.height*0.9},
    ];
    this.parsedBodyPose = undefined;
    if (this.enableAutomaticGonio) {
      this.getBodyPoseData();
    }
    this.drawGoniometer();
  }

  private setPlaybackControl(control: PlaybackControlService): void {
    this.subs.push(control.playbackTime.subscribe((time: number) => {
      if (this.context) {
        if (time !== this.lastTime) {
          this.applyNewGonioOverlay(time);
          this.lastTime = time;
        }
        this.drawGoniometer();
      }
    }));
  }

  private applyNewGonioOverlay(time: number): void {
    const twoDecimalsTime = Math.floor(time * 100) / 100;
    this.points = [];
    // if we find a pose, apply it, otherwise set default
    const tFromFps = this.parsedBodyPose?.fps !== undefined && this.parsedBodyPose?.fps > 0 ? 1/this.parsedBodyPose.fps : 1;
    let tMin = 1e6;
    let frameToUse;
    this.parsedBodyPose?.frames.forEach((frame) => {
      const twoDecimalsFramestampTime = Math.floor(frame?.timestamp * 100) / 100;
      const tMinCur = Math.abs(twoDecimalsTime - twoDecimalsFramestampTime);
      if (tMinCur < tMin) {
        tMin = tMinCur;
        frameToUse = frame;
      }
    });

    if (tMin < tFromFps && frameToUse?.frame_keypoints !== undefined && frameToUse.frame_keypoints.length > 0) {
      const xScale = this.originalVideoWidth ? this.canvas.width / this.originalVideoWidth : 1;
      const yScale = this.originalVideoHeight ? this.canvas.height / this.originalVideoHeight : 1;
      const frameKeypoint = frameToUse.frame_keypoints;
      const segmentKeys = gonioOptions[this.selectedGonioOption] !== undefined ? Object.keys(gonioOptions[this.selectedGonioOption]) : [];
      let segmentsAdded = 0;
      if (segmentKeys.length >= EXPECTED_POINTS) {  // we expect at least 3 segments
        for (const segmentKey of segmentKeys) {
          let curPoint: Point = undefined;
          if (frameKeypoint?.[gonioOptions[this.selectedGonioOption][segmentKey]][IDX_CONFIDENCE] > 0.5) {
            const pointY = frameKeypoint[gonioOptions[this.selectedGonioOption][segmentKey]][IDX_Y];
            const pointX = frameKeypoint[gonioOptions[this.selectedGonioOption][segmentKey]][IDX_X];
            curPoint = {
              x: pointX*xScale,
              y: pointY*yScale
            };
            this.points.push(curPoint);
            segmentsAdded++;
          }
          if (segmentsAdded > EXPECTED_POINTS - 1) {
            break;
          }
        }
      }
    }
    // Set default starting points to plausible location
    if (this.points.length !== EXPECTED_POINTS) {
      this.points = [
        {x: this.canvas.width*0.5, y: this.canvas.height*0.5},
        {x: this.canvas.width*0.45, y: this.canvas.height*0.7},
        {x: this.canvas.width*0.5, y: this.canvas.height*0.9},
      ];
    }
  }

  public setCurrentMousePosition(position: Point): void {
    this.mousePosition.x = position.x;
    this.mousePosition.y = position.y;
  }

  /**
   * Check if the current mouse position overlaps an existing point, if it does that point becomes
   * selected and ready to be dragged
   */
  public selectPoint(): void {
    const targetPoint = this.getPoint(this.mousePosition);
    this.selectedPoint = targetPoint;
    this.moveSelectedPoint();
    this.pointDragRedrawInterval = setInterval(this.moveSelectedPoint.bind(this), 60);
  }

  /**
   * Projects the current mouse position onto the canvas, if a point selection was active it is
   * moved to the new position
   */
  public moveSelectedPoint(): void {
    const clickedPoint = this.projectClickCoordinatesOnCanvas(this.mousePosition);
    if (this.selectedPoint !== undefined) {
      this.selectedPoint.x = clickedPoint.x;
      this.selectedPoint.y = clickedPoint.y;
      this.points = [...this.points];
    }

    this.drawGoniometer();
  }

  public endSelection(): void {
    clearInterval(this.pointDragRedrawInterval);
    this.selectedPoint = undefined;
  }

  /**
   * Draw the currently stored points onto the canvas and connect them
   */
  private drawGoniometer(): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.strokeStyle = '#4985ff';
    this.context.lineWidth = 3;
    for (const index in this.points) {
      const point = this.points[index];
      this.context.beginPath();
      this.context.arc(point.x, point.y, 5, 0, 2 * Math.PI, true);
      this.context.stroke();
      this.context.restore();
      if (+index > 0) {
        this.joinPoints(this.points[+index - 1], point);
      }
    }
    if (this.points.length > 2) {
      const currentAngle = this.getAbsAngleBetweenPoints(...this.points.slice(-3) as [Point, Point, Point]);
      this.currentAngle.emit(currentAngle);
    }
  }

  /**
   * Connects two points in the canvas with a line
   */
  private joinPoints(a: Point, b: Point): void {
    this.context.beginPath();
    this.context.moveTo(a.x, a.y);
    this.context.lineTo(b.x, b.y);
    this.context.stroke();
    this.context.restore();
  }

  /**
   * Projects an absolute position to relative canvas coordinates
   */
  private projectClickCoordinatesOnCanvas(clickPosition: Point): Point {
    const rect = this.canvas.getBoundingClientRect();
    const leftMargin = rect.left;
    const topMargin = rect.top;
    const scaleX = this.canvas.width / rect.width;
    const scaleY = this.canvas.height / rect.height;
    const x = (clickPosition.x - leftMargin) * scaleX;
    const y = (clickPosition.y - topMargin) * scaleY;

    return {x, y};
  }

  private getAbsAngleBetweenPoints(a: Point, b: Point, c: Point): DegreeConvention {
    const rightDegree = Math.abs((Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x)) * (180 / Math.PI));
    const angle = rightDegree < 180 ? rightDegree : 360 - rightDegree;

    return {degree: Math.round(angle), complement: Math.round(180 - angle)};
  }

  /**
   * Given a mouse position, projects it onto the canvas and check if an existing point is already
   * present there, with a tolerance of 50px(scaled)
   */
  private getPoint(a: Point): Point | undefined {
    const tolerance = 10;
    const projectedPoint = this.projectClickCoordinatesOnCanvas(a);
    for (const point of this.points) {
      if (Math.abs(projectedPoint.x - point.x) < tolerance && Math.abs(projectedPoint.y - point.y) < tolerance) {
        this.analyticsService.trackEvent(AnalyticEvent.DRAG_GONIOMETER);
        return point;
      }
    }

    return undefined;
  }

  private async getBodyPoseData(): Promise<void>  {
    this.points = [];   // reset points first
    if (this.currentVideo.bodyPose && this.currentVideo.bodyPose.dataUrl !== undefined)  {
      const bodyPoseData = await this.http.get(this.currentVideo.bodyPose.dataUrl).toPromise();
      this.parsedBodyPose = bodyPoseData;
      if (this.lastTime > -1) {
        this.applyNewGonioOverlay(this.lastTime);
        this.drawGoniometer();
      }
      if (this.parsedBodyPose?.frames.some(frame => frame.frame_keypoints !== undefined && frame.frame_keypoints.length > 0)) {
        this.hasBodyPose.emit(true);
      } else {
        this.hasBodyPose.emit(false);
      }
    } else {
      this.parsedBodyPose = undefined;
      this.hasBodyPose.emit(false);
    }
  }
}
