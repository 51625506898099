import { Injectable } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { DataContext } from '../data-helper';
import { SwingTransition } from './chart.types';

export interface CycleProperties {
  label: string,
  seriesId: string,
}

@Injectable()
export class EChartsHighlightService {

  public setInactiveLinesWhenHighlighting = new BehaviorSubject<boolean>(false);

  public downplayCurrentCycle(echartOption: EChartsOption,  echartsInstance: ECharts, seriesId: string): void {
    if (seriesId) {
      echartsInstance.dispatchAction({
        type: 'downplay',
        seriesName: echartOption.series[seriesId].name,
      });
    }
  }

  public highlightCurrentCycle(echartOption: EChartsOption,  echartsInstance: ECharts, currentCycle: CycleProperties[], currentCycleLeft: CycleProperties[]): void {
    if (currentCycle.length > 0) {
      for (const cycle of currentCycle) {
        if (cycle.seriesId) {
          echartsInstance.dispatchAction({
            type: 'highlight',
            seriesName: echartOption.series[cycle.seriesId].name,
          });
        }
      }
    }

    if (currentCycleLeft.length > 0) {
      for (const cycle of currentCycleLeft) {
        if (cycle.seriesId) {
          echartsInstance.dispatchAction({
            type: 'highlight',
            seriesName: echartOption.series[cycle.seriesId].name,
          });
        }
      }
    }
  }

  public clearHighlightedCycle(echartOption: EChartsOption, echartsInstance: ECharts, currentCycle: CycleProperties[], currentCycleLeft: CycleProperties[], useLeftCycle: boolean): void {
    let isDownplayingCharts = false;
    if (!useLeftCycle && currentCycle && currentCycle.length > 0) {
      while (currentCycle.length > 0) {
        // Remove the first item from the array and store it in 'cycle'
        const cycle = currentCycle.shift();
        const seriesId = cycle.seriesId;
        isDownplayingCharts = true;
        this.downplayCurrentCycle(echartOption, echartsInstance, seriesId);
      }
    }

    if (useLeftCycle && currentCycleLeft && currentCycleLeft.length > 0) {
      while (currentCycleLeft.length > 0) {
        // Remove the first item from the array and store it in 'cycle'
        const cycle = currentCycleLeft.shift();
        const seriesId = cycle.seriesId;
        isDownplayingCharts = true;
        this.downplayCurrentCycle(echartOption, echartsInstance, seriesId);
      }
    }
    if (isDownplayingCharts) {
      this.setInactiveLinesWhenHighlighting.next(false);
    }
  }

  public setHighlightedCycle(echartOption: EChartsOption,  echartsInstance: ECharts, currentCycle: CycleProperties[], currentCycleLeft: CycleProperties[], curCycleLabel: string, useLeftCycle: boolean, showAveragesForCycles: boolean, applyAveragePerCondition: boolean, chartMode: 'trial' | 'report'): void {
    const seriesToHighlight = [];
    for (const iSeries in echartOption.series) {
      let cycleFound = false;
      if (chartMode === 'report') {
        cycleFound = this.findCycleMatchForReport(echartOption.series[iSeries].name, undefined, curCycleLabel, useLeftCycle ? DataContext.leftSide : DataContext.rightSide, showAveragesForCycles, applyAveragePerCondition);
      } else {
        cycleFound = this.findCycleMatchForTrial(echartOption.series[iSeries].name, curCycleLabel);
      }
      if (cycleFound) {
        seriesToHighlight.push(iSeries);
      }
    }
    if (seriesToHighlight.length > 0) {
      let seriesIdPrev = [];
      if (useLeftCycle) {
        seriesIdPrev = currentCycleLeft.map(x => x.seriesId);
      } else {
        seriesIdPrev = currentCycle.map(x => x.seriesId);
      }
      // clear arrays without losing their reference
      if (useLeftCycle) {
        currentCycleLeft.length = 0;
      } else {
        currentCycle.length = 0;
      }
      for (const iSeries of seriesToHighlight) {
        if (useLeftCycle) {
          currentCycleLeft.push({ seriesId: iSeries, label: curCycleLabel });
        } else {
          currentCycle.push({ seriesId: iSeries, label: curCycleLabel });
        }
      }
      this.setInactiveLinesWhenHighlighting.next(true);
      if (seriesIdPrev.length > 0) {
        seriesIdPrev.forEach((seriesId) => { this.downplayCurrentCycle(echartOption, echartsInstance, seriesId); });
      }
      this.highlightCurrentCycle(echartOption, echartsInstance, currentCycle, currentCycleLeft);
    }
  }

  public findCycleMatchForReport(seriesName: string, swingTransition: SwingTransition, curCycleLabel: string, dataContext: DataContext, showAveragesForCycles: boolean, applyAveragePerCondition: boolean): boolean {
    // we get swingTransition or curCycleLabel
    if (seriesName === undefined) {
      return false;
    }
    const isRef = swingTransition?.trialName === '<<<reference>>>'
    const split = seriesName.split(' > ');
    let cycleName: string;
    let trialName: string;
    let curContext: DataContext;
    if (swingTransition) {
      cycleName = swingTransition.cycle;
      trialName = swingTransition.trialName;
      curContext = swingTransition.context;
    } else if (curCycleLabel !== undefined) {
      const splitCycleName = curCycleLabel.split(' > ');
      if (splitCycleName.length > 3) {
        cycleName = splitCycleName[0];
        trialName = splitCycleName.splice(1).join(' > ');
        curContext = dataContext;
      }
    }

    if (trialName === undefined) {
      return false;
    }

    const trialNameSplit = trialName.split(' > ');
    const cycleNameWithoutContextStr = cycleName === undefined ? undefined : cycleName.split(' (left)')[0].split(' (right)')[0];
    let cycleNameWithoutContext = false;
    if ((cycleName && cycleName.includes('(left)') && curContext === DataContext.leftSide) || (cycleName && cycleName.includes('(right)') && curContext === DataContext.rightSide)) {
      cycleNameWithoutContext = false;
    } else {
      cycleNameWithoutContext = true;
    }

    if (showAveragesForCycles) {
      const strToCheck = applyAveragePerCondition === true && trialNameSplit.length > 2 ? trialNameSplit[0] + ' > ' + trialNameSplit [1] + ' > <<<conditionAvg>>>' : trialName;
      if (split.length > 0 && seriesName.endsWith(strToCheck)) {
        if (split[0].includes('(left)') && curContext === DataContext.leftSide) {
          return true;
        } else if (split[0].includes('(right)') && curContext === DataContext.rightSide) {
          return true;
        } else if (isRef && curContext == DataContext.noSide) {
          return true;
        }
      }
    } else if ((cycleNameWithoutContextStr && this.findCycleNumberMatch(seriesName, cycleNameWithoutContextStr) || isRef) && ((split[0].includes('(left)') || cycleNameWithoutContext) && curContext === DataContext.leftSide || split[0].includes('(right)') && curContext === DataContext.rightSide || isRef && curContext === DataContext.noSide ) && seriesName.endsWith(trialName)) {
      return true;
    }
    return false;
  }

  public findCycleMatchForTrial(seriesName: string, curCycleLabel:string): boolean {
    const cycleStr = 'cycle' + curCycleLabel.substring(curCycleLabel.indexOf('cycle-') + 5).split(' (')[0]; // Extract cycle string
    const curCycleLabelMean = curCycleLabel.replace(cycleStr,'mean');

    let curCycleContext: string;
    if (curCycleLabel.endsWith(' (left)')) {
      curCycleContext = ' (left)';
    } else if (curCycleLabel.endsWith(' (right)')) {
      curCycleContext = ' (right)';
    }

    if (seriesName === curCycleLabel || seriesName === curCycleLabelMean) {
      return true;
    } else if ((this.findCycleNumberMatch(seriesName, cycleStr) || seriesName.startsWith('mean')) && curCycleContext && seriesName.endsWith(curCycleContext)) {
      return true;
    }
    return false;
  }

  public findCycleNumberMatch(seriesName: string, cycleStr: string): boolean {
    const regex = new RegExp(`^${cycleStr}[^0-9]`);
    return regex.test(seriesName);
  }
}
