import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

type Pixel = number;
type Point = {x: Pixel; y: Pixel};
type Degree = number;

export type RulerAngles = {
  trunk_tilt: Degree,
  pelvic_tilt: Degree,
  hip_angle: Degree,
  knee_angle: Degree,
  ankle_angle: Degree,
  tibia_inclination: Degree,
}

type RulerData = {
  pointHip: Point,
  pointKnee: Point,
  pointAnkle: Point,
  topAngleWithVert: Degree,
  midAngleWithHor: Degree,
  bottomAngleAnkleToe: Degree,
  figureOrientation: 'right' | 'left',
}

type RulerCalculatedPoints = {
  pointTop?: Point,
  pointMid?: Point,
  pointKnee?: Point,
}

const angleAnkleToe = 20;
/**
 * Renders a draggable 3-points goniometer onto a canvas
 */
@Component({
  selector: 'app-saga-ruler-overlay',
  templateUrl: './saga-ruler-overlay.component.html',
  styleUrls: ['./saga-ruler-overlay.component.scss']
})
export class SagaRulerOverlayComponent implements AfterViewInit, OnChanges {
  @ViewChild('ruler_canvas', { static: true }) canvasRef: ElementRef;

  /** When a new angle is measured, it is outputted to parent for display */
  @Output() rulerAnglesEvent: EventEmitter<RulerAngles> = new EventEmitter<RulerAngles>();

  @Input() public overlayWidth: number;
  @Input() public overlayHeight: number;
  @Input() public originalVideoWidth: number;
  @Input() public originalVideoHeight: number;
  @Input() public videoElementHeight: number;
  @Input() public canvasLeftOffset: number;
  @Input() public offsetTop: number;

  public canvasTopPosition: number = 0;

  /** Current absolute mouse position onto the canvas */
  private mousePosition: Point = {x: undefined, y: undefined};

  /** The currently selected point during a drag and drop, if any */
  private selectedPoint: Point | undefined;
  /** Update clock for redrawing points during a drag */
  private pointDragRedrawIntervalArray = [];
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;

  private rulerData: RulerData;
  private rulerCalculatedPoints: RulerCalculatedPoints = {};
  private rulerAngles: RulerAngles;

  public ngOnChanges(changes: SimpleChanges): void {

    this.canvasTopPosition = this.offsetTop;
    if (this.videoElementHeight > this.overlayHeight) {
      this.canvasTopPosition += Math.floor((this.videoElementHeight - this.overlayHeight) / 2);
    }
  }

  public ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext("2d");
    this.canvas.width = this.canvas.getBoundingClientRect().width;
    this.canvas.height = this.canvas.getBoundingClientRect().height;

    this.rulerData = {
      pointHip: {x: this.canvas.width*0.5, y: this.canvas.height*0.5},
      pointKnee: {x: this.canvas.width*0.6, y: this.canvas.height*0.6},
      pointAnkle: {x: this.canvas.width*0.5, y: this.canvas.height*0.7},
      topAngleWithVert: 0,
      midAngleWithHor: 0,
      bottomAngleAnkleToe: angleAnkleToe,
      figureOrientation: 'right',
    };
    this.rulerAngles = {trunk_tilt: 0, pelvic_tilt: 0, hip_angle: 0, knee_angle: 0, ankle_angle: 0, tibia_inclination: 0};


    this.drawGoniometer();
  }

  public setCurrentMousePosition(position: Point): void {
    this.mousePosition.x = position.x;
    this.mousePosition.y = position.y;
    // applying the correct cursor style
    const targetPoint = this.getPoint(this.mousePosition);
    if (targetPoint !== undefined) {
      this.canvasRef.nativeElement.style.cursor = 'pointer';
    } else {
      const rotationPoint = this.getRotationPointIndex(this.mousePosition);
      if (rotationPoint > -1) {
        this.canvasRef.nativeElement.style.cursor = 'grab';
      } else {
        const movePoint = this.isMovePointPosition(this.mousePosition);
        if (movePoint > -1) {
          this.canvasRef.nativeElement.style.cursor = 'move';
        } else {
          this.canvasRef.nativeElement.style.cursor = 'default';
        }
      }
    }
  }

  /**
   * Check if the current mouse position overlaps an existing point, if it does that point becomes
   * selected and ready to be dragged
   */
  public selectPoint(): void {
    const targetPoint = this.getPoint(this.mousePosition);
    if (targetPoint !== undefined) {
      this.selectedPoint = targetPoint;
      this.moveSelectedPoint();
      this.pointDragRedrawIntervalArray.push(setInterval(this.moveSelectedPoint.bind(this), 60));
    } else {
      const rotationPoint = this.getRotationPointIndex(this.mousePosition);
      if (rotationPoint > -1) {
        this.rotateSelectedPoint(rotationPoint);
        this.pointDragRedrawIntervalArray.push(setInterval(this.rotateSelectedPoint.bind(this, rotationPoint), 60));
        this.canvasRef.nativeElement.style.cursor = 'grabbing';
      } else {
        const movePoint = this.isMovePointPosition(this.mousePosition);
        if (movePoint > -1) {
          this.translateIndex(movePoint);
          this.pointDragRedrawIntervalArray.push(setInterval(this.translateIndex.bind(this, movePoint), 60));
        }
      }
    }
  }

  /**
   * Projects the current mouse position onto the canvas, if a point selection was active it is
   * moved to the new position
   */
  public moveSelectedPoint(): void {
    const clickedPoint = this.projectClickCoordinatesOnCanvas(this.mousePosition);
    if (this.selectedPoint !== undefined) {
      this.selectedPoint.x = clickedPoint.x;
      this.selectedPoint.y = clickedPoint.y;
      // this.points = [...this.points];
    }

    this.drawGoniometer();
  }

  public rotateSelectedPoint(rotationPoint: number): void {
    const clickedPoint = this.projectClickCoordinatesOnCanvas(this.mousePosition);
    const midVec = this.normalizeVec([clickedPoint.x - this.rulerData.pointHip.x, clickedPoint.y - this.rulerData.pointHip.y]);
    switch (rotationPoint) {
      case 0:
        this.rulerData.topAngleWithVert = - this.getAngleBetweenPoints(clickedPoint, this.rulerData.pointHip, {x: this.rulerData.pointHip.x, y: this.rulerData.pointHip.y - 1});
        break;
      case 1:
        this.rulerData.midAngleWithHor = - this.getAngleBetweenPoints(clickedPoint, this.rulerData.pointHip, {x: this.rulerData.pointHip.x + 1, y: this.rulerData.pointHip.y});
        if (midVec[0] < 0) {
          if (this.rulerData.figureOrientation === 'right') {
            this.switchOrientation('left');
          }
        } else {
          if (this.rulerData.figureOrientation === 'left') {
            this.switchOrientation('right');
          }
        }
        break;
      case 2:
        this.rulerData.bottomAngleAnkleToe = - this.getAngleBetweenPoints(clickedPoint, this.rulerData.pointAnkle, {x: this.rulerData.pointAnkle.x + 1, y: this.rulerData.pointAnkle.y});
        break;
    }
    this.drawGoniometer();
  }

  private switchOrientation(orientation: 'right' | 'left'): void {
    this.rulerData.figureOrientation = orientation;
    this.rulerData.pointKnee = {x: this.rulerData.pointHip.x - (this.rulerData.pointKnee.x - this.rulerData.pointHip.x), y: this.rulerData.pointKnee.y};
    this.rulerData.pointAnkle = {x: this.rulerData.pointHip.x - (this.rulerData.pointAnkle.x - this.rulerData.pointHip.x), y: this.rulerData.pointAnkle.y};
    this.rulerData.topAngleWithVert = - this.rulerData.topAngleWithVert;
    this.rulerData.bottomAngleAnkleToe = - this.rulerData.bottomAngleAnkleToe + 180;
  }

  public endSelection(): void {
    this.pointDragRedrawIntervalArray.forEach(interval => clearInterval(interval));
    this.selectedPoint = undefined;
    this.canvasRef.nativeElement.style.cursor = 'default';
  }

  /**
   * Draw the currently stored points onto the canvas and connect them
   */
  private drawGoniometer(): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.strokeStyle = '#4985ff';
    this.context.lineWidth = 3;

    // draw points A, B, C
    this.drawPoint(this.rulerData.pointHip);
    this.drawPoint(this.rulerData.pointKnee);
    this.drawPoint(this.rulerData.pointAnkle);

    // connect points A, B, C
    this.joinPoints(this.rulerData.pointHip, this.rulerData.pointKnee);
    this.joinPoints(this.rulerData.pointKnee, this.rulerData.pointAnkle);
    const distanceAB = this.getDistaceBetweenPoints(this.rulerData.pointHip, this.rulerData.pointKnee);
    // draw top point (using distanceAB and topAngleWithVert)
    const pointTop = this.calculatepointcoordinate(this.rulerData.pointHip, distanceAB, this.rulerData.topAngleWithVert - 90);
    this.rulerCalculatedPoints.pointTop = pointTop;
    this.drawPoint(pointTop);
    this.joinPoints(this.rulerData.pointHip, pointTop);
    // draw mid point (using distanceAB and midAngleWithHor)
    const pointMid = this.calculatepointcoordinate(this.rulerData.pointHip, Math.floor(distanceAB / 2), this.rulerData.midAngleWithHor);
    this.rulerCalculatedPoints.pointMid = pointMid;
    this.drawPoint(pointMid);
    this.joinPoints(this.rulerData.pointHip, pointMid);
    const oppositePointMid = this.calculatepointcoordinate(this.rulerData.pointHip, Math.floor(distanceAB / 4), 180 + this.rulerData.midAngleWithHor);
    this.joinPoints(this.rulerData.pointHip, oppositePointMid);
    // draw bottom point (using distanceBC and bottomAngleAnkleToe)
    const distanceBC = this.getDistaceBetweenPoints(this.rulerData.pointKnee, this.rulerData.pointAnkle);
    const pointKnee = this.calculatepointcoordinate(this.rulerData.pointAnkle, Math.floor(distanceBC * 0.45), this.rulerData.bottomAngleAnkleToe); // knee?
    this.rulerCalculatedPoints.pointKnee = pointKnee;
    this.drawPoint(pointKnee);

    // the angle added is equal to the opposite of the starting angle, so that we start with a flat foot (0°)

    if ( this.rulerData.figureOrientation == 'right') {
      const pointTemp = this.calculatepointcoordinate(this.rulerData.pointHip, 10, this.rulerData.topAngleWithVert);
      this.rulerAngles.trunk_tilt =  - this.getAngleBetweenPoints(pointTemp, this.rulerData.pointHip, pointMid);
    } else {
      const pointTemp = this.calculatepointcoordinate(this.rulerData.pointHip, 10, this.rulerData.topAngleWithVert - 180);
      this.rulerAngles.trunk_tilt = this.getAngleBetweenPoints(pointTemp, this.rulerData.pointHip, pointMid);
    }
    const x1 = this.normalizeVec([pointMid.x - this.rulerData.pointHip.x, pointMid.y - this.rulerData.pointHip.y]);
    this.rulerAngles.pelvic_tilt = Math.round(Math.asin(x1[1])/Math.PI*180);

    if ( this.rulerData.figureOrientation == 'right') {
      const pointTemp = this.calculatepointcoordinate(this.rulerData.pointHip, 10, this.rulerData.midAngleWithHor + 90);
      this.rulerAngles.hip_angle = - this.getAngleBetweenPoints(pointTemp, this.rulerData.pointHip, this.rulerData.pointKnee);
    } else {
      const pointTemp = this.calculatepointcoordinate(this.rulerData.pointHip, 10, this.rulerData.midAngleWithHor - 90);
      this.rulerAngles.hip_angle = this.getAngleBetweenPoints(pointTemp, this.rulerData.pointHip, this.rulerData.pointKnee);
    }

    if ( this.rulerData.figureOrientation == 'right') {
      this.rulerAngles.knee_angle = this.getAngleBetweenPoints(this.rulerData.pointHip, this.rulerData.pointKnee, this.rulerData.pointAnkle) + 180;
    } else {
      this.rulerAngles.knee_angle = 180 - this.getAngleBetweenPoints(this.rulerData.pointHip, this.rulerData.pointKnee, this.rulerData.pointAnkle);
    }
    if (this.rulerAngles.knee_angle > 180) {
      this.rulerAngles.knee_angle = this.rulerAngles.knee_angle - 360;
    }

    let pointHeel: Point;
    if ( this.rulerData.figureOrientation == 'right') {
      pointHeel = this.calculatepointcoordinate(pointKnee, Math.floor(distanceBC * 0.45), this.rulerData.bottomAngleAnkleToe - angleAnkleToe - 180);
      const pointTemp = this.calculatepointcoordinate(this.rulerData.pointAnkle, 10, this.rulerData.bottomAngleAnkleToe - 90 - angleAnkleToe);
      this.rulerAngles.ankle_angle = - this.getAngleBetweenPoints(this.rulerData.pointKnee,  this.rulerData.pointAnkle, pointTemp);
    } else {
      pointHeel = this.calculatepointcoordinate(pointKnee, Math.floor(distanceBC * 0.45), this.rulerData.bottomAngleAnkleToe + angleAnkleToe - 180);
      const pointTemp = this.calculatepointcoordinate(this.rulerData.pointAnkle, 10, this.rulerData.bottomAngleAnkleToe + 90 + angleAnkleToe);
      this.rulerAngles.ankle_angle = this.getAngleBetweenPoints(this.rulerData.pointKnee,  this.rulerData.pointAnkle, pointTemp);
    }

    const pointTemp: Point = {x: this.rulerData.pointAnkle.x, y: this.rulerData.pointAnkle.y - 1};
    if ( this.rulerData.figureOrientation == 'right') {
      this.rulerAngles.tibia_inclination = - this.getAngleBetweenPoints(this.rulerData.pointKnee,  this.rulerData.pointAnkle, pointTemp);
    } else {
      this.rulerAngles.tibia_inclination = this.getAngleBetweenPoints(this.rulerData.pointKnee,  this.rulerData.pointAnkle, pointTemp);
    }
    this.joinPoints(pointKnee, pointHeel);

    this.rulerAnglesEvent.emit(this.rulerAngles);
  }

  /**
   * Connects two points in the canvas with a line
   */
  private joinPoints(a: Point, b: Point): void {
    this.context.beginPath();
    this.context.moveTo(a.x, a.y);
    this.context.lineTo(b.x, b.y);
    this.context.stroke();
    this.context.restore();
  }

  private drawPoint(point: Point): void {
    this.context.beginPath();
    this.context.arc(point.x, point.y, 5, 0, 2 * Math.PI, true);
    this.context.stroke();
    this.context.restore();
  }

  private getDistaceBetweenPoints(a: Point, b: Point): number {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  }

  private calculatepointcoordinate(a: Point, length: number, angle: number): Point {
    const angleRad = angle * Math.PI / 180;
    const x = a.x + length * Math.cos(angleRad);
    const y = a.y + length * Math.sin(angleRad);
    return {x, y};
  }

  /**
   * Projects an absolute position to relative canvas coordinates
   */
  private projectClickCoordinatesOnCanvas(clickPosition: Point): Point {
    const rect = this.canvas.getBoundingClientRect();
    const leftMargin = rect.left;
    const topMargin = rect.top;
    const scaleX = this.canvas.width / rect.width;
    const scaleY = this.canvas.height / rect.height;
    const x = (clickPosition.x - leftMargin) * scaleX;
    const y = (clickPosition.y - topMargin) * scaleY;

    return {x, y};
  }

  private getAngleBetweenPoints(a: Point, b: Point, c: Point): number {
    const rightDegree = Math.abs((Math.atan2(c.y - b.y, c.x - b.x) - Math.atan2(a.y - b.y, a.x - b.x)) * (180 / Math.PI));
    let angle = rightDegree < 180 ? rightDegree : 360 - rightDegree;
    const x1 = this.normalizeVec([a.x - b.x, a.y - b.y]);
    const x2 = this.normalizeVec([c.x - b.x, c.y - b.y]);
    const x1_orth = this.rotateVec(x1,90);
    const dot_x1_orth_x2 = this.dotProduct(x2, x1_orth);
    if (dot_x1_orth_x2 < 0) {
      angle = - angle;
    }
    return Math.round(angle);
  }

  private getMiddlePoint(a: Point, b: Point): Point {
    return {x: (a.x + b.x) / 2, y: (a.y + b.y) / 2};
  }

  private normalizeVec(x: number[]): number[] {
    let normSqr = 0;
    for (let i = 0; i < x.length; i++) {
      normSqr += x[i]*x[i];
    }
    const normVec = Math.sqrt(normSqr);
    for (let i = 0; i < x.length; i++) {
      x[i] = x[i]/normVec;
    }
    return x;
  }


  private rotateVec(vec: number[], ang: number): number[] {
    ang = ang * (Math.PI/180);
    const cos = Math.cos(ang);
    const sin = Math.sin(ang);

    const rotatedVec: number[] = [vec[0] * cos - vec[1] * sin, vec[0] * sin + vec[1] * cos];
    return rotatedVec;
  }

  private dotProduct(vector1: number[], vector2: number[]): number {
    let result = 0;
    for (let i = 0; i < vector1.length; i++) {
      result += vector1[i] * vector2[i];
    }
    return result;
  }

  /**
   * Given a mouse position, projects it onto the canvas and check if an existing point is already
   * present there, with a tolerance of 50px(scaled)
   */
  private getPoint(a: Point): Point | undefined {
    const tolerance = 10;
    const projectedPoint = this.projectClickCoordinatesOnCanvas(a);
    for (const point of [this.rulerData.pointHip, this.rulerData.pointKnee, this.rulerData.pointAnkle] as Point[]) {
      if (Math.abs(projectedPoint.x - point.x) < tolerance && Math.abs(projectedPoint.y - point.y) < tolerance) {
        return point;
      }
    }

    return undefined;
  }

    /**
   * Given a mouse position, search if a rotation point is
   * present there, with a tolerance of 50px(scaled)
   */
  private getRotationPointIndex(a: Point): number {
    const tolerance = 10;
    const projectedPoint = this.projectClickCoordinatesOnCanvas(a);
    const points = [this.rulerCalculatedPoints.pointTop, this.rulerCalculatedPoints.pointMid, this.rulerCalculatedPoints.pointKnee] as Point[];
    for (const [index, point] of points.entries()) {
      if (Math.abs(projectedPoint.x - point.x) < tolerance && Math.abs(projectedPoint.y - point.y) < tolerance) {
        return index;
      }
    }
    return -1;
  }

   /**
   * Given a mouse position, search if a movement point is present there
   */
  private isMovePointPosition(a: Point): number {
    const tolerance = 40;
    const projectedPoint = this.projectClickCoordinatesOnCanvas(a);
    // 0: trunk segment, 1: upper leg segment, 2: lower leg segment
    const movementPoints = [
      this.getMiddlePoint(this.rulerData.pointHip,  this.rulerCalculatedPoints.pointTop),
      this.getMiddlePoint(this.rulerData.pointHip, this.rulerData.pointKnee),
      this.getMiddlePoint(this.rulerData.pointKnee, this.rulerData.pointAnkle)] as Point[];
    for (const [index, movementPoint] of movementPoints.entries()) {
      if (Math.abs(projectedPoint.x - movementPoint.x) < tolerance && Math.abs(projectedPoint.y - movementPoint.y) < tolerance) {
        return index;
      }
    }
    return -1;
  }

  private translateIndex(index: number) {
    // 0: trunk segment, 1: upper leg segment, 2: lower leg segment
    switch (index) {
      case 0:
        this.traslateRulerToPosition(this.getMiddlePoint(this.rulerData.pointHip,  this.rulerCalculatedPoints.pointTop), this.mousePosition);
        break;
      case 1:
        this.traslateRulerToPosition(this.getMiddlePoint(this.rulerData.pointHip, this.rulerData.pointKnee), this.mousePosition);
        break;
      case 2:
        this.traslateRulerToPosition(this.getMiddlePoint(this.rulerData.pointKnee, this.rulerData.pointAnkle), this.mousePosition);
        break;
    }
  }

  private traslateRulerToPosition(startPoint: Point, endPoint: Point): void {
    endPoint = this.projectClickCoordinatesOnCanvas(endPoint);
    const deltaX = endPoint.x - startPoint.x;
    const deltaY = endPoint.y - startPoint.y;
    this.rulerData.pointHip = {x: this.rulerData.pointHip.x + deltaX, y: this.rulerData.pointHip.y + deltaY};
    this.rulerData.pointKnee = {x: this.rulerData.pointKnee.x + deltaX, y: this.rulerData.pointKnee.y + deltaY};
    this.rulerData.pointAnkle = {x: this.rulerData.pointAnkle.x + deltaX, y: this.rulerData.pointAnkle.y + deltaY};
    this.drawGoniometer();
  }
}
