import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { OrganizationByDomain } from 'app/shared/queries';
import gql from 'graphql-tag';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';

export enum PersistenceState {
  session = "session",
  local = "local",
}

export const listOrganizations = gql`
query {
  organizations {
      id
      name
  }
}`;

export interface Organization {
  id?: string;
  name: string;
  domain?: string;
  role?: string;
  sso?: string[];
}

interface OrganizationConfiguration {
  processorsEnabled: boolean;
  ehrIntegration: 'HiX' | 'HL7' | undefined;
}

export interface OrganizationPayload {
  id: organizationGlobalId;
  sso: string[];
  name?: string;
  emailPatterns: string[];
  domain: string;
  sessionPersistence?: PersistenceState;
  guestAccount?: string;
  logo?: string,
  configuration?: OrganizationConfiguration
  maintenance: boolean;
}

type organizationGlobalId = string;

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  public bucketWarning = 'Be careful about setting this correctly.';
  public bucketExplanationUrl: 'https://moveshelflabs.atlassian.net/wiki/spaces/DOCS/pages/2948235265/Project+configuration#Organization';
  private currentOrganization: OrganizationPayload;
  private organizationList: OrganizationPayload[];
  // This promise is only resolved lazily as the first requestor asks for the current organization
  private organizationResolverPromise: Promise<OrganizationPayload>;
  private readonly env;

  constructor(
    private apollo: Apollo,
    private environment: EnvironmentService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.env = this.environment.getEnvironment();
  }

  /**
   * This attempts to fetch the organization related to the current domain from the backend and all
   * its related metadata. If no or multiple organizations are found, it is set as undefined.
   * The query is only attempted lazily at first time, once the query is completed, the result
   * is cached for the session.
   * @returns The organization related to the current domain, if any
   */
  private async fetchCurrentOrganization(): Promise<OrganizationPayload> {
    // Check if we already have an organization cached
    if (this.currentOrganization !== undefined) {
      // We do, return that
      return this.currentOrganization;
    }

    // If we are not in a browser (e.g. SSR), we cannot check the current url for a domain, just return
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Extract the domain from the current url by removing the moveshelf suffix from the hostname
    const domain = (new URL(location.origin)).hostname.replace(this.env.subdomainTemplate, '');

    // Query the backend for any orgs related to the domain
    const orgQueryResponse = await firstValueFrom(
      this.apollo.query<{organizations: OrganizationPayload[]}>({query: OrganizationByDomain, variables: {domain: domain}})
    );
    const organizations = orgQueryResponse.data.organizations;
    this.organizationList = organizations;
    // If there are none or multiple, we cannot load an organization
    if (organizations?.length !== 1) {
      console.warn('No or multiple organizations found for the current domain');
      this.currentOrganization = null;
      return null;
    }

    // We found exactly one org, cache it and return it
    this.currentOrganization = organizations[0];
    return this.currentOrganization;
  }

  public organizationChoices(): Observable<string[]> {
    return this.listOrganizations().pipe(map(organizations => {
      const organizationNames = organizations.map(org => org.name);
      return organizationNames;
    }));
  }

  public listOrganizations(): Observable<Organization[]> {
    return this.apollo.query({query: listOrganizations}).pipe(map((result: any) => result.data.organizations));
  }

  public getCurrentOrganizationId(): organizationGlobalId {
    return this.currentOrganization?.id;
  }

  public isSsoEnabled(): boolean {
    return this.currentOrganization?.sso?.length > 0;
  }

  /**
   * Public API to ask for the current organization, this will return the current fetch promise to
   * help serializing requests.
   * @returns The organization related to the current url, if any
   */
  public async getCurrentOrganization(): Promise<OrganizationPayload> {
    if (this.organizationResolverPromise === undefined) {
      this.organizationResolverPromise = this.fetchCurrentOrganization();
    }

    return this.organizationResolverPromise;
  }

  /**
   * Immediately returns the current organization, if available. Callers will have to manually
   * handle undefined results if calling this before the organization is fetched. If unsure,
   * use `getCurrentOrganization()` instead.
   * @returns The currently loaded organization, if available
   */
  public getCurrentOrganizationImmediate(): OrganizationPayload | undefined {
    return this.currentOrganization;
  }

  public isOrgEmailPatternSet(orgName: string): boolean {
    const currentOrg = this.organizationList.find(x => x.name == orgName);
    return currentOrg?.emailPatterns?.length > 0;
  }

  /**
   * Validates an email against current set organization email pattern in `environment.ts`
   * @param email string
   * @returns boolean true if the input email is valid
   */
  public validateOrganizationEmail(email: string): boolean {
    if (!this.currentOrganization?.emailPatterns || this.currentOrganization?.emailPatterns?.length === 0) {
      // If there is no pattern, return true
      return true;
    }

    // Loop through all patterns
    for (const pattern of this.currentOrganization.emailPatterns) {
      // As soon as one matches, return true
      if (email.endsWith(pattern)) {
        return true;
      }
    }

    return false;
  }

  onEnterpriseDomain(): boolean {
    return this.getCurrentOrganizationId() !== undefined;
  }

  public getOrganizationLogo(): string {
    return this.currentOrganization?.logo;
  }


  public updateOrganizationMember(member: string, role: string, organization: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updateOrganizationMemberMutation($organization: String!, $member: String!, $role: OrganizationRole!) {
          updateOrganizationMember(organization: $organization, member: $member, role: $role) {
            updated
          }
        }
      `,
      variables: {
        member: member,
        role: role,
        organization: organization,
      }
    });
  }
}
