import { Injectable } from '@angular/core';
import { DataTrack, LineChartGroup, Sample } from 'app/shared/chart/chart.types';
import { ClipMetadata } from 'app/shared/graphql-fragments/clip-metadata';
import { MediaData, RotationOptions } from 'app/shared/multi-media-player/media-player/media-player.types';
import { FeatureFlags } from './project-info/project-info-data.service';
import { Clip } from './report/report.types';

export enum ShowState {
  Open = "OPEN",
  Collapsed = "COLLAPSED",
  Hidden = "HIDDEN"
}

export interface ForcePlateContextTemplate {
  [key: string]: string
}

export interface ForcePlateContext {
  name: string;
  context: string;
  label: string;
}

interface EmgDataUnit {
  unit: string;
  multiplier: number;
}

export interface InvalidTrialData {
  kinematics?: string;
  kinetics?: string;
  emg?: string;
}

export interface RepresentativeTrialData {
  left?: boolean;
  right?: boolean;
}

export interface TrialTemplate {
  '3D': ShowState;
  charts: any;
  hidePlayheadForCharts: boolean;
  drawVerticalLineAtSwingTransition: boolean;
  applyEmgReferenceBars: boolean | string;
  mergeLeftRightChart: boolean;
  cycleBasisCharts: boolean;
  contextForcePlates: ForcePlateContextTemplate;
  side: string;
  plane: string;
  videoOrder: string[];
  cameraNamesForDltMox: string[][];
  rotateOptions: RotationOptions;
  aidData: any;
  showEmgConsistencyChart: boolean;
  emgDataUnit: EmgDataUnit;
  sideAdditionalData?: Record<string, string>;
  invalidTrialData?: InvalidTrialData;
  representativeTrial?: RepresentativeTrialData;
}

// Reference JSON for trial templates
// export const newTrialTemplate: TrialTemplate = { // trialConfiguration!!
//   charts: {
//     Forces: { //  Capital ok?
//       disabledTracks: [], // e.g. ["" < name in track, allow control from trial view, NOTE: this will be trackname + option to disable force plate name and emg rms/value,
//       cycleBasisCharts: true,
//       },
//     Kinematics: {
//       disabledTracks: [], // e.g. ["" < name in track, allow control from trial view, NOTE: this will be trackname + option to disable force plate name and emg rms/value,
//       cycleBasisCharts: true,
//     },
//   },
//   cycleBasisCharts: true,     // if defined, this holds for all
//   media: undefined,
//   hidePlayheadForCharts: undefined,
//   mergeLeftRightChart: undefined,
//   hideIndividualCycles: undefined
// };

@Injectable({
  providedIn: 'root'
})
export class TrialTemplateService {

  public trialTemplateAsJSON(trialTemplate: string): TrialTemplate {
    if (!trialTemplate) {
      return undefined;
    } else {
      try {
        return JSON.parse(trialTemplate);
      } catch (JsonParsingError) {
        return undefined;
      }
    }
  }

  public mergedTrialTemplateAsJSON(projectConfiguration: FeatureFlags, trialTemplate: string): TrialTemplate {
    let mergedTrialTemplate = projectConfiguration.defaultTrialTemplate;

    if (trialTemplate) {
      // Merge in overrides
      mergedTrialTemplate = { ...mergedTrialTemplate, ...this.trialTemplateAsJSON(trialTemplate) };
    }
    return mergedTrialTemplate;
  }

  // Example template to filter with:
  // {"media":"HIDDEN", "charts": {"EMG": ["RTA - ", "RGM - "] }}
  public filterCharts(arr: LineChartGroup[], trialTemplate: TrialTemplate, chartMode: 'trial' | 'report', defaultTimeSeries?: boolean): LineChartGroup[] {
    let defaultToCycles = chartMode === 'report';
    if (defaultTimeSeries !== undefined) {
      defaultToCycles = defaultTimeSeries === false;
    }

    let hasTemplate = false;
    let myChartsTemplate;
    let cycleBasisChartsAll;
    if (trialTemplate !== undefined) {
      myChartsTemplate = trialTemplate.charts;
      if (trialTemplate.cycleBasisCharts !== undefined) {
        cycleBasisChartsAll = trialTemplate.cycleBasisCharts;
      }
      if (myChartsTemplate !== undefined) {
        hasTemplate = true;
      }
    }

    if (defaultToCycles) {
      cycleBasisChartsAll = true;
    }

    const copy: LineChartGroup[] = JSON.parse(JSON.stringify(arr)); // deep copy
    const result = [];
    for (const chartGroup of copy) {
      let chartsGroupTemplate;
      let chartGroupTemplateName = chartGroup.name;
      let chartGroupnameToCheck = chartGroup.name;
      if (hasTemplate) {
        if (chartGroup.name.toLowerCase().includes('force')) {
          chartGroupTemplateName = 'Force plates'; // make exception for forces to use 'force plates' from template.
          chartGroupnameToCheck = 'force';
        }
        chartsGroupTemplate = myChartsTemplate[chartGroupTemplateName];
      }
      let chartsToShow;
      let includeCycleCharts = false;
      let disabledTracks = [];
      const defaultGroupsToCycles = defaultTimeSeries !== undefined ? !defaultTimeSeries : true;
      if (defaultGroupsToCycles && (
        chartGroup.name.toLowerCase().includes('kinematics') || 
        chartGroup.name.toLowerCase().includes('moments') || 
        chartGroup.name.toLowerCase().includes('powers') ||
        chartGroup.name.toLowerCase().includes('lengths and velocities')
        )) {
        includeCycleCharts = this.checkForCycleCharts(copy, chartGroup.name);
      }
      // if we find emg from xlsx, we get cycles but not time.
      // Check if we indeed and have cycles and no time charts.
      if (defaultGroupsToCycles && chartGroup.name.toLowerCase().includes('emg')) {
        if (!this.checkForTimeCharts(copy, chartGroup.name)) {
          includeCycleCharts = this.checkForCycleCharts(copy, chartGroup.name);
        }
      }
      if (chartsGroupTemplate !== undefined) {
        // For each group, the value can either be an object, "ALL", or on array of chart names to show.
        if (typeof chartsGroupTemplate === 'object') {
          if (cycleBasisChartsAll === undefined && chartsGroupTemplate.cycleBasisCharts !== undefined) {
            includeCycleCharts = chartsGroupTemplate.cycleBasisCharts;
          }
          if (chartsGroupTemplate.disabledTracks && chartsGroupTemplate.disabledTracks.length > 0) {
            disabledTracks = chartsGroupTemplate.disabledTracks;
          }
          chartsToShow = chartsGroupTemplate.chartsToShow;
        } else {
          chartsToShow = chartsGroupTemplate;
        }
      }

      // Overwrite if cycleBasisChartsAll is defined
      if (cycleBasisChartsAll !== undefined) {
        includeCycleCharts = cycleBasisChartsAll;
      }
      if (includeCycleCharts === true && !defaultToCycles) {
        includeCycleCharts = this.checkForCycleCharts(copy, chartGroupnameToCheck);
      }

      chartGroup.tracks = this.filterTracksForCyclesOrTime(chartGroup.tracks, includeCycleCharts);
      if (defaultToCycles === false && chartsToShow !== undefined && chartsToShow !== "ALL") {
        chartGroup.tracks = chartGroup.tracks.filter(track => {
          return chartsToShow.includes(track.labels.title);
        });
      }

      if (chartGroup.tracks.length > 0) {
        if (disabledTracks.length > 0) {
          const newTracks: DataTrack[] = [];
          for (const track of chartGroup.tracks) {
            const xLabel = track?.labels?.time ?? 'perc';
            let useTrack = true;
            let filterValues = false;
            const keys = Object.keys(track.labels.data);
            const skippedKeys: string[] = [];
            for (const disabledTrack of disabledTracks) {
              if (track.labels.title.toLowerCase().includes(disabledTrack.toLowerCase()) === true) {
                useTrack = false;
                break;
              }
              // limit channel filtering to emg for now to support value/rms.
              if (chartGroup.name.toLowerCase().includes('emg')) {
                const keyName = disabledTrack.replace('-', '');
                for (const key of keys) {
                  if (keyName.length > 0 && key.includes(keyName) === true) {
                    filterValues = true;
                    skippedKeys.push(key);
                  }
                }
              }
            }
            if (skippedKeys.length === keys.length) {
              useTrack = false;
            }

            if (filterValues && skippedKeys < keys) {
              const newValues: Sample[] = [];
              for (let i = 0; i < track.values.length; i++) {
                const sample: Sample = {};
                for (const key in track.values[i]) {
                  if (skippedKeys.includes(key) === false) {
                    sample[key] = track.values[i][key];
                  }
                }
                newValues.push(sample);
              }
              track.values = newValues;
              // update labels
              const labelsData = {};
              for (const label in newValues[0]) {
                if (label !== xLabel) {
                  labelsData[label] = label;
                }
              }
              track.labels.data = labelsData;
            }
            if (useTrack) {
              newTracks.push(track);
            }
          }
          chartGroup.tracks = newTracks;
        }

        if (chartGroup.tracks.length > 0) {

          // If template has been defined, that means we want to see the charts.
          if (defaultToCycles === false && chartsGroupTemplate !== undefined) {
            chartGroup.expanded = true;
          }

          result.push(chartGroup);
        }
      }
    }
    return result;
  }

  public filterTracksForCyclesOrTime(tracks: DataTrack[], includeCycleCharts: boolean): DataTrack[] {
    if (includeCycleCharts) {
      tracks = tracks.filter(track => {
        return track.hasCycles;
      });
    } else {
      tracks = tracks.filter(track => {
        return !track.hasCycles;
      });
    }
    return tracks;
  }

  public checkForCycleCharts(myGroup: LineChartGroup[], chartGroupname: string): boolean {
    const myTracks: LineChartGroup[] = myGroup.filter(trackGroup => trackGroup.name.toLowerCase().includes(chartGroupname.toLowerCase()));
    let includeCycleCharts = false;
    for (const subChartGroup of myTracks) {
      const tracks = subChartGroup.tracks.filter(track => {
        return track.hasCycles;
      });
      if (tracks.length > 0) {
        includeCycleCharts = true;
      }
    }
    return includeCycleCharts;
  }

  public checkForTimeCharts(
    myGroup: LineChartGroup[],
    chartGroupname: string
  ): boolean {
    const myTracks: LineChartGroup[] = myGroup.filter(
      trackGroup => trackGroup.name.toLowerCase().includes(
        chartGroupname.toLowerCase()
      )
    );
    for (const subChartGroup of myTracks) {
      const tracks = subChartGroup.tracks.filter(
        track => track?.hasCycles !== true
      );
      if (tracks && tracks.length > 0) {
        return true;
      }
    }
    return false;
  }

  getUpdatedClipTitleFromTemplate(clip: ClipMetadata | Clip): string {
    const clipTitle = clip.title;
    let classification = '';
    if (clip?.customOptions) {
      const customOptions: { trialTemplate: TrialTemplate } = JSON.parse(clip.customOptions);
      if (customOptions?.trialTemplate) {
        const trialSide = customOptions.trialTemplate?.side;
        const trialPlane = customOptions.trialTemplate?.plane;
        if ((trialPlane && trialPlane.length > 0) || (trialSide && trialSide.length > 0)) {
          classification += '(';
          let spaceStr = '';
          if (trialPlane && trialPlane.length > 0) {
            classification += trialPlane;
            spaceStr = ' ';
          }
          if (trialSide && trialSide.length > 0) {
            classification += spaceStr + trialSide;
          }
          classification += ') ';
        }
      }
    }
    return classification + clipTitle;
  }

  /**
   * Sorts the current trial tracks based on a list of tracks prefixes.
   * Videos that are missing from the sorting array are placed last withtout any particular sorting
   * logic
   * @see #1090
   */
  public orderVideosByMetadata(videoOrder: string[], tracks: MediaData[]): MediaData[] {
    const newVideoTracks = [];
    const missingVideoTracks = []; // These are tracks that are not found in the ordering list
    for (const videoTrack of tracks) {
      const videoName: string = videoTrack.originalFileName;
      // Look for a ordering key in the ordering list for the current video
      const newIndex = videoOrder.findIndex(videoNameOrder => videoName.includes(videoNameOrder));
      if (newIndex !== -1) {
        if (newVideoTracks[newIndex] !== undefined) {
          // A video was already placed in the current position, likely an error in configuration
          console.warn(`Multiple videos found with the same ordering key (${videoOrder[newIndex]}), please review uploaded videos and the trial video ordering.`);
        }
        // Video is found, place it at its correct index
        newVideoTracks[newIndex] = videoTrack;
      } else {
        // Video is not found, place it unsorted in the missing video tracks
        missingVideoTracks.push(videoTrack);
      }
    }
    // Final list of tracks is made of sorted tracks + all unsorted ones at the end
    const sortedTracks = newVideoTracks.concat(missingVideoTracks)
      .filter(track => track !== undefined); // Remove empty indexes
    return sortedTracks;
  }
}
