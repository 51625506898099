import {
    Component, Input, ViewChild
} from '@angular/core';
import { ClipOptionsService } from 'app/core/clip-options.service';
import { AClipLoaderLogicsComponent } from 'app/moveshelf-3dplayer/clip-loader/clip-loader-logics.component.abstract';
import { ClipLoaderService } from 'app/moveshelf-3dplayer/clip-loader/clip-loader.service';
import { Clip3DData } from 'app/shared/multi-media-player/media-player/media-player.types';
import { LeftRightService } from '../left-right.service';
import { SplitscreenMocapClipComponent } from '../splitscreen-mocap-clip/splitscreen-mocap-clip.component';

@Component({
  selector: 'app-splitscreen-clip-loader',
  templateUrl: './splitscreen-clip-loader.component.html',
  styleUrls: ['./splitscreen-clip-loader.component.scss'],
})

export class SplitscreenClipLoaderComponent extends AClipLoaderLogicsComponent {

  @Input() showFullscreenIcon: boolean = true;
  @Input() moveFollowCam: boolean = false;

  public currentClip: Clip3DData;

  @ViewChild(SplitscreenMocapClipComponent)
  protected clipPlayer: SplitscreenMocapClipComponent;

  constructor(
    protected loader: ClipLoaderService,
    protected optionsService: ClipOptionsService,
    protected leftRightService: LeftRightService,
  ) {
    super(optionsService);
  }

  public screenshot(): string {
    return this.clipPlayer.screenshot();
  }

  public animatedScreenshot(): Promise<string> {

    return new Promise( (resolve) => {
       this.clipPlayer.animatedScreenshot().then( (dataUrl) => { resolve(dataUrl) } );
    });
  }

}
