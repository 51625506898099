import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TrialChartsService } from 'app/shared/multi-chart/trial-charts.service';
import { TileUpdateIdsAndType } from '../../left-right';
import { Tile } from '../../left-right.service';
import { ChartTile } from './chart-tile';

@Component({
  selector: 'app-chart-tile',
  templateUrl: './chart-tile.component.html',
  styleUrls: ['./chart-tile.component.scss'],
})
export class ChartTileComponent extends ChartTile {
  @Output() public handleNewTile: EventEmitter<Tile> = new EventEmitter<Tile>();

  @ViewChild('multiChart') multiChartElement: ElementRef;

  public constructor(
    protected readonly chartsService: TrialChartsService,
  ) {
    super(chartsService);
  }

  public isMultiChart(): boolean {
    return this.tile.dataType === 'multi' || this.tile.dataType === 'multi-report';
  }

  public changeChart(chartDropdownElement: HTMLSelectElement): void {
    if (chartDropdownElement.value.indexOf('----') === -1) {
      const changedTile: TileUpdateIdsAndType = { oldId: this.tile.originalId, newId: chartDropdownElement.value, type: 'multi-chart'};
      this.tile = {
        id: this.chartsService.clipId,
        values: [],
        events: [],
        colors: [],
        labels: {
          title: '',
          hAxis: '',
          vAxis: '',
          time: '',
          data: {},
        },
        series: {},
        originalId: chartDropdownElement.value,
        dataType: this.chartsService.getChartMode() === 'trial' ? 'multi' : 'multi-report',
      };
      this.handleNewTile.emit(this.tile);
      this.tileChanged.emit(changedTile);
    } else {
      const chartNameParts = chartDropdownElement.value.split('----');
      const chartGroupName = chartNameParts[0];
      const chartTitle = chartNameParts[1];
      for (const chartGroup of this.fetchCharts()) {
        if (chartGroup.name === chartGroupName) {
          for (const chart of chartGroup.tracks) {
            if (chart.labels.title === chartTitle) {
              const changedTile: TileUpdateIdsAndType = {oldId: this.tile.originalId, newId: chart.originalId, type: 'chart'};
              this.handleNewTile.emit(chart);
              this.tileChanged.emit(changedTile);
              this.tile = chart;
              return;
            }
          }
        }
      }
      console.error('Cannot find the selected chart!');
    }
  }
}
