import * as THREE from 'three';

const MAX_FREQ_HZ = 200;

export class ActivationValueRenderer {

    objects: {
        obj: THREE.Object3D,
        defaultColor: THREE.Color }[] = [];
    defaultColor: THREE.Color[] = [];
    dataReady: boolean = false;
    activationScale: number = 1;
    normActivationThreshold: number = 0.1;
    absActivationThreshold: number = 0.1;
    values: any[] = [];
    dataIndex: number = 0;
    time: number = 0;

    targetColor: any;

    activationValue: number = 0;

    constructor(color: any = 0xff0000) {
        this.targetColor = color;
    }

    public update(time) {
        if (this.dataReady) {

            const values = this.values;
            while ((this.dataIndex + 1) < values.length && values[this.dataIndex + 1].time < time) {
              this.dataIndex++;
            }

            const dataSample = Math.abs(values[this.dataIndex].x);
            this.activationValue = dataSample * this.activationScale;

            const targetColor = new THREE.Color(this.targetColor);
            for (const o of this.objects) {
                const color = new THREE.Color(o.defaultColor);

                let activation = 0;
                if (dataSample > this.absActivationThreshold && this.activationValue > this.normActivationThreshold)
                    activation = this.activationValue;

                o.obj.material.color.r = color.r*(1 - activation) + targetColor.r*activation;
                o.obj.material.color.g = color.g*(1 - activation) + targetColor.g*activation;
                o.obj.material.color.b = color.b*(1 - activation) + targetColor.b*activation;
            }

            if ((this.dataIndex + 1) >= values.length || this.time > time)
              this.dataIndex = 0;

            this.time = time;
        }
    }

    public getActivationValue() {
        return this.activationValue;
    }

    public addObject(obj: THREE.Object3D) {
        this.objects.push({ obj: obj, defaultColor: obj.material.color.getHex() });
    }

    public setData( data: any, labels?: any, max?: number) {
        this.fillData(data, labels, max);
        this.dataReady = true;
    }

    fillData(data: any, labels?: any, max?: number) {
        let skip = 1;
        const avgNo = 50;

        if (data && data.length > avgNo) {
            const end = data[avgNo][labels.time];
            const start = data[1][labels.time];
            const freq = avgNo/(end - start);
            const decimate = Math.ceil(freq / MAX_FREQ_HZ);
            if (!isNaN(decimate) && decimate > 0)
                skip = decimate;
        }

        let autoScale = false;
        if (max == undefined) {
            max = 0;
            autoScale = true;
        }

        this.values = [];
        for (let i=0; i<data.length; i = i+skip) {
            const value =  data[i][labels.x];
            this.values.push(
                { time: data[i][labels.time],
                x: value });

            if (autoScale && value > max)
                max = value;
        }

        if (max > 0) {
            this.activationScale = 1 / max;
        }
    }

}
