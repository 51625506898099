export enum PlugInGaitBonesConfiguration {
    None,
    PlugInGaitBonesFullBody, //39 markers
    PlugInGaitBonesLowerBody, //19 markers
}

export class PlugInGaitBones {
    markerList: any[];

    // For each segment we expect an O/A/L/P marker
    readonly pelvis = ["PELO", "PELP", "PELA", "PELL"]
    readonly rightFemur = ["RFEO", "RFEP", "RFEA", "RFEL"]
    readonly rightTibia = ["RTIO", "RTIP", "RTIA", "RTIL"]
    readonly rightFoot = ["RFOO", "RFOP", "RFOA", "RFOL"]
    readonly leftFemur = ["LFEO", "LFEP", "LFEA", "LFEL"]
    readonly leftTibia = ["LTIO", "LTIP", "LTIA", "LTIL"]
    readonly leftFoot = ["LFOO", "LFOP", "LFOA", "LFOL"]
    readonly head = ["HEDO", "HEDP", "HEDA", "HEDL"]
    readonly rightHumerus = ["RHUO", "RHUP", "RHUA", "RHUL"]
    readonly rightRadius = ["RRAO", "RRAP", "RRAA", "RRAL"]
    readonly rightHand = ["RHNO", "RHNP", "RHNA", "RHNL"]
    readonly leftHumerus = ["LHUO", "LHUP", "LHUA", "LHUL"]
    readonly leftRadius = ["LRAO", "LRAP", "LRAA", "LRAL"]
    readonly leftHand = ["LHNO", "LHNP", "LHNA", "LHNL"]
    readonly thorax = ["TRXO", "TRXP", "TRXA", "TRXL"]

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

    missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
        }
        return missing;
    }


    getSegments() {
        const segments = [];
        // we build our model based on our markers found per segment using O/A/P/L structure

        this.fillSegment(segments, this.head);
        this.fillSegment(segments, this.rightHumerus);
        this.fillSegment(segments, this.rightRadius);
        this.fillSegment(segments, this.rightHand);
        this.fillSegment(segments, this.leftHumerus);
        this.fillSegment(segments, this.leftRadius);
        this.fillSegment(segments, this.leftHand);
        this.fillSegment(segments, this.thorax);
        this.fillSegment(segments, this.pelvis);
        this.fillSegment(segments,  this.rightFemur);
        this.fillSegment(segments, this.leftFemur);
        this.fillSegment(segments, this.rightTibia);
        this.fillSegment(segments,  this.leftTibia);
        this.fillSegment(segments,  this.leftFoot);
        this.fillSegment(segments,  this.rightFoot);
        this.connectSegments(segments, this.head, this.thorax)
        this.connectSegments(segments, this.thorax, this.rightHumerus)
        this.connectSegments(segments, this.rightHumerus, this.rightRadius)
        this.connectSegments(segments, this.rightRadius, this.rightHand)
        this.connectSegments(segments, this.thorax, this.leftHumerus)
        this.connectSegments(segments, this.leftHumerus, this.leftRadius)
        this.connectSegments(segments, this.leftRadius, this.leftHand)
        this.connectSegments(segments, this.thorax, this.pelvis)
        this.connectSegments(segments, this.pelvis, this.rightFemur)
        this.connectSegments(segments, this.rightFemur, this.rightTibia)
        this.connectSegments(segments, this.rightTibia, this.rightFoot)
        this.connectSegments(segments, this.pelvis, this.leftFemur)
        this.connectSegments(segments, this.leftFemur, this.leftTibia)
        this.connectSegments(segments, this.leftTibia, this.leftFoot)
        return segments;
    }

    fillSegment(segments, curSeg) {
        const colors = ["rgb(0,0,0)","rgb(255,0,0)","rgb(0,255,0)","rgb(0,0,255)"]
        for (let i = 1; i<curSeg.length; i++) {
            segments.push({name: curSeg[0] + curSeg[i], markerList: [curSeg[0], curSeg[i]], allConnected: false, color: colors[i], lineLength: 0.2});
        }
    }

    connectSegments(segments, segA, segB) {
        const color = "rgb(255, 0, 255)";
        segments.push({name: segA[0] + segB[0], markerList: [segA[0], segB[0]], allConnected: false, color: color});
    }

    checkConfiguration(config: any[]) {
        const missingMarkers = [];
        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }

        // now we don't have missing markers, set origin marker visible
        for (const c of config) {
            const originMarker = c[0];
            for (const m of this.markerList) {
              if (m.name.indexOf(originMarker) != -1) {// && m.userData.type == 'vMarker') {
                m.visible = true;
                break;
              }
            }
        }
        return missingMarkers;
    }

    findConfiguration() {
        const fullBody = [
            this.head,
            this.rightHumerus,
            this.rightRadius,
            this.rightHand,
            this.leftHumerus,
            this.leftRadius,
            this.leftHand,
            this.thorax,
            this.pelvis,
            this.rightFemur,
            this.leftFemur,
            this.rightTibia,
            this.leftTibia,
            this.leftFoot,
            this.rightFoot
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return PlugInGaitBonesConfiguration.PlugInGaitBonesFullBody;
        }

        const lowerBody = [
            this.pelvis,
            this.rightFemur,
            this.leftFemur,
            this.rightTibia,
            this.leftTibia,
            this.leftFoot,
            this.rightFoot
          ];

        if (this.checkConfiguration(lowerBody).length <= 1)
            return PlugInGaitBonesConfiguration.PlugInGaitBonesLowerBody;

        return PlugInGaitBonesConfiguration.None;
    }
}