<div class="mb-3">
<!-- <button class="btn btn-primary" (click)="fileSelector.click()">Add video track</button> -->
<div *ngIf="!hideDragAndDrop"
  appDndFile
  [accept]="supportedFileTypes"
  (acceptedFiles)="addFiles($event)"
  (rejectedFiles)="rejectFiles($event)"
  class="upload-card clickable-success mb-0 pb-0"
  (click)="fileSelector.click()">
  <div class="m-3 text-center">
    {{description}}
  </div>
</div>
<input type="file" style="display:none" (change)="addFiles(fileSelector.files)" #fileSelector multiple><br *ngIf="!hideDragAndDrop">
<div *ngIf="errors.length > 0" class="card border-danger my-2">
  <ul class="list-group list-group-flush">
    <li *ngFor="let error of errors; let i=index" class="list-group-item list-group-item">
      <span class="pull-right">
        <i class="fa fa-times clickable-danger" aria-label="Close" (click)="clearError(i)"></i>
      </span>
      <p style="font-size: 0.9em">{{error.error}}</p>
    </li>
  </ul>
</div>
<div *ngIf="data?.length > 0" class="mb-2">
  <i class="text-muted">{{tracks_title}}</i>
</div>
<ul class="px-2 mb-1" style="list-style-type:none">
  <li *ngFor="let item of data" class="mb-1" [style.color]="this.isDataForTestersOnly(item) ? 'orange' : ''">
    <div class="d-flex flex-row">
      <input *ngIf="this.additionalDataSelectionEnabled === true" class="form-check-input" type="checkbox" [checked]="item.selected" (click)="this.selectData(item)">
      <div class="d-flex flex-grow-1 text-truncate">
        <app-outdated-warning *ngIf="item.outdated === true"></app-outdated-warning>
        <span [class.text-muted]="this.additionalDataSelectionEnabled === true" *ngIf="gcdContext[item.id] !== undefined && gcdContext[item.id].length > 0"> ({{gcdContext[item.id]}})&nbsp;</span><span [class.text-muted]="this.additionalDataSelectionEnabled === true"> {{item.originalFileName}}</span>
      </div>
      <div class="d-flex flex-grow-0 ml-3">
        <span>{{readableUploadStatus(item.uploadStatus)}}</span>
        <i *ngIf="item.uploadStatus !== 'Complete' && item.uploadStatus !== 'Failed'" class="ml-1 fa fa-spin fa-spinner" [style.color]="item.uploadStatus == 'Processing' ? 'orange' : ''"></i>
        <a *ngIf="this.canEditSession()" (click)="downloadAdditionalData(item.id)" [download]="item.originalFileName" tooltip="Download file"><i class="clickable-success fa fa-download ml-2 my-0"></i></a>
        <i class="ml-2 fa fa-trash-o clickable-danger my-0" (click)="openDeleteConfirmationModal(item.id)"></i>
      </div>
    </div>
    <progressbar *ngIf="item.uploadStatus !== 'Complete'" class="my-1" [type]="this.getProgressBarStatus(item.uploadStatus)" [max]=1 [animate]=true [value]="getProgressValue(item)"></progressbar>
  </li>
</ul>
</div>
