import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ProjectInfo } from 'app/projects/project-view.types';
import gql from 'graphql-tag';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../core/auth.service';
import { ClipUpdateService } from '../core/clip-update.service';
import { PublicAllowedService } from '../projects/public-allowed.service';
import { ClipMetadata } from './graphql-fragments/clip-metadata';
import { EnvironmentService } from './services/environment.service';
import { FeatureFlagsService } from './services/feature-flags.service';

const getUsersLinkedQuery = gql`
query getPatientLinkedUsers($id: ID!) {
  node(id: $id) {
    ... on Patient {
      id
      linkedUsers {
        user {
          email
          id
        }
      }
    }
  }
}
`;

const deleteUserMutation = gql`
  mutation deleteAccountByFirebaseId($inputs: DeleteAccountByFirebaseIdInput) {
  deleteAccountByFirebaseId(inputs: $inputs) {
    deleted
  }
}
`;

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-sharing-clip',
  templateUrl: './sharing-clip.component.html',
  styleUrls: ['./sharing-clip.component.scss'],
})
export class SharingClipComponent implements OnInit {

  selectedUrl;
  sharingUrls;

  @Input()
  subject;

  @Input()
  urlToShare: string;

  @Input()
  allowCodeSnippet: boolean = false;

  @Input() allowUnlistedAccess: boolean;
  @Output() allowUnlistedAccessChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _clip: ClipMetadata;
  @Input() set clip(info: ClipMetadata) {
    this._clip = info;
    this.updateInfo();
  }
  public isSharingPatientActive: boolean = false;

  @Input()
  project: ProjectInfo;

  embedUrl: string;
  @ViewChild('modalTemplate')
  public modalTemplate: TemplateRef<any>;
  public modal: BsModalRef;

  @ViewChild('privateSharing')
  public privateSharing: TemplateRef<any>;

  @ViewChild('grantSubjectLevelAccess')
  public grantSubjectLevelAccess: TemplateRef<any>;

  public moreOptionExpanded: boolean = false;

  displayCode: boolean = false;
  disablePrivateSharingControl: boolean = false;
  private externalEmail: string = null;
  private externalPassword: string = null;
  private showPassword = false;
  public usersWithAccess = [];
  public copyToClipboardMsg: string = undefined;
  public errorMessage: string = undefined;
  public deletedUser: string = undefined;
  public noSSOCheckbox: boolean = false;
  protected confirmationVisible = {};

  constructor(
    private modalService: BsModalService,
    private clipUpdater: ClipUpdateService,
    private location: Location,
    public publicAllowed: PublicAllowedService,
    private environment: EnvironmentService,
    private apollo: Apollo,
    public auth: AuthService,
    private readonly featureFlags: FeatureFlagsService,
  ) {
    this.confirmationVisible = {};
  }

  openModal(dynamicContent: string = "Nothing to share") {
    // get all the usersWithAccess and set them to false
    this.usersWithAccess.forEach(user => this.confirmationVisible[user.user.email] = false);
    this.externalEmail = null;
    this.externalPassword = null;
    this.sharingUrls = [];
    const url = this.location.path().substring(1);

    if (this.subject) {

      this.sharingUrls.push({ name: "Subject", url: "project/" + this.project.id + "/subject/" + this.subject });
      if (url.indexOf("session") != -1) {
        this.sharingUrls.push({ name: "Session", url: url });
      }
      if (url.indexOf("trial") != -1) {
        this.sharingUrls.push({ name: "Trial", url: url });
      }
      if (url.includes("report")) {
        this.sharingUrls.push({ name: "Report", url: url });
      }

    }

    if (this.project) {
      if (this.project.public) {
        this.modal = this.modalService.show(this.modalTemplate, { class: 'modal-sm' });
      } else {
        this.modal = this.modalService.show(this.privateSharing);
      }
    } else {
      let publicClip = true;

      if (this._clip) {
        publicClip = this._clip.project.public;
      }

      if (publicClip) {
        this.modal = this.modalService.show(this.modalTemplate, { class: 'modal-sm' });
      } else {
        this.modal = this.modalService.show(this.privateSharing);
      }
    }

    if (this.sharingUrls.length > 0) {
      this.selectedUrl = this.sharingUrls[this.sharingUrls.length - 1].url;
    }
  }

  shareLink(event: any) {
    if (this.project) {
      this.allowUnlistedAccessChange.emit(event);
    } else {
      this.clipUpdater.updateClip({
        id: this._clip.id,
        metadata: {
          allowUnlistedAccess: event
        }
      }).subscribe(data => {
        const value = data.data.updateClip.clip.allowUnlistedAccess;
        this.allowUnlistedAccess = value;
        this.allowUnlistedAccessChange.emit(value);
      });
    }
  }

  getUrlToShare() {
    if (this.selectedUrl) {
      this.urlToShare = this.getUrlBase() + this.selectedUrl;
    }
    if (this.noSSOCheckbox) {
      this.urlToShare += '?auto=false';
    }

    return this.urlToShare;
  }

  getUrlBase(): string {
    if (this.project?.organization?.domain) {
      return `https://${this.project.organization.domain}${this.environment.getEnvironment().subdomainTemplate}/`;
    } else {
      return this.environment.getEnvironment().baseHref;
    }
  }

  createCredentials(): void {
    this.apollo.mutate<any>({
      mutation: gql`
      mutation addUserPatientRelationship($subjectId: String!) {
        addUserPatientRelationship(subjectId: $subjectId) {
          email
          password
          id
        }
      }
    `,
      variables: { subjectId: this.subject },
    }).subscribe({
      next: (result) => {
        const { email, password, id } = result.data.addUserPatientRelationship;
        // Use email and password as needed
        this.externalEmail = email;
        this.externalPassword = password;
        this.usersWithAccess = [...this.usersWithAccess, { user: { email, id } }];
        this.setAllConfirmationVisibleFalse();
      },
      error: (error) => {
        // Handle errors
        this.externalEmail = error.message;
        this.externalPassword = null;
        this.setAllConfirmationVisibleFalse();
      },
    }
    );
  }

  updateInfo(): void {
    if (this._clip) {
      this.urlToShare = this.getUrlBase() + 'edit/' + this._clip.id;
      const projectUnlistedAccess = this._clip.project.unlistedAccess;
      this.disablePrivateSharingControl = !projectUnlistedAccess;
      this.allowUnlistedAccess = this._clip.allowUnlistedAccess || projectUnlistedAccess;
      this.displayCode = true;
    }

    if (this.urlToShare) {
      this.embedUrl = this.urlToShare.replace('/edit/', '/embed/');
    }

    if (this.project) {
      this.urlToShare = this.getUrlBase() + 'project/' + this.project.id;
      this.allowUnlistedAccess = this.project.allowUnlistedAccess;
      this.disablePrivateSharingControl = this.publicAllowed.privateSharingDisabled();
    }

    if (this.subject) {
      this.urlToShare = this.getUrlBase() + 'subject/' + this.subject;
      this.disablePrivateSharingControl = this.publicAllowed.privateSharingDisabled();
    }

  }

  tooltip(): string {
    return this.publicAllowed.tooltip();
  }

  tooltipLink(): string {
    if (this.sharingUrls?.length > 0 && this.publicAllowed.privateSharingDisabled()) {
      return "Copy this link into your EHR";
    } else {
      return "Authorized users can access the motion data with this link";
    }
  }

  ngOnInit(): void {
    this.isSharingPatientActive = this.featureFlags.get('enableSubjectSharing') === true && (this.project.isAdmin === true || this.auth.isTesterAuthenticated());
    this.updateInfo();
    if (this.subject)
      this.getLinkedUsers();
  }

  copyLink(input: any): void {
    input.focus();
    input.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

  onClipboardCopy(event: boolean): void {
    if (event === true) {
      this.copyToClipboardMsg = 'Credentials have been copied to clipboard';
      setTimeout(() => {
        this.copyToClipboardMsg = undefined;
      }, 3000);
    }
  }

  public copyToClipboard(): string {
    return `Email: ${this.getExternalEmail()} Password: ${this.getExternalPassword()}`;
  }

  public hideModal() {
    this.externalEmail = null;
    this.externalPassword = null;
    this.modal.hide();
  }

  public getExternalEmail() {
    if (this.externalEmail) {
      return this.replaceDomain(this.externalEmail);
    } else {
      return null;
    }
  }

  public replaceDomain(email: string): string {
    return email.replace(/@moveshelf\.com$/, '');
  }

  public getExternalPassword() {
    return this.externalPassword;
  }

  public deleteUserRelationship(email: string): void {
    const id = this.usersWithAccess.find(user => user.user.email === email).user.id;
    if (id) {
      this.apollo.mutate({
        mutation: deleteUserMutation,
        variables: {
          inputs: {
            id,
          }
        },
      }).subscribe({
        next: (result) => {
          if ((result.data as any).deleteAccountByFirebaseId.deleted) {
            this.usersWithAccess = this.usersWithAccess.filter(user => user.user.email !== email);

            if (this.externalEmail === email) {
              this.externalEmail = null;
              this.externalPassword = null;
            }
            this.deletedUser = 'User credentials have been deleted';
            setTimeout(() => {
              this.deletedUser = undefined;
            }, 3000);
          }
        },
        error: (error) => {
          this.errorMessage = "Something went wrong. Please try again.";
          setTimeout(() => {
            this.errorMessage = undefined;
          }, 3000);
        },
      });
    }
  }

  protected getLinkedUsers(): void {
    this.apollo.query<any>({
      query: getUsersLinkedQuery,
      variables: { id: this.subject },
      fetchPolicy: 'network-only'
    }).subscribe({
      next: (result) => {
        this.usersWithAccess = result.data.node.linkedUsers;
      },
      error: (error) => {
        console.log(error);
      },
    }
    );
  }

  private setAllConfirmationVisibleFalse(): void {
    this.usersWithAccess.forEach(user => this.confirmationVisible[user.user.email] = false);
  }

  protected toggleConfirmationVisible(email: string): void {
    // set all to false except the one we want to toggle
    const current = this.confirmationVisible[email];
    this.setAllConfirmationVisibleFalse();
    this.confirmationVisible[email] = !current;
  }

  public toggleNoSSOCheckbox(): void {
    this.noSSOCheckbox = !this.noSSOCheckbox;
  }
}
