import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebAppRootComponent } from './roots/web-app-root.component';
import { ClipNotAvailableComponent } from './shared/clip-not-available.component';
import { HomeMonitoringComponent } from './shared/homemonitoring/homemonitoring.component';

const appRoutes: Routes = [
  {
    path: 'profile',
    component: WebAppRootComponent,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'dashboard',
    component: WebAppRootComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'project',
    component: WebAppRootComponent,
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'evaluation',
    component: WebAppRootComponent,
    loadChildren: () => import('./evaluation/evaluation.module').then(m => m.EvaluationModule)
  },
  {
    path: 'embed',
    component: WebAppRootComponent,
    loadChildren: () => import('./embed/embed.module').then(m => m.EmbedModule)
  },
  {
    path: 'login',
    component: WebAppRootComponent,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'error',
    component: WebAppRootComponent,
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'mocapvr',
    pathMatch: 'full',
    component: ClipNotAvailableComponent,
    data: {
      hideHeader: true,
      includeLink: true
    }
  },
  {
    path: 'posts',
    pathMatch: 'full',
    redirectTo: '/edit'
  },
  {
    path: 'guestpost',
    pathMatch: 'full',
    redirectTo: '/edit'
  }, {
    path: 'auth',
    component: WebAppRootComponent,
  }, {
    path: 'homemonitoring',
    pathMatch: 'full',
    component: HomeMonitoringComponent,
  } ,{
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }, {
    path: '**',
    redirectTo: '/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    anchorScrolling: "enabled",
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
