import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { EnvironmentService } from 'app/shared/services/environment.service';

interface BasicMetaTags {
  title: string,
  description: string,
  imageUrl: string
}

@Injectable()
export class MetaControlService {

  inited: boolean = false;

  defaultDescription =  'Moveshelf is the first all-in-one application for movement labs. Secure, cloud-based software for movement data from all sources, with interactive reports. Unlock the future of movement analysis with computer vision and machine learning algorithms.';
  defaultTitle = 'Moveshelf';
  defaultImageUrl: string;

  private readonly env;

  constructor(
    private title: Title,
    private meta: Meta,
    private environment: EnvironmentService,
  ) {
    this.env = this.environment.getEnvironment();
    this.defaultImageUrl = this.env.baseHref + 'assets/background.jpg';
  }

  initSharingTags() {

    const site_name = this.meta.getTags(`property='og:site_name'`);
    if (site_name.length > 0 && site_name[0].content == 'Moveshelf')
      this.inited = true;

    if (this.inited)
      return;

    /*
        this.meta.addTags([{ name: 'description', content: 'Experience incredible motions in 3D and in VR, replay, measure and share them with your friends and collaborators'}]);
    */

    //this.meta.updateTag({name: 'description', content: clip.node.description} );

    const description = this.defaultDescription;
    const title = this.defaultTitle;
    const imageUrl = this.defaultImageUrl;

    this.createBasicTags(title, description, imageUrl);

  }

  createBasicTags(title: string, description: string, imageUrl: string) {
    this.meta.addTags([
      { property: 'fb:app_id', content: this.env.fb_appId },
      { property: 'og:site_name', content: 'Moveshelf' },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:image:secure_url', content: imageUrl },
      { property: 'og:image:width', content: '800' },
      { property: 'og:image:height', content: '600' },
      { property: 'og:type', content: 'image' },
      { property: 'twitter:site', content: this.env.twitter_userName },
      { property: 'twitter:description', content: description },
      { property: 'twitter:domain', content: this.env.baseHref },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:image', content: imageUrl },
      { name: 'description', content: description } ]);

      this.inited = true;
  }

  updateTitleDescription(title?: string, description?: string) {

    if (!title) {
      title = this.defaultTitle;
      description = this.defaultDescription;
    }

    this.title.setTitle(title);

    if (description)
      this.meta.updateTag( { name: 'description', content: description }, `name='description'`);
  }

  updateBasicTags(newTags?: BasicMetaTags, isVideo: boolean = false) {

    let description = 'The digital motion library';
    let title = 'Moveshelf';
    let imageUrl = this.env.baseHref + 'assets/preview_xl.jpg';
    let ogType = 'image';
    let cardType = 'summary_large_image';

    if (newTags !== undefined) {
      title = newTags.title;
      description = newTags.description;

      if (newTags.imageUrl)
        imageUrl = newTags.imageUrl;

      if (isVideo) {
        ogType = 'video';
        cardType = 'player';
      }
    }

    this.meta.updateTag( { property: 'og:title', content: title }, `property='og:title'`);
    this.meta.updateTag( { property: 'og:description', content: description }, `property='og:description'`);
    this.meta.updateTag( { property: 'og:image', content: imageUrl }, `property='og:image'`);
    this.meta.updateTag( { property: 'og:image:secure_url', content: imageUrl }, `property='og:image:secure_url'`);
    this.meta.updateTag( { property: 'og:type', content: ogType }, `property='og:type'`);
    this.meta.updateTag( { property: 'twitter:card', content: cardType }, `property='twitter:card'`);
    this.meta.updateTag( { property: 'twitter:description', content: description }, `property='twitter:description'`);
    this.meta.updateTag( { property: 'twitter:image', content: imageUrl }, `property='twitter:image'`);
    this.meta.updateTag( { name: 'description', content: description }, `name='description'`);
  }

  videoShareTags(title, description, previewImageUri, clipId) {
    /*let description = 'The digital motion library';
    let title = 'Moveshelf';
    let previewImageUri = this.env.baseHref + 'assets/preview_xl.jpg';
    let clipId = this.env.staffPicks[0];*/

    this.updateBasicTags({title: title, description: description, imageUrl: previewImageUri}, true);

    this.meta.addTags([
      { property: 'og:video:url', content: this.env.baseHref + 'embed/' + clipId },
      { property: 'og:video:secure_url', content: this.env.baseHref + 'embed/' + clipId },
      { property: 'og:video:width', content: '800' },
      { property: 'og:video:height', content: '600' },
      { property: 'og:video:type', content: "text/html" },
      { name: 'twitter:player', content: this.env.baseHref + 'embed/' + clipId },
      { name: 'twitter:player:width', content: '800' },
      { name: 'twitter:player:height', content: '600' } ]);
  }

  clearVideoTags() {
    this.updateBasicTags();
    this.meta.removeTag(`property='og:video:url'`);
    this.meta.removeTag(`property='og:video:secure_url'`);
    this.meta.removeTag(`property='og:video:width'`);
    this.meta.removeTag(`property='og:video:height'`);
    this.meta.removeTag(`property='og:video:type'`);
    this.meta.removeTag(`name='twitter:player'`);
    this.meta.removeTag(`name='twitter:player:width'`);
    this.meta.removeTag(`name='twitter:player:height'`);
  }

  imageShareTags(title: string, description: string, imageUrl: string, urlToShare: string ) {
    if (!this.inited)
      this.createBasicTags(title, description, imageUrl);
    else
      this.updateBasicTags({title: title, description: description, imageUrl: imageUrl}, false);

      this.meta.updateTag( { property: 'og:url', content: urlToShare }, `property='og:url'`);
      //this.meta.addTags([{ property: 'og:url', content: urlToShare } ]);
  }

  clearImageTags() {
    if (this.inited)
      this.updateBasicTags();

    this.meta.removeTag(`property='og:url'`);
  }
}
