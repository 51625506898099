<div id="videoContainer" style="visibility: hidden;" [class]="playerClass" #containerEl>
  <div class="videoContainer" [ngClass]="{'errorvideo': this.error}">
    <video [id]="videoId" class="noselect" style="width: 100%; height: 100%" type="video/mp4" muted playsinline autoplay
    #videoEl [ngClass]="{'rotate': rotationOptions?.rotate, 'errorvideo': this.error}"
    [ngStyle]="this.getRotationStyle()" poster="assets/spinner_16_9.gif"
    (error)="this.handleVideoLoadingError()"></video>
    <app-video-force-overlay
      [videoName]="overlayConfiguration.videoName"
      [overlayType]="overlayConfiguration.overlayType"
      [overlayWidth]="overlayConfiguration.overlayWidth"
      [overlayHeight]="overlayConfiguration.overlayHeight"
      [originalVideoWidth]="overlayConfiguration.originalVideoWidth"
      [originalVideoHeight]="overlayConfiguration.originalVideoHeight"
      [videoElementHeight]="overlayConfiguration.videoElementHeight"
      [canvasLeftOffset]="overlayConfiguration.canvasLeftOffset"
      [offsetTop]="overlayConfiguration.offsetTop"
      [totalVideoDuration]="overlayConfiguration.totalVideoDuration"
      [currentTrialName]="currentTrialName"
    ></app-video-force-overlay>
    <app-goniometer-overlay
      *ngIf="this.goniometerModeActive"
      (currentAngle)="this.displayGoniometerSelection($event)"
      (hasBodyPose)="this.setBodyPoseBoolean($event)"
      [currentVideo]="this.currentVideo"
      [selectedGonioOption]="this.selectedGonioOption"
      [overlayWidth]="overlayConfiguration.overlayWidth"
      [overlayHeight]="overlayConfiguration.overlayHeight"
      [originalVideoWidth]="overlayConfiguration.originalVideoWidth"
      [originalVideoHeight]="overlayConfiguration.originalVideoHeight"
      [videoElementHeight]="overlayConfiguration.videoElementHeight"
      [canvasLeftOffset]="overlayConfiguration.canvasLeftOffset"
      [offsetTop]="overlayConfiguration.offsetTop"
      [enableAutomaticGonio]="this.canEnableAutomaticGoniometer()"
    ></app-goniometer-overlay>
    <app-saga-ruler-overlay
      *ngIf="this.rulerModeActive"
      (rulerAnglesEvent)="this.displaySagaRulerAngles($event)"
      [overlayWidth]="overlayConfiguration.overlayWidth"
      [overlayHeight]="overlayConfiguration.overlayHeight"
      [originalVideoWidth]="overlayConfiguration.originalVideoWidth"
      [originalVideoHeight]="overlayConfiguration.originalVideoHeight"
      [videoElementHeight]="overlayConfiguration.videoElementHeight"
      [canvasLeftOffset]="overlayConfiguration.canvasLeftOffset"
      [offsetTop]="overlayConfiguration.offsetTop"
    ></app-saga-ruler-overlay>
  </div>
  <div id="clickerContainer" *ngIf="clickers" [ngClass]="{'always-visible-icons': isFullscreen}">
    <div class="leftRightSelecters" *ngIf="leftRightService.enabled"
      [class.showSelected]="leftRightService.isTileSelected(currentVideo)">
      <i class="clickable-info fa fa-caret-square-o-left"
        [class.text-success]="leftRightService.isTileSelectedInColumn(currentVideo, leftRightService.getLeftTiles())"
        (click)="leftRightService.selectLeft(currentVideo)" tooltip="Show on the left in split screen"></i>
      <i class="clickable-info fa fa-caret-square-o-right"
        [class.text-success]="leftRightService.isTileSelectedInColumn(currentVideo, leftRightService.getRightTiles())"
        (click)="leftRightService.selectRight(currentVideo)" tooltip="Show on the right in split screen">
      </i>
      <app-left-right-maximize></app-left-right-maximize>
    </div>
    <i *ngIf="!this.error" class="resizer clickable-info fa" [class.fa-compress]="isFullscreen"
      [class.fa-expand]="!isFullscreen" (click)="toggleFullscreen()">
    </i>
  </div>
  <div class="video-controls">
    <app-trial-dropdown *ngIf="showTrialDropdown()" [tile]="tile" [clipList]="clipList" (selectedVideoDropdownElement)="this.selectVideo($event)" (keyPress)="this.keyPressing($event)" class="trial-dropdown"></app-trial-dropdown>
    <i *ngIf="this.canCycleVideos() || this.dropdownCycleVideos()" class="next-video-button fa fa-fast-forward clickable-info" (click)="showDropdown !== true ? selectNextVideo() : selectNextVideoByClipId()"></i>
    <i *ngIf="this.canEnableGoniometer() || this.canEnableRuler()" class="clickable-info goniometer-mode" (click)="this.toggleGoniometer(!this.overlayEnabled)">
      <fa-icon [ngClass]="{'enabled': this.overlayEnabled}" [icon]="['fas', 'ruler-combined']"></fa-icon>
    </i>
    <select *ngIf="this.dropdownVisible()" class="dropdown-small" (change)="changeGoniometerDropdown()" [(ngModel)]="goniometerDropdownValue" (keydown)="disableKeyboardEvent($event)">
      <option [ngValue]="true">Goniometer</option>
      <option [ngValue]="false">SAGA Ruler</option>
    </select>
    <app-goniometer-dropdown class="d-inline-flex ml-2" *ngIf="this.goniometerModeActive && this.videoHasBodyPose" (currentSelection)="this.currentSelectedGoniometerOption($event)"></app-goniometer-dropdown>
  </div>
  <div class="gonio-angle-display" *ngIf="this.currentGoniometerAngle">Current angle: {{ this.currentGoniometerAngle.degree }}° ({{ this.currentGoniometerAngle.complement }}°)</div>
  <div class="gonio-angle-display ruler-display" *ngIf="this.rulerModeActive && this.currentRulerAngles">
    Trunk tilt: {{ this.currentRulerAngles.trunk_tilt }}° {{ this.currentRulerAngles.trunk_tilt >= 0 ? 'Ant' : 'Post' }}<br>
    Pelvic tilt: {{ this.currentRulerAngles.pelvic_tilt }}° {{ this.currentRulerAngles.pelvic_tilt >= 0 ? 'Ant' : 'Post' }}<br>
    Hip angle: {{ this.currentRulerAngles.hip_angle }}° {{ this.currentRulerAngles.hip_angle >= 0 ? 'Flex' : 'Ext' }}<br>
    Knee angle: {{ this.currentRulerAngles.knee_angle }}° {{ this.currentRulerAngles.knee_angle >= 0 ? 'Flex' : 'Ext' }}<br>
    Ankle angle: {{ this.currentRulerAngles.ankle_angle }}° {{ this.currentRulerAngles.ankle_angle >= 0 ? 'Dors' : 'Plntr' }}<br>
    Tibia inclination: {{ this.currentRulerAngles.tibia_inclination }}° {{ this.currentRulerAngles.ankle_angle >= 0 ? 'Fwd' : 'Bwd' }}
  </div>
  <div class="errorbox" *ngIf="this.error">Error while loading video.</div>
</div>
