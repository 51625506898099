<canvas
  #goniometer_canvas
  class="overlay"
  (mousedown)="this.selectPoint()"
  (mouseup)="this.endSelection()"
  (mousemove)="this.setCurrentMousePosition($event)"
  [width]="overlayWidth"
  [height]="overlayHeight"
  [style.top.px]="canvasTopPosition"
  [style.marginLeft.px]="canvasLeftOffset"
></canvas>
