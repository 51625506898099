import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChartReadyEvent, ChartSelectEvent } from 'ng2-google-charts';

const MAX_NO_POINTS = 1500;

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {

  @Input() set input(values) {
    this.setLayout('base');
    this.setChartData(values);
    this.visible = true;
  }

  @ViewChild('cchart', { static: true }) cchart;
  @ViewChild('container', { static: true }) container: ElementRef;

  constructor() {

  }

  readonly green: string = '#55ff4a'; // base color (secondary)
  readonly cyan: string = '#00cdc9'; // gradient point
  readonly blue: string = '#4396ff'; // gradient point
  readonly red: string = '#ff0000'; // gradient point
  readonly gray: string = '#aaaaaa'; // gradient point
  readonly purple: string = '#5850ff'; // base color (primary

  chartData: any;

  data: {
    dataUrl?: string,
    skip?: number,
    labels?: {
      title?: string,
      hAxis?: string,
      vAxis?: string,
      time?: string
    },
    colors?: string[]
  };

  values: any[] = []; //Vector4[] = [];
  dataReady: boolean = false;
  annotationRowIndex: number;
  isFullscreen: boolean = false;
  baseLayout: string = 'thumb-top';
  visible: boolean = false;
  skipFactor = 1;
  chartReady: boolean = false;

  playerClass: string = this.baseLayout;
  maxCount: number = 22;
  hasStd: boolean = false;

  pictograms = [ {x: 0.4, y: -1.55, img: 'assets/cadence.png', el: undefined, width: 120 },
                {x: 0.4, y: 1.3, img: 'assets/stride_length.png', el: undefined, width: 120 },
                {x: 1.7, y: 0, img: 'assets/gait_speed.png', el: undefined, width: 80 } ];

  ngOnInit() {

  }

  public setChartData(data: any, create3d: boolean = false) {
      this.createChart(data);
      this.readValues(data);
  }

  readValues(data: any) {
    this.prepareTableColumns(data);
    this.fillChartData(data.values);
  }

  prepareTableColumns(data: any) {
    let count = 1;
    console.log(data.labels.title);
    for (const label of data.labels.title) {

      this.chartData.dataTable.cols.push( {
        id: count.toString(),
        label: label + '-x',
        type: "number",
        role: "domain"
      });

      this.hasStd = data.labels.std ? true : false;

      this.chartData.dataTable.cols.push( {
        id: count.toString(),
        label: label,
        type: "number"
      });
      count++;
      /*if(this.hasStd)
      {
        this.chartData.dataTable.cols.push( {
          id: count.toString(),
          label: label + '+',
          type: "number",
          role: 'interval'
          });
          count++;
        this.chartData.dataTable.cols.push( {
          id: count.toString(),
          label: label + '-',
          type: "number",
          role: 'interval'
          });
      }*/
      this.chartData.dataTable.cols.push(
        {
          type: 'string',
          role: 'tooltip'
        });
    }

    /*
      let count = 1;
      for(let v of values) {
      this.chartData.dataTable.cols.push( {
        id: count.toString(),
        label: v.label,
        type: "number"
      });
      if(count++ >= this.maxCount)
        break;
    }*/
  }

  public createChart(data: any) {

    this.data = { };

    for (const p in data) {
      this.data[p] = data[p];
    }

    let legend = 'none' as any;
    let title = this.data.labels.title[0];
    if ( this.data.labels.title.length > 1) {
      legend = {
        'position': 'top',
        'textStyle': {
          'color': '#fff'
        }
      };
      title = 'Radar comparison';
    }

    this.chartData =  {
      chartType: 'AreaChart',
      dataTable: {
        cols: [],
        rows: []
    },
    options: {
    'title': title,
    'intervals': { 'style': 'bars', 'lineWidth': '2', 'barWidth': '0.5' },
    'titleTextStyle': {
      'color': '#fff'
    },
    'chartArea': {
      'width': '70%',
      'bottom': '1%',
      'height': '80%',
    },
    'backgroundColor': {
      fill: 'transparent'
    },
    'legend': legend,
    'hAxis': {
      'textStyle': {
      'color': '#fff'},
      //'title': this.data.labels.hAxis,
      'slantedTextAngle': '60',
      'gridlines': {
        'color': 'transparent'
      },
      'baselineColor': '#aaa',
      'titleTextStyle': {
        'color': '#fff'
      }
    },
    'vAxis': {
      'gridlines': {
        'color': 'transparent'
      },
      'baselineColor': '#aaa',
      'textStyle': {
        'color': '#fff'
      },
      'title': this.data.labels.vAxis,
      'titleTextStyle': {
        'color': '#fff'
      }
    },
    'colors': this.data.colors ? this.data.colors : [ this.cyan, this.blue, this.green, this.purple ]
    },
    'annotations': [{
      'style': 'line',
      'isHtml': 'true',
      'textStyle': {
        'fontSize': 30,
        'color': "#000",
        'bold': true
        }
      }],
    };

    const limit = 1.5;
    this.chartData.options.hAxis.viewWindow = {
      'min': -limit,
      'max': limit
    };
    this.chartData.options.vAxis.viewWindow = {
      'min': -limit,
      'max': limit
    };

    this.refresh();
  }

  public refresh() {

    if (this.visible && this.chartReady)
      this.cchart.draw();
  }

  select(event: ChartSelectEvent) {
    if (event.message !== "select")
      return;
  }

  resizeChart() {
    this.chartData.options.height = '' + this.container.nativeElement.clientHeight*0.9 + '';
    if (this.visible)
      this.cchart.draw();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.chartData && this.chartData.options)
      this.resizeChart();
  }


  toggleFullscreen() {
    if (!this.isFullscreen) {
      this.playerClass = 'fullscreen';
      this.isFullscreen = true;
    } else {
      this.playerClass = this.baseLayout;
      this.isFullscreen = false;
    }
  }

  setLayout(layout: string) {
    this.baseLayout = layout;
    this.playerClass = this.baseLayout;
  }

  public isVisible() {
    return this.visible;
  }

  public toggleVisibility(enable?: boolean) {

    this.visible = enable != undefined ? enable : !this.visible;
    if (!this.chartReady)
      return;

    if (this.visible) {
      this.resizeChart();
      this.container.nativeElement.style.visibility = '';
    } else {
      this.container.nativeElement.style.visibility = 'hidden';
    }

  }

  public isChartAvailable() {
    return this.dataReady;
  }

  getXYperLabel(value: any, label: string) {
    const rot = this.getRotPerLabel(label);
    const x = Math.cos(rot)*value;
    const y = Math.sin(rot)*value;
    return { x: x, y: y };
  }

  getRotPerLabel(label: string) {
    const pi = 3.1428;
    if ( label.toLocaleLowerCase().indexOf('cadence') != -1)
      return - pi/2;
    if ( label.toLocaleLowerCase().indexOf('gait speed') != -1)
      return 0;
    if ( label.toLocaleLowerCase().indexOf('stride length') != -1)
      return pi/2;
   }

  private fillChartData(tracks: any) {

    const count = 0;
    const labels = [];
    for (const track of tracks) {
      for (const value of track) {
        let found = false;
        const newLabel = value.label;
        for (const label of labels) {
          if ( label.label == value.label) {
            found = true;
            label.values.push(value.values);
            label.unit = value.unit; //fix me: multiple chart could have different units
            break;
          }
        }
        if (!found)
          labels.push({ label: newLabel, unit: value.unit, values: [ value.values ] });
      }
    }

    const toBeNormalized = [ 'Cadans', 'Cadence', 'Foot Off', 'Foot Contact'];
    const normFactor = 100;
    for (const value of labels) {

      const dataSample = [];

      let i = 0;
      for (const v of value.values) {
        let data = v.mean;
        let std;
        if (this.hasStd)
          std = v.std;

        for (const t of toBeNormalized) {
          if (value.label.indexOf(t) != -1) {
            data = data / normFactor;
            if (this.hasStd)
              std = std / normFactor;

            break;
          }
        }

        const newValue = this.getXYperLabel( data, value.label);
        dataSample.push({ v: newValue.x });
        dataSample.push({ v: newValue.y });
        /*if(this.hasStd) {
          dataSample.push({ v:  data + std });
          dataSample.push({ v:  data - std });
        }*/
        dataSample.push({ v: this.data.labels.title[i++] + '\n' + value.label +': ' + parseFloat(data).toFixed(2) + ' [' + value.unit + ']'});
      }
      this.chartData.dataTable.rows.push({c: dataSample});
    }

    this.dataReady = true;
    this.refresh();

  }

  firstTime = undefined;

  public ready(event: ChartReadyEvent) {
    if (!this.chartReady) {
      this.chartReady = true;
      this.toggleVisibility(this.visible);
    }

    {
      const layout = this.cchart.cli;

      if (!layout)
        return;

      /*if(this.firstTime)
        return;
      let time = -1.4
      setInterval( ()=> {
      time += 0.03;
      if(time > 1.4)
        time = -1.4;
        */

      const container = document.getElementById('my-chart-id');
      container.style.position = 'relative';

      const bound = container.getBoundingClientRect();

      if (!this.firstTime)
        this.firstTime = bound;

      for (const p of this.pictograms) {
        const xPos = layout.getXLocation(p.x);
        const yPos = layout.getYLocation(p.y);

        if (!p.el) {
          p.el = container.appendChild(document.createElement('img'));
          p.el.src = p.img;
          p.el.className = 'pictograms';
        }

        p.el.style.top = (yPos-p.width/2)*bound.height/this.firstTime.height + 'px';//'0px';//(yPos - 8)*100/(bound.top + bound.height) + '%';
        p.el.style.left = (xPos-p.width/2)*bound.width/this.firstTime.width + 'px';//(xPos*100/bound.width) + '%';
        p.el.style.position = 'absolute';
        p.el.style.width = p.width + 'px';
        p.el.style.opacity = '90%';
      }
    //}, 10);
    }
  }

}
