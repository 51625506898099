import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataZoomComponentOption, ECharts, EChartsOption, ElementEvent } from 'echarts';
import { distinctUntilChanged, filter, Subject, Subscription } from 'rxjs';
import { GlobalPlaybackControlService } from '../../core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from '../../core/playback-controls/playback-control.service';
import { PlaybackMode } from '../../core/playback-mode.enum';
import { DataContext } from '../../shared/data-helper';
import { LeftRightService } from '../left-right/left-right.service';
import { TrialChartsService } from '../multi-chart/trial-charts.service';
import { ChartToggleService, LeftRightToggleStatus } from '../services/chart-toggle/chart-toggle.service';
import { ColorService } from '../services/color-service/color-service.service';
import { ConditionAverageToggleService } from '../services/condition-average-toggle/condition-average-toggle.service';
import { DataTrack, SwingTransition } from './chart.types';
import { CycleProperties, EChartsHighlightService } from './echarts-highlight.service';
import { EChartsLegendService } from './echarts-legend.service';
import { ChartResolution, EChartsService } from './echarts.service';
import { Playhead } from './playhead/playhead.component';
import { ReferenceBar } from './reference-canvas/reference-canvas.component';


@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  providers: [EChartsService, EChartsLegendService]
})
export class ChartsComponent implements OnInit, OnDestroy {
  @Input() set input(values: DataTrack) {
    this.setLayout('base');
    this.initZoom(values?.hasCycles === true);
    this.setChartData(values);
  }
  @Input() set playbackControl(value: PlaybackControlService) {
    this.setPlaybackControl(value);
  }
  @Input() splitView: boolean = false;
  @Input() playhead = false;
  @Input() clickers = true;
  @Input() highlightActiveCycle = false;
  @Input() selectedClip: string;
  @Input() zoomChartOnVideoPlayhead: boolean = false;
  @Input() set zoomRightChart(input: boolean) {
    this.leftRightService.zoomRightChart = input;
    this.setChartData(this.chartsInput);
  }
  @Input() multiChartSplitScreen = false;

  private echartsInstance: ECharts;
  public chartsInput: DataTrack;
  private resizeEventPending = 0;
  private dataReady = false;
  private baseLayout = 'thumb-top';
  private subs: Subscription[] = [];
  private _playbackControl: PlaybackControlService;
  private _playbackControlSub: Subscription;
  private updateInstance: Subject<unknown> = new Subject();
  private currentCycles: Record<string, CycleProperties[]>;
  private currentCyclesLeft: Record<string, CycleProperties[]>;
  private skipHighlightHandling = false;
  private skipDownplayHandling = false;

  public echartOption: EChartsOption;
  public playheads: Playhead[] = [];
  public referenceBars: ReferenceBar[] = [];
  public playheadTop = 0;
  public playheadHeight = 200;
  public playheadCanvasWidth;
  public isFullscreen = false;
  public playerClass: string = this.baseLayout;

  public lastPlaybackTime: number = -1;
  public formGroup: FormGroup;
  private minReferencePercentageX: number = 0;
  private maxReferencePercentageX: number = 100;
  private xZoomStart: number = -1;
  private resetInitialZoom = false;
  private lastPlayheadInGrid = false;
  // // used while moving the playhead to don't iterate multiple time on the same cycle condition
  private lastTimeCurrentCycle: Record<number, string> = {};
  private lastTimeCurrentCycleLeft: Record<number, string> = {};

  private playHeadColors = {
    single: '#00cdc9', // Single playhead color
    cycle: {
      // Colors used when displaying double playheads for gait cycles
      left: this.colorService.playheadLeft,
      right: this.colorService.playheadRight,
      noContext: this.colorService.blue,
    },
    reference: this.colorService.gray,
  };

  constructor(
    private playbackGlobal: GlobalPlaybackControlService,
    public echartsService: EChartsService,
    public echartsLegendService: EChartsLegendService,
    private echartsHighlightService: EChartsHighlightService,
    public leftRightService: LeftRightService,
    private formBuilder: FormBuilder,
    private colorService: ColorService,
    protected readonly chartToggleService: ChartToggleService,
    protected readonly averageToggleService: ConditionAverageToggleService,
    private trialChartService: TrialChartsService,
  ) {
  }

  ngOnInit(): void {
    // By default, enable charts animations
    this.echartsService.animationsEnabled = true;
    this.echartsService.applyChartsOpacityReduction = true;
    this.subs.push(this.playbackGlobal.playbackControl.subscribe((service) => {
      this.setPlaybackControl(service);
    }));
    this.initZoom(this.chartsInput?.hasCycles === true);
    this.setChartData(this.chartsInput);
    this.subs.push(this.chartToggleService.leftRightToggleStatus.subscribe((status: LeftRightToggleStatus) => {
      if (status) {
        if (this.echartsService.isEmgConsistency) {
          if (this.echartsService.disableLeft !== !status.left) {
            this.echartsLegendService.resetEmgConsistencyLeft = true;
          } else if (this.echartsService.disableRight !== !status.right) {
            this.echartsLegendService.resetEmgConsistencyRight = true;
          }
        }
        this.echartsService.disableLeft = !status.left;
        this.echartsService.disableRight = !status.right;
        this.setChartData(this.chartsInput);
        this.updatePlayhead(-1);
      }
    }));

    this.subs.push(this.chartToggleService.trialToggleStatus.subscribe(() => {
      this.setChartData(this.chartsInput);
    }));

    this.subs.push(this.averageToggleService.averageToggleStatus.subscribe((status: boolean) => {
      if (this.trialChartService.allowConditionAvgToggle) {
        this.trialChartService.applyAveragePerCondition = status;
        this.trialChartService.showAveragesForCycles = status;
      }
      this.setChartData(this.chartsInput);
    }));

    this.formGroup = this.formBuilder.group({
      Raw: [this.echartsLegendService.showRawChartLines],
      Rms: [this.echartsLegendService.showRmsChartLines],
    });

    this.subs.push(this.formGroup.valueChanges.subscribe(() => {
      this.toggleRawAndRmsChartLines(this.formGroup.get('Raw').value, this.formGroup.get('Rms').value);
    }));
    this.initCurrentCycleObject();

    // this handles the opacity reduction of the charts when the current cycle is highlighted
    this.subs.push(this.echartsHighlightService.setInactiveLinesWhenHighlighting.pipe(distinctUntilChanged()).subscribe((isCurrentCycleHighlighted) => {
      if (this.echartsInstance?.getOption()?.series && this.echartsService.enableCycleHighlighting) {
        const seriesOptions = this.echartsInstance.getOption().series;
        if (isCurrentCycleHighlighted) {
          for (const serieOption of seriesOptions as Array<any>) {
            serieOption.lineStyle = { width: 0.5 };
          }
        } else {
          for (const serieOption of seriesOptions as Array<any>) {
            serieOption.lineStyle = { width: this.echartsService.inactiveLineWidth() };
          }
        }
        this.echartsInstance.setOption({ series: seriesOptions });
      }
    }));
  }

  ngOnDestroy(): void {
    this._playbackControlSub?.unsubscribe();
    for (const s of this.subs) {
      s.unsubscribe();
    }
  }

  private initZoom(hasCycles: boolean): void {
    this.echartsService.resetInitialZoom();
    // is needed to set again the chart data after loading this variable
    if (this.zoomChartOnVideoPlayhead && !hasCycles) {
      // flag is checked also here to make chart switching work
      this.echartsService.setResolution(ChartResolution.HIGHEST);
      // If we are in a high resolution mode, disable animations. @see 2182
      this.echartsService.animationsEnabled = false;
      // if video started later then sensor data, move timebar to the offset and zoom the data on initial load
      const tOffsetAbs = Math.abs(this.trialChartService.sensorDataOffsetInVideo) > 0 ? Math.abs(this.trialChartService.sensorDataOffsetInVideo) : 0;
      if (this._playbackControl) {
        const xZoomStart = tOffsetAbs - 0.1;
        let xZoomEnd = tOffsetAbs + this._playbackControl.timebarDuration + 0.1;
        if (this.trialChartService.sensorDataOffsetInVideo < 0) {
          if (this._playbackControl.playbackTime.value <= 0) {
            this._playbackControl.jumpToTimeBasedOnTimeJump(tOffsetAbs);
          }
          if (tOffsetAbs + this._playbackControl.videoDuration + 0.1 <= this._playbackControl.timebarDuration) {
            xZoomEnd = tOffsetAbs + this._playbackControl.videoDuration + 0.1;
          }
        }
        this.echartsService.initialZoom.xZoomStartValue = xZoomStart;
        this.echartsService.initialZoom.xZoomEndValue = xZoomEnd;
      }
    }
  }

  public setChartData(data: DataTrack): void {
    this.chartsInput = data;
    this.echartsService.enableCycleHighlighting = this.chartsInput.hasCycles === true && (this.highlightActiveCycle || this.isFullscreen);
    this.echartsLegendService.hasGcdCycles = data?.hasGcdCycles === true;
    this.echartsLegendService.isFullscreen = this.isFullscreen;
    this.echartsLegendService.splitView = this.splitView;
    this.echartsLegendService.isMultiChartSplitScreen = this.multiChartSplitScreen;

    this.echartOption = this.echartsService.createChart(data);
    this.echartsService.setFontSizes(this.isFullscreen, this.splitView);
    this.echartsLegendService.handleLegendLineSelection(this.echartOption, undefined, undefined, this.echartsService.isEmgConsistency, this.leftRightService.zoomRightChart, !this.echartsService.disableLeft, !this.echartsService.disableRight, this.trialChartService.mergeLegendLabelsForCycles, this.echartsService.getChartMode(), this.getHiddenTrialPaths());
    this.dataReady = true;
    this.xZoomStart = -1;
    this.resetInitialZoom = true;
  }

  private initCurrentCycleObject(): void {
    this.currentCycles = {};
    this.currentCyclesLeft = {};
    if (this.trialChartService.getChartMode() == 'trial') {
      this.currentCycles['default'] = [];
      this.currentCyclesLeft['default'] = [];
    } else {
      for (const trialName of this.trialChartService.trialNamesReport) {
        this.currentCycles[trialName] = [];
        this.currentCyclesLeft[trialName] = [];
      }
    }
  }

  jumpToTime(params: ElementEvent, useCycle?: string): void {
    if (this._playbackControl) {
      const pointInPixel = [params.offsetX, params.offsetY];

      // Ignore clicks outside the grid, the time basis does not make sense for them.
      const withinGrid = this.echartsInstance.containPixel('grid', pointInPixel);
      if (!withinGrid) {
        return;
      }

      if (this._playbackControl.mode.value == PlaybackMode.Playing) {
        this._playbackControl.pause();
      }

      const pointInGrid = this.echartsInstance.convertFromPixel('grid', pointInPixel);
      const newTime = this.trialChartService.findTimeInChart(this.chartsInput, pointInGrid[0], this.lastPlaybackTime, this.echartsService.disableLeft, this.echartsService.disableRight, useCycle);
      if (newTime) {
        if (this._playbackControl.hasVideo()) {
          this._playbackControl.jumpToTimeBasedOnTimeJump(Math.min(Math.max(0,newTime),this._playbackControl.timebarDuration));
        } else {
          this._playbackControl.jumpToTime(Math.min(Math.max(0,newTime),this._playbackControl.timebarDuration));
        }
      }
    }
  }

  resizeChart(): void {
    this.echartsInstance?.resize();
  }

  // handles click on a specific line chart. Would not work if there are averages or reference lines
  public handleLineClick(event: any): void {
    if (this.chartsInput.hasCycles) {
      if (event.seriesType === 'line' && event.seriesName && !this.trialChartService.isAvgOrReference(event.seriesName)) {
        const currentCycle = event.seriesName;
        this.jumpToTime(event.event, currentCycle);
      }
    }
  }

  public areSeriesAlreadyHighlighted(seriesToHighlight: string[], currentCycles: CycleProperties[]): boolean {
    if (seriesToHighlight.length === currentCycles.length) {
      for (const series of seriesToHighlight) {
        if (!currentCycles.find(cycle => this.echartOption.series[cycle.seriesId].name === series)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  public setEchartsInstance(e: ECharts): void {
    this.echartsInstance = e;

    this.echartsInstance.on('finished', () => {
      this.updateInstance.next(e);
      if (this.lastPlaybackTime === -1) {
        // Called until we have a proper playbackTime to make sure the swingTransitions are drawn when we don't have a playbar.
        this.updatePlayhead(-1);
      }
      this.subscribeToReferenceBars();
    });
    const zr = this.echartsInstance.getZr();
    zr.on('click', params => {
      this.jumpToTime(params);
    });

    zr.on('mousewheel', params => {
      // Lock the zoom when scrolling without ctrl or shift to enable default scrolling and switch it off afterwards
      let zoomChanged = false;
      let zoomLocked = false;
      const curOptions = this.echartsInstance.getOption();
      const zoomOptions = curOptions.dataZoom as DataZoomComponentOption[];
      if (params.event && zoomOptions.length > 0) {
        if (params.event.ctrlKey === false && params.event.altKey === false) {
          for (const zoomOption of zoomOptions) {
            if (zoomOption.zoomLock === false) {
              zoomChanged = true;
              zoomLocked = true;
              zoomOption.zoomLock = true;
            }
          }
        } else {
          for (const zoomOption of zoomOptions) {
            if (zoomOption.zoomLock === true) {
              zoomChanged = true;
              zoomOption.zoomLock = false;
            }
          }
        }
        if (zoomChanged) {
          this.echartsInstance.setOption(curOptions);
          if (zoomLocked) {
            // make sure to switch the lock off by default
            setTimeout(() => {
              const curOptions = this.echartsInstance.getOption();
              const zoomOptions = curOptions.dataZoom as DataZoomComponentOption[];
              let zoomChanged = false;
              for (const zoomOption of zoomOptions) {
                if (zoomOption.zoomLock === true) {
                  zoomChanged = true;
                  zoomOption.zoomLock = false;
                }
              }
              if (zoomChanged) {
                this.echartsInstance.setOption(curOptions);
              }
            }, 200);
          }
        }
      }
    });

    // trigger event on downplay to highlight current cycle after highlight/downplay due to hovering.
    this.echartsInstance.on('downplay', (params) => {
      if (this.skipDownplayHandling === true) {
        this.skipDownplayHandling = false;
      } else {
        let seriesToDownplay = this.echartsLegendService.getSeriesToHandleForHighlighting(this.echartOption, params['batch'], params['name'], this.echartsService.isEmgConsistency, this.trialChartService.mergeLegendLabelsForCycles, this.echartsService.getChartMode());
        for (const trialName of Object.keys(this.currentCycles)) {
          this.echartsHighlightService.highlightCurrentCycle(this.echartOption, this.echartsInstance, this.currentCycles[trialName], this.currentCyclesLeft[trialName]);
          if (this.currentCycles[trialName] && this.currentCycles[trialName].length > 0) {
            for (const series of seriesToDownplay) {
              for (const cycle of this.currentCycles[trialName]) {
                if (this.echartOption.series[cycle.seriesId].name === series) {
                  seriesToDownplay = seriesToDownplay.filter(obj => obj !== series);
                }
              }
            }
          }
          if (this.currentCyclesLeft[trialName] && this.currentCyclesLeft[trialName].length > 0) {
            for (const series of seriesToDownplay) {
              for (const cycle of this.currentCyclesLeft[trialName]) {
                if (this.echartOption.series[cycle.seriesId].name === series) {
                  seriesToDownplay = seriesToDownplay.filter(obj => obj !== series);
                }
              }
            }
          }
        }

        if (seriesToDownplay.length > 0) {
          this.skipDownplayHandling = true;
          this.echartsInstance.dispatchAction({
            type: 'downplay',
            seriesName: seriesToDownplay,
          });
        }
      }
    });

    this.echartsInstance.on('mouseout', (params) => {
      for (const trialName of Object.keys(this.currentCycles)) {
        this.echartsHighlightService.highlightCurrentCycle(this.echartOption, this.echartsInstance, this.currentCycles[trialName], this.currentCyclesLeft[trialName]);
      }
      if (params.componentSubType == "line" && this.echartsService.currentHoveredSeries === params.seriesName) {
        this.echartsService.currentHoveredSeries = undefined;
        this.echartsHighlightService.setInactiveLinesWhenHighlighting.next(false);
      }
    });

    this.echartsInstance.on('mouseover', (params) => {
      if (params.componentSubType == "line") {
        this.echartsService.currentHoveredSeries = params.seriesName;
        this.echartsHighlightService.setInactiveLinesWhenHighlighting.next(true);
      }
    });

    this.echartsInstance.on('highlight', (params) => {
      if (this.skipHighlightHandling === true) {
        this.skipHighlightHandling = false;
      } else {
        let doHighlight = true;
        const seriesToHighlight = this.echartsLegendService.getSeriesToHandleForHighlighting(this.echartOption, params['batch'], params['name'], this.echartsService.isEmgConsistency, this.trialChartService.mergeLegendLabelsForCycles, this.echartsService.getChartMode());
        if (seriesToHighlight.length > 0) {
          for (const trialName of Object.keys(this.currentCycles)) {
            if (this.currentCycles[trialName] && this.currentCycles[trialName].length > 0 && this.areSeriesAlreadyHighlighted(seriesToHighlight, this.currentCycles[trialName])) {
              doHighlight = false;
            }
            if (this.currentCyclesLeft[trialName] && this.currentCycles[trialName].length > 0 && this.areSeriesAlreadyHighlighted(seriesToHighlight, this.currentCyclesLeft[trialName])) {
              doHighlight = false;
            }
          }

          if (doHighlight) {
            this.skipHighlightHandling = true;
            this.echartsInstance.dispatchAction({
              type: 'highlight',
              seriesName: seriesToHighlight,
            });
          }
        }
      }
    });
    // Show/hide the legend only trigger legendselectchanged event
    this.echartsInstance.on('legendselectchanged', (params) => {
      if (params['selected']) {
        if (this.echartsService.isScaleYToFit) {
          this.setChartData(this.chartsInput);
        }
        this.echartsLegendService.handleLegendLineSelection(this.echartOption, this.echartsInstance, params['selected'], this.echartsService.isEmgConsistency, this.leftRightService.zoomRightChart, !this.echartsService.disableLeft, !this.echartsService.disableRight, this.trialChartService.mergeLegendLabelsForCycles, this.echartsService.getChartMode());
      }
    });

    // multiply chart resolution if zooming on the chart
    this.echartsInstance.on('datazoom', (params) => {
      if (this.echartsService.getMaxPoints() < ChartResolution.HIGH) {
        this.echartsService.setResolution(ChartResolution.HIGH);
        this.echartsService.resetInitialZoom();
        this.setChartData(this.chartsInput);
      }
      const zoomOptions = this.echartsInstance.getOption().dataZoom as DataZoomComponentOption[];
      this.setXAxisStatus(zoomOptions);
    });

  }

  private updatePlayhead(playbackTime: number) {
    if (!this.echartsInstance || !this.playhead) {
      return;
    }

    if (this.leftRightService.isMaximized && this.splitView !== true) {
      return;
    }

    const grid = this.echartsInstance.getOption().grid[0];
    this.playheadTop = grid.top;

    const instanceHeight = this.echartsInstance.getHeight();
    this.playheadHeight = instanceHeight - grid.bottom - grid.top;
    const instanceWidth = this.echartsInstance.getWidth();
    this.playheadCanvasWidth = instanceWidth;

    const timeChanged = this.lastPlaybackTime !== playbackTime;
    this.lastPlaybackTime = playbackTime;

    let timeInChart = playbackTime;
    let timeInChartLeftLeg;
    this.playheads = [];
    let color = this.playHeadColors.single;
    const chartLabels = Object.keys(this.echartsService.labelsToShow);
    let hasCycles = false;
    let hasCyclesLeft = false;
    let hasCyclesRight = false;
    const playheadWithContext = (this.echartsService.mergedTrack === true && (chartLabels.some(item => item.includes('(right)')) === true || chartLabels.some(item => item.includes('(left)')) === true)) ? true : false;
    if (playheadWithContext === true) {
      // we have two playheads
      timeInChartLeftLeg = playbackTime;
    }
    if (this.echartsService['hasCycles']) {
      const chartMode = this.trialChartService.getChartMode();
      timeInChart = -1;
      timeInChartLeftLeg = -1;
      const clipStrings = this.splitView && this.leftRightService.trialNamesForVideoSync.length > 0  ? this.leftRightService.trialNamesForVideoSync : [this.selectedClip];
      hasCycles = true;
      color = this.playHeadColors.cycle.noContext;
      if (playheadWithContext) {
        // we have two playheads
        hasCyclesRight = chartLabels.some(item => item.includes('(right)')) === true;
        hasCyclesLeft = chartLabels.some(item => item.includes('(left)')) === true;
      }
      let allTrialNamesRemaining: string[] = JSON.parse(JSON.stringify(this.trialChartService.trialNamesReport));
      for (const clipString of clipStrings) {
        const curCycleIndicator = chartMode === 'trial' ? 'default' : clipString;
        if (curCycleIndicator === undefined) {
          continue;
        }
        const isSelectedClip = chartMode === 'trial' ? true : this.selectedClip !== undefined && clipString === this.selectedClip;
        if (chartMode === 'report') {
          allTrialNamesRemaining = allTrialNamesRemaining.filter(item => item !== curCycleIndicator);
        }
        if (playheadWithContext) {
          color = this.playHeadColors.cycle.right;
          const curCycle = this.echartsService.convertTimeToCycle(playbackTime, DataContext.rightSide, clipString);
          if (isSelectedClip) {
            timeInChart = curCycle['perc'];
          }
          // avoid running the same logic again if the current cycle hasn't changed and the percentages are not opposite
          if (timeChanged && curCycle['label'] !== this.lastTimeCurrentCycle['label'] || curCycle['perc'] * this.lastTimeCurrentCycle['perc'] < 0) {
            this.lastTimeCurrentCycle = curCycle;
            if (this.echartsService.enableCycleHighlighting && curCycle['perc'] >= 0 && this.currentCycles[curCycleIndicator] && !this.currentCycles[curCycleIndicator].find(x => x.label == curCycle['label'])) {
              this.echartsHighlightService.setHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[curCycleIndicator], this.currentCyclesLeft[curCycleIndicator], curCycle['label'], false, this.trialChartService.showAveragesForCycles, this.trialChartService.applyAveragePerCondition, chartMode);
            } else if (!this.echartsService.enableCycleHighlighting || curCycle['perc'] < 0) {
              this.echartsHighlightService.clearHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[curCycleIndicator], this.currentCyclesLeft[curCycleIndicator], false);
            }
          }

          const curCycleLeft = this.echartsService.convertTimeToCycle(playbackTime, DataContext.leftSide, clipString);
          if (isSelectedClip) {
            timeInChartLeftLeg = curCycleLeft['perc'];
          }
          if (timeChanged && curCycleLeft['label'] !== this.lastTimeCurrentCycleLeft['label'] || curCycleLeft['perc'] * this.lastTimeCurrentCycleLeft['perc'] < 0) {
            this.lastTimeCurrentCycleLeft = curCycleLeft;
            if (this.echartsService.enableCycleHighlighting && curCycleLeft['perc'] >= 0 && this.currentCyclesLeft[curCycleIndicator] && !this.currentCyclesLeft[curCycleIndicator].find(x => x.label == curCycle['label'])) {
              this.echartsHighlightService.setHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[curCycleIndicator], this.currentCyclesLeft[curCycleIndicator], curCycleLeft['label'], true, this.trialChartService.showAveragesForCycles, this.trialChartService.applyAveragePerCondition, chartMode);
            } else if (!this.echartsService.enableCycleHighlighting || curCycleLeft['perc'] < 0) {
              this.echartsHighlightService.clearHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[curCycleIndicator], this.currentCyclesLeft[curCycleIndicator], true);
            }
          }
        } else {
          const curCycle = this.echartsService.convertTimeToCycle(playbackTime, undefined, clipString);
          if (isSelectedClip) {
            timeInChart = curCycle['perc'];
          }
          if (timeChanged && this.echartsService.enableCycleHighlighting && curCycle['perc'] >= 0 && this.currentCycles[curCycleIndicator] && !this.currentCycles[curCycleIndicator].find(x => x.label == curCycle['label'])) {
            this.echartsHighlightService.setHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[curCycleIndicator], this.currentCyclesLeft[curCycleIndicator], curCycle['label'], true, this.trialChartService.showAveragesForCycles, this.trialChartService.applyAveragePerCondition, chartMode);
          } else if (!this.echartsService.enableCycleHighlighting || curCycle['perc'] < 0) {
            this.echartsHighlightService.clearHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[curCycleIndicator], this.currentCyclesLeft[curCycleIndicator], true);
          }

        }
      }
      for (const trialName of allTrialNamesRemaining) {
        this.echartsHighlightService.clearHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[trialName], this.currentCyclesLeft[trialName], false);
        if (playheadWithContext) {
          this.echartsHighlightService.clearHighlightedCycle(this.echartOption, this.echartsInstance, this.currentCycles[trialName], this.currentCyclesLeft[trialName], true);
        }
      }

      // Render left leg, if available in cycle
      const xPosLeftLeg = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, timeInChartLeftLeg);
      const playheadLeftLeg = xPosLeftLeg;
      this.playheads.push({
        position: playheadLeftLeg,
        visible: this.echartsInstance.containPixel('grid', [playheadLeftLeg, this.playheadHeight]),
        color: this.playHeadColors.cycle.left,
        style: 'pointer',
      });
    }

    // Calculate the new default playhead position (only apply the offset if video started before)
    const tOffset = this.chartsInput.hasCycles !== true && this.trialChartService.sensorDataOffsetInVideo > 0 ? this.trialChartService.sensorDataOffsetInVideo : 0;
    const xPos = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, timeInChart + tOffset);
    const playheadPosition = xPos;
    this.playheads.push({
      position: playheadPosition,
      visible: this.echartsInstance.containPixel('grid', [playheadPosition, this.playheadHeight]),
      color: color ,
      style: 'pointer',
    });

    if (!hasCycles || !this.echartsService.swingTransitions) {
      return;
    }
    let myColor: string;
    const swingTransitionPlayheads: Playhead[] = [];
    for (const swingTransition of this.echartsService.swingTransitions) {
      if ((swingTransition.cycle === undefined && !swingTransition.trialName.includes('<<<reference>>>')) || swingTransition.opposite === true) {
        continue;
      }
      let isRef = false;
      if (swingTransition.trialName.includes('<<<reference>>>')) {
        myColor = this.playHeadColors.reference;
        isRef = true;
      } else if (swingTransition.context === DataContext.leftSide) {
        if (hasCyclesLeft) {
          myColor = this.playHeadColors.cycle.left;
        } else {
          if (hasCycles) {
            myColor = this.playHeadColors.cycle.noContext;
          } else {
            myColor = this.playHeadColors.single;
          }
        }
      } else if (swingTransition.context === DataContext.rightSide && hasCyclesRight) {
        myColor = this.playHeadColors.cycle.right;
      }
      if (!myColor) {
        continue;
      }
      const playHeadPos = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, swingTransition.perc);
      const isHighlighted = this.isSwingTransitionHighlighted(swingTransition);
      swingTransitionPlayheads.push({
        position: playHeadPos,
        visible: this.echartsInstance.containPixel('grid', [playHeadPos, this.playheadHeight]) && this.echartsService.isSwingTransitionEnabled(swingTransition),
        color: myColor,
        style: isHighlighted ? 'bold' : isRef ? 'default' : 'subtle',
        line: this.echartsService.getSwingTransitionLineStyle(swingTransition),
      });
    }
    this.playheads = this.playheads.concat(swingTransitionPlayheads);
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event): void {
    this.resizeEventPending++;
    setTimeout(() => {
      this.resizeEventPending--;
      if (this.resizeEventPending === 0) {
        this.resizeChart();
      }
    }, 200);
  }

  @HostListener('document:keyup.escape', ['$event'])
  onKeyupHandler(): void {
    if (this.isFullscreen) {
      this.toggleFullscreen();
    }
  }

  scaleYToFit(): void {
    this.echartsService.isScaleYToFit = !this.echartsService.isScaleYToFit;
    this.echartsService.applyDataZoom(this.echartsInstance.getOption().dataZoom, true, false, false);
    this.setChartData(this.chartsInput);
  }

  toggleFullscreen(): void {
    if (!this.isFullscreen) {
      this.playerClass = 'fullscreen';
      this.isFullscreen = true;
      this.playbackGlobal.controlsFullscreen.next(true);
    } else {
      this.playerClass = this.baseLayout;
      this.isFullscreen = false;
      this.playbackGlobal.controlsFullscreen.next(false);
    }
    const animationsEnabled = this.echartsService.animationsEnabled === true;   // store if we had them enabled
    this.echartsService.animationsEnabled = false;
    this.echartsService.applyDataZoom(this.echartsInstance.getOption().dataZoom, true, true, true);
    this.setChartData(this.chartsInput);
    this.echartsService.animationsEnabled = animationsEnabled;
    // redraw chart again after toggling full screen, and add timeout after changing the css class (note: the redraw is doubled)
    setTimeout(() => {
      this.echartsService.applyDataZoom(this.echartsInstance.getOption().dataZoom, true, true, true);
      this.setChartData(this.chartsInput);
    }, 20);
  }

  toggleRawAndRmsChartLines(showRawLines: boolean, showRmsLines: boolean): void {
    this.echartsLegendService.resetEmgConsistencyLegendMapping(this.echartOption, this.trialChartService.mergeLegendLabelsForCycles, this.echartsService.getChartMode());
    this.echartsLegendService.showRawChartLines = showRawLines;
    this.echartsLegendService.showRmsChartLines = showRmsLines;
    this.echartsService.applyDataZoom(this.echartsInstance.getOption().dataZoom, true, true, true);
    this.setChartData(this.chartsInput);
  }

  setPlaybackControl(control: PlaybackControlService): void {
    if (!control) return;
    this._playbackControlSub?.unsubscribe();
    this._playbackControl = control;
    this._playbackControlSub = this._playbackControl.playbackTime.subscribe(playbackTime => {
      if (this.echartsInstance) {
        // if we have splitview and a time-series chart (no cycles) then we find the window to show based on the last x zoom
        const trackHasCycles = this.chartsInput.hasCycles ? this.chartsInput.hasCycles : false;
        if (this.splitView && !trackHasCycles) {
          this.setZoomWindowForTimeCharts(playbackTime);
        }
        this.updatePlayhead(playbackTime);
      } else {
        console.warn(`Trying to draw playheads before chart is initialized`);
      }
    });
  }

  private updateZoomX(xStart: number, xEnd: number): void {
    this.staggeredPause();
    this.xZoomStart = xStart;
    this.echartsInstance.dispatchAction({
      type: 'dataZoom',
      dataZoomIndex: 0,
      startValue: xStart,
      endValue: xEnd
    });
  }

  /**
   * If the playback is running, this function pauses it temporarily. Used to wait for rendering
   * actions to finish before resuming. Acts as a workaround when there is no awaitable event
   * from echarts that can be listened for.
   *
   * @see #2182
   */
  private staggeredPause(): void {
    if (this._playbackControl.mode.value === PlaybackMode.Playing) {
      this._playbackControl.pause();
      setTimeout(() => this._playbackControl.play(), 500);
    }
  }

  setLayout(layout: string): void {
    this.baseLayout = layout;
    this.playerClass = this.baseLayout;
  }

  public isChartAvailable(): boolean {
    return this.dataReady;
  }

  public subscribeToReferenceBars(): void {
    this.echartsService.referenceBarPoints
      .pipe(filter(() => !!this.echartsInstance))
      .subscribe((pointArray) => {
        this.referenceBars = [];

        const grid = this.echartsInstance.getOption().grid[0];
        const instanceHeight = this.echartsInstance.getHeight();
        const halfHeight = (instanceHeight - grid.bottom - grid.top) / 2;

        for (let i = 0; i < pointArray.length; i = i + 2) {
          let x1 = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, pointArray[i]);
          let x2 = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, pointArray[i + 1]);

          // we check if the bar extremes are contained in the grid
          const containX1 = this.echartsInstance.containPixel('grid', [x1, halfHeight]);
          const containX2 = this.echartsInstance.containPixel('grid', [x2, halfHeight]);
          // maximum and minimum pixel references are taken from the current zoom level
          const minRefX = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, this.minReferencePercentageX);
          const maxRefX = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, this.maxReferencePercentageX);

          if (!containX1 && !containX2) {
            if (x2 < minRefX || x1 > maxRefX) {
              continue;
            } else {
              x1 = minRefX;
              x2 = maxRefX;
            }
          }
          if (!containX1 && containX2) {
            x1 = minRefX;
          } else if (containX1 && !containX2) {
            x2 = maxRefX;
          }
          this.referenceBars.push({ color: '#bcbcbc', visible: true, point1: { x: x1 }, point2: { x: x2 } });
        }
      });
  }

  /**
   * this sets the xZoom for time-based charts
   */
  private setZoomWindowForTimeCharts(playbackTime: number): void {
    // if we had a zoom stored, reset zoom to that
    if (this.leftRightService.xZoomStartStored > -1 && (this.resetInitialZoom || this.xZoomStart !== this.leftRightService.xZoomStartStored)) {
      this.resetInitialZoom = false;
      this.updateZoomX(this.leftRightService.xZoomStartStored, this.leftRightService.xZoomStartStored + this.leftRightService.zoomWindowX);
    }
    const playHeadPos = this.echartsInstance.convertToPixel({ xAxisIndex: 0 }, playbackTime);
    const withinGrid = this.echartsInstance.containPixel('grid', [playHeadPos, this.playheadHeight]);

    if (playbackTime < this.lastPlaybackTime) {
      this.leftRightService.xZoomOffset = 0;
    }
    const updateZoom = (!withinGrid && this.leftRightService.xZoomOffset == 0) || this.lastPlayheadInGrid !== withinGrid;
    if (updateZoom && this.leftRightService.zoomWindowX > -1 && playbackTime !== this.lastPlaybackTime) {
      const playbackTimeForZoom = playbackTime - this.leftRightService.xZoomOffset;

      const xStart = Math.max(0, Math.floor(playbackTimeForZoom / this.leftRightService.zoomWindowX) * this.leftRightService.zoomWindowX) + this.leftRightService.xZoomOffset;
      const xEnd = xStart + this.leftRightService.zoomWindowX;

      if (this.xZoomStart !== xStart || this.leftRightService.lastZoomWindowX !== this.leftRightService.zoomWindowX) {
        this.leftRightService.lastZoomWindowX = this.leftRightService.zoomWindowX;
        this.updateZoomX(xStart, xEnd);
      }
    }
    this.lastPlayheadInGrid = withinGrid;
  }

  /**
   * this takes the start and end of the XAxis from the DataZoom event
   */
  public setXAxisStatus(zoomOptions: DataZoomComponentOption[]): void {
    const trackHasCycles = this.chartsInput.hasCycles ? this.chartsInput.hasCycles : false;
    if (zoomOptions.length > 0) {
      if (trackHasCycles) {
        this.minReferencePercentageX = zoomOptions[0].start;
        this.maxReferencePercentageX = zoomOptions[0].end;
      } else {
        const startValue = zoomOptions[0].startValue as number;
        const endValue = zoomOptions[0].endValue as number;
        this.leftRightService.zoomWindowX = Math.max(1, Math.round(endValue - startValue));   // prevent this value being 0
        this.leftRightService.xZoomOffset = startValue % this.leftRightService.zoomWindowX;
        this.leftRightService.xZoomStartStored = startValue;
      }
    }
  }

  /**
   * Returns true iif the passed swingtransition should be highlighted.
   * A swing transition can be highlighted for two reasons:
   * 1. in a trial view, the transition cycle is being played
   * 2. in a report view, the transition trial is being highlighted
   */
  private isSwingTransitionHighlighted(swingTransition: SwingTransition): boolean {
    let isHighlighted = false;
    if (this.echartsService.getChartMode() === 'report') {
      // Highlighting based on a report, select based on current trial
      for (const trialName of this.trialChartService.trialNamesReport) {
        isHighlighted = isHighlighted || this.echartsHighlightService.findCycleMatchForReport(
          this.currentCycles[trialName] && this.currentCycles[trialName].length > 0 ? this.currentCycles[trialName][0].label : undefined,
          swingTransition,
          undefined,
          undefined,
          this.trialChartService.showAveragesForCycles,
          this.trialChartService.applyAveragePerCondition
        ) || this.echartsHighlightService.findCycleMatchForReport(
          this.currentCyclesLeft[trialName] && this.currentCyclesLeft[trialName].length > 0 ? this.currentCyclesLeft[trialName][0].label : undefined,
          swingTransition,
          undefined,
          undefined,
          this.trialChartService.showAveragesForCycles,
          this.trialChartService.applyAveragePerCondition
        );
      }
    } else if (this.echartsService.getChartMode() === 'trial') {
      // Highlighting based on trial view, select based on current cycle
      for (const cycle of [this.currentCycles['default'], this.currentCyclesLeft['default']]) {
        if (cycle && cycle.length > 0) {
          isHighlighted = isHighlighted || cycle[0].label === swingTransition.cycle;
        }
      }
    }

    return isHighlighted;
  }

  public canToggleConditionAverages(): boolean {
    return this.trialChartService.allowConditionAvgToggle;
  }

  // get a list of hidden trials from toggle service
  private getHiddenTrialPaths(): string[] {
    const currentTrialToggleStatus = this.chartToggleService.trialToggleStatus.getValue();
    const hiddenTrials = [];
    for (const trial of currentTrialToggleStatus) {
      if (trial.hideTrial) {
        hiddenTrials.push(trial.trialPath);
      }
    }
    return hiddenTrials;
  }
}
