<div *ngIf="clip_info" style="position: relative;">
  <div *ngIf="!moreActions.showApprove else textInput">
      <h6 class="card-title;">
        <a [routerLink]="['/embed', clip_info.id]" routerLinkActive="active">{{clip_info.title}}</a>
      </h6>
  </div>
  <ng-template #textInput>
      <div class="form-group d-inline-flex" style="margin-bottom: 3px;">
          <input type="text" class="form-control" value={{clip_info.title}} style="margin-right: 5px;" #inputTitle>
      </div>
  </ng-template>
  <div class="card-text">
    <small class="text-muted">{{clip_info.createdAt | date}}</small>
  </div>
  <div style="position: absolute; float: right; top: 0px; right: 0px;">
    <span *ngIf="include_comments">
      <i class="fa fa-comment clickable" aria-hidden="true" (click)="commentsClicked()"></i>
    </span>
    <app-more-actions [allowEdit]=allow_edit (actionClick)=moreActionsClick($event) #moreActions></app-more-actions>
  </div>
  <app-comments-feed *ngIf=include_comments [clip_id]="clip_info.id" class="mb-3" #commentsFeed> </app-comments-feed>
</div>