<div class="splitscreen-multi-chart-container" #splitscreenMultiChart>
  <div *ngIf="getCurrentChartGroup()">
    <div *ngFor="let lineChartGroup of getCurrentChartGroup().groups">
      <div *ngIf="lineChartGroup.name.length>0" class="ml-3">
        <h6 class="mb-0 chart-group-name">{{lineChartGroup.name}}&nbsp;</h6>
      </div>
      <div class="row mr-0 ml-0">
        <div class="col-lg-4 col-md-6 pl-1 pr-0 small-margins-bottom" *ngFor="let dataTrack of lineChartGroup.tracks"
          [style]="'position: relative; width: 100%; height: ' + lineHeight +'px;'">
          <div style="width: 99%; height: 100%;">
            <app-charts [splitView]="true" [input]="dataTrack" [playhead]="playhead" [playbackControl]="playbackControl" [highlightActiveCycle]="true" #chart
            [clickers]="false" [selectedClip]="this.selectedChartFilter" [multiChartSplitScreen]="true" style="width: 100%; height: 100%;"></app-charts>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
