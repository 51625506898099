import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) {  }

  transform(value: any, type: string): SafeHtml | SafeStyle {
    switch (type) {
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'url': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Unsupported type for SafePipe: ${type}`)
    }
  }
}
