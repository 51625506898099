import gql from 'graphql-tag';

export const jobStatus = gql`
query jobStatus($jobId: ID!) {
  node(id: $jobId) {
    ... on Job {
      id,
      status,
      result,
      description
    }
  }
}
`;

export const checkOrganization = gql`
query checkOrganization($email: String) {
  organizations(email: $email) {
      id
      name
      logo
  }
}
`;

export const additionalDataStatus = gql`
query additionalDataStatus($id: ID!) {
  node(id: $id) {
    ... on AdditionalData {
      id,
      uploadStatus
    }
  }
}
`;

export const additionalDataMetadata = gql`
query additionalDataMetadata($id: ID!) {
  node(id: $id) {
    ... on AdditionalData {
      id,
      blobMetadata
    }
  }
}
`;

export const additionalDataDownloadUri = gql`
query additionalDataDownloadUri($id: ID!) {
    node(id: $id) {
      ... on AdditionalData {
        originalDataDownloadUri
      }
    }
  }
`;

export const normsList = gql`
query {
  norms {
    id
    name
    description
    uploadStatus
    project {
      id
    }
  }
}
`;


export const gaitToolMutation = gql`
mutation processGaitTool($clips: [String!], $trialType: String) {
  processGaitTool(clips: $clips, trialType: $trialType) {
          jobId
  }
}`;

export const normMutation = gql`
mutation createNormFromClips($project: String!, $name: String!, $clips: [String!], $projectPath: String) {
  createNorm(project: $project, name: $name, clips: $clips, projectPath: $projectPath) {
          created
          norm {
              id
              name
              description
              uploadStatus
          }
  }
}`;

export const deleteNormMutation = gql`
mutation deleteNormById($normId: ID!) {
  deleteNorm(normId: $normId) {
    deleted
  }
}`;

export const updateNormMutation = gql`
mutation updateNormById($normId: ID!, $projectPath: String!) {
  updateNorm(normId: $normId, projectPath: $projectPath) {
    updated
  }
}`;

export const createReportMutation = gql`
mutation createReportFromClips($project: String!, $title: String!, $avgIds: [String], $clipIds: [String], $projectPath: String, $layoutType: String, $patientId: ID, $customOptions: JSONString, $sessionId: ID$, normId: ID) {
  createReport(project: $project, title: $title, clipIds: $clipIds,  avgIds: $avgIds, projectPath: $projectPath, layoutType: $layoutType, patientId: $patientId, customOptions: $customOptions, sessionId: $sessionId, normId: $normId,) {
          created
          report {
              id
              title
              projectPath
              description
              clipIds
              layoutType
              norm {
                id
              }
          }
  }
}`;

export const updateReportMutation = gql`
mutation updateReportMutation($reportId: ID!, $normId: ID, $title: String, $description: String, $projectPath: String, $clipIds: [String], $template: JSONString, $layoutType: String) {
  updateReport(reportId: $reportId, normId: $normId, title: $title, description: $description, projectPath: $projectPath, clipIds: $clipIds, template: $template, layoutType: $layoutType) {
      report {
          id,
          title,
          customOptions,
          description,
          projectPath,
          norm {
            id
          }
      }
  }
}`;

export const deleteReportMutation = gql`
mutation deleteReportById($reportId: ID!) {
  deleteReport(reportId: $reportId) {
    deleted
  }
}`;


export const fbxConvertMutation = gql`
mutation processFbx($clipId: String!, $options: String) {
  processFbx(clipId: $clipId, options: $options) {
          jobId
  }
}`;

export const classifyMutation = gql`
mutation classifyMutation($clipId: String!, $options: String) {
  classifyPattern(clipId: $clipId, options: $options) {
          started
          prediction
  }
}`;

export const hrnetMutation = gql`
mutation processVideo($clipIds: [String!]!, $options: String) {
  processVideo(clipIds: $clipIds, options: $options) {
          started
          clips {
            id
            originalFileName
            uploadStatus
          }
  }
}`;


export const viewerProjects = gql`
query {
  viewer {
    id
    organizations {
      id,
      name,
      role
    }
    projects {
      id,
      name,
      public,
      imageUri,
      updated,
      unlistedAccess,
      deleted
      organization {
        id
        name
      }
      members {
        role,
        user {
          username,
          avatar
        }
      }
    }
  }
}
`;

export const GetUserClipsQuery = gql`
query getUserClips($username: String!, $limit: Int=10, $cursor: String) {
  user(username: $username) {
    id,
    mocapClips(first: $limit, after: $cursor) {
      pageInfo {
        hasNextPage,
        endCursor
      }
      edges {
        node {
          id,
          previewDataUri,
          originalFileName,
          title,
          description,
          created
        }
      }
    }
  }
}
`;

export const GetClipsQuery = gql`
query getClips($before: String, $after: String, $first: Int, $last: Int, $filter: String) {
  mocapClips(first: $first, last: $last, before: $before, after: $after, search: $filter) {
    pageInfo {
      hasNextPage,
      endCursor
    },
    edges {
      node {
        id,
        previewDataUri,
        originalFileName,
        title,
        description,
        created,
        owner {
          displayName,
          username,
          avatar
        }
      }
    }
  }
}`;

export const OrganizationByDomain = gql`
  query getOrganizationByDomain($domain: String!) {
    organizations(domain: $domain) {
      id,
      name,
      logo,
      domain,
      sessionPersistence,
      emailPatterns,
      guestAccount,
      sso,
      configuration {
        processorsEnabled
        ehrIntegration
      },
      maintenance
    }
  }`;

export const ReportById = gql`
  query getReportById($id: ID!) {
    node(id: $id) {
      ... on Report {
        id,
        title,
        description,
        customOptions,
        created,
        projectPath,
        layoutType,
        author {
          username,
          avatar,
          displayName
        },
        patient {
          id,
          name
        }
        norm {
          id,
          name
        },
        session {
          id,
          projectPath
        }
        project {
          id,
          name,
          canEdit
          norms {
            id,
            name,
            previewUri,
            clips {
              id,
              title
            }
          }
          reports {
            id
          }
        },
        avgs {
          id,
          name,
          projectPath,
          previewUri,
          clips {
            id,
            title,
            has3d,
            hasVideo,
            projectPath,
            additionalData {
              id,
              dataType,
              originalFileName
            }
          }
        },
        clips {
          id,
          title,
          has3d,
          hasCharts,
          hasVideo,
          hasDocuments,
          customOptions,
          defaultSelection {
            start,
            end
          },
          projectPath,
          additionalData {
            id,
            dataType,
            originalFileName
          }
          owner {
            id,
            username,
            avatar
          }
        }
      }
    }
  }`;

export const ReportByIdForSessionQuery = gql`
  query getReportByIdForSession($id: ID!) {
    node(id: $id) {
      ... on Report {
        id,
        title,
        customOptions,
        projectPath,
        session {
          id,
          projectPath
        }
      }
    }
  }`;

export const UpdateProjectMutation = gql`
      mutation updateProjectMutation($input: UpdateProjectInput!) {
        updateProject(input: $input) {
          project {
            id
            name
            description
            public
            imageUri
            unlistedAccess
          }
        }
      }
    `;


export const ProjectPatientByEhrId = gql`
query getPatientByEhrId($ehrId: String!, $projectId: String!) {
  patient(ehrId: $ehrId, projectId: $projectId) {
    id,
    name
  }
}
`;

export const ProjectPatientsByIdQuery = gql`
  query getProjectPatients($id: ID!) {
    node(id: $id) {
      ... on Project {
        id,
        name,
        description,
        configuration,
        canEdit,
        patientsList {
          id
          name
          updated
          externalId
        }
      }
    }
  }
`;

export const ProjectDataByIdQuery = gql`
  query getProjectMetadata($id: ID!, $limit: Int,  $cursor: String) {
    node(id: $id) {
      ... on Project {
        id,
        name,
        imageUri,
        description,
        public,
        unlistedAccess,
        configuration,
        organization {
          id
          name
        }
        members {
          role,
          user {
            username,
            avatar
          }
        },
        norms {
          id,
          name,
          description,
          projectPath,
          clips {
            id,
            title
          }
        },
        reports {
          id,
          title,
          projectPath,
          clipIds,
          layoutType,
          clips {
            id,
            title,
            hasVideo,
            hasDocuments
          }
          created,
          project {
            id
          }
        },
        canEdit,
        isAdmin,
        sessions {
          id,
          projectPath
        }
        clips(first: $limit, after: $cursor) {
          edges {
            node {
              id,
              title,
              owner {
                id,
                avatar,
                username
              },
              session {
                id
                projectPath
                clips {
                  id,
                  title,
                  projectPath
                }
              }
              allowDownload,
              created,
              hasCharts,
              dataReady,
              projectPath
            }
          }
        }
      }
    }
  }
`;

export const PatientContextByIdQuery = gql`
query getPatientContext($id: ID!) {
  node(id: $id) {
    ... on Patient {
      id,
      name,
      metadata,
      project {
        id
        description
        canEdit
        isAdmin
        configuration
        unlistedAccess
        organization {
          id
          name
          domain
        }
      }
    }
  }
}
`;

