import { fromEvent, Observable, Subject } from 'rxjs';

import { map, first } from 'rxjs/operators';
import { ThreeJsRendererService } from '../core/three-js-renderer.service';

import {
  Quaternion,
  Vector3,
  Mesh,
  TorusBufferGeometry,
  MeshBasicMaterial,
} from 'three';
import { CoordinatesType } from './clip-player';

export class VrController {

  constructor(pad: Gamepad, private renderer: ThreeJsRendererService, coord?: CoordinatesType) {
    this._id = pad.id;
    this._index = pad.index;
    this._orientation = new Quaternion();
    this._position = new Vector3();
    this._rayDirection = new Vector3();
    this._hasPosition = (pad as any).pose.hasPosition;
    this._hasOrientation = (pad as any).pose.hasOrientation;
    this._teleportButtonPressed = false;

    this._axes = new Subject<number[]>();
    this._buttons = new Subject<GamepadButton[]>();

    const geom = new TorusBufferGeometry(0.1, 0.025, 8, 16);

    // if(coord !== CoordinatesType.zUp)
    geom.rotateX(-Math.PI / 2);

    this._coord = coord;

    const mat = new MeshBasicMaterial({ color: 0x5850ff });
    this.teleporter = new Mesh(geom, mat);
    this.teleporter.frustumCulled = false;
  }

  get id(): string {
    return this._id;
  }

  get connected(): boolean {
    return this._connected;
  }

  get timestamp(): number {
    return this._timestamp;
  }

  get axes(): Observable<number[]> {
    return this._axes;
  }

  get buttons(): Observable<GamepadButton[]> {
    return this._buttons;
  }

  get hasPosition() {
    return this._hasPosition;
  }

  get hasOrientation() {
    return this._hasOrientation;
  }

  static readonly forward = new Vector3(0, 0, -1);
  private _index;
  private _orientation: Quaternion;
  private _position: Vector3;
  private _rayDirection: Vector3;
  private _id: string;
  private _connected: boolean;
  private _timestamp: number;
  private _axes: Subject<number[]>;
  private _buttons: Subject<GamepadButton[]>;
  private _hasPosition: boolean;
  private _hasOrientation: boolean;
  private _teleportButtonPressed: boolean;
  private _coord: CoordinatesType;

  // type becomes Mesh<TorusBufferGeometry, MeshBasicMaterial> after threejs upgrade
  public teleporter: Mesh;

  static getController(renderer: ThreeJsRendererService, coord?: CoordinatesType): Promise<VrController> {
    return fromEvent(window, 'gamepadconnected').pipe(
      first(),
      map((ev: GamepadEvent) => new VrController(ev.gamepad, renderer, coord))
    ).toPromise();
  }

  private setTeleportToFloorIntercept(): boolean {
    /*if(this._coord == CoordinatesType.zUp) {
      if (this._rayDirection.z) {
        const t = -this._position.z / this._rayDirection.z;
        const v = this._rayDirection.clone().multiplyScalar(t);
        this.teleporter.position.addVectors(this._position, v);
      }
    }
    else*/
    {
      if (this._rayDirection.y) {
        const t = -this._position.y / this._rayDirection.y;

        if (t > 0) {
          const v = this._rayDirection.clone().multiplyScalar(t);
          this.teleporter.position.addVectors(this._position, v);
          this.teleporter.position.y = 0;
        }

        if (t > 10 || t < 0) {
          return false;
        }

        return true;
      }
    }
  }

  update() {
    const pad = navigator.getGamepads()[this._index];

    if (pad) {
      this._connected = pad.connected;
      this._timestamp = pad.timestamp;
      // XXX this needs to be fixed to get the VR controller working again sometime.
      // this._axes.next(pad.axes);
      // this._buttons.next(pad.buttons);

      const pose = (pad as any).pose;
      if (pose) {
        if (pose.position) {
          this._position.fromArray(pose.position);
        } else if (this.renderer.vrCameraPosition) {
          this._position.copy(this.renderer.vrCameraPosition);
          this._position.y = 1.2; // Based on 1.6m userHeight in WebVRManager
          /*
          if(this._coord == CoordinatesType.zUp)
            this._position.z = 1.2; // Based on 1.6m userHeight in WebVRManager
          else
            this._position.y = 1.2; // Based on 1.6m userHeight in WebVRManager
          */
        }
        if (pose.orientation) { this._orientation.fromArray(pose.orientation); }

        this._rayDirection.copy(VrController.forward);
        this._rayDirection.applyQuaternion(this._orientation);

        /*if(this._coord == CoordinatesType.zUp)
        {
          var axis = new Vector3( 1, 0, 0 );
          var angle = Math.PI / 2;
          this._rayDirection.applyAxisAngle( axis, angle );
        }*/

        // Can be removed after upgrading threejs version
        const material = this.teleporter.material as MeshBasicMaterial;
        if (this.setTeleportToFloorIntercept()) {
          material.color.setHex(0x5850ff);
          if (this._teleportButtonPressed !== pad.buttons[0].pressed) {
            this._teleportButtonPressed = pad.buttons[0].pressed;

            if (this._teleportButtonPressed) {
              this.renderer.vrCameraPosition = this.teleporter.position;
            }
          }
        } else {
          material.color.setHex(0xff0000);
        }
      }
    }
  }
}
