import * as THREE from 'three';

export class TrailRenderer {

    positions: Float32Array;
    geometry: THREE.BufferGeometry;
    obj: any;
    segments = 100;
    activePoints = 0;
    zUp: boolean;
    group: THREE.Group;

    constructor(scene: any, obj: any, zUp: boolean) {

        this.geometry = new THREE.BufferGeometry();
        this.positions =  new Float32Array( this.segments * 3 );
        this.zUp = zUp;
        this.obj = obj;

        const colors = new Float32Array( this.segments * 3 );

        for ( let i = 0; i < this.segments; i ++ ) {
            colors[i*3] = 0.8;
            colors[i*3 + 1] = 0.8;
            colors[i*3 + 2] = 0.8;
        }

        this.geometry.addAttribute( 'position', new THREE.BufferAttribute( this.positions, 3 ).setDynamic( true ) );
        this.geometry.addAttribute( 'color', new THREE.BufferAttribute( colors, 3 ).setDynamic( true ) );

        //this.geometry.setDrawRange( 0, 0 );
        const material = new THREE.LineBasicMaterial( {
            vertexColors: THREE.VertexColors,
            blending: THREE.AdditiveBlending,
            linewidth: 0.6,
            opacity: 0.4,
            transparent: true
        } );

        const linesMesh = new THREE.Line( this.geometry, material );
        linesMesh.frustumCulled = false;

        this.group = new THREE.Group();

        this.group.add( linesMesh );
        scene.add( this.group );
    }

    resetPoints() {
        this.activePoints = 0;
    }

    toggleVisibility() {
        this.group.visible = !this.group.visible;
    }

    update() {
        if (!this.obj.visible)
            return;

        const pos = new THREE.Vector3();
        this.obj.getWorldPosition(pos);

        this.activePoints ++;
        if (this.activePoints > this.segments) {
            this.activePoints = this.segments;
            for ( let i = 1; i < this.segments; i ++ ) {

                this.positions[(i-1)*3] = this.positions[i*3];
                this.positions[(i-1)*3 + 1] = this.positions[i*3 + 1];
                this.positions[(i-1)*3 + 2] = this.positions[i*3 + 2];
            }
        }

        for (let j = this.activePoints; j <= this.segments; j++) {

            if (this.zUp) {
                this.positions[(j - 1)*3] = pos.x;
                this.positions[(j - 1)*3 + 1] = -pos.z;
                this.positions[(j - 1)*3 + 2] = pos.y;
            } else {
                this.positions[(j - 1)*3] = pos.x;
                this.positions[(j - 1)*3 + 1] = pos.y;
                this.positions[(j - 1)*3 + 2] = pos.z;
            }
        }
        //this.geometry.setDrawRange( 0, numConnected * 2 );
        this.geometry.attributes.position.needsUpdate = true;
        this.geometry.attributes.color.needsUpdate = true;

    }

}
