import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class WebvrService {

  private inBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.inBrowser = isPlatformBrowser(this.platformId);
  }

  public getVRDisplays(): Promise<any[] | null> {
    if (this.inBrowser && (navigator as any).getVRDisplays) {
      return (navigator as any).getVRDisplays();
    }
    return Promise.resolve(null);
  }
}
