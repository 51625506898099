import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LeftRightToggleStatus {
  left: boolean;
  right: boolean;
}

export interface TrialToggleStatus {
  trialPath: string;
  hideTrial: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ChartToggleService {
  public leftRightToggleStatus: BehaviorSubject<LeftRightToggleStatus> = new BehaviorSubject<LeftRightToggleStatus>(undefined);
  public showLeftToggle = false;
  public showRightToggle = false;
  public trialToggleStatus: BehaviorSubject<TrialToggleStatus[]> = new BehaviorSubject<TrialToggleStatus[]>([]);


  public setLeftRightToggleStatus(status: LeftRightToggleStatus): void {
    this.leftRightToggleStatus.next(status);
  }

  public setTrialToggleStatus(status: TrialToggleStatus[]): void {
    this.trialToggleStatus.next(status);
  }

  public resetStatus(): void {
    this.leftRightToggleStatus.next(undefined);
    this.trialToggleStatus.next([]);
  }
}
