import { Inject, Injectable, OnDestroy } from "@angular/core";
import { ChartTemplatesOverride } from "app/projects/project-info/project-info-data.service";
import { LineChartMajorGroup } from "app/shared/chart/chart.types";
import { ChartConfig } from "app/shared/chart/echarts.service";
import { templatesConfiguration } from "./templates/report.templates";

export enum TemplateType {
  REPORT = 'report',
  TRIAL = 'trial',
}

export interface ChartTemplate {
  hideChart?: boolean; // default false
  title: string;
  id: string;
  names: string[];
  vLimits?: [number, number];
  vAxis?: string;
  chartGroupSourceName?: string;
}

export interface ChartTemplateGroup {
  name: string;
  charts: ChartTemplate[];
}

export interface TemplateDefinition {
  metadata: TemplateMetadata,
  configuration: TemplatesConfiguration;
  chartConfig?: ChartConfig;
}

export interface TemplateMetadata {
  label: string;
  id: string;
  version: number;
  mergeTemplate?: boolean;
  defaultTimeSeries?: boolean;
  normalizeToBodyWeight?: boolean;
  applyEmgReferenceBars?: boolean;   // We use this in Insight as boolean of chartTemplate, on Information System it can be a boolean or string and is part of trial template
  customChartNames?: Record<string, string>;
  customForceComponentNames?: string[];
}

export interface TemplatesConfiguration {
  momentsCharts: ChartTemplateGroup[],
  powersCharts: ChartTemplateGroup[],
  emgsCharts: ChartTemplateGroup[],
  kinematicsCharts: ChartTemplateGroup[],
  forcesCharts: ChartTemplateGroup[],
  muscleLengthsVelocitiesCharts: ChartTemplateGroup[],
  emgsConsistencyCharts: ChartTemplateGroup[],
  trajectoriesCharts: ChartTemplateGroup[],
  combinedCharts: ChartTemplateGroup[],
}

export const DEFAULT_CHART_NAMES: Record<string, string> = {
  kinematicsCharts: 'Kinematics',
  momentsCharts: 'Moments',
  powersCharts: 'Powers',
  forcesCharts: 'Forces',
  emgsCharts: 'EMG',
  emgsConsistencyCharts: 'EMG consistency',
  trajectoriesCharts: 'Trajectories',
  combinedCharts: 'Combined',
  muscleLengthsVelocitiesCharts: 'Muscle lengths and velocities',
};

const NO_TEMPLATE_ID: string = 'notemplate';

@Injectable()
export class ChartsTemplatesService implements OnDestroy {
  private readonly currentTemplateType: TemplateType;
  private currentTemplate: TemplatesConfiguration;

  public forceComponentNames: string[] = ['-Fx', '-Fy', '-Fz'];

  constructor(
    @Inject('templateType') templateType: TemplateType,
  ) {
    this.currentTemplateType = templateType;
    this.currentTemplate = JSON.parse(JSON.stringify(templatesConfiguration));
  }

  public ngOnDestroy(): void {
    // no need to unsubscribe from anything
  }

  public isNoTemplateSelected(currentTemplateMetadata: TemplateMetadata): boolean {
    return currentTemplateMetadata?.id === NO_TEMPLATE_ID ? true : false;
  }

  public getCurrentTemplateType(): TemplateType {
    return this.currentTemplateType;
  }

  public loadTemplateOverride(override: TemplatesConfiguration, overrideByOrder: boolean = true): void {
    this.mergeTemplatesConfigurations(this.currentTemplate, override, overrideByOrder);
  }

  public resetDefaultTemplate(): void {
    this.currentTemplate = JSON.parse(JSON.stringify(templatesConfiguration));
  }

  public setCurrentReportTemplate(reportTemplate: TemplatesConfiguration, mergeTemplate = true): void {
    if (!reportTemplate) {
      this.resetTemplate();
    } else if (this.currentTemplate && mergeTemplate) {
      // this.mergeTemplatesConfigurations(this.currentTemplate, reportTemplate, false);
      this.mergeTemplatesConfigurations(reportTemplate, this.currentTemplate, false);
    } else {
      this.currentTemplate = reportTemplate;
    }
  }

  public setInitialChartsTemplate(templateOverridesConfig: ChartTemplatesOverride, projectBaseTemplates: TemplateDefinition[], overrideByOrder = true): TemplateMetadata {
    this.resetDefaultTemplate();
    const defaultTemplate = projectBaseTemplates.find(template => template.metadata.id === templateOverridesConfig?.defaultChartsTemplateId);
    if (templateOverridesConfig?.enabled) {
      if (defaultTemplate) {
        this.setCurrentReportTemplate(defaultTemplate.configuration, false);
      } else {
        this.loadTemplateOverride(templateOverridesConfig.override, overrideByOrder);
      }
    }
    return defaultTemplate?.metadata;
  }

  public getCurrentTemplate(): TemplatesConfiguration {
    return this.currentTemplate;
  }

  public resetTemplate(): void {
    this.currentTemplate = undefined;
  }

  public getTemplateFor(chartType: keyof TemplatesConfiguration): ChartTemplateGroup[] {
    return this.currentTemplate?.[chartType] !== undefined ? this.currentTemplate[chartType] : [];
  }

  public getChartNameFor(chartType: keyof TemplatesConfiguration): string {
    return DEFAULT_CHART_NAMES[chartType];
  }

  public replaceChartNames(lineChartMajorGroups: LineChartMajorGroup[], templateMetadata: TemplateMetadata): void {
    const chartNamesToReplace: Record<string, string> = {};
    const chartNames = Object.keys(DEFAULT_CHART_NAMES);
    if (templateMetadata?.customChartNames !== undefined) {
      for (const chartName of chartNames) {
        if (templateMetadata.customChartNames[chartName] !== undefined && templateMetadata.customChartNames[chartName].length > 0) {
          chartNamesToReplace[DEFAULT_CHART_NAMES[chartName]] = templateMetadata.customChartNames[chartName];
        }
      }
    }
    const chartNamesToReplaceArray = Object.keys(chartNamesToReplace);
    lineChartMajorGroups.forEach(group => {
      if (chartNamesToReplaceArray.includes(group.name)) {
        group.name = chartNamesToReplace[group.name];
      }
    });
  }

  // sets force component names if they are defined on the template
  public setForceComponentNames(templateMetadata: TemplateMetadata): void {
    const forceNames = templateMetadata?.customForceComponentNames;
    if (forceNames && forceNames.length === 3) {
      this.forceComponentNames = ['-' + forceNames[0], '-' + forceNames[1], '-' + forceNames[2]];
    }
  }

  public getAvailableTemplates(chartTemplatesOverride: ChartTemplatesOverride, projectBaseTemplates: TemplateDefinition[], emptyTemplate: TemplateDefinition): TemplateDefinition[] {
    // check if we need to pick a base template as default and if it exists if we need to pick. Then update the template list accordingly.
    if (chartTemplatesOverride?.defaultChartsTemplateId) {
      const emptyDefaultTemplate: TemplateDefinition = JSON.parse(JSON.stringify(emptyTemplate)); // deep copy
      const projectBaseTemplatesCopy: TemplateDefinition[] = JSON.parse(JSON.stringify(projectBaseTemplates)); // deep copy
      const templateIds:string[] = projectBaseTemplatesCopy.map(template => template.metadata.id);
      const templateExists = templateIds.includes(chartTemplatesOverride.defaultChartsTemplateId);
      if (templateExists) {
        const defaultTemplate = projectBaseTemplatesCopy.find(template => template.metadata.id === chartTemplatesOverride.defaultChartsTemplateId);
        emptyDefaultTemplate.metadata.id = defaultTemplate.metadata.id;
        emptyDefaultTemplate.metadata.label = defaultTemplate.metadata.label + ' (default)';
        emptyDefaultTemplate.metadata.version = defaultTemplate.metadata.version;
        emptyDefaultTemplate.configuration = defaultTemplate.configuration;
        const index = projectBaseTemplatesCopy.findIndex(template => template.metadata.id === chartTemplatesOverride.defaultChartsTemplateId);
        projectBaseTemplatesCopy.splice(index, 1);
      }
      return [emptyDefaultTemplate, ...projectBaseTemplatesCopy];
    } else {
      return [emptyTemplate, ...projectBaseTemplates];
    }
  }
  /**
   * Merge two templates configurations with custom logic to match them on chart group name
   * @TODO: unit tests
   */
  private mergeTemplatesConfigurations(base: TemplatesConfiguration, override: TemplatesConfiguration, overrideByOrder: boolean): void {
    for (const chartType in override) {
      for (const chartGroup of override[chartType]) {
        const groupName = chartGroup.name;
        const baseConfig = base[chartType]?.filter(baseChartGroup => baseChartGroup.name.toLowerCase() === groupName.toLowerCase());
        if (baseConfig?.length !== 1 ) {
          this.hideGroupCharts(chartGroup);
          continue;
        }
        // baseConfig[0] = merge(baseConfig[0], chartGroup);
        if (overrideByOrder === true) {
          baseConfig[0] = this.mergeChartGroupsByOrder(baseConfig[0], chartGroup);
        } else {
          baseConfig[0] = this.mergeChartGroups(chartGroup, baseConfig[0]);
        }
      }
    }
  }

  private mergeChartGroupsByOrder(base: ChartTemplateGroup, override: ChartTemplateGroup): ChartTemplateGroup {
    const result = base;
    const baseCharts = base.charts;
    const overrideCharts = override.charts;
    for (const chartIndex in overrideCharts) {
      baseCharts[chartIndex] = this.mergeCharts(baseCharts[chartIndex], overrideCharts[chartIndex]);
    }

    return result;
  }

  private mergeChartGroups(base: ChartTemplateGroup, override: ChartTemplateGroup): ChartTemplateGroup {
    const result = base;
    const baseCharts = base.charts;
    const overrideCharts = override.charts;
    for (let chart of baseCharts) {
      const overrideChart = this.findChartInGroup(chart, override);
      if (overrideChart === undefined) {
        chart.hideChart = true;
        console.error(`Cannot find chart with id/title ${chart.id}/${chart.title}, falling back to default and setting it to hidden`);
      } else {
        chart = this.mergeCharts(chart, overrideChart);
      }
    }
    for (const chart of overrideCharts) {
      if (!this.findChartInGroup(chart, result)) {
        if (!chart.id) {
          chart.id = chart.title;
        }
        if (chart.hideChart === undefined) {
          chart.hideChart = false;
        }
        result.charts.push(chart);
      }
    }

    return result;
  }

  private mergeCharts(base: ChartTemplate, override: ChartTemplate): ChartTemplate {
    const result = base;

    // If the base is undefined, the override is specifying a new chart at the tail, return it as it is
    if (result === undefined) {
      return override;
    }

    // If the chart is set to null, skip it as it was just a way to not override a specific chart
    if (override === null) {
      return result;
    }

    if (!result.id) {
      result.id = result.title;
    }

    if (Object.keys(override).length === 0 || override.names?.length === 0) {
      result.hideChart = true;
      return result;
    }

    for (const chartKey in override) {
      result[chartKey] = override[chartKey];
    }

    return result;
  }

  private findChartInGroup(chartToFind: ChartTemplate, group: ChartTemplateGroup): ChartTemplate {
    for (const chart of group.charts) {
      if ((chart.id !== undefined && chartToFind.id !== undefined && chart.id === chartToFind.id)
        || (chart.title !== undefined && chartToFind !== undefined && chart.title === chartToFind.title)
        || this.chartsHaveSameNames(chart, chartToFind)) {
        chart.id = chartToFind.id;
        return chart;
      }
    }

    return undefined;
  }

  private chartsHaveSameNames(c1: ChartTemplate, c2: ChartTemplate): boolean {
    return (c1.names?.length === c2.names?.length) && (c1.names?.every(val => c2.names?.includes(val)));
  }

  private hideGroupCharts(group: ChartTemplateGroup): void {
    for (const chart of group.charts) {
      chart.hideChart = true;
    }
  }

}
