import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

type Pixel = number;
type Color = string; // A hex based color string without alpha channel
type PlayheadStyle = 'default' | 'bold' | 'subtle' | 'pointer';

export type Playhead = {
  position: Pixel;
  visible: boolean;
  /**
   * A hex based color string *without* alpha channels specified
   */
  color: Color;
  /**
   * Playhead line style, can be either:
   * * 'default' (or left undefined): no extra styling
   * * 'bold': line is wider
   * * 'subtle': line is thinner and a 5% transparency is applied to it
   * * 'pointer': line position is highlighted by two triangle shaped pointers
   */
  style?: PlayheadStyle;
  /**
   * If specified, defines a dashed line for the playhead in array notation.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
   */
  line?: number[];
};

@Component({
  standalone: true,
  selector: 'app-playhead',
  templateUrl: './playhead.component.html',
  styleUrls: ['./playhead.component.scss']
})
export class PlayheadComponent implements AfterViewInit {

  @ViewChild('playhead_canvas', { static: true }) canvasRef: ElementRef;

  @Input() public set playheads(playheadsArray: Playhead[]) {
    this.drawPlayheads(playheadsArray);
  }
  @Input() public height: Pixel;
  @Input() public width: Pixel;
  @Input() public top: Pixel;
  private _currentPlayheads: Playhead[] = [];
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private setServiceAsReady: () => void;
  private serviceToBeReady: Promise<void> = new Promise<void>(resolve => this.setServiceAsReady = resolve);

  public ngAfterViewInit(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext('2d');

    this.setServiceAsReady();
  }

  private async drawPlayheads(playheads: Playhead[]): Promise<void> {
    await this.serviceToBeReady;
    if (!this.shouldRedraw(this._currentPlayheads, playheads)) {
      return;
    }
    this._currentPlayheads = playheads;

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    for (const playhead of this._currentPlayheads) {
      this.drawPlayhead(playhead);
    }
   }

  private shouldRedraw(oldPlayheads: Playhead[], newPlayheads: Playhead[]): boolean {
    if (oldPlayheads.length !== newPlayheads.length) {
      return true;
    }

    for (let i = 0; i < oldPlayheads.length; i++) {
      if (oldPlayheads[i].position !== newPlayheads[i].position) {
        return true;
      }
      if (oldPlayheads[i].visible !== newPlayheads[i].visible) {
        return true;
      }
    }

    return false;
  }

  private drawPlayhead(playhead: Playhead): void {
    if (playhead.visible === false) {
      return;
    }
    const canvasOptions = this.getCanvasStrokeOptions(playhead);
    const x = playhead.position;
    this.context.save();
    this.context.beginPath();
     // Set playhead options
    this.context.strokeStyle = canvasOptions.color;
    this.context.lineCap = 'butt';
    this.context.lineWidth = canvasOptions.lineWidth;
    const capSize = this.context.lineWidth / 2;
    if (playhead.line) {
      this.context.setLineDash(playhead.line);
    }

    // Draw the main line
    this.context.moveTo(x, capSize);
    this.context.lineTo(x, this.canvas.height - capSize);
    this.context.stroke();

    // If it is a pointer, also draw top and bottom triangles
    if (playhead.style === 'pointer') {
      // top triangle
      this.context.lineCap = 'round';
      this.context.beginPath();
      this.context.lineWidth = 4;
      this.context.lineTo(x + 5, capSize);
      this.context.lineTo(x, capSize + 5);
      this.context.lineTo(x - 5, capSize);
      this.context.lineTo(x, capSize);
      this.context.stroke();

      // bottom triangle
      this.context.beginPath();
      this.context.lineWidth = 4;
      this.context.lineTo(x + 5, this.canvas.height - capSize);
      this.context.lineTo(x, this.canvas.height - capSize - 5);
      this.context.lineTo(x - 5, this.canvas.height - capSize);
      this.context.lineTo(x, this.canvas.height - capSize);
      this.context.stroke();
    }

    this.context.restore();
  }

  private getCanvasStrokeOptions(playhead: Playhead): { lineWidth: number, color: string } {
    switch (playhead.style) {
      case 'bold':
        return { lineWidth: 3, color: playhead.color };
      case 'subtle':
        // Thinner line and also add a 5% transparency to the hex based color
        return { lineWidth: 0.8, color: playhead.color + '95' };
      default:
        return { lineWidth: 2, color: playhead.color };
    }
  }

}
