import { Injectable } from "@angular/core";
import { WindowService } from "app/core/window.service";
import { environment } from 'environments/environment';

export enum Region {
  EU = '',
  US = 'us',
}

@Injectable({providedIn: "root"})
export class EnvironmentService {
  private environment = environment;

  constructor(
    private readonly window: WindowService,
  ) {
    const region = this.getRegion();
    // this.environment.baseHref = this.applyRegionToUrl(this.environment.baseHref, region);
    // this.environment.subdomainTemplate = this.applyRegionToUrl(this.environment.subdomainTemplate, region);
    this.environment.graphqlUrl = this.applyRegionToUrl(this.environment.graphqlUrl, region);
    this.environment.privateApiUrl = this.applyRegionToUrl(this.environment.privateApiUrl, region);
  }

  private getRegionFromUrl(url: string): Region {
    // Detect if the current url belongs to a US domain
    // Currently only implemented for specific strings, please see
    // https://moveshelflabs.atlassian.net/wiki/spaces/DOCS/pages/3509616646/Regional+deployment
    if (url?.includes('.us.') || url?.includes('shriners')) {
      return Region.US;
    }

    return Region.EU;
  }

  private applyRegionToUrl(url: string, region: Region): string {
    if (region === Region.EU) {
      // EU region doesn't have any suffix to apply
      return url;
    }
    const parts = url.split('.');
    if (!(parts.length > 1)) {
      // the url is either malformed, localhost, don't do anything
      return url;
    }
    parts.splice(1, 0, region);

    return parts.join('.');
  }

  public getRegion(): Region {
    return this.getRegionFromUrl(this.window.getCurrentHost());
  }

  public getEnvironment(): typeof environment {
    return this.environment;
  }
}
