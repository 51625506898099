<form [formGroup]="form" class="ms-form" (ngSubmit)="sendPasswordReset()" novalidate>
  <div class="modal-body">
    <div *ngIf="passwordResetSent" class="card border-success">
      <div class="card-body">
        <p>A password reset email has been sent to:</p>
        <p><strong>{{email.value}}</strong></p>
        <p>Please follow the link in the email to set your new password.</p>
      </div>
    </div>
    <div *ngIf="errorMessage" class="card border-danger">
      <div class="card-body">
        {{errorMessage}}
      </div>
    </div>
    <div class="form-group row mt-3">
      <div class="col">
        <input placeholder="enter your email" class="form-control" type="email" formControlName="email" id="email" autofocus/>
        <div *ngIf="email.invalid && email.dirty" class="form-text small text-danger">
          <p *ngIf="email.errors.email">Please enter a valid email address</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="ms-button btn btn-outline-secondary" (click)="back()" id="back-login">Cancel</button>
      <button type="submit" class="ms-button btn btn-primary" [disabled]="form.invalid || passwordResetSent">
        Send reset code
      </button>
  </div>
</form>
