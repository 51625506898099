import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { WindowService } from 'app/core/window.service';
import fscreen from 'fscreen';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalPlaybackControlService } from '../../core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from '../../core/playback-controls/playback-control.service';
import { PlaybackMode } from '../../core/playback-mode.enum';
import { PoseComparisonService } from '../../core/pose-comparison.service';
import { TimecodeService } from '../../core/timecode.service';
import { ColorService } from '../services/color-service/color-service.service';

export interface timebarColors {
  playbarEventLeft: string,
  playbarEventLeftTransparent: string,
  playbarEventRight: string,
  playbarEventRightTransparent: string,
}

@Component({
  selector: 'app-clip-controls',
  templateUrl: './clip-controls.component.html',
  styleUrls: ['./clip-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TimecodeService
  ]
})
export class ClipControlsComponent implements OnInit, OnDestroy {
  protected playbackSpeed: number;
  public enableFullscreen: boolean;
  public marks: any[] = [];

  @Input() allowSelectionEditing = false;
  @Input() fullControls = false;
  @Input() globalControl = false;
  @Input() enablePlaybackSpeedController = false;
  @Input() isInsight = false;
  @Input() timebarColors: timebarColors = {
    playbarEventLeft: this.colorService.playbarEventLeft,
    playbarEventLeftTransparent: this.colorService.playbarEventLeftTransparent,
    playbarEventRight: this.colorService.playbarEventRight,
    playbarEventRightTransparent: this.colorService.playbarEventRightTransparent
  };

  private _clipId: string;
  get clipId(): string {
    return this._clipId;
  }
  @Input() set clipId(clipId: string) {
    this._clipId = clipId;
    this.timecodeService.clipId = clipId;
  }

  @Input()
  public includeEditLink = false;

  @Input()
  public includeShareButton = false;

  @Input() public minFramerate: number = 0;

  public playingClass: string = "fa fa-play";
  public timecode: string;

  private subs: Subscription[] = [];

  @Output() public share: EventEmitter<void> = new EventEmitter();

  private _fullscreen = false;
  @Output() fullscreen: EventEmitter<boolean> = new EventEmitter();

  public separateFullscreen: boolean = false;

  constructor(
    private window: WindowService,
    private changeDetector: ChangeDetectorRef,
    private timecodeService: TimecodeService,
    private playbackControl: PlaybackControlService,
    private comp: PoseComparisonService,
    private playbackGlobal: GlobalPlaybackControlService,
    private colorService: ColorService,
  ) {
    this.enableFullscreen = fscreen.fullscreenEnabled || !this.window.embedded();
  }

  public ngOnInit(): void {
    this.playbackSpeed = 1;
    this.playbackControl.setPlaybackSpeed(this.playbackSpeed);
    this.playbackGlobal.setPlaybackSpeed(this.playbackSpeed);
    const mode = this.playbackControl.mode.pipe(
      filter(mode => mode === PlaybackMode.Paused || mode === PlaybackMode.Playing)
    );

    this.subs.push(this.playbackGlobal.controlsFullscreen.subscribe((fullscreen) => {
      this.showControlsFullscreen(fullscreen);
    }));

    this.subs.push( this.comp.marks.subscribe( (marks) => {
      this.marks = [];
      for (const m of marks) {
        if (m.clipId == this.clipId)
          this.marks.push(m);
      }
    }));

    this.subs.push(mode.subscribe(mode => {
      switch (mode) {
        case PlaybackMode.Paused:
          this.playingClass = 'fa fa-play';
          break;

        default:
          this.playingClass = 'fa fa-pause';
      }
      this.changeDetector.markForCheck();
    }));

    this.subs.push(this.playbackControl.playbackTime.subscribe(time => {
      const message = { type: 'time',
                      time: time };
      this.window.postMessageToEmbedder(message, '*');

      this.timecode = this.timecodeService.getTimecode(time);
      this.changeDetector.markForCheck();
    }));
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private limitPlaybackSpeed(value): number {
    if (value <= 0.0625)
      return 0.0625;
    if (value >= 1)
      return 1;
    return parseFloat(value);
  }

  protected onPlaybackSpeedChange(): void {
    this.playbackSpeed = this.limitPlaybackSpeed(this.playbackSpeed);
    this.playbackControl.setPlaybackSpeed(this.playbackSpeed); // changes playbackspeed of timebar and 3dplayer
    this.playbackGlobal.setPlaybackSpeed(this.playbackSpeed); // change playbackspeed of video
  }

  public playPause(): void {
    this.playbackControl.togglePlayPause();
  }

  public shareOnSocial(): void {
    this.share.emit();
  }

  showControlsFullscreen(fullscreen: boolean): void {
    this.separateFullscreen = fullscreen;
    this.changeDetector.markForCheck();
  }

  public toggleFullscreen(): void {
    this._fullscreen = !this._fullscreen;
    this.fullscreen.emit(this._fullscreen);
  }
}
