import { Injectable } from '@angular/core';
import { AdditionalDataService } from 'app/core/additional-data.service';
import { ClipUploaderService, CreatedClipWithData } from 'app/core/clip-uploader.service';
import { ConditionsService } from 'app/projects/conditions.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { DirectoryOptions } from 'app/projects/project-info/project-info-data.service';
import { DirectoryUploadService, FileWrapper } from './directory-upload.service';
import { Trial } from "./vicon-directory.service";

@Injectable({
  providedIn: 'root'
})
export class TheiaDirectoryService extends DirectoryUploadService {
  private files: FileWrapper[];
  private options: DirectoryOptions = {
    filesFilter: ['.avi','.settings.xml'],
    requireC3D: false,
    requireData: true,
    dataFileFormat: 'TheiaKinematics.c3d',
  };

  constructor(
    private readonly clipUploader: ClipUploaderService,
    private readonly additionalData: AdditionalDataService,
    private conditionsService: ConditionsService
  ) {
    super();
  }

  public getProviderName(): string {
    return 'Theia';
  }

  /**
   * Sets the files for the service. These files are all the files of the
   * directory that was dropped.
   * @param newFiles - An array of files to set.
   */
  public setFiles(newFiles: File[]): void {
    this.files = this.fileWrap(newFiles);
  }

  /**
   * Not necessary for theia but implementation is required as we are extending abstract class DirectoryUploadService.
   * @param options
   * @returns
   */
  public setOptions(options: DirectoryOptions): void {
    // No options to set for THEIA, use default options
    return;
  }

  public getOptions(): DirectoryOptions {
    return this.options;
  }

  /**
   * Returns a list of trials. A THEIA directory import can have multiple trials. There is no information about conditions,
   * so they are grouped together into a hard-coded "THEIA" condition.
   * @returns a list of trials
   */
  public async getTrials(): Promise<Trial[]> {
    const trials: Trial[] = [];
    const conditions = await this.groupFiles();
    for (const conditionName in conditions) {
      const listTrialsEvaluated: String[] = [];
      const condition = conditions[conditionName];
      for (const index in condition) {
        const trial = condition[index];
        
        //Remove the file extension
        let trialName = trial.name.split('.')[0];

        //Keep only the first part of the trial name (i.e. trial name)
        trialName = trialName.split('_')[0];

        //If the trial has already been evaluated, skip it
        if (listTrialsEvaluated.includes(trialName)) {
          continue;
        }

        //Check if the trial has a video file
        let hasVideo = [];
        if (this.options.filesFilter.length > 0) {
          // Check if the trial with that name has a video file with the correct extension in the filesFilter array
          hasVideo = Object.values(condition).filter((file) => file["name"].startsWith(trialName) && this.options.filesFilter.some((videoExt) => file["name"].endsWith(videoExt)));
        }

        //Check if the trial has a charts file
        let hasCharts = [];
        if (this.options.dataFileFormat !== undefined) {
          // Check if the trial with that name has a charts file with the correct extension with the dataFileFormat
          hasCharts = Object.values(condition).filter((file) => file["name"].startsWith(trialName) && file["name"].endsWith(this.options.dataFileFormat));
        }

        listTrialsEvaluated.push(trialName);

        trials.push({
          name: trialName,
          notes: '',
          condition: conditionName,
          forcePlates: '',
          plane: '',
          side: '',
          hasCharts: hasCharts.length > 0,
          hasVideo: hasVideo.length > 0,
          date: new Date(Date.UTC(2000, 1, 1, 0, 0, 0)),
        });
      }
    }
    return trials;
  }

  /**
   * Groups the files by condition and trials according to the THEIA requirements.
   * @returns
   */
  private async groupFiles() {
    // Step 1: Filter files to keep only the relevant ones
    const filteredFiles = this.files.filter((file) => {
      return (
        file.name.includes("Gait Markerless ") &&
        (file.name.endsWith(this.options.dataFileFormat) ||
        this.options.filesFilter.some((filter) => file.name.endsWith(filter)))
      );
    });

    // Step 2: Group files by condition
    const conditions = filteredFiles.reduce((conditions, file) => {
      let conditionPath = file.path;
      let conditionName = null;
      conditionPath = conditionPath.replace(/^\/|\/$/g, ''); // removes leading or trailing slashes
      const subPaths = conditionPath.split('/');

      switch (subPaths.length) {
        case 1:
          conditionName = subPaths[0];
          break;
        case 2:
          conditionName = subPaths[subPaths.length - 1];
          break;
        default:
          break;
      }

      if (conditionName !== null) {
        if (!conditions[conditionName]) {
          conditions[conditionName] = [];
        }
        conditions[conditionName].push(file);
      }

      return conditions;
    }, {});

    return conditions;
  }


  /**
   * Uploads the data:
   * - Creates the mocapClips to hold data for each trial
   * - Uploads data for each trial (mocapclip)
   * @param session
   */
  public async upload(session: Session): Promise<void> {
    const clipsToCreate: Promise<CreatedClipWithData>[] = [];
    for (const trial of await this.getTrials()) {
      const metadata = {
        title: trial.name,
        projectPath: `${session.projectPath}${trial.condition || 'Trials'}/`,
        description: trial.notes,
      };
      clipsToCreate.push(this.clipUploader.createClip(session.project.name, metadata).toPromise());
      // Throttle down clips creation request by allowing at least 0.5s to pass. This avoids sending
      // all requests at once.
      await this.delay(500);
    }

    try {
      const data = await this.groupFiles();
      for await (const createdClip of clipsToCreate) {
        const filesToUpload : FileWrapper[] = [];
        const clip = createdClip.data.createClips.response[0].mocapClip;
        const splittedProjectPath = this.conditionsService.splitProjectPath(clip.projectPath);
        for (var fileToUpload of data[splittedProjectPath.conditionName]) {
          if (fileToUpload.name.startsWith(clip.title)) {
            filesToUpload.push(fileToUpload)
          }
        }  
        await this.additionalData.uploadMultipleAdditionalDataWithoutType(clip.id, filesToUpload).toPromise();
      }
    } catch (clipOrDataCreationError) {
      // for..await will throw on the first clip creation exception and won't continue the process.
      // Errors on additional data creation are not currently blocking.
      console.error('Error while creating clip or data');
      console.debug('Error while creating clip or data', clipOrDataCreationError);
    }
  }

  // returns the list of files to upload
  public async getExpectedUploads(): Promise<File[]> {
    const expectedUploads: File[] = [];
    const data = await this.groupFiles();
    for (const condition_name in data) {
      const condition = data[condition_name];
      for (var trial_name of condition) {
        expectedUploads.push(trial_name);
      }
    }
    return expectedUploads;
  }

  /**
   * Loops through current trials and generate a list of the current conditions sorted by date
   * @see #2185 for details on logic
   */
  public async getSortedConditions(): Promise<string[]> {
    const trials = await this.getTrials();
    const conditionsDates = new Map<string, Date>();

    // Map each condition with the earliest trial's date it contains
    for (const trial of trials) {
      if (!conditionsDates.get(trial.condition)) {
        conditionsDates.set(trial.condition, trial.date);
      } else if (conditionsDates.get(trial.condition) > trial.date) {
        conditionsDates.set(trial.condition, trial.date);
      } else {
        continue;
      }
    }

    // Sort the mapped condition by date and return their names only, as an array
    const sortedConditions = [...conditionsDates.entries()].sort((c1, c2) => {
      return c1[1] < c2[1] ? -1 : 1;
    });
    const sortedConditionsArray = sortedConditions.map(tuple => {
      return tuple[0];
    });

    return sortedConditionsArray;
  }
}
