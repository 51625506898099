import { Injectable } from '@angular/core';

export interface ProjectSessionCondition {
  projectName: string;
  sessionName: string;
  conditionName: string;
}

export interface Condition {
  path?: string;
  fullPath?: string;
  clips?: any[];
  norms?: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ConditionsService {

  constructor() { }

  // This function makes the assumptions about how we store trials in the
  // projectPath explicit. We expect all new trials to be stored as
  // /projectName/sessionName/conditionName/. So we expect the split to always
  // be exactly 5 long. Sometimes the trailing / is missing, then the split will
  // be length 4. The else case is there to handle any old trials which do not
  // have projectPath stored as expected.
  public splitProjectPath(projectPath: string): ProjectSessionCondition {
    const split = projectPath.split('/');
    if (split.length >= 4) {
      return {
        projectName: split[1],
        sessionName: split[2],
        conditionName: split[3]
      };
    } else {
      return undefined;
    }
  }

  public formattedProjectPath(projectPath: string): string {
    const split = this.splitProjectPath(projectPath);
    return `${split.sessionName} > ${split.conditionName} > `;
  }

  getConditionsFromSession(session, conditions: Condition[]) {
    const sessionPath = session.projectPath;
    for (const c of session.clips) {
      const clipPath = c.projectPath.split(sessionPath);
      if (clipPath.length > 0) {
        const conditionPath = clipPath[1];
        let conditionFound = false;
        for (const condition of conditions) {
          if (condition.path == conditionPath) {
            condition.clips.push(c);
            conditionFound = true;
            break;
          }
        }
        if (!conditionFound) {
          conditions.push({ path: conditionPath, fullPath: sessionPath + conditionPath, norms: [], clips: [c] });
        }
      }
    }

    for (const n of session.norms) {
      let normPath = '';
      if (n.projectPath)
        normPath = n.projectPath.split(sessionPath);
      if (normPath.length > 0) {
        const conditionPath = normPath[1];
        let conditionFound = false;
        for (const condition of conditions) {
          if (condition.path == conditionPath) {
            condition.norms.push(n);
            conditionFound = true;
            break;
          }
        }
        if (!conditionFound) {
          conditions.push({ path: conditionPath, clips: [], norms: [n] });
        }
      }
    }
  }
}
