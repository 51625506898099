import { Location } from '@angular/common';
import {
  Component, Input, OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { OrganizationService } from 'app/shared/services/organization/organization.service';
import { url as gravatar } from 'gravatar';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AuthService, AuthState, SignUpDetails } from './auth.service';
import { UsernameValidatorService } from './username-validator.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public form: UntypedFormGroup;
  public avatarFile: File;

  private subs: Subscription[] = [];
  private modal: BsModalRef;
  public completeOnly: boolean = false;
  //public redeemForm: boolean = false;
  public signUpPending: boolean = false;

  @ViewChild('signUpModal', { static: true }) signUpModal: TemplateRef<any>;

  @Input() emailAddress: string = '';
  defaultName: string;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private userValidator: UsernameValidatorService,
    private location: Location,
    private readonly orgService: OrganizationService,
  ) {
    this.form = this.fb.group({
      email: [
        this.emailAddress,
        [
          Validators.email,
        ]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8)
        ]
      ],
      username: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\w+$/),
          Validators.maxLength(32)
        ]),
        this.userValidator.userDoesNotExist()
      ],
      displayName: [
        '',
        [
          Validators.required,
          Validators.maxLength(32)
        ]
      ],
      termsAndConditions: [
        false,
        Validators.requiredTrue
      ],
      avatar: [
        '',
        Validators.required
      ]
    });

    this.subs.push(this.email.valueChanges.pipe(
      filter(() => this.username.pristine),
      debounceTime(100)
    ).subscribe(email => {
      this.username.setValue(email.substring(0, email.indexOf('@')).replace(/\W/g, "_"));
    }));

    this.subs.push(this.email.valueChanges.pipe(
      filter(() => this.avatar.pristine),
      debounceTime(100)
    ).subscribe(email => {
      this.avatar.setValue(gravatar(email.split('@')[0], { size: '256', protocol: 'https', d: 'identicon' }, true));
    }));

    this.subs.push(this.username.valueChanges.pipe(
      filter(() => this.displayName.pristine),
      debounceTime(100)
    ).subscribe(username => {
      if (!this.defaultName)
        this.displayName.setValue(username);
    }));
  }

  ngOnInit() {
    this.authService.setSignUpRef(this);
    this.authService.state.subscribe(state => {
      //this.redeemForm = false;

      if (state === AuthState.UserDetailsMissing &&
        !/^\/legal\//.test(this.location.path())) {
        this.emailAddress = this.authService.getAuthenticatedUserEmail();
        const emailAddress = this.emailAddress;
        // If on an enterprise domain, skip email validations and just complete your data
        this.completeOnly = !this.orgService.onEnterpriseDomain();

        const defaultName = this.defaultName ? this.defaultName : emailAddress.substring(0, emailAddress.indexOf('@')).replace(/\W/g, '_');
        this.form.patchValue({
          email: emailAddress,
          password: 'xxxxxxxx',
          displayName: defaultName,
          username: defaultName,
          termsAndConditions: false,
          avatar: gravatar(emailAddress.split('@')[0], { size: '256', protocol: 'https', d: 'identicon' }, true)
        });
        this.showModal();
      }

      if (state === AuthState.RedeemInvitation) {
        this.emailAddress = this.authService.invitationInfo.email;
        this.completeOnly = false;
        //this.redeemForm = true;
        this.showModal();
      }
    });
  }

  public showModal(email?: string, name?: string) {

    if (email)
      this.emailAddress = email;

    if (name) {
      this.defaultName = name;
      this.displayName.setValue(name);
    }

    if (!this.completeOnly)
      this.password.reset();

    this.termsAndConditions.reset();
    const template = this.signUpModal;
    this.modal = this.modalService.show(template, {
      class: 'website-modal modal-md',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }
  get username() { return this.form.get('username'); }
  get displayName() { return this.form.get('displayName'); }
  get termsAndConditions() { return this.form.get('termsAndConditions'); }
  get avatar() { return this.form.get('avatar'); }

  handleSignup(userDetails: SignUpDetails, sendVerification: boolean = false) {
    this.authService.completeSignup(userDetails).then(() => {
      if (!this.authService.getVerificationState() && sendVerification)
        this.authService.sendVerification();

      if (this.authService.invitationInfo && this.authService.invitationInfo.redirectUrl) {
        this.authService.removeOutletAndNavigate(this.authService.invitationInfo.redirectUrl);
        this.authService.invitationInfo.redirectUrl = undefined;
      }
      this.signUpPending = false;
      this.hideModal();
      this.authService.redirectToApp();
    });
  }

  submit() {
    const userDetails = this.form.value as SignUpDetails;
    this.signUpPending = true;

    /*if(!this.completeOnly)
      userDetails.beta = true;

    if(this.redeemForm) {
      this.authService.changePassword(this.authService.tempPassword, userDetails.password).then( () => {
        this.handleSignup(userDetails);
      });
    }
    else*/
    if (this.completeOnly) {
      this.handleSignup(userDetails, true);
    } else {
      userDetails.beta = true;
      this.authService.signUp(userDetails, this.completeOnly).then(user => {
        this.handleSignup(userDetails);
      }, err => {

        this.signUpPending = false;
        switch (err.code) {
          case 'auth/email-already-in-use':
            this.email.setErrors({'inUse': err.message});
            break;

          case 'auth/invalid-emailAddress':
            this.email.setErrors({'email': err.message});
            break;

          case 'auth/weak-password':
            this.password.setErrors({'weak': err.message});
            break;
        }
      });
    }
  }

  cancel() {
    this.authService.cancelLogin();
    this.hideModal();
  }

  private hideModal() {
    this.modal.hide();
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
