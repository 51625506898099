import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public collapseLeftSidebar: boolean = false;

  public toggleLeftSidebar(): void {
    this.collapseLeftSidebar = !this.collapseLeftSidebar;
  }

  public SetCollapseLeftSidebar(value: boolean): void {
    this.collapseLeftSidebar = value;
  }
}
