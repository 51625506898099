const THREE = require('three');
SkeletonHelper2.prototype = Object.create( THREE.LineSegments.prototype );
SkeletonHelper2.prototype.constructor = SkeletonHelper2;
SkeletonHelper2.prototype.setBodyPart = setBodyPart;

SkeletonHelper2.prototype.updateMatrixWorld = function () {

	var vector = new THREE.Vector3();

	var boneMatrix = new THREE.Matrix4();
	var matrixWorldInv = new THREE.Matrix4();

	return function updateMatrixWorld( force ) {

		this.iteration += 1;

		var bones = this.bones;

		var geometry = this.geometry;
		var position = geometry.getAttribute( 'position' );

		matrixWorldInv.getInverse( this.root.matrixWorld );

		for ( var i = 0, j = 0; i < bones.length; i ++ ) {

			var bone = bones[ i ];

			if ( bone.parent && bone.parent.isBone ) {

				boneMatrix.multiplyMatrices( matrixWorldInv, bone.matrixWorld );
				vector.setFromMatrixPosition( boneMatrix );
				position.setXYZ( j, vector.x, vector.y, vector.z );

				boneMatrix.multiplyMatrices( matrixWorldInv, bone.parent.matrixWorld );
				vector.setFromMatrixPosition( boneMatrix );
				position.setXYZ( j + 1, vector.x, vector.y, vector.z );
				j += 2;

			}

		}

		geometry.getAttribute( 'position' ).needsUpdate = true;
		THREE.Object3D.prototype.updateMatrixWorld.call( this, force );

	};

}();

function highlightBoneInHierarchy(bone, hierarchy, k, colorBuffer, color1)
{
	for(var j=0; j<hierarchy.length; j++)
	{
		if (hierarchy[j] != undefined)
		{
			if (hierarchy[j].bone.name == bone.parent.name)
			{
				colorBuffer.setXYZ(k, color1.r, color1.g, color1.b);
				colorBuffer.setXYZ(k + 1, color1.r, color1.g, color1.b);
			}

			if(j == hierarchy.length-1)
			{
				for(var kk=0; kk<hierarchy[j].children.length; kk++)
				{
					if ((hierarchy[j].children[kk].name == bone.parent.name) || (hierarchy[j].bone.name == bone.parent.name))
					{
						colorBuffer.setXYZ(k, color1.r, color1.g, color1.b);
						colorBuffer.setXYZ(k + 1, color1.r, color1.g, color1.b);
					}
				}
			}
		}
	}
}

function setBodyPart(skeletonIds)
{
	this.leftArm = skeletonIds.leftArm;
	this.leftLeg = skeletonIds.leftLeg;
	this.rightArm = skeletonIds.rightArm;
	this.rightLeg = skeletonIds.rightLeg;
	this.spine = skeletonIds.spine;

	var geometry = this.geometry;
	var color = geometry.getAttribute( 'color' );
	var bones = this.bones;

	var leftArm = this.leftArm;
	var leftLeg = this.leftLeg;
	var rightArm = this.rightArm;
	var rightLeg = this.rightLeg;
	var spine = this.spine;

	var color1 = new THREE.Color( 0, 0, 1 );
	var color2 = new THREE.Color( 0, 1, 0 );

	this.iteration = 0;
	//leftLeg = [1, 2, 3];
	for (var i = 0, k = 0; i < bones.length; i ++ )
	{
		boneId = i;
		nextBoneId = i+1;
		bone = bones[i];

		if (bone.parent && bone.parent.isBone ) {

			highlightBoneInHierarchy(bone, leftArm, k, color, color1);
			highlightBoneInHierarchy(bone, leftLeg, k, color, color1);
			highlightBoneInHierarchy(bone, rightArm, k, color, color2);
			highlightBoneInHierarchy(bone, rightLeg, k, color, color2);
			highlightBoneInHierarchy(bone, spine, k, color, color2);

			k += 2
		}

	}
}

function SkeletonHelper2( object, firstBone ) {

	var fullBones = getBoneList( object );
	var bones = [];
	var geometry = new THREE.BufferGeometry();

	var vertices = [];
	var colors = [];

	var color1 = new THREE.Color( 0, 0, 1 );
	var color2 = new THREE.Color( 0, 1, 0 );

	for ( var i = firstBone; i < fullBones.length; i ++ ) {

		var bone = fullBones[ i ];
		bones.push(bone);

		if ( i > 0 && bone.parent && bone.parent.isBone ) {

			vertices.push( 0, 0, 0 );
			vertices.push( 0, 0, 0 );
			colors.push( color1.r, color1.g, color1.b );
			colors.push( color2.r, color2.g, color2.b );

		}

	}

	geometry.addAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
	geometry.addAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

	var material = new THREE.LineBasicMaterial( { vertexColors: THREE.VertexColors, depthTest: false, depthWrite: false, transparent: true } );

	THREE.LineSegments.call( this, geometry, material );

	this.root = object;
	this.bones = bones;
	this.leftLeg = [];

	this.matrix = object.matrixWorld;
	this.matrixAutoUpdate = false;


}


function getBoneList( object ) {

	var boneList = [];

	if ( object && object.isBone ) {

		boneList.push( object );

	}

	for ( var i = 0; i < object.children.length; i ++ ) {

		boneList.push.apply( boneList, getBoneList( object.children[ i ] ) );

	}

	return boneList;

}

export { SkeletonHelper2 }
