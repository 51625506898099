
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of as observableOf, timer } from 'rxjs';
import { catchError, map, switchMapTo } from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const query = gql `
  query user($username: String!){
    user(username: $username) {
      id
    }
  }
`;

@Injectable()
export class UsernameValidatorService {

  constructor(private apollo: Apollo) { }

  public userDoesNotExist(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{[key: string]: any}> => {
      return timer(300).pipe(
        switchMapTo(
          this.apollo.query({
            query: query,
            variables: {username: control.value}
          })
        ),
        catchError(err => observableOf(null)),
        map(result => result ? {'userExists': {value: control.value}} : null)
      );
    };
  }
}
