import * as THREE from 'three';
import { Cycle, DataTrack, Event, GaitParameter, GaitParameterTable, Sample } from './chart/chart.types';

export enum DataContext {
  noSide = '',
  leftSide = 'l',
  rightSide = 'r',
  bothSides = 'lr'
}

export interface VideoForceProjection {
  name: string,
  forceplate: VideoForceMap[],
  trialName?: string,
  condition?: string,
  session?: string,
  fullPath?: string
}

export interface VideoForceMap {
  name: string,
  map: Map<number, ForceOverlayCoordinate>;
}

export interface ForceOverlayCoordinate {
  x_start: number;
  y_start: number;
  x_end: number;
  y_end: number;
}

export interface CyclePoint {
  cyclePerc: number;
  cycleLabel: string;
}

interface SelectedCycle {
  start: number,
  end: number,
  side: DataContext,
  hasKinematics: boolean,
  hasEmg: boolean,
  hasKinetics: boolean,
}

export class DataHelper {

  static parseFloatVec(s: string, _delimiter?: string) {
    const delimiter = _delimiter ? _delimiter : ' ';
    return s.split(delimiter).map(parseFloat);
  }

  static addEvents(states: any, times: any, context: string, events: Event[]): void {
    for (let i = 0; i < states.length; i++) {
      const state = states[i];
      const t = times[i];
      if (state != 0) {
        let eventName = "";
        if (state == 2)
          eventName = "Foot Strike";

        if (state == 1)
          eventName = "Foot Off";

        const event = {
          context: context,
          frame: i,
          name: eventName,
          time: t
        };

        if (event) {
          events.push(event);
        }
      }
    }
  }


  static arrayToMatrix(list, elementsPerSubArray) {
    const matrix = [];
    let i, k;

    for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }

        matrix[k].push(list[i]);
    }

    return matrix;
}

  static CSVToArray( strData, strDelimiter ) {
      // Check to see if the delimiter is defined. If not,
      // then default to comma.
      strDelimiter = (strDelimiter || ",");
      // Create a regular expression to parse the CSV values.
      const objPattern = new RegExp(
          (
              // Delimiters.
              "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
              // Quoted fields.
              "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
              // Standard fields.
              "([^\"\\" + strDelimiter + "\\r\\n]*))"
          ),
          "gi"
          );


      // Create an array to hold our data. Give the array
      // a default empty first row.
      const arrData = [[]];
      // Create an array to hold our individual pattern
      // matching groups.
      const arrMatches = null;
      let strMatchedValue;
      // Keep looping over the regular expression matches
      // until we can no longer find a match.
      while (arrMatches == objPattern.exec( strData )) {
          // Get the delimiter that was found.
          const strMatchedDelimiter = arrMatches[ 1 ];
          // Check to see if the given delimiter has a length
          // (is not the start of string) and if it matches
          // field delimiter. If id does not, then we know
          // that this delimiter is a row delimiter.
          if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter) ) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push( [] );
          }
          // Now that we have our delimiter out of the way,
          // let's check to see which kind of value we
          // captured (quoted or unquoted).
          if (arrMatches[ 2 ]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[ 2 ].replace(
                new RegExp( "\"\"", "g" ),
                "\""
                );
          } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[ 3 ];
          }
          // Now that we have our value string, let's add
          // it to the data array.
          arrData[ arrData.length - 1 ].push( strMatchedValue );
      }
      // Return the parsed data.
      return ( arrData );
  }

  static guidGenerator() {
    const S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  static parseQualysisData(data: any, outputList: any[]) {

    if (!data)
      return;

    const chartTracks = [];

    for (let i=0; i< data.length; i++) {
      const d = data[i];
      const values = [];
      const labels = ['x', 'y', 'z'];
      const units = "[-]";
      if (d.model.userData.type.indexOf('data') == -1)
        continue;

        console.log(d.model.userData.type);
        const dataType = d.model.userData.type.split(':')[1];

        if (dataType.toLowerCase() != "kinematics") //current alternative is a generic "data"
          continue;

        for (let j=0; j <d.times.length; j++) {
          const value = {};
          value['time'] = d.times[j];
          for (let k=0; k<3; k++)
            value[labels[k]] = d.positions[j*3+k];
          values.push(value);
        }
      chartTracks.push(this.formatChart(values, d.name, units, labels, dataType + "#" + i));
    }
    if (chartTracks.length > 0) {
      const chartGroup = { name: "Kinematics", tracks: chartTracks };
      outputList.push(chartGroup);
    }
  }

  static formatChart(values: any, title: string, vUnit: string, dataLabels: string[], id: string, events?: any ) {
    const chartData = {
      id: id ? id : this.guidGenerator(),
      values: values,
      events: (events && events.length > 0) ? events : undefined,
      labels: {
      title: title,
      hAxis: (events && events.length > 0) ? "Cycle [%]" : 'Time [s]',
      vAxis: "Value " + vUnit,
      time: "time",
      data: {x: dataLabels[0], y: dataLabels[1], z: dataLabels[2]}
    } };
    return chartData;
  }

  static parseCSV(text: string, outputList: any[], title: string, groupName: string, id=0, location=""): void {
    // Create array of data from csv
    const dataArray = text.split(/\r?\n/).map((row: string) => row.split(','));

    let min = +Infinity;
    let max = -Infinity;
    let values = [];
    let headerRow = 0;

    // Check if the CSV comes from a DOT file
    if (dataArray.length > 1 && dataArray[1].length > 0 && dataArray[1][0].includes('DeviceTag')) {
      headerRow = 11;
    }

    if (dataArray.length < headerRow + 1) {
      return;
    }
    // find the time index
    const timeIndex = dataArray[headerRow].findIndex((i) => i.toLowerCase().includes('time'));

    if (timeIndex < 0) {
      return;
    }

    const tOffset = parseFloat(dataArray[headerRow + 1][timeIndex]);
    const timeLabel = dataArray[headerRow][timeIndex];
    let labelCounter = 0;
    let additionalDataCounter = 0;
    let chartTitle: string;

    // Iterate over each column
    for (let i = 0; i < dataArray[headerRow].length; i ++) {
      // Check if the column is time or packet -> skip
      if (dataArray[headerRow][i].toLowerCase().includes('time') || dataArray[headerRow][i].toLowerCase().includes('packet') || dataArray[headerRow][i].toLowerCase().includes('status')) continue;

      // Assign the first element of the row as label
      let label = dataArray[headerRow][i].slice();
      chartTitle = dataArray[headerRow][i].slice();
      if (label.split('_').length > 1) {
        label = label.split('_')[1].toLowerCase();
        chartTitle = chartTitle.split('_')[0];
      } else if (label.split('[').length > 1) {
        chartTitle = chartTitle.split('[')[0];
      }
      const chartTitleStr = location.length > 0 ? `${chartTitle} - loc: ${location}` : chartTitle;
      label = location.length > 0 ? label + ` - ${location}` : label;
      // Assign next label to check if we are still in the same reading or different
      const nextLabel = dataArray[headerRow][i + 1];

      // Iterate over each row starting from the row after headerRow
      for (let j = headerRow + 1; j < dataArray.length; j ++) {
        // Calculate time based on first reading
        const timeStamp = parseFloat(dataArray[j][timeIndex]) - tOffset;

        // Check if the timestamp has an invalid reading
        // If that's the case, skip that value
        if (isNaN(timeStamp)) continue;
        const valueDict = {time: timeStamp};
        // Convert time in seconds if we have 'sampleTimeFine'
        if (timeLabel.toLowerCase().includes('sampletimefine')) {
          valueDict.time  = valueDict.time / 1000000;
        }

        // Get the current value
        const value = parseFloat(dataArray[j][i]);
        if (value > max) max = value;
        if (value < min) min = value;

        if (labelCounter > 0) {
          values[additionalDataCounter][label] = value;
          additionalDataCounter ++;
        } else {
          valueDict[label] = value;
          values.push(valueDict);
        }
      }

      labelCounter ++;
      additionalDataCounter = 0;
      if (!nextLabel?.startsWith(chartTitle)) {
        const chartData: DataTrack = {
          id: 'csv_data' + "-input#" + id + '-' + chartTitleStr,
          originalId: 'csv_data' + "-input#" + id + '-' + chartTitleStr,
          values: values,
          events: undefined,
          colors: undefined,
          series: undefined,
          labels: {
            title: chartTitleStr,
            hAxis: "Time [s]",
            vAxis: "Values",
            // vLimits: [ min, max ], // auto set limites
            time: "time",
            data: this.updateLabelsFromSamples(values, 'time')
          },
          dataType: 'csv'
        };
        outputList.push(chartData);
        values = [];
        labelCounter = 0;
        additionalDataCounter = 0;
        min = +Infinity;
        max = -Infinity;
      }
    }
  }

  static parseMvnxData(text: any, globalChartTracks: any) {

    const parser = new DOMParser();
    const document = parser.parseFromString(text,"application/xml");

    const subjectEl = document.getElementsByTagName('subject')[0] as any;
    const frameRate = parseFloat(subjectEl.getAttribute('frameRate'));

    const framesEl = document.getElementsByTagName('frame') as any;
    const segmentsEl = document.getElementsByTagName('segment') as any;
    const jointsEl = document.getElementsByTagName('joint') as any;

    const segments = [];
    for (let i=0; i<segmentsEl.length; i++) {
      segments.push({ name: segmentsEl[i].getAttribute('label'), times: [], positions: [], velocities: [], accelerations: [] });
    }

    const joints = [];
    for (let i=0; i<jointsEl.length; i++) {
      joints.push({ name: jointsEl[i].getAttribute('label'), times: [], angles: [] });
    }

    const joinAngleLabels = ["Lat. Bending", "Ax. Rot.", "Flex/Ext"];
    for (let i=0; i< framesEl.length; i++) {
      const f = framesEl[i];
      if (f.getAttribute('type') != "normal")
        continue;

      const time = f.getAttribute('time') / 1000;

      let position;
      let jointsAngle;
      let velocity;
      let acceleration;

      for (const el of f.children) {
        if (el.tagName == 'velocity')
          velocity = DataHelper.parseFloatVec(el.innerHTML);

        if (el.tagName == 'jointAngle')
          jointsAngle = DataHelper.parseFloatVec(el.innerHTML);

        if (el.tagName == 'position') {
          position = DataHelper.parseFloatVec(el.innerHTML);
        }

        if (el.tagName == 'acceleration')
          acceleration = DataHelper.parseFloatVec(el.innerHTML);
      }

      for (let j=0; j < segments.length; j++) {
        segments[j].times.push(time);
        if (velocity)
          segments[j].velocities.push({time: time, x: velocity[j*3], y: velocity[j*3+1], z: velocity[j*3+2]});

        if (position)
          segments[j].positions.push({time: time, x: position[j*3], y: position[j*3+1], z: position[j*3+2]});

        if (acceleration)
          segments[j].accelerations.push({time: time, x: acceleration[j*3], y: acceleration[j*3+1], z: acceleration[j*3+2]});
      }

      if (jointsAngle) {
        for (let j=0; j <joints.length; j++) {
          joints[j].times.push(time);
          const value = {};
          value['time'] = time;
          for (let k=0; k<3; k++)
            value[joinAngleLabels[k]] = jointsAngle[j*3+k];
          joints[j].angles.push(value);
        }
      }
    }

    if (joints.length > 0) {
      const groupName = "Joints angle";
      const chartTracks = [];
      for (let i=0; i< joints.length; i++)
        chartTracks.push(DataHelper.formatChart(joints[i].angles, joints[i].name, "[deg]", joinAngleLabels, groupName + "#" + i));

      const chartGroup = { name: groupName, tracks: chartTracks };
      globalChartTracks.push(chartGroup);
    }

    /*if(segments.length > 0 && segments[0].positions.length > 0)
    {
      let chartTracks = [];
      for(let i=0; i< segments.length; i++)
        chartTracks.push(this.formatChart(segments[i].positions, segments[i].name, "[m]", ["x", "y", "z"]));

      let chartGroup = { name: "Segments position", tracks: chartTracks };
      this.chartTracks.push(chartGroup);
    }*/

    if (segments.length > 0 && segments[0].velocities.length > 0) {
      const groupName = "Segments velocity";
      const chartTracks = [];
      for (let i=0; i< segments.length; i++)
        chartTracks.push(DataHelper.formatChart(segments[i].velocities, segments[i].name, "[m/s]", ["x", "y", "z"], groupName + "#" + i));

      const chartGroup = { name: groupName, tracks: chartTracks };
      globalChartTracks.push(chartGroup);
    }

    if (segments.length > 0 && segments[0].accelerations.length > 0) {
      const groupName = "Segments acceleration";
      const chartTracks = [];
      for (let i=0; i< segments.length; i++)
        chartTracks.push(DataHelper.formatChart(segments[i].accelerations, segments[i].name, "[m/s^2]", ["x", "y", "z"], groupName + "#" + i));

      const chartGroup = { name: groupName, tracks: chartTracks };
      globalChartTracks.push(chartGroup);
    }
  }

  static parseMoxClip(text: any, clipName: string, projectPath: string, videoResolutionRatio: Record<string, number> = {default: 1}, cameraNamesForDltMoxArray: string[][] = []) {
    const parser = new DOMParser();
    const document = parser.parseFromString(text,"application/xml");

    let zUp = true;
    let invertXy = false;

    const forceData = [];
    const cameraData = [];
    const emgData = [];
    const videoFileNames: string[] = [];
    const forceProjections: VideoForceProjection[] = [];
    const selectedCycles: SelectedCycle[] = [];

    const markers = document.getElementsByTagName('marker') as any;
    const forceplate = document.getElementsByTagName('forceplate') as any;
    const dlt = document.getElementsByTagName('dlt_matrix') as any;
    const emg = document.getElementsByTagName('emg_sensor') as any;
    const vitc = document.getElementsByTagName('vitc') as any;
    const videofile = document.getElementsByTagName('videofile') as any;
    const selected_cycles = document.getElementsByTagName('selected_cycle') as any;
    const isGoat = document.getElementsByTagName('target_software').length > 0 ? document.getElementsByTagName('target_software')[0].innerHTML === 'goat' : false;
    const forceThreshold = 20;        // threshold for vertical force below which we don't calculate cop and don't provide force for projection
    const forceScale = 0.0015;        // scale factor used to normalize forces for projection
    let useMmForProjection = false;
    let tVideoOffset = 0;
    let fpCornersDefined = false;
    let showFirstForceOverlayOnly = false;

    const force_platforms = document.getElementsByTagName('force_platform') as any;
    for (const f of forceplate) {
      forceData.push( { name: f.getAttribute('label').replace('_',''), x: undefined, y: undefined, z: undefined,
        Fx: undefined, Fy: undefined, Fz: undefined,
        Mx: undefined, My: undefined, Mz: undefined, corners: [], time: undefined});
    }

    let i = 0;
    let maxAbsY = 0;
    let maxAbsZ = 0;
    for (const f of force_platforms) {
      const corners = f.getElementsByTagName('corner');
      for (const c of corners) {
        const corner = { x: parseFloat(c.children[0].innerHTML.toLowerCase())*1000, y: parseFloat(c.children[1].innerHTML.toLowerCase())*1000, z: parseFloat(c.children[2].innerHTML.toLowerCase())*1000 };
        if (forceData.length > i) {
          forceData[i].corners.push(corner);
          fpCornersDefined = true;
        }
        maxAbsY = Math.max(maxAbsY, corner.y);
        maxAbsZ = Math.max(maxAbsZ, corner.z);
      }
      i++;
    }

    for (const f of emg) {
      emgData.push( { name: f.getAttribute('name'), id: f.getAttribute('id'), values: []});
    }

    for (const cycle of selected_cycles) {
      const myCycle: SelectedCycle = {
        start: cycle.getAttribute('vitc_start') ? parseInt(cycle.getAttribute('vitc_start')) :  -1,
        end: cycle.getAttribute('vitc_end') ? parseInt(cycle.getAttribute('vitc_end')) : -1,
        side: cycle.getAttribute('side') ? cycle.getAttribute('side') == 'left' ? DataContext.leftSide : DataContext.rightSide  : DataContext.noSide,
        hasKinematics: cycle.getAttribute('kinematics') ? cycle.getAttribute('kinematics') === 'true' : false,
        hasEmg: cycle.getAttribute('emg') ? cycle.getAttribute('emg') === 'true' : false,
        hasKinetics: cycle.getAttribute('kinetics') ? cycle.getAttribute('kinetics') === 'true' : false,
      };
      selectedCycles.push(myCycle);
    }

    if (videofile && videofile.length > 0) {
      for (const v of videofile) {
        const data = v.innerHTML;
        if (data && data.length > 0) {
          const filenames = data.split('*');
          for (const filename of filenames) {
            videoFileNames.push(filename);
          }
        }
      }
    }

    if (dlt && dlt.length > 0) {
      let maxDlt = 0;
      for (const d of dlt) {
        const data = this.parseFloatVec(d.innerHTML);
        if (data.length >= 12) {
          cameraData.push(this.arrayToMatrix(data, 4));
          for (let i=0; i<3; i++) {
            maxDlt = Math.max(Math.abs(data[i]), maxDlt);
          }
        }
      }
      if (maxDlt < 100) {
        useMmForProjection = true;
      }
    }

    if (vitc && vitc.length > 0) {
      tVideoOffset = parseFloat(vitc[0].innerHTML);
      if (isNaN(tVideoOffset)) {
        tVideoOffset = 0;
      }
    }

    const markerMaterial = new THREE.MeshPhongMaterial({color: 0xeeeeee });
    const markerGeometry = new THREE.SphereGeometry(0.013, 32, 32);
    const bones = [];

    const markersData = [];
    for (const m of markers) {

      const pos = {
        x: parseFloat(m.children[0].innerHTML.toLowerCase()),
        y: parseFloat(m.children[1].innerHTML.toLowerCase()),
        z: parseFloat(m.children[2].innerHTML.toLowerCase())
      };

      const material = markerMaterial;
      const geometry = markerGeometry;

      const model = new THREE.Mesh(geometry, material);
      model.castShadow = true;
      model.position.x = pos.x;
      model.position.y = pos.y;
      model.position.z = pos.z;

      bones.push(model);

      model.userData.type = 'mMarker';
      model.name = m.getAttribute('label');
      markersData.push({ name: model.name, x: undefined, y: undefined, z: undefined, model: model} );
    }

    const forces = [];
    const charts = [];
    const barCharts: GaitParameterTable[] = [];

    const forceDataForTracks = [];
    const emgDataForTracks = [];
    const kinematicData = [];
    const momentData = [];
    const powerData = [];
    const eventData: Event[] = [];
    const spatiotemporalData: GaitParameter[] = [];

    const res = this.parseChannelsData(document, markersData, forceData, emgData, kinematicData, momentData, powerData, eventData, spatiotemporalData);
    const time = res.time;
    let duration = 0;
    if (!isNaN(time[0]) && !isNaN(time[time.length-1])) {
      duration = time[time.length - 1] - time[0];
    }

    // Determine whether zUp is true, first based on "corners", then by data
    if (maxAbsZ > 0 || maxAbsY > 0) {
      if (maxAbsZ > maxAbsY) {
        zUp = false;
      }
    } else {
      if (forceData.length > 0 && forceData[0].Fy !== undefined && forceData[0].Fz !== undefined) {
        const maxFy = forceData[0].Fy.reduce((max, v) => max >= v ? max : !isNaN(v) ? v : max, -Infinity);
        const maxFz = forceData[0].Fz.reduce((max, v) => max >= v ? max : !isNaN(v) ? v : max, -Infinity);
        if (!isNaN(maxFy) && !isNaN(maxFz) && maxFy > maxFz) {
          zUp = false;
        }
      }
    }

    if (zUp && !isGoat) {
      invertXy = true;
    }

    if (res.videoFrameRate && res.timeCodeOffset && tVideoOffset >= res.timeCodeOffset) {
      tVideoOffset = tVideoOffset - res.timeCodeOffset;
      tVideoOffset /= res.videoFrameRate;
    } else if (isGoat && res.sampleRate) {
      tVideoOffset = - tVideoOffset / res.sampleRate;
    } else {
      tVideoOffset = 0;
    }

    //let context = ['left', 'right'];
    for (const f of forceData) {
      if (f.time === undefined) {
        f.time = time;
      }
      const data: any[] = [];
      if (!f.Fx)
        continue;

      // CoP only for zUp currently!!
      const calculateCop = zUp && f.Fz !== undefined && f.Fz.length > 0 && f.Mx !== undefined && f.Mx.length > 0 && f.Fz.length === f.My.length && f.My !== undefined && f.My.length > 0 && f.Fz.length === f.My.length;

      showFirstForceOverlayOnly = !fpCornersDefined && calculateCop;

      for (let i = 0; i < f.Fx.length; i++) {
         const sample: Sample = { time: f.time[i], x: 0, y: 0, z: 0, Fx: 0, Fy: 0, Fz: 0};
        if (f.x && f.y && f.z) {
          sample.x = f.x[i]*1000;
          sample.y = f.y[i]*1000;
          sample.z = f.z[i]*1000;
        } else if (calculateCop && zUp && f.Fz[i] > forceThreshold) {
          sample.x = -f.My[i]/f.Fz[i]*1000;
          sample.y = f.Mx[i]/f.Fz[i]*1000;
          sample.z = 0;
        }
        sample.Fx = f.Fx[i];
        sample.Fy = f.Fy[i];
        sample.Fz = f.Fz[i];
        data.push(sample);
      }
      forces.push({ name: f.name, force: data, corners: f.corners });
      forceDataForTracks.push({label: f.name, values: data, corners: f.corners});
    }
    const forceTracks = this.parseMoxDataTracks(forceDataForTracks, eventData, 'time', ['Fx', 'Fy', 'Fz'], 'force', 'Force [N]', false, selectedCycles);

    if (forceTracks.length > 0) {
      charts.push({ name: 'Forces', tracks: forceTracks });
    }

    // Check if we need to apply DLT based on names or order
    let cameraNamesForDltToCheck: string[] = [];
    let hasCameraName = false;
    for (const cameraNamesForDltMox of cameraNamesForDltMoxArray) {
      if (hasCameraName) {
        break;
      }
      cameraNamesForDltToCheck = [];
      const candidateDlt = cameraNamesForDltMox.length > 0 && cameraData.length === cameraNamesForDltMox.length && cameraNamesForDltMox.length >= videoFileNames.length;
      if (candidateDlt) {
        for (let cameraName of cameraNamesForDltMox) {
          if (!cameraName.startsWith('.')) {
            cameraName = '.' + cameraName;
          }
          if (!cameraName.endsWith('.')) {
            cameraName = cameraName + '.';
          }
          hasCameraName = hasCameraName || videoFileNames.some(x => x.includes(cameraName));
          cameraNamesForDltToCheck.push(cameraName);
        }
      }
    }
    const applyDlt = hasCameraName || cameraData.length >= videoFileNames.length;

    if (applyDlt) {
      for (let iVideo = 0; iVideo < videoFileNames.length; iVideo++) {

        const videoFileName: string = videoFileNames[iVideo];
        let dltMatrix: number[][];
        if (hasCameraName) {
          // try to find DLT based on names from trial template (cameraNamesForDltToCheck)
          let iDlt = 0;
          for (const cameraNameForDlt of cameraNamesForDltToCheck) {
            if (videoFileName.includes(cameraNameForDlt)) {
              dltMatrix = cameraData[iDlt];
              break;
            }
            iDlt++;
          }
        } else {
          // use order in cameradata
          dltMatrix = cameraData[iVideo];
        }

        const projectInfo = {}

        if(projectPath !== undefined) {
          /*
            On Dashboard, the projectPath is in the format of /project/session/condition/
            We should remove the first and last slashes and split the string by slashes
          */

          const splittedProjectPath = projectPath.split('/').reverse();

          projectInfo['condition'] = splittedProjectPath[1];
          projectInfo['session'] = splittedProjectPath[2];
          projectInfo['fullPath'] = [splittedProjectPath[2], splittedProjectPath[1], clipName, videoFileName].join(' > ');
        } else {
          projectInfo['fullPath'] = videoFileName;
        }

        const forceProjection: VideoForceProjection = {
          name: videoFileName,
          forceplate: [], 
          trialName: clipName,
          ...projectInfo
        };

        if (!videoFileName || !dltMatrix) {
          continue;
        }
        for (const f of forceDataForTracks) {
          const myForcePlate: VideoForceMap = {name: f.label, map: undefined};
          const videoNameWithoutExtension = videoFileName.substring(0, videoFileName.lastIndexOf('.')) || videoFileName;
          const videoName = Object.keys(videoResolutionRatio).find(x => x === videoNameWithoutExtension || x.includes('.' + videoNameWithoutExtension + '.'));
          const videoResolutionRatioCurrent = videoName !== undefined ? videoResolutionRatio[videoName] : videoResolutionRatio.default;
          const mySamples = this.applyDltMatrix(dltMatrix, f.values, forceThreshold, forceScale, zUp, useMmForProjection, false, invertXy);
          myForcePlate.map = new Map(mySamples.map(i => [i.time, {x_start: i.x_start*videoResolutionRatioCurrent, y_start: i.y_start*videoResolutionRatioCurrent, x_end: i.x_end*videoResolutionRatioCurrent, y_end: i.y_end*videoResolutionRatioCurrent}]));
          forceProjection.forceplate.push(myForcePlate);

          if (showFirstForceOverlayOnly) {
            break;
          }
        }
        if (forceProjection.forceplate.length > 0) {
          forceProjections.push(forceProjection);
        }
      }
    }

    for (const f of emgData) {
      if (!f.values)
        continue;

      emgDataForTracks.push({label: f.name, values: f.values});
    }
    const emgTracks = this.parseMoxDataTracks(emgDataForTracks, eventData, 'time', ['value', 'processed'], 'emg', 'EMG [uV]', false, selectedCycles);  // add rms in addition to value if needed
    if (emgTracks.length > 0) {
      charts.push({ name: 'EMG', tracks: emgTracks });
    }

    const kinematicTracks = this.parseMoxDataTracks(kinematicData, eventData, 'time', ['x'], 'kinematics', 'Angle [deg]', false, selectedCycles);
    if (kinematicTracks.length > 0) {
      charts.push({ name: 'Kinematics', tracks: kinematicTracks });
    }

    const momentTracks = this.parseMoxDataTracks(momentData, eventData, 'time', ['x'], 'moments', 'Moment [Nm/kg]', false, selectedCycles);
    if (momentTracks.length > 0) {
      charts.push({ name: 'Moments', tracks: momentTracks });
    }

    const powerTracks = this.parseMoxDataTracks(powerData, eventData, 'time', ['x'], 'powers', 'Power [W/kg]', false, selectedCycles);
    if (powerTracks.length > 0) {
      charts.push({ name: 'Powers', tracks: powerTracks });
    }

    if (spatiotemporalData.length > 0) {
      const barChartData = {
        id: "barChart",
        values: [spatiotemporalData],
        labels: {
          title: ['Spatiotemporal parameters'],
        },
      };

      barCharts.push({ name: 'Spatiotemporal parameters', tracks: barChartData, expanded: false });
    }

    const tracks = [];
    for (const m of markersData) {
      const positions = [];
      if (!m.x || m.x.length !== time.length)
        continue;

      for (let i=0; i< m.x.length; i++) {
        positions.push(m.x[i]);
        positions.push(m.y[i]);
        positions.push(m.z[i]);
      }
      const track = new THREE.VectorKeyframeTrack( '.bones['+ m.name +'].position', time, positions );
      track.optimize(); //trick to remove missing / static markers
      tracks.push( track );
    }

    const skeleton = new THREE.Skeleton( bones );
    const clip = new THREE.AnimationClip( 'animation', - 1, tracks );

    return {
      skeleton: skeleton,
      markerData: { },
      forceData: forces,
      forceDataForVideo: forceProjections,
      tVideoOffset: tVideoOffset,
      chartData: charts,
      barChartData: barCharts,
      cameraData: cameraData,
      clips: [ clip ],
      duration: duration,
      events: eventData,
    };
  }

  static parseChannelsData(document: any, markersData?: any, forceData?: any, emgData?: any, kinematicData?: any, momentData?: any, powerData?: any, eventData?: Event[], spatiotemporalData?: any): any {
    let timeKey = undefined;
    let rightState = [];
    let leftState = [];
    let rightStateTime = [];
    let leftStateTime = [];
    let sampleRate: number;
    let sampleRateProcessed: number;
    let videoFrameRate: number;
    let timeCodeOffset = 0;

    const channelsEl = document.getElementsByTagName('viewer_channel') as any;
    for (const ch of channelsEl) {
      sampleRate = 100;
      sampleRateProcessed = 100;
      if (ch.children && ch.children.length >= 2) {

        let channel_label = ch.children[0];
        let raw_channel_data = ch.children[1];
        let channelAnatomicalLabel: string;
        let processed_channel_data= ch.children[1];
        for (const c of ch.children) {
          if (c.tagName === 'channel_label') {
            channel_label = c;
          }
          if (c.tagName === 'raw_channel_data') {
            raw_channel_data = c;
          }
          if (c.tagName === 'channel_anatomical_label_long') {
            channelAnatomicalLabel = c.innerHTML;
          }
          if (c.tagName === 'channel_anatomical_label_short') {
            channelAnatomicalLabel = c.innerHTML;
          }
          if (c.tagName === 'processed_channel_data') {
            processed_channel_data = c;
          }
        }

        if (raw_channel_data.children[0] !== undefined && raw_channel_data.children[0].tagName === 'sampling_frequency') {
          const sample_rate = parseFloat(raw_channel_data.children[0].innerHTML);
          if (!isNaN(sample_rate)) {
            sampleRate = sample_rate;
          }
        } else if (processed_channel_data.children[0] !== undefined && processed_channel_data.children[0].tagName === 'sampling_frequency') {
          const sample_rate = parseFloat(processed_channel_data.children[0].innerHTML);
          if (!isNaN(sample_rate)) {
            sampleRateProcessed = sample_rate;
          }
        }

        const channelName = channel_label.innerHTML;
        const channelNameLow = channel_label.innerHTML.toLowerCase();

        if (channelNameLow.indexOf('timecode') !== -1) {
          const channel_data = raw_channel_data.children[1];
          const timeCode = this.parseFloatVec(channel_data.innerHTML).filter(x => !isNaN(x));
          videoFrameRate = Math.round((timeCode[timeCode.length-1] - timeCode[0])/timeCode.length*1000);
          timeCodeOffset = timeCode[0];
        }

        if (channelNameLow.indexOf('frame') !== -1) {
          // TBD, should we store frame -> match with video?
        }

        if (channelNameLow.indexOf('timekey') != -1) {
          const channel_data = raw_channel_data.children[1];
          timeKey = this.parseFloatVec(channel_data.innerHTML);
        }

        if (channelNameLow.indexOf('right.state') != -1) {
          const channel_data = raw_channel_data.children[1];
          rightState = this.parseFloatVec(channel_data.innerHTML);
          rightStateTime = this.getTimeVec(rightState, sampleRate);
        }

        if (channelNameLow.indexOf('left.state') != -1) {
          const channel_data = raw_channel_data.children[1];
          leftState = this.parseFloatVec(channel_data.innerHTML);
          leftStateTime = this.getTimeVec(leftState, sampleRate);
        }

        if (channelNameLow.startsWith('l.') || channelNameLow.startsWith('r.') || channelNameLow.includes('.speed')) {
          const channel_data = raw_channel_data.children[1];
          const values = this.parseFloatVec(channel_data.innerHTML);
          // filters out <=0 values that are used to indicate missing data (see https://gitlab.com/moveshelf/mvp/-/issues/2788)
          const filteredValues = values.filter(item => item > 0);
          if (filteredValues.length > 0) {
            const sumOfValues = filteredValues.reduce((a, b) => a + b, 0);
            const avgValue = sumOfValues/filteredValues.length;
            let unit = '-';
            const hasContext = channelNameLow.startsWith('l.') || channelNameLow.startsWith('r.')
            const parLabel = hasContext ? channelNameLow.substr(2) : channelNameLow;
            if (parLabel.includes('cadence')) {
              unit = 'steps/min';
            } else if (parLabel.includes('speed')) {
              unit = 'm/s';
            } else if (parLabel.includes('.support') || parLabel.includes('stance.swing')) {
              unit = '%';
            } else if (parLabel.includes('.time')) {
              unit = 's';
            } else if (parLabel.includes('.length') || parLabel.includes('.width')) {
              unit = 'm';
            }
            const myParam: GaitParameter = {
              label: parLabel,
              unit: unit,
              context: hasContext ? channelNameLow[0] == 'l' ? 'Left' : 'Right' : undefined,
              values: {
                mean: avgValue
              }
            };
            spatiotemporalData.push(myParam);
          }
        }

        if (channelNameLow.indexOf('force') !== -1 || channelNameLow.indexOf('- moment') !== -1 || channelNameLow.indexOf('- cop') !== -1) {
          const channel_data = raw_channel_data.children[1];
          const data = this.parseFloatVec(channel_data.innerHTML);
          let found = false;

          let myChannelName = channelName;
          myChannelName = myChannelName.replace(/_force/gi,'');
          myChannelName = myChannelName.replace(/_moment/gi,'');
          // skip '_filtered' force tracks
          if (myChannelName.includes('_filtered')) {
            continue;
          }

          let force = undefined;
          let myFpName = myChannelName.split(' ')[0];
          if (myChannelName.toLowerCase().startsWith('fp - ')) {
            myFpName += '0';
          }
          for (const m of forceData) {
            if (myFpName.toLowerCase() === m.name.toLowerCase()) {
              force = m;
              found = true;
              break;
            }
          }
          if (!found) {
            force = { name: myFpName, x: undefined, y: undefined, z: undefined,
            Fx: undefined, Fy: undefined, Fz: undefined,
            Mx: undefined, My: undefined, Mz: undefined, time: undefined};
          }

          if (force.time === undefined && data.length > 0) {
            force.time = this.getTimeVec(data, sampleRate);
          }

          if (channelNameLow.indexOf('cop x') !== -1) {
            force.x = data;
          }

          if (channelNameLow.indexOf('cop y') !== -1) {
            force.y = data;
          }

          if (channelNameLow.indexOf('cop z') !== -1) {
            force.z = data;
          }

          if (channelName.indexOf('Fx') !== -1 || channelNameLow.indexOf('force x') !== -1) {
            force.Fx = data;
          }
          if (channelName.indexOf('Fy') !== -1 || channelNameLow.indexOf('force y') !== -1) {
            force.Fy = data;
          }
          if (channelName.indexOf('Fz') !== -1 || channelNameLow.indexOf('force z') !== -1) {
            force.Fz = data;
          }
          if (channelName.indexOf('Mx') !== -1 || channelNameLow.indexOf('moment x') !== -1) {
            force.Mx = data;
          }
          if (channelName.indexOf('My') !== -1 || channelNameLow.indexOf('moment y') !== -1) {
            force.My = data;
          }
          if (channelName.indexOf('Mz') !== -1 || channelNameLow.indexOf('moment z') !== -1) {
            force.Mz = data;
          }

          if (!found) {
            forceData.push( force );
          }
        }

        if (channelNameLow.startsWith('rotation') || channelNameLow.endsWith('progression')) {
          const channel_data = raw_channel_data.children[1];
          const values = this.parseFloatVec(channel_data.innerHTML);
          const tVec: number[] = this.getTimeVec(values, sampleRate);
          const mySamples: Sample[] = [];
          for (let i=0; i<values.length; i++) {
            mySamples.push({time: tVec[i], x: values[i]});
          }
          kinematicData.push({label: channel_label.innerHTML, values: mySamples });
        }

        if (channelNameLow.startsWith('moment')) {
          const channel_data = raw_channel_data.children[1];
          const values = this.parseFloatVec(channel_data.innerHTML);
          const tVec: number[] = this.getTimeVec(values, sampleRate);
          const mySamples: Sample[] = [];
          for (let i=0; i<values.length; i++) {
            mySamples.push({time: tVec[i], x: values[i]});
          }
          momentData.push({label: channel_label.innerHTML, values: mySamples });
        }

        if (channelNameLow.startsWith('power')) {
          const channel_data = raw_channel_data.children[1];
          const values = this.parseFloatVec(channel_data.innerHTML);
          const tVec: number[] = this.getTimeVec(values, sampleRate);
          const mySamples: Sample[] = [];
          for (let i=0; i<values.length; i++) {
            mySamples.push({time: tVec[i], x: values[i]});
          }
          powerData.push({label: channel_label.innerHTML, values: mySamples });
        }

        for (const m of markersData) {
          if (channelName.indexOf(m.name + '-X') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.x = this.parseFloatVec(channel_data.innerHTML);
            m.model.userData.type = 'Marker';
          }
          if (channelName.indexOf(m.name + '-Y') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.y = this.parseFloatVec(channel_data.innerHTML);
            m.model.userData.type = 'Marker';
          }
          if (channelName.indexOf(m.name + '-Z') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.z = this.parseFloatVec(channel_data.innerHTML);
            m.model.userData.type = 'Marker';
          }
        }

        if (channelNameLow.indexOf('voltage') !== -1 || channelNameLow.indexOf('emg') !== -1) {
          const channel_data = raw_channel_data.children[1];
          const hasRawData = channel_data !== undefined;
          let channel_data_processed = undefined;
          let emgChannelName = channelNameLow;
          if (channelNameLow.indexOf('emg') !== -1) {
            if (channelAnatomicalLabel !== undefined && channelAnatomicalLabel.length > 0) {
              emgChannelName = 'EMG-' + channelAnatomicalLabel;
              if (channelAnatomicalLabel === 'not used') {
                continue;
              }
            }
          }
          let hasProcessedData = false;
          if (processed_channel_data !== undefined && processed_channel_data.children[1] !== undefined) {
            channel_data_processed = processed_channel_data.children[1];
            hasProcessedData = true;
          }

          if (!hasRawData && !hasProcessedData) {
            continue;
          }
          let found = false;
          for (const m of emgData) {
            if (emgChannelName.indexOf(m.name.toLowerCase()) !== -1) {
              m.values = this.getEmgValues(channel_data, channel_data_processed, sampleRate, sampleRateProcessed, hasRawData, hasProcessedData);
              found = true;
              break;
            }
          }
          if (emgData.length == 0 || !found) {
            const mySamples = this.getEmgValues(channel_data, channel_data_processed, sampleRate, sampleRateProcessed, hasRawData, hasProcessedData);
            emgData.push( { name: emgChannelName, values: mySamples });
          }
        }

        for (const m of forceData) {
          const name = m.name.toLowerCase();
          if (channelNameLow.indexOf(name + ' - force x') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.Fx = this.parseFloatVec(channel_data.innerHTML);
          }
          if (channelNameLow.indexOf(name + ' - force y') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.Fy = this.parseFloatVec(channel_data.innerHTML);
          }
          if (channelNameLow.indexOf(name + ' - force z') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.Fz = this.parseFloatVec(channel_data.innerHTML);
          }
          if (channelNameLow.indexOf(name + ' - cop x') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.x = this.parseFloatVec(channel_data.innerHTML);
          }
          if (channelNameLow.indexOf(name + ' - cop y') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.y = this.parseFloatVec(channel_data.innerHTML);
          }
          if (channelNameLow.indexOf(name + ' - cop z') !== -1) {
            const channel_data = raw_channel_data.children[1];
            m.z = this.parseFloatVec(channel_data.innerHTML);
          }
        }
      }
    }

    sampleRate = sampleRate !== undefined ? sampleRate : 100;
    let time = timeKey;
    if (time === undefined) {
      const dt = 1/sampleRate;
      time = [];
      let currentTime = 0;
      if (forceData !== undefined && forceData.length > 0) {
        if (forceData[0].time !== undefined && forceData[0].time.length > 0) {
          time = forceData[0].time;
        } else if (forceData[0]?.Fx !== undefined) {
          for (let i = 0; i<forceData[0].Fx.length; i++) {
            time.push(currentTime);
            currentTime += dt;
          }
        }
      }
    }

    const offset = time[0]; //remove time offset
    for (let i = 0; i<time.length; i++) {
      time[i] -= offset;
      if (isNaN(time[i]) && i > 1) //replace nan with duplicates
        time[i] = time[i-1] + 0.000001; //trick to trick the optimizer
    }

    this.addEvents(rightState, rightStateTime, "Right", eventData);
    this.addEvents(leftState, leftStateTime, "Left", eventData);

    return {time: time, sampleRate: sampleRate, videoFrameRate: videoFrameRate, timeCodeOffset: timeCodeOffset};
  }

  static applyDltMatrix(dltMatrix: number[][], data: Sample[], forceThreshold: number, forceScale: number, zUp: boolean, useMmForProjection: boolean, apply2D = false, invertXyForce = false): Sample[] {
    const outputVec: Sample[] = [];
    if (dltMatrix.length < 3) {
      return outputVec;
    }

    if (apply2D) {
      // make sure the z is disregarded
      dltMatrix[0][2] = 0;
      dltMatrix[1][2] = 0;
      dltMatrix[2][2] = 0;
    }

    const hasCoP = data.some(el => el.x !== undefined);
    let lastSampleTime: number = -0.01;
    const timeResolution = 0.01-1e-6;
    let scale = 0;

    for (let i = 0; i < data.length; i++) {
      let myVecStart: number[];
      let myVecEnd: number[];
      let verticalForce = 0;
      if (zUp) {
        verticalForce = data[i].Fz;
      } else {
        verticalForce = data[i].Fy;
      }

      if (!hasCoP) {
        data[i].x = -data[i].My/data[i].Fz*1000;
        data[i].y = data[i].Mx/data[i].Fz*1000;
        data[i].z = 0;
      }

      if (data[i].z === undefined) {
        data[i].z = 0;
      }

      const xyCorrF = invertXyForce ? -1 : 1;

      // x/y/z data is provided in mm already for 3D viewer, hence undo here.
      // also for zUp, x and y force get -sign.
      if (useMmForProjection) {
        myVecStart = [data[i].x, data[i].y, data[i].z, 1];
        myVecEnd = [myVecStart[0] + xyCorrF*data[i].Fx*forceScale*1000, myVecStart[1] + xyCorrF*data[i].Fy*forceScale*1000, myVecStart[2] + data[i].Fz*forceScale*1000, 1];
      } else {
        myVecStart = [data[i].x/1000, data[i].y/1000, data[i].z/1000, 1];
        myVecEnd = [myVecStart[0] + xyCorrF*data[i].Fx*forceScale, myVecStart[1] + xyCorrF*data[i].Fy*forceScale, myVecStart[2] + data[i].Fz*forceScale, 1];
      }

      const timeSample = Math.round(data[i].time * 100) / 100;
      const output = {time: timeSample, x_start: 0, y_start: 0, x_end: 0, y_end: 0, d_z: 0};
      if (verticalForce > forceThreshold && data[i].x !== undefined && data[i].x !== null && data[i].time > lastSampleTime + timeResolution) {
        lastSampleTime = data[i].time;
        let uStart = 0;
        let uEnd = 0;
        let vStart = 0;
        let vEnd = 0;
        let wStart = 0;
        let wEnd = 0;
        for (let j = 0; j < 3; j++) {
          let sumStart = 0;
          let sumEnd = 0;
          for (let k = 0; k < dltMatrix[j].length; k++) {
            sumStart += dltMatrix[j][k]*myVecStart[k];
            sumEnd += dltMatrix[j][k]*myVecEnd[k];
          }
          if (j == 0) {
            uStart = sumStart;
            uEnd = sumEnd;
          } else if (j == 1) {
            vStart = sumStart;
            vEnd = sumEnd;
          } else if (j == 2) {
            wStart = sumStart;
            wEnd = sumEnd;
          }
        }
        output.x_start = uStart/wStart;
        output.x_end = uEnd/wEnd;
        output.y_start = vStart/wStart;
        output.y_end = vEnd/wEnd;

        if (apply2D) {
          // assume z is up in video. Get scale of z from ratio xy (real) to uv (projected) for horizontal components
          const dVec_x = myVecEnd[0] - myVecStart[0];
          const dVec_y = myVecEnd[1] - myVecStart[1];
          const dVec_z = myVecEnd[2] - myVecStart[2];
          const dVec_u = output.x_end - output.x_start;
          const dVec_v = output.y_end - output.y_start;
          const d_xy_norm = this.normVec([dVec_x, dVec_y]);
          const d_uv_norm = this.normVec([dVec_u, dVec_v]);

          scale = Math.max(scale, d_uv_norm/d_xy_norm);
          output.d_z = dVec_z;
          // We apply scale later to make sure we always apply same scale (consider to add here to speed up...)
          // output.y_end = output.y_end - dVec_z * scale;
        }
        outputVec.push(output);
      }
    }

    for (let i = 0; i < outputVec.length; i++) {
      outputVec[i].y_end = outputVec[i].y_end - outputVec[i].d_z * scale;
    }
    return outputVec;
  }

  static normVec(v: number[]): number {
    let sumSq = 0;
    for (let i =0; i < v.length; i++) {
      sumSq += v[i]*v[i];
    }
    return Math.sqrt(sumSq);
  }

  static getEmgValues(channel_data: any, channel_data_processed: any, sampleRate: number, sampleRateProcessed: number, hasRawData: boolean, hasProcessedData: boolean): Sample[] {

    let valuesRaw = [];
    let valuesProcessed = [];
    let tVec: number[];
    let skipFactor = 1;
    let nSamples: number;
    if (hasRawData) {
      valuesRaw = this.parseFloatVec(channel_data.innerHTML);
      if (valuesRaw !== undefined && valuesRaw.length <= 1) {
        valuesRaw = this.parseFloatVec(channel_data.innerHTML, '\t'); // try with tab
      }

      nSamples = valuesRaw.length;
      tVec = this.getTimeVec(valuesRaw, sampleRate);
    }

    if (hasProcessedData) {
      valuesProcessed = this.parseFloatVec(channel_data_processed.innerHTML);
      if (valuesProcessed !== undefined && valuesProcessed.length <= 1) {
        valuesProcessed = this.parseFloatVec(channel_data_processed.innerHTML, '\t'); // try with tab
      }
      if (!hasRawData) {
        nSamples = valuesProcessed.length;
        tVec = this.getTimeVec(valuesProcessed, sampleRateProcessed);
      } else {
        skipFactor = Math.round(sampleRate/sampleRateProcessed);
      }
    }

    const mySamples: Sample[] = [];
    let iProcessed = 0;
    for (let i=0; i<nSamples; i++) {
      const myValue: Sample = {time: tVec[i]};

      if (hasRawData) {
        myValue.value = valuesRaw[i];
      }

      if (hasProcessedData) {
        if (i === 0) {
          myValue.processed = null;
        }
        if (i % skipFactor === 0 && iProcessed < valuesProcessed.length) {
          myValue.processed = valuesProcessed[iProcessed];
          iProcessed++;
        } else {
          myValue.processed = null;
        }
      }
      mySamples.push(myValue);
    }

    if (hasRawData && hasProcessedData && skipFactor > 1) {
      let lastValue = undefined;
      let deltaValue = undefined;
      let deltaFactor = 1;
      let lastStart = 0;

      let i = 0;
      for (const mySample of mySamples) {
        if (mySample.processed !== null) {
          lastValue = mySample.processed;
          deltaValue = undefined;
          lastStart = i;
          for (let j = lastStart+1; j<mySamples.length; j++) {
            if (mySamples[j].processed !== null) {
              deltaValue = mySamples[j].processed - lastValue;
              deltaFactor = 1/(j-lastStart);
              break;
            }
          }
        } else if (deltaValue !== undefined) {
          mySample.processed = lastValue + (i-lastStart)*deltaFactor*deltaValue;
        }
        i++;
      }
    }
    return mySamples;
  }

  static getTimeVec(data: number[], sampleRate: number, tOffset: number = 0): number[] {
    const tVec = [];
    let currentTime = 0;
    const timeIncrement = 1/sampleRate;
    for (let i = 0; i<data.length; i++) {
      tVec.push(currentTime + tOffset);
      currentTime = Math.round((currentTime + timeIncrement)*sampleRate)/sampleRate ;
    }
    return tVec;
  }

  static insertEvent(event: any, sortedEventList: any) {
    const length = sortedEventList.length;
    if (length == 0)
      sortedEventList.push(event);
    else {
      const e = sortedEventList[length - 1];
      if (event.time >= e.time)
        sortedEventList.push(event);
      else {
        for (let i = 0; i < length; i++) {
          const e = sortedEventList[i];
          if (event.time <= e.time) {
            sortedEventList.splice( i, 0, event);
            break;
          }
        }
      }
    }
  }

  static getCycleTimes(cycles: Cycle[], cyclesLeft?: Cycle[]): Record<string, string> {
    let mergedTrack = false;
    if (cyclesLeft) {
      mergedTrack = true;
    }
    const cycleTimes = {};
    for (let j = 0; j<cycles.length; j++) {
      const cycle = { 'time-start': cycles[j].start.time, 'time-end': cycles[j].end.time };
      if (mergedTrack) {
        cycleTimes['cycle-' + j + ' (right)'] = cycle;
      } else {
        cycleTimes['cycle-' + j] = cycle;
      }
    }

    if (mergedTrack) {
      for (let j = 0; j<cyclesLeft.length; j++) {
        const cycle = { 'time-start': cyclesLeft[j].start.time, 'time-end': cyclesLeft[j].end.time };
        cycleTimes['cycle-' + j + ' (left)'] = cycle;
      }
    }
    return cycleTimes;

  }

  static getCyclePercentageFromTime(currentTime: number, cycleTimes: Record<string, string>, dataContext?: DataContext, trackFilterString?: string): CyclePoint {
    const curCycle: CyclePoint = {cycleLabel: undefined, cyclePerc: undefined};
    let lateralityFilterString;
    if (dataContext) {
      if (dataContext === DataContext.rightSide) {
        lateralityFilterString = ' (right)';
      } else if (dataContext === DataContext.leftSide) {
        lateralityFilterString = ' (left)';
      }
    }
    let cyclePerc = -1;
    let cycleLabel;
    for (const c in cycleTimes) {
      if (lateralityFilterString && !c.split(' > ')[0].includes(lateralityFilterString)) {
        continue;
      }
      if (trackFilterString && !c.includes(trackFilterString)) {
        continue;
      }
      const cycleStart = cycleTimes[c]['time-start'];
      const cycleEnd = cycleTimes[c]['time-end'];
      const cycleDuration = cycleEnd - cycleStart;

      if (currentTime >= cycleStart && currentTime <= cycleEnd) {
        cyclePerc = (currentTime-cycleStart)/cycleDuration*100;
        cycleLabel = c;
        break;
      }
    }
    curCycle.cyclePerc = cyclePerc;
    curCycle.cycleLabel = cycleLabel;

    return curCycle;
  }

  static addDataToCycles(values: Sample[], cycles: Cycle[], dataLabels: Record<string, string>, filterStr?: string): Sample[] {
    // filterStr: optional argument that is used to filter cycles data, e.g. in case of merged track
    const filterData = filterStr !== undefined ? true : false;

    const timeLabel = "time";//labels.time;

    let lastSampleIndex = 0;
    for (let j = 0; j<cycles.length; j++) {
      const cycleStart = cycles[j].start.time;
      const cycleEnd = cycles[j].end.time;
      const cycleDuration = cycleEnd - cycleStart;

      cycles[j].samples = [];

      for (let i= lastSampleIndex; i<values.length; i++) {
        const sampleTime = values[i][timeLabel];
          if (sampleTime >= cycleStart && sampleTime <= cycleEnd) {
            const cyclePercentage = (sampleTime - cycleStart)/ cycleDuration;
            const dataSample = { time: sampleTime, perc: cyclePercentage };
            for (const p in dataLabels) {
              const label = dataLabels[p];
              if (filterData) {
                if (label.includes(filterStr)) {
                  dataSample[label] = values[i][label];
                }
              } else {
                dataSample[label] = values[i][label];
              }

            }
            cycles[j].samples.push(dataSample);
          }

          if (sampleTime >= cycleEnd) {
            lastSampleIndex = i;
            break; //go to next cycle, assumes cycles and samples are ordered in time
          }
      }
    }

    const step = 0.01;
    const samples = [];
    for (let t=0; t<= 1; t=t+step) {
      const sample = { perc: t*100 };
      const sum = { perc: t, count: 0 };
      const sumSq = { perc: t, count: 0 };
      for (const p in dataLabels) {
        sum[p] = 0;
        sumSq[p] = 0;
      }

      for (let j=0; j< cycles.length; j++) {
        const c = cycles[j];
        for (const p in dataLabels) {
          const prop = p + '-' + j;
          sample[prop] = null;
        }

        for (let k=0; k< c.samples.length; k++) { //should this start from the last index?
          const samplePerc = c.samples[k].perc;

          if ( t >= samplePerc && ((k+1) == c.samples.length || t < c.samples[k+1].perc)) {
            let nonzero = false;
            let interp = false;
            let nextSamplePerc = 0;
            let delta = 0;
            if ((k+1) < c.samples.length) {
              interp = true;
              nextSamplePerc = c.samples[k+1].perc;
              delta = 1 / (nextSamplePerc - samplePerc);
            }

            for (const p in dataLabels) {
              const prop = p + '-' + j;
              if (!interp) {
                sample[prop] = c.samples[k][dataLabels[p]];
              } else {
                sample[prop] = (c.samples[k][dataLabels[p]]*(nextSamplePerc - t) + c.samples[k+1][dataLabels[p]]*(t-samplePerc)) * delta;
              }

              if (sample[prop] != 0 && !isNaN(sample[prop])) {
                nonzero = true;
              }
            }

            if (nonzero) {
              for (const p in dataLabels) {
                const prop = p + '-' + j;
                sum[p] += sample[prop];
                sumSq[p] += sample[prop]*sample[prop];
              }
              sum.count += 1;
              sumSq.count += 1;
            }
            break;
          }

          /*if( samplePerc >= t && (c.samples.length == (k -1) || (samplePerc < t + step))) {
            for(let p in this.data.labels.data) {
              let prop = p + '-' + j;
              sample[prop] = c.samples[k][this.data.labels.data[p]];
            }
            break; //need to add sample
          }*/
        }
      }

      for (const p in dataLabels) {
        const meanLabel = 'mean';
        const stdLabel = 'std';
        if (sum.count > 0) {
          const n = sum.count;
          const mean = sum[p]/n;
          let std = 0;
          if ( n-1 > 0) {
            std = Math.sqrt( (sumSq[p] - (sum[p] * sum[p]) / n ) / (n - 1) );
          }
          sample[meanLabel] = mean;
          sample[stdLabel] = std;
        }
      }
      samples.push(sample);
    }

    return samples;
  }

  static mergeDataCycles(samplesLeft: Sample[], samplesRight: Sample[]): Sample[] {
    const nSamples = samplesLeft.length;
    const samples: Sample[] = [];

    for (let i = 0; i < nSamples; i++) {
      const sampleLeft = samplesLeft[i];
      const sampleRight = samplesRight[i];
      const keysLeft = Object.keys(sampleLeft);
      const keysRight = Object.keys(sampleRight);
      const keys = [...new Set([...keysLeft, ...keysRight])];
      const sample = {};
      for (const key of keys) {
        if (key.includes('left')) {
          sample[key] = sampleLeft[key];
        } else {
          sample[key] = sampleRight[key];
        }
      }


      samples.push(sample);
    }
    return samples;
  }
  //context = left, right or both
  static getCyclesForContext(events: Event[], context: string) {
    const cycles = [];
    const orderedEvents = [];
    for (const e of events) {
      if (e.context.toLowerCase()[0] == context) {
        this.insertEvent(e, orderedEvents);
      }
    }

    let currentCycle = undefined;
    for (const e of orderedEvents) {
      if (e.name.toLowerCase().indexOf('strike') != - 1) {
        if (currentCycle != undefined) {
          currentCycle.end = e;
          cycles.push(currentCycle);
        }

        const cycle = { start: e, end: undefined, samples: undefined };
        currentCycle = cycle;
      }
    }
    return cycles;
  }

  static selectCyclesForDataType(allCycles: any, selectedCycles: SelectedCycle[], dataContext: DataContext, groupName: string) {
    const cycles = [];
    if (selectedCycles.length === 0) {
      return cycles;
    } else {
      for (const cycle of allCycles) {
        const cycleStart = cycle.start.frame ? cycle.start.frame : -1;
        const cycleEnd = cycle.end.frame ? cycle.end.frame : -1;
        for (const selectedCycle of selectedCycles) {
          if (selectedCycle.side === dataContext && selectedCycle.start >= cycleStart && selectedCycle.end <= cycleEnd &&
            ((
              groupName.includes('kinematics') && selectedCycle.hasKinematics) || 
              (groupName.includes('emg') && selectedCycle.hasEmg) ||
              (groupName.includes('moments') && selectedCycle.hasKinetics) || 
              (groupName.includes('powers') && selectedCycle.hasKinetics) || 
              (groupName.includes('force') && selectedCycle.hasKinetics)
              )) {
                cycles.push(cycle);
                break;
          }
        }
      }
      return cycles;
    }
  }

  static getDataContext(title: string, defaultContext: string, mergedTrack?: boolean): string {
    let context = defaultContext;//"NONE"; //Context cannot be understood automatically for force

    if (mergedTrack) {
      context = DataContext.bothSides;
    } else if (title.length > 1) {
      // skip "- loc: " for context detection, which we get from csv parsing with a sensorspec
      // we check for l/r at start of string followed by capital letter OR we check left/right in title OR we check for _L/_R followed by captial OR Voltage._L/_R, OR _L/_R/ L/ R at the end of the string
      const isLeft =  this.startsWithLAndCapital(title) ||
                      this.containsLeftNotInLoc(title) ||
                      this.hasSpaceLCapital(title) ||
                      this.includesVoltageL(title) ||
                      this.endsWith_L_OrSpaceL(title);

      const isRight = this.startsWithRAndCapital(title) ||
                      this.containsRightNotInLoc(title) ||
                      this.hasSpaceRCapital(title) ||
                      this.includesVoltageR(title) ||
                      this.endsWith_R_OrSpaceR(title);

      if (isLeft)
        context = DataContext.leftSide;

      if (isRight)
        context = DataContext.rightSide;
    }
    return context;
  }

  // Check if title starts with 'L' followed by a capital letter
  static startsWithLAndCapital(title): boolean {
    return title[0].toLowerCase() === "l" && this.checkIfCap(title[1]);
  }

  // Check if title contains 'left' but not in the context '- loc: '
  static containsLeftNotInLoc(title): boolean {
    return (
      !title.toLowerCase().includes(" - loc: ") &&
      title.toLowerCase().indexOf("left") != -1
    );
  }

  // Check if there is a ' L' followed by a capital letter somewhere in the title
  static hasSpaceLCapital(title): boolean {
    const index = title.indexOf(" L");
    return (
      index !== -1 &&
      title.length > index + 2 &&
      this.checkIfCap(title[index + 2])
    );
  }

  // Check if title contains 'Voltage.L'
  static includesVoltageL(title): boolean {
    return title.indexOf("Voltage.L") !== -1;
  }

  // Check if the title ends with '_L' or ' L'.
  static endsWith_L_OrSpaceL(title): boolean {
    return (
      title.length > 2 &&
      (title.substring(title.length - 2) === "_L" ||
        title.substring(title.length - 2) === " L")
    );
  }

  // Check if title starts with 'R' followed by a capital letter
  static startsWithRAndCapital(title: string): boolean {
    return title[0].toLowerCase() === "r" && this.checkIfCap(title[1]);
  }

  // Check if title contains 'right' but not in the context '- loc: '
  static containsRightNotInLoc(title: string): boolean {
    return (
      !title.toLowerCase().includes(" - loc: ") &&
      title.toLowerCase().indexOf("right") !== -1
    );
  }

  // Check if there is a ' R' followed by a capital letter somewhere in the title
  static hasSpaceRCapital(title: string): boolean {
    const index = title.indexOf(" R");
    return (
      index !== -1 &&
      title.length > index + 2 &&
      this.checkIfCap(title[index + 2])
    );
  }

  // Check if title contains 'Voltage.R'
  static includesVoltageR(title: string): boolean {
    return title.indexOf("Voltage.R") !== -1;
  }

  // Check if the title ends with '_R' or ' R'.
  static endsWith_R_OrSpaceR(title: string): boolean {
    return (
      title.length > 2 &&
      (title.substring(title.length - 2) === "_R" ||
        title.substring(title.length - 2) === " R")
    );
  }

  static checkIfCap(letter): boolean {
    return letter === letter.toUpperCase();
  }

  static splitDataContextFromLabel(context: string, label: string): string {
    let curContext = context;
    const labelToCheck = label.split(' > ')[0];   // Cut everything after ' > ' (keep original string if ' > ' not found)
    if (context == DataContext.bothSides && labelToCheck.includes('right')) {
      curContext = DataContext.rightSide;
    } else if (context == DataContext.bothSides && labelToCheck.includes('left')) {
      curContext = DataContext.leftSide;
    }
    return curContext;
  }

  static parseMoxDataTracks(myData: any[], events: Event[], xLabel: string, dataLabels: string[], groupName: string, vAxis: string, apply_vLimits: boolean, selectedCycles: SelectedCycle[]): DataTrack[] {
    const outputTracks: DataTrack[] = [];
    let trackId = 0;
    for (let index = 0; index < myData.length; index++) {
      const trackLabel = myData[index].label;
      const trackValues = myData[index].values;
      const values: Sample[] = [];
      let min = +Infinity;
      let max = -Infinity;
      for (let i=0; i<trackValues.length; i++) {
        const value = {};
        for (const label in trackValues[i]) {
          if (label === xLabel) {
            value[label] = trackValues[i][label];
          }
          for (const dataLabel of dataLabels) {
            if (label === dataLabel) {
              value[label] = trackValues[i][label];
              if (value[label] > max) {
                max = value[label];
              }
              if (value[label] < min) {
                min = value[label];
              }
            }
          }
        }
        values.push(value);
      }

      if (values.length === 0) {
        continue;
      }

      if (groupName.includes('kinematics') && (max - min) < 40 ) {
        min -= 20;
        max += 20;
      }

      let myTitle = trackLabel;
      myTitle = myTitle.replace('Rotation ', '');
      myTitle = myTitle.replace('Moment ', '');
      myTitle = myTitle.replace('Power ', '');
      myTitle = myTitle.replace('Voltage', 'EMG');
      const mergedTrack = false;

      let chartDataType = undefined;
      if (groupName.toLowerCase().includes('emg')) {
        chartDataType = 'emg';
      } else if (groupName.toLowerCase().includes('force')) {
        chartDataType = 'force';
      }

      // First store time series (then check for cycles)
      const chartData: DataTrack = {
        id: groupName ? groupName + '-input#' + trackId : this.guidGenerator(),
        originalId: groupName ? groupName + '-input#' + trackId : this.guidGenerator(),
        values: values,
        events: undefined,
        hasCycles: false,
        labels: {
          title: myTitle,
          hAxis: 'Time [s]',
          vAxis: vAxis,
          vLimits: apply_vLimits ? [ min, max ] : undefined,
          time: xLabel,
          data: this.updateLabelsFromSamples(values, xLabel),
        },
        colors: undefined,
        series: [],
        dataType: chartDataType
      };

      outputTracks.push(chartData);

      let forceTrack = false;
      let contexts = [''];
      // See if we can extract cycles and store (add data to cycles for each label)
      if (groupName.includes('force')) {
        // handle left and right for forces!!
        contexts = ['Left', 'Right'];
        forceTrack = true;
      }

      let context: string;
      if (events.length > 0) {
        const cycleLabels = Object.keys(this.updateLabelsFromSamples(chartData.values, xLabel));
        for (let iContext = 0; iContext < contexts.length; iContext ++) {
          context = DataHelper.getDataContext(myTitle, DataContext.leftSide, mergedTrack);
          let contextStr = '';
          if (forceTrack) {
            contextStr = contexts[iContext];
            if (contexts[iContext] === 'Left') {
              context = DataContext.leftSide;
            } else {
              context = DataContext.rightSide;
            }
          }
          const cyclesAll = DataHelper.getCyclesForContext(events, context);
          const cycles = DataHelper.selectCyclesForDataType(cyclesAll, selectedCycles, context === DataContext.rightSide ? DataContext.rightSide : DataContext.leftSide , groupName);
          if (cycles.length > 0) {
            const cycleTimes = DataHelper.getCycleTimes(cycles);
            for (const dataLabel of cycleLabels) {
              const chartDataCycles  = JSON.parse(JSON.stringify(chartData)); // deep copy
              if (!forceTrack) {
                context = DataHelper.getDataContext(myTitle, DataContext.noSide, mergedTrack);
              }
              const localValues = [];
              for (let i = 0; i < values.length; i++) {
                const dataSample: Sample = {};
                dataSample[xLabel] = values[i][xLabel];
                dataSample['cycle'] = values[i][dataLabel];
                localValues.push(dataSample);
              }

              const xLabelPerc = 'perc';
              let chartId = chartDataCycles.id;
              chartId = chartId.replace('force', 'force_normalized');
              let chartTitle = chartDataCycles.labels.title;
              if (cycleLabels.length > 1) {
                if (contextStr.length > 0) {
                  chartId += '-' + contextStr + '-' + dataLabel;
                  chartTitle = contextStr + '-' + chartTitle + '-' + dataLabel;
                } else {
                  chartId += '-' + dataLabel;
                  chartTitle = chartTitle + '-' + dataLabel;
                }
              }
              if (forceTrack) {
                chartDataCycles.originalId += '-' + contextStr;  
              }
              chartDataCycles.id = chartId;
              chartDataCycles.values = DataHelper.addDataToCycles(localValues, cycles, {'cycle': 'cycle'});
              chartDataCycles.hasCycles = true;
              chartDataCycles.cycleTimes = cycleTimes;
              chartDataCycles.labels.hAxis = "Cycle [%]";
              chartDataCycles.labels.time = xLabelPerc;
              chartDataCycles.labels.data = this.updateLabelsFromSamples(chartDataCycles.values, xLabelPerc);
              chartDataCycles.labels.title = chartTitle;
              outputTracks.push(chartDataCycles);
            }
          }
        }
      }
      trackId++;
    }
    return outputTracks;
  }

  static parseMoxData(text: any, outputList: DataTrack[]) {

    const parser = new DOMParser();
    const document = parser.parseFromString(text,"application/xml");

    const chartTracks = [];

    //let subjectEl = document.getElementsByTagName('subject')[0] as any;
    //let frameRate = parseFloat(subjectEl.getAttribute('frameRate'));

    const angles = [];
    const events: Event[] = [];
    const res = this.parseChannelsData(document, [], [], [], angles, [], [], events, []);

    const groupName = 'angles';
    for (let index = 0; index < angles.length; index ++) {
      const values: Sample[] = angles[index].values;
      const valuesCycles: Sample[] = angles[index].values;
      let min = +Infinity;
      let max = -Infinity;
      for (let i=0; i< angles[index].values.length - 1; i++) {
        const value = angles[index].values[i].x;
        if (value > max)
          max = value;
        if (value < min)
          min = value;
      }

      if ( (max - min) < 40 ) {
        min -= 20;
        max += 20;
      }

      let xLabel = 'time';
      const myTitle = angles[index].label.replace('Rotation ', '');
      const mergedTrack = false;

      // First store time series (then check for cycles)
      const chartData: DataTrack = {
        id: groupName + "-input#" + index,
        values: values,
        events: undefined,
        hasCycles: false,
        labels: {
          title: myTitle,
          hAxis: 'Time [s]',
          vAxis: "Value [deg]",
          vLimits: [ min, max ],
          time: xLabel,
          data: this.updateLabelsFromSamples(values, xLabel),
        },
        colors: undefined,
        series: []
       };

      outputList.push(chartData);

      // See if we can extract cycles and store
      const chartDataCycles  = JSON.parse(JSON.stringify(chartData)); // deep copy
      chartDataCycles.values = valuesCycles;
      let context: string;
      if (events.length > 0) {
        const dataLabelsPre = this.updateLabelsFromSamples(chartDataCycles.values, xLabel);
        context = DataHelper.getDataContext(myTitle, DataContext.leftSide, mergedTrack);
        const cycles = DataHelper.getCyclesForContext(events, context);
        if (cycles.length > 0) {
          xLabel = 'perc';
          const cycleTimes = DataHelper.getCycleTimes(cycles);
          context = DataHelper.getDataContext(myTitle, DataContext.noSide, mergedTrack);
          chartDataCycles.values = DataHelper.addDataToCycles(chartDataCycles.values, cycles, dataLabelsPre);
          chartDataCycles.hasCycles = true;
          chartDataCycles.cycleTimes = cycleTimes;
          chartDataCycles.labels.hAxis = "Cycle [%]";
          chartDataCycles.labels.time = xLabel;
          chartDataCycles.labels.data = this.updateLabelsFromSamples(chartDataCycles.values, xLabel);
          outputList.push(chartDataCycles);
        }
      }
    }
  }

  static updateLabelsFromSamples(samples: Sample[], xLabel: string): Record<string, string> {
    const labelsData = {};
    // get all keys from first 1000 samples (max), flatten into array and get unique values
    const allLabels = samples.slice(0,Math.min(1000,samples.length)).map(s => Object.keys(s)).flat().filter((value, index, arr) => arr.indexOf(value) === index);
    for (const label of allLabels) {
      if (label !== xLabel) {
        labelsData[label] = label;
      }
    }
    return labelsData;
  }
}
