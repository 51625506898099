import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConditionsService } from 'app/projects/conditions.service';
import { SessionAndConditionObject, SessionService } from 'app/projects/patient-view/create-session/session.service';
import { Clip } from 'app/projects/report/report.types';
import { MediaData } from 'app/shared/multi-media-player/media-player/media-player.types';


@Component({
  selector: 'app-trial-dropdown',
  templateUrl: './trial-dropdown.component.html',
  styleUrls: ['./trial-dropdown.component.scss']
})
export class TrialDropdownComponent implements OnInit {
  @Input() tile: MediaData;
  @Input() clipList: Clip[] = [];

  @Output() public selectedVideoDropdownElement: EventEmitter<HTMLSelectElement> = new EventEmitter();
  @Output() public keyPress: EventEmitter<number> = new EventEmitter();

  // object grouping trials under conditions under sessions
  public sessionAndConditionObject: SessionAndConditionObject[] = [];
  public showNoVideoLabel: boolean = false;

  constructor(private conditionService: ConditionsService, private sessionService: SessionService) {
  }

  ngOnInit(): void {
    for (const clip of this.clipList) {
      const splittedClip = this.conditionService.splitProjectPath(clip.projectPath);
      if (splittedClip) {
        clip.projectName = splittedClip.projectName;
        clip.sessionName = splittedClip.sessionName;
        clip.conditionName = splittedClip.conditionName;
      }
    }
    const sessions = this.sessionService.groupClipsByAtrr(this.clipList, 'sessionName');
    this.sessionAndConditionObject = this.sessionService.createSessionAndConditionObject(sessions);
  }

  public changeVideo(videoDropdownElement: HTMLSelectElement): void {
    this.selectedVideoDropdownElement.emit(videoDropdownElement);
  }

  public keyPressing($event): void {
    this.keyPress.emit($event.keyCode);
    $event.preventDefault();
  }
}
