import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';



@Injectable()
export class RedirectorService {
  private _redirectUri: string;

  static readonly KEY = makeStateKey<string>('redirectUri');

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId
  ) { }

  public get redirectUri(): string {
    if (this._redirectUri) {
      return this._redirectUri;
    } else {
      return this.transferState.get(RedirectorService.KEY, null);
    }
  }

  public set redirectUri(uri: string) {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(RedirectorService.KEY, uri);
    } else {
      this._redirectUri = uri;
    }
  }
}
