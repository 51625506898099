import { Injectable, makeStateKey, TransferState } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';


const KEY = makeStateKey<number>('ui-service');

@Injectable()
export class UiControlService {

  private get headerHideCounter(): number {
    return this.state.get<number>(KEY, 0);
  }

  private set headerHideCounter(value: number) {
    this.state.set<number>(KEY, value);
  }

  private headerHideSub: BehaviorSubject<boolean>;

  constructor(private state: TransferState) {
    this.headerHideSub = new BehaviorSubject<boolean>(this.headerHideCounter > 0);
  }

  public hideHeader(): void {
    this.headerHideCounter += 1;
    if (this.headerHideCounter > 0)
      this.headerHideSub.next(true);
  }

  public showHeader(): void {
    this.headerHideCounter -= 1;
    if (this.headerHideCounter == 0)
      this.headerHideSub.next(false);
  }

  public monitorHeaderHide(): Observable<boolean> {
    return this.headerHideSub.asObservable();
  }

}
