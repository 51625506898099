import { Config } from "mixpanel-browser";

enum EnvType {
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}

const defaultDebugOptions: Partial<Config> = {
  debug: true,
  ip: true,
  opt_out_tracking_by_default: false,
  ignore_dnt: true,
};

const defaultProdOptions: Partial<Config> = {
  debug: false,
  ip: false,
  opt_out_tracking_by_default: false,
  ignore_dnt: true,
};

export interface AnalyticsConfig {
  enabled?: boolean; // True by default
  token: string; // This is not sensitive
  options: Partial<Config>;
  baseEventPayload: {
    env: EnvType,
    org: string,
  };
}

export interface ProjectConfig {
  dev?: AnalyticsConfig;
  staging: AnalyticsConfig;
  prod: AnalyticsConfig;
}

export const projectsConfig: { [key: string]: ProjectConfig } = {
  app: {
    'dev': {
      enabled: true,
      token: 'f0e07ca94a7ce5e20de97f0d4723fdef',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: null,
      }
    },
    'staging': {
      token: 'ebf717aee464c3bf287ce92d1e97ee3a',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: null,
      }
    },
    'prod': {
      enabled: true,
      token: 'dcc1609ffd4219f8c8c890b1ddcadc04',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: null,
      }
    },
  },
  app_us: {
    'dev': {
      enabled: true,
      token: '8d3502d9add846a8fbe6f628aded737a',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: null,
      }
    },
    'staging': {
      token: 'b5d23c1165d5b5f5864388e5866d92d3',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: null,
      }
    },
    'prod': {
      enabled: true,
      token: '2ff8ebd117694df98d8cd8f4b5ad4d5b',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: null,
      }
    },
  },
  internal: {
    'dev': {
      enabled: true,
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'internal',
      }
    },
    'staging': {
      token: '1095f97b4fc4012e0c78a62912c10c35',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'internal',
      }
    },
    'prod': {
      enabled: true,
      token: '7b1aaef2d7365291588b33bcc3dc9616',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'internal',
      }
    },
  },
  smk: {
    'dev': {
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'SintMaartenskliniek',
      }
    },
    'staging': {
      token: 'd0b19b044f17a23729de408561adb643',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'SintMaartenskliniek',
      }
    },
    'prod': {
      enabled: true,
      token: '6fc2e6352d61864309f58a1253a99092',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'SintMaartenskliniek',
      }
    },
  },
  mumc: {
    'dev': {
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'MUMC',
      }
    },
    'staging': {
      token: '34df041f7f59e4ea4dfd078c866080e9',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'MUMC',
      }
    },
    'prod': {
      enabled: true,
      token: '1b0c867ef528a613708d0b4efd8adfc3',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'MUMC',
      }
    },
  },
  nwz: {
    'dev': {
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'nwz',
      }
    },
    'staging': {
      enabled: false,
      token: 'redacted',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'nwz',
      }
    },
    'prod': {
      enabled: false,
      token: 'redacted',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'nwz',
      }
    },
  },
  shriners: {
    'dev': {
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'shriners',
      }
    },
    'staging': {
      enabled: true,
      token: 'af7d4bad215e768820bd19216eaf2490',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'shriners',
      }
    },
    'prod': {
      enabled: true,
      token: 'a8082e426494cee1ed8d6a99bfd3743a',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'shriners',
      }
    },
  },
  umcg: {
    'dev': {
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'umcg',
      }
    },
    'staging': {
      enabled: true,
      token: 'fd94e976087e5b41cac51496422a4c7e',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'umcg',
      }
    },
    'prod': {
      enabled: true,
      token: '4c0099e1378ef9c64fc36a572fa08906',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'umcg',
      }
    },
  },
  roessingh: {
    'dev': {
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'roessingh',
      }
    },
    'staging': {
      enabled: true,
      token: 'd7576e36aa8f536f6487496af6e67d52',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'roessingh',
      }
    },
    'prod': {
      enabled: true,
      token: '5b3d203eaccab2026cf1b5f24f96a847',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'roessingh',
      }
    },
  },
  aumc: {
    'dev': {
      enabled: true,
      token: '05172ae48c7b7b6ebb584c948087f587',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.DEV,
        org: 'aumc',
      }
    },
    'staging': {
      enabled: true,
      token: '6dcbf9200342b53e0791c05b7cc93723',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'aumc',
      }
    },
    'prod': {
      enabled: true,
      token: '50482a0e58b01176bb666c09e2ecf81f',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'aumc',
      }
    },
  },
  hva: {
    'staging': {
      enabled: true,
      token: '0a2bc7e563c45a4affd0cb54f109a1d6',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'hva',
      }
    },
    'prod': {
      enabled: true,
      token: '2e1fae3af36c4c2e13f7cec989a0bd18',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'hva',
      }
    },
  },
  treant: {
    'staging': {
      enabled: true,
      token: '66450e56bbd307b5659b942077d93a2a',
      options: defaultDebugOptions,
      baseEventPayload: {
        env: EnvType.STAGING,
        org: 'treant',
      }
    },
    'prod': {
      enabled: true,
      token: '7c13fba3383392bd125e5fc0edd3b032',
      options: defaultProdOptions,
      baseEventPayload: {
        env: EnvType.PROD,
        org: 'treant',
      }
    },
  },
};
