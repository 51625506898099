import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AnalyticEvent, AnalyticsService } from 'app/shared/services/analytics';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { checkOrganization } from '../../shared/queries';
import { OrganizationPayload, OrganizationService } from '../../shared/services/organization/organization.service';
import { AuthService, AuthState } from '../auth.service';
import { AvailableSsoProviders, availableSsoProvidersInfo } from './sso.details';



@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  public errorMessage: string;
  public loading: boolean;
  public showPasswordReset: boolean = false;
  inviteSize: string = "sm";
  public form: UntypedFormGroup;
  title: string = "Login";

  authState: AuthState;
  selectedGuestAccount;
  availableGuestAccount;
  availableSSO: string[] = [];
  public availableOrganization = false;
  public inactive = false;

  @ViewChild('loginDialog', { static: true })
  private modalTemplate: TemplateRef<any>;
  private modal: BsModalRef;
  public hideSub: Subscription;
  public readonly availableSsoProvidersInfo = availableSsoProvidersInfo;
  public loginFormCollapsed: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    @Inject(PLATFORM_ID) private platformId,
    private apollo: Apollo,
    private orgService: OrganizationService,
    private readonly analyticsService: AnalyticsService,
  ) {
    this.loading = false;

    this.form = this.formBuilder.group({
      email: [
        '',
        this.usernameOrEmailValidator(Validators.email, this.externalUsernameValidator)
      ],
      password: [
        '',
        Validators.required
      ]
    });
  }

  async ngOnInit(): Promise<void> {

    if (await this.orgService.getCurrentOrganization()) {
      this.availableOrganization = true;
    }
    this.authService.state.subscribe(state => {
      this.authState = state;
      if (state === AuthState.LoginRequested) {
        this.form.reset();

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('inactive')) {
          this.inactive = true;
        }

        this.modal = this.modalService.show(
          this.modalTemplate,
          {
            ignoreBackdropClick: true,
            keyboard: false,
            class: "website-modal"
          });

        if (this.hideSub) {
          this.hideSub.unsubscribe();
        }

        this.hideSub = this.modalService.onHide.subscribe((reason: string) => {
          if (this.authState == AuthState.LoginRequested ||
            this.authState == AuthState.LoggingIn) {
            this.cancel();
          }
        });
      }
    });

    const org = await this.orgService.getCurrentOrganization();
      if (org?.guestAccount) {
        this.availableGuestAccount = org.guestAccount;
      }
      if (this.orgService.isSsoEnabled()) {
        this.availableSSO = org.sso;
        this.loginFormCollapsed = true;
        console.log('enabling sso mode');
        this.route.queryParams.subscribe(params => {
          // if `auto` is `true`, it means we want to automatically start a SSO flow
          if (params['auto'] === 'true') {
            // Get the default SSO for the current organization
            const orgDeafultSso = org.sso[0];
            const landingUrl = params['landing_url'];
            // Redirect the user to SSO immediately
            this.loginWithSso(orgDeafultSso, landingUrl);
          }
        });
      }
  }

  async getOrganization(): Promise<OrganizationPayload> {
    return await this.orgService.getCurrentOrganization();
  }

  getOrganizationLogo(): string {
    return this.orgService.getOrganizationLogo();
  }

  ngOnDestroy() {
    if (this.hideSub) {
      this.hideSub.unsubscribe();
    }
  }

  getGuestAccount() {
    this.selectedGuestAccount = this.availableGuestAccount;
    this.email.setValue(this.selectedGuestAccount);

  }

  switchToRegular() {
    this.email.setValue('');
    this.email.markAsPristine();
    this.selectedGuestAccount = "";
  }

  submitLogin(email: string, password: string) {
    this.loading = true;
    this.errorMessage = null;
    this.authService.submitLogin(email, password).then(() => {
      this.loading = false;
      this.modal.hide();

      this.authService.stableState.then((state) => {

        if (state == AuthState.LoggedIn) {
          // This is where we determine we are logged in, start analytics service
          if (this.selectedGuestAccount) {
            this.analyticsService.trackEvent(AnalyticEvent.GUEST_LOG_IN, false);
          } else {
            this.analyticsService.trackEvent(AnalyticEvent.LOG_IN, false);
          }
          const redirectUrl = this.route.snapshot.queryParamMap.get('landing_url') || 'dashboard';
          this.authService.redirectToApp(redirectUrl);
        }
      });

    }).catch((error) => {
      this.loading = false;
      switch (error.code) {
        case 'auth/user-disabled':
          this.errorMessage = 'Account disabled';
          break;

          // Treat as user not found. There is a difference between angular
          // email validation and firebase validation.
      case 'auth/invalid-email':
        case 'auth/user-not-found':
          this.errorMessage = `The email address ${email} is not registered with Moveshelf.com`;
          break;

        case 'auth/wrong-password':
          this.errorMessage = 'Incorrect password';
          break;

        default:
          this.errorMessage = 'Unknown error';
          console.log(error);
      }
    });
  }

  async logIn() {
    if (this.availableOrganization) {
      this.apollo.query<any>({
        query: checkOrganization,
        variables: {
          email: this.email.value
        }
      }).subscribe(async ({ data }) => {
        console.log(data);
        const org = await this.getOrganization();
        let loginValid = false;
        for (const o of data.organizations) {
          if (org.id == (o as any).id) {
            loginValid = true;
          }
        }
        if (loginValid) {
          let email = this.email.value;
          // if the email is external, append the domain
          if (this.checkUsernameRegex(this.email.value)) {
            email = this.email.value + '@moveshelf.com';
          }
          this.submitLogin(email, this.password.value);
        } else {
          this.errorMessage = `Invalid credentials for given organization`;
        }
      });
    } else {
      this.submitLogin(this.email.value, this.password.value);
    }
  }

  displayPasswordReset(enable: boolean) {
    if (enable) {
      this.showPasswordReset = true;
      this.title = "Reset Password";
    } else {
      this.showPasswordReset = false;
      this.title = "Login";
    }
  }

  hide() {
    this.inactive = false;
    this.modal.hide();
  }

  cancel() {
    this.authService.cancelLogin();
    this.displayPasswordReset(false);
  }

  loginWithSso(idp: string, landingUrl: string) {
    // This is where we determine we are logging in via SSO, start analytics service
    this.analyticsService.trackEvent(AnalyticEvent.SSO);
    switch (idp) {
      case AvailableSsoProviders.AZURE: this.router.navigate(
        ['/login/sso/azure'],
        { queryParams: { landing_url: landingUrl } }
      ); break;
      default: break;
    }
  }

  public isSsoAvailable(): boolean {
    return this.availableSSO?.length > 0;
  }

  get email() { return this.form.get('email'); }
  get password() { return this.form.get('password'); }

  private checkUsernameRegex(username: string): boolean {
    const usernamelRegex = /^external\.[A-Za-z0-9]{12}$/;
    return usernamelRegex.test(username);
  }

  private externalUsernameValidator = (control: FormControl): ValidationErrors | null => {
    const isValid = this.checkUsernameRegex(control.value);
    return isValid ? null : { 'customEmailInvalid': true };
  };

  private usernameOrEmailValidator(emailValidator: ValidatorFn, usernameValidator: ValidatorFn): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const resultA = emailValidator(control);
      const resultB = usernameValidator(control);

      // If one of the validators passes, return null, else return an error
      return !resultA || !resultB ? null : { 'customEmailInvalid': true };
    };
  }

}
