<div class="icon-notify pr-2 noselect">
  <div style="display: inline-block" [popover]="popTemplate" [outsideClick]="true" placement="bottom" (click)="clearNotifications()">
    <i class="fa fa-bell" style="cursor: pointer"></i>
    <span class="d-lg-none ml-2" style="cursor: pointer">Notifications</span>
  </div>
  <span [hidden]="count==0" class="badge badge-pill badge-danger badge-notify">{{count}}</span>
</div>
<ng-template #popTemplate>
    <div class="container" style="width: 300px; color: #999;">
      <div *ngIf="notifications?.length == 0" class="my-1"><i>Not much so far ... keep working!</i></div>
      <div *ngFor="let notification of notifications" class="w-100 row my-2">
          <div class="col-2 p-0 pl-1">
            <ng-container *ngIf="notification.state != 'Read'">+</ng-container>
            <img class="rounded-circle mr-2 d-inline" width="22" [src]="notification.sender.avatar"/>
          </div>
          <div class="col p-0">
            <a [routerLink]="notification.url" class="clickable-success noselect">{{notification.sender.displayName}} {{notification.message}}</a>
          </div>
        <div class="col-2 p-0 pl-2" style="margin-right: -20px; padding-right: -30px"><i>{{notification.created | date:'MMM d'}}</i></div>
      </div>
      <div class="clickable-success mt-2 mb-1" (click)="loadMore()"><i *ngIf="clipPageInfo?.hasNextPage">loadMore...</i></div>
    </div>
</ng-template>