<div *ngIf="!uploading" class="card p-2 mt-3 bg-dark-gray">
  <div *ngIf="this.getAvailableProviders().length > 1" class="provider-selector">
    <div style="margin-bottom: 5px; margin-right: 10px; display: flex; align-items: center;">Directory format to upload
    </div>
    <select (change)="this.enableProvider($any($event.target).value)" class="form-control py-1" style="width: 150px">
      <option *ngFor="let provider of this.getAvailableProviders()" [selected]="this.availableDirectoryProviders[provider].service.getProviderName() === this.service.getProviderName()"
        [value]="provider">{{ this.availableDirectoryProviders[provider].service.getProviderName() }}</option>
    </select>
  </div>

  <div appDndFile (acceptedFiles)="readDirectory($event)" class="upload-card clickable-success mb-0 pb-0"
    (click)="fileSelector.click()">
    <div class="m-3 text-center">
      {{folderTypeDescription}}
    </div>
  </div>

  <alert type="info" *ngFor="let message of this.messages" [dismissible]="true">{{message}}</alert>

  <div *ngIf="directoryWasRead && this.sortedConditions.length === 0" class="mt-2">
    <p>Finished reading directory, but found nothing to upload. Please make sure the directory is correct.</p>
  </div>

  <div *ngIf="directoryWasRead && this.sortedConditions.length > 0" class="mt-2">
    <p>Finished reading directory. Click upload to upload the following data:</p>

    <div *ngFor="let condition of this.sortedConditions">
      {{condition}}
      <ul>
        <li *ngFor="let trial of conditions[condition]">
          {{trial.name}}
          <app-line-chart-icon *ngIf="trial.hasCharts" class="ml-2"></app-line-chart-icon>
          <app-video-icon *ngIf="trial.hasVideo" class="ml-2"></app-video-icon>
        </li>
      </ul>
    </div>
    <div *ngIf="this.configFile && this.configDataAvailable && this.configDataAvailable.length > 0">
      Configuration file: {{this.configFile.name}}:
      <ul>
        <li *ngFor="let str of this.configDataAvailable">
          {{str}}
        </li>
      </ul>
    </div>
    <div class="d-flex">
      <div *ngIf="this.canShowUploadOptions()" style="display: flex;">
        <div style="margin-right: 10px; display: flex; align-items: center;">Upload options</div>
        <select class="form-control py-1" style="width: fit-content;" [(ngModel)]="selectedUploadOption"
          (change)="handleUploadOptionSelection()">
          <option value="{{UploadOptionEnum.NonVideosOnly}}">Upload non-videos (default)</option>
          <option value="{{UploadOptionEnum.VideosOnly}}">Upload videos</option>
          <option value="{{UploadOptionEnum.AllFiles}}">Upload both</option>
        </select>
      </div>
      <button class="btn btn-secondary ml-auto" [disabled]="uploading" (click)="reset()">Cancel</button>
      <button type="submit" class="btn btn-primary ml-2" [disabled]="uploading" (click)="upload()">
        <i class="fa fa-spinner fa-spin mr-2" *ngIf="uploading"></i> Upload
      </button>
    </div>
  </div>
</div>

<input type="file" style="display:none" (change)="readSelectedDirectoryOrFiles(fileSelector.files)" #fileSelector
  webkitdirectory multiple>