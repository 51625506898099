import { AfterViewInit, Component, DoCheck, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { LineChartMajorGroup, Tracks } from 'app/shared/chart/chart.types';
import { MultiChartDataService } from 'app/shared/multi-chart/multi-chart-data.service';
// import { Subscription } from 'rxjs';
export interface MultiChartStatus {
  errors?: string[];
  ready: boolean;
}

const MIN_CHART_HEIGHT = 165;

@Component({
  selector: 'app-splitscreen-multi-chart',
  templateUrl: './splitscreen-multi-chart.component.html',
  styleUrls: ['./splitscreen-multi-chart.component.scss']
})
export class SplitscreenMultiChartComponent implements DoCheck, AfterViewInit, OnChanges {


  public lineChartMajorGroups: LineChartMajorGroup[] = [];
  public tracks: Tracks;
  public playbackControl: PlaybackControlService;

  @Input() isReport = false;
  @Input() playhead = false;
  @Input() majorGroup: string;
  @Input() selectedChartFilter: string;
  @Input() parentRef: ElementRef;
  @Input() chartHeight: number = 100;

  @ViewChild('splitscreenMultiChart') thisElement: ElementRef;

  public loading = true;
  public lineHeight: number = 165;

  constructor(
    private multiChartDataService: MultiChartDataService,
  ) { }

  ngDoCheck(): void {
    this.lineChartMajorGroups = this.multiChartDataService.getLineChartMajorGroups();
  }

  ngOnChanges(): void {
    this.calculateElementsHeight();
  }

  ngAfterViewInit(): void {
    this.calculateElementsHeight();
  }

  public getCurrentChartGroup(): LineChartMajorGroup {
    const index = this.lineChartMajorGroups.findIndex(majorGroup => majorGroup.name === this.majorGroup);
    return this.lineChartMajorGroups[index];
  }

  private calculateElementsHeight(): void {
    if (this.thisElement && this.parentRef) {
      this.calculateHeight(this.thisElement.nativeElement.clientHeight, this.parentRef.nativeElement.clientHeight);
    }
  }

  public calculateHeight(totalHeight: number, parentHeigh: number): void {
    const index = this.lineChartMajorGroups.findIndex(majorGroup => majorGroup.name === this.majorGroup);
    // we assume max 3 charts per group
    let totalLines = 0;
    this.lineChartMajorGroups[index].groups.forEach(x => totalLines += Math.ceil(x.tracks.length/3));
    const labelSpacing = 16;
    const minThreeLinesHeight = 3 * MIN_CHART_HEIGHT + labelSpacing * 3;
    // if we have less than 3 lines, we try to fill the whole height with 2 or 1 lines
    if (totalLines < 3) {
      this.lineHeight = Math.min((parentHeigh - labelSpacing*totalLines) / totalLines, parentHeigh*0.5);
    } else {
      // if three rows at the minimum height are larger than the parent height, we are in a quarter window, so we want to fill the space with 2 lines or minimum possible height
      if (minThreeLinesHeight > parentHeigh && this.chartHeight < 100) {
        this.lineHeight = Math.max((parentHeigh - labelSpacing*2)/ 2, MIN_CHART_HEIGHT + labelSpacing);
        // in a full column case we instead want to fill the space with 3 lines or maximum possible height
      } else {
        this.lineHeight = Math.min((parentHeigh - labelSpacing*3) / 3, parentHeigh*0.5);
        this.lineHeight = Math.max(this.lineHeight, MIN_CHART_HEIGHT);
      }
    }
  }

   // This method is called whenever the window is resized.
   @HostListener('window:resize', ['$event'])
   onResize(event) {
     this.calculateElementsHeight();
   }
}
