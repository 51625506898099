import gql from 'graphql-tag';
import { RelayNode } from '../graphql-types/relay-node';

export const UserMetadataFragment = gql`
  fragment UserMetadata on User {
    id,
    displayName,
    username,
    avatar,
    organizations {
      id,
      name,
      logo,
      ssoClients{
        name
        clientId
        tenantId
        redirectUri
      },
      members {
        role,
        user {
          username
        }
      }
    }
    tier
  }
`;

export class UserMetadata extends RelayNode {
  displayName: string;
  username: string;
  avatar: string;
  tier: string;
  organizations: {
    id: string;
    name: string;
    logo: string;
    ssoClients: {
      name: string;
      clientId: string;
      clientSecret: string;
      tenantId: string;
      redirectUri: string;
    }[];
    members: Member[];
  }[];
}

export type Member = {
  role: string;
  user: {
    username: string;
  }
};
