import { Component } from '@angular/core';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-outdated-warning',
  templateUrl: './outdated-warning.component.html',
  styleUrls: ['./outdated-warning.component.scss']
})
export class OutdatedWarningComponent {

  constructor(
    private readonly authService: AuthService,
  ) { }

  public showOutdatedNotice(): boolean {
    return this.authService.isTesterAuthenticated();
  }

}
