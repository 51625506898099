import { Component, DoCheck, EventEmitter, Input, IterableDiffer, IterableDiffers, OnDestroy, OnInit, Output } from '@angular/core';
import { ChartTemplatesOverride, NormalizeKinetics } from 'app/projects/project-info/project-info-data.service';
import { Subscription } from 'rxjs';
import { GlobalPlaybackControlService } from '../../core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from '../../core/playback-controls/playback-control.service';
import { TrialTemplate, TrialTemplateService } from '../../projects/trial-template.service';
import { DataTrack, GaitParameterTable, LineChartGroup, LineChartMajorGroup, Tracks } from '../chart/chart.types';
import { DataContext } from '../data-helper';
import { LeftRightService } from '../left-right/left-right.service';
import { ChartToggleService } from '../services/chart-toggle/chart-toggle.service';
import { ChartsTemplatesService, ChartTemplate, ChartTemplateGroup, TemplateDefinition, TemplateMetadata } from '../services/charts-templates/charts-templates.service';
import { ColorService } from '../services/color-service/color-service.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { MultiChartDataService } from './multi-chart-data.service';
import { TrialChartsService, TrialTracksGroup } from './trial-charts.service';

export interface MultiChartStatus {
  errors?: string[];
  ready: boolean;
}

@Component({
  selector: 'app-multi-chart',
  templateUrl: './multi-chart.component.html',
  styleUrls: ['./multi-chart.component.scss'],
  providers: [FeatureFlagsService]
})
export class MultiChartComponent implements OnInit, OnDestroy, DoCheck {

  public lineChartMajorGroups: LineChartMajorGroup[] = [];
  kinematicTracks: TrialTracksGroup = {};
  momentTracks: TrialTracksGroup = {};
  powerTracks: TrialTracksGroup = {};
  lengthVelocityTracks: TrialTracksGroup = {};
  emgTracks: TrialTracksGroup = {};
  forceTracks: TrialTracksGroup = {};
  combinedTracks: TrialTracksGroup = {};
  private currentTemplateMetadata: TemplateMetadata;

  public multiChartTiles: Record<string,DataTrack> = {};
  private subs: Subscription[] = [];
  private parsedTrialTemplate: TrialTemplate;
  public tracks: Tracks;
  public playbackControl: PlaybackControlService;
  private _clipId: string;

  @Input() set clipId(value: string) {
    this._clipId = value;
    this.loadClip(value);
  }
  @Input() set trialTemplate(value: string) {
    this.parsedTrialTemplate = this.trialTemplateService.mergedTrialTemplateAsJSON(this.featureFlagsService.getAll(), value);
  }
  @Input() playhead = false;
  @Input() set showLineStyles(value: boolean) {
    this.trialChartsService.showLineStyles = value;
  }

  @Input() set subjectWeight(value: number) {
    this.trialChartsService.subjectWeight = value;
  }
  @Input() applyChartTemplate;
  @Input() bodyweightWarningIcon: boolean = false;
  @Input() clickers = true;

  @Output() ready: EventEmitter<MultiChartStatus> = new EventEmitter<MultiChartStatus>();

  public loading = true;

  private iterableDiffer: IterableDiffer<unknown>;

  constructor(
    private trialChartsService: TrialChartsService,
    private colorService: ColorService,
    private iterableDiffers: IterableDiffers,
    private trialTemplateService: TrialTemplateService,
    private playbackGlobal: GlobalPlaybackControlService,
    private featureFlagsService: FeatureFlagsService,
    private readonly templateService: ChartsTemplatesService,
    private chartToggleService: ChartToggleService,
    public leftRightService: LeftRightService,
    private multiChartDataService: MultiChartDataService,
  ) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
    this.trialChartsService.applyAveragePerCondition = false;
    this.trialChartsService.isConditionSummary = false;
    this.trialChartsService.allowConditionAvgToggle = false;
    const normalizeKinetics = this.featureFlagsService.get('normalizeKinetics') as NormalizeKinetics;
    this.trialChartsService.normalizeToBodyWeight = false;
    this.trialChartsService.normalizeToBodyMassInsteadOfWeight  = false;
    if (normalizeKinetics?.enabled) {
      this.trialChartsService.normalizeToBodyWeight = normalizeKinetics?.enabled;
      if (normalizeKinetics.normalizeToBodyMassInsteadOfWeight && normalizeKinetics.normalizeToBodyMassInsteadOfWeight === true ) {
        this.trialChartsService.normalizeToBodyMassInsteadOfWeight = true;
      }
    }
  }

  ngOnInit(): void {
    this.subs.push(this.playbackGlobal.playbackControl.subscribe(
      (service: PlaybackControlService) => {
        this.playbackControl = service;
      })
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subs) {
      s.unsubscribe();
    }
    this.chartToggleService.resetStatus();
  }

  ngDoCheck(): void {
    const changes = this.iterableDiffer.diff(this.tracks?.charts);
    if (!changes) {
      return;
    }
    this.lineChartMajorGroups = [];
    this.kinematicTracks = {};
    this.momentTracks = {};
    this.powerTracks = {};
    this.lengthVelocityTracks = {};
    this.emgTracks = {};
    this.forceTracks = {};
    this.combinedTracks = {};
    let hasLeftData = false;
    let hasRightData = false;
    this.trialChartsService.timeSeriesMergeWarning = '';
    this.trialChartsService.showAveragesForCycles = false;
    this.trialChartsService.selectedClipPath = undefined;
    this.currentTemplateMetadata = this.templateService.setInitialChartsTemplate(this.featureFlagsService.get('overrideChartTemplates') as ChartTemplatesOverride, this.featureFlagsService.get('availableChartsTemplates') as TemplateDefinition[] ?? []);

    this.trialChartsService.extractForcePlateContext(this.tracks?.charts, this.parsedTrialTemplate);

    let filteredCharts = this.trialTemplateService.filterCharts(this.tracks?.charts, this.parsedTrialTemplate, this.trialChartsService.getChartMode());
    const createCopies = this.featureFlagsService.get('enableCombinedChart') === true;
    const chartsCopy: LineChartGroup[] = createCopies ? JSON.parse(JSON.stringify(filteredCharts)) : [];   // deep copy

    let index: number;
    if (this.applyChartTemplate) {
      // Do the new data handling for 'moments' + 'powers' + 'emg' + 'forces', others to be included later
      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'Kinematics');
      if (index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        filteredCharts[index].tracks = this.trialChartsService.prepareTrackData(filteredCharts[index], this.applyChartTemplate, this.parsedTrialTemplate);
        if (createCopies) {
          chartsCopy[index].tracks = JSON.parse(JSON.stringify(filteredCharts[index].tracks)); // deep copy
        }
        if (this.applyChartTemplate && filteredCharts[index].tracks.length > 0) {
          this.trialChartsService.parseTracks(filteredCharts, index, this.kinematicTracks, this.templateService.getTemplateFor('kinematicsCharts'), filteredCharts[index].name, true, false);
        }
      }

      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'Moments');
      if (index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        filteredCharts[index].tracks = this.trialChartsService.prepareTrackData(filteredCharts[index], this.applyChartTemplate, this.parsedTrialTemplate);
        if (createCopies) {
          chartsCopy[index].tracks = JSON.parse(JSON.stringify(filteredCharts[index].tracks)); // deep copy
        }
        if (this.applyChartTemplate && filteredCharts[index].tracks.length > 0) {
          this.trialChartsService.parseTracks(filteredCharts, index, this.momentTracks, this.templateService.getTemplateFor('momentsCharts'), filteredCharts[index].name, true, false);
        }
      }
      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'Powers');
      if (index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        filteredCharts[index].tracks = this.trialChartsService.prepareTrackData(filteredCharts[index], this.applyChartTemplate, this.parsedTrialTemplate);
        if (createCopies) {
          chartsCopy[index].tracks = JSON.parse(JSON.stringify(filteredCharts[index].tracks)); // deep copy
        }
        if (this.applyChartTemplate && filteredCharts[index].tracks.length > 0) {
          this.trialChartsService.parseTracks(filteredCharts, index, this.powerTracks, this.templateService.getTemplateFor('powersCharts'), filteredCharts[index].name, true, false);
        }
      }
      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'Muscle lengths and velocities');
      if (index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        filteredCharts[index].tracks = this.trialChartsService.prepareTrackData(filteredCharts[index], this.applyChartTemplate, this.parsedTrialTemplate);
        if (createCopies) {
          chartsCopy[index].tracks = JSON.parse(JSON.stringify(filteredCharts[index].tracks)); // deep copy
        }
        if (this.applyChartTemplate && filteredCharts[index].tracks.length > 0) {
          this.trialChartsService.parseTracks(filteredCharts, index, this.lengthVelocityTracks, this.templateService.getTemplateFor('muscleLengthsVelocitiesCharts'), filteredCharts[index].name, true, false);
        }
      }


      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'EMG');
      if (index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        filteredCharts[index].tracks = this.trialChartsService.prepareTrackData(filteredCharts[index], this.applyChartTemplate, this.parsedTrialTemplate);
        if (createCopies) {
          chartsCopy[index].tracks = JSON.parse(JSON.stringify(filteredCharts[index].tracks)); // deep copy
        }
        if (this.applyChartTemplate && filteredCharts[index].tracks.length > 0) {
          this.trialChartsService.parseTracks(filteredCharts, index, this.emgTracks, this.templateService.getTemplateFor('emgsCharts'), filteredCharts[index].name, true, false);
        }
      }
      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'Forces');
      if (index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        filteredCharts[index].tracks = this.trialChartsService.prepareTrackData(filteredCharts[index], this.applyChartTemplate, this.parsedTrialTemplate);
        if (createCopies) {
          chartsCopy[index].tracks = JSON.parse(JSON.stringify(filteredCharts[index].tracks)); // deep copy
        }
        if (this.applyChartTemplate && filteredCharts[index].tracks.length > 0) {
          this.trialChartsService.parseTracks(filteredCharts, index, this.forceTracks, this.templateService.getTemplateFor('forcesCharts'), filteredCharts[index].name, true, false);
        }
      }

      if (this.applyChartTemplate && createCopies) {
        this.trialChartsService.parseTracks(chartsCopy, undefined, this.combinedTracks, this.templateService.getTemplateFor('combinedCharts'), 'Combined', true, false);
      }
    }

    // see if any of the charts still needs to be normalized to body weight or if the V -> uV conversion needs to be applied
    for (const chart of filteredCharts) {
      if (
        chart.name.toLowerCase().includes('force') ||
        chart.name.toLowerCase().includes('moments') ||
        chart.name.toLowerCase().includes('powers') ||
        chart.name.toLowerCase().includes('lengths and velocities')
        ) {
        if ((this.applyChartTemplate === false || (this.applyChartTemplate === true && chart.tracks.length > 0 && chart.tracks[0].hasCycles === undefined)) &&
        this.trialChartsService.normalizeToBodyWeight && this.trialChartsService.subjectWeight !== undefined) {
          for (const track of chart.tracks) {

            let xLabel = 'perc';
            if (track.labels !== undefined && track.labels.time !== undefined) {
              xLabel = track.labels.time;
            }
            // Note: we only apply the normalization for forces, since we assume the moments and powers to already have been normalized (part of the common workflow, e.g. Polygon)
            if (chart.name.toLowerCase().includes('force') && !track.labels.vAxis.includes('[%BW]') && !track.labels.vAxis.includes('[N/kg]')) {
              track.values = this.trialChartsService.normalizeSamplesToBodyWeight(track.values, xLabel, this.trialChartsService.normalizeToBodyMassInsteadOfWeight);
              if (this.trialChartsService.normalizeToBodyMassInsteadOfWeight === false) {
                track.values = this.trialChartsService.applyScalarToSamples(track.values, xLabel, 100); // Get to percentage 1-100
              }
            } else if (chart.name.toLowerCase().includes('moment') && track.labels.vAxis.length >=4 && track.labels.vAxis.substring(track.labels.vAxis.length-4,track.labels.vAxis.length) === 'Nmm]') {
              // Check if we get Nmm from c3d processor and convert to Nm (/1000)
              track.values = this.trialChartsService.applyScalarToSamples(track.values, xLabel, 0.001);
              track.labels.vAxis = track.labels.vAxis.replace('Nmm]','Nm]');
            }

            if (track.labels.vAxis.length > 0) {
              track.labels.vAxis = this.trialChartsService.updateLabelForWeightNormalization(track.labels.vAxis, chart.name);
            }
          }
        }
      } else if (chart.name.toLowerCase().includes('emg') && (this.applyChartTemplate === false || (this.applyChartTemplate === true && chart.tracks.length && chart.tracks[0].hasCycles === undefined))) {
        for (const track of chart.tracks) {
          if (!track.labels.vAxis.includes('[uV]')) {
            let xLabel = 'perc';
            if (track.labels !== undefined && track.labels.time !== undefined) {
              xLabel = track.labels.time;
            }
            let multiplier = 1;
            if (this.parsedTrialTemplate !== undefined && this.parsedTrialTemplate.emgDataUnit !== undefined) {
              if (track.labels.vAxis.endsWith(']') && track.labels.vAxis.includes('[')) {
                track.labels.vAxis = track.labels.vAxis.substring(0,track.labels.vAxis.lastIndexOf('['));
              }
              track.labels.vAxis = track.labels.vAxis + '[' + this.parsedTrialTemplate.emgDataUnit.unit + ']';
              multiplier = this.parsedTrialTemplate.emgDataUnit.multiplier;
            } else {
              // convert V to uV
              multiplier = 1e6;
              track.labels.vAxis = track.labels.vAxis.replace('[V]','[uV]');
            }
            track.values = this.trialChartsService.applyScalarToSamples(track.values, xLabel, multiplier);
          }
        }
      }
    }

    if (this.parsedTrialTemplate?.mergeLeftRightChart !== false) {
      filteredCharts = this.trialChartsService.mergeLeftRightCharts(filteredCharts, this.applyChartTemplate);
    }
    const emptyTrack: TrialTracksGroup = {};
    const emptyChart: ChartTemplateGroup[] = [];

    // if charts template is defined and we have tracks, then show the track, otherwise show from filtered charts.
    const filteredChartsToPush = this.applyChartTemplate ? null : filteredCharts;
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.combinedTracks, this.templateService.getTemplateFor('combinedCharts'), filteredChartsToPush, this.templateService.getChartNameFor('combinedCharts'));
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.forceTracks, this.templateService.getTemplateFor('forcesCharts'), filteredChartsToPush, this.templateService.getChartNameFor('forcesCharts'));
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, emptyTrack, emptyChart, filteredCharts, 'Force plates'); // should this be in an or?
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.kinematicTracks, this.templateService.getTemplateFor('kinematicsCharts'), filteredChartsToPush, this.templateService.getChartNameFor('kinematicsCharts'));
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.momentTracks, this.templateService.getTemplateFor('momentsCharts'), filteredChartsToPush, this.templateService.getChartNameFor('momentsCharts'));
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.powerTracks, this.templateService.getTemplateFor('powersCharts'), filteredChartsToPush, this.templateService.getChartNameFor('powersCharts'));
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.lengthVelocityTracks, this.templateService.getTemplateFor('muscleLengthsVelocitiesCharts'), filteredChartsToPush, this.templateService.getChartNameFor('muscleLengthsVelocitiesCharts'));
    this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, this.emgTracks, this.templateService.getTemplateFor('emgsCharts'), filteredChartsToPush, this.templateService.getChartNameFor('emgsCharts'));

    const chartNames = filteredCharts.map(chart => chart.name);
    const preferredCharts = ['Combined', 'Kinematics', 'Moments', 'Powers', 'Muscle lengths and velocities', 'Forces', 'Force plates', 'EMG'];
    const additionalCharts = chartNames.filter(function(obj) { return preferredCharts.indexOf(obj) == -1; });
    for (const additionalChart of additionalCharts) {
      this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, emptyTrack, emptyChart, filteredCharts, additionalChart);
    }

    // EMG consistency chart
    if (this.parsedTrialTemplate && this.parsedTrialTemplate.showEmgConsistencyChart && this.parsedTrialTemplate.showEmgConsistencyChart === true) {
      const emgTracks_combined: TrialTracksGroup = {};
      const myTemplateGroups: ChartTemplateGroup[] = this.templateService.getTemplateFor('emgsConsistencyCharts');
      let myTemplate: ChartTemplateGroup;
      if (myTemplateGroups) {
        myTemplate = myTemplateGroups.find(charts => charts.name === 'EMG consistency');
      }
      index = filteredCharts.findIndex(trackGroup => trackGroup.name === 'EMG');
      let myDataTracks: DataTrack[] = [];
      if (myTemplate && index > -1 && filteredCharts[index].tracks.length > 0 && filteredCharts[index].tracks[0].hasCycles !== undefined) {
        if (this.applyChartTemplate && Object.keys(this.emgTracks).length > 0) {
          const copy: TrialTracksGroup = JSON.parse(JSON.stringify(this.emgTracks)); // deep copy
          const myTracks: DataTrack[] = [];
          for (const trackGroupLabel in copy) {
            for (const trackLabel in copy[trackGroupLabel]) {
              myTracks.push(copy[trackGroupLabel][trackLabel]);
            }
          }
          myDataTracks = myTracks;
        } else {
          const copy: LineChartGroup = JSON.parse(JSON.stringify(filteredCharts[index])); // deep copy
          myDataTracks = this.trialChartsService.prepareTrackData(copy, true, this.parsedTrialTemplate);
          myDataTracks = this.trialChartsService.splitEmgConsistencyLeftRight(myDataTracks);
        }

        for (const dataTrack of myDataTracks) {
          let myChart: ChartTemplate;
          if (Object.values(dataTrack.labels.data).some(a =>a.includes(" (left)"))) {
            myChart = myTemplate.charts.find(chart => chart.id.toLowerCase().includes('left'));
            hasLeftData = true;
          } else if (Object.values(dataTrack.labels.data).some(a =>a.includes(" (right)"))) {
            myChart = myTemplate.charts.find(chart => chart.id.toLowerCase().includes('right'));
            hasRightData = true;
          } else {
            myChart = myTemplate.charts.find(chart => chart.id.toLowerCase().includes('nocontext'));
          }

          if (myChart) {
            this.trialChartsService.addTrackToContainer(emgTracks_combined, dataTrack, dataTrack.labels.title, myTemplate.name, myChart.title, myChart.id, myChart.vAxis, myChart.vLimits, true, true, false);
          }
        }

        // if we don't apply the chart template, push the newly generated charts into filteredCharts to have them available on split screen as well.
        if (!this.applyChartTemplate) {
          const myTracks: DataTrack[] = [];
          for (const trackGroupLabel in emgTracks_combined) {
            for (const trackLabel in emgTracks_combined[trackGroupLabel]) {
              myTracks.push(emgTracks_combined[trackGroupLabel][trackLabel]);
            }
          }
          const myChart: LineChartGroup = {name: 'EMG consistency', tracks: myTracks};
          filteredCharts.push(myChart);
        }
        this.trialChartsService.addToLineChartMajorGroup(this.lineChartMajorGroups, emgTracks_combined, this.templateService.getTemplateFor('emgsConsistencyCharts'), undefined, this.templateService.getChartNameFor('emgsConsistencyCharts'));
      }
    }

    const lineChartMajorGroupsNew: LineChartMajorGroup[] = [];
    const chartGroupNames = this.lineChartMajorGroups.map(group => group.name);
    const chartsAdded: string[]  = [];
    for (const chartName of preferredCharts) {
      index = this.lineChartMajorGroups.findIndex(majorGroup => majorGroup.name === chartName);
      if (index > -1) {
        lineChartMajorGroupsNew.push(this.lineChartMajorGroups[index]);
        chartsAdded.push(chartName);
      }
    }
    // Add remaining
    for (const groupName of chartGroupNames) {
      index = this.lineChartMajorGroups.findIndex(majorGroup => majorGroup.name === groupName);
      if (index > -1 && chartsAdded.indexOf(groupName) === -1) {
        lineChartMajorGroupsNew.push(this.lineChartMajorGroups[index]);
      }
    }
    this.lineChartMajorGroups = lineChartMajorGroupsNew;
    this.templateService.replaceChartNames(this.lineChartMajorGroups, this.currentTemplateMetadata);

    let sharedTracks: LineChartGroup[] = [];
    if (this.applyChartTemplate) {
      sharedTracks = [];
      let expanded = false;
      for (const comparisonGroup of this.lineChartMajorGroups) {
        const tracksWithoutGroups: DataTrack[] = [];
        if (comparisonGroup.expanded) {
          expanded = comparisonGroup.expanded;
        }
        for (const group of comparisonGroup.groups) {
          for (const track of group.tracks) {
            tracksWithoutGroups.push(track);
          }
        }
        sharedTracks.push({name: comparisonGroup.name, tracks: tracksWithoutGroups, expanded: expanded});
      }
    } else {
      sharedTracks = filteredCharts;

    }
    const sortTrackLabels = !this.applyChartTemplate;
    this.trialChartsService.setLineCharts(sharedTracks, sortTrackLabels);
    this.chartToggleService.showLeftToggle = hasLeftData ? hasLeftData : this.trialChartsService.hasContextInChart(this.lineChartMajorGroups, DataContext.leftSide);
    this.chartToggleService.showRightToggle = hasRightData ? hasRightData : this.trialChartsService.hasContextInChart(this.lineChartMajorGroups, DataContext.rightSide);
    this.multiChartDataService.setLineChartMajorGroups(this.lineChartMajorGroups);
    this.multiChartTiles = this.multiChartDataService.getMultiChartTiles(this._clipId, this.trialChartsService.getChartMode());
  }

  public toggleExpandParams(paramsGroup: GaitParameterTable): void {
    paramsGroup.expanded = !paramsGroup.expanded;
  }

  public toggleExpandCharts(lineChartGroup: LineChartGroup | LineChartMajorGroup): void {
    lineChartGroup.expanded = !lineChartGroup.expanded;
    // Pause playback when expanding/collapsing chart groups
    // @see #1608
    this.playbackControl.pause();
  }

  public getLineCharts(): LineChartGroup[] {
    return this.trialChartsService.lineCharts;
  }

  public showBodyMassWarning(groupName: string): boolean {
    return this.colorService.groupNamesForBodyMassWarningIcon.indexOf(groupName) !== -1 && this.bodyweightWarningIcon && this.trialChartsService.normalizeToBodyWeight;
  }

  private async loadClip(clipId: string): Promise<void> {
    let clip;
    this.trialChartsService.disabledLines = {};
    const defaultTrialTemplate = this.trialTemplateService.mergedTrialTemplateAsJSON(this.featureFlagsService.getAll(), undefined);
    try {
      clip = await this.trialChartsService.getDataFromId(
        clipId,
        {
          skipMox: !!this.featureFlagsService.get('skipMoxDownloadAndParseForCharts'),
          fetchTimeBasisCharts: true,
          applyChartTemplate: this.applyChartTemplate,
          cameraNamesForDlt: defaultTrialTemplate?.cameraNamesForDltMox,
          applyRegularGcdCycles: !!this.featureFlagsService.get('canPerformBiomechEvaluation') === false,
        });
    } catch (clipLoadError) {
      this.ready.emit({
        ready: false,
        errors: ['There was an error while loading graphs content, please refresh the page or contact an administrator.'],
      });
      return;
    } finally {
      this.loading = false;
    }

    this.tracks = clip.tracks;
    if (this.tracks?.pdfs) {
      for (const pdf of this.tracks.pdfs) {
        pdf.collapsed = false;
      }
    }
    this.trialChartsService.resetLineStyles();
    this.ready.emit({
      ready: true,
    });
  }

  // exportPdf() {

  //   let jsPDF = require('jspdf');
  //   let html2canvas = require('html2canvas');

  //   this.exporting = true;
  //   // Default export is a4 paper, portrait, using milimeters for units
  //   var pdf = new jsPDF( 'p', 'mm', 'a4');

  //   html2canvas(this.testDiv.nativeElement, {
  //     }).then( (canvas) => {

  //       var img = canvas.toDataURL('image/png');

  //       let height = 250;
  //       let scale = height / canvas.height;
  //       pdf.text('Moveshelf - Kinematics report', 15, 20)
  //       pdf.addImage(img, 15, 25, canvas.width * scale, height);
  //       //multi page test
  //       /*pdf.addImage(img, 20, 0, 150, 500); //a4: 211, 298
  //       pdf.addPage('a4');
  //       pdf.addImage(img, 20, -298, 150, 500);*/
  //       pdf.save('report.pdf');
  //       this.exporting = false;
  //     });
  // }
}
