import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TimeSelection } from '../core/time-selection';

@Injectable()
export class SelectionService {

  public selection: BehaviorSubject<TimeSelection> = new BehaviorSubject<TimeSelection>(null);

  constructor() { }
}
