/**
 * @author Kyle-Larson https://github.com/Kyle-Larson
 * @author Takahiro https://github.com/takahirox
 *
 * Loader loads FBX file and generates Group representing FBX scene.
 * Requires FBX file to be >= 7.0 and in ASCII or to be any version in Binary
 * format.
 *
 * Supports:
 * 	Mesh Generation (Positional Data)
 * 	Normal Data (Per Vertex Drawing Instance)
 *  UV Data (Per Vertex Drawing Instance)
 *  Skinning
 *  Animation
 * 	- Separated Animations based on stacks.
 * 	- Skeletal & Non-Skeletal Animations
 *  NURBS (Open, Closed and Periodic forms)
 *
 * Needs Support:
 * 	Indexed Buffers
 * 	PreRotation support.
 */
const THREE = require('three');

(function() {

/**
 * Generates a loader for loading FBX files from URL and parsing into
 * a THREE.Group.
 * @param {THREE.LoadingManager} manager - Loading Manager for loader to use.
 */
THREE.FBXAnimationLoader = function(manager) {

  this.manager =
      (manager !== undefined) ? manager : THREE.DefaultLoadingManager;

};

Object.assign(THREE.FBXAnimationLoader.prototype, {

  /**
   * Loads an ASCII/Binary FBX file from URL and parses into a THREE.Group.
   * THREE.Group will have an animations property of AnimationClips
   * of the different animations exported with the FBX.
   * @param {string} url - URL of the FBX file.
   * @param {function(THREE.Group):void} onLoad - Callback for when FBX file is loaded and parsed.
   * @param {function(ProgressEvent):void} onProgress - Callback fired periodically when file is being retrieved from server.
   * @param {function(Event):void} onError - Callback fired when error occurs (Currently only with retrieving file, not with parsing errors).
   */
  load: function(url, onLoad, onProgress, onError) {

    var self = this;

    var resourceDirectory = THREE.Loader.prototype.extractUrlBase(url);

    var loader = new THREE.FileLoader(this.manager);
    loader.setResponseType('arraybuffer');
    loader.load(url, function(buffer) {

      try {
        var scene = self.parse(buffer, resourceDirectory);

        onLoad(scene);

      } catch (error) {
        window.setTimeout(function() {

          if (onError) onError(error);

          self.manager.itemError(url);

        }, 0);
      }

    }, onProgress, onError);

  },

  /**
   * Parses an ASCII/Binary FBX file and returns a THREE.Group.
   * THREE.Group will have an animations property of AnimationClips
   * of the different animations within the FBX file.
   * @param {ArrayBuffer} FBXBuffer - Contents of FBX file to parse.
   * @param {string} resourceDirectory - Directory to load external assets (e.g. textures ) from.
   * @returns {THREE.Group}
   */
  parse: function(FBXBuffer, resourceDirectory) {

    var FBXTree;

    if (isFbxFormatBinary(FBXBuffer)) {
      FBXTree = new BinaryParser().parse(FBXBuffer);

    } else {
      var FBXText = convertArrayBufferToString(FBXBuffer);

      if (!isFbxFormatASCII(FBXText)) {
        throw new Error('THREE.FBXLoader: Unknown format.');
      }

      if (getFbxVersion(FBXText) < 7000) {
        throw new Error(
            'THREE.FBXLoader: FBX version not supported, FileVersion: ' +
            getFbxVersion(FBXText));
      }

      FBXTree = new TextParser().parse(FBXText);
    }

    // console.log( FBXTree );

    var connections = parseConnections(FBXTree);
    var images = parseImages(FBXTree);
    var textures = parseTextures(
        FBXTree,
        new THREE.TextureLoader(this.manager).setPath(resourceDirectory),
        images, connections);
    var materials = parseMaterials(FBXTree, textures, connections);
    var deformers = parseDeformers(FBXTree, connections);
    var geometryMap = parseGeometries(FBXTree, connections, deformers);
    var sceneGraph =
        parseScene(FBXTree, connections, deformers, geometryMap, materials);

    return sceneGraph;

  }

});

/**
 * Parses map of relationships between objects.
 * @param {{Connections: { properties: { connections: [number, number, string][]}}}} FBXTree
 * @returns {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>}
 */
function parseConnections(FBXTree) {
  /**
   * @type {Map<number, { parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>}
   */
  var connectionMap = new Map();

  if ('Connections' in FBXTree) {
    /**
     * @type {[number, number, string][]}
     */
    var connectionArray = FBXTree.Connections.properties.connections;
    for (var connectionArrayIndex = 0,
             connectionArrayLength = connectionArray.length;
         connectionArrayIndex < connectionArrayLength; ++connectionArrayIndex) {
      var connection = connectionArray[connectionArrayIndex];

      if (!connectionMap.has(connection[0])) {
        connectionMap.set(connection[0], {parents: [], children: []});
      }

      var parentRelationship = {ID: connection[1], relationship: connection[2]};
      connectionMap.get(connection[0]).parents.push(parentRelationship);

      if (!connectionMap.has(connection[1])) {
        connectionMap.set(connection[1], {parents: [], children: []});
      }

      var childRelationship = {ID: connection[0], relationship: connection[2]};
      connectionMap.get(connection[1]).children.push(childRelationship);
    }
  }

  return connectionMap;
}

/**
 * Parses map of images referenced in FBXTree.
 * @param {{Objects: {subNodes: {Texture: Object.<string, FBXTextureNode>}}}} FBXTree
 * @returns {Map<number, string(image blob/data URL)>}
 */
function parseImages(FBXTree) {
  /**
   * @type {Map<number, string(image blob/data URL)>}
   */
  var imageMap = new Map();

  if ('Video' in FBXTree.Objects.subNodes) {
    var videoNodes = FBXTree.Objects.subNodes.Video;

    for (var nodeID in videoNodes) {
      var videoNode = videoNodes[nodeID];

      // raw image data is in videoNode.properties.Content
      if ('Content' in videoNode.properties) {
        var image = parseImage(videoNodes[nodeID]);
        imageMap.set(parseInt(nodeID), image);
      }
    }
  }

  return imageMap;
}

/**
 * @param {videoNode} videoNode - Node to get texture image information from.
 * @returns {string} - image blob/data URL
 */
function parseImage(videoNode) {
  var content = videoNode.properties.Content;
  var fileName =
      videoNode.properties.RelativeFilename || videoNode.properties.Filename;
  var extension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();

  var type;

  switch (extension) {
    case 'bmp':

      type = 'image/bmp';
      break;

    case 'jpg':

      type = 'image/jpeg';
      break;

    case 'png':

      type = 'image/png';
      break;

    case 'tif':

      type = 'image/tiff';
      break;

    default:

      console.warn('FBXLoader: No support image type ' + extension);
      return;
  }

  if (typeof content === 'string') {
    // ASCII format sometimes adds an extra character to the end of the content
    // string
    if (content.slice(-1) !== '=') {
      content = content.slice(0, -1);
    }

    return 'data:' + type + ';base64,' + content;

  } else {
    var array = new Uint8Array(content);
    return window.URL.createObjectURL(new Blob([array], {type: type}));
  }
}

/**
 * Parses map of textures referenced in FBXTree.
 * @param {{Objects: {subNodes: {Texture: Object.<string, FBXTextureNode>}}}} FBXTree
 * @param {THREE.TextureLoader} loader
 * @param {Map<number, string(image blob/data URL)>} imageMap
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @returns {Map<number, THREE.Texture>}
 */
function parseTextures(FBXTree, loader, imageMap, connections) {
  /**
   * @type {Map<number, THREE.Texture>}
   */
  var textureMap = new Map();

  if ('Texture' in FBXTree.Objects.subNodes) {
    var textureNodes = FBXTree.Objects.subNodes.Texture;
    for (var nodeID in textureNodes) {
      var texture =
          parseTexture(textureNodes[nodeID], loader, imageMap, connections);
      textureMap.set(parseInt(nodeID), texture);
    }
  }

  return textureMap;
}

/**
 * @param {textureNode} textureNode - Node to get texture information from.
 * @param {THREE.TextureLoader} loader
 * @param {Map<number, string(image blob/data URL)>} imageMap
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @returns {THREE.Texture}
 */
function parseTexture(textureNode, loader, imageMap, connections) {
  var FBX_ID = textureNode.id;

  var name = textureNode.attrName;

  var fileName;

  var filePath = textureNode.properties.FileName;
  var relativeFilePath = textureNode.properties.RelativeFilename;

  var children = connections.get(FBX_ID).children;

  if (children !== undefined && children.length > 0 &&
      imageMap.has(children[0].ID)) {
    fileName = imageMap.get(children[0].ID);

  } else if (
      relativeFilePath !== undefined && relativeFilePath[0] !== '/' &&
      relativeFilePath.match(/^[a-zA-Z]:/) === null) {
    // use textureNode.properties.RelativeFilename
    // if it exists and it doesn't seem an absolute path

    fileName = relativeFilePath;

  } else {
    var split = filePath.split(/[\\\/]/);

    if (split.length > 0) {
      fileName = split[split.length - 1];

    } else {
      fileName = filePath;
    }
  }

  var currentPath = loader.path;

  if (fileName.indexOf('blob:') === 0 || fileName.indexOf('data:') === 0) {
    loader.setPath(undefined);
  }

  /**
   * @type {THREE.Texture}
   */
  var texture = loader.load(fileName);
  texture.name = name;
  texture.FBX_ID = FBX_ID;

  var wrapModeU = textureNode.properties.WrapModeU;
  var wrapModeV = textureNode.properties.WrapModeV;

  var valueU = wrapModeU !== undefined ? wrapModeU.value : 0;
  var valueV = wrapModeV !== undefined ? wrapModeV.value : 0;

  // http://download.autodesk.com/us/fbx/SDKdocs/FBX_SDK_Help/files/fbxsdkref/class_k_fbx_texture.html#889640e63e2e681259ea81061b85143a
  // 0: repeat(default), 1: clamp

  texture.wrapS =
      valueU === 0 ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;
  texture.wrapT =
      valueV === 0 ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;

  loader.setPath(currentPath);

  return texture;
}

/**
 * Parses map of Material information.
 * @param {{Objects: {subNodes: {Material: Object.<number, FBXMaterialNode>}}}} FBXTree
 * @param {Map<number, THREE.Texture>} textureMap
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @returns {Map<number, THREE.Material>}
 */
function parseMaterials(FBXTree, textureMap, connections) {
  var materialMap = new Map();

  if ('Material' in FBXTree.Objects.subNodes) {
    var materialNodes = FBXTree.Objects.subNodes.Material;
    for (var nodeID in materialNodes) {
      var material =
          parseMaterial(materialNodes[nodeID], textureMap, connections);
      if (material !== null) materialMap.set(parseInt(nodeID), material);
    }
  }

  return materialMap;
}

/**
 * Takes information from Material node and returns a generated THREE.Material
 * @param {FBXMaterialNode} materialNode
 * @param {Map<number, THREE.Texture>} textureMap
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @returns {THREE.Material}
 */
function parseMaterial(materialNode, textureMap, connections) {
  var FBX_ID = materialNode.id;
  var name = materialNode.attrName;
  var type = materialNode.properties.ShadingModel;

  // Case where FBXs wrap shading model in property object.
  if (typeof type === 'object') {
    type = type.value;
  }

  // Seems like FBX can include unused materials which don't have any
  // connections. Ignores them so far.
  if (!connections.has(FBX_ID)) return null;

  var children = connections.get(FBX_ID).children;

  var parameters =
      parseParameters(materialNode.properties, textureMap, children);

  var material;

  switch (type.toLowerCase()) {
    case 'phong':
      material = new THREE.MeshPhongMaterial();
      break;
    case 'lambert':
      material = new THREE.MeshLambertMaterial();
      break;
    default:
      console.warn(
          'THREE.FBXLoader: No implementation given for material type %s in FBXLoader.js. Defaulting to standard material.',
          type);
      material = new THREE.MeshStandardMaterial({color: 0x3300ff});
      break;
  }

  material.setValues(parameters);
  material.name = name;

  return material;
}

/**
 * @typedef {{Diffuse: FBXVector3, Specular: FBXVector3, Shininess: FBXValue, Emissive: FBXVector3, EmissiveFactor: FBXValue, Opacity: FBXValue}} FBXMaterialProperties
 */
/**
 * @typedef {{color: THREE.Color=, specular: THREE.Color=, shininess: number=, emissive: THREE.Color=, emissiveIntensity: number=, opacity: number=, transparent: boolean=, map: THREE.Texture=}} THREEMaterialParameterPack
 */
/**
 * @param {FBXMaterialProperties} properties
 * @param {Map<number, THREE.Texture>} textureMap
 * @param {{ID: number, relationship: string}[]} childrenRelationships
 * @returns {THREEMaterialParameterPack}
 */
function parseParameters(properties, textureMap, childrenRelationships) {
  var parameters = {};

  if (properties.BumpFactor) {
    parameters.bumpScale = parseFloat(properties.BumpFactor.value);
  }
  if (properties.Diffuse) {
    parameters.color = parseColor(properties.Diffuse);
  }
  if (properties.DisplacementFactor) {
    parameters.displacementScale =
        parseFloat(properties.DisplacementFactor.value);
  }
  if (properties.ReflectionFactor) {
    parameters.envMapIntensity = parseFloat(properties.ReflectionFactor.value);
    parameters.reflectivity = parseFloat(properties.ReflectionFactor.value);
  }
  if (properties.Specular) {
    parameters.specular = parseColor(properties.Specular);
  }
  if (properties.Shininess) {
    parameters.shininess = parseFloat(properties.Shininess.value);
  }
  if (properties.Emissive) {
    parameters.emissive = parseColor(properties.Emissive);
  }
  if (properties.EmissiveFactor) {
    parameters.emissiveIntensity = parseFloat(properties.EmissiveFactor.value);
  }
  if (properties.Opacity) {
    parameters.opacity = parseFloat(properties.Opacity.value);
  }
  if (parameters.opacity < 1.0) {
    parameters.transparent = true;
  }

  for (var childrenRelationshipsIndex = 0,
           childrenRelationshipsLength = childrenRelationships.length;
       childrenRelationshipsIndex < childrenRelationshipsLength;
       ++childrenRelationshipsIndex) {
    var relationship = childrenRelationships[childrenRelationshipsIndex];

    var type = relationship.relationship;

    switch (type) {
      case 'Bump':
      case ' "Bump':
        parameters.bumpMap = textureMap.get(relationship.ID);
        break;

      case 'DiffuseColor':
      case ' "DiffuseColor':
        parameters.map = textureMap.get(relationship.ID);
        break;

      case 'DisplacementColor':
      case ' "DisplacementColor':
        parameters.displacementMap = textureMap.get(relationship.ID);
        break;


      case 'EmissiveColor':
      case ' "EmissiveColor':
        parameters.emissiveMap = textureMap.get(relationship.ID);
        break;

      case 'NormalMap':
      case ' "NormalMap':
        parameters.normalMap = textureMap.get(relationship.ID);
        break;

      case 'ReflectionColor':
      case ' "ReflectionColor':
        parameters.envMap = textureMap.get(relationship.ID);
        parameters.envMap.mapping = THREE.EquirectangularReflectionMapping;
        break;

      case 'SpecularColor':
      case ' "SpecularColor':
        parameters.specularMap = textureMap.get(relationship.ID);
        break;

      case 'TransparentColor':
      case ' "TransparentColor':
        parameters.alphaMap = textureMap.get(relationship.ID);
        parameters.transparent = true;
        break;

      case 'AmbientColor':
      case ' "AmbientColor':
      case 'ShininessExponent':  // AKA glossiness map
      case ' "ShininessExponent':
      case 'SpecularFactor':  // AKA specularLevel
      case ' "SpecularFactor':
      case 'VectorDisplacementColor':  // NOTE: Seems to be a copy of
                                       // DisplacementColor
      case ' "VectorDisplacementColor':
      default:
        console.warn(
            'THREE.FBXLoader: %s map is not supported in three.js, skipping texture.',
            type);
        break;
    }
  }

  return parameters;
}

/**
 * Generates map of Skeleton-like objects for use later when generating and
 * binding skeletons.
 * @param {{Objects: {subNodes: {Deformer: Object.<number, FBXSubDeformerNode>}}}} FBXTree
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @returns {Map<number, {map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[], skeleton: THREE.Skeleton|null}>}
 */
function parseDeformers(FBXTree, connections) {
  var deformers = {};

  if ('Deformer' in FBXTree.Objects.subNodes) {
    var DeformerNodes = FBXTree.Objects.subNodes.Deformer;

    for (var nodeID in DeformerNodes) {
      var deformerNode = DeformerNodes[nodeID];

      if (deformerNode.attrType === 'Skin') {
        var conns = connections.get(parseInt(nodeID));
        var skeleton = parseSkeleton(conns, DeformerNodes);
        skeleton.FBX_ID = parseInt(nodeID);

        deformers[nodeID] = skeleton;
      }
    }
  }

  return deformers;
}

function getTimesAndValues(curves, times, values) {
  var xIndex = 0, yIndex = 0, zIndex = 0;
  var xLength = curves.x.times.length - 1;
  var yLength = curves.y.times.length - 1;
  var zLength = curves.z.times.length - 1;

  var time = curves.x.times[0]; //should be min of the three

  if(curves.y.times[0] < time)
    time = curves.y.times[0];

  if(curves.z.times[0] < time)
    time = curves.z.times[0];

  var lastTimes = time;
  for (;;) {

  //if( time > (lastTimes + 0.014)) //duplicate samples should be avoided by design
  {
    times.push(time);
    values.x.push(curves.x.values[xIndex]);
    values.y.push(curves.y.values[yIndex]);
    values.z.push(curves.z.values[zIndex]);
    lastTimes = time;
  }

  if(xIndex == xLength && yIndex == yLength && zIndex == zLength)
    break;

  if(xIndex < xLength &&
  (yIndex == yLength || curves.x.times[xIndex + 1] <= curves.y.times[yIndex + 1]) &&
  (zIndex == zLength || curves.x.times[xIndex + 1] <= curves.z.times[zIndex + 1]))
  {
    xIndex++;
    if(yIndex < yLength && curves.x.times[xIndex] == curves.y.times[yIndex + 1])
      yIndex++;
    if(zIndex < zLength && curves.x.times[xIndex] == curves.z.times[zIndex + 1])
      zIndex++;
    time = curves.x.times[xIndex];
    continue;
  }

  if(yIndex < yLength &&
    (xIndex == xLength || curves.y.times[yIndex + 1] <= curves.x.times[xIndex + 1]) &&
    (zIndex == zLength || curves.y.times[yIndex + 1] <= curves.z.times[zIndex + 1]))
  {
    yIndex++;
    if(xIndex < xLength && curves.y.times[yIndex] == curves.x.times[xIndex + 1])
      xIndex++;
    if(zIndex < zLength && curves.y.times[yIndex] == curves.z.times[zIndex + 1])
      zIndex++;
    time = curves.y.times[yIndex];
    continue;
  }

  if(zIndex < zLength &&
    (xIndex == xLength || curves.z.times[zIndex + 1] <= curves.x.times[xIndex + 1]) &&
    (yIndex == yLength || curves.z.times[zIndex + 1] <= curves.y.times[yIndex + 1]))
  {
    zIndex++;
    if(xIndex < xLength && curves.z.times[zIndex] == curves.x.times[xIndex + 1])
      xIndex++;
    if(yIndex < yLength && curves.z.times[zIndex] == curves.y.times[yIndex + 1])
      yIndex++;
    time = curves.z.times[zIndex];
    continue;
  }

  }
}

/**
 * Generates a "Skeleton Representation" of FBX nodes based on an FBX Skin
 * Deformer's connections and an object containing SubDeformer nodes.
 * @param {{parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}} connections
 * @param {Object.<number, FBXSubDeformerNode>} DeformerNodes
 * @returns {{map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[], skeleton: THREE.Skeleton|null}}
 */
function parseSkeleton(connections, DeformerNodes) {
  var subDeformers = {};
  var children = connections.children;

  for (var i = 0, l = children.length; i < l; ++i) {
    var child = children[i];

    var subDeformerNode = DeformerNodes[child.ID];

    var subDeformer = {
      FBX_ID: child.ID,
      index: i,
      indices: [],
      weights: [],
      transform:
          parseMatrixArray(subDeformerNode.subNodes.Transform.properties.a),
      transformLink:
          parseMatrixArray(subDeformerNode.subNodes.TransformLink.properties.a),
      linkMode: subDeformerNode.properties.Mode
    };

    if ('Indexes' in subDeformerNode.subNodes) {
      subDeformer.indices =
          parseIntArray(subDeformerNode.subNodes.Indexes.properties.a);
      subDeformer.weights =
          parseFloatArray(subDeformerNode.subNodes.Weights.properties.a);
    }

    subDeformers[child.ID] = subDeformer;
  }

  return {map: subDeformers, bones: []};
}

/**
 * Generates Buffer geometries from geometry information in FBXTree, and
 * generates map of THREE.BufferGeometries
 * @param {{Objects: {subNodes: {Geometry: Object.<number, FBXGeometryNode}}}} FBXTree
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @param {Map<number, {map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[], skeleton: THREE.Skeleton|null}>} deformers
 * @returns {Map<number, THREE.BufferGeometry>}
 */
function parseGeometries(FBXTree, connections, deformers) {
  var geometryMap = new Map();

  if ('Geometry' in FBXTree.Objects.subNodes) {
    var geometryNodes = FBXTree.Objects.subNodes.Geometry;

    for (var nodeID in geometryNodes) {
      var relationships = connections.get(parseInt(nodeID));
      var geo = parseGeometry(geometryNodes[nodeID], relationships, deformers);
      geometryMap.set(parseInt(nodeID), geo);
    }
  }

  return geometryMap;
}

/**
 * Generates BufferGeometry from FBXGeometryNode.
 * @param {FBXGeometryNode} geometryNode
 * @param {{parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}} relationships
 * @param {Map<number, {map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[]}>} deformers
 * @returns {THREE.BufferGeometry}
 */
function parseGeometry(geometryNode, relationships, deformers) {
  switch (geometryNode.attrType) {
    case 'Mesh':
      return parseMeshGeometry(geometryNode, relationships, deformers);
      break;

    case 'NurbsCurve':
      return parseNurbsGeometry(geometryNode);
      break;
  }
}

/**
 * Specialty function for parsing Mesh based Geometry Nodes.
 * @param {FBXGeometryNode} geometryNode
 * @param {{parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}} relationships - Object representing relationships between specific geometry node and other nodes.
 * @param {Map<number, {map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[]}>} deformers - Map object of deformers and subDeformers by ID.
 * @returns {THREE.BufferGeometry}
 */
function parseMeshGeometry(geometryNode, relationships, deformers) {
  for (var i = 0; i < relationships.children.length; ++i) {
    var deformer = deformers[relationships.children[i].ID];
    if (deformer !== undefined) break;
  }

  return genGeometry(geometryNode, deformer);
}

/**
 * @param {{map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[]}} deformer - Skeleton representation for geometry instance.
 * @returns {THREE.BufferGeometry}
 */
function genGeometry(geometryNode, deformer) {
  var geometry = new Geometry();

  var subNodes = geometryNode.subNodes;

  // First, each index is going to be its own vertex.

  var vertexBuffer = parseFloatArray(subNodes.Vertices.properties.a);
  var indexBuffer = parseIntArray(subNodes.PolygonVertexIndex.properties.a);

  if (subNodes.LayerElementNormal) {
    var normalInfo = getNormals(subNodes.LayerElementNormal[0]);
  }

  if (subNodes.LayerElementUV) {
    var uvInfo = [];
    var i = 0;
    while (subNodes.LayerElementUV[i]) {
      uvInfo.push(getUVs(subNodes.LayerElementUV[i]));
      i++;
    }
  }

  if (subNodes.LayerElementColor) {
    var colorInfo = getColors(subNodes.LayerElementColor[0]);
  }

  if (subNodes.LayerElementMaterial) {
    var materialInfo = getMaterials(subNodes.LayerElementMaterial[0]);
  }

  var weightTable = {};

  if (deformer) {
    var subDeformers = deformer.map;

    for (var key in subDeformers) {
      var subDeformer = subDeformers[key];
      var indices = subDeformer.indices;

      for (var j = 0; j < indices.length; j++) {
        var index = indices[j];
        var weight = subDeformer.weights[j];

        if (weightTable[index] === undefined) weightTable[index] = [];

        weightTable[index].push({id: subDeformer.index, weight: weight});
      }
    }
  }

  var faceVertexBuffer = [];
  var polygonIndex = 0;
  var displayedWeightsWarning = false;

  for (var polygonVertexIndex = 0; polygonVertexIndex < indexBuffer.length;
       polygonVertexIndex++) {
    var vertexIndex = indexBuffer[polygonVertexIndex];

    var endOfFace = false;

    if (vertexIndex < 0) {
      vertexIndex = vertexIndex ^ -1;
      indexBuffer[polygonVertexIndex] = vertexIndex;
      endOfFace = true;
    }

    var vertex = new Vertex();
    var weightIndices = [];
    var weights = [];

    vertex.position.fromArray(vertexBuffer, vertexIndex * 3);

    if (deformer) {
      if (weightTable[vertexIndex] !== undefined) {
        var array = weightTable[vertexIndex];

        for (var j = 0, jl = array.length; j < jl; j++) {
          weights.push(array[j].weight);
          weightIndices.push(array[j].id);
        }
      }

      if (weights.length > 4) {
        if (!displayedWeightsWarning) {
          console.warn(
              'THREE.FBXLoader: Vertex has more than 4 skinning weights assigned to vertex. Deleting additional weights.');
          displayedWeightsWarning = true;
        }

        var WIndex = [0, 0, 0, 0];
        var Weight = [0, 0, 0, 0];

        weights.forEach(function(weight, weightIndex) {

          var currentWeight = weight;
          var currentIndex = weightIndices[weightIndex];

          Weight.forEach(function(
              comparedWeight, comparedWeightIndex, comparedWeightArray) {

            if (currentWeight > comparedWeight) {
              comparedWeightArray[comparedWeightIndex] = currentWeight;
              currentWeight = comparedWeight;

              var tmp = WIndex[comparedWeightIndex];
              WIndex[comparedWeightIndex] = currentIndex;
              currentIndex = tmp;
            }

          });

        });

        weightIndices = WIndex;
        weights = Weight;
      }

      for (var i = weights.length; i < 4; ++i) {
        weights[i] = 0;
        weightIndices[i] = 0;
      }

      vertex.skinWeights.fromArray(weights);
      vertex.skinIndices.fromArray(weightIndices);
    }

    if (normalInfo) {
      vertex.normal.fromArray(
          getData(polygonVertexIndex, polygonIndex, vertexIndex, normalInfo));
    }

    if (uvInfo) {
      var uvTemp = new THREE.Vector2();

      for (var i = 0; i < uvInfo.length; i++) {
        vertex.uv.push(uvTemp.fromArray(
            getData(polygonVertexIndex, polygonIndex, vertexIndex, uvInfo[i])));
      }
    }

    if (colorInfo) {
      vertex.color.fromArray(
          getData(polygonVertexIndex, polygonIndex, vertexIndex, colorInfo));
    }

    faceVertexBuffer.push(vertex);

    if (endOfFace) {
      var face = new Face();
      face.genTrianglesFromVertices(faceVertexBuffer);

      if (materialInfo !== undefined) {
        var materials = getData(
            polygonVertexIndex, polygonIndex, vertexIndex, materialInfo);
        face.materialIndex = materials[0];

      } else {
        // Seems like some models don't have
        // materialInfo(subNodes.LayerElementMaterial). Set 0 in such a case.
        face.materialIndex = 0;
      }

      geometry.faces.push(face);
      faceVertexBuffer = [];
      polygonIndex++;

      endOfFace = false;
    }
  }

  /**
   * @type {{vertexBuffer: number[], normalBuffer: number[], uvBuffer: number[], skinIndexBuffer: number[], skinWeightBuffer: number[], materialIndexBuffer: number[]}}
   */
  var bufferInfo = geometry.flattenToBuffers();

  var geo = new THREE.BufferGeometry();
  geo.name = geometryNode.name;
  geo.addAttribute(
      'position', new THREE.Float32BufferAttribute(bufferInfo.vertexBuffer, 3));

  if (bufferInfo.normalBuffer.length > 0) {
    geo.addAttribute(
        'normal', new THREE.Float32BufferAttribute(bufferInfo.normalBuffer, 3));
  }
  if (bufferInfo.uvBuffers.length > 0) {
    for (var i = 0; i < bufferInfo.uvBuffers.length; i++) {
      var name = 'uv' + (i + 1).toString();
      if (i == 0) {
        name = 'uv';
      }

      geo.addAttribute(
          name, new THREE.Float32BufferAttribute(bufferInfo.uvBuffers[i], 2));
    }
  }

  if (subNodes.LayerElementColor) {
    geo.addAttribute(
        'color', new THREE.Float32BufferAttribute(bufferInfo.colorBuffer, 3));
  }

  if (deformer) {
    geo.addAttribute(
        'skinIndex',
        new THREE.Float32BufferAttribute(bufferInfo.skinIndexBuffer, 4));

    geo.addAttribute(
        'skinWeight',
        new THREE.Float32BufferAttribute(bufferInfo.skinWeightBuffer, 4));

    geo.FBX_Deformer = deformer;
  }

  // Convert the material indices of each vertex into rendering groups on the
  // geometry.

  var materialIndexBuffer = bufferInfo.materialIndexBuffer;
  var prevMaterialIndex = materialIndexBuffer[0];
  var startIndex = 0;

  for (var i = 0; i < materialIndexBuffer.length; ++i) {
    if (materialIndexBuffer[i] !== prevMaterialIndex) {
      geo.addGroup(startIndex, i - startIndex, prevMaterialIndex);

      prevMaterialIndex = materialIndexBuffer[i];
      startIndex = i;
    }
  }

  return geo;
}

/**
 * Parses normal information for geometry.
 * @param {FBXGeometryNode} geometryNode
 * @returns {{dataSize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}}
 */
function getNormals(NormalNode) {
  var mappingType = NormalNode.properties.MappingInformationType;
  var referenceType = NormalNode.properties.ReferenceInformationType;
  var buffer = parseFloatArray(NormalNode.subNodes.Normals.properties.a);
  var indexBuffer = [];
  if (referenceType === 'IndexToDirect') {
    if ('NormalIndex' in NormalNode.subNodes) {
      indexBuffer = parseIntArray(NormalNode.subNodes.NormalIndex.properties.a);

    } else if ('NormalsIndex' in NormalNode.subNodes) {
      indexBuffer =
          parseIntArray(NormalNode.subNodes.NormalsIndex.properties.a);
    }
  }

  return {
    dataSize: 3,
    buffer: buffer,
    indices: indexBuffer,
    mappingType: mappingType,
    referenceType: referenceType
  };
}

/**
 * Parses UV information for geometry.
 * @param {FBXGeometryNode} geometryNode
 * @returns {{dataSize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}}
 */
function getUVs(UVNode) {
  var mappingType = UVNode.properties.MappingInformationType;
  var referenceType = UVNode.properties.ReferenceInformationType;
  var buffer = parseFloatArray(UVNode.subNodes.UV.properties.a);
  var indexBuffer = [];
  if (referenceType === 'IndexToDirect') {
    indexBuffer = parseIntArray(UVNode.subNodes.UVIndex.properties.a);
  }

  return {
    dataSize: 2,
    buffer: buffer,
    indices: indexBuffer,
    mappingType: mappingType,
    referenceType: referenceType
  };
}

/**
 * Parses Vertex Color information for geometry.
 * @param {FBXGeometryNode} geometryNode
 * @returns {{dataSize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}}
 */
function getColors(ColorNode) {
  var mappingType = ColorNode.properties.MappingInformationType;
  var referenceType = ColorNode.properties.ReferenceInformationType;
  var buffer = parseFloatArray(ColorNode.subNodes.Colors.properties.a);
  var indexBuffer = [];
  if (referenceType === 'IndexToDirect') {
    indexBuffer = parseFloatArray(ColorNode.subNodes.ColorIndex.properties.a);
  }

  return {
    dataSize: 4,
    buffer: buffer,
    indices: indexBuffer,
    mappingType: mappingType,
    referenceType: referenceType
  };
}

/**
 * Parses material application information for geometry.
 * @param {FBXGeometryNode}
 * @returns {{dataSize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}}
 */
function getMaterials(MaterialNode) {
  var mappingType = MaterialNode.properties.MappingInformationType;
  var referenceType = MaterialNode.properties.ReferenceInformationType;

  if (mappingType === 'NoMappingInformation') {
    return {
      dataSize: 1,
      buffer: [0],
      indices: [0],
      mappingType: 'AllSame',
      referenceType: referenceType
    };
  }

  var materialIndexBuffer =
      parseIntArray(MaterialNode.subNodes.Materials.properties.a);

  // Since materials are stored as indices, there's a bit of a mismatch between
  // FBX and what we expect.  So we create an intermediate buffer that points to
  // the index in the buffer, for conforming with the other functions we've
  // written for other data.
  var materialIndices = [];

  for (var materialIndexBufferIndex = 0,
           materialIndexBufferLength = materialIndexBuffer.length;
       materialIndexBufferIndex < materialIndexBufferLength;
       ++materialIndexBufferIndex) {
    materialIndices.push(materialIndexBufferIndex);
  }

  return {
    dataSize: 1,
    buffer: materialIndexBuffer,
    indices: materialIndices,
    mappingType: mappingType,
    referenceType: referenceType
  };
}

/**
 * Function uses the infoObject and given indices to return value array of
 * object.
 * @param {number} polygonVertexIndex - Index of vertex in draw order (which index of the index buffer refers to this vertex).
 * @param {number} polygonIndex - Index of polygon in geometry.
 * @param {number} vertexIndex - Index of vertex inside vertex buffer (used because some data refers to old index buffer that we don't use anymore).
 * @param {{datasize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}} infoObject - Object containing data and how to access data.
 * @returns {number[]}
 */

var dataArray = [];

var GetData = {

  ByPolygonVertex: {

    /**
     * Function uses the infoObject and given indices to return value array of
     * object.
     * @param {number} polygonVertexIndex - Index of vertex in draw order (which index of the index buffer refers to this vertex).
     * @param {number} polygonIndex - Index of polygon in geometry.
     * @param {number} vertexIndex - Index of vertex inside vertex buffer (used because some data refers to old index buffer that we don't use anymore).
     * @param {{datasize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}} infoObject - Object containing data and how to access data.
     * @returns {number[]}
     */
    Direct: function(
        polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {

      var from = (polygonVertexIndex * infoObject.dataSize);
      var to = (polygonVertexIndex * infoObject.dataSize) + infoObject.dataSize;

      // return infoObject.buffer.slice( from, to );
      return slice(dataArray, infoObject.buffer, from, to);

    },

    /**
     * Function uses the infoObject and given indices to return value array of
     * object.
     * @param {number} polygonVertexIndex - Index of vertex in draw order (which index of the index buffer refers to this vertex).
     * @param {number} polygonIndex - Index of polygon in geometry.
     * @param {number} vertexIndex - Index of vertex inside vertex buffer (used because some data refers to old index buffer that we don't use anymore).
     * @param {{datasize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}} infoObject - Object containing data and how to access data.
     * @returns {number[]}
     */
    IndexToDirect: function(
        polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {

      var index = infoObject.indices[polygonVertexIndex];
      var from = (index * infoObject.dataSize);
      var to = (index * infoObject.dataSize) + infoObject.dataSize;

      // return infoObject.buffer.slice( from, to );
      return slice(dataArray, infoObject.buffer, from, to);

    }

  },

  ByPolygon: {

    /**
     * Function uses the infoObject and given indices to return value array of
     * object.
     * @param {number} polygonVertexIndex - Index of vertex in draw order (which index of the index buffer refers to this vertex).
     * @param {number} polygonIndex - Index of polygon in geometry.
     * @param {number} vertexIndex - Index of vertex inside vertex buffer (used because some data refers to old index buffer that we don't use anymore).
     * @param {{datasize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}} infoObject - Object containing data and how to access data.
     * @returns {number[]}
     */
    Direct: function(
        polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {

      var from = polygonIndex * infoObject.dataSize;
      var to = polygonIndex * infoObject.dataSize + infoObject.dataSize;

      // return infoObject.buffer.slice( from, to );
      return slice(dataArray, infoObject.buffer, from, to);

    },

    /**
     * Function uses the infoObject and given indices to return value array of
     * object.
     * @param {number} polygonVertexIndex - Index of vertex in draw order (which index of the index buffer refers to this vertex).
     * @param {number} polygonIndex - Index of polygon in geometry.
     * @param {number} vertexIndex - Index of vertex inside vertex buffer (used because some data refers to old index buffer that we don't use anymore).
     * @param {{datasize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}} infoObject - Object containing data and how to access data.
     * @returns {number[]}
     */
    IndexToDirect: function(
        polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {

      var index = infoObject.indices[polygonIndex];
      var from = index * infoObject.dataSize;
      var to = index * infoObject.dataSize + infoObject.dataSize;

      // return infoObject.buffer.slice( from, to );
      return slice(dataArray, infoObject.buffer, from, to);

    }

  },

  ByVertice: {

    Direct: function(
        polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {

      var from = (vertexIndex * infoObject.dataSize);
      var to = (vertexIndex * infoObject.dataSize) + infoObject.dataSize;

      // return infoObject.buffer.slice( from, to );
      return slice(dataArray, infoObject.buffer, from, to);

    }

  },

  AllSame: {

    /**
     * Function uses the infoObject and given indices to return value array of
     * object.
     * @param {number} polygonVertexIndex - Index of vertex in draw order (which index of the index buffer refers to this vertex).
     * @param {number} polygonIndex - Index of polygon in geometry.
     * @param {number} vertexIndex - Index of vertex inside vertex buffer (used because some data refers to old index buffer that we don't use anymore).
     * @param {{datasize: number, buffer: number[], indices: number[], mappingType: string, referenceType: string}} infoObject - Object containing data and how to access data.
     * @returns {number[]}
     */
    IndexToDirect: function(
        polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {

      var from = infoObject.indices[0] * infoObject.dataSize;
      var to =
          infoObject.indices[0] * infoObject.dataSize + infoObject.dataSize;

      // return infoObject.buffer.slice( from, to );
      return slice(dataArray, infoObject.buffer, from, to);

    }

  }

};

function getData(polygonVertexIndex, polygonIndex, vertexIndex, infoObject) {
  return GetData[infoObject.mappingType][infoObject.referenceType](
      polygonVertexIndex, polygonIndex, vertexIndex, infoObject);
}

/**
 * Specialty function for parsing NurbsCurve based Geometry Nodes.
 * @param {FBXGeometryNode} geometryNode
 * @param {{parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}} relationships
 * @returns {THREE.BufferGeometry}
 */
function parseNurbsGeometry(geometryNode) {
  if (THREE.NURBSCurve === undefined) {
    console.error(
        'THREE.FBXLoader: The loader relies on THREE.NURBSCurve for any nurbs present in the model. Nurbs will show up as empty geometry.');
    return new THREE.BufferGeometry();
  }

  var order = parseInt(geometryNode.properties.Order);

  if (isNaN(order)) {
    console.error(
        'THREE.FBXLoader: Invalid Order %s given for geometry ID: %s',
        geometryNode.properties.Order, geometryNode.id);
    return new THREE.BufferGeometry();
  }

  var degree = order - 1;

  var knots = parseFloatArray(geometryNode.subNodes.KnotVector.properties.a);
  var controlPoints = [];
  var pointsValues = parseFloatArray(geometryNode.subNodes.Points.properties.a);

  for (var i = 0, l = pointsValues.length; i < l; i += 4) {
    controlPoints.push(new THREE.Vector4().fromArray(pointsValues, i));
  }

  var startKnot, endKnot;

  if (geometryNode.properties.Form === 'Closed') {
    controlPoints.push(controlPoints[0]);

  } else if (geometryNode.properties.Form === 'Periodic') {
    startKnot = degree;
    endKnot = knots.length - 1 - startKnot;

    for (var i = 0; i < degree; ++i) {
      controlPoints.push(controlPoints[i]);
    }
  }

  var curve =
      new THREE.NURBSCurve(degree, knots, controlPoints, startKnot, endKnot);
  var vertices = curve.getPoints(controlPoints.length * 7);

  var positions = new Float32Array(vertices.length * 3);

  for (var i = 0, l = vertices.length; i < l; ++i) {
    vertices[i].toArray(positions, i * 3);
  }

  var geometry = new THREE.BufferGeometry();
  geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));

  return geometry;
}

/**
 * Finally generates Scene graph and Scene graph Objects.
 * @param {{Objects: {subNodes: {Model: Object.<number, FBXModelNode>}}}} FBXTree
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @param {Map<number, {map: Map<number, {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}>, array: {FBX_ID: number, indices: number[], weights: number[], transform: number[], transformLink: number[], linkMode: string}[], skeleton: THREE.Skeleton|null}>} deformers
 * @param {Map<number, THREE.BufferGeometry>} geometryMap
 * @param {Map<number, THREE.Material>} materialMap
 * @returns {THREE.Group}
 */
function parseScene(FBXTree, connections, deformers, geometryMap, materialMap) {
  var sceneGraph = new THREE.Group();

  var ModelNode = FBXTree.Objects.subNodes.Model;

  /**
   * @type {Array.<THREE.Object3D>}
   */
  var modelArray = [];

  /**
   * @type {Map.<number, THREE.Object3D>}
   */
  var modelMap = new Map();

  for (var nodeID in ModelNode) {
    var id = parseInt(nodeID);
    var node = ModelNode[nodeID];
    var conns = connections.get(id);
    var model = null;

    if(conns) {
    for (var i = 0; i < conns.parents.length; ++i) {
      for (var FBX_ID in deformers) {
        var deformer = deformers[FBX_ID];
        var subDeformers = deformer.map;
        var subDeformer = subDeformers[conns.parents[i].ID];

        if (subDeformer) {
          var model2 = model;
          model = new THREE.Bone();
          deformer.bones[subDeformer.index] = model;

          // seems like we need this not to make non-connected bone, maybe?
          // TODO: confirm
          if (model2 !== null) model.add(model2);
        }
      }
    }
  }

    if (!model) {
      switch (node.attrType) {
        case 'Camera':
          /* ***********
           * Supported camera types:
           * PerspectiveCamera
           * OrthographicCamera
           ************** */
          var cameraAttribute;

          for (var childrenIndex = 0, childrenLength = conns.children.length;
               childrenIndex < childrenLength; ++childrenIndex) {
            var childID = conns.children[childrenIndex].ID;

            var attr = FBXTree.Objects.subNodes.NodeAttribute[childID];

            if (attr !== undefined && attr.properties !== undefined) {
              cameraAttribute = attr.properties;
            }
          }

          if (cameraAttribute === undefined) {
            model = new THREE.Object3D();

          } else {
            var type = 0;
            if (cameraAttribute.CameraProjectionType !== undefined &&
                (cameraAttribute.CameraProjectionType.value === '1' ||
                 cameraAttribute.CameraProjectionType.value === 1)) {
              type = 1;
            }

            var nearClippingPlane = 1;
            if (cameraAttribute.NearPlane !== undefined) {
              nearClippingPlane = cameraAttribute.NearPlane.value / 1000;
            }

            var farClippingPlane = 1000;
            if (cameraAttribute.FarPlane !== undefined) {
              farClippingPlane = cameraAttribute.FarPlane.value / 1000;
            }


            var width = window.innerWidth;
            var height = window.innerHeight;

            if (cameraAttribute.AspectWidth !== undefined &&
                cameraAttribute.AspectHeight !== undefined) {
              width = parseFloat(cameraAttribute.AspectWidth.value);
              height = parseFloat(cameraAttribute.AspectHeight.value);
            }

            var aspect = width / height;

            var fov = 45;
            if (cameraAttribute.FieldOfView !== undefined) {
              fov = parseFloat(cameraAttribute.FieldOfView.value);
            }

            switch (type) {
              case '0':  // Perspective
              case 0:
                model = new THREE.PerspectiveCamera(
                    fov, aspect, nearClippingPlane, farClippingPlane);
                break;

              case '1':  // Orthographic
              case 1:
                model = new THREE.OrthographicCamera(
                    -width / 2, width / 2, height / 2, -height / 2,
                    nearClippingPlane, farClippingPlane);
                break;

              default:
                console.warn(
                    'THREE.FBXLoader: Unknown camera type ' + type + '.');
                model = new THREE.Object3D();
                break;
            }
          }

          break;

        case 'Light':
          /* ***********
           * Supported light types:
           * DirectionalLight
           * PointLight
           * SpotLight
           ************** */

          var lightAttribute;

          if(conns) {
          for (var childrenIndex = 0, childrenLength = conns.children.length;
               childrenIndex < childrenLength; ++childrenIndex) {
            var childID = conns.children[childrenIndex].ID;

            var attr = FBXTree.Objects.subNodes.NodeAttribute[childID];

            if (attr !== undefined && attr.properties !== undefined) {
              lightAttribute = attr.properties;
            }
          }
          }

          if (lightAttribute === undefined) {
            model = new THREE.Object3D();

          } else {
            var type;

            // LightType can be undefined for Point lights
            if (lightAttribute.LightType === undefined) {
              type = 0;

            } else {
              type = lightAttribute.LightType.value;
            }

            var color = 0xffffff;

            if (lightAttribute.Color !== undefined) {
              var temp = lightAttribute.Color.value.split(',');

              var r = parseFloat(temp[0]);
              var g = parseFloat(temp[1]);
              var b = parseFloat(temp[1]);

              color = new THREE.Color(r, g, b);
            }

            var intensity = (lightAttribute.Intensity === undefined) ?
                1 :
                lightAttribute.Intensity.value / 100;

            // light disabled
            if (lightAttribute.CastLightOnObject !== undefined &&
                (lightAttribute.CastLightOnObject.value === '0' ||
                 lightAttribute.CastLightOnObject.value === 0)) {
              intensity = 0;
            }

            var distance = 0;
            if (lightAttribute.FarAttenuationEnd !== undefined) {
              if (lightAttribute.EnableFarAttenuation !== undefined &&
                  (lightAttribute.EnableFarAttenuation.value === '0' ||
                   lightAttribute.EnableFarAttenuation.value === 0)) {
                distance = 0;

              } else {
                distance = lightAttribute.FarAttenuationEnd.value / 1000;
              }
            }

            // TODO
            // could be calculated linearly from FarAttenuationStart to
            // FarAttenuationEnd?
            var decay = 1;

            switch (type) {
              case '0':  // Point
              case 0:
                model = new THREE.PointLight(color, intensity, distance, decay);
                break;

              case '1':  // Directional
              case 1:
                model = new THREE.DirectionalLight(color, intensity);
                break;

              case '2':  // Spot
              case 2:
                var angle = Math.PI / 3;

                if (lightAttribute.InnerAngle !== undefined) {
                  angle = THREE.Math.degToRad(lightAttribute.InnerAngle.value);
                }

                var penumbra = 0;
                if (lightAttribute.OuterAngle !== undefined) {
                  // TODO: this is not correct - FBX calculates outer and inner
                  // angle in degrees with OuterAngle > InnerAngle && OuterAngle
                  // <= Math.PI while three.js uses a penumbra between (0, 1) to
                  // attenuate the inner angle
                  penumbra =
                      THREE.Math.degToRad(lightAttribute.OuterAngle.value);
                  penumbra = Math.max(penumbra, 1);
                }

                model = new THREE.SpotLight(
                    color, intensity, distance, angle, penumbra, decay);
                break;

              default:
                console.warn(
                    'THREE.FBXLoader: Unknown light type ' +
                    lightAttribute.LightType.value +
                    ', defaulting to a THREE.PointLight.');
                model = new THREE.PointLight(color, intensity);
                break;
            }

            if (lightAttribute.CastShadows !== undefined &&
                (lightAttribute.CastShadows.value === '1' ||
                 lightAttribute.CastShadows.value === 1)) {
              model.castShadow = true;
            }
          }

          break;

        case 'Marker':
        case 'OpticalMarker':
          var markerSize = 1;
          var geometry = new THREE.SphereGeometry(markerSize, 32, 32);
          var material = new THREE.MeshPhongMaterial( {color: 0xffffff});

          model = new THREE.Mesh(geometry, material);
          model.userData.type = 'Marker';

          let name = node.attrName
          if(name == "" || name == undefined || name.toLowerCase().indexOf('unlabeled') != -1) {
            material.color.setHex(0xff7300);
            model.userData.type = 'mMarker';
          }
          break;
        case 'Mesh':
          /**
           * @type {?THREE.BufferGeometry}
           */
          var geometry = null;

          /**
           * @type {THREE.MultiMaterial|THREE.Material}
           */
          var material = null;

          /**
           * @type {Array.<THREE.Material>}
           */
          var materials = [];

          if(conns){
          for (var childrenIndex = 0, childrenLength = conns.children.length;
               childrenIndex < childrenLength; ++childrenIndex) {
            var child = conns.children[childrenIndex];

            if (geometryMap.has(child.ID)) {
              geometry = geometryMap.get(child.ID);
            }

            if (materialMap.has(child.ID)) {
              materials.push(materialMap.get(child.ID));
            }
          }
          }
          if (materials.length > 1) {
            material = materials;

          } else if (materials.length > 0) {
            material = materials[0];

          } else {
            material = new THREE.MeshStandardMaterial({color: 0x3300ff});
            materials.push(material);
          }
          if ('color' in geometry.attributes) {
            for (var materialIndex = 0, numMaterials = materials.length;
                 materialIndex < numMaterials; ++materialIndex) {
              materials[materialIndex].vertexColors = THREE.VertexColors;
            }
          }
          if (geometry.FBX_Deformer) {
            for (var materialsIndex = 0, materialsLength = materials.length;
                 materialsIndex < materialsLength; ++materialsIndex) {
              materials[materialsIndex].skinning = true;
            }
            model = new THREE.SkinnedMesh(geometry, material);

          } else {
            model = new THREE.Mesh(geometry, material);
          }
          break;

        case 'NurbsCurve':
          var geometry = null;

          if(conns) {
          for (var childrenIndex = 0, childrenLength = conns.children.length;
               childrenIndex < childrenLength; ++childrenIndex) {
            var child = conns.children[childrenIndex];

            if (geometryMap.has(child.ID)) {
              geometry = geometryMap.get(child.ID);
            }
          }
          }

          // FBX does not list materials for Nurbs lines, so we'll just put our
          // own in here.
          material =
              new THREE.LineBasicMaterial({color: 0x3300ff, linewidth: 5});
          model = new THREE.Line(geometry, material);
          break;

        case 'LimbNode':
          model = new THREE.Bone();
          break;
        default:
          model = new THREE.Bone();  // THREE.Group();
          break;
      }
    }

    model.name = THREE.PropertyBinding.sanitizeNodeName(node.attrName);
    model.FBX_ID = id;

    modelArray.push(model);
    modelMap.set(id, model);
  }

  // var orderEnum = ['ZYX', 'ZYX', 'ZYX', 'ZYX', 'YXZ', 'ZYX', 'XYZ'];
  var orderEnum = ['XYZ', 'XZY', 'YZX', 'YXZ', 'ZXY', 'ZYX', 'XYZ'];

  for (var modelArrayIndex = 0, modelArrayLength = modelArray.length;
       modelArrayIndex < modelArrayLength; ++modelArrayIndex) {
    var model = modelArray[modelArrayIndex];

    var node = ModelNode[model.FBX_ID];
    var order = orderEnum[0];

    if ('RotationOrder' in node.properties)
      order = orderEnum[node.properties.RotationOrder.value];

    if ('Lcl_Translation' in node.properties) {
      model.position.fromArray(
          parseFloatArray(node.properties.Lcl_Translation.value));
    }

    if ('Lcl_Rotation' in node.properties) {
      var rotation = parseFloatArray(node.properties.Lcl_Rotation.value)
                         .map(degreeToRadian);
      // order = 'YXZ'; //rokoko

      rotation.push(order);
      model.rotation.fromArray(rotation);
    }
    else {
      var rotation = [0,0,0]
      rotation.push(order);
      model.rotation.fromArray(rotation);
    }

    if ('Lcl_Scaling' in node.properties) {
      model.scale.fromArray(parseFloatArray(node.properties.Lcl_Scaling.value));
    }

    if ('PreRotation' in node.properties) {
      // order = 'YXZ'; //rokoko
      var preRotations = new THREE.Euler().setFromVector3(
          parseVector3(node.properties.PreRotation).multiplyScalar(DEG2RAD),
          order);
      preRotations = new THREE.Quaternion().setFromEuler(preRotations);
      var currentRotation = new THREE.Quaternion().setFromEuler(model.rotation);
      preRotations.multiply(currentRotation);

      if ('PostRotation' in node.properties) {
        var postRotations = new THREE.Euler().setFromVector3(
          parseVector3(node.properties.PostRotation).multiplyScalar(DEG2RAD), order);
        postRotations = new THREE.Quaternion().setFromEuler(postRotations).inverse();
        preRotations.multiply(postRotations);
      }

      model.rotation.setFromQuaternion(preRotations, order);
    }

    // allow transformed pivots - see
    // https://github.com/mrdoob/three.js/issues/11895
    if ('GeometricTranslation' in node.properties) {
      var array = node.properties.GeometricTranslation.value;

      model.traverse(function(child) {

        if (child.geometry) {
          child.geometry.translate(array[0], array[1], array[2]);
        }

      });
    }

    if ('LookAtProperty' in node.properties) {
      var conns = connections.get(model.FBX_ID);

      if(conns) {
      for (var childrenIndex = 0, childrenLength = conns.children.length;
           childrenIndex < childrenLength; ++childrenIndex) {
        var child = conns.children[childrenIndex];

        if (child.relationship === 'LookAtProperty' ||
            child.relationship === ' "LookAtProperty') {
          var lookAtTarget = FBXTree.Objects.subNodes.Model[child.ID];

          if ('Lcl_Translation' in lookAtTarget.properties) {
            var pos = lookAtTarget.properties.Lcl_Translation.value.split(',')
                          .map(function(val) {

                            return parseFloat(val);

                          });

            // DirectionalLight, SpotLight
            if (model.target !== undefined) {
              model.target.position.set(pos[0], pos[1], pos[2]);
              sceneGraph.add(model.target);


            } else {  // Cameras and other Object3Ds

              model.lookAt(new THREE.Vector3(pos[0], pos[1], pos[2]));
            }
          }
        }
      }
    }
    }

    var conns = connections.get(model.FBX_ID);
    if(conns) {
    for (var parentIndex = 0; parentIndex < conns.parents.length;
         parentIndex++) {
      var pIndex = findIndex(modelArray, function(mod) {

        return mod.FBX_ID === conns.parents[parentIndex].ID;

      });
      if (pIndex > -1) {
        modelArray[pIndex].add(model);
        break;
      }
    }
    }
    if (model.parent === null) {
      sceneGraph.add(model);
    }
  }


  // Now with the bones created, we can update the skeletons and bind them to
  // the skinned meshes.
  sceneGraph.updateMatrixWorld(true);

  // Put skeleton into bind pose.
  var BindPoseNode = FBXTree.Objects.subNodes.Pose;
  for (var nodeID in BindPoseNode) {
    if (BindPoseNode[nodeID].attrType === 'BindPose') {
      BindPoseNode = BindPoseNode[nodeID];
      break;
    }
  }
  if (BindPoseNode) {
    var PoseNode = BindPoseNode.subNodes.PoseNode;
    var worldMatrices = new Map();

    for (var PoseNodeIndex = 0, PoseNodeLength = PoseNode.length;
         PoseNodeIndex < PoseNodeLength; ++PoseNodeIndex) {
      var node = PoseNode[PoseNodeIndex];

      var rawMatWrd = parseMatrixArray(node.subNodes.Matrix.properties.a);

      worldMatrices.set(parseInt(node.id), rawMatWrd);
    }
  }

  for (var FBX_ID in deformers) {
    var deformer = deformers[FBX_ID];
    var subDeformers = deformer.map;

    for (var key in subDeformers) {
      var subDeformer = subDeformers[key];
      var subDeformerIndex = subDeformer.index;

      /**
       * @type {THREE.Bone}
       */
      var bone = deformer.bones[subDeformerIndex];
      if (!worldMatrices.has(bone.FBX_ID)) {
        break;
      }
      var mat = worldMatrices.get(bone.FBX_ID);
      bone.matrixWorld.copy(mat);
    }

    // Now that skeleton is in bind pose, bind to model.
    deformer.skeleton = new THREE.Skeleton(deformer.bones);

    var conns = connections.get(deformer.FBX_ID);
    var parents = conns.parents;

    for (var parentsIndex = 0, parentsLength = parents.length;
         parentsIndex < parentsLength; ++parentsIndex) {
      var parent = parents[parentsIndex];

      if (geometryMap.has(parent.ID)) {
        var geoID = parent.ID;
        var geoConns = connections.get(geoID);

        for (var i = 0; i < geoConns.parents.length; ++i) {
          if (modelMap.has(geoConns.parents[i].ID)) {
            var model = modelMap.get(geoConns.parents[i].ID);
            // ASSERT model typeof SkinnedMesh
            model.bind(deformer.skeleton, model.matrixWorld);
            break;
          }
        }
      }
    }
  }

  // Skeleton is now bound, return objects to starting
  // world positions.
  sceneGraph.updateMatrixWorld(true);

  // Silly hack with the animation parsing.  We're gonna pretend the scene graph
  // has a skeleton to attach animations to, since FBXs treat animations as
  // animations for the entire scene, not just for individual objects.
  sceneGraph.skeleton = {bones: modelArray};

  var globalTime = null;
  var takesLength = [];

  //Getting global time information from the take
  if(FBXTree.Takes && FBXTree.Takes.subNodes &&
    FBXTree.Takes.subNodes.Take.subNodes &&
    FBXTree.Takes.subNodes.Take.subNodes.LocalTime)
  {
    globalTime = { start: 0, stop: 0};
    for(var prop in FBXTree.Takes.subNodes.Take)
    {
      var candidate = FBXTree.Takes.subNodes.Take;
      var isTake = false;
      if(prop == "name" && candidate[prop] == "Take")
        isTake = true;

      if(candidate[prop]["name"] == "Take") {
        candidate = candidate[prop];
        isTake = true;
      }

      if(isTake)
      {
        var start = 0;
        var stop = 0;
        for (var prop in candidate.subNodes.LocalTime) {
            start = convertFBXTimeToSeconds(candidate.subNodes.LocalTime[prop].propertyList[0]);
            stop = convertFBXTimeToSeconds(candidate.subNodes.LocalTime[prop].propertyList[1]);
        }
        takesLength.push({id: candidate["id"], start: start, stop: stop });
      }
    }

    for (var prop in FBXTree.Takes.subNodes.Take.subNodes.LocalTime) {
      globalTime.start = convertFBXTimeToSeconds(FBXTree.Takes.subNodes.Take.subNodes.LocalTime[prop].propertyList[0]);
      globalTime.stop = convertFBXTimeToSeconds(FBXTree.Takes.subNodes.Take.subNodes.LocalTime[prop].propertyList[1]);
    }
  }

  var animations = parseAnimations(FBXTree, connections, sceneGraph);

  addAnimations(sceneGraph, animations, globalTime, takesLength);


  // Parse ambient color - if it's not set to black (default), create an ambient
  // light
  if ('GlobalSettings' in FBXTree &&
      'AmbientColor' in FBXTree.GlobalSettings.properties) {
    var ambientColor = FBXTree.GlobalSettings.properties.AmbientColor.value;
    var r = ambientColor[0];
    var g = ambientColor[1];
    var b = ambientColor[2];

    if (r !== 0 || g !== 0 || b !== 0) {
      var color = new THREE.Color(r, g, b);
      sceneGraph.add(new THREE.AmbientLight(color, 1));
    }
  }


  return sceneGraph;
}

/**
 * Parses animation information from FBXTree and generates an
 * AnimationInfoObject.
 * @param {{Objects: {subNodes: {AnimationCurveNode: any, AnimationCurve: any, AnimationLayer: any, AnimationStack: any}}}} FBXTree
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 */
function parseAnimations(FBXTree, connections, sceneGraph) {
  var rawNodes = FBXTree.Objects.subNodes.AnimationCurveNode;
  var rawCurves = FBXTree.Objects.subNodes.AnimationCurve;
  var rawLayers = FBXTree.Objects.subNodes.AnimationLayer;
  var rawStacks = FBXTree.Objects.subNodes.AnimationStack;

  /**
   * @type {{
                   curves: Map<number, {
                   T: {
                          id: number;
                          attr: string;
                          internalID: number;
                          attrX: boolean;
                          attrY: boolean;
                          attrZ: boolean;
                          containerBoneID: number;
                          containerID: number;
                          curves: {
                                  x: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  y: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  z: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                          };
                  },
                   R: {
                          id: number;
                          attr: string;
                          internalID: number;
                          attrX: boolean;
                          attrY: boolean;
                          attrZ: boolean;
                          containerBoneID: number;
                          containerID: number;
                          curves: {
                                  x: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  y: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  z: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                          };
                  },
                   S: {
                          id: number;
                          attr: string;
                          internalID: number;
                          attrX: boolean;
                          attrY: boolean;
                          attrZ: boolean;
                          containerBoneID: number;
                          containerID: number;
                          curves: {
                                  x: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  y: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  z: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                          };
                  }
           }>,
           layers: Map<number, {
                  T: {
                          id: number;
                          attr: string;
                          internalID: number;
                          attrX: boolean;
                          attrY: boolean;
                          attrZ: boolean;
                          containerBoneID: number;
                          containerID: number;
                          curves: {
                                  x: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  y: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  z: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                          },
                  },
                  R: {
                          id: number;
                          attr: string;
                          internalID: number;
                          attrX: boolean;
                          attrY: boolean;
                          attrZ: boolean;
                          containerBoneID: number;
                          containerID: number;
                          curves: {
                                  x: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  y: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  z: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                          },
                  },
                  S: {
                          id: number;
                          attr: string;
                          internalID: number;
                          attrX: boolean;
                          attrY: boolean;
                          attrZ: boolean;
                          containerBoneID: number;
                          containerID: number;
                          curves: {
                                  x: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  y: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                                  z: {
                                          version: any;
                                          id: number;
                                          internalID: number;
                                          times: number[];
                                          values: number[];
                                          attrFlag: number[];
                                          attrData: number[];
                                  };
                          },
                  }
                  }[]>,
           stacks: Map<number, {
                   name: string,
                   layers: {
                          T: {
                                  id: number;
                                  attr: string;
                                  internalID: number;
                                  attrX: boolean;
                                  attrY: boolean;
                                  attrZ: boolean;
                                  containerBoneID: number;
                                  containerID: number;
                                  curves: {
                                          x: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                          y: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                          z: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                  };
                          };
                          R: {
                                  id: number;
                                  attr: string;
                                  internalID: number;
                                  attrX: boolean;
                                  attrY: boolean;
                                  attrZ: boolean;
                                  containerBoneID: number;
                                  containerID: number;
                                  curves: {
                                          x: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                          y: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                          z: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                  };
                          };
                          S: {
                                  id: number;
                                  attr: string;
                                  internalID: number;
                                  attrX: boolean;
                                  attrY: boolean;
                                  attrZ: boolean;
                                  containerBoneID: number;
                                  containerID: number;
                                  curves: {
                                          x: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                          y: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                          z: {
                                                  version: any;
                                                  id: number;
                                                  internalID: number;
                                                  times: number[];
                                                  values: number[];
                                                  attrFlag: number[];
                                                  attrData: number[];
                                          };
                                  };
                          };
                  }[][],
           length: number,
           frames: number }>,
           length: number,
           fps: number,
           frames: number
   }}
   */

  var TimeModeEnum = [0, 120, 100, 60, 50, 48, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  // Parse ambient color - if it's not set to black (default), create an ambient
  // light
  if ('GlobalSettings' in FBXTree &&
      'TimeMode' in FBXTree.GlobalSettings.properties) {
    fps = TimeModeEnum[FBXTree.GlobalSettings.properties.TimeMode.value];
  } else
    fps = 0;

  var returnObject = {
    curves: new Map(),
    layers: {},
    stacks: {},
    length: 0,
    fps: fps,
    frames: 0
  };

  /**
   * @type {Array.<{
                  id: number;
                  attr: string;
                  internalID: number;
                  attrX: boolean;
                  attrY: boolean;
                  attrZ: boolean;
                  containerBoneID: number;
                  containerID: number;
          }>}
   */
  var animationCurveNodes = [];
  for (var nodeID in rawNodes) {
    if (nodeID.match(/\d+/)) {
      var animationNode = parseAnimationNode(
          FBXTree, rawNodes[nodeID], connections, sceneGraph);
      animationCurveNodes.push(animationNode);
    }
  }

  /**
   * @type {Map.<number, {
                  id: number,
                  attr: string,
                  internalID: number,
                  attrX: boolean,
                  attrY: boolean,
                  attrZ: boolean,
                  containerBoneID: number,
                  containerID: number,
                  curves: {
                          x: {
                                  version: any,
                                  id: number,
                                  internalID: number,
                                  times: number[],
                                  values: number[],
                                  attrFlag: number[],
                                  attrData: number[],
                          },
                          y: {
                                  version: any,
                                  id: number,
                                  internalID: number,
                                  times: number[],
                                  values: number[],
                                  attrFlag: number[],
                                  attrData: number[],
                          },
                          z: {
                                  version: any,
                                  id: number,
                                  internalID: number,
                                  times: number[],
                                  values: number[],
                                  attrFlag: number[],
                                  attrData: number[],
                          }
                  }
          }>}
   */
  var tmpMap = new Map();
  for (var animationCurveNodeIndex = 0;
       animationCurveNodeIndex < animationCurveNodes.length;
       ++animationCurveNodeIndex) {
    if (animationCurveNodes[animationCurveNodeIndex] === null) {
      continue;
    }
    tmpMap.set(
        animationCurveNodes[animationCurveNodeIndex].id,
        animationCurveNodes[animationCurveNodeIndex]);
  }


  /**
   * @type {{
                  version: any,
                  id: number,
                  internalID: number,
                  times: number[],
                  values: number[],
                  attrFlag: number[],
                  attrData: number[],
          }[]}
   */
  var animationCurves = [];
  for (nodeID in rawCurves) {
    if (nodeID.match(/\d+/)) {
      var animationCurve = parseAnimationCurve(rawCurves[nodeID]);

      // seems like this check would be necessary?
      if (!connections.has(animationCurve.id)) continue;

      animationCurves.push(animationCurve);

      var firstParentConn = connections.get(animationCurve.id).parents[0];
      var firstParentID = firstParentConn.ID;
      var firstParentRelationship = firstParentConn.relationship;
      var axis = '';

      if (firstParentRelationship.match(/X/)) {
        axis = 'x';

      } else if (firstParentRelationship.match(/Y/)) {
        axis = 'y';

      } else if (firstParentRelationship.match(/Z/)) {
        axis = 'z';

      } else if (firstParentRelationship.match(/Deform/)) {
        axis = 'd';
      } else {
        continue;
      }

      tmpMap.get(firstParentID).curves[axis] = animationCurve;
    }
  }

  tmpMap.forEach(function(curveNode) {

    var id = curveNode.containerBoneID;
    if (!returnObject.curves.has(id)) {
      returnObject.curves.set(id, {T: null, R: null, S: null, DeformPercent: null});
    }
    returnObject.curves.get(id)[curveNode.attr] = curveNode;
    if (curveNode.attr === 'R') {
      var curves = curveNode.curves;

      // Seems like some FBX files have AnimationCurveNode
      // which doesn't have any connected AnimationCurve.
      // Setting animation parameter for them here.

      if (curves.x === null) {
        curves.x = {version: null, times: [0.0], values: [0.0]};
      }

      if (curves.y === null) {
        curves.y = {version: null, times: [0.0], values: [0.0]};
      }

      if (curves.z === null) {
        curves.z = {version: null, times: [0.0], values: [0.0]};
      }

      let times = [];
      let values = {x: [], y: [], z: []};
      getTimesAndValues(curves, times, values);

      curves.x.values = values.x.map(degreeToRadian);
      curves.y.values = values.y.map(degreeToRadian);
      curves.z.values = values.z.map(degreeToRadian);

      if (curveNode.preRotations !== null) {

        order = curveNode.preRotations._order;
        preRotations = new THREE.Quaternion().setFromEuler(curveNode.preRotations);
        //var postRotations = curveNode.postRotations;

        var frameRotation = new THREE.Euler();
        var frameRotationQuaternion = new THREE.Quaternion();
        for (var frame = 0; frame < times.length; ++frame) {
          frameRotation.set(
              curves.x.values[frame], curves.y.values[frame],
              curves.z.values[frame], order);
          frameRotationQuaternion.setFromEuler(frameRotation)
              .premultiply(preRotations);

          /*if(curveNode.postRotations !== null)
            frameRotationQuaternion.multiply( postRotations );*/

          frameRotation.setFromQuaternion(frameRotationQuaternion, order);
          curves.x.values[frame] = frameRotation.x;
          curves.y.values[frame] = frameRotation.y;
          curves.z.values[frame] = frameRotation.z;
        }
      }
    }

  });

  for (var nodeID in rawLayers) {
    /**
     * @type {{
            T: {
                    id: number;
                    attr: string;
                    internalID: number;
                    attrX: boolean;
                    attrY: boolean;
                    attrZ: boolean;
                    containerBoneID: number;
                    containerID: number;
                    curves: {
                            x: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                            y: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                            z: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                    },
            },
            R: {
                    id: number;
                    attr: string;
                    internalID: number;
                    attrX: boolean;
                    attrY: boolean;
                    attrZ: boolean;
                    containerBoneID: number;
                    containerID: number;
                    curves: {
                            x: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                            y: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                            z: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                    },
            },
            S: {
                    id: number;
                    attr: string;
                    internalID: number;
                    attrX: boolean;
                    attrY: boolean;
                    attrZ: boolean;
                    containerBoneID: number;
                    containerID: number;
                    curves: {
                            x: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                            y: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                            z: {
                                    version: any;
                                    id: number;
                                    internalID: number;
                                    times: number[];
                                    values: number[];
                                    attrFlag: number[];
                                    attrData: number[];
                            };
                    },
            }
            }[]}
     */
    var layer = [];
    var conns = connections.get(parseInt(nodeID));
    if (conns) {
    var children = conns.children;

    for (var child of children) {
      // Skip lockInfluenceWeights
      if (tmpMap.has(child.ID)) {
        var curveNode = tmpMap.get(child.ID);
        var boneID = curveNode.containerBoneID;
        if (boneID == -1)
          boneID = curveNode.internalID;
        if (layer[boneID] === undefined) {
          layer[boneID] = {T: null, R: null, S: null, DeformPercent: null};
        }

        layer[boneID][curveNode.attr] = curveNode;
      }
    }
    }

    returnObject.layers[nodeID] = layer;
  }

  for (var nodeID in rawStacks) {
    var layers = [];
    var conns = connections.get(parseInt(nodeID));
    if(conns) {
    var children = conns.children;
    var timestamps = {max: 0, min: Number.MAX_VALUE};
    var frames = {max: 0};

    for (var child of children) {
      var currentLayer = returnObject.layers[child.ID];

      if (currentLayer !== undefined) {
        layers.push(currentLayer);

        for (var layer of currentLayer) {
          if (layer) {
            getCurveNodeMaxMinTimeStamps(layer, timestamps);
            getCurveNodeMaxFrames(layer, frames);
          }
        }
      }
    }
    }

    var frames = (returnObject.fps > 0) ?
        (timestamps.max - timestamps.min) * returnObject.fps :
        frames.max;
    // Do we have an animation clip with actual length?
    if (timestamps.max > timestamps.min) {
      returnObject.stacks[nodeID] = {
        name: rawStacks[nodeID].attrName,
        layers: layers,
        length: timestamps.max - timestamps.min,
        frames: frames
      };
    }
    // console.log(timestamps.max - timestamps.min);
    // console.log(frames);

    if (returnObject.fps == 0)
      returnObject.fps = frames / (timestamps.max - timestamps.min);
  }

  if (returnObject.fps == 0) returnObject.fps = 30;

  // console.log(returnObject.fps);

  return returnObject;
}

/**
 * @param {Object} FBXTree
 * @param {{id: number, attrName: string, properties: Object<string, any>}} animationCurveNode
 * @param {Map<number, {parents: {ID: number, relationship: string}[], children: {ID: number, relationship: string}[]}>} connections
 * @param {{skeleton: {bones: {FBX_ID: number}[]}}} sceneGraph
 */
function parseAnimationNode(
    FBXTree, animationCurveNode, connections, sceneGraph) {
  var rawModels = FBXTree.Objects.subNodes.Model;

  var returnObject = {
    /**
     * @type {number}
     */
    id: animationCurveNode.id,

    /**
     * @type {string}
     */
    attr: animationCurveNode.attrName,

    /**
     * @type {number}
     */
    internalID: animationCurveNode.id,

    /**
     * @type {boolean}
     */
    attrX: false,

    /**
     * @type {boolean}
     */
    attrY: false,

    /**
     * @type {boolean}
     */
    attrZ: false,

    /**
     * @type {number}
     */
    containerBoneID: -1,

    /**
     * @type {number}
     */
    containerID: -1,

    curves: {x: null, y: null, z: null},

    /**
     * @type {number[]}
     */
    preRotations: null,
    postRotations: null
  };

  if (returnObject.attr.match(/S|R|T|Deform/)) {
    for (var attributeKey in animationCurveNode.properties) {
      if (attributeKey.match(/X/)) {
        returnObject.attrX = true;
      }
      if (attributeKey.match(/Y/)) {
        returnObject.attrY = true;
      }
      if (attributeKey.match(/Z/)) {
        returnObject.attrZ = true;
      }
      if (attributeKey.match(/Deform/)) {
        returnObject.deformer = true;
      }
    }

  } else {
    return null;
  }

  var conns = connections.get(returnObject.id);
  if(conns) {
  var containerIndices = conns.parents;
  // var orderEnum = ['ZYX', 'ZYX', 'ZYX', 'ZYX', 'YXZ', 'ZYX', 'XYZ'];
  var orderEnum = ['XYZ', 'XZY', 'YZX', 'YXZ', 'ZXY', 'ZYX', 'XYZ'];

  for (var containerIndicesIndex = containerIndices.length - 1;
       containerIndicesIndex >= 0; --containerIndicesIndex) {
    var boneID = findIndex(sceneGraph.skeleton.bones, function(bone) {

      return bone.FBX_ID === containerIndices[containerIndicesIndex].ID;

    });
    if (boneID > -1) {
      returnObject.containerBoneID = boneID;
      returnObject.containerID = containerIndices[containerIndicesIndex].ID;
      var model = rawModels[returnObject.containerID.toString()];

      order = orderEnum[0];
      if ('RotationOrder' in model.properties) {
        order = orderEnum[model.properties.RotationOrder.value];
      }

      order = reverse(order);

      /*if ( 'PostRotation' in model.properties ) {
        var postRotations = parseVector3( model.properties.PostRotation ).multiplyScalar( Math.PI / 180 );
        returnObject.postRotations = new THREE.Quaternion().setFromEuler(new THREE.Euler().setFromVector3( postRotations, order )).inverse();
      }*/

      if ('PreRotation' in model.properties) {
        var usePrerotation = true;
        if ( 'PostRotation' in model.properties ) {
            var eps = 0.001;
            if(Math.abs(model.properties.PostRotation.value[0] - model.properties.PostRotation.value[0] < eps) &&
            Math.abs(model.properties.PostRotation.value[1] - model.properties.PostRotation.value[1] < eps) &&
            Math.abs(model.properties.PostRotation.value[2] - model.properties.PostRotation.value[2] < eps))
            usePrerotation = false; //simple hack to make noitom FBX working
        }

        if(usePrerotation)
        {
          var preRotations = parseVector3(model.properties.PreRotation)
                                .multiplyScalar(Math.PI / 180);
          returnObject.preRotations =
              new THREE.Euler().setFromVector3(preRotations, order);
        }
      }
      break;
     }
    }
  }

  return returnObject;
}

/**
 * @param {{id: number, subNodes: {KeyTime: {properties: {a: string}}, KeyValueFloat: {properties: {a: string}}, KeyAttrFlags: {properties: {a: string}}, KeyAttrDataFloat: {properties: {a: string}}}}} animationCurve
 */
function parseAnimationCurve(animationCurve) {
  return {
    version: null,
    id: animationCurve.id,
    internalID: animationCurve.id,
    times: parseFloatArray(animationCurve.subNodes.KeyTime.properties.a)
               .map(convertFBXTimeToSeconds),
    values: parseFloatArray(animationCurve.subNodes.KeyValueFloat.properties.a),

    attrFlag: parseIntArray(animationCurve.subNodes.KeyAttrFlags.properties.a),
    attrData:
        parseFloatArray(animationCurve.subNodes.KeyAttrDataFloat.properties.a)
  };
}

/**
 * Sets the maxTimeStamp and minTimeStamp variables if it has timeStamps that
 are either larger or smaller
 * than the max or min respectively.
 * @param {{
                        T: {
                                        id: number,
                                        attr: string,
                                        internalID: number,
                                        attrX: boolean,
                                        attrY: boolean,
                                        attrZ: boolean,
                                        containerBoneID: number,
                                        containerID: number,
                                        curves: {
                                                        x: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                                        y: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                                        z: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                        },
                        },
                        R: {
                                        id: number,
                                        attr: string,
                                        internalID: number,
                                        attrX: boolean,
                                        attrY: boolean,
                                        attrZ: boolean,
                                        containerBoneID: number,
                                        containerID: number,
                                        curves: {
                                                        x: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                                        y: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                                        z: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                        },
                        },
                        S: {
                                        id: number,
                                        attr: string,
                                        internalID: number,
                                        attrX: boolean,
                                        attrY: boolean,
                                        attrZ: boolean,
                                        containerBoneID: number,
                                        containerID: number,
                                        curves: {
                                                        x: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                                        y: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                                        z: {
                                                                        version:
 any, id: number, internalID: number, times: number[], values: number[],
                                                                        attrFlag:
 number[], attrData: number[],
                                                        },
                                        },
                        },
        }} layer
 */
function getCurveNodeMaxMinTimeStamps(layer, timestamps) {
  if (layer.R) {
    getCurveMaxMinTimeStamp(layer.R.curves, timestamps);
  }
  if (layer.S) {
    getCurveMaxMinTimeStamp(layer.S.curves, timestamps);
  }
  if (layer.T) {
    getCurveMaxMinTimeStamp(layer.T.curves, timestamps);
  }
}

function getCurveNodeMaxFrames(layer, frames) {
  if (layer.R) {
    getCurveMaxFrames(layer.R.curves, frames);
  }
  if (layer.S) {
    getCurveMaxFrames(layer.S.curves, frames);
  }
  if (layer.T) {
    getCurveMaxFrames(layer.T.curves, frames);
  }
}

/**
 * Sets the maxTimeStamp and minTimeStamp if one of the curve's time stamps
 * exceeds the maximum or minimum.
 * @param {{
                        x: {
                                        version: any,
                                        id: number,
                                        internalID: number,
                                        times: number[],
                                        values: number[],
                                        attrFlag: number[],
                                        attrData: number[],
                        },
                        y: {
                                        version: any,
                                        id: number,
                                        internalID: number,
                                        times: number[],
                                        values: number[],
                                        attrFlag: number[],
                                        attrData: number[],
                        },
                        z: {
                                        version: any,
                                        id: number,
                                        internalID: number,
                                        times: number[],
                                        values: number[],
                                        attrFlag: number[],
                                        attrData: number[],
                        }
        }} curve
 */
function getCurveMaxMinTimeStamp(curve, timestamps) {
  if (curve.x) {
    getCurveAxisMaxMinTimeStamps(curve.x, timestamps);
  }
  if (curve.y) {
    getCurveAxisMaxMinTimeStamps(curve.y, timestamps);
  }
  if (curve.z) {
    getCurveAxisMaxMinTimeStamps(curve.z, timestamps);
  }
}

function getCurveMaxFrames(curve, frames) {
  if (curve.x) {
    getCurveAxisMaxFrames(curve.x, frames);
  }
  if (curve.y) {
    getCurveAxisMaxFrames(curve.y, frames);
  }
  if (curve.z) {
    getCurveAxisMaxFrames(curve.z, frames);
  }
}
/**
 * Sets the maxTimeStamp and minTimeStamp if one of its timestamps exceeds the
 * maximum or minimum.
 * @param {{times: number[]}} axis
 */
function getCurveAxisMaxMinTimeStamps(axis, timestamps) {
  timestamps.max = axis.times[axis.times.length - 1] > timestamps.max ?
      axis.times[axis.times.length - 1] :
      timestamps.max;
  timestamps.min =
      axis.times[0] < timestamps.min ? axis.times[0] : timestamps.min;
}
/**
 * Sets the maxTimeStamp and minTimeStamp if one of its timestamps exceeds the
 * maximum or minimum.
 * @param {{times: number[]}} axis
 */
function getCurveAxisMaxFrames(axis, frames) {
  frames.max = (axis.times.length - 1) > frames.max ? (axis.times.length - 1) :
                                                      frames.max;
}
/**
 * @param {{
        curves: Map<number, {
                T: {
                        id: number;
                        attr: string;
                        internalID: number;
                        attrX: boolean;
                        attrY: boolean;
                        attrZ: boolean;
                        containerBoneID: number;
                        containerID: number;
                        curves: {
                                x: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                y: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                z: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                        };
                };
                R: {
                        id: number;
                        attr: string;
                        internalID: number;
                        attrX: boolean;
                        attrY: boolean;
                        attrZ: boolean;
                        containerBoneID: number;
                        containerID: number;
                        curves: {
                                x: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                y: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                z: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                        };
                };
                S: {
                        id: number;
                        attr: string;
                        internalID: number;
                        attrX: boolean;
                        attrY: boolean;
                        attrZ: boolean;
                        containerBoneID: number;
                        containerID: number;
                        curves: {
                                x: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                y: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                z: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                        };
                };
        }>;
        layers: Map<number, {
                T: {
                        id: number;
                        attr: string;
                        internalID: number;
                        attrX: boolean;
                        attrY: boolean;
                        attrZ: boolean;
                        containerBoneID: number;
                        containerID: number;
                        curves: {
                                x: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                y: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                z: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                        };
                };
                R: {
                        id: number;
                        attr: string;
                        internalID: number;
                        attrX: boolean;
                        attrY: boolean;
                        attrZ: boolean;
                        containerBoneID: number;
                        containerID: number;
                        curves: {
                                x: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                y: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                z: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                        };
                };
                S: {
                        id: number;
                        attr: string;
                        internalID: number;
                        attrX: boolean;
                        attrY: boolean;
                        attrZ: boolean;
                        containerBoneID: number;
                        containerID: number;
                        curves: {
                                x: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                y: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                                z: {
                                        version: any;
                                        id: number;
                                        internalID: number;
                                        times: number[];
                                        values: number[];
                                        attrFlag: number[];
                                        attrData: number[];
                                };
                        };
                };
        }[]>;
        stacks: Map<number, {
                name: string;
                layers: {
                        T: {
                                id: number;
                                attr: string;
                                internalID: number;
                                attrX: boolean;
                                attrY: boolean;
                                attrZ: boolean;
                                containerBoneID: number;
                                containerID: number;
                                curves: {
                                        x: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                        y: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                        z: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                };
                        };
                        R: {
                                id: number;
                                attr: string;
                                internalID: number;
                                attrX: boolean;
                                attrY: boolean;
                                attrZ: boolean;
                                containerBoneID: number;
                                containerID: number;
                                curves: {
                                        x: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                        y: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                        z: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                };
                        };
                        S: {
                                id: number;
                                attr: string;
                                internalID: number;
                                attrX: boolean;
                                attrY: boolean;
                                attrZ: boolean;
                                containerBoneID: number;
                                containerID: number;
                                curves: {
                                        x: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                        y: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                        z: {
                                                version: any;
                                                id: number;
                                                internalID: number;
                                                times: number[];
                                                values: number[];
                                                attrFlag: number[];
                                                attrData: number[];
                                        };
                                };
                        };
                }[][];
                length: number;
                frames: number;
        }>;
        length: number;
        fps: number;
        frames: number;
}} animations,
 * @param {{skeleton: { bones: THREE.Bone[]}}} group
 */
function addAnimations(group, animations, globalTime, takesLength) {
  if (group.animations === undefined) {
    group.animations = [];
  }

  var stacks = animations.stacks;

  // Allow garbage collection
  animations.curves = null;
  animations.layers = null;

  const minTargetFps = 50;
  const decimationRate = Math.max(1, Math.floor(animations.fps / minTargetFps));

  for (var key in stacks) {
    var stack = stacks[key];

    /**
     * @type {{
     * name: string,
     * fps: number,
     * length: number,
     * hierarchy: Array.<{
     * 	parent: number,
     * 	name: string,
     * 	keys: Array.<{
     * 		time: number,
     * 		pos: Array.<number>,
     * 		rot: Array.<number>,
     * 		scl: Array.<number>
     * 	}>
     * }>
     * }}
     */

    var length = stack.length;
    var timeOffset = 99999999999999999999999;

    var lengthApplied = false;
    for(var take of takesLength) {
      if (take.id == stack.name)
      {
        length = take.stop - take.start;
        timeOffset = take.start;
        lengthApplied = true;
      }
    }

    var animationData = {
      name: stack.name,
      fps: animations.fps / decimationRate,
      length: length,
      hierarchy: []
    };

    var bones = group.skeleton.bones;

    for (var bonesIndex = 0, bonesLength = bones.length;
         bonesIndex < bonesLength; ++bonesIndex) {
      var bone = bones[bonesIndex];

      var name = bone.name.replace(/.*:/, '');
      var parentIndex = findIndex(bones, function(parentBone) {

        return bone.parent === parentBone;

      });
      animationData.hierarchy.push({parent: parentIndex, name: name, keys: []});
    }

    if(!lengthApplied)
    {
      if(globalTime == null)
      {
        for (var bonesIndex = 0, bonesLength = bones.length;
          bonesIndex < bonesLength; ++bonesIndex) {
          var bone = bones[bonesIndex];
          var boneIndex = bonesIndex;

          var animationNode = stack.layers[0][boneIndex];

          for (var hierarchyIndex = 0,
            hierarchyLength = animationData.hierarchy.length;
            hierarchyIndex < hierarchyLength; ++hierarchyIndex) {
            var node = animationData.hierarchy[hierarchyIndex];

            if (node.name === bone.name) {
              var newTimeOffset = getTimeOffset(animationNode);
              if(newTimeOffset < timeOffset)
                timeOffset = newTimeOffset;
            }
          }
        }
      }
      else
      {
        timeOffset = globalTime.start;
        animationData.length = globalTime.stop - globalTime.start;
      }
    }

    for (var bonesIndex = 0, bonesLength = bones.length;
      bonesIndex < bonesLength; ++bonesIndex) {
      var bone = bones[bonesIndex];
      var boneIndex = bonesIndex;

      var animationNode = stack.layers[0][boneIndex];

      for (var hierarchyIndex = 0,
        hierarchyLength = animationData.hierarchy.length;
        hierarchyIndex < hierarchyLength; ++hierarchyIndex) {
        var node = animationData.hierarchy[hierarchyIndex];

        if (node.name === bone.name) {
          var order = reverse(bone.rotation._order);
          for (var frame = 0; frame <= stack.frames; frame+=decimationRate) {
            var time = frame / animations.fps;
            if(time > animationData.length)
              break;
            node.keys.push(generateKey(animations, animationNode, bone, frame, time, order, timeOffset));
          }
        }
      }

      // Allow aninmation curve data to be garbage collected
      if (animationNode) {
        animationNode.T = null;
        animationNode.R = null;
        animationNode.S = null;
      }
    }

    ensureEndKeyframePropertiesDefined(animationData, bones);

    group.animations.push(
        THREE.AnimationClip.parseAnimation(animationData, bones));


    var deforCurvesList = [];

    let index = 0;
    let maxLength = 0;

    for(var prop in stack.layers[0])
    {
      var layer = stack.layers[0][prop];
      if(layer && layer.DeformPercent !== null) {
        if(layer.DeformPercent.curves.d && layer.DeformPercent.curves.d !== null)
        {
          if(layer.DeformPercent.curves.d.times.length > maxLength)
            maxLength = layer.DeformPercent.curves.d.times.length;

            deforCurvesList.push(layer.DeformPercent.curves.d);
        }
      }
    }


    var deformTrackTimes = [];
    var deformTrackValues = [];

    for(var i=0; i< maxLength; i++) {
      deformTrackTimes.push(deforCurvesList[0].times[i]);
      for(var curve of deforCurvesList) {
        deformTrackValues.push(curve.values[i]);
      }
    }

    if(deformTrackTimes.length > 0 && deformTrackValues.length > 0)
      group.animations[0].tracks.push(new THREE.NumberKeyframeTrack( "morphTargetInfluences", deformTrackTimes, deformTrackValues))
  }

  group.animationsFps = animations.fps / decimationRate;
}

/*
 * Ensure that first and last keyframes have position, rotation, and scale
 * properties defined.
 *
 */
function ensureEndKeyframePropertiesDefined(animationData, bones) {
  for (let i = 0, hiearachyLength = animationData.hierarchy.length; i < hiearachyLength; ++i) {
    let node = animationData.hierarchy[i];
    let bone;

    for (let j = 0, bonesLength = bones.length; j < bonesLength; ++j) {
      if (bones[j].name === node.name) {
        bone = bones[j];
        break;
      }
    }

    ensurePropertiesDefined = (key) => {
      if (!key.hasOwnProperty('pos')) {
        key.pos = bone.position.toArray();
      }
      if (!key.hasOwnProperty('rot')) {
        key.rot = bone.quaternion.toArray();
      }
      if (!key.hasOwnProperty('scl')) {
        key.scl = bone.scale.toArray();
      }
    }

    ensurePropertiesDefined(node.keys[0]);
    ensurePropertiesDefined(node.keys[node.keys.length -1]);
  }
}

var euler = new THREE.Euler();
var quaternion = new THREE.Quaternion();

function reverse(s) {
  return s.split('').reverse().join('');
}

function getCurveValueForTime(time, frame, curve) {
  var eps = 0.0005;
  var index = 0;
  var firstIndex = curve.lastIndex ? curve.lastIndex : 0;

  if (time <= curve.times[0])
  {
    curve.lastIndex = 0;
    return curve.values[0];
  }

  if(Math.abs(curve.times[frame] - time) < eps)
  {
    curve.lastIndex = frame;
    return curve.values[frame];
  }

  for(var i=firstIndex; i < (curve.times.length-1); i++) {
    if (time >= curve.times[i] && time < curve.times[i+1])
    {
      curve.lastIndex = i;
      return curve.values[i];
    }
  }
  curve.lastIndex = curve.times.length - 1;
  return curve.values[curve.times.length - 1];
}

function getTimeOffsetForCurve( time, curve ) {

  if (curve.curves.x.times[0] <= time)
    time = curve.curves.x.times[0];

  if (curve.curves.y.times[0] <= time)
    time = curve.curves.y.times[0];

  if (curve.curves.z.times[0] <= time)
    time = curve.curves.z.times[0];

  return time;
}

function getTimeOffset( animationNode) {
  var time = 99999999999999999999;
  try {
    if (hasCurve(animationNode, 'T'))
      time = getTimeOffsetForCurve(time, animationNode.T);

    if (hasCurve(animationNode, 'R'))
      time = getTimeOffsetForCurve(time, animationNode.R);

    if (hasCurve(animationNode, 'S'))
      time = getTimeOffsetForCurve(time, animationNode.S);

    return time;

  } catch (error) {
    // Curve is not fully plotted.
    console.log('THREE.FBXLoader: ', bone);
    console.log('THREE.FBXLoader: ', error);
  }
}

/**
 * @param {THREE.Bone} bone
 */
function generateKey(animations, animationNode, bone, frame, time, order, timeOffset) {
  var key = {
    time: time
  };

  if (animationNode === undefined) return key;

  try {
    if (hasCurve(animationNode, 'T')) {
      key.pos = [
        getCurveValueForTime(time + timeOffset, frame, animationNode.T.curves.x),
        getCurveValueForTime(time + timeOffset, frame, animationNode.T.curves.y),
        getCurveValueForTime(time + timeOffset, frame, animationNode.T.curves.z),
      ];

      // key.pos = [0, 0, 0];
    }

    if (hasCurve(animationNode, 'R')) {
      /*
      order = 'ZYX';
      //order = 'YXZ'; //rokoko

      if (bone.rotation._order.indexOf('XYZ') != -1)
              order = 'ZYX' //maybe should just flip the
      else
              order = bone.rotation._order;
      */

      var rotationX = getCurveValueForTime(time + timeOffset, frame, animationNode.R.curves.x);
      var rotationY = getCurveValueForTime(time + timeOffset, frame, animationNode.R.curves.y);
      var rotationZ = getCurveValueForTime(time + timeOffset, frame, animationNode.R.curves.z);

      quaternion.setFromEuler(
          euler.set(rotationX, rotationY, rotationZ, order));
      key.rot = quaternion.toArray();
    }

    if (hasCurve(animationNode, 'S')) {
      key.scl = [
        getCurveValueForTime(time + timeOffset, frame, animationNode.S.curves.x),
        getCurveValueForTime(time + timeOffset, frame, animationNode.S.curves.y),
        getCurveValueForTime(time + timeOffset, frame, animationNode.S.curves.z),
      ];
    }

  } catch (error) {
    // Curve is not fully plotted.
    console.log('THREE.FBXLoader: ', bone);
    console.log('THREE.FBXLoader: ', error);
  }

  return key;
}

var AXES = ['x', 'y', 'z'];

function hasCurve(animationNode, attribute) {
  if (animationNode === undefined) {
    return false;
  }

  var attributeNode = animationNode[attribute];

  if (!attributeNode) {
    return false;
  }

  return AXES.every(function(key) {

    return attributeNode.curves[key] !== null;

  });
}

function hasKeyOnFrame(attributeNode, frame) {
  return AXES.every(function(key) {

    return isKeyExistOnFrame(attributeNode.curves[key], frame);

  });
}

function isKeyExistOnFrame(curve, frame) {
  return curve.values[frame] !== undefined;
}

/**
 * An instance of a Vertex with data for drawing vertices to the screen.
 * @constructor
 */
function Vertex() {
  /**
   * Position of the vertex.
   * @type {THREE.Vector3}
   */
  this.position = new THREE.Vector3();

  /**
   * Normal of the vertex
   * @type {THREE.Vector3}
   */
  this.normal = new THREE.Vector3();

  /**
   * Array of UV coordinates of the vertex.
   * @type {Array of THREE.Vector2}
   */
  this.uv = [];

  /**
   * Color of the vertex
   * @type {THREE.Vector3}
   */
  this.color = new THREE.Vector3();

  /**
   * Indices of the bones vertex is influenced by.
   * @type {THREE.Vector4}
   */
  this.skinIndices = new THREE.Vector4(0, 0, 0, 0);

  /**
   * Weights that each bone influences the vertex.
   * @type {THREE.Vector4}
   */
  this.skinWeights = new THREE.Vector4(0, 0, 0, 0);
}

Object.assign(Vertex.prototype, {

  copy: function(target) {

    var returnVar = target || new Vertex();

    returnVar.position.copy(this.position);
    returnVar.normal.copy(this.normal);
    returnVar.uv.copy(this.uv);
    returnVar.skinIndices.copy(this.skinIndices);
    returnVar.skinWeights.copy(this.skinWeights);

    return returnVar;

  },

  flattenToBuffers: function(
      vertexBuffer, normalBuffer, uvBuffers, colorBuffer, skinIndexBuffer,
      skinWeightBuffer) {

    this.position.toArray(vertexBuffer, vertexBuffer.length);
    this.normal.toArray(normalBuffer, normalBuffer.length);

    for (var i = 0; i < this.uv.length; i++) {
      this.uv[i].toArray(uvBuffers[i], uvBuffers[i].length);
    }
    this.color.toArray(colorBuffer, colorBuffer.length);
    this.skinIndices.toArray(skinIndexBuffer, skinIndexBuffer.length);
    this.skinWeights.toArray(skinWeightBuffer, skinWeightBuffer.length);

  }

});

/**
 * @constructor
 */
function Triangle() {
  /**
   * @type {{position: THREE.Vector3, normal: THREE.Vector3, uv: THREE.Vector2, skinIndices: THREE.Vector4, skinWeights: THREE.Vector4}[]}
   */
  this.vertices = [];
}

Object.assign(Triangle.prototype, {

  copy: function(target) {

    var returnVar = target || new Triangle();

    for (var i = 0; i < this.vertices.length; ++i) {
      this.vertices[i].copy(returnVar.vertices[i]);
    }

    return returnVar;

  },

  flattenToBuffers: function(
      vertexBuffer, normalBuffer, uvBuffers, colorBuffer, skinIndexBuffer,
      skinWeightBuffer) {

    var vertices = this.vertices;

    for (var i = 0, l = vertices.length; i < l; ++i) {
      vertices[i].flattenToBuffers(
          vertexBuffer, normalBuffer, uvBuffers, colorBuffer, skinIndexBuffer,
          skinWeightBuffer);
    }

  }

});

/**
 * @constructor
 */
function Face() {
  /**
   * @type {{vertices: {position: THREE.Vector3, normal: THREE.Vector3, uv: THREE.Vector2, skinIndices: THREE.Vector4, skinWeights: THREE.Vector4}[]}[]}
   */
  this.triangles = [];
  this.materialIndex = 0;
}

Object.assign(Face.prototype, {

  copy: function(target) {

    var returnVar = target || new Face();

    for (var i = 0; i < this.triangles.length; ++i) {
      this.triangles[i].copy(returnVar.triangles[i]);
    }

    returnVar.materialIndex = this.materialIndex;

    return returnVar;

  },

  genTrianglesFromVertices: function(vertexArray) {

    for (var i = 2; i < vertexArray.length; ++i) {
      var triangle = new Triangle();
      triangle.vertices[0] = vertexArray[0];
      triangle.vertices[1] = vertexArray[i - 1];
      triangle.vertices[2] = vertexArray[i];
      this.triangles.push(triangle);
    }

  },

  flattenToBuffers: function(
      vertexBuffer, normalBuffer, uvBuffers, colorBuffer, skinIndexBuffer,
      skinWeightBuffer, materialIndexBuffer) {

    var triangles = this.triangles;
    var materialIndex = this.materialIndex;

    for (var i = 0, l = triangles.length; i < l; ++i) {
      triangles[i].flattenToBuffers(
          vertexBuffer, normalBuffer, uvBuffers, colorBuffer, skinIndexBuffer,
          skinWeightBuffer);
      append(
          materialIndexBuffer, [materialIndex, materialIndex, materialIndex]);
    }

  }

});

/**
 * @constructor
 */
function Geometry() {
  /**
   * @type {{triangles: {vertices: {position: THREE.Vector3, normal: THREE.Vector3, uv: Array of THREE.Vector2, skinIndices: THREE.Vector4, skinWeights: THREE.Vector4}[]}[], materialIndex: number}[]}
   */
  this.faces = [];

  /**
   * @type {{}|THREE.Skeleton}
   */
  this.skeleton = null;
}

Object.assign(Geometry.prototype, {

  /**
   * @returns	{{vertexBuffer: number[], normalBuffer: number[], uvBuffers:
   * Array of number[], skinIndexBuffer: number[], skinWeightBuffer: number[],
   * materialIndexBuffer: number[]}}
   */
  flattenToBuffers: function() {

    var vertexBuffer = [];
    var normalBuffer = [];
    var uvBuffers = [];
    var colorBuffer = [];
    var skinIndexBuffer = [];
    var skinWeightBuffer = [];
    var materialIndexBuffer = [];

    var faces = this.faces;

    for (var i = 0; i < faces[0].triangles[0].vertices[0].uv.length; i++) {
      uvBuffers.push([]);
    }

    for (var i = 0, l = faces.length; i < l; ++i) {
      faces[i].flattenToBuffers(
          vertexBuffer, normalBuffer, uvBuffers, colorBuffer, skinIndexBuffer,
          skinWeightBuffer, materialIndexBuffer);
    }

    return {
      vertexBuffer: vertexBuffer,
      normalBuffer: normalBuffer,
      uvBuffers: uvBuffers,
      colorBuffer: colorBuffer,
      skinIndexBuffer: skinIndexBuffer,
      skinWeightBuffer: skinWeightBuffer,
      materialIndexBuffer: materialIndexBuffer
    };

  }

});

function TextParser() {}

Object.assign(TextParser.prototype, {

  getPrevNode: function() {

    return this.nodeStack[this.currentIndent - 2];

  },

  getCurrentNode: function() {

    return this.nodeStack[this.currentIndent - 1];

  },

  getCurrentProp: function() {

    return this.currentProp;

  },

  pushStack: function(node) {

    this.nodeStack.push(node);
    this.currentIndent += 1;

  },

  popStack: function() {

    this.nodeStack.pop();
    this.currentIndent -= 1;

  },

  setCurrentProp: function(val, name) {

    this.currentProp = val;
    this.currentPropName = name;

  },

  // ----------parse ---------------------------------------------------
  parse: function(text) {

    this.currentIndent = 0;
    this.allNodes = new FBXTree();
    this.nodeStack = [];
    this.currentProp = [];
    this.currentPropName = '';

    var split = text.split('\n');

    for (var lineNum = 0, lineLength = split.length; lineNum < lineLength;
         lineNum++) {
      var l = split[lineNum];

      //not really handled
      if(l.indexOf('P: "MoBu')!= -1)
          continue;
      // skip comment line
      if (l.match(/^[\s\t]*;/)) {
        continue;
      }

      // skip empty line
      if (l.match(/^[\s\t]*$/)) {
        continue;
      }

      // beginning of node
      var beginningOfNodeExp =
          new RegExp('^\\t{' + this.currentIndent + '}(\\w+):(.*){', '');
      var match = l.match(beginningOfNodeExp);

      if (match) {
        var nodeName = match[1].trim().replace(/^"/, '').replace(/"$/, '');
        var nodeAttrs = match[2].split(',');

        for (var i = 0, l = nodeAttrs.length; i < l; i++) {
          nodeAttrs[i] =
              nodeAttrs[i].trim().replace(/^"/, '').replace(/"$/, '');
        }

        this.parseNodeBegin(l, nodeName, nodeAttrs || null);
        continue;
      }

      // node's property
      var propExp = new RegExp(
          '^\\t{' + (this.currentIndent) + '}(\\w+):[\\s\\t\\r\\n](.*)');
      var match = l.match(propExp);

      if (match) {
        var propName = match[1].replace(/^"/, '').replace(/"$/, '').trim();
        var propValue = match[2].replace(/^"/, '').replace(/"$/, '').trim();

        // for special case: base64 image data follows "Content: ," line
        //	Content: ,
        //	 "iVB..."
        if (propName === 'Content' && propValue === ',') {
          propValue = split[++lineNum].replace(/"/g, '').trim();
        }

        this.parseNodeProperty(l, propName, propValue);
        continue;
      }

      // end of node
      var endOfNodeExp = new RegExp('^\\t{' + (this.currentIndent - 1) + '}}');

      if (l.match(endOfNodeExp)) {
        this.nodeEnd();
        continue;
      }

      // for special case,
      //
      //	  Vertices: *8670 {
      //		  a:
      //0.0356229953467846,13.9599733352661,-0.399196773.....(snip)
      // -0.0612030513584614,13.960485458374,-0.409748703241348,-0.10.....
      // 0.12490539252758,13.7450733184814,-0.454119384288788,0.09272.....
      // 0.0836158767342567,13.5432004928589,-0.435397416353226,0.028.....
      //
      // these case the lines must contiue with previous line
      if (l.match(/^[^\s\t}]/)) {
        this.parseNodePropertyContinued(l);
      }
    }

    return this.allNodes;

  },

  parseNodeBegin: function(line, nodeName, nodeAttrs) {

    // var nodeName = match[1];
    var node = {'name': nodeName, properties: {}, 'subNodes': {}};
    var attrs = this.parseNodeAttr(nodeAttrs);
    var currentNode = this.getCurrentNode();

    // a top node
    if (this.currentIndent === 0) {
      this.allNodes.add(nodeName, node);

    } else {
      // a subnode

      // already exists subnode, then append it
      if (nodeName in currentNode.subNodes) {
        var tmp = currentNode.subNodes[nodeName];

        // console.log( "duped entry found\nkey: " + nodeName + "\nvalue: " +
        // propValue );
        if (this.isFlattenNode(currentNode.subNodes[nodeName])) {
          if (attrs.id === '') {
            currentNode.subNodes[nodeName] = [];
            currentNode.subNodes[nodeName].push(tmp);

          } else {
            currentNode.subNodes[nodeName] = {};
            currentNode.subNodes[nodeName][tmp.id] = tmp;
          }
        }

        if (attrs.id === '') {
          currentNode.subNodes[nodeName].push(node);

        } else {
          currentNode.subNodes[nodeName][attrs.id] = node;
        }

      } else if (typeof attrs.id === 'number' || attrs.id.match(/^\d+$/)) {
        currentNode.subNodes[nodeName] = {};
        currentNode.subNodes[nodeName][attrs.id] = node;

      } else {
        currentNode.subNodes[nodeName] = node;
      }
    }

    // for this		  ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
    // NodeAttribute: 1001463072, "NodeAttribute::", "LimbNode" {
    if (nodeAttrs) {
      node.id = attrs.id;
      node.attrName = attrs.name;
      node.attrType = attrs.type;
    }

    this.pushStack(node);

  },

  parseNodeAttr: function(attrs) {

    var id = attrs[0];

    if (attrs[0] !== '') {
      id = parseInt(attrs[0]);

      if (isNaN(id)) {
        // PolygonVertexIndex: *16380 {
        id = attrs[0];
      }
    }

    var name = '', type = '';

    if (attrs.length > 1) {
      name = attrs[1].replace(/^(\w+)::/, '');
      type = attrs[2];
    }

    return {id: id, name: name, type: type};

  },

  parseNodeProperty: function(line, propName, propValue) {

    var currentNode = this.getCurrentNode();
    var parentName = currentNode.name;

    // special case parent node's is like "Properties70"
    // these children nodes must treat with careful
    if (parentName !== undefined) {
      var propMatch = parentName.match(/Properties(\d)+/);
      if (propMatch) {
        this.parseNodeSpecialProperty(line, propName, propValue);
        return;
      }
    }

    // special case Connections
    if (propName === 'C') {
      var connProps = propValue.split(',').slice(1);
      var from = parseInt(connProps[0]);
      var to = parseInt(connProps[1]);

      var rest = propValue.split(',').slice(3);

      propName = 'connections';
      propValue = [from, to];
      append(propValue, rest);

      if (currentNode.properties[propName] === undefined) {
        currentNode.properties[propName] = [];
      }
    }

    // special case Connections
    if (propName === 'Node') {
      var id = parseInt(propValue);
      currentNode.properties.id = id;
      currentNode.id = id;
    }

    // already exists in properties, then append this
    if (propName in currentNode.properties) {
      // console.log( "duped entry found\nkey: " + propName + "\nvalue: " +
      // propValue );
      if (Array.isArray(currentNode.properties[propName])) {
        currentNode.properties[propName].push(propValue);

      } else {
        currentNode.properties[propName] += propValue;
      }

    } else {
      // console.log( propName + ":  " + propValue );
      if (Array.isArray(currentNode.properties[propName])) {
        currentNode.properties[propName].push(propValue);

      } else {
        currentNode.properties[propName] = propValue;
      }
    }

    this.setCurrentProp(currentNode.properties, propName);

  },

  // TODO:
  parseNodePropertyContinued: function(line) {

    this.currentProp[this.currentPropName] += line;

  },

  parseNodeSpecialProperty: function(line, propName, propValue) {

    // split this
    // P: "Lcl Scaling", "Lcl Scaling", "", "A",1,1,1
    // into array like below
    // ["Lcl Scaling", "Lcl Scaling", "", "A", "1,1,1" ]
    var props = propValue.split('",');

    for (var i = 0, l = props.length; i < l; i++) {
      props[i] = props[i].trim().replace(/^\"/, '').replace(/\s/, '_');
    }

    var innerPropName = props[0];
    var innerPropType1 = props[1];
    var innerPropType2 = props[2];
    var innerPropFlag = props[3];
    var innerPropValue = props[4];

    /*
    if ( innerPropValue === undefined ) {
            innerPropValue = props[3];
    }
    */

    // cast value in its type
    switch (innerPropType1) {
      case 'int':
        innerPropValue = parseInt(innerPropValue);
        break;

      case 'double':
        innerPropValue = parseFloat(innerPropValue);
        break;

      case 'ColorRGB':
      case 'Vector3D':
        innerPropValue = parseFloatArray(innerPropValue);
        break;
    }

    // CAUTION: these props must append to parent's parent
    this.getPrevNode().properties[innerPropName] = {

      'type': innerPropType1,
      'type2': innerPropType2,
      'flag': innerPropFlag,
      'value': innerPropValue

    };

    this.setCurrentProp(this.getPrevNode().properties, innerPropName);

  },

  nodeEnd: function() {

    this.popStack();

  },

  /* ---------------------------------------------------------------- */
  /*		util
   */
  isFlattenNode: function(node) {

    return ('subNodes' in node && 'properties' in node) ? true : false;

  }

});

// Binary format specification:
//   https://code.blender.org/2013/08/fbx-binary-file-format-specification/
//   https://wiki.rogiken.org/specifications/file-format/fbx/ (more detail but
//   Japanese)
function BinaryParser() {}

Object.assign(BinaryParser.prototype, {

  /**
   * Parses binary data and builds FBXTree as much compatible as possible with
   * the one built by TextParser.
   * @param {ArrayBuffer} buffer
   * @returns {THREE.FBXTree}
   */
  parse: function(buffer) {

    var reader = new BinaryReader(buffer);
    reader.skip(23);  // skip magic 23 bytes

    var version = reader.getUint32();

    console.log('THREE.FBXLoader: FBX binary version: ' + version);

    var allNodes = new FBXTree();

    while (!this.endOfContent(reader)) {
      var node = this.parseNode(reader, version);
      if (node !== null) allNodes.add(node.name, node);
    }

    return allNodes;

  },

  /**
   * Checks if reader has reached the end of content.
   * @param {BinaryReader} reader
   * @returns {boolean}
   */
  endOfContent: function(reader) {

    // footer size: 160bytes + 16-byte alignment padding
    // - 16bytes: magic
    // - padding til 16-byte alignment (at least 1byte?)
    //   (seems like some exporters embed fixed 15 or 16bytes?)
    // - 4bytes: magic
    // - 4bytes: version
    // - 120bytes: zero
    // - 16bytes: magic
    if (reader.size() % 16 === 0) {
      return ((reader.getOffset() + 160 + 16) & ~0xf) >= reader.size();

    } else {
      return reader.getOffset() + 160 + 16 >= reader.size();
    }

  },

  /**
   * Parses Node as much compatible as possible with the one parsed by
   * TextParser
   * TODO: could be optimized more?
   * @param {BinaryReader} reader
   * @param {number} version
   * @returns {Object} - Returns an Object as node, or null if NULL-record.
   */
  parseNode: function(reader, version) {

    // The first three data sizes depends on version.
    var endOffset = (version >= 7500) ? reader.getUint64() : reader.getUint32();
    var numProperties =
        (version >= 7500) ? reader.getUint64() : reader.getUint32();
    var propertyListLen =
        (version >= 7500) ? reader.getUint64() : reader.getUint32();
    var nameLen = reader.getUint8();
    var name = reader.getString(nameLen);

    // Regards this node as NULL-record if endOffset is zero
    if (endOffset === 0) return null;

    var propertyList = [];

    for (var i = 0; i < numProperties; i++) {
      propertyList.push(this.parseProperty(reader));
    }

    // Regards the first three elements in propertyList as id, attrName, and
    // attrType
    var id = propertyList.length > 0 ? propertyList[0] : '';
    var attrName = propertyList.length > 1 ? propertyList[1] : '';
    var attrType = propertyList.length > 2 ? propertyList[2] : '';

    var subNodes = {};
    var properties = {};

    var isSingleProperty = false;

    // if this node represents just a single property
    // like (name, 0) set or (name2, [0, 1, 2]) set of {name: 0, name2: [0, 1,
    // 2]}
    if (numProperties === 1 && reader.getOffset() === endOffset) {
      isSingleProperty = true;
    }

    while (endOffset > reader.getOffset()) {
      var node = this.parseNode(reader, version);

      if (node === null) continue;

      // special case: child node is single property
      if (node.singleProperty === true) {
        var value = node.propertyList[0];

        if (Array.isArray(value)) {
          // node represents
          //	Vertices: *3 {
          //		a: 0.01, 0.02, 0.03
          //	}
          // of text format here.

          node.properties[node.name] = node.propertyList[0];
          subNodes[node.name] = node;

          // Later phase expects single property array is in node.properties.a
          // as String.
          // TODO: optimize
          node.properties.a = value.toString();

        } else {
          // node represents
          // 	Version: 100
          // of text format here.

          properties[node.name] = value;
        }

        continue;
      }

      // special case: connections
      if (name === 'Connections' && node.name === 'C') {
        var array = [];

        // node.propertyList would be like
        // ["OO", 111264976, 144038752, "d|x"] (?, from, to, additional values)
        for (var i = 1, il = node.propertyList.length; i < il; i++) {
          array[i - 1] = node.propertyList[i];
        }

        if (properties.connections === undefined) {
          properties.connections = [];
        }

        properties.connections.push(array);

        continue;
      }

      // special case: child node is Properties\d+
      if (node.name.match(/^Properties\d+$/)) {
        // move child node's properties to this node.

        var keys = Object.keys(node.properties);

        for (var i = 0, il = keys.length; i < il; i++) {
          var key = keys[i];
          properties[key] = node.properties[key];
        }

        continue;
      }

      // special case: properties
      if (name.match(/^Properties\d+$/) && node.name === 'P') {
        var innerPropName = node.propertyList[0];
        var innerPropType1 = node.propertyList[1];
        var innerPropType2 = node.propertyList[2];
        var innerPropFlag = node.propertyList[3];
        var innerPropValue;

        if (innerPropName.indexOf('Lcl ') === 0)
          innerPropName = innerPropName.replace('Lcl ', 'Lcl_');
        if (innerPropType1.indexOf('Lcl ') === 0)
          innerPropType1 = innerPropType1.replace('Lcl ', 'Lcl_');

        if (innerPropType1 === 'ColorRGB' || innerPropType1 === 'Vector' ||
            innerPropType1 === 'Vector3D' ||
            innerPropType1.indexOf('Lcl_') === 0) {
          innerPropValue = [
            node.propertyList[4], node.propertyList[5], node.propertyList[6]
          ];

        } else {
          innerPropValue = node.propertyList[4];
        }

        if (innerPropType1.indexOf('Lcl_') === 0) {
          innerPropValue = innerPropValue.toString();
        }

        // this will be copied to parent. see above.
        properties[innerPropName] = {

          'type': innerPropType1,
          'type2': innerPropType2,
          'flag': innerPropFlag,
          'value': innerPropValue

        };

        continue;
      }

      // standard case
      // follows TextParser's manner.
      if (subNodes[node.name] === undefined) {
        if (typeof node.id === 'number') {
          subNodes[node.name] = {};
          subNodes[node.name][node.id] = node;

        } else {
          subNodes[node.name] = node;
        }

      } else {
        if (node.id === '') {
          if (!Array.isArray(subNodes[node.name])) {
            subNodes[node.name] = [subNodes[node.name]];
          }

          subNodes[node.name].push(node);

        } else {
          if (subNodes[node.name][node.id] === undefined) {
            subNodes[node.name][node.id] = node;

          } else {
            // conflict id. irregular?

            if (!Array.isArray(subNodes[node.name][node.id])) {
              subNodes[node.name][node.id] = [subNodes[node.name][node.id]];
            }

            subNodes[node.name][node.id].push(node);
          }
        }
      }
    }

    return {

      singleProperty: isSingleProperty,
      id: id,
      attrName: attrName,
      attrType: attrType,
      name: name,
      properties: properties,
      propertyList: propertyList,  // raw property list, would be used by parent
      subNodes: subNodes

    };

  },

  parseProperty: function(reader) {

    var type = reader.getChar();

    switch (type) {
      case 'F':
        return reader.getFloat32();

      case 'D':
        return reader.getFloat64();

      case 'L':
        return reader.getInt64();

      case 'I':
        return reader.getInt32();

      case 'Y':
        return reader.getInt16();

      case 'C':
        return reader.getBoolean();

      case 'f':
      case 'd':
      case 'l':
      case 'i':
      case 'b':

        var arrayLength = reader.getUint32();
        var encoding = reader.getUint32();  // 0: non-compressed, 1: compressed
        var compressedLength = reader.getUint32();

        if (encoding === 0) {
          switch (type) {
            case 'f':
              return reader.getFloat32Array(arrayLength);

            case 'd':
              return reader.getFloat64Array(arrayLength);

            case 'l':
              return reader.getInt64Array(arrayLength);

            case 'i':
              return reader.getInt32Array(arrayLength);

            case 'b':
              return reader.getBooleanArray(arrayLength);
          }
        }

        if (window.Zlib === undefined) {
          throw new Error(
              'THREE.FBXLoader: External library Inflate.min.js required, obtain or import from https://github.com/imaya/zlib.js');
        }

        var inflate = new Zlib.Inflate(new Uint8Array(reader.getArrayBuffer(
            compressedLength)));  // eslint-disable-line no-undef
        var reader2 = new BinaryReader(inflate.decompress().buffer);

        switch (type) {
          case 'f':
            return reader2.getFloat32Array(arrayLength);

          case 'd':
            return reader2.getFloat64Array(arrayLength);

          case 'l':
            return reader2.getInt64Array(arrayLength);

          case 'i':
            return reader2.getInt32Array(arrayLength);

          case 'b':
            return reader2.getBooleanArray(arrayLength);
        }

      case 'S':
        var length = reader.getUint32();
        return reader.getString(length);

      case 'R':
        var length = reader.getUint32();
        return reader.getArrayBuffer(length);

      default:
        throw new Error('THREE.FBXLoader: Unknown property type ' + type);
    }

  }

});


function BinaryReader(buffer, littleEndian) {
  this.dv = new DataView(buffer);
  this.offset = 0;
  this.littleEndian = (littleEndian !== undefined) ? littleEndian : true;
}

Object.assign(
    BinaryReader.prototype, {

      getOffset: function() {

        return this.offset;

      },

      size: function() {

        return this.dv.buffer.byteLength;

      },

      skip: function(length) {

        this.offset += length;

      },

      // seems like true/false representation depends on exporter.
      //   true: 1 or 'Y'(=0x59), false: 0 or 'T'(=0x54)
      // then sees LSB.
      getBoolean: function() {

        return (this.getUint8() & 1) === 1;

      },

      getBooleanArray: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getBoolean());
        }

        return a;

      },

      getInt8: function() {

        var value = this.dv.getInt8(this.offset);
        this.offset += 1;
        return value;

      },

      getInt8Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getInt8());
        }

        return a;

      },

      getUint8: function() {

        var value = this.dv.getUint8(this.offset);
        this.offset += 1;
        return value;

      },

      getUint8Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getUint8());
        }

        return a;

      },

      getInt16: function() {

        var value = this.dv.getInt16(this.offset, this.littleEndian);
        this.offset += 2;
        return value;

      },

      getInt16Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getInt16());
        }

        return a;

      },

      getUint16: function() {

        var value = this.dv.getUint16(this.offset, this.littleEndian);
        this.offset += 2;
        return value;

      },

      getUint16Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getUint16());
        }

        return a;

      },

      getInt32: function() {

        var value = this.dv.getInt32(this.offset, this.littleEndian);
        this.offset += 4;
        return value;

      },

      getInt32Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getInt32());
        }

        return a;

      },

      getUint32: function() {

        var value = this.dv.getUint32(this.offset, this.littleEndian);
        this.offset += 4;
        return value;

      },

      getUint32Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getUint32());
        }

        return a;

      },

      // JavaScript doesn't support 64-bit integer so attempting to calculate by
      // ourselves. 1 << 32 will return 1 so using multiply operation instead
      // here. There'd be a possibility that this method returns wrong value if
      // the value is out of the range between Number.MAX_SAFE_INTEGER and
      // Number.MIN_SAFE_INTEGER.
      // TODO: safely handle 64-bit integer
      getInt64: function() {

        var low, high;

        if (this.littleEndian) {
          low = this.getUint32();
          high = this.getUint32();

        } else {
          high = this.getUint32();
          low = this.getUint32();
        }

        // calculate negative value
        if (high & 0x80000000) {
          high = ~high & 0xFFFFFFFF;
          low = ~low & 0xFFFFFFFF;

          if (low === 0xFFFFFFFF) high = (high + 1) & 0xFFFFFFFF;

          low = (low + 1) & 0xFFFFFFFF;

          return -(high * 0x100000000 + low);
        }

        return high * 0x100000000 + low;

      },

      getInt64Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getInt64());
        }

        return a;

      },

      // Note: see getInt64() comment
      getUint64: function() {

        var low, high;

        if (this.littleEndian) {
          low = this.getUint32();
          high = this.getUint32();

        } else {
          high = this.getUint32();
          low = this.getUint32();
        }

        return high * 0x100000000 + low;

      },

      getUint64Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getUint64());
        }

        return a;

      },

      getFloat32: function() {

        var value = this.dv.getFloat32(this.offset, this.littleEndian);
        this.offset += 4;
        return value;

      },

      getFloat32Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getFloat32());
        }

        return a;

      },

      getFloat64: function() {

        var value = this.dv.getFloat64(this.offset, this.littleEndian);
        this.offset += 8;
        return value;

      },

      getFloat64Array: function(size) {

        var a = [];

        for (var i = 0; i < size; i++) {
          a.push(this.getFloat64());
        }

        return a;

      },

      getArrayBuffer: function(size) {

        var value = this.dv.buffer.slice(this.offset, this.offset + size);
        this.offset += size;
        return value;

      },

      getChar: function() {

        return String.fromCharCode(this.getUint8());

      },

      getString: function(size) {

        var s = '';

        while (size > 0) {
          var value = this.getUint8();
          size--;

          if (value === 0) break;

          s += String.fromCharCode(value);
        }

        // Manage UTF8 encoding
        s = decodeURIComponent(escape(s));

        this.skip(size);

        return s;

      }

    });


function FBXTree() {}

Object.assign(FBXTree.prototype, {

  add: function(key, val) {

    this[key] = val;

  },

  searchConnectionParent: function(id) {

    if (this.__cache_search_connection_parent === undefined) {
      this.__cache_search_connection_parent = [];
    }

    if (this.__cache_search_connection_parent[id] !== undefined) {
      return this.__cache_search_connection_parent[id];

    } else {
      this.__cache_search_connection_parent[id] = [];
    }

    var conns = this.Connections.properties.connections;

    var results = [];
    for (var i = 0; i < conns.length; ++i) {
      if (conns[i][0] == id) {
        // 0 means scene root
        var res = conns[i][1] === 0 ? -1 : conns[i][1];
        results.push(res);
      }
    }

    if (results.length > 0) {
      append(this.__cache_search_connection_parent[id], results);
      return results;

    } else {
      this.__cache_search_connection_parent[id] = [-1];
      return [-1];
    }

  },

  searchConnectionChildren: function(id) {

    if (this.__cache_search_connection_children === undefined) {
      this.__cache_search_connection_children = [];
    }

    if (this.__cache_search_connection_children[id] !== undefined) {
      return this.__cache_search_connection_children[id];

    } else {
      this.__cache_search_connection_children[id] = [];
    }

    var conns = this.Connections.properties.connections;

    var res = [];
    for (var i = 0; i < conns.length; ++i) {
      if (conns[i][1] == id) {
        // 0 means scene root
        res.push(conns[i][0] === 0 ? -1 : conns[i][0]);
        // there may more than one kid, then search to the end
      }
    }

    if (res.length > 0) {
      append(this.__cache_search_connection_children[id], res);
      return res;

    } else {
      this.__cache_search_connection_children[id] = [];
      return [];
    }

  },

  searchConnectionType: function(id, to) {

    var key = id + ',' + to;  // TODO: to hash
    if (this.__cache_search_connection_type === undefined) {
      this.__cache_search_connection_type = {};
    }

    if (this.__cache_search_connection_type[key] !== undefined) {
      return this.__cache_search_connection_type[key];

    } else {
      this.__cache_search_connection_type[key] = '';
    }

    var conns = this.Connections.properties.connections;

    for (var i = 0; i < conns.length; ++i) {
      if (conns[i][0] == id && conns[i][1] == to) {
        // 0 means scene root
        this.__cache_search_connection_type[key] = conns[i][2];
        return conns[i][2];
      }
    }

    this.__cache_search_connection_type[id] = null;
    return null;

  }

});


/**
 * @param {ArrayBuffer} buffer
 * @returns {boolean}
 */
function isFbxFormatBinary(buffer) {
  var CORRECT = 'Kaydara FBX Binary  \0';

  return buffer.byteLength >= CORRECT.length &&
      CORRECT === convertArrayBufferToString(buffer, 0, CORRECT.length);
}

/**
 * @returns {boolean}
 */
function isFbxFormatASCII(text) {
  var CORRECT = [
    'K', 'a',  'y', 'd', 'a', 'r', 'a', '\\', 'F',  'B',
    'X', '\\', 'B', 'i', 'n', 'a', 'r', 'y',  '\\', '\\'
  ];

  var cursor = 0;

  function read(offset) {
    var result = text[offset - 1];
    text = text.slice(cursor + offset);
    cursor++;
    return result;
  }

  for (var i = 0; i < CORRECT.length; ++i) {
    var num = read(1);
    if (num === CORRECT[i]) {
      return false;
    }
  }

  return true;
}

/**
 * @returns {number}
 */
function getFbxVersion(text) {
  var versionRegExp = /FBXVersion: (\d+)/;
  var match = text.match(versionRegExp);
  if (match) {
    var version = parseInt(match[1]);
    return version;
  }
  throw new Error(
      'THREE.FBXLoader: Cannot find the version number for the file given.');
}

/**
 * Converts FBX ticks into real time seconds.
 * @param {number} time - FBX tick timestamp to convert.
 * @returns {number} - FBX tick in real world time.
 */
function convertFBXTimeToSeconds(time) {
  // Constant is FBX ticks per second.
  return time / 46186158000;
}

/**
 * Parses comma separated list of float numbers and returns them in an array.
 * @example
 * // Returns [ 5.6, 9.4, 2.5, 1.4 ]
 * parseFloatArray( "5.6,9.4,2.5,1.4" )
 * @returns {number[]}
 */
function parseFloatArray( value ) {

	var array = value.split( ',' ).map( function ( val ) {

		return parseFloat( val );

	} );

	return array;

}

/**
 * Parses comma separated list of int numbers and returns them in an array.
 * @example
 * // Returns [ 5, 8, 2, 3 ]
 * parseFloatArray( "5,8,2,3" )
 * @returns {number[]}
 */
function parseIntArray(string) {
  var array = string.split(',');

  for (var i = 0, l = array.length; i < l; i++) {
    array[i] = parseInt(array[i]);
  }

  return array;
}

/**
 * Parses Vector3 property from FBXTree.  Property is given as .value.x,
 * .value.y, etc.
 * @param {FBXVector3} property - Property to parse as Vector3.
 * @returns {THREE.Vector3}
 */
function parseVector3(property) {
  return new THREE.Vector3().fromArray(property.value);
}

/**
 * Parses Color property from FBXTree.  Property is given as .value.x, .value.y,
 * etc.
 * @param {FBXVector3} property - Property to parse as Color.
 * @returns {THREE.Color}
 */
function parseColor(property) {
  return new THREE.Color().fromArray(property.value);
}

function parseMatrixArray(floatString) {
  return new THREE.Matrix4().fromArray(parseFloatArray(floatString));
}

/**
 * Converts ArrayBuffer to String.
 * @param {ArrayBuffer} buffer
 * @param {number} from
 * @param {number} to
 * @returns {String}
 */
function convertArrayBufferToString(buffer, from, to) {
  if (from === undefined) from = 0;
  if (to === undefined) to = buffer.byteLength;

  var array = new Uint8Array(buffer, from, to);

  if (window.TextDecoder !== undefined) {
    return new TextDecoder().decode(array);
  }

  var s = '';

  for (var i = 0, il = array.length; i < il; i++) {
    s += String.fromCharCode(array[i]);
  }

  return s;
}

/**
 * Converts number from degrees into radians.
 * @param {number} value
 * @returns {number}
 */
function degreeToRadian(value) {
  return value * DEG2RAD;
}

var DEG2RAD = Math.PI / 180;

//

function findIndex(array, func) {
  for (var i = 0, l = array.length; i < l; i++) {
    if (func(array[i])) return i;
  }

  return -1;
}

function append(a, b) {
  for (var i = 0, j = a.length, l = b.length; i < l; i++, j++) {
    a[j] = b[i];
  }
}

function slice(a, b, from, to) {
  for (var i = from, j = 0; i < to; i++, j++) {
    a[j] = b[i];
  }

  return a;
}

})();
