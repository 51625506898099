import {
  Component, Input, ViewChild
} from '@angular/core';
import { LeftRightService } from 'app/shared/left-right/left-right.service';
import { Clip3DData } from 'app/shared/multi-media-player/media-player/media-player.types';
import { ClipOptionsService } from '../../core/clip-options.service';
import { MocapClipComponent } from '../mocap-clip.component';
import { AClipLoaderLogicsComponent } from './clip-loader-logics.component.abstract';
import { ClipLoaderService } from './clip-loader.service';

@Component({
  selector: 'app-clip-loader',
  templateUrl: './clip-loader.component.html',
  styleUrls: ['./clip-loader.component.scss'],
})

export class ClipLoaderComponent extends AClipLoaderLogicsComponent {

  @Input() showFullscreenIcon: boolean = true;
  @Input() moveFollowCam: boolean = false;
  @Input() clickers = true;

  public currentClip: Clip3DData;

  @ViewChild(MocapClipComponent)
  protected clipPlayer: MocapClipComponent;

  constructor(
    protected loader: ClipLoaderService,
    protected optionsService: ClipOptionsService,
    protected leftRightService: LeftRightService,
  ) {
    super(optionsService);
  }

  public finalizeResult(result: any): void {
    this.animationClips = [result];
    this.optionsService.setClipId(this._clipId);
    this.loadingClip = false;
    this.loadingComplete = true;
    this.currentClip = {id: this._clipId, clipTrcPath: this._clipId, dataUrl: this._clipId};
  }

  public screenshot(): string {
    return this.clipPlayer.screenshot();
  }

  public animatedScreenshot(): Promise<string> {

    return new Promise( (resolve) => {
       this.clipPlayer.animatedScreenshot().then( (dataUrl) => { resolve(dataUrl) } );
    });
  }

}
