import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-icon',
  templateUrl: './checkbox-icon.component.html',
  styleUrls: ['./checkbox-icon.component.scss'],
  providers: [ ]
})
export class CheckboxIconComponent {

  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() colorClass: string;
  @Input() tooltip: string = '';
  @Input() increaseLabelSize: boolean = false;

  // this preventDefault avoids getting the focus on the checkbox on mousedown
  // this keeps the click working on the checkbox status, while also leaving keyboard events for the rest of the page (timebar)
  public avoidFocus($event: Event): void {
    $event.preventDefault();
  }

  public getControl(): FormControl {
    return this.control as FormControl;
 }
}
