import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Condition } from 'app/projects/conditions.service';
import { Clip } from 'app/projects/report/report.types';
import { CreateSessionMutation, UpdateSessionMutation } from 'app/shared/mutations';
import { DatetimeService } from 'app/shared/services/datetime/datetime.service';
import { firstValueFrom } from 'rxjs';
import { Session, SessionMetadata } from './session.types';

export interface SessionAndConditionObject {
  sessionName: string;
  conditions: ConditionObject[];
  referenceDataSetName?: string;
}

export interface ConditionObject {
  conditionName: string;
  trials: Clip[];
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  /** contains the session folder expanded status for both sidebar and subject view */
  private collapsed: Record<string, boolean> = {};
  /** contains the conditions folder expanded status for both sidebar and subject view */
  private conditionsCollapsed: Record<string, boolean> = {};
  /** used to know if condition initial status has been handled (to avoid resetting without page refresh) */
  public handledConditionStatus: string = '';

  constructor(
    private apollo: Apollo,
    private datetimeService: DatetimeService
  ) { }

  create(projectId: string, patient, sessionName: string, sessionDate: string | null) {
    return this.apollo.mutate<{ createSession: { session: { id: string } } }>({
      mutation: CreateSessionMutation,
      variables: {
        projectId: projectId,
        projectPath: '/' + patient.name + '/' + sessionName + '/',
        patientId: patient.id,
        sessionDate: sessionDate,
      }
    });
  }

  /**
   * Updates a session
   * @returns True if the update is successfull
   */
  async update(
    sessionId: string,
    sessionName: string,
    sessionMetadata?: SessionMetadata,
    sessionDate?: string
  ): Promise<boolean> {
    const variables = {
      sessionId,
      sessionName,
      sessionDate
    };
    // Avoid passing empty metadata, this saves a bit of records in the db
    if (sessionMetadata) {
      variables['sessionMetadata'] = JSON.stringify(sessionMetadata); // Stringify metadata for saving
    }
    try {
      return ((await firstValueFrom(this.apollo.mutate({
        mutation: UpdateSessionMutation,
        variables
      }))).data as { updated: boolean })?.updated === true;
    } catch (error) {
      throw Error(`Cannot update session: ${error}`);
    }
  }

  /**
   * Extracts a name for a specific session. It gets the session name attribute if present or
   * fallbacks to project path otherwise.
   * @param session A Session object
   * @returns The session name
   */
  extractSessionNameFromSession(session: Session): string {
    return session.projectPath.split('/')[2] || session.projectPath;
  }

  /**
   * Sorts the currently loaded session conditions inline
   */
  public sortConditions(sessionMetadata: SessionMetadata, conditions: Condition[]): Condition[] {
    if (sessionMetadata?.conditionsOrder?.length > 1) {
      conditions = conditions.sort((t1, t2) => {
        const order = sessionMetadata.conditionsOrder;
        // The condition name (path) is usually loaded with a `\` at the end that needs to be removed to be
        // matched against the order array. Then return conditions by their array position order.
        const path1 = t1.path.slice(-1) === '/' ? t1.path.slice(0, -1) : t1.path;
        const path2 = t2.path.slice(-1) === '/' ? t2.path.slice(0, -1) : t2.path;
        return order.indexOf(path1) < order.indexOf(path2) ? -1 : 1;
      });
    }
    return conditions;
  }

  public clickedCaret(id: string): void {
    this.collapsed[id] = !this.collapsed[id];
  }

  public clickConditions(fullPath: string): void {
    this.conditionsCollapsed[fullPath] = !this.conditionsCollapsed[fullPath];
  }

  public collapseCondition(fullPath: string): void {
    this.conditionsCollapsed[fullPath] = true;
  }

  public sessionHasReports(sessionId: string, reportList: any): boolean {
    for (const r of reportList) {
      if (r.session.id === sessionId) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sorts (in place) a Session array based on the project date (most recent dates first).
   * If session.date if not valid, sort alphabetically
   * @param sessions an array of sessions to sort
   */
  public sortSessions(sessions: Session[]): void {
    sessions.sort((first, second) => {
      return this.compareSessionsByDate(first, second);
    });
  }


  /**
   * Compares two sessions by date.
   * If both first and second session have invalid dates, sort them alphabetically by projectPath.
   * If only one of the sessions has an invalid date, sort the one with the valid date first.
   * If both sessions have valid dates, sort them by date.
   * 
   * @param first the first session to compare
   * @param second the second session to compare
   * @returns a number that indicates the order of the two sessions.
   * If the result is negative a is sorted before b.
   * If the result is positive b is sorted before a.
   * If the result is 0 no changes are done.
   */
  public compareSessionsByDate(first: Session, second: Session): number {
    const fd = this.datetimeService.fromISO(first.date);
    const sd = this.datetimeService.fromISO(second.date);

    // if none of the sessions have a valid session.date, compare the sessions' names
    if (isNaN(fd.getTime()) && isNaN(sd.getTime())) {
      return first.projectPath.localeCompare(second.projectPath);
    }
    if (isNaN(fd.getTime())) {
      return 1;
    }
    if (isNaN(sd.getTime())) {
      return -1;
    }
    return sd.getTime() - fd.getTime();
  }

  /**
   * return a SessionAndConditionObject created from trials grouped by session
   */
  public createSessionAndConditionObject(sessions: { [key: string]: Clip[] }, selectedReference: string = undefined): SessionAndConditionObject[] {
    const returnObj: SessionAndConditionObject[] = [];
    for (const sessionName in sessions) {
      // group trials by conditions for each session
      const conditions = this.groupClipsByAtrr(sessions[sessionName], 'conditionName');
      const conditionsArray = [];
      for (const conditionName in conditions) {
        conditionsArray.push({ conditionName: conditionName, trials: conditions[conditionName] });
      }
      returnObj.push({ sessionName: sessionName, conditions: conditionsArray, referenceDataSetName: selectedReference});
    }
    return returnObj;
  }

  /**
   * @param clips List of clips to divide in groups
   * @param attr Attribute to use for grouping
   * @returns Object that associate different attribute values (key) with groups of clips with that attribute value
   */
  public groupClipsByAtrr(clips: Clip[], attr: string): { [key: string]: Clip[] } {
    return clips.reduce((groups, item) => {
      const group = groups[item[attr]] || [];
      group.push(item);
      groups[item[attr]] = group;
      return groups;
    }, {});
  }

  /*
    * Parses the title of a gait report to change the date format from yyyy-mm-dd to dd-mm-yyyy
  */
  public parseGaitReportTitle(title: string): string {
    // Regex to match the date in yyyy-mm-dd format
    const datePattern = /(\d{4})-(\d{2})-(\d{2})/;
    const match = title.match(datePattern);

    if (match) {
      const fullDate = match.shift();
      title = title.substring(0, title.indexOf(fullDate) + 10);
      return title;
    } else {
      return title;
    }
  }
}
