export enum MotionAnalysisConfiguration {
    None,
    MotionAnalysisFullBody, //41 markers
    MotionAnalysisMCS,
    MotionAnalysisM101
}

export class MotionAnalysis {

    markerList: any[];

    //To be added
    //RBAC
    //LSHN
    //RSHN

    readonly head = ["RFHD", "LFHD", "LBHD", "RBHD"];

    readonly upperLimbLeft = ["LSHO", "LUPA", "LELB", "LWRA", "LWRB", "LFIN"]; //LARM
    readonly upperLimbRight = ["RSHO", "RUPA", "RELB", "RWRA", "RWRB", "RFIN"]; //RARM

    readonly torso = ["CLAV", "STRN", "C7", "T10"]; // "R10"];
    readonly pelvis = ["LFWT", "RFWT", "RBWT", "LBWT"];

    readonly lowerLimbLeft = ["LTHI", "LKNE"]; // "LLEG"];
    readonly lowerLimbRight = ["RTHI", "RKNE"];// "RLEG"];

    readonly leftFoot = ["LANK", "LHEE", "LTOE", "LMT5"];
    readonly rightFoot = ["RANK", "RHEE", "RTOE", "RMT5"];

    readonly mcs = ["HeadTop", "ForeHead", "LFrontHead", "RFrontHead", "TopSpine", "Sternum", "Scapula", "MiddleBack", "LowerBack", "LFShoulder", "LShoulder", "LBicep", "LOuterElbow", "LHand", "LWristPinky", "LWristThumb", "RFShoulder", "RShoulder", "RBicep", "ROuterElbow", "RHand", "RWristPinky", "RWristThumb", "Pelvis", "LBackWaist", "RBackWaist", "LFrontWaist", "RFrontWaist", "Bellymarker", "LThigh", "LOuterKnee", "LAnkle", "LHeel", "LOuterMeta", "LToe", "RThigh", "ROuterKnee", "RAnkle", "RHeel", "ROuterMeta", "RToe"];

    readonly m101 = ["LtFtHip", "LtBkHip", "RtFtHip", "RtBkHip", "Spine1", "Spine2", "Spine3", "SpineOffsetLow", "SpineOffsetHigh", "LtCtChest", "RtCtChest",
    "LtChest", "RtChest", "LtShoulder", "LtBicep", "LtElbow", "LtForeArm", "LtWrist", "LtPinky", "LtThumb", "LtMiddFing", "RtShoulder", "RtBicep", "RtElbow",
    "RtForeArm","RtWrist", "RtPinky", "RtThumb", "RtMiddFing", "LtThigh", "LtKnee", "LtCalf", "LtAnkle", "LtHeel", "LtBall", "LtToe", "RtThigh", "RtKnee", "RtCalf",
    "RtAnkle", "RtHeel", "RtBall", "RtToe", "TopHead", "LfFtHead", "LtBkHead", "RtFtHead", "RtBkHead"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments(conf: MotionAnalysisConfiguration) {

        const segments = [];

        if (conf == MotionAnalysisConfiguration.MotionAnalysisM101) {
          segments.push( { name: "head", markerList: [ "TopHead", "LfFtHead", "LtBkHead", "RtFtHead", "RtBkHead"], allConnected: true});

          segments.push( { name: "torso", markerList: ["Spine1", "Spine2", "Spine3", "SpineOffsetHigh", "LtCtChest", "RtCtChest",
          "LtChest", "RtChest"], allConnected: true});

          segments.push( { name: "leftArm", markerList: [ "LtShoulder", "LtBicep", "LtElbow"], allConnected: true, side: 1 });
          segments.push( { name: "leftForeArm", markerList: [ "LtElbow", "LtForeArm", "LtWrist"], allConnected: true, side: 1 });
          segments.push( { name: "leftHand", markerList: ["LtWrist", "LtPinky", "LtThumb", "LtMiddFing"], allConnected: true, side: 1 });

          segments.push( { name: "rightArm", markerList: [ "RtShoulder", "RtBicep", "RtElbow"], allConnected: true, side: 2 });
          segments.push( { name: "rightForeArm", markerList: ["RtElbow", "RtForeArm", "RtWrist"], allConnected: true, side: 2 });
          segments.push( { name: "rightHand", markerList: [ "RtWrist", "RtPinky", "RtThumb", "RtMiddFing"], allConnected: true, side: 2 });

          segments.push( { name: "pelvis", markerList: [ "Root", "SpineOffsetLow","LtFtHip", "LtBkHip", "RtFtHip", "RtBkHip"], allConnected: true});

          segments.push( { name: "leftLeg", markerList: [ "LtFtHip", "LtBkHip", "LtThigh", "LtKnee"], allConnected: true, side: 1 });
          segments.push( { name: "lefLowerLeg", markerList: [ "LtKnee", "LtCalf", "LtAnkle"], allConnected: true, side: 1 });
          segments.push( { name: "leftFoot", markerList: [ "LtAnkle", "LtHeel", "LtBall", "LtToe" ], allConnected: true, side: 1 });

          segments.push( { name: "rightLeg", markerList: [ "RtFtHip", "RtBkHip", "RtThigh", "RtKnee"], allConnected: true, side: 2 });
          segments.push( { name: "rightLowerLeg", markerList: ["RtKnee", "RtCalf", "RtAnkle"], allConnected: true, side: 2 });
          segments.push( { name: "rightFoot", markerList: [ "RtAnkle", "RtHeel", "RtBall", "RtToe" ], allConnected: true, side: 2 });
        } else if (conf == MotionAnalysisConfiguration.MotionAnalysisMCS) {
          segments.push( { name: "head", markerList: [ "HeadTop", "ForeHead", "LFrontHead", "RFrontHead"], allConnected: true});

          segments.push( { name: "torso", markerList: [ "TopSpine", "Sternum", "Scapula", "MiddleBack", "LowerBack"], allConnected: true});

          segments.push( { name: "leftArm", markerList: [ "LFShoulder", "LShoulder", "LBicep"], allConnected: true, side: 1 });
          segments.push( { name: "leftForeArm", markerList: [ "LBicep", "LOuterElbow", "LHand"], allConnected: true, side: 1 });
          segments.push( { name: "leftHand", markerList: [ "LHand", "LWristPinky", "LWristThumb"], allConnected: true, side: 1 });

          segments.push( { name: "rightArm", markerList: [ "RFShoulder", "RShoulder", "RBicep"], allConnected: true, side: 2 });
          segments.push( { name: "rightForeArm", markerList: [ "RBicep", "ROuterElbow", "RHand"], allConnected: true, side: 2 });
          segments.push( { name: "rightHand", markerList: [ "RHand", "RWristPinky", "RWristThumb"], allConnected: true, side: 2 });

          segments.push( { name: "pelvis", markerList: [ "Root", "Pelvis", "LBackWaist", "RBackWaist", "LFrontWaist", "RFrontWaist", "Bellymarker"], allConnected: true});
          segments.push( { name: "leftLeg", markerList: [ "LFrontWaist", "LBackWaist", "LThigh", "LOuterKnee"], allConnected: true, side: 1 });
          segments.push( { name: "lefLowerLeg", markerList: [ "LOuterKnee", "LAnkle"], allConnected: true, side: 1 });
          segments.push( { name: "leftFoot", markerList: [ "LAnkle", "LHeel", "LOuterMeta", "LToe"], allConnected: true, side: 1 });

          segments.push( { name: "rightLeg", markerList: [ "RFrontWaist", "RBackWaist", "RThigh", "ROuterKnee"], allConnected: true, side: 2 });
          segments.push( { name: "rightLowerLeg", markerList: [ "ROuterKnee", "RAnkle"], allConnected: true, side: 2 });
          segments.push( { name: "rightFoot", markerList: [ "RAnkle", "RHeel", "ROuterMeta", "RToe"], allConnected: true, side: 2 });
        }

        //if(this.hasAllMarkers(this.head))
          segments.push({ name: "head", markerList: this.head, allConnected: true});

        //if(this.hasAllMarkers(this.upperLimbRight))
        {
          segments.push({ name: "rightArm", markerList: ["RSHO", "RUPA", "RELB", "RFRM", "RARM"], allConnected: true, side: 2 });
          segments.push({ name: "rightHand", markerList: ["RARM", "RFRM", "RWRA", "RWRB", "RFIN"], allConnected: true, side: 2 });
        }

        //if(this.hasAllMarkers(this.upperLimbLeft))
        {
          segments.push({ name: "leftArm", markerList: ["LSHO", "LUPA", "LELB", "LFRM" ,"LARM"], allConnected: true, side: 1 });
          segments.push({ name: "leftHand", markerList: ["LARM", "LFRM", "LWRA", "LWRB", "LFIN"], allConnected: true, side: 1 });
        }

        //if(this.hasAllMarkers(this.pelvis))
          segments.push(  { name: "pelvis", markerList:  ["LFWT", "RFWT", "RBWT", "LBWT"], allConnected: true });

        //if(this.hasAllMarkers(this.torso))
          segments.push(  { name: "torso", markerList:  ["CLAV", "STRN", "C7", "T10", "R10"], allConnected: true });

        //if(this.hasAllMarkers(this.lowerLimbRight) && this.hasAllMarkers(this.pelvis) && this.hasAllMarkers(this.leftFoot))
        {
          segments.push(  { name: "leftLegUp", markerList:  ["LFWT", "LBWT", "LTHI",  "LKNE"], allConnected: true, side: 1 });
          segments.push(  { name: "leftLeg", markerList:  ["LKNE", "LLEG", "LANK"], allConnected: true, side: 1 });
          segments.push(  { name: "leftFoot", markerList:  ["LANK", "LHEE", "LTOE", "LMT5"], allConnected: true, side: 1 });

          segments.push(  { name: "rightLegUp", markerList:  ["RFWT", "RBWT", "RTHI", "RKNE"], allConnected: true, side: 2 });
          segments.push(  { name: "rightLeg", markerList:  ["RKNE", "RLEG", "RANK"], allConnected: true, side: 2 });
          segments.push(  { name: "rightFoot", markerList:  ["RANK", "RHEE", "RTOE", "RMT5"], allConnected: true, side: 2 });
        }

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];
        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      checkMotionAnalysis() {

        const fullBody = [this.head,
          this.upperLimbLeft,
          this.upperLimbRight,
          this.torso,
          this.pelvis,
          this.lowerLimbLeft,
          this.lowerLimbRight,
          this.leftFoot,
          this.rightFoot
        ];

        console.log(this.checkConfiguration(fullBody));
        if (this.checkConfiguration(fullBody).length <= 1) {
            return MotionAnalysisConfiguration.MotionAnalysisFullBody;
        }

        const fullBodyMcs = [this.mcs];

        if (this.checkConfiguration(fullBodyMcs).length <= 1) {
          return MotionAnalysisConfiguration.MotionAnalysisMCS;
        }

        const fullBodyM101 = [this.m101];

        if (this.checkConfiguration(fullBodyM101).length <= 1) {
            return MotionAnalysisConfiguration.MotionAnalysisM101;
        }
        return MotionAnalysisConfiguration.None;

      }

}
