import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChartReadyEvent, ChartSelectEvent } from 'ng2-google-charts';

const MAX_NO_POINTS = 1500;

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  @Input() set input(values) {
    this.setLayout('base');
    this.setChartData(values);
    this.visible = true;
  }

  @ViewChild('cchart', { static: true }) cchart;
  @ViewChild('container', { static: true }) container: ElementRef;

  constructor() {

  }

  readonly green: string = '#55ff4a'; // base color (secondary)
  readonly cyan: string = '#00cdc9'; // gradient point
  readonly blue: string = '#4396ff'; // gradient point
  readonly red: string = '#ff0000'; // gradient point
  readonly gray: string = '#aaaaaa'; // gradient point
  readonly purple: string = '#5850ff'; // base color (primary

  chartData: any;

  chartInfo: {
    dataUrl?: string,
    skip?: number,
    labels?: {
      title?: string,
      hAxis?: string,
      vAxis?: string,
      time?: string
    },
    colors?: string[]
  };

  values: any[] = []; //Vector4[] = [];
  dataReady: boolean = false;
  annotationRowIndex: number;
  isFullscreen: boolean = false;
  baseLayout: string = 'thumb-top';
  visible: boolean = false;
  skipFactor = 1;
  chartReady: boolean = false;

  playerClass: string = this.baseLayout;
  maxCount: number = 22;
  hasStd: boolean = false;

  ngOnInit() {

  }

  public setChartData(chartInfo: any, create3d: boolean = false) {
    this.createChart(chartInfo);
    this.prepareTableColumns(chartInfo);
    this.fillChartData(chartInfo.values);
  }

  prepareTableColumns(data: any) {
    this.chartData.dataTable.cols.push( {
      id: "001",
      label: "name",
      type: "string"
    });

    let count = 2;
    this.hasStd = data.labels.std ? true : false;

    for (const label of data.labels.title) {
      this.chartData.dataTable.cols.push( {
        id: count.toString(),
        label: label,
        type: "number"
      });
      count++;
      if (this.hasStd) {
        this.chartData.dataTable.cols.push( {
          id: count.toString(),
          label: label + '+',
          type: "number",
          role: 'interval'
          });
          count++;
        this.chartData.dataTable.cols.push( {
          id: count.toString(),
          label: label + '-',
          type: "number",
          role: 'interval'
          });
      }
      this.chartData.dataTable.cols.push({type: 'string', role: 'tooltip'});
    }

    /*
      let count = 1;
      for(let v of values) {
      this.chartData.dataTable.cols.push( {
        id: count.toString(),
        label: v.label,
        type: "number"
      });
      if(count++ >= this.maxCount)
        break;
    }*/
  }

  public createChart(chartInfo: any) {

    this.chartInfo = { };

    for (const p in chartInfo) {
      this.chartInfo[p] = chartInfo[p];
    }

    let legend = 'none' as any;
    let title = this.chartInfo.labels.title[0];
    if ( this.chartInfo.labels.title.length > 1) {
      legend = {
        'position': 'top',
        'textStyle': {
          'color': '#fff'
        },
        maxLines: 3
      };
      title = this.chartInfo['title'] ? this.chartInfo['title'] : 'Comparison';
    }

    this.chartData =  {
      chartType: 'ColumnChart',
      dataTable: {
        cols: [],
        rows: []
    },
    options: {
    'title': title,
    'intervals': { 'style': 'bars', 'lineWidth': '2', 'barWidth': '0.5' },
    'titleTextStyle': {
      'color': '#fff'
    },
    'chartArea': {
      'width': '70%',
      'height': '60%',
    },
    'backgroundColor': {
      fill: 'transparent'
    },
    'bar': {
      'groupWidth': '80%'
    },
    'legend': legend,
    'hAxis': {
      'textStyle': {
      'color': '#fff'},
      //'title': this.chartInfo.labels.hAxis,
      'slantedTextAngle': '60',
      'titleTextStyle': {
        'color': '#fff'
      }
    },
    'vAxis': {
      'textStyle': {
        'color': '#fff'
      },
      'textPosition': 'none',
      'title': this.chartInfo.labels.vAxis,
      'titleTextStyle': {
        'color': '#fff'
      }
    },
    'colors': this.chartInfo.colors ? this.chartInfo.colors : [ this.cyan, this.blue, this.green, this.purple ]
    },
    'annotations': [{
      'style': 'line',
      'textStyle': {
        'fontSize': 30,
        'color': "#000",
        'bold': true
        }
      }],
    };

    if (chartInfo.labels.vLimits) {
      const limits = chartInfo.labels.vLimits;
      this.chartData.options.vAxis.viewWindow = {
        'min': limits[0],
        'max': limits[1]
      };
    }
    this.refresh();
  }

  public refresh() {

    if (this.visible && this.chartReady)
      this.cchart.draw();
  }

  select(event: ChartSelectEvent) {
    if (event.message !== "select")
      return;
  }

  resizeChart() {
    this.chartData.options.height = '' + this.container.nativeElement.clientHeight*0.9 + '';
    if (this.visible)
      this.cchart.draw();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.chartData && this.chartData.options)
      this.resizeChart();
  }


  toggleFullscreen() {
    if (!this.isFullscreen) {
      this.playerClass = 'fullscreen';
      this.isFullscreen = true;
    } else {
      this.playerClass = this.baseLayout;
      this.isFullscreen = false;
    }
  }

  setLayout(layout: string) {
    this.baseLayout = layout;
    this.playerClass = this.baseLayout;
  }

  public isVisible() {
    return this.visible;
  }

  public toggleVisibility(enable?: boolean) {

    this.visible = enable != undefined ? enable : !this.visible;
    if (!this.chartReady)
      return;

    if (this.visible) {
      this.resizeChart();
      this.container.nativeElement.style.visibility = '';
    } else {
      this.container.nativeElement.style.visibility = 'hidden';
    }

  }

  public isChartAvailable() {
    return this.dataReady;
  }

  private fillChartData(tracks: any) {

    let count = 0;
    const labels = [];
    for (const track of tracks) {
      for (const value of track) {
        let found = false;
        const newLabel = value.label;
        for (const label of labels) {
          if ( label.label == value.label) {
            found = true;
            label.values.push(value.values);
            label.unit = value.unit; //fix me: multiple chart could have different units
            break;
          }
        }
        if (!found)
          labels.push({ label: newLabel, unit: value.unit, values: [ value.values ] });
      }
    }

    const toBeNormalized = [ 'Cadans', 'Cadence', 'Foot Off', 'Foot Contact'];
    const toBeSkipped = [ 'stappen'];
    const normFactor = 100;
    for (const value of labels) {

      const dataSample = [];
      dataSample.push({ v: value.label });

      let i = 0;
      for (const v of value.values) {
        let data = v.mean;
        let std;
        if (this.hasStd)
          std = v.std;

        for (const t of toBeNormalized) {
          if (value.label.indexOf(t) != -1) {
            data = data / normFactor;
            if (this.hasStd)
              std = std / normFactor;

            break;
          }
        }

        let skip = false;
        for (const t of toBeSkipped) {
          if (value.label.indexOf(t) != -1) {
            skip = true;
            break;
          }
        }
        if (skip)
          continue;

        dataSample.push({ v:  data });
        if (this.hasStd) {
          dataSample.push({ v:  data + std });
          dataSample.push({ v:  data - std });
        }
        dataSample.push({ v: this.chartInfo.labels.title[i++] + '\n' + value.label +': ' + parseFloat(v.mean).toFixed(2) + ' [' + value.unit + ']'});
      }

      this.chartData.dataTable.rows.push({c: dataSample});

      if (++count >= this.maxCount)
        break;
    }

    this.dataReady = true;
    this.refresh();

  }

  public ready(event: ChartReadyEvent) {
    if (!this.chartReady) {
      this.chartReady = true;
      this.toggleVisibility(this.visible);
    }
  }

}
