import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-save-layout-modal",
  templateUrl: "./save-layout-modal.component.html",
  styleUrls: ["./save-layout-modal.component.scss"],
})
export class SaveLayoutModalComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Output() saveLayoutEvent = new EventEmitter<string>();

  public formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      layoutName: ['Layout', Validators.required],
    });
    // Preventing keydown elements to be propagated to timebar while the modal is opened. Keypress is for spacebar handling
    document.querySelector('#input').addEventListener('keydown', e => {
      e.stopPropagation();
    });
    document.querySelector('#input').addEventListener('keypress', e => {
      e.stopPropagation();
    });

    // TODO: Should we catch pressing ESC key and close the modal?
  }

  public closeModal(): void {
    this.closeModalEvent.emit(true);
  }

  public saveLayout(): void {
    if (this.formGroup.valid) {
      this.saveLayoutEvent.emit(this.formGroup.get('layoutName').value);
    }
  }
}