import { Injectable } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';
import { ChartsTemplatesService } from '../services/charts-templates/charts-templates.service';
import { ColorService } from '../services/color-service/color-service.service';

/**
 * This service is used to handle all legend related modifications related to our charts.
 */

const emgConsistencyLegendStringsRaw = ['(left)-value > ', '(right)-value > ', 'value > ', 'value (left) > ', 'value (right) > '];
const emgConsistencyLegendStringsProcessed = ['(left)-rms > ', '(right)-rms > ', 'rms > ', 'rms (left) > ', 'rms (right) > ', '(left)-processed > ', '(right)-processed > ', 'processed > ', 'processed (left) > ', 'processed (right) > '];
const emgConsistencyLegendStrings = emgConsistencyLegendStringsRaw.concat(emgConsistencyLegendStringsProcessed);

interface LegendProperties {
    sortedSeries: string[];
    sessionNames: string[];
    conditionNames: string[];
    trialNamesWithSession: string[];
    lineIndexInTrial: Record<string,number>;
    hasMultipleLinesPerTrial: boolean;
    hasMultipleGcdCyclesPerTrial: boolean;
    hasEmgValue: boolean;
    hasEmgRms: boolean;
}

@Injectable()

export class EChartsLegendService {

    public applyLegendRenaming = false;
    public lineSelectionByLegend: Record<string, boolean> = {};
    public lineSelectionByLegendLast: Record<string, boolean> = {};
    public showRawChartLines = true;
    public showRmsChartLines = true;
    public showEmgConsistencyRawRmsToggle = false;
    public resetInitialEmgConsistencySelection = false;
    public mergeLabelsForCycles = false;
    public resetEmgConsistencyLeft = true;
    public resetEmgConsistencyRight = true;
    public hasGcdCycles = false;
    public isFullscreen = false;
    public splitView = false;
    public isMultiChartSplitScreen = false;
    public applyAveragePerCondition = false;
    public legendProperties: LegendProperties;
    public insightTrialPaths: Record<string, string> = {};

    constructor(
      private colorService: ColorService,
      private chartsTemplatesService: ChartsTemplatesService
      ) {
      //empty constructor
    }

    /** This function is used to update the entries in the legend.
     * "legendLabels" is an array of strings with the resulting labels, which should match names existing in the series
     * visualization can be modified using the formatter.
     * "extractLegendLabels" is used to keep track of the mapping between existing names and the new names, e.g. for cycle highligting and (de-)selection
    */
    public updateLegend(echartOption: EChartsOption, mergeLegendLabelsForCycles: boolean, simplifyLegendLabelsForReport: boolean, hasCycles: boolean, meanLabel: string, showAveragesForCycles: boolean, isEmgConsistency: boolean, chartMode: 'trial' | 'report'): void {
        this.applyLegendRenaming = false;
        if (isEmgConsistency) {
            // First get the series names (only from the lines, not the std bands)
            const res = this.extractLegendLabels(echartOption, isEmgConsistency, mergeLegendLabelsForCycles, chartMode);
            const legendLabels = res['legendLabels'];
            const replaceText = res['replaceText'];
            if (replaceText === true) {
                this.applyLegendRenaming = true;
                echartOption['legend']['data'] = legendLabels;
                // then remove the ' value >' and/or 'rms > ' w/wo context from the label + 'cycle-0-' if we have cycles
                echartOption['legend']['formatter'] = (name) => {
                    for (const legendStr of emgConsistencyLegendStrings) {
                        name = name.replace(legendStr, '');
                    }
                    name = name.replace('cycle-0-', '');
                    name = name.replace('cycle-0 ', '');
                    return name;
                };
            }
        } else if (chartMode === 'trial' && hasCycles) {
            // First get the series names (only from the lines, not the std bands)
            const res = this.extractLegendLabels(echartOption, isEmgConsistency, mergeLegendLabelsForCycles, chartMode);
            const legendLabels = res['legendLabels'];
            const replaceText = res['replaceText'];
            if (replaceText === true && mergeLegendLabelsForCycles) {
                this.applyLegendRenaming = true;
                echartOption['legend']['data'] = legendLabels;
                // then remove the '-0' and context from the label
                echartOption['legend']['formatter'] = (name) => {
                    name = this.replaceNameForReferenceAndAverage(name);
                    name = this.replaceNameForInsightForces(name);
                    name = this.replaceInsightTrialPaths(name);
                    return name.replace('-0', '');
                };
            } else if (!mergeLegendLabelsForCycles) {
                // simplify legend label and increase count of cycles to start with cycle-1
                echartOption['legend']['formatter'] = (name) => {
                    if (!name.startsWith('mean ')) {
                        // remove context and start with cycle 1 (increase by 1)
                        const cycleStr = 'cycle-';
                        const split = name.split(cycleStr);
                        if (split.length == 2) {
                            let strToReplace = split[1];
                            let forceOrContextStr = '';
                            if ((split[1].includes('-Fx') || split[1].includes('-Fy') || split[1].includes('-Fz')) && split[1].split('-').length > 2) {
                                // we have force: cycle-<x>-<FpName>-<Fdir>
                                const forceSplit = split[1].split('-');
                                forceSplit.shift();
                                forceOrContextStr = forceSplit.join('-');
                                forceOrContextStr = forceOrContextStr.replace(' (left)', '');
                                forceOrContextStr = forceOrContextStr.replace(' (right)', '');
                                forceOrContextStr = '-' + forceOrContextStr;
                            } else if (strToReplace.includes(' (left)')) {
                                forceOrContextStr = ' (left)';
                            } else if (strToReplace.includes(' (right)')) {
                                forceOrContextStr = ' (right)';
                            }
                            // now we should have only a number left
                            if (!isNaN(parseInt(strToReplace))) {
                                strToReplace = parseInt(strToReplace) + 1;
                                strToReplace = strToReplace.toString();
                                name = cycleStr + strToReplace + forceOrContextStr;
                            }
                        }
                    }
                    name = this.replaceNameForReferenceAndAverage(name);
                    name = this.replaceNameForInsightForces(name);
                    name = this.replaceInsightTrialPaths(name);
                    return name;
                };
            }
        } else if (chartMode === 'trial') {
            // keep 'raw' and 'rms' for trials
            echartOption['legend']['formatter'] = (name) => {
                name = name.replace('value (left)', 'raw');
                name = name.replace('rms (left)', 'rms');
                name = name.replace('processed (left)', 'rms');
                name = name.replace('value (right)', 'raw');
                name = name.replace('rms (right)', 'rms');
                name = name.replace('processed (right)', 'rms');
                name = this.replaceNameForReferenceAndAverage(name);
                name = this.replaceInsightTrialPaths(name);
                return name;
            };

        } else if (chartMode === 'report' && simplifyLegendLabelsForReport) {
            const res = this.extractLegendLabels(echartOption, isEmgConsistency, mergeLegendLabelsForCycles, chartMode);
            const legendLabels = res['legendLabels'];
            const replaceText = res['replaceText'];
            if (replaceText === true && mergeLegendLabelsForCycles) {
                this.applyLegendRenaming = true;
                echartOption['legend']['data'] = legendLabels;
            }
            this.setLegendProperties(echartOption);

            echartOption['legend']['formatter'] = (name) => {
                name = this.updateSerieStringForReport(name, meanLabel, showAveragesForCycles, hasCycles, mergeLegendLabelsForCycles, false);
                return name;
            };
        }
    }

    public replaceNameForReferenceAndAverage(name: string): string {
        name = name.replace('<<<reference>>>', 'reference');      // remove our internal indication for reference
        name = name.replace('<<<average>>>', 'average');          // remove our internal indication for average
        name = name.replace('> <<<conditionAvg>>>', '');          // remove our internal indication for condition average (at location of trial name)
        return name;
    }

    public replaceNameForInsightForces(name: string): string {
        name = name.replace('mean-fp-','mean-');        // this is needed for insight to shorten the label, can be removed once we use chartMode other than trial for insight
        return name;
    }

    public replaceInsightTrialPaths(name: string): string {
        for (const trialPath in this.insightTrialPaths) {
            name = name.replace(trialPath, this.insightTrialPaths[trialPath]);
        }
        return name;
    }


    /** This function maps the existing labels on a reduced set of labels to be shown in the legend
     * "legendLabels" contains the set to be shown in the chart
     * "legendLabelsReplace" contains the mapping between the full and reduced set.
     */
    public extractLegendLabels(echartOption: EChartsOption, isEmgConsistency: boolean, mergeLegendLabelsForCycles: boolean, chartMode: 'trial' | 'report') {
		const seriesNames = this.getLineSeriesNames(echartOption);

        const seriesNameByTrial: Record<string, string[]> = {};
        if (chartMode === 'report') {
            // Find trial names based on name > session > condition > trial
            let trialNames = seriesNames.map(serie => serie.split(' > ').splice(1).join(' > '));
            trialNames = trialNames.filter(function(item, pos) {
                return trialNames.indexOf(item)== pos;
            });
            for (const trialName of trialNames) {
                seriesNameByTrial[trialName] = seriesNames.filter(item => item.includes(trialName));
            }
        } else {
            seriesNameByTrial['trial'] = seriesNames;
        }
        // Filter the labels to remain with the first ones only if we have more than 2
        const cycleLabel = 'cycle-';
        let replaceText = false;
        let legendLabels:string[] = [];
        let legendLabelsReplace = {};

        if (isEmgConsistency) {
            replaceText = true;
            const res = this.replaceLegendLabelsEmgConsistency(seriesNames, legendLabelsReplace);
            legendLabels = res['seriesNameStored'];
            legendLabelsReplace = res['legendLabelsReplace'];
        } else if ((chartMode === 'trial' && seriesNames.length > 2) || (chartMode === 'report' && mergeLegendLabelsForCycles) ) {
            if (chartMode === 'trial' && seriesNames[0] === 'x') {
                replaceText = true;
                legendLabels.push(seriesNames[0]);
                for (const serie of seriesNames) {
                    legendLabelsReplace[serie] = seriesNames[0];
                }
            } else if (chartMode === 'trial' && seriesNames[0] === 'x (left)' && seriesNames.length >= 2) {
                replaceText = true;
                const res = this.replaceLegendLabelsLeftRight(seriesNames, legendLabelsReplace);
                legendLabels.push(res['seriesNameLeft']);
                legendLabels.push(res['seriesNameRight']);
                legendLabelsReplace = res['legendLabelsReplace'];
            } else if (mergeLegendLabelsForCycles && seriesNames[0].substring(0, cycleLabel.length) === cycleLabel) {
                replaceText = true;
                for (const trial in seriesNameByTrial) {
                    const seriesNameForTrial = seriesNameByTrial[trial];
                    if (seriesNameForTrial[0].includes('-Fx') && seriesNameForTrial.length > 2) {
                        const res = this.replaceLegendLabelsForceCycles(seriesNameForTrial, legendLabelsReplace);
                        legendLabels.push(res['seriesNameFx']);
                        legendLabels.push(res['seriesNameFy']);
                        legendLabels.push(res['seriesNameFz']);
                        for (const item in res['legendLabelsReplace']) {
                            legendLabelsReplace[item] = res['legendLabelsReplace'][item];
                        }
                    } else if ((seriesNames[0].includes('-rms') || seriesNames[0].includes('-value')) && seriesNameForTrial.length > 1) {
                        const res = this.replaceLegendLabelsEmgCycles(seriesNameForTrial, legendLabelsReplace);
                        if (res['seriesNameRaw'] !== undefined) {
                            legendLabels.push(res['seriesNameRaw']);
                        }
                        if (res['seriesNameRms'] !== undefined) {
                            legendLabels.push(res['seriesNameRms']);
                        }
                        for (const item in res['legendLabelsReplace']) {
                            legendLabelsReplace[item] = res['legendLabelsReplace'][item];
                        }
                    } else if (seriesNameForTrial[0].includes('left')) {
                        const res = this.replaceLegendLabelsLeftRight(seriesNameForTrial, legendLabelsReplace);
                        if (res['seriesNameLeft'] !== undefined) {
                            legendLabels.push(res['seriesNameLeft']);
                        }
                        if (res['seriesNameRight'] !== undefined) {
                            legendLabels.push(res['seriesNameRight']);
                        }
                        for (const item in res['legendLabelsReplace']) {
                            legendLabelsReplace[item] = res['legendLabelsReplace'][item];
                        }
                    } else {
                        legendLabels.push(seriesNameForTrial[0]);
                        for (const serie of seriesNameForTrial) {
                            legendLabelsReplace[serie] = seriesNameForTrial[0];
                        }
                    }
                }
            } else if (mergeLegendLabelsForCycles && seriesNames[0].endsWith('<<<conditionAvg>>>')) {
                for (const trial in seriesNameByTrial) {
                    const seriesNameForTrial = seriesNameByTrial[trial];
                    if (seriesNameForTrial[0].includes('-Fx') && seriesNameForTrial.length > 2) {
                        replaceText = true;
                        const res = this.replaceLegendLabelsForceCycles(seriesNameForTrial, legendLabelsReplace);
                        legendLabels.push(res['seriesNameFx']);
                        legendLabels.push(res['seriesNameFy']);
                        legendLabels.push(res['seriesNameFz']);
                        for (const item in res['legendLabelsReplace']) {
                            legendLabelsReplace[item] = res['legendLabelsReplace'][item];
                        }
                    }
                }
            }
        }

        return { replaceText: replaceText, legendLabels: legendLabels, legendLabelsReplace: legendLabelsReplace };
    }

    private setLegendProperties(echartOption: EChartsOption): void {
        // Starting from a "value > session > condition > trial" structure, we want to move to a
        // context > (session > ) (condition >) trial structure or gcd > (session > ) (condition >)

        // First check how many sessions we have in our chart and remove from the label if we only have a single session
        let seriesNames = this.getLineSeriesNames(echartOption);

        this.legendProperties = {
            sortedSeries: [],
            sessionNames: [],
            conditionNames: [],
            trialNamesWithSession: [],
            lineIndexInTrial: {},
            hasMultipleLinesPerTrial: false,
            hasMultipleGcdCyclesPerTrial: false,
            hasEmgValue: false,
            hasEmgRms: false
        };

        const updateOrderOfSeries = echartOption?.legend['data'] !== undefined ? true : false;
        if (updateOrderOfSeries) {
            // For EMG: we replaced the EMG labels and we first added raw, then rms. Make sure we have the same ordering to get title/item of legend correct.
            const tempReplacementString = '-a<<<temp>>>';
            seriesNames = seriesNames.map(function(val) {
                const strSplit = val.split(' > ');
                const strStart = strSplit.length > 0 ? strSplit[0].replace('-value', tempReplacementString) : '';   // make sure we get raw/value before rms/processed
                let strToAdd = '';
                if (strSplit.length > 1) {
                    strSplit.shift();
                    strToAdd = ' > ' + strSplit.join(' > ');
                }
                return strStart + strToAdd;
            });
            seriesNames.sort();
            this.legendProperties.sortedSeries = seriesNames.map(x => x.replace(tempReplacementString, '-value'));
        } else {
            this.legendProperties.sortedSeries = seriesNames;
        }

        const gcdPaths: Record<string,string[]> = {};
        const nLinesPerTrial: Record<string, number> = {};

        for (const seriesName of this.legendProperties.sortedSeries) {
            const seriesNameSplit = seriesName.split(' > ');
            // Check if we find a "value > session > condition > trial" structure
            if (seriesNameSplit.length > 3) {
                if (!this.legendProperties.sessionNames.includes(seriesNameSplit[1])) {
                    this.legendProperties.sessionNames.push(seriesNameSplit[1]);
                }
                if (!this.legendProperties.conditionNames.includes(seriesNameSplit[2])) {
                    this.legendProperties.conditionNames.push(seriesNameSplit[2]);
                }
                const trialNameWithSession = seriesNameSplit[1] + ' > ' + seriesNameSplit[2] + ' > ' + seriesNameSplit[3];
                if (!this.legendProperties.trialNamesWithSession.includes(trialNameWithSession)) {
                    this.legendProperties.trialNamesWithSession.push(trialNameWithSession);
                    nLinesPerTrial[trialNameWithSession] = 0;
                } else {
                    this.legendProperties.hasMultipleLinesPerTrial = true;
                    nLinesPerTrial[trialNameWithSession] += 1;
                }
                this.legendProperties.lineIndexInTrial[seriesName] = nLinesPerTrial[trialNameWithSession];
                if (this.hasGcdCycles) {
                    let gcdName = seriesNameSplit[0];
                    gcdName = seriesNameSplit[0].replace(' (left)', '');
                    gcdName = seriesNameSplit[0].replace(' (right)', '');
                    if (gcdPaths[trialNameWithSession] === undefined) {
                        gcdPaths[trialNameWithSession] = [];
                    }
                    if (!gcdPaths[trialNameWithSession].includes(gcdName)) {
                        gcdPaths[trialNameWithSession].push(gcdName);
                        if (gcdPaths[trialNameWithSession].length > 1) {
                            this.legendProperties.hasMultipleGcdCyclesPerTrial = true;
                        }
                    }
                }
                if (seriesNameSplit[0].includes('-value')) {
                    this.legendProperties.hasEmgValue = true;
                }
                if (seriesNameSplit[0].includes('-rms') || seriesNameSplit[0].includes('-processed')) {
                    this.legendProperties.hasEmgRms = true;
                }
            }
        }
    }

    public updateSerieStringForReport(nameStr: string, meanLabel: string, showAveragesForCycles: boolean, hasCycles: boolean, mergeLegendLabelsForCycles: boolean, isTooltip: boolean): string {
        // Starting from a "value > session > condition > trial" structure, we want to move to a
        // context > (session > ) (condition >) trial structure or gcd > (session > ) (condition >)

        let simplifyFirstPartOfLegend = true;
        const applyGroupingForFullOrSpitScreen = (!this.hasGcdCycles && this.legendProperties.hasMultipleLinesPerTrial) || (this.hasGcdCycles && this.legendProperties.hasMultipleGcdCyclesPerTrial);

        const hideLegendTitle = this.legendProperties.lineIndexInTrial[nameStr] > 0 ? true : false;

        const hideEmgLabel = (this.legendProperties.hasEmgValue && !this.legendProperties.hasEmgRms) || (!this.legendProperties.hasEmgValue && this.legendProperties.hasEmgRms);
        const hasEmg = this.legendProperties.hasEmgValue || this.legendProperties.hasEmgRms;

        if (this.legendProperties.conditionNames.length == 1 && !this.applyAveragePerCondition) {
            const conditionNameReplace = this.legendProperties.conditionNames[0] + ' > ';
            const index = nameStr.indexOf(conditionNameReplace);
            if (index > -1) {
                nameStr = nameStr.slice(0,index) + nameStr.substring(index + conditionNameReplace.length);
            }
        }
        if (this.legendProperties.sessionNames.length == 1) {
            const sessionNameReplace = this.legendProperties.sessionNames[0] + ' > ';
            const index = nameStr.indexOf(sessionNameReplace);
            if (index > -1) {
                nameStr = nameStr.slice(0,index) + nameStr.substring(index + sessionNameReplace.length);
            }
        }

        // Next, check for possible legend label structures (w/wo mean/cycle, w/wo emg info, w/wo context) and replace the text
        let cycleLabels: string[] = [meanLabel];
        if (isTooltip) {
            cycleLabels = [meanLabel, 'cycle-'];
        } else if (showAveragesForCycles || mergeLegendLabelsForCycles) {
            cycleLabels = [meanLabel, 'cycle-0'];
        }
        const emgLabelStruct: Record<string, string> = {};
        emgLabelStruct[''] = '';
        emgLabelStruct['-rms'] = 'rms';
        emgLabelStruct['-processed'] = 'rms';
        emgLabelStruct['-value'] = 'raw';

        const contextReplacementStruct: Record<string, string> = {};
        contextReplacementStruct[' > '] = ' > ';
        contextReplacementStruct[' (left) > '] = ' > ';
        contextReplacementStruct[' (right) > '] = ' > ';

        let labelFound = false;
        let isLeft = false;
        let isRight = false;
        for (const emgLabel in emgLabelStruct) {
            if (labelFound) {
                break;
            }
            if (!nameStr.includes(emgLabel)) {
                continue;
            }
            for (let cycleLabel of cycleLabels) {
                if (labelFound) {
                    break;
                }
                if (!nameStr.includes(cycleLabel)) {
                    continue;
                } else if (isTooltip) {
                    // for the tooltip, we get the first part of cycle label including the number of the cycle (cycle-0-...)
                    const tempSplit = nameStr.split(cycleLabel);
                    if (tempSplit.length > 1) {
                        const cycleNumber = tempSplit[1].split('-')[0].split(' ')[0];
                        if (cycleNumber.length < 3) {
                            cycleLabel = cycleLabel + cycleNumber;
                        }
                    }
                }
                for (const contextLabel in contextReplacementStruct) {
                    if (!nameStr.includes(contextLabel)) {
                        continue;
                    }
                    const labelToCheck = cycleLabel + emgLabel + contextLabel;
                    let replacementString = emgLabelStruct[emgLabel] + contextLabel;
                    if (hasEmg) {
                        replacementString = emgLabelStruct[emgLabel] + contextReplacementStruct[contextLabel];
                    }

                    if (hideEmgLabel) {
                        replacementString = '';
                    }

                    if (nameStr.length >= labelToCheck.length && nameStr.slice(0,labelToCheck.length) === labelToCheck) {
                        const index = nameStr.indexOf(labelToCheck);
                        if (index !== -1) {
                            isLeft = contextLabel.includes(' (left)');
                            isRight = contextLabel.includes(' (right)');
                            nameStr = replacementString + nameStr.substring(index + labelToCheck.length);
                            labelFound = true;
                            break;
                        }
                    }
                }
            }
        }

        simplifyFirstPartOfLegend = simplifyFirstPartOfLegend && !hasEmg;   // do not update initial part of string, we already updated this

        const arr = nameStr.split(' > ');
        let isForce = false;
        // Finally if we hide context, remove everything before the first " > ", otherwise we check if our label starts with " > ", "mean-" (or "cycle-1-") and we remove it
        if (simplifyFirstPartOfLegend) {
            if (arr.length > 0) {
                if (arr[0].includes('-Fx') || arr[0].includes('-Fy') || arr[0].includes('-Fz')) {
                    isForce = true;
                    isLeft = arr.length > 0 ? arr[0].includes(' (left)') : isLeft;
                    isRight = arr.length > 0 ? arr[0].includes(' (right)') : isRight;

                    // for force, if we start with "cycle-<number>-" and we don't want the averages, then keep cycle and increase number by 1
                    // otherwise only keep Fx/Fy/Fz
                    const forceLabelNames = this.chartsTemplatesService.forceComponentNames;
                    if (showAveragesForCycles) {
                        for (const [index, forceLabel] of ['-Fx', '-Fy', '-Fz'].entries()) {
                            if (arr[0].includes(forceLabel)) {
                                arr[0] = forceLabelNames[index];
                                arr[0] = arr[0].replace('-','');
                                break;
                            }
                        }
                    } else {
                        const cycleSplit = arr[0].split('-');
                        const isRef = nameStr.includes('<<<reference>>>');
                        if ((arr[0].startsWith('cycle-') && cycleSplit.length > 1 && !isNaN(parseInt(cycleSplit[1]))) || isRef) {
                            cycleSplit[1] = (parseInt(cycleSplit[1]) + 1).toString();
                            for (const [index, forceLabel] of ['-Fx', '-Fy', '-Fz'].entries()) {
                                if (arr[0].includes(forceLabel)) {
                                    arr[0] = mergeLegendLabelsForCycles || isRef || isTooltip ? forceLabelNames[index].replace('-','') : 'cycle-' + cycleSplit[1] + forceLabelNames[index];
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    // no force
                    if (showAveragesForCycles && hasCycles) {
                        arr.shift();
                    } else if (this.hasGcdCycles) {
                        // replace left and right for gcd cycles and hide trial name if we have single gcd's per trial
                        if (arr[0].includes (' (left)')) {
                            arr[0] = arr[0].replace(' (left)', ' > left');
                        } else if (arr[0].includes (' (right)')) {
                            arr[0] = arr[0].replace(' (right)', ' > right');
                        }
                        if (!this.legendProperties.hasMultipleGcdCyclesPerTrial) {
                            arr[1] = arr[0];
                            arr.shift();
                        }
                    } else {
                        if (arr[0].startsWith('cycle-')) {
                            arr[0] = this.increaseCycleCountLabelForReport(arr[0], hasEmg, hideEmgLabel);
                        }
                        // remove left and right
                        arr[0] = arr[0].replace(' (left)', '');
                        arr[0] = arr[0].replace(' (right)', '');
                    }
                }
            }
        }

        // For full screen and split screen we use the legendTitle and legendItem rich text format defined in echarts service
        if (arr.length > 0) {
            let strToAdd = '';
            let forceContext = '';
            const fDir = arr[0];
            if (isForce) {
                strToAdd = arr[0];
                arr.shift();
                forceContext = this.addContextToStringForReportLegend(forceContext, isLeft, isRight);
            } else if (hasEmg) {
                if (arr.length > 1 && !hideEmgLabel) {
                    // here we still have an emg indication (raw/rms)
                    strToAdd = arr[0];
                    arr.shift();
                    strToAdd = this.addContextToStringForReportLegend(strToAdd, isLeft, isRight);
                } else if (isLeft) {
                     strToAdd += 'left';
                } else if (isRight) {
                     strToAdd += 'right';
                }
            } else if (!hasCycles) {
                strToAdd = arr[0];
                arr.shift();
                strToAdd = this.addContextToStringForReportLegend(strToAdd, isLeft, isRight);
            } else if (this.hasGcdCycles && this.legendProperties.hasMultipleGcdCyclesPerTrial) {
                strToAdd = arr[0];
                arr.shift();
            } else if (isLeft && !isForce && !this.hasGcdCycles) {
                strToAdd = 'left';
            } else if (isRight && !isForce && !this.hasGcdCycles) {
                strToAdd = 'right';
            }
            if (isForce) {
                let fxLabel = this.chartsTemplatesService.forceComponentNames[0];
                if (fxLabel.startsWith('-')) {
                    fxLabel = fxLabel.replace('-','');
                }

                if (!isTooltip && (this.isFullscreen || (this.splitView && !this.isMultiChartSplitScreen))) {
                    if (fDir.includes(fxLabel)) {
                        nameStr = '{legendTitle|' + arr.join(' > ') + forceContext + '}' + '{legendItem|\n' + strToAdd + '}';
                    } else {
                        nameStr = '{legendItem|' + strToAdd + '}';
                    }
                } else {
                    nameStr = arr.join(' > ') + ' > ' + strToAdd;
                }
            } else {
                if (applyGroupingForFullOrSpitScreen && !isTooltip && (this.isFullscreen || (this.splitView && !this.isMultiChartSplitScreen))) {
                    if ((hideLegendTitle || arr.length === 0) && strToAdd.length > 0) {
                        nameStr = '{legendItem|' + strToAdd + '}';
                    } else {
                        nameStr = '{legendTitle|' + arr.join(' > ') + '}' + '{legendItem|\n' + strToAdd + '}';
                    }
                } else {
                    nameStr = strToAdd.length > 0 ? arr.join(' > ') + ' > ' + strToAdd : arr.join(' > ');
                }
            }
        }

        let startStringsToRemove =  [' > ', '{legendTitle| > ', 'mean-'];
        if (showAveragesForCycles || mergeLegendLabelsForCycles || isTooltip) {
            startStringsToRemove = [' > ', '{legendTitle| > ', 'cycle-1 > ', '{legendTitle| cycle-1 > ', 'mean-'];
        }
        for (const startStringToRemove of startStringsToRemove) {
            if (nameStr.length >= startStringToRemove.length && nameStr.slice(0,startStringToRemove.length) === startStringToRemove) {
                nameStr = nameStr.substring(startStringToRemove.length);
                nameStr = startStringToRemove.includes('{legendTitle|') ? '{legendTitle|' + nameStr : nameStr;  // add back the indication of legendTitle if we had it
            }
        }

        nameStr = this.replaceNameForReferenceAndAverage(nameStr);
        return nameStr;
    }

    private addContextToStringForReportLegend(legendStr: string, isLeft: boolean, isRight: boolean): string {
        if (isLeft) {
            legendStr += ' > left';
        } else if (isRight) {
            legendStr += ' > right';
        }
        return legendStr;
    }

    public increaseCycleCountLabelForReport(cycleLabel: string, hasEmg: boolean, hideEmgLabel: boolean): string {
        const cycleSplit_space = cycleLabel.split(' ');   // find "cycle-x (left/right)"
        const cycleSplit = cycleSplit_space.length > 0 ? cycleSplit_space[0].split('-') : [];

        if (cycleSplit.length > 1 && !isNaN(parseInt(cycleSplit[1]))) {
            cycleSplit[1] = (parseInt(cycleSplit[1]) + 1).toString();
            cycleLabel = 'cycle-' + cycleSplit[1];
            if (hasEmg && !hideEmgLabel && cycleSplit.length > 1) {
                cycleLabel += '-' + cycleSplit[2];
            }
        }
        return cycleLabel;
    }

    public getLineSeriesNames(echartOption: EChartsOption): string[] {
        const seriesNames: string[] = [];
        for (const serie in echartOption.series) {
            if (echartOption.series[serie]['type'] === 'line') {
                seriesNames.push(echartOption.series[serie]['name']);
            }
        }
        return seriesNames;
    }

    private replaceLegendLabelsForceCycles(seriesNames, legendLabelsReplace) {
        // Search for the cycle-x-<fpName>-<forceDir (context)> structure
        let seriesNameFx;
        let seriesNameFy;
        let seriesNameFz;

        if (seriesNames.length > 2) {
            for (const serie of seriesNames) {
                const fpString = serie.split(' > ')[0].split('-').pop();
                if (fpString.includes('Fx')) {
                    seriesNameFx = seriesNameFx === undefined ? serie : seriesNameFx;
                    legendLabelsReplace[serie] = seriesNameFx;
                } else if (fpString.includes('Fy')) {
                    seriesNameFy = seriesNameFy === undefined ? serie : seriesNameFy;
                    legendLabelsReplace[serie] = seriesNameFy;
                } else if (fpString.includes('Fz')) {
                    seriesNameFz = seriesNameFz === undefined ? serie : seriesNameFz;
                    legendLabelsReplace[serie] = seriesNameFz;
                }
            }
        }
        return { seriesNameFx: seriesNameFx, seriesNameFy: seriesNameFy, seriesNameFz: seriesNameFz, legendLabelsReplace: legendLabelsReplace };
    }

    private replaceLegendLabelsEmgCycles(seriesNames, legendLabelsReplace) {
        // Search for the cycle-x-<rms/value (context)> structure
        let seriesNameRaw;
        let seriesNameRms;
        if (seriesNames.length > 1) {
            for (const serie of seriesNames) {
                const emgString = serie.split(' > ')[0].split('-').pop();
                if (emgString.includes('value')) {
                    seriesNameRaw = seriesNameRaw === undefined ? serie : seriesNameRaw;
                    legendLabelsReplace[serie] = seriesNameRaw;
                } else if (emgString.includes('rms') || emgString.includes('processed')) {
                    seriesNameRms = seriesNameRms === undefined ? serie : seriesNameRms;
                    legendLabelsReplace[serie] = seriesNameRms;
                }
            }
        }
        return { seriesNameRaw: seriesNameRaw, seriesNameRms: seriesNameRms, legendLabelsReplace: legendLabelsReplace };
    }

    private replaceLegendLabelsLeftRight(seriesNames, legendLabelsReplace) {
        // Search for the first left (and right) label and use the first one to be shown in the legend
        const seriesNameLeft = seriesNames[0];
        let seriesNameRight;
        let rightFound = false;

        for (const serie of seriesNames) {
            if (serie.includes('left')) {
                legendLabelsReplace[serie] = seriesNameLeft;
            } else if (serie.includes('right')) {
                if (!rightFound) {
                    rightFound = true;
                    seriesNameRight = serie;
                }
                legendLabelsReplace[serie] = seriesNameRight;
            }
        }
        return { seriesNameLeft: seriesNameLeft, seriesNameRight: seriesNameRight, legendLabelsReplace: legendLabelsReplace };
    }


    private replaceLegendLabelsEmgConsistency(seriesNames, legendLabelsReplace) {
        // search for raw and rms values and store raw (if we have raw or both) or rms
        const muscleNames: string[] = [];
        const seriesNameStored: string[] = [];

        const storeRawStr = !(this.showRmsChartLines && !this.showRawChartLines);

        let hasRaw = false;
        let hasRms = false;

        for (const serie of seriesNames) {
            let muscleName = '';
            let myStrToCheck = '';
            let isRaw = false;
            for (const legendStr of emgConsistencyLegendStringsRaw) {
                if (serie.includes(legendStr)) {
                    myStrToCheck = legendStr;
                    isRaw = true;
                    hasRaw = true;
                    break;
                }
            }

            if (!isRaw) {
                for (const legendStr of emgConsistencyLegendStringsProcessed) {
                    if (serie.includes(legendStr)) {
                        myStrToCheck = legendStr;
                        hasRms = true;
                        break;
                    }
                }
            }

            if (myStrToCheck.length > 0) {
                muscleName = serie.split(myStrToCheck)[1];
            }

            const storeStr = (storeRawStr && isRaw) || (!storeRawStr && !isRaw);
            if (muscleName.length > 0) {
                if (storeStr && !muscleNames.some(a =>a.includes(muscleName))) {
                    muscleNames.push(muscleName);
                    seriesNameStored.push(serie);
                }
                legendLabelsReplace[serie] = muscleName;
            }
        }

        this.showEmgConsistencyRawRmsToggle = hasRaw && hasRms;

        for (const legendLabelReplace in legendLabelsReplace) {
            const index = muscleNames.indexOf(legendLabelsReplace[legendLabelReplace]);
            legendLabelsReplace[legendLabelReplace] = seriesNameStored[index];
        }

        return { seriesNameStored: seriesNameStored, legendLabelsReplace: legendLabelsReplace };
    }
    public handleLegendLineSelection(echartOption: EChartsOption,  echartsInstance: ECharts, paramsSelected: Record<string, boolean>, isEmgConsistency: boolean, zoomRightChart: boolean, showEmgConsistencyLeft: boolean, showEmgConsistencyRight: boolean, mergeLegendLabelsForCycles: boolean, chartMode: 'trial' | 'report', hiddenTrials = []): void {
      let hasRaw = false;
      if (isEmgConsistency) {
            // enable first 2 lines for splitscreen, otherwise show all
            // overwrite using stored values in "lineSelectionByLegend"
            let i = 0;
            const res = this.extractLegendLabels(echartOption, isEmgConsistency, mergeLegendLabelsForCycles, chartMode);
            const legendLabelsReplace = res['legendLabelsReplace'];
            const legendKeysSelected: string[] = [];
            const chartTitle = echartOption?.title['text'] as string;
            const updatelineSelection = chartTitle !== undefined ? (chartTitle.includes(' - left') && this.resetEmgConsistencyLeft) || (chartTitle.includes(' - right') && this.resetEmgConsistencyRight) : false;

            let leftUpdated = false;
            let rightUpdated = false;
            for (const series of echartOption.series as Array<any>) {
                if (series.name) {
                    if (this.lineSelectionByLegend[series.name] === undefined || this.resetInitialEmgConsistencySelection || updatelineSelection) {
                        if (this.splitView) {
                            // enable legend for first two lines in case of split screen
                            // but keep track of legend entries of first 2 lines to also enable other lines linked to the same legend entry
                            const legendKey = legendLabelsReplace[series.name];
                            if (i < 2 && !legendKeysSelected.includes(legendKey)) {
                                legendKeysSelected.push(legendKey);
                            }

                            if (legendKeysSelected.includes(legendKey)) {
                                if (series.name.includes(' (left)')) {
                                    this.lineSelectionByLegend[series.name] = showEmgConsistencyLeft;
                                    leftUpdated = true;
                                } else if (series.name.includes(' (right)')) {
                                    this.lineSelectionByLegend[series.name] = showEmgConsistencyRight;
                                    rightUpdated = true;
                                } else {
                                    this.lineSelectionByLegend[series.name] = true;
                                }
                            } else {
                                this.lineSelectionByLegend[series.name] = false;
                            }
                        } else {
                            if (series.name.includes(' (left)')) {
                                this.lineSelectionByLegend[series.name] = showEmgConsistencyLeft;
                                leftUpdated = true;
                            } else if (series.name.includes(' (right)')) {
                                this.lineSelectionByLegend[series.name] = showEmgConsistencyRight;
                                rightUpdated = true;
                            } else {
                                this.lineSelectionByLegend[series.name] = true;
                            }
                        }
                    }
                    for (const legendStr of emgConsistencyLegendStringsRaw) {
                        if (series.name.includes(legendStr)) {
                            hasRaw = true;
                            this.lineSelectionByLegend[series.name] = this.lineSelectionByLegend[series.name] && this.showRawChartLines;
                            break;
                        }
                    }
                    for (const legendStr of emgConsistencyLegendStringsProcessed) {
                        if (series.name.includes(legendStr)) {
                            this.lineSelectionByLegend[series.name] = this.lineSelectionByLegend[series.name] && this.showRmsChartLines;
                            break;
                        }
                    }
                }
                i++;
            }
            this.resetInitialEmgConsistencySelection = false;
            if (leftUpdated) {
                this.resetEmgConsistencyLeft = false;
            }
            if (rightUpdated) {
                this.resetEmgConsistencyRight = false;
            }
        }

        // Apply the selection based on the legend item that was clicked
        if (paramsSelected) {
            for (const chartLine in paramsSelected) {
                this.lineSelectionByLegend[chartLine] = paramsSelected[chartLine];
            }

            // Next, we find whether we need to enable/disable other lines in our chart, since legend can refer to multiple lines
            const res = this.extractLegendLabels(echartOption, isEmgConsistency, mergeLegendLabelsForCycles, chartMode);
            const legendLabelsReplace = res['legendLabelsReplace'];
            if (this.applyLegendRenaming) {
                for (const legendLabelReplace in legendLabelsReplace) {
                    const selected = paramsSelected[legendLabelsReplace[legendLabelReplace]];
                    if (isEmgConsistency) {
                        const isRms = emgConsistencyLegendStringsProcessed.some(x => legendLabelReplace.includes(x));
                        this.lineSelectionByLegend[legendLabelReplace] = selected && (isRms ? this.showRmsChartLines : this.showRawChartLines);
                    } else {
                        this.lineSelectionByLegend[legendLabelReplace] = selected;
                    }
                }
            }
        }

        if (!echartsInstance) {
            echartOption.legend['selected'] = this.lineSelectionByLegend;
            // Place the legend on the right for fullscreen and splitview
            if (this.isFullscreen || (this.splitView && !this.isMultiChartSplitScreen)) {
                echartOption.legend['orient'] = 'vertical';
                echartOption.legend['bottom'] = 80;
                echartOption.legend['textStyle']['overflow'] = 'break';
                echartOption.legend['type'] = 'scroll';
                echartOption.legend['pageTextStyle'] = {color: this.colorService.fullscreenLegendColor};
                echartOption.legend['pageIconColor'] = this.colorService.fullscreenLegendColor;
                if (this.splitView) {
                    echartOption.legend['textStyle']['width'] = 100;
                    echartOption.grid['right'] = '20%';
                    echartOption.legend['left'] = '82%';
                    if (zoomRightChart) {
                      echartOption.grid['left'] = '5%';
                      echartOption.grid['right'] = '15%';
                      echartOption.legend['left'] = '85%';
                    } else {
                      echartOption.grid['left'] = '20%';
                    }
                } else {
                    echartOption.legend['textStyle']['width'] = 120;
                    echartOption.grid['left'] = '10%';
                    echartOption.grid['right'] = '15%';
                    echartOption.legend['left'] = '87%';
                }
            }
        } else if (echartOption.series) {
            for (const seriesName in this.lineSelectionByLegend) {
                for (const series in echartOption.series) {
                    if (echartOption.series[series].name && echartOption.series[series].name === seriesName) {

                        echartsInstance.dispatchAction({
                            type: this.lineSelectionByLegend[seriesName] === true ? 'legendSelect' : 'legendUnSelect',
                            name: seriesName,
                        });
                        break;
                    }
                }
            }
        }
        if (this.isMultiChartSplitScreen) {
          echartOption.title['padding'] = 0;
          echartOption.title['textStyle']['fontSize'] = 10;
          echartOption.title['textStyle']['lineHeight'] = 15;
          echartOption.grid['top'] = 15;
          echartOption.grid['right'] = 10;
          echartOption.grid['left'] = 35;
          echartOption.grid['bottom'] = 50;
          // xAxis
          echartOption.xAxis['axisLabel']['fontSize'] = 9;
          echartOption.xAxis['nameTextStyle']['fontSize'] = 9;
          echartOption.xAxis['nameGap'] = 20;
          // yAxis
          echartOption.yAxis['axisLabel']['fontSize'] = 9;
          echartOption.yAxis['nameTextStyle']['fontSize'] = 9;
          echartOption.yAxis['nameGap'] = 25;
          // legend
          echartOption.legend['orient'] = "horizontal";
          echartOption.legend['textStyle']['fontSize'] = 10;
          echartOption.legend['textStyle']['lineHeight'] = 15;
          echartOption.legend['bottom'] = 0;
          echartOption.legend['type'] = 'scroll';
          echartOption.legend['itemHeight'] = '0';
        }
      if (isEmgConsistency && hasRaw) {
        for (const series of echartOption.series as Array<any>) {
          for (const legendStr of emgConsistencyLegendStringsRaw) {
            if (series.name.includes(legendStr)) {
              series['lineStyle']['opacity'] = 0.7;
              series['lineStyle']['width'] = 0.7;
            }
          }
        }
      }

      // filter active lines based on hidden trials
      if (hiddenTrials.length > 0) {
        for (const key of Object.keys(this.lineSelectionByLegend)) {
          if (hiddenTrials.findIndex(x => key.includes(x)) > -1) {
            this.lineSelectionByLegend[key] = false;
          }
        }
      }
    }

    public resetEmgConsistencyLegendMapping(echartOption: EChartsOption, mergeLegendLabelsForCycles: boolean, chartMode: 'trial' | 'report'): void {
        // if we disabled both raw and rms, restore selection
        if (!this.showRawChartLines && !this.showRmsChartLines) {
            this.lineSelectionByLegend =  JSON.parse(JSON.stringify(this.lineSelectionByLegendLast));
        }
        const res = this.extractLegendLabels(echartOption, true, mergeLegendLabelsForCycles, chartMode);
        const legendLabelsReplace = res['legendLabelsReplace'];
        if (this.applyLegendRenaming) {
            for (const legendLabelReplace in legendLabelsReplace) {
            this.lineSelectionByLegend[legendLabelReplace] = this.lineSelectionByLegend[legendLabelsReplace[legendLabelReplace]];
            }
        }

        // store last selection to be able to restore when both raw and rms are deselected
        if (this.showRawChartLines || this.showRmsChartLines) {
            this.lineSelectionByLegendLast =  JSON.parse(JSON.stringify(this.lineSelectionByLegend));
        }
    }

    public getSeriesToHandleForHighlighting(echartOption: EChartsOption, batch: any[], seriesName: string, isEmgConsistency: boolean, mergeLegendLabelsForCycles: boolean, chartMode: 'trial' | 'report'): string[] {
        const seriesToHandle:string[] = [];
        if (!(batch && batch.length > 0) && seriesName) {
            const res = this.extractLegendLabels(echartOption, isEmgConsistency, mergeLegendLabelsForCycles, chartMode);
            const legendLabelsReplace = res['legendLabelsReplace'];

            for (const legendLabelReplace in legendLabelsReplace) {
                const selected = legendLabelsReplace[legendLabelReplace] === seriesName;
                if (selected === true) {
                    seriesToHandle.push(legendLabelReplace);
                }
            }
        }
        return seriesToHandle;
      }
}
