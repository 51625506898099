import { inject, Injectable, Injector } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { Apollo } from 'apollo-angular';
import { createGaitReportMutation } from 'app/shared/mutations';
import { ChartsTemplatesService, TemplateDefinition, TemplatesConfiguration } from 'app/shared/services/charts-templates/charts-templates.service';
import { FeatureFlagsService } from 'app/shared/services/feature-flags.service';
import { pollConfig } from 'app/shared/services/polling.service';
import gql from 'graphql-tag';
import { firstValueFrom, lastValueFrom, Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { additionalDataStatus, classifyMutation, gaitToolMutation, hrnetMutation, jobStatus, ReportById, ReportByIdForSessionQuery } from '../../shared/queries';
import { ChartTemplatesOverride } from '../project-info/project-info-data.service';
import { ReportDataService } from '../report/report-data.service';
import { ReportLoadDataService } from '../report/report-load-data.service';
import { AdditionalDataId, ClipId, GaitToolProcessors, HrnetProcessors, ProcessableObjectId } from './processor.types';
import { ProcessingStatus, ProcessingStatusEnum, ProcessorType } from './processors';
import { ProgressionParamsService } from './progression-params-service/progression-params.service';
import { ChartsJson, Session, SessionMetadata } from '../patient-view/create-session/session.types';
import { SessionByIdQuery } from '../session-view/session-view.component';
import { SessionService } from '../patient-view/create-session/session.service';

interface BiomechEvaluationResult {
  processBiomechanicalEvaluation: {
    jobId: string;
  }
}

interface JobNode {
  node: {
    status: string;
    result: string;
  },
  errors?: [];
}

export enum WordExporterType {
  BiomechanicalExporter = "BiomechanicalExporter",
  GaitReportExporter = "GaitReportExporter",
  MotorExporter = "MotorExporter",
  StoreChartJsonFiles = "StoreChartJsonFiles"
}

export const GCDMutation = gql`
mutation processGcd($clips: [String!]) {
  processGcd(clips: $clips) {
          jobId
  }
}`;

export const ReprocessC3DMutation = gql`
mutation reprocessC3d($clips: [String!]) {
  reprocessC3d(clips: $clips) {
          jobId
  }
}`;

export const ReprocessVideoMutation = gql`
mutation reprocessVideo($clips: [String!]) {
  reprocessVideo(clips: $clips) {
          jobId
  }
}`;

const getSessionByIDForLoadingReportData = gql`
query getSession($id: ID!) {
  node(id: $id) {
    ... on Session {
      id,
      projectPath,
      metadata,
      reports {
        id
      }
      project {
        id
        name
        canEdit
        configuration
        norms {
          id,
          name
        }
      }
      patient {
        id
        name
      }
    }
  }
}
`;

/**
 * This service handles API calls to perform files processings
 */
@Injectable()
export class ProcessorService {
  private injector = inject(Injector);
  constructor(
    private readonly apolloService: Apollo,
    private progressionParamsService: ProgressionParamsService,
    private featureFlagsService: FeatureFlagsService,
    protected readonly reportDataService: ReportDataService,
    private readonly templateService: ChartsTemplatesService,
    protected readonly sessionService: SessionService,
  ) { }

  /**
   * Runs a file process. Each processor has its own executor.
   * Note: once more executors are added, they should be moved from here and possibly aggregate
   *       some common functionalities.
   * @param objs_ids A list of processable objects to process, can be either clips or session depending on desired process
   * @param processor A processor type
   * @returns Observable<ProcessingStatus>
   */
  public async process(objs_ids: ProcessableObjectId[], processor: ProcessorType, additionalObjects?: ProcessableObjectId[]): Promise<Observable<ProcessingStatus>> {
    switch (processor) {
      case ProcessorType.gaitToolProcessorLtest:
        return this.processGaitTool(objs_ids, GaitToolProcessors.LTEST);
      case ProcessorType.gaitToolProcessor2mwt:
        return this.processGaitTool(objs_ids, GaitToolProcessors.TMWT);
      case ProcessorType.gaitToolProcessorHm:
        return this.processGaitTool(objs_ids, GaitToolProcessors.HM);
      case ProcessorType.GCDProcessor:
        return this.processGCD(objs_ids);
      case ProcessorType.hrnetProcessor:
        return this.processWithHrnet(objs_ids, HrnetProcessors.ANONYMIZER_HRNET);
      case ProcessorType.gaitEvaluation:
        return this.processGaitEvaluation();
      case ProcessorType.biomechanicalEvaluation:
        return this.processBiomechanicalEvaluation(objs_ids, additionalObjects);
      case ProcessorType.reprocessC3DData:
        return this.reprocessC3DData(objs_ids);
      case ProcessorType.reprocessVideos:
        return this.reprocessVideos(objs_ids);
      case ProcessorType.longitudinalChartsProcessor:
        return this.processProgressionParams(objs_ids);
      default:
        console.warn('Not implemented yet.');
        return new Subject();
    }
  }

  private processProgressionParams(clipIds: ClipId[]): Observable<ProcessingStatus> {
    const processMonitor = new Subject<ProcessingStatus>();
    this.progressionParamsService.processClips(clipIds, this.featureFlagsService.get('canPerformProgressionAnalysis'));
    processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
    this.progressionParamsService.processedClips$.pipe(takeUntil(processMonitor), delay(200)).subscribe((processedClips) => {
      if (processedClips === clipIds.length) {
        const hasOlderVersions = this.progressionParamsService.addMeasures();
        this.progressionParamsService.writeProgressionResults();
        if (hasOlderVersions) {
          // We set the status to Failed to show the warning to the user, the results are written though.
          processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.stringify("Note: multiple versions were detected in the data, only the latest version was used to generate the results.")] });
        } else {
          processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
        }
        processMonitor.complete();
      }
    }, (error) => {
      console.error('Error while processing Progression Params', error);
      processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.stringify(error)] });
      processMonitor.complete();
    });
    return processMonitor;
  }

  private async reprocessC3DData(clipIds: ClipId[]): Promise<Observable<ProcessingStatus>> {
    const processMonitor = new Subject<ProcessingStatus>();

    this.apolloService.mutate({
      mutation: ReprocessC3DMutation,
      variables: {
        'clips': clipIds
      },
    }).subscribe((apolloResult: ApolloQueryResult<any>) => {
      processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
      this.apolloService.watchQuery<any>({
        query: jobStatus,
        pollInterval: pollConfig.LONG,
        variables: {
          jobId: apolloResult.data.reprocessC3d.jobId
        },
      })
        .valueChanges.pipe(takeUntil(processMonitor))
        .subscribe(data => {
          if (data.errors || data.data.node.status === 'Failed') {
            console.error('Error while reprocessing C3D/XLSX files', data.errors, data.data.node.result);
            processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.parse(data.data.node.result).result] });
            processMonitor.complete();
            return;
          }
          if (data.data.node.status === 'Complete') {
            processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
            processMonitor.complete();
            return;
          }
        });
    }, (error) => {
      console.error('Error while reprocessing C3D/XLSX files', error);
      processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [error.message] });
      processMonitor.complete();
    });

    return processMonitor;
  }

  private async reprocessVideos(clipIds: ClipId[]): Promise<Observable<ProcessingStatus>> {
    const processMonitor = new Subject<ProcessingStatus>();

    this.apolloService.mutate({
      mutation: ReprocessVideoMutation,
      variables: {
        'clips': clipIds
      },
    }).subscribe((apolloResult: ApolloQueryResult<any>) => {
      processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
      this.apolloService.watchQuery<any>({
        query: jobStatus,
        pollInterval: pollConfig.LONG,
        variables: {
          jobId: apolloResult.data.reprocessVideo.jobId
        },
      })
        .valueChanges.pipe(takeUntil(processMonitor))
        .subscribe(data => {
          if (data.errors || data.data.node.status === 'Failed') {
            console.error('Error while reprocessing Video files', data.errors, data.data.node.result);
            processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.parse(data.data.node.result).result] });
            processMonitor.complete();
            return;
          }
          if (data.data.node.status === 'Complete') {
            processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
            processMonitor.complete();
            return;
          }
        });
    }, (error) => {
      console.error('Error while reprocessing video files', error);
      processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [error.message] });
      processMonitor.complete();
    });

    return processMonitor;
  }

  private async processGaitEvaluation(): Promise<Observable<ProcessingStatus>> {

    const pathnameList = window.location.pathname.split('/');
    const sessionId = pathnameList[pathnameList.length - 1];


    /**
     * This section is incredibily inefficient, but making it efficient would
     * require a lot of work in solving technical debt: a major refactor of the
     * loadAllData function would be required. If you decide to venture in this
     * quest, you have my best wishes.
     *
     * Related issue: https://gitlab.com/moveshelf/mvp/-/issues/3183
    */

    // get the session
    let sessionRes;
    try {
      sessionRes = await firstValueFrom(this.apolloService.query<{ node }>({
        query: getSessionByIDForLoadingReportData,
        variables: {
          id: sessionId
        },
      }));
    } catch (error) {
      // This doesn't seem to be the right way to propagate the error and indeed it's not working (the caller doesn't get the error)
      // There must be another way but I'm unsure at the moment.
      console.error('Error while fetching session: "', sessionId, '". Cannot generate Word report: ', sessionId);
      console.error(error);
      const processMonitor = new Subject<ProcessingStatus>();
      processMonitor.next({ status: ProcessingStatusEnum.FAILED });
      processMonitor.complete();
      return processMonitor;
    }

    const session = sessionRes.data.node;
    const sessionMetadata = JSON.parse(session.metadata);

    const processMonitor = new Subject<ProcessingStatus>();
    processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });

    /**
     * Get all the reports ID to load (we currently load all reports in the
     * session, no matter what). This is a temporary solution until we have a
     * better way to handle this.
     * The previous implementation only loaded the reports that were not in the
     * session metadata yet. This was done to avoid loading the same report
     * multiple times. However, this was not working as expected and was causing
     * issues. The previous implementation is commented below.
     * More info here:
     * - https://gitlab.com/moveshelf/mvp/-/issues/3183
     * - https://gitlab.com/moveshelf/mvp/-/issues/3183#note_1946336525
     */
    const reportsToLoad = session.reports.map(report => report.id);

    // Check if sessionMetadata contains a chartsJson key and get the value, default it to []
    // Consider only the reports that are not in the session metadata yet
    /* // Uncomment this block to use the previous implementation of loading reports
    const reportIds = session.reports.map(report => report.id);
    const currentChartsJson = sessionMetadata?.chartsJsons || [];
    const currentSessionMetadataChartJSONIDs = new Set(currentChartsJson.map(chart => chart.reportId));
    const reportsToLoad = reportIds.filter(id => !currentSessionMetadataChartJSONIDs.has(id));
    */

    // for each report ID of the session, fetch the report data
    // Create an empty object to store dynamic variables
    const reportLoaders: Promise<Report>[] = [];
    for (const reportId of reportsToLoad) {
      reportLoaders.push(this.loadReportById(reportId));
    }

    let loadedReports = [];
    try {
      loadedReports = await Promise.all(reportLoaders);
    } catch (reportLoadError) {
      console.error('There was an error while loading the reports!');
      return;
    }

    if (loadedReports.length !== reportsToLoad.length) {
      console.error('Not all reports were loaded correctly...');
      return;
    }
    const skipSessionMetadataUpdateForJsons = true;
    const serviceInstances = [];
    let serviceId = 1;
    const reportDataLoaders: Promise<ChartsJson>[] = [];
    for (const report of loadedReports) {
      const reportIinjector: Injector = Injector.create({ providers: [ReportLoadDataService], parent: this.injector });
      const curReportService: ReportLoadDataService = reportIinjector.get(ReportLoadDataService);
      const chartsInjector = Injector.create({ providers: [ChartsTemplatesService], parent: this.injector });
      const templateServiceIndependent: ChartsTemplatesService = chartsInjector.get(ChartsTemplatesService);

      curReportService.initializeServices(templateServiceIndependent, this.featureFlagsService);


      // Set initial charts template: this is same behavior as in the report
      // component when handling a newURL for a report
      curReportService.templateService.setInitialChartsTemplate(
        this.featureFlagsService.get('overrideChartTemplates') as ChartTemplatesOverride,
        this.featureFlagsService.get('availableChartsTemplates') as TemplateDefinition[] ?? [],
        true
      );

      // check if there is a custom options object and set the report template
      const customOptionsObject = report.customOptions ? JSON.parse(report.customOptions) : {};
      curReportService.currentTemplateMetadata = customOptionsObject?.metadata;

      if (curReportService.templateService.isNoTemplateSelected(curReportService.currentTemplateMetadata)) {
        // we have the notemplate option, so make sure to reset the template
        curReportService.templateService.resetTemplate();
      } else if (customOptionsObject?.reportConfig) {
        // if we find a report template, overwrite current.
        curReportService.templateService.setCurrentReportTemplate(customOptionsObject.reportConfig as TemplatesConfiguration, false);
      }


      // for each report, load the data. Doing this saves the charts in the session metadata.
      console.debug('loading data for report ID: ', report.id, ' (', report.title, ')');
      curReportService.references = report.project.norms;
      const normId: string = report?.norm?.id ? report.norm.id : undefined;
      curReportService.forceReload = true;
      // load data, this will ensure that the data is saved in the session metadata chartJSONs. We don't use async as we want to fire all these calls in parallel
      reportDataLoaders.push(curReportService.loadAllData(
        report.clips,
        session,
        session.patient.id,
        normId,
        report.avgs,
        report.layoutType === 'ConditionSummary',
        report.id,
        report.title,
        session.id,
        report.project.reports,
        skipSessionMetadataUpdateForJsons
      ));
      console.debug('Done loading data for report ID: ', report.id, ' (', report.title, ')');
      serviceId++;
      serviceInstances.push(curReportService);
      serviceInstances.push(templateServiceIndependent);
    }

    // destroy the dynamically created services
    for (const service of serviceInstances) {
      service.ngOnDestroy();
    }

    let chartsJsonObjects: ChartsJson[] = [];
    try {
      chartsJsonObjects = await Promise.all(reportDataLoaders);
    } catch (reportLoadError) {
      console.error('There was an error while loading report content, please refresh the page or contact an administrator.');
      return;
    }

    if (skipSessionMetadataUpdateForJsons) {
      const res = await firstValueFrom(this.apolloService.query<any>({
        query: SessionByIdQuery,
        variables: {
          id: sessionId
        },
      }));

      let sessionMetadata: SessionMetadata = {};
      let sessionName: string;

      if (res.data?.node) {
        const session: Session = res.data.node;
        sessionName = this.sessionService.extractSessionNameFromSession(session);
        sessionMetadata = JSON.parse(session.metadata as string);
      }

      if (sessionMetadata === null) {
        sessionMetadata = {};     // make sure we have an object in any case
      }

      if (sessionMetadata?.chartsJsons && sessionMetadata.chartsJsons.length > 0) {
        // only remain with the ids in the object and in current session
        const idsToRemove: string[] = [];
        for (const chartJson of sessionMetadata.chartsJsons) {
          if (!reportsToLoad.includes(chartJson.reportId)) {
            idsToRemove.push(chartJson.reportId);
          } else {
            const resCurrentReport = await firstValueFrom(this.apolloService.query<any>({
              query: ReportByIdForSessionQuery,
              variables: {
                id: chartJson.reportId
              },
            }));
            if (resCurrentReport.data?.node?.session?.id) {
              const sessionIdForReport = resCurrentReport.data.node.session.id;
              if (sessionIdForReport !== sessionId) {
                idsToRemove.push(chartJson.reportId);
              }
            }
          }
        }
        sessionMetadata.chartsJsons = sessionMetadata.chartsJsons.filter(i => !idsToRemove.includes(i.reportId));
      } else {
        sessionMetadata.chartsJsons = [];
      }
      
      const chartsJsonObjectsFiltered = chartsJsonObjects.filter (x => x !== undefined);
      for (const chartsJsonObject of chartsJsonObjectsFiltered) {
        // first remove existing for this report (if we did not have it yet, this will pass)
        sessionMetadata.chartsJsons = sessionMetadata.chartsJsons.filter(item => item.reportId !== chartsJsonObject.reportId);
        sessionMetadata.chartsJsons.push(chartsJsonObject);
      }
      await this.sessionService.update(sessionId, sessionName, sessionMetadata); 
    }
    this.apolloService.mutate<{ generateGaitReport: { created: boolean } }>({
      mutation: createGaitReportMutation,
      variables: {
        sessionId: sessionId,
      }
    }).subscribe(({ data }) => {
      console.log('Gait report generation started');

      // In case of timeout not being triggered correctly
      processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
      processMonitor.complete();

      return;
    });

    // This timeout is to ensure that the processMonitor is set before the status is sent
    // if not, the status update will be ignored
    setTimeout(() => {
      processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
      processMonitor.complete();
    }, 5000);

    return processMonitor;
  }

  private async loadReportById(reportId: string): Promise<Report | undefined> {
    const reportRes = await firstValueFrom(this.apolloService.query<{ node; }>({
      query: ReportById,
      variables: {
        id: reportId
      },
    }));

    // check if there is data
    if (!reportRes.data?.node) {
      console.error('No data found for report: ', reportId);
      return undefined;
    }
    return reportRes.data.node;
  }

  private async processBiomechanicalEvaluation(clipIds: ClipId[], additionalDataIds?: AdditionalDataId[]): Promise<Observable<ProcessingStatus>> {
    const processMonitor = new Subject<ProcessingStatus>();

    const processingRequestResult = await lastValueFrom(this.apolloService.mutate<BiomechEvaluationResult>({
      mutation: gql`
        mutation processBiomechanicalEvaluation($clips: [String!], $additionalData: [String]) {
          processBiomechanicalEvaluation(clips: $clips, additionalData: $additionalData) {
            jobId
          }
        }`,
      variables: {
        'clips': clipIds,
        'additionalData': additionalDataIds,
      }
    }));

    processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
    this.apolloService.watchQuery<JobNode>({
      query: jobStatus,
      pollInterval: pollConfig.SHORT,
      variables: {
        jobId: processingRequestResult.data.processBiomechanicalEvaluation.jobId
      },
    })
      .valueChanges.pipe(takeUntil(processMonitor))
      .subscribe(data => {
        if (data.errors || data.data.node.status === 'Failed') {
          console.error('Error while reprocessing C3D files', data.errors, data.data.node.result);
          processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.parse(data.data.node.result).result] });
          processMonitor.complete();
          return;
        }
        if (data.data.node.status === 'Complete') {
          const warning_msgs_str = JSON.parse(data.data.node.result)?.warning;
          let warning_msg = '';
          const warning_msgs = warning_msgs_str !== undefined ? JSON.parse(warning_msgs_str) : [];
          if (warning_msgs.length > 0) {
            warning_msg = 'Warnings:';
            for (const warning of warning_msgs) {
              warning_msg += ' \n - ' + warning;
            }
          }
          processMonitor.next({ status: ProcessingStatusEnum.COMPLETED, warnings: warning_msg });
          processMonitor.complete();
          return;
        }
      });

    return processMonitor;
  }

  private processGCD(clips: ClipId[]): Observable<ProcessingStatus> {
    const processMonitor = new Subject<ProcessingStatus>();
    this.apolloService.mutate({
      mutation: GCDMutation,
      variables: {
        'clips': clips
      },
    }).subscribe((apolloResult: ApolloQueryResult<any>) => {
      processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
      this.apolloService.watchQuery<any>({
        query: jobStatus,
        pollInterval: pollConfig.LONG,
        variables: {
          jobId: apolloResult.data.processGcd.jobId
        },
      })
        .valueChanges.pipe(takeUntil(processMonitor))
        .subscribe(data => {
          if (data.errors || data.data.node.status === 'Failed') {
            console.error('Error while performing GCD analysis', data.errors, data.data.node.result);
            processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.parse(data.data.node.result).result] });
            processMonitor.complete();
            return;
          }
          if (data.data.node.status === 'Complete') {
            processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
            processMonitor.complete();
            return;
          }
        });
    }, (error) => {
      console.error('Error while performing GCD analysis', error);
      processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.stringify(error)] });
      processMonitor.complete();
    });

    return processMonitor;
  }

  private processGaitTool(clips: ClipId[], trialType): Observable<ProcessingStatus> {
    const processMonitor = new Subject<ProcessingStatus>();
    this.apolloService.mutate({
      mutation: gaitToolMutation,
      variables: {
        'clips': clips,
        'trialType': trialType
      },
    }).subscribe((apolloResult: ApolloQueryResult<any>) => {
      processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
      this.apolloService.watchQuery<any>({
        query: jobStatus,
        pollInterval: pollConfig.LONG,
        variables: {
          jobId: apolloResult.data.processGaitTool.jobId
        },
      })
        .valueChanges.pipe(takeUntil(processMonitor))
        .subscribe(data => {
          if (data.errors || data.data.node.status === 'Failed') {
            console.error('Error while performing gait tool analysis', data.errors, data.data.node.result);
            processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.parse(data.data.node.result).result] });
            processMonitor.complete();
            return;
          }
          if (data.data.node.status === 'Complete') {
            processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
            processMonitor.complete();
            return;
          }
        });
    }, (error) => {
      console.error('Error while performing gait tool analysis', error);
      processMonitor.next({ status: ProcessingStatusEnum.FAILED, errors: [JSON.stringify(error)] });
      processMonitor.complete();
    });

    return processMonitor;
  }

  private processWithHrnet(clipIds: ClipId[], type: HrnetProcessors): Observable<ProcessingStatus> {
    const processMonitor = new Subject<ProcessingStatus>();
    /**
     * Triggers the hrnet mutation for the clip, which (on the BE), means to
     * start the hrnet processing for ALL the additional data of the clip.
     */
    this.apolloService.mutate({
      mutation: hrnetMutation,
      variables: {
        'clipIds': clipIds,
        'options': type,
      },
    }).subscribe({
      next: (apolloResult: ApolloQueryResult<any>) => {
        // returns all the additional data of the clip
        processMonitor.next({ status: ProcessingStatusEnum.IN_PROGRESS });
        // for each additional data
        for (const clip of apolloResult.data.processVideo.clips) {
          // poll over the status of the additional data (each additional data is processed separately)
          this.apolloService.watchQuery<any>({
            query: additionalDataStatus,
            variables: {
              'id': clip.id
            },
            pollInterval: pollConfig.LONG,
          })
            .valueChanges.pipe(takeUntil(processMonitor))
            .subscribe((data) => {
              // if value changes, check if the status is complete or failed
              // NOTE: when the first additional data is complete, the process is
              // considered complete
              if (data.errors || data.data.node.uploadStatus === 'Failed') {
                console.error('Error while processing', data.errors);
                processMonitor.next({ status: ProcessingStatusEnum.FAILED });
                processMonitor.complete();
                return;
              }
              if (data.data.node.uploadStatus === 'Complete') {
                processMonitor.next({ status: ProcessingStatusEnum.COMPLETED });
                processMonitor.complete();
                return;
              }
            });
        }
      },
      error: (error) => {
        console.error('Error while performing gait tool analysis', error);
        processMonitor.next({ status: ProcessingStatusEnum.FAILED });
        processMonitor.complete();
      }
    }
    );
    return processMonitor;
  }

  // Demo purposes only
  processClassifier(clip_id): Observable<ProcessingStatus> {
    const processMonitor = new Subject<ProcessingStatus>();
    this.apolloService.mutate<{ classifyPattern: { prediction: unknown } }>({
      mutation: classifyMutation,
      variables: {
        "clipId": clip_id
      },
    }).subscribe(({ data }) => {
      const predictions = data.classifyPattern.prediction;
      processMonitor.next({ status: ProcessingStatusEnum.COMPLETED, results: { predictions: predictions } });
      return;
    });
    return processMonitor;
  }

}
