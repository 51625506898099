import { APP_BASE_HREF, isPlatformServer } from '@angular/common';
import { provideHttpClient, withFetch } from "@angular/common/http";
import { Inject, NgModule, APP_ID, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { ErrorLink } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { faRulerCombined } from '@fortawesome/free-solid-svg-icons';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'environments/environment';
import { RECAPTCHA_BASE_URL, RecaptchaFormsModule } from 'ng-recaptcha-2';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ShareButtonsConfig, ShareModule } from 'ngx-sharebuttons';
// import { AngularFireAuthModule } from '';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AppRoutingModule } from './app-routing.module';
import { DefaultRouteReuseStrategy } from './app-routing.strategy';
import { AppComponent } from './app.component';
import { AuthService } from './core/auth.service';
import { CoreModule } from './core/core.module';
import { MocapActionService } from './moveshelf-3dplayer/mocap-action.service';
import { Moveshelf3dPlayerModule } from './moveshelf-3dplayer/moveshelf-3dplayer.module';
import { WebAppRootComponent } from './roots/web-app-root.component';
import { EnvironmentService } from './shared/services/environment.service';
import { OrganizationService } from './shared/services/organization/organization.service';
import { SharedModule } from './shared/shared.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

const options: ShareButtonsConfig = {
  include: ['facebook', 'twitter', 'google', 'linkedin', 'whatsapp'],
  twitterAccount: 'moveshelf'
};

const stateKey = makeStateKey<any>('apollo.state');

@NgModule({
  declarations: [
    AppComponent,
    WebAppRootComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ShareModule.withConfig(options),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    UiSwitchModule,
    NgScrollbarModule,
    AngularFireModule.initializeApp(environment.firebase, 'moveshelf-mvp'),
    AngularFireAuthModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          sanitize: true
        }
      }
    }),
    CoreModule,
    SharedModule,
    AppRoutingModule,
    // RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    Moveshelf3dPlayerModule.forRoot(),
    ApolloModule,
    FontAwesomeModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
    },
    { provide: APP_BASE_HREF, useValue: '/' }, // environment.baseHref
    { provide: 'ssr', useValue: 'serverApp' },
    {
      provide: RouteReuseStrategy,
      useClass: DefaultRouteReuseStrategy,
    },
    MocapActionService,
    provideHttpClient(withFetch()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private apolloCache: InMemoryCache;
  private readonly env;

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private transferState: TransferState,
    private authService: AuthService,
    private organizationService: OrganizationService,
    public library: FaIconLibrary,
    private environment: EnvironmentService,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.env = this.environment.getEnvironment();
    library.addIcons(faRulerCombined);
    library.addIcons(faFileLines);

    const graphqlUrl = isPlatformServer(platformId) ? this.env.serverGraphqlUrl : this.env.graphqlUrl;

    if (!isPlatformServer(platformId)) {
      console.log(location.origin);
    }

    const retry = new RetryLink({
      delay: {
        initial: 500,
        max: 20000,
      },
      attempts: {
        max: 8,
      }
    });

    // Middleware to intercept all network errors on graphql calls
    const errorLink = new ErrorLink((error) => {
      console.log('graphql error:', error);
    });

    const basic = setContext((operation, context) => ({
      headers: {
        Accept: 'charset=utf-8'
      }
    }));

    const auth = setContext(async (operation, context) => {
      const token = await this.authService.getAuthToken();

      if (!token) {
        return {};
      } else {
        return {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
      }
    });

    const customHttpLink = this.httpLink.create({
      uri: graphqlUrl,
      withCredentials: true
    });

    const link = ApolloLink.from([
      auth,
      // errorLink, // currently not needed, can be enabled for future usages
      retry,
      customHttpLink,
    ]);

    this.apolloCache = new InMemoryCache({});

    this.apollo.create({
      cache: this.apolloCache,
      ssrMode: isPlatformServer(platformId),
      ssrForceFetchDelay: 100,
      link: link,
    });


    if(!isPlatformServer(platformId)) {
      // Set the persistence state for the current session based on the organization
      // local: Save the session data in the browser's local storage
      // session: Save the session data in the browser's session storage
      // In case of undefined, the default value is 'local' return by backend
      this.organizationService.getCurrentOrganization().then((organization) => {
        const persistenceState = organization.sessionPersistence;

        console.debug('Setting persistence to', persistenceState);

        this.authService.setPersistence(persistenceState);
      }).catch((error) => {
        console.error('Error while setting persistence', error);
      });

      this.transferState.onSerialize(stateKey, () => this.apolloCache.extract());

      const transferredState = this.transferState.get(stateKey, null);
      if (transferredState) {
        this.apolloCache.restore(transferredState);
      }
    }
  }
}
