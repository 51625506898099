import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ClipUpdateService } from './clip-update.service';

import { BehaviorSubject } from 'rxjs';

export interface PoseMatch {
  id: string;
  value: number;
  pose: any;
  time: number;
  title?: string;
}

export interface KeyFrame {
  time: number;
  pose: any[];
}

export enum MatchStyle {
  FullBody = 'FULLBODY',
  UpperBody = 'UPPERBODY',
  LowerBody = 'LOWERBODY',
}
@Injectable()
export class PoseComparisonService {

  poseArray1: any;
  poseArrayToSearch: any;

  pose1: any;
  poseToSearch: any;
  poseToSearchIncludeZ: boolean;

  parts: any[] = [];

  clipId: string;
  keyframes: KeyFrame[] = [];
  storedKeyframes: KeyFrame[] = [];

  winnings: BehaviorSubject<PoseMatch[]> = new BehaviorSubject([]);

  numberOfCompares: number = 0;

  marks: BehaviorSubject<any[]> = new BehaviorSubject([]);
  matchStyle: MatchStyle = MatchStyle.FullBody;

  constructor(private clipUpdater: ClipUpdateService,
    private apollo: Apollo) {
    this.setMatchStyle(MatchStyle.FullBody);
  }

  compare(): number {

    if (!this.poseArray1 || !this.poseArrayToSearch) {
      return -1;
    }

    let num = 0;
    let magSq1 = 0;
    let magSq2 = 0;
    let count = 0;

    for (let i = 0; i < this.poseArray1.length; i++) {
      if (this.poseArray1[i] && this.poseArrayToSearch[i]) {
        count++;
        num += this.poseArray1[i] * this.poseArrayToSearch[i];
        magSq1 += this.poseArray1[i] * this.poseArray1[i];
        magSq2 += this.poseArrayToSearch[i] * this.poseArrayToSearch[i];
      }
    }

    const weightScore = 1; // count/(this.parts.length*2);
    const value = num * weightScore / (Math.sqrt(magSq1) * Math.sqrt(magSq2));
    return value;
  }


  getNumberOfStoredKeys() {
    this.apollo.query<any>({
      query: gql`
            query getLikes($clipId: ID!) {
              node(id: $clipId) {
                ... on MocapClip {
                  id,
                  keyframes
                }
              }
            }
          `,
      variables: {
        clipId: this.clipId
      }
    }).subscribe(({ data }) => {
      if (data.node.keyframes != null) {
        this.storedKeyframes = JSON.parse(data.node.keyframes);
      } else {
        this.storedKeyframes = [];
      }
    });
  }

  updateKeyframes(clipId: string, keyframes: KeyFrame[]) {
    this.clipUpdater.updateClip({
      id: clipId,
      metadata: {},
      keyframes: JSON.stringify(keyframes),
    }).subscribe(({ data }) => {
      const keystr = data.updateClip.clip.keyframes;

      if (keystr != null) {
        this.storedKeyframes = JSON.parse(keystr);
      } else {
        this.storedKeyframes = [];
      }
    });
  }

  deMeanDeMag(pose: any, flipY = false, includeZ = false, zUp = false) {

    let properties = ['x', 'y'];
    if (includeZ) {
      properties = ['x', 'y', 'z'];
    }

    const min: any = {};
    const max: any = {};
    for (const prop of properties) {
      min[prop] = 9999999999;
      max[prop] = -9999999999;
    }
    for (const p of pose) {
      for (const prop of properties) {
        if (p.position[prop] < min[prop]) {
          min[prop] = p.position[prop];
        }

        if (p.position[prop] > max[prop]) {
          max[prop] = p.position[prop];
        }
      }
    }
    const mag: any = {};
    const off = min;
    let scale = 0; // a single scale factor avoids distortsions

    for (const prop of properties) {
      mag[prop] = Math.abs(max[prop] - min[prop]);
      if (mag[prop] > scale) {
        scale = mag[prop];
      }
    }

    for (const p of pose) {

      const temp: any = { x: (p.position.x - off.x) / scale, y: (p.position.y - off.y) / scale };
      if (flipY) {
        temp.y = 1 - temp.y;
      }

      if (includeZ) {
        temp.z = (p.position.z - off.z) / scale;
      }

      if (zUp) {
        const oldY = temp.y;
        temp.y = temp.z;
        temp.z = oldY;
      }
      p.position = temp;
    }
  }

  poseToPoseArray(pose: any, includeZ: boolean = false) {
    const poseArray = [];
    for (const part of this.parts) {
      let found = false;
      for (const p of pose) {
        if (p.part == part) {
          found = true;
          poseArray.push(p.position.x);
          poseArray.push(p.position.y);
          if (includeZ) {
            poseArray.push(p.position.z);
          }
          break;
        }
      }
      if (!found) {
        poseArray.push(undefined);
        poseArray.push(undefined);
        if (includeZ) {
          poseArray.push(undefined);
        }
      }
    }
    return poseArray;
  }

  getKeyframeClipId() {
    return this.clipId;
  }

  storeKeyframes() {
    this.updateKeyframes(this.clipId, this.keyframes);
    this.keyframes = [];
  }

  setKeyframes(clipId: string, keyframes: KeyFrame[], skipCompare: boolean = false) {

    // this.keyframes = keyframes;
    if (this.clipId != clipId) {
      this.keyframes = [];
      this.clipId = clipId;
      this.getNumberOfStoredKeys();
    }

    for (const k of keyframes) {
      this.deMeanDeMag(k.pose, false, true, false);
    }

    this.pose1 = keyframes[0].pose;
    this.poseArray1 = this.poseToPoseArray(this.pose1);

    // only for testing, for now
    this.setPoseToSearch(keyframes[0].pose, false, true, true);


    for (const k of keyframes) {
      if (this.keyframes.length == 0 || (k.time - this.keyframes[this.keyframes.length - 1].time) > 0.1) {
        this.keyframes.push(k);
      }
    }

    if (skipCompare) {
      return -1;
    } else {
      return this.compare();
    }
  }

  setPoseToSearch(pose: any, flipY: boolean = true, includeZ: boolean = false, skipCompare: boolean = false) {
    this.poseToSearch = pose;
    this.deMeanDeMag(this.poseToSearch, flipY, includeZ);
    this.poseToSearchIncludeZ = includeZ;
    this.poseArrayToSearch = this.poseToPoseArray(this.poseToSearch, includeZ);

    if (skipCompare) {
      return -1;
    } else {
      return this.compare();
    }
  }

  setMarks(clipId: string, time: number) {
    this.marks.next([{ clipId: clipId, time: time }]);
  }

  setMatchStyle(style: MatchStyle) {
    switch (style) {
      case MatchStyle.UpperBody:
        this.parts = [
          'leftShoulder',
          'rightShoulder',
          'rightElbow',
          'rightWrist',
          'leftElbow',
          'leftWrist'];
        break;
      case MatchStyle.LowerBody:
        this.parts = [
          'rightHip',
          'leftHip',
          'leftKnee',
          'leftAnkle',
          'rightKnee',
          'rightAnkle'];
        break;
      case MatchStyle.FullBody:
      default:
        this.parts = [
          'leftShoulder',
          'rightShoulder',
          'rightHip',
          'leftHip',
          'leftKnee',
          'leftAnkle',
          'rightKnee',
          'rightAnkle',
          'rightElbow',
          'rightWrist',
          'leftElbow',
          'leftWrist'];
    }

    this.matchStyle = style;
  }
}
