import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EventsRawAdditionalData } from "app/core/additional-data.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MocapActionService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public async getStartTime(dataUri?: string): Promise<number> {
    if (!dataUri) {
      return 0;
    }
    const res = await firstValueFrom(this.http.get(dataUri));
    const parsedData = res as EventsRawAdditionalData;
    if (parsedData?.info && parsedData?.info?.start_frame > 0) {
      const info = parsedData.info;
      return info.start_frame / info.sample_rate;
    } else {
      return 0;
    }
  }

  public setStartTime(time: number): void {
    // this is just to have an equivalent method to its polyB counterpart
  }

  public async getForceData(dataUri?: string): Promise<any> {
    if (!dataUri) {
      return undefined;
    }
    return await firstValueFrom(this.http.get(dataUri));
  }

  public setForceData(data: any): void {
    // this is just to have an equivalent method to its polyB counterpart
  }
}
