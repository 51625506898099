import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AdditionalDataService } from './core/additional-data.service';
import { MetaControlService } from './core/meta-control.service';
import { WindowService } from './core/window.service';
import { AnalyticEvent, AnalyticsService } from './shared/services/analytics';
import { OrganizationService, PersistenceState } from './shared/services/organization/organization.service';
import { AuthService, AuthState } from './core/auth.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';

// 15 minutes * 4 = 60 minutes = 1 hour
// 1 hour * 12 = 12 hours of inactivity
const MAXIMUM_RESTART_INACTIVITY = 4 * 12;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  headerSub: Subscription;
  showCookieBanner = false;
  collapse = true;
  search = '';
  avatarUrl: string;

  navbarBtn: string =  "btn-primary";
  navbarBg: string = 'bg-primary';
  navbarDropBg: string = 'bg-primary';

  private subs: Subscription[] = [];
  private pendingUploads = 0;

  // If the user is uploading a file, the inactivity timer is restarted
  // for a maximum of 4 times 
  // i.e. 15 minutes inactivity in case of no upload
  //      60 minutes inactivity in case of upload
  private uploadingRestart = MAXIMUM_RESTART_INACTIVITY;

  constructor(
    private idle: Idle,
    private router: Router,
    private window: WindowService,
    private metaService: MetaControlService,
    private apollo: Apollo,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private readonly orgService: OrganizationService,
    private readonly additionalDataService: AdditionalDataService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.router.events.pipe(
      filter(ev => ev instanceof NavigationEnd))
      .subscribe(() => {
        this.collapse = true;
        this.window.scrollToTop();
      });

      // sets an idle timeout of 15 minutes - 1 second of timeout.
      // idle.setIdle(15 * 60);
      idle.setIdle(15 * 60 - 1);
      // timeout is called after 14:59 minutes of idle time.
      // sets a timeout period of 1 seconds. It should be set to 1 second to trigger
      // in total 15 minutes of inactivity time.
      idle.setTimeout(1);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      // When the idle timeout is reached, log out the user
      this.subs.push(this.idle.onTimeout.subscribe(() => {

        // Check if there are uploads in progress. If there are, restart the inactivity timer
        // for a maximum of 4 times. Decrease the number of restarts and restart the inactivity timer
        if (this.pendingUploads > 0 && this.uploadingRestart > 1) {
          console.debug(`Reset due to pending uploads.`);

          this.uploadingRestart = this.uploadingRestart - 1;
          this.watchInactivity();
        } else {
          console.debug("Logged out due to inactivity");

          this.authService.logOut(true);
        }
      }));
    

      // Reset the idle timer on interrupt
      this.subs.push(this.idle.onInterrupt.subscribe(() => { 
        console.debug("Reset due to interrupt.");

        // Reset the number of restarts because the user is active
        this.uploadingRestart = MAXIMUM_RESTART_INACTIVITY;
        this.watchInactivity();
      }));
  }

  ngOnInit(): void {
    this.subs.push(
      this.router.events.pipe(
        filter(ev => ev instanceof NavigationEnd)
      ).subscribe(async (ev: NavigationEnd) => {
        this.analyticsService.trackEvent(AnalyticEvent.PAGE_VIEW, false);
        if (ev.url === '/home' || ev.url === '/') {
          this.navbarBtn = "btn-none";
          this.navbarBg = 'bg-none';
          this.navbarDropBg = 'bg-drop';
        } else {
          this.navbarBg = 'bg-primary';
          this.navbarBtn = "btn-primary";
          this.navbarDropBg = 'bg-primary';
        }

        const org = await this.orgService.getCurrentOrganization();

        // If maintenance mode is active, interrupt navigation and go to maintenance page
        if (org?.maintenance === true && this.router.url !== '/error/maintenance') {
          console.warn('Maintenance mode active, redirecting to maintenance page');
          this.router.navigate(['error', 'maintenance']);
          return;
        }

        if (org?.id &&
          (ev.url === '/home'
          || ev.url === '/'
          || ev.url === '/login'
        )) {
          this.router.navigate(['/login/' + org?.id]);
        }
      }
      )
    );

    this.subs.push(this.additionalDataService.fileInUpload().subscribe(fileInUpload => {
      this.pendingUploads = fileInUpload;
    }));

    this.metaService.initSharingTags();

    this.authService.state.subscribe(state => {
      if (state == AuthState.LoggedIn) {
        // If user is logged in, set watch for inactivity
        this.watchInactivity();
      } else {
        // If user is not logged in, stop watch for inactivity
        this.stopInactivity();
      }
    });
  }

  // Watch for inactivity
  public watchInactivity() {
    // Check if the current persistance is session, because inactivity is only watched for session persistance
    if (this.authService.currentPersistance == PersistenceState.session) {
        console.debug("Set/reset for inactivity");

        this.idle.watch();
    }
  }

  // Stop watching for inactivity
  public stopInactivity() {
    // Check if the current persistance is session, because inactivity is only watched for session persistance
    if (this.authService.currentPersistance == PersistenceState.session) {
      console.debug("Stop for inactivity");

      this.idle.stop();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNodification(evt: any) {
    if (this.pendingUploads) {
      const msg = "There are uploads in progress. Are you sure you want to leave?";
      evt.returnValue = msg;
      return msg;
    }
  }
}
