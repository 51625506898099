<div *ngIf="pendingUploads" id="upload-in-progress-banner" class="container sticky-top my-2 py-2 card border-warning text-warning text-center" style="width: 70%; z-index: 0">
  <span>
    <i class="fa fa-spinner fa-spin"></i>
    Upload in progress. {{pendingUploads}} file{{pendingUploads > 1 ? 's': ''}} remaining.
  </span>
</div>
<div *ngIf="uploadComplete">
<div *ngIf="enable else nolinkbanner" id="upload-complete-banner" (click)="viewProject()" class="container sticky-top my-2 py-2 card clickable border-success text-success text-center" style="width: 70%; z-index: 0">
  <span>
    Upload complete! Click to view the project your motion clip was added to.
    <span class="pull-right"><i id="close-upload-complete-banner" class="fa fa-times clickable-danger" aria-label="Close" (click)="hideBanner()"></i></span>
  </span>
</div>
<ng-template #nolinkbanner>
  <div id="upload-complete-banner" class="container sticky-top my-2 py-2 card border-success text-success text-center" style="width: 70%; z-index: 0">
  <span>
    Upload complete.
    <span class="pull-right"><i id="close-upload-complete-banner" class="fa fa-times clickable-danger" aria-label="Close" (click)="hideBanner()"></i></span>
  </span>
</div>
</ng-template> 
</div>