export type ClipId = string;
export type SessionId = string;
export type AdditionalDataId = string;
export type ProcessableObjectId = ClipId | SessionId | AdditionalDataId;

export enum HrnetProcessors {
  ANONYMIZER_HRNET = 'blur_faces_smoothen_interpolation_hrnet',
}

export enum GaitToolProcessors {
  LTEST = 'L-test',
  TMWT = '2MWT',
  HM = 'HomeMonitoring'
}
