import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../core/auth.service';
import { EnvironmentService } from '../environment.service';

export interface calloutOptions {
  withCredentials: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private readonly env;
  // Backend base url, already ends with `/`
  private readonly backendLocation: string;

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private environment: EnvironmentService,
  ) {
    this.env = this.environment.getEnvironment();
    this.backendLocation = this.env.privateApiUrl;
  }

  public async get<T>(endpoint: string, options: calloutOptions = { withCredentials: false }): Promise<T> {
    const url = `${this.backendLocation}${endpoint}`;
    const getOptions = {
      headers: { 'Authorization': `Bearer ${await this.auth.getAuthToken()}` },
      withCredentials: options.withCredentials
    };

    return await firstValueFrom(this.http.get<T>(url, getOptions));
  }

  public async post<T>(endpoint: string, body: {}): Promise<T> {
    const url = `${this.backendLocation}${endpoint}`;
    const authHeader = {
      'Authorization': `Bearer ${await this.auth.getAuthToken()}`
    };

    return await this.http.post<T>(url, body, { headers: authHeader }).toPromise();
  }

  public async externalGet<T>(url: string): Promise<T> {
    return await firstValueFrom(this.http.get<T>(url));
  }

  public async externalPost<T>(url: string, body: {}): Promise<T> {
    return await firstValueFrom(this.http.post<T>(url, body));
  }


  public async delete<T>(endpoint: string): Promise<T> {
    const url = `${this.backendLocation}${endpoint}`;
    const authHeader = {
      'Authorization': `Bearer ${await this.auth.getAuthToken()}`
    };

    return await this.http.delete<T>(url, { headers: authHeader }).toPromise();
  }
}
