import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConditionAverageToggleService {
  public averageToggleStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public setStatus(status: boolean): void {
    this.averageToggleStatus.next(status);
  }

  public resetStatus(): void {
    this.averageToggleStatus.next(false);
  }
}
