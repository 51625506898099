import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, IterableDiffers, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ColorService } from 'app/shared/services/color-service/color-service.service';
import { KeyboardHandlerService } from 'app/shared/services/keyboard-handler.service';
import { SharedModule } from 'app/shared/shared.module';
// import './three-global';
import 'three';
// import { OrbitControls } from 'three-orbitcontrols-ts';
import "../../js/Reflector";
import "../../js/Refractor";
import "../../js/Water2";
import { ClipOptionsService } from '../core/clip-options.service';
import { GlobalPlaybackControlService } from '../core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from '../core/playback-controls/playback-control.service';
import { PoseComparisonService } from '../core/pose-comparison.service';
import { ThreeJsRendererService } from '../core/three-js-renderer.service';
import { ThreeJsScene } from '../core/three-js-scene';
import { TimecodeService } from '../core/timecode.service';
import { WebvrService } from '../core/webvr.service';
import { WindowService } from '../core/window.service';
import './BVHLoader';
import { ClipLoaderService } from './clip-loader/clip-loader.service';
import './DRACOLoader';
import './FBXAnimationLoader';
import './FBXLoader';
import './GLTFLoader';
import { MocapActionService } from './mocap-action.service';
import { AMocapClipComponent } from './mocap-clip.abstract';
import './OrbitControls';
import './TGALoader';
import * as THREE from './three-global';
import './TRCLoader';
import './zlib-global';
import './zlib.min';

const THREE = require('three');

const cameraFov = 55;
const cameraInit = {
  origin: { x: 3, y: 1.7, z: 3 },
  target: { x: 0, y: 0.8, z: 0 }
};

@Component({
  standalone: true,
  selector: 'app-mocap-clip',
  templateUrl: './mocap-clip.component.html',
  styleUrls: ['./mocap-clip.component.scss'],
  imports: [CommonModule, SharedModule],
  animations: [
    trigger('controlState', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible <=> hidden', animate('200ms ease-out')),
    ])
  ]
})
export class MocapClipComponent extends AMocapClipComponent implements OnInit, OnDestroy, ThreeJsScene {

  constructor(
    protected readonly renderer: ThreeJsRendererService,
    protected readonly domRenderer: Renderer2,
    protected playbackControl: PlaybackControlService,
    protected readonly http: HttpClient,
    protected readonly window: WindowService,
    protected readonly comp: PoseComparisonService,
    protected readonly _iterableDiffers: IterableDiffers,
    protected readonly optionService: ClipOptionsService,
    protected readonly loader: ClipLoaderService,
    protected readonly playbackGlobal: GlobalPlaybackControlService,
    protected readonly timecodeService: TimecodeService,
    protected readonly mocapActionService: MocapActionService,
    protected readonly colorService: ColorService,
    protected readonly  webvr: WebvrService,
    protected readonly keyboardHandlerService: KeyboardHandlerService,
  ) {
    super(
      renderer,
      domRenderer,
      playbackControl,
      http,
      window,
      comp,
      _iterableDiffers,
      optionService,
      loader,
      playbackGlobal,
      timecodeService,
      colorService,
      webvr,
      keyboardHandlerService,
    );
    this.mocapActionService = mocapActionService;
  }
}
