import { TrialTemplate } from "app/projects/trial-template.service"

export interface ClipPlayerOptions {
  applyMesh?: boolean,
  meshAssetUri?: string,
  meshAssetUnits?: number, //conversion wrt meters
  replaceMeshMaterials?: MaterialModifier,
  meshMaterials?: any,
  initTransparent?: boolean,
  skyColorHex?: any,
  gridColorHex?: any,
  floorColorHex?: any,
  lightComplexity?: number,
  useFog?: boolean,
  duplicateTracksList?: {sourceTrack: MotionTrack, destinationTrack: MotionTrack}[],
  renameTracksList?: any,
  cameraInit?: {origin: {x: number, y: number, z: number}, target: {x: number, y: number, z: number}},
  enableFollowCam?: boolean,
  showCameras?: boolean,
  renamePrefix?: { sourcePrefix: string, destPrefix: string},
  opticalSegmentsList?: any[],
  enableOpticalSegments?: boolean,
  segmentsModelUri?: string,
  aidData?: AidData[],
  coordinates?: CoordinatesType,
  charts?: any,
  enableGhost?: boolean,
  ghostOptions?: any,
  placeholderColor?: any,
  forcePlateState?: number,
  timeRamps?: any,
  animationUnits?: any,
  loadMeshFromFile?: boolean,
  skeletonUnits?: any,
  loadAnyModel?: boolean,
  realHeight?: number,
  emgChannels?: any,
  playerTheme?: string,
  video?: any,
  markerTraces?: any,
  plateOptions?: any,
  trialTemplate?: TrialTemplate
}

export interface ClipPlayerInput {
  animation: any,
  customOptions?: ClipPlayerOptions,
  additionalData?: any,
  title?: string,
  id?: string,
  trackNo?: number,
  projectPath?: string,
  offsetTimeExtracted?: number, //on polyB we extract the offset before passing the clip data to the player, and not on AdditionalData
}

export enum MotionTrackProperty {
  Position = "position",
  Rotation = "quaternion",
  Scale = "scale",
  Visible = "visible"
}

export enum MaterialModifier {
  nochange = 0,
  replace = 1,
  modify = 2
}

export enum SideEnum {
  noSide = 0,
  leftSide = 1,
  rightSide = 2
}
export enum CoordinatesType {
  yUp = 0,
  zUp = 1
}
export interface MotionTrack {
  name: string,
  properties?: MotionTrackProperty []
}

export interface OpticalSegmentGroup {
  name?: string,
  markerList: string [],
  side?: SideEnum,
  color?: string,
  allConnected?: boolean,
  lineLength?: number
}

export enum VideoVisibilityEnum {
  hidden = 0,
  small = 1,
  threed = 2
}

export interface AidData {
  type: AidDataType,
  data: any
}

export enum AidDataType {
  none = 0,
  forceAidDataType = 1,
  videoAidDataType = 2,
  audioAidDataType = 3,
  chartAidDataType = 4
}
