type url = string;

export interface SsoProviderInfo {
  id: string;
  label: string;
  icon: url;
};

export enum AvailableSsoProviders {
  AZURE = 'azure',
}

export const availableSsoProvidersInfo: { [key: string]: SsoProviderInfo} = {
  'azure': { id: 'azure', label: 'Microsoft Active Directory', icon: '/assets/icons/microsoft_32x32_icon.png' },
};
