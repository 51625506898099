import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UiControlService } from '../core/ui-control.service';

export enum ClipError {
  NoError = 0,
  Unavailable,
  Unauthorized,
  Unauthenticated
}

@Component({
  selector: 'app-clip-not-available',
  templateUrl: './clip-not-available.component.html',
  styleUrls: ['./clip-not-available.component.scss'],
})
export class ClipNotAvailableComponent implements OnInit {

  @Input() includeLink: boolean = false;

  @Input()
  public errorCode = ClipError.Unavailable;

  readonly errors = ClipError;

  constructor(
    private route: ActivatedRoute,
    private uiService: UiControlService
  ) { }

  ngOnInit() {
    if (this.route.snapshot.data.hideHeader) {
      this.uiService.hideHeader();
    }

    if (this.route.snapshot.data.includeLink)
      this.includeLink = true;
  }

}
