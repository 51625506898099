
const gaitToolVersion = '0.1.12';
const biomechanicalProcessorVersion = '1.0';

export enum ProcessorType {
  glTFConverter = 'gltf-converter',
  pyCgm2Processor = 'pycgm2-processor',
  normProcessor = 'norm-processor',
  gaitToolProcessorLtest = 'gaittool-processor-ltest',
  gaitToolProcessor2mwt = 'gaittool-processor-2mwt',
  gaitToolProcessorHm = 'gaittool-processor-hm',
  GCDProcessor = 'gcd-processor',
  reportGenerator = 'report-generator',
  hrnetProcessor = 'hrnet-processor',
  patternClassifier = 'pattern-classifier',
  biomechanicalEvaluation = 'biomech-evaluation',
  gaitEvaluation = 'gait-evaluation',
  reprocessC3DData = 'reprocess-c3d-data',
  reprocessVideos = 'reprocess-videos',
  longitudinalChartsProcessor = 'longitudinal-charts-processor'
}

export const defaultProcessor = ProcessorType.gaitToolProcessor2mwt;

export interface Processor {
  id: ProcessorType;
  name: string;
}

export const processors: Record<ProcessorType, Processor> = {
  "gaittool-processor-2mwt": {id: ProcessorType.gaitToolProcessor2mwt, name: `GaitTool - 2MWT (v${gaitToolVersion})`},
  "gaittool-processor-ltest": {id: ProcessorType.gaitToolProcessorLtest, name: `GaitTool - LTest (v${gaitToolVersion})`},
  "gaittool-processor-hm": {id: ProcessorType.gaitToolProcessorHm, name: `GaitTool - HomeMonitoring (dev version)`},
  "gcd-processor": {id: ProcessorType.GCDProcessor, name: `Reprocess GCDs`},
  "gltf-converter": {id: ProcessorType.glTFConverter, name: ''},
  "norm-processor": {id: ProcessorType.normProcessor, name: ''},
  "hrnet-processor": {id: ProcessorType.hrnetProcessor, name: '(BETA) Video Anonymizer - HRnet'},
  "pattern-classifier": {id: ProcessorType.patternClassifier, name: ''},
  "pycgm2-processor": {id: ProcessorType.pyCgm2Processor, name: ''},
  "report-generator": {id: ProcessorType.reportGenerator, name: ''},
  'biomech-evaluation': {id: ProcessorType.biomechanicalEvaluation, name: `Biomechanical Evaluation (v${biomechanicalProcessorVersion})`},
  'gait-evaluation': {id: ProcessorType.gaitEvaluation, name: 'Create Gait Word Report'},
  'reprocess-c3d-data': {id: ProcessorType.reprocessC3DData, name: 'Reprocess C3D/XLSX/Noraxon'},
  'reprocess-videos': {id: ProcessorType.reprocessVideos, name: 'Reprocess Videos'},
  'longitudinal-charts-processor': {id: ProcessorType.longitudinalChartsProcessor, name: 'Longitudinal Charts'},
};

export interface ProcessingStatus {
  status: ProcessingStatusEnum,
  errors?: string[],
  warnings?: string,
  results?: { predictions: unknown }
}

export enum ProcessingStatusEnum {
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  CANCELED = 'canceled',
  COMPLETED = 'complete',
}

export const processorsSMK: readonly Processor[] = [
  processors["gaittool-processor-2mwt"],
  processors["gaittool-processor-ltest"],
  // processors["gaittool-processor-hm"],
] as const;

// HRNet processor is not included here because we need to check first
// if the client is in the EU region
export const processorsTesters: readonly Processor[] = [
  processors["gaittool-processor-2mwt"],
  processors["gaittool-processor-ltest"],
  processors["gaittool-processor-hm"],
  processors["gcd-processor"],
  processors["biomech-evaluation"],
  processors["gait-evaluation"],
  processors['reprocess-c3d-data'],
  processors['reprocess-videos'],
  processors['longitudinal-charts-processor'],
] as const;
