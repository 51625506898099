export enum SttSystemsConfiguration {
    None,
    SttSystemsFullBody, //xx markers
    SttSystemsFullBodyBikeFitting,
    SttSystemsFullBodyGait
}

export class SttSystems {

    markerList: any[];

    readonly head = ["front_head", "top_head", "back_head"];

    readonly upperLimbLeft = ["left_shoulder", "left_elbow", "left_wrist"];
    readonly upperLimbRight = ["right_shoulder", "right_elbow", "right_wrist"];

    readonly pelvis = ["right_hip", "left_hip", "back_hip"];
    readonly pelvisAlt = ["right_asis", "left_asis", "s2"];
    readonly torso = ["left_shoulder", "right_shoulder", "neck"];
    readonly torsoAlt = ["neck", "spine_top", "spine_down"];

    readonly lowerLimbLeft = ["left_hip", "left_knee", "left_ankle", "left_foot"];
    readonly lowerLimbRight = ["right_hip", "right_knee", "right_ankle", "right_foot"];

    readonly lowerLimbLeftAlt = ["left_calf", "left_lateral_thigh", "left_knee", "left_ankle", "left_heel", "left_toe"];
    readonly lowerLimbRightAlt = ["right_calf", "right_lateral_thigh", "right_knee", "right_ankle", "right_heel", "right_toe"];

    constructor(markerList: any[]) {
        this.markerList = markerList;
    }

      missingMarkers(limb: any[]) {
        const missing = [];
        for (const l of limb) {
            let found = false;
            for (const m of this.markerList) {
              if (m.name.indexOf(l) != -1) {
                found = true;
                break;
              }
            }
            if (!found) {
              missing.push(l);
            }
          }
          return missing;
      }

      getSegments() {

        const segments = [];

        segments.push({ name: "rightArm", markerList: ["right_shoulder", "right_elbow", "right_wrist"], allConnected: false, side: 2 });
        segments.push({ name: "leftArm", markerList: ["left_shoulder", "left_elbow", "left_wrist"], allConnected: false, side: 1 });

        segments.push({ name: "head", markerList: [  "front_head", "top_head", "back_head" ], allConnected: true});

        segments.push(  { name: "pelvis", markerList:  ["right_hip", "left_hip", "back_hip"], allConnected: true });
        segments.push(  { name: "pelvisAlt", markerList:  ["right_asis", "left_asis", "s2"], allConnected: true });
        segments.push(  { name: "torso", markerList:   ["right_hip", "left_hip", "left_shoulder", "right_shoulder", "right_hip"] });
        segments.push(  { name: "torsoAlt", markerList:   ["right_asis", "left_asis", "left_shoulder", "right_shoulder", "right_asis"] });
        //segments.push(  { name: "torsoAlt", markerList:   ["s2", "left_shoulder", "right_shoulder"], allConnected: true });
        segments.push({ name: "torso2", markerList: [  "left_shoulder", "right_shoulder", "neck" ], allConnected: true});

        if (this.checkConfiguration([this.torsoAlt]).length <= 1)
          segments.push({ name: "backAlt", markerList: [ "neck", "spine_top", "spine_down", "back_hip"], allConnected: false });
        else
          segments.push({ name: "back", markerList: [ "neck", "back_hip", "s2" ], allConnected: true });

        segments.push(  { name: "rightUpLeg", markerList:  ["back_hip","right_hip", "right_knee"], allConnected: true, side: 2 });
        segments.push(  { name: "rightUpLegAlt", markerList:  ["s2", "right_asis", "right_lateral_thigh", "right_knee"], allConnected: true, side: 2 });
        segments.push(  { name: "rightLeg", markerList:  ["right_knee", "right_ankle", "right_calf"], allConnected: true, side: 2 });
        segments.push(  { name: "leftUpLeg", markerList:  ["back_hip", "left_hip", "left_knee"], allConnected: true, side: 1 });
        segments.push(  { name: "leftUpLegAlt", markerList:  ["s2", "left_asis", "left_lateral_thigh", "left_knee"], allConnected: true, side: 1 });
        segments.push(  { name: "leftLeg", markerList:  ["left_knee", "left_ankle", "left_calf"], allConnected: true, side: 1 });
        segments.push(  { name: "rightFoot", markerList:  ["right_ankle", "right_foot", "right_heel", "right_toe"], allConnected: true, side: 2 });
        segments.push(  { name: "leftFoot", markerList:  ["left_ankle", "left_foot", "left_heel", "left_toe"], allConnected: true, side: 1 });

        return segments;
      }

      checkConfiguration(config: any[]) {

        const missingMarkers = [];

        for (const l of config) {
          const miss = this.missingMarkers(l);
          for (const m of miss) {
              missingMarkers.push(m);
              if (missingMarkers.length > 1)
                return missingMarkers;
          }
        }
        return missingMarkers;
      }

      findConfiguration() {

        let fullBody = [this.head,
            this.upperLimbLeft,
            this.upperLimbRight,
            this.torso,
            this.pelvis,
            this.lowerLimbLeft,
            this.lowerLimbRight
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return SttSystemsConfiguration.SttSystemsFullBody;
        }

        fullBody = [
          this.upperLimbLeft,
          this.upperLimbRight,
          this.torso,
          this.pelvisAlt,
          this.lowerLimbLeftAlt,
          this.lowerLimbRightAlt
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
            return SttSystemsConfiguration.SttSystemsFullBodyGait;
        }

        fullBody = [
          this.upperLimbLeft,
          this.upperLimbRight,
          this.pelvis,
          this.torsoAlt,
          this.lowerLimbLeft,
          this.lowerLimbRight
        ];

        if (this.checkConfiguration(fullBody).length <= 1) {
          return SttSystemsConfiguration.SttSystemsFullBodyBikeFitting;
        }

        return SttSystemsConfiguration.None;
      }

}
