import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, IterableDiffers, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ClipOptionsService } from 'app/core/clip-options.service';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { PoseComparisonService } from 'app/core/pose-comparison.service';
import { ThreeJsRendererService } from 'app/core/three-js-renderer.service';
import { ThreeJsScene } from 'app/core/three-js-scene';
import { TimecodeService } from 'app/core/timecode.service';
import { WebvrService } from 'app/core/webvr.service';
import { WindowService } from 'app/core/window.service';
import { ClipLoaderService } from 'app/moveshelf-3dplayer/clip-loader/clip-loader.service';
import { MocapActionService } from 'app/moveshelf-3dplayer/mocap-action.service';
import { AMocapClipComponent } from 'app/moveshelf-3dplayer/mocap-clip.abstract';
import { ColorService } from 'app/shared/services/color-service/color-service.service';
import { KeyboardHandlerService } from 'app/shared/services/keyboard-handler.service';
// import './three-global';
import 'three';
// import { OrbitControls } from 'three-orbitcontrols-ts';
import "../../../../../src/js/Reflector";
import "../../../../../src/js/Refractor";
import "../../../../../src/js/Water2";

import '../../../../app/moveshelf-3dplayer/BVHLoader';
import '../../../../app/moveshelf-3dplayer/DRACOLoader';
import '../../../../app/moveshelf-3dplayer/FBXAnimationLoader';
import '../../../../app/moveshelf-3dplayer/FBXLoader';
import '../../../../app/moveshelf-3dplayer/GLTFLoader';
import '../../../../app/moveshelf-3dplayer/OrbitControls';
import '../../../../app/moveshelf-3dplayer/TGALoader';
import * as THREE from '../../../../app/moveshelf-3dplayer/three-global';
import '../../../../app/moveshelf-3dplayer/TRCLoader';
import '../../../../app/moveshelf-3dplayer/zlib-global';
import '../../../../app/moveshelf-3dplayer/zlib.min';

const THREE = require('three');

const cameraFov = 55;
const cameraInit = {
  origin: { x: 3, y: 1.7, z: 3 },
  target: { x: 0, y: 0.8, z: 0 }
};

@Component({
  selector: 'app-splitscreen-mocap-clip',
  templateUrl: './splitscreen-mocap-clip.component.html',
  styleUrls: ['./splitscreen-mocap-clip.component.scss'],
  animations: [
    trigger('controlState', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible <=> hidden', animate('200ms ease-out')),
    ])
  ]
})
export class SplitscreenMocapClipComponent extends AMocapClipComponent implements OnInit, OnDestroy, ThreeJsScene {

  constructor(
    protected readonly renderer: ThreeJsRendererService,
    protected readonly domRenderer: Renderer2,
    protected playbackControl: PlaybackControlService,
    protected readonly http: HttpClient,
    protected readonly window: WindowService,
    protected readonly comp: PoseComparisonService,
    protected readonly _iterableDiffers: IterableDiffers,
    protected readonly optionService: ClipOptionsService,
    protected readonly loader: ClipLoaderService,
    protected readonly playbackGlobal: GlobalPlaybackControlService,
    protected readonly timecodeService: TimecodeService,
    protected readonly mocapActionService: MocapActionService,
    protected readonly colorService: ColorService,
    protected readonly  webvr: WebvrService,
    protected readonly keyboardHandlerService: KeyboardHandlerService,
  ) {
    super(
      renderer,
      domRenderer,
      playbackControl,
      http,
      window,
      comp,
      _iterableDiffers,
      optionService,
      loader,
      playbackGlobal,
      timecodeService,
      colorService,
      webvr,
      keyboardHandlerService,
    );
    this.mocapActionService = mocapActionService;
  }
}
