import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
})
export class SpinnerComponent {
  @Input()
  size: 'small' | 'average' | 'big' | 'bigger' = 'small';

  public getClassSize(): string {
    switch (this.size) {
      case 'small':
        return '';
      case 'average':
        return 'fa-lg';
      case 'big':
        return 'fa-3x';
      case 'bigger':
        return 'fa-5x';
    }
  }

}
