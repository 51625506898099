import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HomeMonitoringComponent } from 'app/shared/homemonitoring/homemonitoring.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxEchartsModule } from 'ngx-echarts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShareModule } from 'ngx-sharebuttons';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BarChartComponent } from './bar-chart.component';
import { ChartsComponent } from './chart/charts.component';
import { EChartsHighlightService } from './chart/echarts-highlight.service';
import { PlayheadComponent } from './chart/playhead/playhead.component';
import { ReferenceCanvasComponent } from './chart/reference-canvas/reference-canvas.component';
import { ClassifierComponent } from './classifier.component';
import { ClipControlsModule } from './clip-control/clip-controls.module';
import { ClipErrorWarningComponent } from './clip-error-warning/clip-error-warning.component';
import { ClipInfoComponent } from './clip-info.component';
import { ClipNotAvailableComponent } from './clip-not-available.component';
import { CommentComponent } from './comment.component';
import { CommentsFeedComponent } from './comments-feed.component';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { CssLoadingSpinnerComponent } from './css-loading-spinner.component';
import { DndFileDirective } from './dnd-file.directive';
import { GaitParameterTableComponent } from './gait-parameter-table/gait-parameter-table.component';
import { ForceOverlayIconComponent } from './icons/force-overlay-icon/force-overlay-icon.component';
import { ImageCropperComponent } from './image-cropper.component';
import { LayoutlistComponent } from './layoutlist/layoutlist.component';
import { LeftRightMaximizeComponent } from './left-right/left-right-maximize.component';
import { LeftRightComponent } from './left-right/left-right.component';
import { SaveLayoutModalComponent } from './left-right/save-layout-modal/save-layout-modal.component';
import { SplitscreenClipLoaderComponent } from './left-right/splitscreen-clip-loader/splitscreen-clip-loader.component';
import { SplitscreenMocapClipComponent } from './left-right/splitscreen-mocap-clip/splitscreen-mocap-clip.component';
import { ChartTileComponent } from './left-right/tile/chart-tile/chart-tile.component';
import { SplitscreenMultiChartComponent } from './left-right/tile/chart-tile/splitscreen-multi-chart/splitscreen-multi-chart.component';
import { SyncDropdownComponent } from './left-right/tile/sync-dropdown/sync-dropdown.component';
import { TrialDropdownComponent } from './left-right/tile/trial-dropdown/trial-dropdown.component';
import { VideoTileComponent } from './left-right/tile/video-tile/video-tile.component';
import { LicenseNamePipe } from './license-name.pipe';
import { LicenseTermsPipe } from './license-terms.pipe';
import { LikeClipButtonComponent } from './like-clip-button.component';
import { LineChartIconComponent } from './line-chart-icon.component';
import { LineChartComponent } from './line-chart.component';
import { MediaTracksManagerComponent } from './media-tracks-manager.component';
import { MoreActionsComponent } from './more-actions.component';
import { MultiChartComponent } from './multi-chart/multi-chart.component';
import { GoniometerDropdownComponent } from './multi-media-player/media-player/goniometer-dropdown/goniometer-dropdown.component';
import { GoniometerOverlayComponent } from './multi-media-player/media-player/goniometer-overlay/goniometer-overlay.component';
import { MediaPlayerComponent } from './multi-media-player/media-player/media-player.component';
import { SagaRulerOverlayComponent } from './multi-media-player/media-player/saga-ruler-overlay/saga-ruler-overlay.component';
import { MediaPlayerService } from './multi-media-player/media-player/services/media-player.service';
import { MultiMediaPlayerComponent } from './multi-media-player/multi-media-player.component';
import { VideoForceOverlayComponent } from './multi-media-player/video-force-overlay/video-force-overlay';
import { NotificationsComponent } from './notifications.component';
import { OutdatedWarningComponent } from './outdated-warning/outdated-warning.component';
import { RoundPipe } from './pipes/round.pipe';
import { RadarChartComponent } from './radar-chart.component';
import { RegexEscapePipe } from './regex-escape.pipe';
import { SafePipe } from './safe.pipe';
import { SharingClipComponent } from './sharing-clip.component';
import { SpinnerComponent } from './spinners/spinner.component';
import { StripFirstPathPipe } from './strip-first-path.pipe';
import { SupportRequestDialogComponent } from './support-request-dialog.component';
import { SvgIconComponent } from './svg-icon.component';
import { TimecodeFormatterPipe } from './timecode-formatter.pipe';
import { TruncatePipe } from './truncate.pipe';
import { AveragesSwitchComponent } from './ui/averages-switch/averages-switch.component';
import { ButtonComponent } from './ui/button.component';
import { CheckboxIconComponent } from './ui/checkbox-icon/checkbox-icon.component';
import { LeftRightSwitchComponent } from './ui/left-right-switch/left-right-switch.component';
import { UploadDirectoryComponent } from './upload-directory/upload-directory.component';
import { VideoIconComponent } from './video-icon.component';
import { ViewCounterComponent } from './view-counter.component';
import { ReportLoadDataService } from 'app/projects/report/report-load-data.service';

@NgModule({ declarations: [
        ClipInfoComponent,
        SharingClipComponent,
        DndFileDirective,
        ImageCropperComponent,
        CommentsFeedComponent,
        MoreActionsComponent,
        CommentComponent,
        RegexEscapePipe,
        SafePipe,
        StripFirstPathPipe,
        TruncatePipe,
        ClipNotAvailableComponent,
        ViewCounterComponent,
        LikeClipButtonComponent,
        LicenseNamePipe,
        LicenseTermsPipe,
        TimecodeFormatterPipe,
        SupportRequestDialogComponent,
        ChartsComponent,
        NotificationsComponent,
        BarChartComponent,
        LineChartComponent,
        MediaTracksManagerComponent,
        RadarChartComponent,
        MultiChartComponent,
        MediaPlayerComponent,
        ConfirmationDialogComponent,
        ClassifierComponent,
        UploadDirectoryComponent,
        MultiMediaPlayerComponent,
        LineChartIconComponent,
        ForceOverlayIconComponent,
        VideoIconComponent,
        LeftRightComponent,
        HomeMonitoringComponent,
        ButtonComponent,
        LeftRightMaximizeComponent,
        ChartTileComponent,
        VideoTileComponent,
        TrialDropdownComponent,
        SyncDropdownComponent,
        OutdatedWarningComponent,
        ClipErrorWarningComponent,
        GoniometerOverlayComponent,
        SagaRulerOverlayComponent,
        LeftRightSwitchComponent,
        AveragesSwitchComponent,
        CheckboxIconComponent,
        SplitscreenMultiChartComponent,
        GoniometerDropdownComponent,
        RoundPipe,
        SaveLayoutModalComponent,
        LayoutlistComponent,
        SplitscreenMocapClipComponent,
        SplitscreenClipLoaderComponent,
    ],
    exports: [
        ClipInfoComponent,
        DndFileDirective,
        ImageCropperComponent,
        CommentsFeedComponent,
        SharingClipComponent,
        RegexEscapePipe,
        SafePipe,
        StripFirstPathPipe,
        TruncatePipe,
        ClipNotAvailableComponent,
        ViewCounterComponent,
        LikeClipButtonComponent,
        SvgIconComponent,
        LicenseNamePipe,
        LicenseTermsPipe,
        CssLoadingSpinnerComponent,
        TimecodeFormatterPipe,
        SupportRequestDialogComponent,
        ChartsComponent,
        LineChartComponent,
        NotificationsComponent,
        BarChartComponent,
        RadarChartComponent,
        MediaTracksManagerComponent,
        MultiChartComponent,
        MediaPlayerComponent,
        ConfirmationDialogComponent,
        ClassifierComponent,
        UploadDirectoryComponent,
        MultiMediaPlayerComponent,
        LineChartIconComponent,
        ForceOverlayIconComponent,
        VideoIconComponent,
        LeftRightComponent,
        ButtonComponent,
        GaitParameterTableComponent,
        OutdatedWarningComponent,
        SpinnerComponent,
        ClipErrorWarningComponent,
        PlayheadComponent,
        ReferenceCanvasComponent,
        ClipControlsModule,
        AveragesSwitchComponent,
        LeftRightMaximizeComponent,
        LeftRightSwitchComponent,
        GoniometerDropdownComponent,
        RoundPipe,
        LayoutlistComponent
    ], imports: [CommonModule,
        RouterModule,
        ShareModule,
        ModalModule,
        ProgressbarModule,
        ButtonsModule,
        TabsModule,
        PopoverModule,
        TooltipModule,
        FormsModule,
        ReactiveFormsModule,
        UiSwitchModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        Ng2GoogleChartsModule,
        ImageCropperModule,
        AlertModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        NgxDatatableModule,
        FontAwesomeModule,
        ClipControlsModule,
        PlayheadComponent,
        ReferenceCanvasComponent,
        CssLoadingSpinnerComponent,
        SvgIconComponent,
        GaitParameterTableComponent,
        VideoForceOverlayComponent,
        SpinnerComponent,
        ClipboardModule], providers: [
        EChartsHighlightService,
        MediaPlayerService,
        ReportLoadDataService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
