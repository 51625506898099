<span class="btn btn-outline-white btn-sm p-0 m-0">
  <span class="text-muted">
    <i class="fa fa-eye"></i>
    {{views}}</span>
  </span>
  <span *ngIf="allowDownload" class="btn btn-outline-white btn-sm p-0 m-0">
    &nbsp;
    <span class="text-muted">
      <i class="fa fa-download"></i>
      {{downloads}}</span>
    </span>