import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ColumnMode, NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GaitParameter, GaitParameterTable } from '../chart/chart.types';
import { CssLoadingSpinnerComponent } from '../css-loading-spinner.component';
import { TrialChartsService } from '../multi-chart/trial-charts.service';

export interface ParamWithOrigin {
  value: string;
  origin: string;
}

export interface ParamRecord {
  value: ParamWithOrigin[];
  left: ParamWithOrigin[];
  right: ParamWithOrigin[];
}

export interface ParamTableRow {
  value: ParamWithOrigin;
  left: ParamWithOrigin;
  right: ParamWithOrigin;
  parameter: string;
}

@Component({
  standalone: true,
  imports: [TooltipModule, NgxDatatableModule, CssLoadingSpinnerComponent, CommonModule],
  selector: 'app-gait-parameter-table',
  templateUrl: './gait-parameter-table.component.html',
  styleUrls: ['./gait-parameter-table.component.scss'],
})
export class GaitParameterTableComponent {

  @Input() public groupByCondition: boolean = false;

  @Input() set input(value: GaitParameterTable) {
    this.loading = true;
    [this.rows, this.hasLeft, this.hasRight, this.hasNoContext] = this.trialChartsService.getGaitParamsTableRows(value, this.groupByCondition);
    this.loading = false;
  }

  hasLeft = false;
  hasRight = false;
  hasNoContext = false;
  rows = [];
  public loading = false;

  ColumnMode = ColumnMode;

  constructor(
    private trialChartsService: TrialChartsService,
  ) {}

  public getRowClass(row): any {
    return row.underline ? 'underline' : '';
  }

  public getCellClass({ row, column, value }): any {
    return {
      'text-muted': value.origin.indexOf('>') === -1 && value.origin.includes('reference')
    };
  }
}
