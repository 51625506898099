const THREE = require('three');
THREE.TRCLoader = function ( manager ) {

	this.manager = ( manager !== undefined ) ? manager : THREE.DefaultLoadingManager;

	this.animateBonePositions = true;
	this.animateBoneRotations = true;

};

THREE.TRCLoader.prototype = {

	constructor: THREE.TRCLoader,

	load: function ( url, onLoad, onProgress, onError ) {

		var scope = this;

		var loader = new THREE.FileLoader( scope.manager );
		loader.load( url, function ( text ) {

			onLoad( scope.parse( text ) );

		}, onProgress, onError );

	},

	parse: function ( text ) {

		/*
			reads a string array (lines) from a BVH file
			and outputs a skeleton structure including motion data

			returns thee root node:
			{ name: '', channels: [], children: [] }
		*/
		function readTrc( lines ) {
            if (!lines) {
              return undefined;
            }

            // read model structure
            nextLine( lines );
            var headers = nextLine( lines ).split( /[\t]+/ );
            var values = nextLine( lines ).split( /[\t]+/ );
            var temp = nextLine( lines ).split( /[\t]+/ );
            var markerNames = temp.splice(2);
            var axisNames = nextLine( lines ).split( /[\t]+/ );
            var timeOffset = undefined;

            var numFrames = values[2];

            var markersData = [];
            var bones = [];
            var tracks = [];

            var unitConversion = 1;

            if(values.length >= 4)
            {
                switch(values[4])
                {
                    case 'dm':
                        unitConversion = 0.1;
                        break;
                    case 'cm':
                        unitConversion = 0.01;
                        break;
                    case 'mm':
                        unitConversion = 0.001;
                        break;

                }
            }

            var nMarker = markerNames.length;
            extendMarkerSet(markersData, bones, nMarker, markerNames);

            for(var i=0; i< numFrames-1; i++) {
                var temp = nextLine( lines ).split('\t');
                var frameSample = temp.splice(2);
                var frameNumber = parseInt(temp[0]);
                var frameTime = parseFloat(temp[1]);
                if(timeOffset == undefined)
                    timeOffset = frameTime;

                //Make all TRC start at 0 for playback easiness
                frameTime -= timeOffset;

                let nMarkerRow = Math.floor(frameSample.length/3);
                if(nMarkerRow > nMarker) {
                    nMarker = nMarkerRow;
                    extendMarkerSet(markersData, bones, nMarker, markerNames);
                }

                //hack made to avoid all zero frames and null frame
                var dataAvailable = false;
                for(var j=0; j< frameSample.length; j++)
                {
                    var temp = parseFloat(frameSample[j*3 + 0])
                    if(!isNaN(temp) && temp != 0) {
                        dataAvailable = true;
                        break;
                    }
                }

                if(!dataAvailable)
                    continue;

                //end hack

                for(var j=0; j< nMarker; j++)
                {
                    var x = parseFloat(frameSample[j*3 + 0])*unitConversion;
                    var y = parseFloat(frameSample[j*3 + 1])*unitConversion;
                    var z = parseFloat(frameSample[j*3 + 2])*unitConversion;

                    //limit frame rate
                    let l = markersData[j].times.length;
                    let minInterval = 0.0167;
                    if( l > 0 && frameTime < (markersData[j].times[l-1] + minInterval ))
                        continue;

                    if(!isNaN(x) && !isNaN(y) && !isNaN(z))
                    {
                        markersData[j].times.push(frameTime);
                        markersData[j].positions.push(x);
                        markersData[j].positions.push(y);
                        markersData[j].positions.push(z);
                        markersData[j].lastSeen = frameTime;
                        markersData[j].visibleTimes.push(frameTime);
                        markersData[j].visibleValues.push(true);
                    }
                    else
                    {
                        var delta = frameTime - markersData[j].lastSeen;
                        if(delta > 0.05)
                        {
                            markersData[j].visibleTimes.push(frameTime);
                            markersData[j].visibleValues.push(false);
                            //markersData[j].model.material.color = new THREE.Color(0xff0000);

                            /*markersData[j].times.push(frameTime);
                            markersData[j].positions.push(0);
                            markersData[j].positions.push(0);
                            markersData[j].positions.push(0);
                            markersData[j].interp = THREE.InterpolateDiscrete;*/
                        }
                    }
                }
            }

            for(var j=0; j<nMarker; j++)//axisNames.length; j++)
            {
                if(markersData[j].model.userData.type == 'data')
                    continue;

                if(markersData[j].positions.length > 0)
                {
                    tracks.push( new THREE.VectorKeyframeTrack( '.bones['+ markersData[j].name +'].position', markersData[j].times, markersData[j].positions, markersData[j].interp ));
                    if(markersData[j].model.userData.type !== 'vMarker' && markersData[j].model.userData.type !== 'jcMarker')
                        tracks.push( new THREE.BooleanKeyframeTrack( '.bones['+ markersData[j].name +'].visible', markersData[j].visibleTimes, markersData[j].visibleValues));
                }
                else
                    bones[j].userData.type = 'mMarker'; //missing marker
            }

            var skeleton = new THREE.Skeleton( bones );
            var clip = new THREE.AnimationClip( 'animation', - 1, tracks );

		    return {
                markerSet: [], //new THREE.Skeleton( threeBones ),
                skeleton: skeleton,
                data: markersData,
			    clips: [clip]
		    };

		}

        function getMarkerName(markerNames, i) {
            if (markerNames.length > 0) {
                return markerNames[i] ? markerNames[i] : ('Unlabeled' + i);
            } else {
                return 'Marker' + i;
            }
        }

        function extendMarkerSet(markersData, bones, markerNumber, markerNames) {
            // First we find our plug-in-gait bones by (each segment has 4 virtual markers (segName + O/A/L/P) that we look for:
            // - find all potential segments based on finding 'O'
            // - add all markers with segName + O/A/L/P
            // - all segs with 4 markers are marked as bones and made virtual

            // find all potential segments based on finding 'O'
            const potentialSegments = [];
            for (var i = markersData.length; i< markerNumber; i++) {
                name = getMarkerName(markerNames, i);
                if (name.length > 0 && name.endsWith('O')) {
                    const segName = name.slice(0, -1);
                    if (!potentialSegments.includes(segName)) {
                        potentialSegments.push({name: segName, markers: []}); // markers will be filled in next loop
                    }
                }
            }

            // add all markers with segName + O/A/L/P
            for (var i = markersData.length; i< markerNumber; i++) {
                name = getMarkerName(markerNames, i);
                if (name.length > 0) {
                    const segName = name.slice(0, -1);
                    const segment = potentialSegments.find(x => x.name == segName);
                    if (segment && (name.endsWith('O') || name.endsWith('A') || name.endsWith('L') || name.endsWith('P'))) {
                        segment.markers.push(name);
                    }               
                }
            }

            // all segs with 4 markers are marked as bones and made virtual
            const pigBones = [];
            for (var i = 0; i < potentialSegments.length; i++) {
                if (potentialSegments[i].markers.length == 4) {
                    pigBones.push(potentialSegments[i]);
                }
            }

            for(var i = markersData.length; i< markerNumber; i++) {

                var name;
                let model;
                name = getMarkerName(markerNames, i);

                let dataType = getDataType(name);
                if( dataType ) {
                    model = { userData: { type: 'data:'+dataType } };
                }
                else {
                    var virtual = isMarkerVirtual(name, pigBones);
                    var jointCenter = isJointCenter(name);
                    var com = isCoM(name);
                    var custom = isCustom(name);

                    var material = markerMaterial;
                    var geometry = markerGeometry;

                    if (virtual || jointCenter || custom || com)
                        material = customMarkerMaterial;

                    if(jointCenter)
                        geometry = jointCenterGeometry;

                    model = new THREE.Mesh(geometry, material);
                    model.castShadow = true;

                    bones.push(model);
                    model.userData.type = virtual ? 'vMarker' : jointCenter ? 'jcMarker' : 'Marker';
                    model.name = name;
                }
                markersData.push({ name: name, model: model, times: [], positions: [], visibleTimes: [], visibleValues: [], lastSeen: 0, interp: THREE.InterpolateLinear });
            }
        }

        function getDataType(name) {
            let markerId = parseInt(name);
            if (!isNaN(markerId)) {
                if (markerId >= 7720 && markerId <= 7730) //Joint Agnles (Simi Motion)
                    return "Kinematics";

                if (markerId >= 7400 && markerId <= 7530) //Segment Axis (Simi Motion)
                    return "Data";
            }

            if (name.toLowerCase().indexOf('displacement') != -1 ||
            name.toLowerCase().indexOf('angle') != -1 ||
            name.toLowerCase().indexOf('grf') != -1 ||
            name.toLowerCase().indexOf('velocity') != -1)
                return "Kinematics";

            return undefined;
        }
        function isJointCenter(name) {
            let markerId = parseInt(name);
            if (!isNaN(markerId)) {
                if (markerId >= 7670 && markerId <= 7680) //Joint Centers (Simi Motion)
                    return true;
            }
            let virtualNames = ["LHJC", "RHJC",
            "LKJC", "RKJC", "LAJC", "RAJC", "LSJC", "RSJC", "LEJC", "REJC", "LWJC", "RWJC", "LHO", "RHO"];
            for(let v of virtualNames) {
                if(name.indexOf(v)!= -1)
                    return true
            }
            return false;
        }

        function isCustom(name) {
            let customNames = ["Front of Head",
            "Shaft",
            "Stick Top",
            "Stick Middle",
            "Stick Bottom Heel",
            "Stick Bottom Toe"];

            for(let c of customNames) {
                if(name.indexOf(c)!= -1)
                return true;
            }
            return false;
        }

        function isCoM(name) {
            let CoMnames = ['CenterOfMass',
            'CenterOfMassFloor',
            'CentreOfMass',
            'CentreOfMassFloor',
            'center of mass'];

            for(let c of CoMnames) {
                if(name.indexOf(c)!= -1)
                return true;
            }
            return false;
        }

        function isMarkerVirtual(name, bonesList)
        {
            for (const v of bonesList) {
                if (v.markers.includes(name)) {
                    return true;
                }
            }
            return false;
        }
		/*
			returns the next non-empty line in lines
		*/
		function nextLine( lines ) {

			var line;
			// skip empty lines
			while ( ( line = lines.shift().trim() ).length === 0 ) { }
			return line;

		}

        var scope = this;
        var standardMarkersColor = 0xffffff; //(Math.random()*.3 + 0.7) * 0xffffff;
        var customMarkerColor = 0xff7300;

        var markerGeometry = new THREE.SphereBufferGeometry(0.013, 32, 32);
        var jointCenterGeometry = new THREE.SphereBufferGeometry(0.004, 32, 32);

        var markerMaterial = new THREE.MeshPhongMaterial({color: standardMarkersColor });
        var customMarkerMaterial = new THREE.MeshPhongMaterial({color: customMarkerColor });

        var lines = text.match(/[^\r\n]+/g);

        var result = readTrc( lines );

        return result;
	}

};
