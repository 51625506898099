import {
  Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { CommentUpdateService } from '../core/comment-update.service';
import { SelectionService } from '../core/selection.service';
import { MoreActionsComponent, MoreActionsEvent } from './more-actions.component';


@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentComponent implements OnInit, OnDestroy {

  _comment: any;
  @Input() set comment(comment: any) {
    this._comment = comment;

    this.commentText = this._comment.comment;
    this.commentHtml = '<a>' + this.commentText + '</a>';

    this.commentHtml = this.commentHtml.replace(/\@([a-z\-_0-9]+)/gi, function(match, mention) {
      if (mention)
          return "<span class='highlight-success mention m-0'>" + mention +"</span>";
      // shouldnt get here, but just in case
      return match;
});
  }
  get comment() {
    return this._comment;
  }

  @Input() framerate: string; // Enumerator, i.e. 'FPS_24', 'FPS_25'
  @Input() baseTimecode: string;

  @ViewChild('moreActions', { static: true })
  moreActionsSelectorRef: MoreActionsComponent;

  commentText: string;
  commentHtml: string;
  allowCommentEdit: boolean = false;

  private subs: Subscription[] = [];

  constructor(
    private commentUpdateService: CommentUpdateService,
    private authService: AuthService,
    private router: Router,
    private selectionService: SelectionService
  ) { }

  ngOnInit() {
    this.subs.push(this.authService.state.subscribe(state => {
      this.allowCommentEdit = this.authService.isAuthenticated(this.comment.author.username);
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  deleteComment() {
    this.commentUpdateService.deleteComment(this.comment.id).subscribe();
  }

  moreActionsClick(event: MoreActionsEvent) {
    switch (event) {
      case MoreActionsEvent.Edit:
        this.moreActionsSelectorRef.showConfirm();
        break;
      case MoreActionsEvent.Delete:
        this.deleteComment();
        break;
      case MoreActionsEvent.Approve:
        this.editComment(this.commentText);
        break;
      case MoreActionsEvent.Deny:
        this.moreActionsSelectorRef.hideConfirm();
        break;
    }
  }

  editComment(newText: string) {
    this.commentUpdateService.updateComment({commentId: this.comment.id, comment: newText}).subscribe();
  }

  onEnter() {
    this.editComment(this.commentText);
  }

  setTimeSelection() {
    this.selectionService.selection.next(this.comment.timeSelection);
  }
}
