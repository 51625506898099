import { VideoVisibilityEnum } from "app/moveshelf-3dplayer/clip-player";

export enum OverlayType {
  TrialView = 'TrialView',
  ReportView = 'ReportView',
}

export class MediaData {
  id?: string; //trialId
  dataUrl: string;
  originalId?: string;
  rotateOptions?: RotationOptions;
  timeOffset?: number;
  playbackRate?: number;
  originalFileName?: string;
  trialNameInReport?: string;
  primaryVideo?: boolean = false;
  audio?: boolean;
  screenPosition?: any;
  bodyPose?: {
    dataUrl: string;
    originalFileName: string;
  };
}

export class Clip3DData {
  id?: string;
  dataUrl?: string;
  originalId?: string;
  clipTrcPath?: string;
  clipVideoPaths?: string[];
  clipCameraParamsPath?: string;
}

export interface RotationOptions {
  rotate: boolean;
  rotation?: number;
}

export interface VideoRotationCSSProps { }

export interface OverlayConfiguration {
  videoName: string;
  overlayType: OverlayType;
  overlayWidth?: number;
  overlayHeight?: number;
  originalVideoWidth?: number;
  originalVideoHeight?: number;
  videoElementHeight?: number;
  canvasLeftOffset?: number;
  offsetTop?: number;
  totalVideoDuration?: number;
}
