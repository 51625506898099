import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  public dictionaryGait = {
    types: {
      "sex": [
        "Male",
        "Female",
        "Intersex",
        "Prefer not to say",
      ],
      "gender": [
        "Male",
        "Female",
        "Non-Binary/Third Gender",
        "Self-Describe",
      ],
      "diagnosis": [
        "ACL Injury",
        "Amputee-Congenital",
        "Amputee-Traumatic",
        "Amputee-Acquired",
        "Arthrogryposis",
        "Blount's Disease",
        "Cavovarus Foot Deformity",
        "Pes Cavus",
        "Cerebral Palsy",
        "Cerebral Palsy-Spastic",
        "Cerebral Palsy-Dyskinetic",
        "Cerebral Palsy-Mixed",
        "Cerebrovascular Accident",
        "Charcot Marie Tooth",
        "Clubfoot",
        "Duchenne Muscular Dystrophy",
        "Familial Spastic Paraplegia",
        "Genu Valgum",
        "Genu Varum",
        "Global Developmental Delay",
        "Hip Dysplasia",
        "In-toeing",
        "Out-toeing",
        "Juvenile Idiopathic Arthritis",
        "Knee Injury (Other)",
        "Limb Length Discrepancy",
        "MCL Injury",
        "Meniscus Injury",
        "Miserable Malalignment",
        "Multiple Hereditary Exostoses",
        "Muscular Dystrophy",
        "Myelodysplasia",
        "Myelomeningocele",
        "Osteogenesis Imperfecta",
        "Pes planus",
        "Polio",
        "Proximal femoral focal deficiency",
        "Rickets",
        "Sacral Agenesis",
        "Scoliosis",
        "Spastic Paraparesis",
        "Spinal Cord Injury",
        "Spinal Muscular Atrophy",
        "Spondyloepiphyseal dysplasia",
        "Spondylolisthesis",
        "Toe Walker",
        "Transverse Myelitis",
        "Traumatic Brain Injury",
        "Traumatic Injury-Lower Extremity",
        "Genetic Disorder",
        "Rickets",
        "Femoral Anteversion",
        "Femoral Retroversion",
        "External Tibial Torsion",
        "Internal Tibial Torsion",
        "Avascular Necrosis",
        "Leff-Perthes",
        "Blount's Disease",
        "Spina Bifida",
        "Dystonia",
        "Unknown",
        "Other",
      ],
      "modifier": [
        "Monoplegia",
        "Hemiplegia",
        "Diplegia",
        "Triplegia",
        "Quadriplegia",
        "Ataxic",
        "Dystonic",
        "Athetoid",
        "Type I",
        "Type II",
        "Type III",
        "Type IV",
        "Above Knee",
        "Below Knee",
        "Knee Disarticulation",
        "Idiopathic",
        "Congenital",
        "Other",
      ],
      "laterality": [
        "Bilateral",
        "Left",
        "Right",
        "Whole Body",
      ],
      "clinical-presentation": [
        "Stable",
        "Evolving",
        "Unstable"
      ],
      "site": [
        "Chicago",
        "Greenville",
        "Lexington",
        "Montreal",
        "Northern California",
        "Philadelphia",
        "Portland",
        "Salt Lake City",
        "Shreveport",
        "Spokane",
        "Springfield",
        "Texas",
        "Mexico",
      ],
      "complexity": [
        "Low",
        "Moderate",
        "High",
      ],
      "yes-no": [
        "Yes",
        "No",
      ],
      "location": [
        "Above Knee",
        "Asymmetric",
        "Below Knee",
        "Bilateral",
        "Congenital",
        "Diplegic",
        "Hemiplegic",
        "Idiopathic",
        "Hemiplegic",
        "Diplegic",
        "Monoplegic",
        "Paraplegic",
        "Quadraplegic",
        "Hemiplegic",
        "Spastic",
        "Tetraplegic",
        "Triplegic",
      ],
      "classification": [
        "MMFC1",
        "MMFC2",
        "MMFC3",
        "MMFC4",
        "Type I",
        "Type III",
        "Type IV",
      ],
      "icd10": [
        "A00.0 Cholera due to Vibrio cholerae 01, biovar cholerae",
        "Typhoid meningitis",
        "A01.09 Typhoid fever with other complications",
        "A01.3 Paratyphoid fever C",
        "A01.4 Paratyphoid fever, unspecified",
        "D18.0 Hemangioma",
        "G11.4 Hereditary spastic paraplegia",
        "G54.0 Brachial plexus disorders",
        "Z00.11 Newborn health examination",
        "G80.0 Spastic quadriplegic cerebral palsy",
        "G80.1 Spastic diplegic cerebral palsy",
        "G80.2 Spastic hemiplegic cerebral palsy",
        "G80.8 Other cerebral palsy",
        "G80.9 Cerebral palsy, unspecified",
        "G81.11 Spastic hemiplegia affecting right dominant side",
        "G81.13 Spastic hemiplegia affecting right nondominant side",
        "G81.94 Hemiplegia, unspecified affecting left nondominant side",
        "G82.50 Quadriplegia, unspecified",
        "GQ66.01 Congenital talipes equinovarus, right foot",
        "I69.353 Hemiplegia and hemiparesis following cerebral infarction",
        "M20.5X1 Other deformities of toe(s) (acquired), right foot",
        "M21.7 Unequal limb length (acquired)",
        "M21.852 Other specified acquired deformities of left thigh",
        "M24.522 Contracture, left elbow",
        "M62.838 Other muscle spasm",
        "M62.89 Other specified disorders of muscle",
        "P14.0 Erb's paralysis due to birth injury",
        "Q05.2 Lumbar spina bifida with hydrocephalus",
        "Q65.89 Other specified congenital deformities of hip",
        "Q72.41 Longitudinal reduction defect of right femur",
        "Q72.61 Longitudinal reduction defect of right fibula",
        "R26.89 Other abnormalities of gait and mobility",
        "S72.002 Fracture of unspecified part of neck of left femur",
        "Z86.69 Personal history of other diseases of the nervous system and sense organs",
      ],
      "pain": [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
      ],
      "gmfcs": [
        "I", "II", "III", "IV", "V", "NA"
      ],
      "fms-c": [
        "1", "2", "3", "4", "5", "6", "C"
      ],
      "fms-n": [
        "1", "2", "3", "4", "5", "6", "N"
      ],
      "orthotics": [
        "SMO", "SAFO", "PLSO", "FRFO", "UCBL", "KAFO", "DAFO", "AAFO", "Hinged", "Other"
      ],
      "assistive-devices": ["None", "Anterior Walker", "Axillary Crutch", "Forearm Crutch", "Cane", "Hand Hold Assist", "Standard Walker", "Posterior Walker", "Quad Cane", "Wheelchair"],
      "site-code": [ "CHI", "ERI", "GRV", "LEX", "MEX", "NCL", "PHL", "POR", "SCC", "SHR", "SPO", "SPR", "TEX", "TMP", "Other" ],
      "data-collected": [
        "Trunk kinematics",
        "Lower extremity kinematics",
        "Lower extremity kinetics",
        "Lower extremity surface EMG",
        "Lower extremity fine wire EMG",
        "Foot model",
        "Foot pressure",
        "Oxygen consumption",
        "Upper extremity",
        "Video"
      ],
      "cancellation": ["Cancelled", "no show"],
      "referral-type": [
        "Gait Analysis",
        "Sports Analysis",
        "Video",
        "Video & Foot Pressures",
        "Running",
        "Upper Extremity Analysis",
        "Spine Analysis",
        "SHUEE",
        "Oxygen Consumption (Energy Expenditure)",
        "Dynamometry",
        "Other"
      ],
      "session-type": ["Clinical", "Control", "Research"],
      "conditions-collected": ["Barefoot", "Brace", "Walker", "Crutch", "Other"],
      "goal-score": ["-2", "-1", "0", "1", "2"],
      "falls-frequency": ["day", "week", "month", "year"],
      "evaluation-complexity": ["Low (97161)", "Moderate (97162)", "High (97163)"],
      "side": ["Left", "Right", "Both"],
      "procedure-location": [
        "Abductor Hallicus",
        "Abductors",
        "Acetabulum",
        "Achillies Tendon",
        "Ankle",
        "Anterior Cruciate Ligament (ACL)",
        "Anterior Tibialis",
        "Biceps",
        "Calcaneal",
        "Calcaneocubiod",
        "Calcaneus",
        "Cervical",
        "Column",
        "Dorsal",
        "Elbow",
        "Exostosis",
        "Extensor Digitorum",
        "Extensor Pollicis Longus",
        "Extremity",
        "Fascia",
        "Femur",
        "Fibula",
        "Fibrous Connective Tissue",
        "Flexor Carpi Ulnaris",
        "Flexor Digitorum",
        "Flexor Hallucis Longus",
        "Flexor Digitorum Longus",
        "Flexor Pollicis Longus",
        "Foot",
        "Gastrocmenius",
        "Gracilis",
        "Hallucius Longus",
        "Hamstrings",
        "Hand",
        "Heel",
        "Hip",
        "Iliotibial Band (IT)",
        "Knee",
        "Knee Capsule",
        "Lateral Hamstrings",
        "Lumbar",
        "Malleolus",
        "Medial Collatoral Ligament (MCL)",
        "Medial Column",
        "Medial Hamstrings",
        "Meniscus",
        "Metatarsal",
        "Metatarsophalangeal (MTP)",
        "Midfoot",
        "Obturator Nerve",
        "Patella",
        "Patellar Tendon",
        "Pelvis",
        "Periacetabular",
        "Peroneal Tertius",
        "Peroneals",
        "Peroneus Longus",
        "Plantar Fascia",
        "Posterior Capsule",
        "Posterior Cruciate Ligament (PCL)",
        "Posterior Tibialis",
        "Pronator Muscle",
        "Quadriceps",
        "Ray",
        "Rectus Femoris",
        "Sartorius",
        "Semimembranosus",
        "Semitendonosus",
        "Shoulder",
        "Soleus",
        "Subtalar",
        "Tarsal joints",
        "Talonavicular",
        "Talus",
        "Thoracic",
        "Tibia",
        "Toe",
        "Toe Nail",
        "Tubercle",
        "Vastus Intermedius",
        "Vastus Lateralus",
        "Vertebrae",
        "Vertebral Body",
        "Wrist",
      ],
      "location-modifier": [
        "2nd",
        "3rd",
        "4th",
        "5th",
        "Abductor",
        "Adductor",
        "Closed",
        "Closing",
        "Complete",
        "Derotational",
        "Distal",
        "Dorsum",
        "Double",
        "Extension",
        "Extensor",
        "Flat",
        "Flexion",
        "Flexor",
        "Head & Neck",
        "L1",
        "L2",
        "L3",
        "L4",
        "L5",
        "Lateral",
        "Lower",
        "Medial",
        "Medial & Lateral",
        "Open",
        "Opening",
        "OTHER",
        "Partial",
        "Percutaneus",
        "Posterior",
        "Proximal",
        "Realignment",
        "Rotational",
        "Single",
        "Sliding",
        "Split",
        "Supra",
        "T10",
        "T11",
        "T12",
        "Total",
        "Triple",
        "Upper",
        "Valgus Producing",
        "Varus Producing",
        "Wedge",
      ],
      "plus-minus": ["+", "-"],
      "neuromuscular-values": ["0", "1", "1+", "2", "3", "4"],
      "mmt-values": ["5", "4", "3+", "3", "2+", "2", "2-", "1", "0"],
      "up-down-neutral": ["up", "down", "neutral"],
      "0-1-2": ["0", "1", "2"],
      "scale-to-ten": ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      "scale-confusion": ["Neg", "Neut", "Inv", "Ev"],
      "static-foot-multiselect": ["Adduction", "Abduction", "Neutral", "Supination", "Pronation", "Splay"],
      "planus-cavus": ["Planus", "Cavus", "Neutral"],
      "varus-valgus": ["Varus", "Valgus", "Neutral"],
      "varus-valgus-calcaneous": ["Varus", "Valgus", "Neutral", "Equinus", "Calcaneous"],
      "coleman-block": ["Flexible", "Rigid"],
      "score-to-five": ["I", "II", "III", "IV", "V"],
      "procedure": [
        "Advancement",
        "Amputation",
        "Arthroresis",
        "Arthroscopy",
        "Baclofen Pump",
        "Bumpectomy",
        "Casting",
        "Epiphysiodesis",
        "Ethanol Injection",
        "Excision/Resection",
        "External Fixator Application",
        "Fasciotomy",
        "Fusion/Arthrodesis",
        "Hardware Removal",
        "Hemiepiphysiodesis",
        "Injection",
        "Lengthening",
        "Neurectomy",
        "Osteochondroplasty",
        "Osteotomy",
        "Osteotomy Rotational",
        "Pinning",
        "Posterior Medial Release",
        "Recession",
        "Reconstruction",
        "Reduction",
        "Release/Tenotomy",
        "Resection",
        "Rhizotomy",
        "Rodding",
        "Shortening",
        "Transfer",
        "OTHER - Bony",
      ],
      "procedure-modifier": [
        "None",
        "Boyd",
        "Symes",
        "STA-Peg",
        "Serial",
        "Inhibition",
        "Ponseti",
        "DeMeglio",
        "EDF",
        "Screw",
        "Plate",
        "Staple",
        "8-Plates",
        "Talocalcaneal",
        "Talonavicular",
        "Ilizarov",
        "Taylor Spatial ",
        "Anterior",
        "Screw",
        "Plate",
        "Staple",
        "8-Plates",
        "Botox",
        "Dysport",
        "Ethanol",
        "Phenol",
        "Fractional",
        "Hoke",
        "Baker",
        "Vulpius",
        "Strayer",
        "Z-Plasty",
        "VY",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Percutaneus",
        "2-Level",
        "Ganz",
        "Acetabular",
        "Pemberton",
        "Opening Wedge",
        "Closing Wedge",
        "Periacetabular",
        "Shortening",
        "Extension",
        "Flexion",
        "Varus",
        "Valgus",
        "Tarsal Coalition",
        "Slide",
        "Dega",
        "Other",
        "Chiari",
        "Internal",
        "External",
        "Van Ness",
        "Open",
        "Closed",
        "Compartment",
        "Posterior",
        "Intramedullary",
        "Magnetic Lengthening",
        "Imbrication",
        "Plication",
        "Peabody",
        "Split"
      ],
    },
    template:
    {
      "subject-first-name": {
        "type": "value",
        "valueType": "string",
        "label": "First Name",
        "placeholder": "First name",
        "column": "1",
      },
      "subject-mrn": {
        "type": "value",
        "valueType": "string",
        "label": "MRN",
        "col": 6,
        "placeholder": "Medical Record Number",
        "column": "1",
      },
      "subject-fin": {
        "type": "value",
        "valueType": "string",
        "label": "FIN/CSN",
        "col": 6,
        "placeholder": "Financial Number",
        "column": "1",
      },
      "subject-diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Primary diagnosis",
        "column": "1",
      },
      "subject-excellent-outcome": {
        "type": "value",
        "valueType": "yes-no",
        "label": "Excellent outcome",
        "col": 6,
        "column": "1",
      },
      "subject-teaching-case:": {
        "type": "value",
        "valueType": "yes-no",
        "label": "Interesting teaching case",
        "col": 6,
        "column": "1",
      },
      "subject-middle-name": {
        "type": "value",
        "valueType": "string",
        "label": "Middle Name",
        "placeholder": "Middle name",
        "column": "2",
      },
      "subject-local-id": {
        "type": "value",
        "valueType": "yes-no",
        "label": "Local ID#",
        "col": 6,
        "column": "2",
      },
      "subject-date-of-birth": {
        "type": "date",
        "valueType": "date",
        "label": "Date of Birth",
        "col": 6,
        "column": "2",
      },
      "subject-laterality": {
        "type": "list",
        "valueType": "laterality",
        "label": "Laterality",
        "col": 6,
        "column": "2",
      },
      "subject-modifier": {
        "type": "list",
        "valueType": "modifier",
        "label": "Modifier",
        "col": 6,
        "column": "2",
      },
      "subject-last-name": {
        "type": "value",
        "valueType": "string",
        "label": "Last Name",
        "placeholder": "Last name",
        "column": "3",
      },
      "subject-sex": {
        "type": "value",
        "valueType": "sex",
        "label": "Sex (assigned at birth)",
        "col": 6,
        "column": "3",
      },
      "subject-gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender",
        "col": 6,
        "column": "3",
      },
      "subject-additional-diagnosis": {
        "type": "value",
        "valueType": "shortText",
        "label": "Additional diagnosis",
        "column": "3",
      },
    },
    subjectMetadataTabs: [
      {
        name: 'Interventions',
        template: {
          "site": {
            "type": "site-code",
            "valueType": "site-code",
            "col": 2,
            "label": "Site"
          },
          "date": {
            "type": "date",
            "valueType": "date",
            "col": 2,
            "label": "Date"
          },
          "surgeon": {
            "type": "value",
            "valueType": "string",
            "col": 2,
            "label": "Surgeon",
          },
          "surgery-dictation": {
            "type": "value",
            "valueType": "textarea",
            "label": "Surgery dictation",
            "textAreaSize": "4",
            "col": 6,
          },
        },
        procedureTemplate: {
          "side": {
            "type": "side",
            "valueType": "side",
            "label": "Side"
          },
          "location": {
            "type": "procedure-location",
            "valueType": "procedure-location",
            "label": "Location"
          },
          "location-modifier": {
            "type": "location-modifier",
            "valueType": "location-modifier",
            "label": "Modifier"
          },
          "location-modifier-2": {
            "type": "location-modifier",
            "valueType": "location-modifier",
            "label": "Modifier 2"
          },
          "location-modifier-3": {
            "type": "location-modifier",
            "valueType": "location-modifier",
            "label": "Modifier 3"
          },
          "procedure": {
            "type": "procedure",
            "valueType": "procedure",
            "label": "Procedure"
          },
          "procedure-modifier": {
            "type": "procedure-modifier",
            "valueType": "procedure-modifier",
            "label": "Modifier"
          },
        }
      },
    ],
    // the keys defined for "templateSession" will be shown in the session metadata
    sessionMetadataTabs: [
      {
        name: 'Interview',
        template: {
          "interview-patient-information": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "Patient Information"
          },
          "interview-reason-for-referral": {
            "type": "value",
            "valueType": "textarea",
            "label": "Reason for Referral",
            "placeholder": "Patient or parent perspective on the reason for their visit",
            "column": "1",
          },
          "interview-chief-complaint": {
            "type": "value",
            "valueType": "textarea",
            "label": "Chief complaint",
            "column": "1",
          },
          "interview-present-condition": {
            "type": "value",
            "valueType": "textarea",
            "label": "Present condition",
            "placeholder": "",
            "textAreaSize": "4",
            "column": "1",
          },
          "interview-patient-goal-1": {
            "type": "value",
            "valueType": "textarea",
            "label": "Patient goal 1",
            "placeholder": "What would the patient like to achieve?",
            "textAreaSize": "1",
            "column": "1",
            "col": 10,
          },
          "interview-patient-goal-1-score": {
            "type": "value",
            "valueType": "goal-score",
            "label": "Score",
            "column": "1",
            "col": 2,
          },
          "interview-patient-goal-2": {
            "type": "value",
            "valueType": "textarea",
            "label": "Patient goal 2",
            "placeholder": "What would the patient like to achieve?",
            "textAreaSize": "1",
            "column": "1",
            "col": 10,
          },
          "interview-patient-goal-2-score": {
            "type": "value",
            "valueType": "goal-score",
            "label": "Score",
            "column": "1",
            "col": 2,
          },
          "interview-family-goal-1": {
            "type": "value",
            "valueType": "textarea",
            "label": "Family goal 1",
            "textAreaSize": "1",
            "column": "1",
            "col": 10,
          },
          "interview-family-goal-1-score": {
            "type": "value",
            "valueType": "goal-score",
            "label": "Score",
            "column": "1",
            "col": 2,
          },
          "interview-family-goal-2": {
            "type": "value",
            "valueType": "textarea",
            "label": "Family goal 2",
            "textAreaSize": "1",
            "column": "1",
            "col": 10,
          },
          "interview-family-goal-2-score": {
            "type": "value",
            "valueType": "goal-score",
            "label": "Score",
            "column": "1",
            "col": 2,
          },
          "interview-treatment-consideration": {
            "type": "value",
            "valueType": "textarea",
            "label": "Treatment consideration",
            "textAreaSize": "3",
            "column": "1",
          },
          "interview-social-home-school": {
            "type": "value",
            "valueType": "textarea",
            "label": "Social / Home / School",
            "placeholder": "Home info, are there stairs?, Lives with, Activities enjoyed, School info, Grade level, learning issues, IEP",
            "textAreaSize": "4",
            "column": "1",
          },
          "interview-assessment-consent": {
            "type": "value",
            "valueType": "yes-no",
            "label": "Consent for assessment",
            "col": 6,
            "column": "1",
          },
          "interview-treatment-consent": {
            "type": "value",
            "valueType": "yes-no",
            "label": "Consent for treatment",
            "col": 6,
            "column": "1",
          },
          "interview-medical-history": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Past medical History"
          },
          "interview-pain": {
            "type": "value",
            "valueType": "pain",
            "label": "Pain",
            "col": 2,
            "column": "2",
          },
          "interview-pain-notes": {
            "type": "value",
            "valueType": "textarea",
            "label": "Pain notes",
            "placeholder": "Faces pain scale 0-10 at its best & worst, Body Location, Frequency, Intensity, What makes it better/worse?",
            "textAreaSize": "3",
            "column": "2",
          },
          "interview-therapies": {
            "type": "value",
            "valueType": "textarea",
            "label": "Therapies",
            "placeholder": "PT/OT/ST, Frequency, What are they working on?",
            "column": "2",
          },
          "interview-developmental-history": {
            "type": "value",
            "valueType": "textarea",
            "label": "Developmental History",
            "placeholder": "",
            "textAreaSize": "3",
            "column": "2",
          },
          "interview-past-medical-history": {
            "type": "value",
            "valueType": "textarea",
            "label": "Past medical history",
            "placeholder": "General history, seizures, seen by other specialists (neuro, PM&R, cardio), birth and developmental history.",
            "textAreaSize": "4",
            "column": "2",
          },
          "interview-imaging": {
            "type": "value",
            "valueType": "textarea",
            "label": "Imaging",
            "placeholder": "",
            "textAreaSize": "3",
            "column": "2",
          },
          "interview-current-medications": {
            "type": "value",
            "valueType": "textarea",
            "label": "Current Medications",
            "placeholder": "Type & Dosage",
            "column": "2",
          },
          "interview-last-mac-evaluation": {
            "type": "date",
            "valueType": "date",
            "label": "Last MAC evaluation",
            "col": 6,
            "column": "2",
          },
          "interview-mobility": {
            "type": "title",
            "valueType": "title",
            "column": "3",
            "label": "Mobility"
          },
          "interview-ambulatory-status": {
            "type": "value",
            "valueType": "textarea",
            "label": "Ambulatory status",
            "placeholder": "Brief description of orthoses, devices, problems, environment, history of ambulation",
            "textAreaSize": "4",
            "column": "3",
          },
          "interview-gmfcs": {
            "type": "value",
            "valueType": "gmfcs",
            "label": "GMFCS",
            "col": 2,
            "column": "3",
          },
          "interview-fms5m": {
            "type": "value",
            "valueType": "fms-c",
            "label": "FMS 5m",
            "col": 2,
            "column": "3",
          },
          "interview-fms50m": {
            "type": "value",
            "valueType": "fms-n",
            "label": "FMS 50m",
            "col": 2,
            "column": "3",
          },
          "interview-fms500m": {
            "type": "value",
            "valueType": "fms-n",
            "label": "FMS 500m",
            "col": 2,
            "column": "3",
          },
          "interview-orthotics": {
            "type": "value",
            "valueType": "orthotics",
            "label": "Orthotics",
            "hasContext": true,
            "col": 12,
            "column": "3",
          },
          "interview-assistive-device": {
            "type": "value",
            "valueType": "assistive-devices",
            "label": "Assistive device",
            "col": 12,
            "column": "3",
          },
          "interview-advanced-mobility": {
            "type": "value",
            "valueType": "textarea",
            "label": "Advanced Mobility",
            "placeholder": "Running/Jumping/Stairs/Bike/PE Class/Uneven Surfaces/Sports",
            "textAreaSize": "2",
            "column": "3",
          },
          "interview-falls": {
            "type": "value",
            "valueType": "textarea",
            "label": "Falls",
            "placeholder": "",
            "textAreaSize": "3",
            "column": "3",
          },
          "interview-falls-number": {
            "type": "value",
            "valueType": "string",
            "label": "Falls number",
            "column": "3",
            "col": 6,
          },
          "interview-falls-frequency": {
            "type": "value",
            "valueType": "falls-frequency",
            "label": "Falls frequency",
            "col": 6,
            "column": "3",
          },
          "interview-clinical-presentation": {
            "type": "value",
            "valueType": "clinical-presentation",
            "label": "Clinical presentation",
            "col": 6,
            "column": "3",
          },
          "interview-evaluation-complexity": {
            "type": "value",
            "valueType": "evaluation-complexity",
            "label": "Evaluation complexity",
            "col": 6,
            "column": "3",
          },
        }
      },
      {
        name: 'Session info',
        template: {
          "sessioninfo-site": {
            "type": "site-code",
            "valueType": "site-code",
            "column": "1",
            "col": 6,
            "label": "Site"
          },
          "sessioninfo-cancellation": {
            "type": "cancellation",
            "valueType": "cancellation",
            "column": "1",
            "col": 6,
            "label": "Cancellation/no show"
          },
          "sessioninfo-referring-physician": {
            "type": "value",
            "valueType": "string",
            "label": "Referring physician",
            "column": "1",
          },
          "sessioninfo-outside-referral": {
            "type": "yes-no",
            "valueType": "yes-no",
            "column": "1",
            "col": 6,
            "label": "Outside referral"
          },
          "sessioninfo-referral-type": {
            "type": "referral-type",
            "valueType": "referral-type",
            "column": "1",
            "col": 6,
            "label": "Referral type"
          },
          "sessioninfo-therapist": {
            "type": "value",
            "valueType": "string",
            "label": "Therapist",
            "column": "1",
          },
          "sessioninfo-session-type": {
            "type": "session-type",
            "valueType": "session-type",
            "column": "1",
            "label": "Session type"
          },
          "sessioninfo-conditions-collected": {
            "type": "multiselect",
            "valueType": "conditions-collected",
            "column": "2",
            "label": "Conditions collected"
          },
          "sessioninfo-data-collected": {
            "type": "multiselect",
            "valueType": "data-collected",
            "column": "2",
            "label": "Data collected"
          },
          "sessioninfo-person-processing": {
            "type": "value",
            "valueType": "string",
            "column": "2",
            "label": "Person who processed data"
          },
          "sessioninfo-date-processing-completed": {
            "type": "date",
            "valueType": "date",
            "label": "Processing completed",
            "col": 6,
            "column": "2",
          },
          "sessioninfo-error-in-data": {
            "type": "yes-no",
            "valueType": "yes-no",
            "column": "2",
            "col": 6,
            "label": "Error in data"
          },
          "sessioninfo-description-error": {
            "type": "value",
            "valueType": "textarea",
            "label": "Description of error",
            "textAreaSize": "4",
            "column": "2",
          },
          "sessioninfo-evaluating-pt": {
            "type": "value",
            "valueType": "string",
            "label": "Evaluating PT",
            "column": "3",
          },
          "sessioninfo-pt-evaluation-date": {
            "type": "date",
            "valueType": "date",
            "label": "PT evaluation in EMR",
            "col": 6,
            "column": "3",
          },
          "sessioninfo-pdf-to-emr-date": {
            "type": "date",
            "valueType": "date",
            "label": "PDF data in EMR",
            "col": 6,
            "column": "3",
          },
          "sessioninfo-mac-recommendations": {
            "type": "value",
            "valueType": "textarea",
            "label": "MAC recommendations",
            "textAreaSize": "4",
            "column": "3",
          },
          "sessioninfo-comments": {
            "type": "value",
            "valueType": "textarea",
            "label": "Comments",
            "textAreaSize": "4",
            "column": "3",
          },
        }
      },
      {
        name: 'Physical exam 1',
        template: {
          "vicon": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "Vicon",
            "hasContext": true,
          },
          "vicon-height": {
            "type": "value",
            "valueType": "string",
            "label": "Height (cm)",
            "column": "1",
          },
          "vicon-weight": {
            "type": "value",
            "valueType": "string",
            "label": "Weight (kg)",
            "column": "1",
          },
          "vicon-bmi-percentile": {
            "type": "value",
            "valueType": "string",
            "label": "BMI Percentile",
            "column": "1",
          },
          "vicon-hip-width": {
            "type": "value",
            "valueType": "string",
            "label": "Hip width (mm)",
            "column": "1",
          },
          "vicon-leg-length": {
            "type": "value",
            "valueType": "string",
            "label": "Leg length (mm)",
            "hasContext": true,
            "column": "1",
          },
          "vicon-knee-width": {
            "type": "value",
            "valueType": "string",
            "label": "Knee width (mm)",
            "hasContext": true,
            "column": "1",
          },
          "vicon-ankle-width": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle width (mm)",
            "hasContext": true,
            "column": "1",
          },
          "vicon-brace-ankle-width": {
            "type": "value",
            "valueType": "string",
            "label": "Brace ankle width (mm)",
            "hasContext": true,
            "column": "1",
          },
          "supine": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "Supine",
            "hasContext": true,
          },
          "supine-hip-abduction-ext": {
            "type": "value",
            "valueType": "string",
            "label": "Hip abduction (ext) ROM",
            "hasContext": true,
            "column": "1",
          },
          "supine-hip-abduction-flex": {
            "type": "value",
            "valueType": "string",
            "label": "Hip abduction (flex) ROM",
            "hasContext": true,
            "column": "1",
          },
          "supine-hip-adduction": {
            "type": "value",
            "valueType": "string",
            "label": "Hip adduction ROM",
            "hasContext": true,
            "column": "1",
          },
          "supine-hip-flexion": {
            "type": "value",
            "valueType": "string",
            "label": "Hip flexion ROM",
            "hasContext": true,
            "column": "1",
          },
          "supine-hip-thomas-test": {
            "type": "value",
            "valueType": "string",
            "label": "Hip thomas test",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-flexion": {
            "type": "value",
            "valueType": "string",
            "label": "Knee flexion ROM",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-extension": {
            "type": "value",
            "valueType": "string",
            "label": "Knee extension ROM",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-extensor-lag": {
            "type": "value",
            "valueType": "string",
            "label": "Knee extensor lag",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-slr-w-stretch": {
            "type": "value",
            "valueType": "string",
            "label": "Knee SLR w/ stretch",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-single-pop-r1-angle": {
            "type": "value",
            "valueType": "string",
            "label": "Knee single pop(R1) angle",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-single-pop-r2-angle": {
            "type": "value",
            "valueType": "string",
            "label": "Knee single pop(R2) angle",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-double-pop-r1-angle": {
            "type": "value",
            "valueType": "string",
            "label": "Knee double pop(R1) angle",
            "hasContext": true,
            "column": "1",
          },
          "supine-knee-double-pop-r2-angle": {
            "type": "value",
            "valueType": "string",
            "label": "Knee double pop(R2) angle",
            "hasContext": true,
            "column": "1",
          },
          "supine-transmal-axis-ext": {
            "type": "value",
            "valueType": "string",
            "label": "Transmal axis ext",
            "hasContext": true,
            "column": "1",
          },
          "supine-patella-alta": {
            "type": "plus-minus",
            "valueType": "plus-minus",
            "label": "Patella alta",
            "hasContext": true,
            "column": "1",
          },
          "supine-allis": {
            "type": "plus-minus",
            "valueType": "plus-minus",
            "label": "Allis",
            "hasContext": true,
            "column": "1",
          },
          "supine-galeazzi": {
            "type": "plus-minus",
            "valueType": "plus-minus",
            "label": "Galeazzi",
            "hasContext": true,
            "column": "1",
          },
          "supine-spasticity-hip-adductors": {
            "type": "neuromuscular-values",
            "valueType": "neuromuscular-values",
            "label": "Spasticity hip adductors",
            "hasContext": true,
            "column": "1",
          },
          "supine-spasticity-hamstrings": {
            "type": "neuromuscular-values",
            "valueType": "neuromuscular-values",
            "label": "Spasticity hamstrings",
            "hasContext": true,
            "column": "1",
          },
          "sidelying": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Sidelying",
            "hasContext": true,
          },
          "sidelying-hip-abduction": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Hip abduction MMT",
            "hasContext": true,
            "column": "2",
          },
          "sidelying-hip-adduction": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Hip adduction MMT",
            "hasContext": true,
            "column": "2",
          },
          "sidelying-scale-hip": {
            "type": "0-1-2",
            "valueType": "0-1-2",
            "label": "SCALE hip",
            "hasContext": true,
            "column": "2",
          },
          "sitting": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Sitting",
            "hasContext": true,
          },
          "sitting-hip-flexion": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Hip flexion MMT",
            "hasContext": true,
            "column": "2",
          },
          "sitting-ankle-inversion-mmt": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Ankle inversion MMT",
            "hasContext": true,
            "column": "2",
          },
          "sitting-ankle-eversion-mmt": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Ankle eversion MMT",
            "hasContext": true,
            "column": "2",
          },
          "sitting-ankle-dorsiflexion": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Ankle dorsiflexion MMT",
            "hasContext": true,
            "column": "2",
          },
          "sitting-knee-extension": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Knee extension MMT",
            "hasContext": true,
            "column": "2",
          },
          "sitting-ankle-inversion": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle inversion ROM",
            "hasContext": true,
            "column": "2",
          },
          "sitting-ankle-eversion": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle eversion ROM",
            "hasContext": true,
            "column": "2",
          },
          "sitting-spasticity-posterior-tibialis": {
            "type": "neuromuscular-values",
            "valueType": "neuromuscular-values",
            "label": "Spasticity posterior tibialis",
            "hasContext": true,
            "column": "2",
          },
          "sitting-clonus-posterior-tibialis": {
            "type": "plus-minus",
            "valueType": "plus-minus",
            "label": "Clonus posterior tibialis",
            "hasContext": true,
            "column": "2",
          },
          "sitting-scale-knee": {
            "type": "0-1-2",
            "valueType": "0-1-2",
            "label": "SCALE knee",
            "hasContext": true,
            "column": "2",
          },
          "sitting-scale-subtalar": {
            "type": "0-1-2",
            "valueType": "0-1-2",
            "label": "SCALE subtalar",
            "hasContext": true,
            "column": "2",
          },
          "sitting-scale-ankle": {
            "type": "0-1-2",
            "valueType": "0-1-2",
            "label": "SCALE ankle",
            "hasContext": true,
            "column": "2",
          },
          "sitting-scale-toes": {
            "type": "0-1-2",
            "valueType": "0-1-2",
            "label": "SCALE toes",
            "hasContext": true,
            "column": "2",
          },
          "sitting-scale-cumulative": {
            "type": "scale-to-ten",
            "valueType": "scale-to-ten",
            "label": "SCALE cumulative",
            "hasContext": true,
            "column": "2",
          },
          "sitting-confusion-unresisted": {
            "type": "scale-confusion",
            "valueType": "scale-confusion",
            "label": "Confusion unresisted",
            "hasContext": true,
            "column": "2",
          },
          "sitting-confusion-resisted": {
            "type": "scale-confusion",
            "valueType": "scale-confusion",
            "label": "Confusion resisted",
            "hasContext": true,
            "column": "2",
          },
        }
      },
      {
        name: 'Physical exam 2',
        template: {
          "prone": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "Prone",
            "hasContext": true,
          },
          "prone-knee-flexion": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Knee flexion MMT",
            "hasContext": true,
            "column": "1",
          },
          "prone-hip-extension-mmt": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Hip extension MMT",
            "hasContext": true,
            "column": "1",
          },
          "prone-spasticity-rectus-femoris": {
            "type": "neuromuscular-values",
            "valueType": "neuromuscular-values",
            "label": "Spasticity rectus femoris",
            "hasContext": true,
            "column": "1",
          },
          "prone-spasticity-gastroc-soleus": {
            "type": "neuromuscular-values",
            "valueType": "neuromuscular-values",
            "label": "Spasticity gastroc - soleus",
            "hasContext": true,
            "column": "1",
          },
          "prone-clonus-gastroc": {
            "type": "plus-minus",
            "valueType": "plus-minus",
            "label": "Clonus gastroc",
            "hasContext": true,
            "column": "1",
          },
          "prone-hip-extension": {
            "type": "value",
            "valueType": "string",
            "label": "Hip extension ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-hip-internal-rotation": {
            "type": "value",
            "valueType": "string",
            "label": "Hip internal rotation ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-hip-external-rotation": {
            "type": "value",
            "valueType": "string",
            "label": "Hip external rotation ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-knee-flexion-r1": {
            "type": "value",
            "valueType": "string",
            "label": "Knee flexion (prone, R1) ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-knee-flexion-r2": {
            "type": "value",
            "valueType": "string",
            "label": "Knee flexion (prone, R2) ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-dorsiflexion-extr1": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle dorsiflexion (ext)R1 ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-dorsiflexion-extr2": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle dorsiflexion (ext)R2 ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-dorsiflexion-mfb": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle dorsiflexion (MFB) ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-dorsiflexion-flexr1": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle dorsiflexion (flex)R1 ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-dorsiflexion-flexr2": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle dorsiflexion (flex)R2 ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-plantarflexion": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle plantarflexion ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-hindfoot-inversion": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle hindfoot inversion ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-ankle-hindfoot-eversion": {
            "type": "value",
            "valueType": "string",
            "label": "Ankle hindfoot eversion ROM",
            "hasContext": true,
            "column": "1",
          },
          "prone-transmal-axis-flex": {
            "type": "value",
            "valueType": "string",
            "label": "Transmal axis flex",
            "hasContext": true,
            "column": "1",
          },
          "prone-thighfoot-angle": {
            "type": "value",
            "valueType": "string",
            "label": "Thigh-foot angle",
            "hasContext": true,
            "column": "1",
          },
          "prone-femoral-anteversion-tpat": {
            "type": "value",
            "valueType": "string",
            "label": "Femoral anteversion (TPAT)",
            "hasContext": true,
            "column": "1",
          },
          "prone-nonwb-forefoot": {
            "type": "multiselect",
            "valueType": "static-foot-multiselect",
            "label": "NON-WB forefoot",
            "hasContext": true,
            "column": "1",
          },
          "prone-nonwb-midfoot": {
            "type": "planus-cavus",
            "valueType": "planus-cavus",
            "label": "NON-WB midfoot",
            "hasContext": true,
            "column": "1",
          },
          "prone-nonwb-hindfoot": {
            "type": "varus-valgus-calcaneous",
            "valueType": "varus-valgus-calcaneous",
            "label": "NON-WB hindfoot",
            "hasContext": true,
            "column": "1",
          },
          "prone-nonwb-great-toe": {
            "type": "varus-valgus",
            "valueType": "varus-valgus",
            "label": "NON-WB great toe",
            "hasContext": true,
            "column": "1",
          },
          "standing": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Standing",
            "hasContext": true,
          },
          "standing-wb-forefoot": {
            "type": "multiselect",
            "valueType": "static-foot-multiselect",
            "label": "WB forefoot",
            "hasContext": true,
            "column": "2",
          },
          "standing-wb-midfoot": {
            "type": "planus-cavus",
            "valueType": "planus-cavus",
            "label": "WB midfoot",
            "hasContext": true,
            "column": "2",
          },
          "standing-wb-hindfoot": {
            "type": "varus-valgus-calcaneous",
            "valueType": "varus-valgus-calcaneous",
            "label": "WB hindfoot",
            "hasContext": true,
            "column": "2",
          },
          "standing-wb-great-toe": {
            "type": "varus-valgus",
            "valueType": "varus-valgus",
            "label": "WB great toe",
            "hasContext": true,
            "column": "2",
          },
          "standing-ankle-plantarflexion-heel-raises": {
            "type": "mmt-values",
            "valueType": "mmt-values",
            "label": "Plantarflexion MMT",
            "hasContext": true,
            "column": "2",
          },
          "standing-coleman-block": {
            "type": "coleman-block",
            "valueType": "coleman-block",
            "label": "Coleman Block",
            "hasContext": true,
            "column": "2",
          },
          "standing-pelvic-obliquity": {
            "type": "up-down-neutral",
            "valueType": "up-down-neutral",
            "label": "Pelvic obliquity",
            "hasContext": true,
            "column": "2",
          },
          "comments-exam-title": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Comments",
          },
          "comments-exam": {
            "type": "value",
            "valueType": "textarea",
            "label": "Comments",
            "textAreaSize": "4",
            "column": "2",
          }
        }
      },
      {
        name: 'Questionnaires',
        template: {
          "question-podci": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "PODCI",
          },
          "question-patient-responses": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "Patient responses",
            "col": 5,
          },
          "question-patient-upper-extremity-function": {
            "type": "value",
            "valueType": "string",
            "label": "Upper extremity function",
            "column": "1",
            "col": 5,
          },
          "question-patient-transfers-basic-mobility": {
            "type": "value",
            "valueType": "string",
            "label": "Transfers and basic mobility",
            "column": "1",
            "col": 5,
          },
          "question-patient-sports-physical-function": {
            "type": "value",
            "valueType": "string",
            "label": "Sports and physical function",
            "column": "1",
            "col": 5,
          },
          "question-patient-comfort-pain": {
            "type": "value",
            "valueType": "string",
            "label": "Comfort / pain",
            "column": "1",
            "col": 5,
          },
          "question-patient-global-function": {
            "type": "value",
            "valueType": "string",
            "label": "Global function",
            "column": "1",
            "col": 5,
          },
          "question-patient-happiness-w-physical-cond": {
            "type": "value",
            "valueType": "string",
            "label": "Happiness with physical condition",
            "column": "1",
            "col": 5,
          },
          "question-parent-responses": {
            "type": "title",
            "valueType": "title",
            "column": "1",
            "label": "Parent responses",
            "col": 5,
          },
          "question-parent-relationship": {
            "type": "value",
            "valueType": "string",
            "label": "Relationship",
            "column": "1",
          },
          "question-parent-upper-extremity-function": {
            "type": "value",
            "valueType": "string",
            "label": "Upper extremity function",
            "column": "1",
            "col": 5,
          },
          "question-parent-transfers-basic-mobility": {
            "type": "value",
            "valueType": "string",
            "label": "Transfers and basic mobility",
            "column": "1",
            "col": 5,
          },
          "question-parent-sports-physical-function": {
            "type": "value",
            "valueType": "string",
            "label": "Sports and physical function",
            "column": "1",
            "col": 5,
          },
          "question-parent-comfort-pain": {
            "type": "value",
            "valueType": "string",
            "label": "Comfort / pain",
            "column": "1",
            "col": 5,
          },
          "question-parent-global-function": {
            "type": "value",
            "valueType": "string",
            "label": "Global function",
            "column": "1",
            "col": 5,
          },
          "question-parent-happiness-w-physical-cond": {
            "type": "value",
            "valueType": "string",
            "label": "Happiness with physical condition",
            "column": "1",
            "col": 5,
          },
          "question-gmfm-title": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "GMFM_66",
          },
          "question-d-standing": {
            "type": "value",
            "valueType": "string",
            "label": "D: Standing %",
            "column": "2",
            "col": 5,
          },
          "question-d-walking-running": {
            "type": "value",
            "valueType": "string",
            "label": "E: Walking, running, jumping %",
            "column": "2",
            "col": 5,
          },
          "question-gmfm": {
            "type": "value",
            "valueType": "string",
            "label": "GMFM-66",
            "column": "2",
            "col": 5,
          },
          "question-gmfm-percentile": {
            "type": "value",
            "valueType": "string",
            "label": "GMFM-66 percentile",
            "column": "2",
            "col": 5,
          },
          "question-cp-classifications": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "CP Classifications",
          },
          "question-manual-ability-score": {
            "type": "score-to-five",
            "valueType": "score-to-five",
            "label": "Manual Ability Classification Score (MACS)",
            "column": "2",
            "col": 5,
          },
          "question-comm-functional-score": {
            "type": "score-to-five",
            "valueType": "score-to-five",
            "label": "Communicational Functional Classification System (CFCS)",
            "column": "2",
            "col": 5,
          },
          "question-other": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Other",
          },
          "question-gillette-faq": {
            "type": "scale-to-ten",
            "valueType": "scale-to-ten",
            "label": "Gillette FAQ mobility scale",
            "column": "2",
            "col": 5,
          },
          "question-six-minutes-walk": {
            "type": "value",
            "valueType": "string",
            "label": "6 Minute Walk Test (m)",
            "column": "2",
            "col": 5,
          },
          "question-timed-up-go": {
            "type": "value",
            "valueType": "string",
            "label": "Timed Up and Go (s)",
            "column": "2",
            "col": 5,
          },
          "question-cmt-pediatric-scale": {
            "type": "value",
            "valueType": "string",
            "label": "CMT pediatric scale",
            "column": "2",
            "col": 5,
          },
          "question-comments-title": {
            "type": "title",
            "valueType": "title",
            "column": "2",
            "label": "Comments",
          },
          "question-comments": {
            "type": "value",
            "valueType": "textarea",
            "label": "Comments",
            "textAreaSize": "4",
            "column": "2",
          },
        }
      },
    ],
  };

  public dictionarySportsMedicine = {
    types: {
      "gender": [
        "Male",
        "Female"
      ],
      "diagnosis": [
        "ACL Rupture",
        "MPFL"
      ],
      "laterality": [
        "Left",
        "Right",
        "Bilateral",
        "Normal"
      ],
      "clinical-presentation": [
        "Stable",
        "Evolving",
        "Unstable"
      ],
      "site": [
        "Chicago",
        "Greenville",
        "Lexington",
        "Montreal",
        "Northern California",
        "Philadelphia",
        "Portland",
        "Salt Lake City",
        "Shreveport",
        "Spokane",
        "Springfield",
        "Texas",
        "Mexico",
      ],
      "complexity": [
        "Low",
        "Moderate",
        "High",
      ],
    },
    template:
    {
      "site": {
        "type": "value",
        "valueType": "site",
        "label": "Site",
      },
      "last-name": {
        "type": "value",
        "valueType": "string",
        "label": "Last Name",
        "placeholder": "Last name",
      },
      "first-name": {
        "type": "value",
        "valueType": "string",
        "label": "First Name",
        "placeholder": "First name",
      },
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "MRN",
      },
      "fin": {
        "type": "value",
        "valueType": "string",
        "label": "FIN",
      },
      "gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender",
      },
      "date-of-birth": {
        "type": "date",
        "valueType": "date",
        "label": "Date of Birth",
      },
      "diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Primary diagnosis"
      },
      "laterality": {
        "type": "list",
        "valueType": "laterality",
        "label": "Laterality of complaint"
      },
      "research-irb-id": {
        "type": "value",
        "valueType": "string",
        "label": "Research IRB ID"
      },
    },
    // the keys defined for "templateSession" will be shown in the session metadata
    sessionMetadataTabs: [
      {
        name: 'Session info',
        template: {
          "evaluation-time": {
            "type": "value",
            "valueType": "string",
            "label": "Evaluation time"
          },
          "physician": {
            "type": "value",
            "valueType": "string",
            "label": "Physician"
          },
          "outside-physician": {
            "type": "value",
            "valueType": "string",
            "label": "Outside physician"
          },
          "therapist": {
            "type": "value",
            "valueType": "string",
            "label": "Therapist"
          },
          "referral-reason": {
            "type": "value",
            "valueType": "string",
            "label": "Referral reason",
            "placeholder": "MD reason from medical record, type of study i.e. treatment planning, post, baseline",
          },
          "study": {
            "type": "value",
            "valueType": "string",
            "label": "Study"
          },
          "conditions-collected": {
            "type": "value",
            "valueType": "textarea",
            "label": "Conditions collected",
            "placeholder": "Conditions collected (i.e. Barefoot, Braced (side), Shod, Running, wedge, “Best Walk” (toe walkers)]",
          },
          "imaging": {
            "type": "value",
            "valueType": "textarea",
            "label": "Imaging",
            "placeholder": "Motion analysis relevant MRI, CT scan & radiographic information",
          },
          "additional-comments": {
            "type": "value",
            "valueType": "textarea",
            "label": "Additional comments",
            "placeholder": "MD notes, comorbidities, pertinent info from interview, overall current status of the patient, parent’s comments, etc.",
          },
        }
      },
      {
        name: 'History',
        template: {
          "chief-complaint": {
            "type": "value",
            "valueType": "textarea",
            "label": "Chief complaint",
            "placeholder": "Patient or parent perspective on the reason for their visit",
          },
          "patient-goals": {
            "type": "value",
            "valueType": "textarea",
            "label": "Patient goals",
            "placeholder": "What would the patient like to achieve? Goal Attainment Score (Autopopulate Previous)",
          },
          "ambulatory-status": {
            "type": "value",
            "valueType": "textarea",
            "label": "Ambulatory status",
            "placeholder": "Brief description of orthoses, devices, problems, environment, history of ambulation",
          },
          "pain": {
            "type": "value",
            "valueType": "textarea",
            "label": "Pain",
            "placeholder": "Pain, PainNotes. Faces pain scale 0-10 at its best & worst, Body Location, Frequency, Intensity, What makes it better/worse?"
          },
          "advanced-mobility": {
            "type": "value",
            "valueType": "textarea",
            "label": "Advanced Mobility",
            "placeholder": "Running/Jumping/Stairs/Bike/PE Class/Uneven Surfaces/Sports",
          },
          "social-home-school": {
            "type": "value",
            "valueType": "textarea",
            "label": "Social/home/school",
            "placeholder": "Home info, are there stairs?, Lives with, Activities enjoyed, School info, Grade level, learning issues, IEP, 504",
          },
          "therapies": {
            "type": "value",
            "valueType": "textarea",
            "label": "Therapies",
            "placeholder": "PT/OT/ST, Frequency, What are they working on?",
          },
          "past-medical-history": {
            "type": "value",
            "valueType": "textarea",
            "label": "Past medical history",
            "placeholder": "General history, seizures, seen by other specialists (neuro, PM&R, cardio)",
          },
          "prior-studies": {
            "type": "value",
            "valueType": "textarea",
            "label": "Prior studies and interventions",
            "placeholder": "Brief description, MAC appointments and surgeries",
          },
          "current-medications": {
            "type": "value",
            "valueType": "textarea",
            "label": "Current Medications",
            "placeholder": "Type & Dosage",
          }
        }
      },
      {
        name: 'Recommendations',
        template: {
          "summary": {
            "type": "value",
            "valueType": "textarea",
            "label": "Summary"
          },
          "plan-of-care": {
            "type": "value",
            "valueType": "textarea",
            "label": "Plan of care",
            "placeholder": "Complete a one-time evaluation for comprehensive motion analysis to be discussed with an interdisciplinary team for treatment planning. No additional therapy is necessary in this setting at this time",
          },
          "personal-factors": {
            "type": "value",
            "valueType": "textarea",
            "label": "Personal factors",
            "placeholder": "Factors include sex, age, coping styles, social background, education, past/current experiences, behavior pattern, character, and overall factors that influence how disability is experienced by the individual",
          },
          "body-systems": {
            "type": "value",
            "valueType": "textarea",
            "label": "Body systems",
            "placeholder": "List the body systems involved (elements include body structures and functions, activity limitations, and/or participation restrictions) ex. hip abductor weakness, hip extensor weakness, hamstring weakness limited gastrocnemius length",
          },
          "clinical-presentation": {
            "type": "value",
            "valueType": "clinical-presentation",
            "label": "Clinical Presentation"
          },
          "clinical-decision-making": {
            "type": "value",
            "valueType": "complexity",
            "label": "Clinical decision making complexity",
            "placeholder": "Complexity, or number of elements, used to make treatment decision: Low/Moderate/High",
          },
          "evaluation-complexity": {
            "type": "value",
            "valueType": "complexity",
            "label": "Evaluation complexity",
          },
        }
      },
      {
        name: 'ROM',
        template: {
          "leg-length": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Leg length [m]"
          },
          "knee-flexion": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee flexion [deg]"
          },
          "knee-extension-rom": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee extension [deg]"
          },
          "popliteal-angle": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Popliteal angle [deg]"
          },
          "dorsiflexion-knee-extended": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Dorsiflexion knee extended [deg]",
          },
          "dorsiflexion-knee-flexed": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Dorsiflexion knee flexed [deg]",
          },
          "hip-internal-rotation": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip internal rotation [deg]",
          },
          "hip-external-rotation": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip external rotation [deg]",
          },
          "tibial-torsion-prone": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Tibial torsion, prone [deg]",
          },
        }
      },
      {
        name: 'Strength',
        template: {
          "hip-abduction": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip abduction"
          },
          "hip-extension": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip extension"
          },
          "knee-extension-strength": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee extension"
          },
          "heel-raises": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Heel raises"
          },
        }
      }
    ],
  };

}
