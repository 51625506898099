import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FeatureFlags } from "app/projects/project-info/project-info-data.service";

@Injectable()
export class FeatureFlagsService {

  private flags: FeatureFlags;

  public constructor(
    private readonly route: ActivatedRoute,
  ) {
    this.flags = this.route.snapshot.data.projectInfo;
  }

  public get(flagName: keyof FeatureFlags): boolean | unknown {
    return this.flags[flagName];
  }

  public getAll(): FeatureFlags {
    return this.flags;
  }
}
