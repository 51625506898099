import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {
  @Output()
  imageDataUri: EventEmitter<string>;

  @ViewChild('modalTemplate', { static: true })
  template: TemplateRef<any>;

  //public image: string;


  private modalRef: BsModalRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    /*this.imageDataUri.emit(cropper.getCroppedCanvas({
      width: 256,
      height: 256
    }).toDataURL('image/jpeg'));
    this.modalRef.hide();*/
  }

  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }

  @Input()
  set filename(filename) {
    if (filename) {
      this.imageChangedEvent = filename;
      this.modalRef = this.modalService.show(this.template);
    } else
      this.imageChangedEvent = null;

    /*if (filename) {
      let reader = new FileReader();
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.modalRef = this.modalService.show(this.template);
      }
      reader.readAsDataURL(filename);
    }*/
  }

  constructor(private modalService: BsModalService) {
    this.imageDataUri = new EventEmitter<string>();
  }

  save(cropper): void {
    this.imageDataUri.emit(this.croppedImage); //.toDataURL('image/png'));
    this.modalRef.hide();

    /*this.imageDataUri.emit(cropper.getCroppedCanvas({
      width: 256,
      height: 256
    }).toDataURL('image/jpeg'));
    this.modalRef.hide();*/
  }

  cancel(): void {
    this.modalRef.hide();
  }
}
