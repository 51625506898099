<a class="nav-link" (click)="showModal(modal)" style="cursor: pointer">
  <i class="fa fa-upload mr-2" aria-hidden="true"></i>Upload
</a>

<input type="file" [accept]="acceptFiles" style="display:none" (change)="addFiles(fileSelector.files)" #fileSelector multiple>

<ng-template class="modal fade" #modal>
  <!-- Keeping this code as we may want to use clip uploader component, which is currently unused -->
    <!-- <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Upload motion data</h4>
        <span class="pull-right">
          <i class="fa fa-times clickable-danger" aria-label="Close" (click)="hideModal()"></i>
        </span>
      </div>
      <form #uploadForm="ngForm" (ngSubmit)="uploadFiles()">
        <div class="modal-body">
          <div *ngIf="errors.length > 0" class="card border-danger my-2">
            <ul class="list-group list-group-flush">
              <li *ngFor="let error of errors; let i=index" class="list-group-item list-group-item">
                <span class="pull-right">
                  <i class="fa fa-times clickable-danger" aria-label="Close" (click)="clearError(i)"></i>
                </span>
                <p>{{error.error}}</p>
                <button type="button" *ngIf="error.file" class="btn btn-secondary" (click)="requestSupport(error)" [disabled]="error.supportRequested">
                  {{error.supportRequested ? "Support Requested" : "Request Support"}}
                </button>
              </li>
            </ul>
          </div>
          <div class="card border-success card-header text-center text-muted" appDndFile [accept]="acceptFiles" (acceptedFiles)="addFiles($event)"
            (rejectedFiles)="rejectFiles($event)" (click)="fileSelector.click()">
            Drag and drop files to upload or click to select
            <i *ngIf="loading" class="fa fa-spinner fa-spin" style="float: right;"></i>
          </div>
          <br>
          <tabset #tabset>
          <tab *ngFor="let upload of uploads; let i=index; trackBy:trackByAnimationId;" [heading]="upload.file.name"
          [id]="i" class="container">
          <div class="col-12 clearfix">
              <i class="fa fa-times clickable-danger mx-2" style="float: right;" (click)="deleteTab(i, tabset)"></i>
            <ng-container *ngIf="!upload.clip.nopreview; else nopreview">
              <div class="d-block">
              <app-mocap-clip [animationClips]="[upload.clip]" [autoPlay]="i==0" [playing]="tabset.tabs[i].active" allowSelectionEditing=true [(selection)]="upload.selection"></app-mocap-clip>
              </div>
              <div class="small text-muted mt-1">
                <span class="align-middle mr-2">
                  <i>Data not playing correctly?</i>
                </span>
                <ng-container *ngIf="!upload.supportRequested else supportrequested">
                  <span class="align-middle clickable" (click)="requestSupport(upload)">
                    Request Support
                  </span>
                </ng-container>
                <ng-template #supportrequested><span class="align-middle">Support Requested</span></ng-template>
              </div>
            </ng-container>
            <ng-template #nopreview>
              <div class="d-block text-muted" style="margin: 30px 30px">
                <i>Please fill-in clip details and confirm the upload to get started...</i>
              </div>
            </ng-template>
            <div class="row p-1">
              <label for="filename{{i}}" class="col-sm-3 col-form-label text-muted">Filename</label>
              <input type="text" readonly class="form-control-plaintext text-light col-sm-8" name="filename{{i}}" [(ngModel)]="upload.file.name" id="filename{{index}}"
              disabled>
            </div>
            <div class="row p-1">
              <label for="title_{{i}}" class="col-sm-3 col-form-label text-muted">Title</label>
              <input type="text" name="title_{{i}}" class="form-control col-sm-8" [(ngModel)]="upload.metadata.title" id="title_{{index}}">
            </div>
            <div class="row p-1">
              <label for="desc_{{i}}" class="col-sm-3 col-form-label text-muted">Description</label>
              <input type="text" name="desc_{{i}}" class="form-control col-sm-8" [(ngModel)]="upload.metadata.description" id="desc_{{index}}">
            </div>
            <br>
          </div>
          </tab>
          </tabset>
        </div>
        <div class="modal-footer">
          <div class="d-flex my-auto ml-auto justify-content-end form-group">
            <label class="m-1" for="project">Project:</label>
            <select class="form-control py-1" [disabled]="uploads.length == 0" [(ngModel)]="project" id="inputGroupSelect03" name="projectName">
                <option *ngFor="let p of projects" [ngValue]="p.name">{{p.name}}</option>
            </select>
          </div>
          <button class="btn btn-primary" type="submit" [disabled]="uploads.length == 0">Upload</button>
        </div>
      </form>
  </div> -->
</ng-template>
