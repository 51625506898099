const THREE = require('three');
BonesMeshHelper.prototype = Object.create( THREE.Mesh.prototype );
BonesMeshHelper.prototype.constructor = BonesMeshHelper;
BonesMeshHelper.prototype.setBodyPart = setBodyPart;

BonesMeshHelper.prototype.updateMatrixWorld = function () {

  var vector = new THREE.Vector3();
  var vectorParent = new THREE.Vector3();

  var boneMatrix = new THREE.Matrix4();
  var matrixWorldInv = new THREE.Matrix4();
  var matr = new THREE.Matrix4();

  var axis = new THREE.Vector3();

  return function updateMatrixWorld( force ) {

    var bones = this.bones;
    var geometry = this.geometry;
    var position = geometry.getAttribute( 'position' );
    var bonesSize = this.bonesSize;

    var localY = new THREE.Vector3();
    var localZ = new THREE.Vector3();
    var orthoX = new THREE.Vector3();
    var orthoY = new THREE.Vector3();
    var x = new THREE.Vector3();
    var y = new THREE.Vector3();

    matrixWorldInv.getInverse( this.root.matrixWorld );

    for ( var i = 0, j = 0, k = 0; i < bones.length; i ++ ) {

      var bone = bones[ i ];

      if ( bone.parent && bone.parent.isBone )
      {
        boneMatrix.multiplyMatrices( matrixWorldInv, bone.matrixWorld );
        vector.setFromMatrixPosition( boneMatrix );

        boneMatrix.multiplyMatrices( matrixWorldInv, bone.parent.matrixWorld );
        vectorParent.setFromMatrixPosition( boneMatrix );

        //var localX = new THREE.Vector3(boneMatrix.elements[0], boneMatrix.elements[1], boneMatrix.elements[2]);
        localY.set(boneMatrix.elements[4], boneMatrix.elements[5], boneMatrix.elements[6]);
        localZ.set(boneMatrix.elements[8], boneMatrix.elements[9], boneMatrix.elements[10]);

        axis.set(vector.x-vectorParent.x, vector.y-vectorParent.y, vector.z-vectorParent.z);
        axis.normalize();
        var dotZ = axis.dot(localZ);
        var dotY = axis.dot(localY);
        if(dotZ > 0.98) //the idea is only use this if axis || to localZ. Conceptually this should only be computed once to save computational time and to avoid odd jumps
        {
          orthoX.crossVectors(axis, localY);
        }
        else
        {
          orthoX.crossVectors(axis, localZ);
        }
        orthoX.normalize();
        orthoY.crossVectors(axis, orthoX);
        orthoY.normalize();

        var lineLength = 0.0525 * this.scaling; //default size
        if(bonesSize[j] != -1)
          lineLength = bonesSize[j] * 0.35;

        x.set(orthoX.x*lineLength, orthoX.y*lineLength, orthoX.z*lineLength);
        y.set(orthoY.x*lineLength, orthoY.y*lineLength, orthoY.z*lineLength);

        position.setXYZ( k, vectorParent.x + x.x - y.x, vectorParent.y + x.y - y.y, vectorParent.z + x.z - y.z);
        position.setXYZ( k + 1, vectorParent.x + x.x + y.x, vectorParent.y + x.y + y.y, vectorParent.z + x.z + y.z);
        position.setXYZ( k + 2, vector.x, vector.y, vector.z);


        position.setXYZ( k + 3, vectorParent.x + x.x + y.x, vectorParent.y + x.y + y.y, vectorParent.z + x.z + y.z);
        position.setXYZ( k + 4, vectorParent.x + y.x - x.x, vectorParent.y - x.y + y.y, vectorParent.z - x.z + y.z);
        position.setXYZ( k + 5, vector.x, vector.y, vector.z);


        position.setXYZ( k + 6, vectorParent.x + y.x - x.x, vectorParent.y - x.y + y.y, vectorParent.z - x.z + y.z);
        position.setXYZ( k + 7, vectorParent.x - x.x - y.x, vectorParent.y - x.y - y.y, vectorParent.z - x.z - y.z);
        position.setXYZ( k + 8, vector.x, vector.y, vector.z);


        position.setXYZ( k + 9, vectorParent.x - x.x - y.x, vectorParent.y - x.y - y.y, vectorParent.z - x.z - y.z);
        position.setXYZ( k + 10, vectorParent.x + x.x - y.x, vectorParent.y + x.y - y.y, vectorParent.z + x.z - y.z);
        position.setXYZ( k + 11, vector.x, vector.y, vector.z);

        k += 12;
        j += 1;
      }

    }

    geometry.getAttribute( 'position' ).needsUpdate = true;
    THREE.Object3D.prototype.updateMatrixWorld.call( this, force );

  };

}();

function modifyChildrenSize(bone, hierarchy, bonesSizeArray, k, size)
{
  var hierarchyParent = hierarchy[hierarchy.length-1];
  if (hierarchyParent != undefined)
  {
    for(var kk=0; kk<hierarchyParent.children.length; kk++)
    {
      if ((hierarchyParent.children[kk].name == bone.parent.name) || (hierarchyParent.bone.name == bone.parent.name) )
      {
        bonesSizeArray[k] = size;
      }
    }
  }
}

function setBodyPart(skeletonIds)
{
  this.leftArm = skeletonIds.leftArm;
  this.leftLeg = skeletonIds.leftLeg;
  this.rightArm = skeletonIds.rightArm;
  this.rightLeg = skeletonIds.rightLeg;
  this.spine = skeletonIds.spine;

  var geometry = this.geometry;
  var color = geometry.getAttribute( 'color' );
  var bones = this.bones;

  var leftArm = this.leftArm;
  var leftLeg = this.leftLeg;
  var rightArm = this.rightArm;
  var rightLeg = this.rightLeg;
  var spine = this.spine;

  var smallScale = 0.05;
  for (var i = 0, j = 0; i < bones.length; i ++ )
  {
    var bone = bones[i];

    if (bone.parent && bone.parent.isBone ) {
      modifyChildrenSize(bone, leftArm, this.bonesSize, j, smallScale * this.scaling);
      modifyChildrenSize(bone, rightArm, this.bonesSize, j, smallScale * this.scaling);
      j += 1;
    }

  }
}

function BonesMeshHelper( object, firstBone, scaling, opacity ) {

  var fullBones = getBoneList( object );
  var bones = [];
  var geometry = new THREE.BufferGeometry();

  var vertices = [];
  var bonesSize = [];
  var color3 = new THREE.Color( 1, 0, 0 );

  for ( var i = firstBone; i < fullBones.length; i ++ ) {

    var bone = fullBones[ i ];
    bones.push(bone);

    if ( bone.parent && bone.parent.isBone ) {
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );
      vertices.push( 0, 0, 0 );

      bonesSize.push(0.15 * scaling);
    }

  }

  geometry.addAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

  var material = new THREE.MeshPhongMaterial({
    color: 0xaaaaaa,
    skinning : false,
    transparent: true,
    side: THREE.DoubleSide,
    flatShading: true,
    wireframe: false,
    opacity: 0.2 * opacity
  } );

  THREE.Mesh.call( this, geometry, material );

  this.castShadow = true;

  this.root = object;
  this.bones = bones;
  this.scaling = scaling;

  this.matrix = object.matrixWorld;
  this.matrixAutoUpdate = false;
  this.bonesSize = bonesSize;


}

function getBoneList( object ) {

  var boneList = [];

  if ( object && object.isBone ) {

    boneList.push( object );

  }

  for ( var i = 0; i < object.children.length; i ++ ) {

    boneList.push.apply( boneList, getBoneList( object.children[ i ] ) );

  }

  return boneList;
}

export { BonesMeshHelper }
