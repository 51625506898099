import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { Subscription } from 'rxjs';
import { pollConfig } from './services/polling.service';


const POLL_INTERVAL = pollConfig.LONG;
const getNotifications = gql`
query getNotifications($username: String!, $first: Int = 5, $cursor: String) {
  user(username: $username) {
    id,
    notifications(first: $first, after: $cursor, order: DESCENDING) {
      pageInfo {
        hasNextPage,
        endCursor
      },
      edges {
        node {
          id,
          created,
          message,
          url,
          state,
          sender {
            id,
            username,
            displayName,
            avatar
          }
        }
      }
    }
  }
}
`;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() username: string;

  constructor(private apollo: Apollo,
    @Inject(PLATFORM_ID) private platformId) { }

  queryRef: QueryRef<any>;
  count: number = 0;
  notifications: any[];
  subs: Subscription[] = [];
  clipPageInfo: any;
  pendingQuery: boolean = false;
  numberToLoad: number = 5;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.queryRef = this.apollo.watchQuery<any>({
        query: getNotifications,
        variables: {
          username: this.username
        },
        pollInterval: POLL_INTERVAL
      });
      this.pendingQuery = true;
      const sub = this.queryRef.valueChanges.subscribe( ({data}) => {
        this.notifications = [];
        this.count = 0;
        const unreadNotifications = [];
        this.pendingQuery = false;

        for (const edge of data.user.notifications.edges) {
          const notification = edge.node;
          if (notification.state != 'Read') {
            this.count++;
            if (notification.state == 'Unread')
              unreadNotifications.push(notification);
          }
          this.notifications.push(notification);
        }

        if (unreadNotifications.length > 0)
          this.notifyMe(this.username, unreadNotifications);

        this.clipPageInfo = data.user.notifications.pageInfo;
      } );

      this.subs.push(sub);
    }
  }

  notificationWithUrl(username: string, notifications: any[]) {
    let body = '';
    for (const n of notifications) {
      body += n.sender.displayName + ' ' + n.message + '; \n';
      this.clearNotification(n.id, 'Seen');
    }

    const notification = new Notification( username + ", you have new notifications!",
    { icon: "/assets/icons/moveshelf_icon512.png",
      body: body});
      notification.onclick = () => {
      window.open("/profile");
    };
  }

  notifyMe(username: string, notifications: any[]) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      return;
      //alert("This browser does not support system notifications");
    } else if (Notification.permission === "granted") {
       // Let's check whether notification permissions have already been granted
      // If it's okay let's create a notification
      this.notificationWithUrl(username, notifications);
    } else if (Notification.permission !== 'denied') {
    // Otherwise, we need to ask the user for permission
      Notification.requestPermission( (permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted")
          this.notificationWithUrl(username, notifications);
      });
    }

    // If the user has denied notifications and you
    // want to be respectful there is no need to bother them any more.
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  clearNotifications() {
    setTimeout( () => {
    for (const notification of this.notifications) {
      if (notification.state != 'Read')
        this.clearNotification(notification.id);
    }
    }, 4000);
  }

  loadMore() {
    if (this.clipPageInfo.hasNextPage && !this.pendingQuery) {
      this.pendingQuery = true;
      this.numberToLoad += 5;
      this.queryRef.stopPolling();
      this.queryRef.setVariables({username: this.username, first: this.numberToLoad});
      this.queryRef.startPolling(POLL_INTERVAL);
    }
  }

  public clearNotification(id: string, state: string = 'Read') {
    this.apollo.mutate({
      mutation: gql`
      mutation clearNotification($id: ID!, $state: String) {
        clearNotification(id: $id, state: $state) {
          notif {
            id,
            message,
            url,
            state
          }
        }
      }
    `,
      variables: {
        id: id,
        state: state
      }
    }).subscribe( ({data}) => {

    });
  }

}
