import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnvironmentService } from 'app/shared/services/environment.service';
import { WindowService } from '../window.service';

@Component({
  selector: 'app-tenant-dialog',
  templateUrl: './tenant-dialog.component.html',
  styleUrls: ['./tenant-dialog.component.scss']
})
export class TenantDialogComponent {

  public tenantForm = this.formBuilder.group({
    domain: ['', Validators.required]
  });
  public subdomainTemplate: string;
  protected chooseDomain: boolean = false;

  @Output() continueHereEvent = new EventEmitter<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private readonly window: WindowService,
    private environment: EnvironmentService,
  ) {
    this.subdomainTemplate = this.environment.getEnvironment().subdomainTemplate;
  }

  onSubmit(): void {
    const domain: string = this.tenantForm.get('domain').value;
    const requestedPath = this.router.url;
    const externalUrl = `https://${domain}${this.subdomainTemplate}${requestedPath}`;
    this.window.redirect(externalUrl);
  }

  continueHere(): void {
    this.continueHereEvent.emit();
  }

  get domain(): AbstractControl { return this.tenantForm.get('domain'); }

  protected getHost(): string {
    return window.location.host;
  }

  protected toggleTenantDialog(): void {
    this.chooseDomain = !this.chooseDomain;
  }
}
