import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { TileUpdateIdsAndType } from 'app/shared/left-right/left-right';
import { LeftRightService } from 'app/shared/left-right/left-right.service';
import { TrialChartsService } from 'app/shared/multi-chart/trial-charts.service';
import { FeatureFlagsService } from 'app/shared/services/feature-flags.service';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { GlobalPlaybackControlService } from '../../../core/playback-controls/global-playback-control.service';
import { PlaybackMode } from '../../../core/playback-mode.enum';
import { DegreeConvention } from './goniometer-overlay/goniometer-overlay.component';
import { MediaData, OverlayConfiguration, OverlayType, RotationOptions, VideoRotationCSSProps } from './media-player.types';
import { RulerAngles } from './saga-ruler-overlay/saga-ruler-overlay.component';
import { MediaPlayerOverlayService } from './services/media-player-overlay.service';
import { MediaPlayerService } from './services/media-player.service';
import { VideoTrackService } from './video-tracks.service';

@Component({
  template: '',
})
export abstract class AMediaPlayerComponent implements AfterViewInit, OnDestroy {
  @Input() set tracks(trs: any[]) {
    if (trs.length > 0) {
      this.videoTracks = [];
      for (const track of trs) {
        this.videoTracks.push(track);
      }
      this.currentVideo = trs[0];
      this.createVideo(trs[0]);
    }
  }

  @Input() nextVideoEnabled = true;

  @Input() set playbackControl(value: any) {
    this.setPlaybackControl(this.error, this.mode, value);
  }

  @Input() set playbackSpeed(value: any) {
    this.setPlaybackRate(value);
  }

  @Input() set layout(value: any) {
    this.setLayout(value);
  }

  @Input() set controls(value: any) {
    if (value) {
      this.showControls();
      this._showControls = true;
    } else {
      this.hideControls();
      this._showControls = false;
    }

  }

  @Input() set maxHeight(maxHeight: number) {
    if (!this.rotationOptions?.rotate && !this.splitView) {
      return;
    }
    this.maxWidth = maxHeight;
  }

  @Input() splitView: boolean = false;
  @Input() clickers = true;
  @Input() protected currentTrialName: string = "";
  @Input() protected trialId: string = "";

  @Output() durationEvent: EventEmitter<any> = new EventEmitter();
  @Output() videoReady: EventEmitter<boolean> = new EventEmitter();
  @Output() mediaMetadataLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() tileChanged: EventEmitter<TileUpdateIdsAndType> = new EventEmitter<TileUpdateIdsAndType>();

  @ViewChild('videoEl', { static: true })
  videoEl: ElementRef;

  @ViewChild('containerEl', { static: true })
  containerEl: ElementRef;

  public rotationOptions: RotationOptions;
  audio: HTMLAudioElement;
  video: HTMLVideoElement;

  media: HTMLMediaElement;
  mediaCurrentDuration: number = 0;
  timeOffset: number = 0;

  baseLayout: string = 'thumb-top';
  playerClass: string = this.baseLayout;
  isFullscreen: boolean = false;
  muted: boolean = true;
  mode: PlaybackMode = PlaybackMode.Paused;

  data: MediaData;
  public currentVideo: MediaData;
  videoTracks = [];
  playingRequested = false;
  public overlayEnabled = false;
  public goniometerModeActive = false;
  public rulerModeActive = false;
  public dropdownActive = false;
  public goniometerDropdownValue = true;
  public currentGoniometerAngle: DegreeConvention;
  public currentRulerAngles: RulerAngles;
  public videoHasBodyPose = false;

  _showControls: boolean = false;
  public maxWidth: number;
  public error = false;

  public overlayConfiguration: OverlayConfiguration = { videoName: '', overlayType: OverlayType.TrialView};
  public videoId: string;
  public interval: NodeJS.Timer;

  protected _playbackControl: PlaybackControlService;
  private subs: Subscription[] = [];
  private lastTime: number = 0;

  constructor(
    protected readonly playbackGlobal: GlobalPlaybackControlService,
    public leftRightService: LeftRightService,
    protected readonly trackService: VideoTrackService,
    protected readonly mediaPlayerOverlayService: MediaPlayerOverlayService,
    protected readonly mediaPlayerService: MediaPlayerService,
    protected readonly featureFlagsService: FeatureFlagsService,
    protected readonly trialChartsService: TrialChartsService,
  ) {
    this.videoId = uuidv4();

    this.data = {
      dataUrl: '',
      rotateOptions: { rotate: false },
      audio: false,
      playbackRate: 1,
      timeOffset: 0,
      screenPosition: { x: -3, y: 3, z: 7 },
    };
  }

  ngAfterViewInit(): void {
    const videoElem: HTMLVideoElement = <HTMLVideoElement>document.getElementById(this.videoId);
    const getVideoElementData = () => {
      this.overlayConfiguration = this.mediaPlayerOverlayService.getVideoElementData(this.overlayConfiguration, videoElem);
    };

    videoElem.addEventListener('loadedmetadata', getVideoElementData);
    this.interval = setInterval(function () {
      getVideoElementData();
    }, 500);

    window.addEventListener('resize', getVideoElementData);
  }

  public ngOnDestroy(): void {
    if (this.media) {
      this.media.src = '';
      this.media.load();
    }
    this.subs.forEach(sub => sub.unsubscribe());
    // TODO: fix interval
    // clearInterval(this.interval);
  }

  hideControls() {
    if (this.media && this.media.hasAttribute("controls")) {
      this.media.removeAttribute("controls");
    }
  }

  showControls() {
    /*if (this.media.hasAttribute("controls")) {
       this.media.removeAttribute("controls")
    } else {
       this.media.setAttribute("controls","controls")
    }*/

    if (this.media) {
      this.media.setAttribute("controls", "controls");
    }
  }

  isMuted() {
    if (this.data.audio && this.media) {
      return this.media.muted;
    }

    return true;
  }

  toggleAudio() {
    if (this.data.audio && this.media) {
      this.media.muted = !this.media.muted;
    }
  }

  createAudio(data: MediaData) {

    this.media = document.createElement('audio');
    this.media.loop = true;

    this.readMediaData(data);
    this.data.audio = true;
    this.audio = this.media;
  }

  readMediaData(data: MediaData): void {
    let isMediaMetadataLoaded = false;
    // Save the video name with trial info and original file name
    if (data.trialNameInReport) {
      // If the trial is in the report, add it to the video name to differentiate between videos with the same name
      this.overlayConfiguration.videoName = [data.trialNameInReport, data.originalFileName].join(' > ');
      this.overlayConfiguration.overlayType = OverlayType.ReportView;
    } else {
      // If it's only a trial view, use the original file name
      this.overlayConfiguration.videoName = data.originalFileName;
      this.overlayConfiguration.overlayType = OverlayType.TrialView;
    }

    for (const p in data) {
      this.data[p] = data[p];
    }

    this.media.crossOrigin = "anonymous";
    const tempOffset = this.timeOffset;

    this.rotationOptions = data.rotateOptions;

    this.timeOffset = this.data.timeOffset;
    this.media.defaultPlaybackRate = this.playbackGlobal.playbackControl.getValue().getPlaybackSpeed();
    this.media.playbackRate = this.playbackGlobal.playbackControl.getValue().getPlaybackSpeed();

    this.media.autoplay = true;
    this.media.muted = true;

    this.media.onloadedmetadata = () => {
      this.durationEvent.emit(this.media.duration);
      isMediaMetadataLoaded = true;
      this.mediaMetadataLoaded.emit(true);
      const videoElem: HTMLVideoElement = <HTMLVideoElement>document.getElementById(this.videoId);
      const frameCounter = (now, metadata) => {
        const tOffsetVideoSync = this.leftRightService.tOffsetVideoSync[this.currentVideo?.trialNameInReport] !== undefined ? this.leftRightService.tOffsetVideoSync[this.currentVideo.trialNameInReport] : 0;
        const videoTime = this._playbackControl.playbackTime.value + this.timeOffset + tOffsetVideoSync - this.trialChartsService.playbarOffset;
        const currentTime = Math.max(videoTime, 0);

        const delta = currentTime - this.media.currentTime;
        if (Math.abs(delta) >= this._playbackControl.getTimeJumpSeconds()) {
          this.media.currentTime = currentTime;
        }
        // Re-register the callback to be notified about the next frame.
        videoElem.requestVideoFrameCallback(frameCounter);
      };
      // Initially register the callback to be notified about the first frame.
      videoElem.requestVideoFrameCallback(frameCounter);
    };
    // if we don't get the media loaded event within 500ms, then make sure we get an update on 3D player
    setTimeout(() => {
      if (!isMediaMetadataLoaded) {
        this.mediaMetadataLoaded.emit(true);
      }
    }, 500);


    this.media.onloadeddata = () => {
      if (this.mode == PlaybackMode.Paused || this.mode == PlaybackMode.Seeking) {
        this.media.pause();
      }
      this.videoReady.emit(true);
    };

    this.media.src = this.data.dataUrl;
  }

  @HostListener('document:keyup.escape', ['$event'])
  onKeyupHandler(): void {
    if (this.isFullscreen) {
      this.toggleFullscreen();
    }
  }

  public toggleFullscreen(): void {
    if (!this.isFullscreen) {
      this.playerClass = 'fullscreen';
      this.isFullscreen = true;
      this.playbackGlobal.controlsFullscreen.next(true);
    } else {
      this.playerClass = this.baseLayout;
      this.isFullscreen = false;
      this.playbackGlobal.controlsFullscreen.next(false);
      if (this.currentVideo !== this.videoTracks[0]) {
        // Video have been changed while full screen, restore original
        this.currentVideo = this.videoTracks[0];
        this.readMediaData(this.currentVideo);
      }
      this.goniometerModeActive = false;
      this.rulerModeActive = false;
      this.dropdownActive = false;
      this.overlayEnabled = false;
      this.goniometerDropdownValue = true;
      this.currentGoniometerAngle = undefined;
      this.currentRulerAngles = undefined;
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 20);
  }

  setLayout(layout: string) {
    this.baseLayout = layout; // 'thumb-bottom';
    this.playerClass = this.baseLayout;
    if (layout != 'fullscreen') {
      this.isFullscreen = false;
    }
  }

  setPlaybackRate(rate: number): void {
    if (this.media && this.data) {
      this.media.playbackRate = rate;
    }
  }

  selectNextVideo(): void {
    if (this.trackService.getTracks()) {
      const _currentTime = this._playbackControl.playbackTime.value;
      const curId = this.currentVideo.dataUrl;
      this.currentVideo = this.trackService.getNextTrack(this.currentVideo);
      this.readMediaData(this.currentVideo);
      if (this._playbackControl.isPlaying()) {
        const newTime = this._playbackControl.jumpToTimeBasedOnTimeJump(_currentTime); // pauses and seeks
        this._playbackControl.togglePlayPause(); // restarts the video
      } else {
        const newTime = this._playbackControl.jumpToTimeBasedOnTimeJump(_currentTime); // pauses (although already paused in this else) and seek
      }
      this.tileChanged.emit({ oldId: curId, newId: this.currentVideo.dataUrl });
    }
  }

  selectNextVideoByClipId(): void {
    if (this.trackService.getTracksById(this.trialId)) {
      const _currentTime = this.media.currentTime;
      const curId = this.currentVideo.dataUrl;
      this.currentVideo = this.trackService.getNextTrackById(this.currentVideo, this.trialId);
      this.readMediaData(this.currentVideo);
      if (this._playbackControl.isPlaying()) {
        const newTime = this._playbackControl.jumpToTimeBasedOnTimeJump(_currentTime); // pauses and seeks
        this._playbackControl.togglePlayPause(); // restarts the video
      } else {
        const newTime = this._playbackControl.jumpToTimeBasedOnTimeJump(_currentTime); // pauses (although already paused in this else) and seek
      }
      this.tileChanged.emit({ oldId: curId, newId: this.currentVideo.dataUrl });
    }
  }

  public createVideo(data: MediaData, ontimeupdate?: any) {

    this.overlayConfiguration.videoName = data.originalFileName;

    this.media = this.videoEl.nativeElement;

    if (this._showControls) {
      this.showControls();
    }

    this.containerEl.nativeElement.style.visibility = '';

    this.readMediaData(data);

    if (ontimeupdate != undefined) {
      this.media.ontimeupdate = ontimeupdate;
    }

    /*if(coordinates == CoordinatesType.zUp)
    {
      this.videoScreen.position.set(-3, -7, 3);
      this.videoScreen.rotation.x = Math.PI/2;
      this.videoScreen.rotation.y = Math.PI;
    }
    else*/
    this.video = this.media as HTMLVideoElement;
  }

  public isVideoAvailable() {
    return this.video != undefined;
  }

  public isAudioAvailable() {
    return this.audio != undefined || (this.data && this.data.audio);
  }

  /**
   * Produce a set of CSS properties for a particular video rotation
   * @returns CSS properties for a specific video rotation
   */
  public getRotationStyle(): VideoRotationCSSProps {
    return this.mediaPlayerService.getRotationStyle(this.rotationOptions, this.maxWidth);
  }

  /**
   * Handle errors while trying to load a video source by displaying a message.
   */
  public handleVideoLoadingError(): void {
    console.error('video loading failed!');
    this.error = true;
    this.video.poster = '';
  }

  public canCycleVideos(): boolean {
    return this.hasMultipleTracks() && (this.nextVideoEnabled || this.isFullscreen);
  }

  public hasMultipleTracks(): boolean {
    return this.trackService.getTracks()?.length > 1;
  }

  public setBodyPoseBoolean(hasBodyPose: boolean): void {
    this.videoHasBodyPose = hasBodyPose;
  }

  public setPlaybackControl(videoError: boolean, playbackMode: PlaybackMode, service?: PlaybackControlService): void {

    if (service) {
      this._playbackControl = service;
    }

    if (!this._playbackControl) {
      return;
    }

    this.subs.forEach(sub => sub.unsubscribe());

    this.subs.push(this._playbackControl.playbackTime.subscribe(time => {
      if (!this.media || videoError) {
        return;
      }
      // Calculate the adjusted time based on the current tick + any offsets we have.
      // If less than 0, it means that there are charts that have data collected before the video
      // starts. In this case, simply set the video to a still frame at t0.
      // @TODO: add some form of UI to give a visual cue for still frames
      // @see #2324

      const tOffsetVideoSync = this.leftRightService.tOffsetVideoSync[this.currentVideo?.trialNameInReport] !== undefined ? this.leftRightService.tOffsetVideoSync[this.currentVideo.trialNameInReport] : 0;
      const videoTime = time + this.timeOffset + tOffsetVideoSync - this.trialChartsService.playbarOffset;
      const currentTime = Math.max(videoTime, 0);

      const delta = time - this.lastTime;
      if (currentTime <= 0 && !this.media.paused) {
        this.media.pause();
      }
      // if the current timebar time is at the end of the video part, or later
      // we set the time at the very end of the video and pause the playback
      if (videoTime >= this.media.duration) {
        this.media.currentTime = this.media.duration;
        this.media.pause();
      }

      if (currentTime > 0 && (playbackMode == PlaybackMode.Playing) && this.media.paused && videoTime < this.media.duration) {
        this.media.currentTime = currentTime;
        this.media.play();
      }

      if (this.media && (delta < 0 || this.media.paused || currentTime < 0) && videoTime < this.media.duration) {
        this.media.currentTime = currentTime;
      }
      this.lastTime = time;
    }));

    this.subs.push(this._playbackControl.mode.subscribe(mode => {
      playbackMode = mode;
      if (this.media) {
        switch (mode) {
          case PlaybackMode.Paused:
            if (!this.media.paused) {
              this.media.pause();
              this.media.currentTime = this._playbackControl.playbackTime.value;
            }
            break;
          case PlaybackMode.Seeking:
            if (!this.media.paused) {
              this.media.pause();
            }
            break;
          // case PlaybackMode.Playing:
          // default:
          //  if(this.media.paused)
          //    this.media.play();
        }
      }
    }));
  }

}
