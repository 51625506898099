import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartToggleService, LeftRightToggleStatus } from 'app/shared/services/chart-toggle/chart-toggle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-left-right-switch',
  templateUrl: './left-right-switch.component.html',
  styleUrls: ['./left-right-switch.component.scss'],
})
export class LeftRightSwitchComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  private subs: Subscription[] = [];

  @Input() startingValues: LeftRightToggleStatus = {left: true, right: true};

  constructor (
    private formBuilder: FormBuilder,
    protected readonly chartToggleService: ChartToggleService,
  ) {}


  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      switchLeft: [this.startingValues.left],
      switchRight: [this.startingValues.right],
    });

    this.subs.push(this.formGroup.valueChanges.subscribe(() => {
      this.chartToggleService.setLeftRightToggleStatus({left: this.formGroup.get('switchLeft').value, right: this.formGroup.get('switchRight').value});
    }));

    this.subs.push(this.chartToggleService.leftRightToggleStatus.subscribe((status) => {
      if (status) {
        this.formGroup.patchValue({'switchLeft': status.left, 'switchRight': status.right}, {onlySelf: true, emitEvent: false});
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
