import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription ,  Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';

@Component({
  selector: 'app-view-counter',
  templateUrl: './view-counter.component.html',
  styleUrls: ['./view-counter.component.scss']
})
export class ViewCounterComponent implements OnInit, OnDestroy {

  @Input()
  set clipId(id: string) {
    this._clipId.next(id);
  }

  private _clipId = new Subject<string>();

  public views: number;
  public downloads: number;
  public allowDownload: boolean;

  private subs: Subscription[] = [];
  private query: QueryRef<any>;

  constructor(
    private apollo: Apollo
  ) {
    this.subs.push(this._clipId.pipe(
      switchMap(clipId =>
        this.apollo.watchQuery<any>({
          query: gql`
          query getViews($clipId: ID!){
                node(id: $clipId) {
                  ... on MocapClip {
                    id,
                    views,
                    downloads,
                    allowDownload
                  }
                }
              }
            `,
            variables: {
              clipId: clipId
            }
                  }).valueChanges
      )
    ).subscribe(({data}) => {
      this.views = data.node.views;
      this.allowDownload = data.node.allowDownload;
      this.downloads = data.node.downloads;
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
