import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor() { }

  /**
   * Parses a string into a JSDate using a custom format
   * @param text the datestring to parse
   * @param fmt the format to use (see Luxon)
   * @returns a JS Date (either valid or invalid)
   */
  public fromFormat(text: string, fmt: string = 'yyyy-MM-dd'): Date {
    const d: DateTime = DateTime.fromFormat(text, fmt);
    return d.toJSDate();
  }

  /**
   * Formats a JS Date into a string
   * @param jsDate the date to format
   * @param format the format to use
   * @param opts options to pass to the formatter
   * @returns a string containing the formatted date
   */
  public formatDate(jsDate: Date, format: string, opts: object = {}): string {
    return DateTime.fromJSDate(jsDate, opts).toFormat(format);
  }

  /**
   * Parses a ISO datestring into a JSDate
   * @param text
   * @param opts options to pass to the parser
   * @returns a JS Date (either valid or invalid)
   */
  public fromISO(text: string, opts: object = {}): Date {
    return DateTime.fromISO(text, opts).toJSDate();
  }
}
