import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licenseTerms'
})
export class LicenseTermsPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'CC_BY':
        return 'https://creativecommons.org/licenses/by/4.0/legalcode';

      case 'CC_BY_ND':
        return 'https://creativecommons.org/licenses/by-nd/4.0/legalcode';

      case 'CC_BY_SA':
        return 'https://creativecommons.org/licenses/by-sa/4.0/legalcode';

      case 'CC_BY_NC':
        return 'https://creativecommons.org/licenses/by-nc/4.0/legalcode';

      case 'CC_BY_NC_ND':
        return 'https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode';

      case 'CC_BY_NC_SA':
        return 'https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode';

      default:
        return null;
    }
  }

}
