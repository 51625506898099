import { HttpClient } from "@angular/common/http";
import { inject, Injectable, Injector, OnDestroy } from "@angular/core";
import { Apollo } from "apollo-angular";
import { AdditionalDataService } from "app/core/additional-data.service";
import { ClipUpdateService } from "app/core/clip-update.service";
import { GlobalPlaybackControlService } from "app/core/playback-controls/global-playback-control.service";
import { PlaybackControlService } from "app/core/playback-controls/playback-control.service";
import { WindowService } from "app/core/window.service";
import { ClipLoaderService } from "app/moveshelf-3dplayer/clip-loader/clip-loader.service";
import { EmgReferenceBarsService } from "app/shared/chart/emg-overlay/emg-reference-bars.service";
import { LeftRightService } from "app/shared/left-right/left-right.service";
import { MultiChartDataService } from "app/shared/multi-chart/multi-chart-data.service";
import { TrialChartsService } from "app/shared/multi-chart/trial-charts.service";
import { VideoForceOverlayService } from "app/shared/multi-media-player/video-force-overlay/video-force-overlay.service";
import { BackendService } from "app/shared/services/backend/backend.service";
import { ChartToggleService } from "app/shared/services/chart-toggle/chart-toggle.service";
import { ColorService } from "app/shared/services/color-service/color-service.service";
import { ConditionsService } from "../conditions.service";
import { SessionService } from "../patient-view/create-session/session.service";
import { SubjectContextService } from "../subject-context/subject-context.service";
import { TrialTemplateService } from "../trial-template.service";
import { ReportDataService } from "./report-data.service";

@Injectable()
export class ReportLoadDataService extends ReportDataService implements OnDestroy {
  private injector = inject(Injector);

  constructor(
    protected trialChartsService: TrialChartsService,
    protected http: HttpClient,
    protected readonly windowService: WindowService,
    protected readonly trialTemplateService: TrialTemplateService,
    protected emgReferenceBarsService: EmgReferenceBarsService,
    protected conditionsService: ConditionsService,
    protected readonly sessionService: SessionService,
    public leftRightService: LeftRightService,
    protected apollo: Apollo,
    protected colorService: ColorService,
    protected chartToggleService: ChartToggleService,
    protected multiChartDataService: MultiChartDataService,
    protected clipLoaderService: ClipLoaderService,
    protected subjectService: SubjectContextService,
    protected readonly backendService: BackendService,
    protected readonly additionalDataService: AdditionalDataService,
    public playbackControlMaster: PlaybackControlService,
    protected clipUpdateService: ClipUpdateService,
    protected videoForceOverlayService: VideoForceOverlayService,
    protected globalPlaybackControlService: GlobalPlaybackControlService,
  ) {
    super(
      trialChartsService,
      http,
      windowService,
      trialTemplateService,
      emgReferenceBarsService,
      conditionsService,
      sessionService,
      leftRightService,
      apollo,
      colorService,
      chartToggleService,
      multiChartDataService,
      clipLoaderService,
      subjectService,
      backendService,
      additionalDataService,
      playbackControlMaster,
      clipUpdateService
    );
    const injector = Injector.create({ providers: [TrialChartsService], parent: this.injector})
    this.trialChartsService = injector.get(TrialChartsService);
  }

  public ngOnDestroy(): void {
    // destroy the dynamically created service
    this.trialChartsService.ngOnDestroy();
  }
}
