<div *ngIf="videoTracks?.length > 0" class="ml-2">
  <h5 class="clickable-success" (click)="showVideo = !showVideo">Video ({{videoTracks.length}}) <i [class]="showVideo ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></h5>
  <div *ngIf="showVideo" class="mx-1 mb-3">
    <div class="row" style="width: 100%;" (window:resize)="updateRotatedMediaPlayers()">
      <div *ngFor="let t of videoTracks" class="col mx-2 px-0" style="outline: none; max-width: 500px; background-color: #111;">
          <div
            *ngIf="t.uploadStatus !== 'Complete'"
            class="processing"
          >Video processing, please try again in a few minutes.</div>
          <app-media-player
            *ngIf="t.uploadStatus === 'Complete'"
            [tracks]="[t]"
            [playbackControl]="playbackControl"
            [playbackSpeed]="playbackSpeed"
            [layout]="'basic'"
            [controls]="hideControls"
            [maxHeight]="videosMaxHeight"
            [nextVideoEnabled]="this.nextVideoEnabled"
            [currentTrialName]="currentTrialName"
            style="outline: none"
            (durationEvent)="newDurationValue($event)"
            (renderedEvent)="updateRotatedMediaPlayers()"
            #mediaPlayers
          ></app-media-player>
      </div>
    </div>
  </div>
</div>
