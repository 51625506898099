import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlaybackControlService } from './playback-control.service';

@Injectable()
export class GlobalPlaybackControlService {

  public playbackControl: BehaviorSubject<PlaybackControlService>;
  public playbackSpeed: BehaviorSubject<any>;
  public controlsFullscreen: BehaviorSubject<boolean>;

  constructor() {
    this.playbackControl = new BehaviorSubject<any>(undefined);
    this.playbackSpeed = new BehaviorSubject<any >(1);
    this.controlsFullscreen = new BehaviorSubject<boolean>(false);
  }

  public setPlaybackControl(p: PlaybackControlService) {
    this.playbackControl.next(p);
  }

  public setPlaybackSpeed(v: number) {
    this.playbackSpeed.next(v);
  }

  public getPlaybackControl() {
    return this.playbackControl.value;
  }

}
