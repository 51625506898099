import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Moveshelf3dPlayerModule } from '../moveshelf-3dplayer/moveshelf-3dplayer.module';
import { SharedModule } from '../shared/shared.module';
import { AdditionalDataService } from './additional-data.service';
import { AuthCallbackComponent } from './auth-callback.component';
import { AuthService } from './auth.service';
import { ClipOptionsService } from './clip-options.service';
import { ClipUpdateService } from './clip-update.service';
import { ObservableFileReaderService } from './clip-upload/observable-file-reader.service';
import { ClipUploaderComponent } from './clip-uploader.component';
import { ClipUploaderService } from './clip-uploader.service';
import { CommentUpdateService } from './comment-update.service';
import { CoreRoutingModule } from './core-routing.module';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { MetaControlService } from './meta-control.service';
import { PasswordResetFormComponent } from './password-reset-form.component';
import { GlobalPlaybackControlService } from './playback-controls/global-playback-control.service';
import { PlaybackControlService } from './playback-controls/playback-control.service';
import { PoseComparisonService } from './pose-comparison.service';
import { RedirectorService } from './redirector.service';
import { ResumableUploadService } from './resumable-upload.service';
import { SelectionService } from './selection.service';
import { SignUpComponent } from './sign-up.component';
import { SvgIconCacheService } from './svg-icon-cache.service';
import { TenantDialogComponent } from './tenant-dialog/tenant-dialog.component';
import { ThreeJsRendererService } from './three-js-renderer.service';
import { TimecodeService } from './timecode.service';
import { UiControlService } from './ui-control.service';
import { UploadBannerComponent } from './upload-banner.component';
import { UserUpdateService } from './user-update.service';
import { UsernameValidatorService } from './username-validator.service';
import { WebvrService } from './webvr.service';

@NgModule({ declarations: [
        LoginDialogComponent,
        SignUpComponent,
        AuthCallbackComponent,
        PasswordResetFormComponent,
        UploadBannerComponent,
        ClipUploaderComponent,
        TenantDialogComponent
    ],
    exports: [
        LoginDialogComponent,
        SignUpComponent,
        UploadBannerComponent,
        ClipUploaderComponent,
        TenantDialogComponent
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreRoutingModule,
        ModalModule,
        TabsModule,
        ProgressbarModule,
        UiSwitchModule,
        SharedModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        Moveshelf3dPlayerModule], providers: [
        AuthService,
        GlobalPlaybackControlService,
        UiControlService,
        ClipUploaderService,
        ThreeJsRendererService,
        UserUpdateService,
        ClipUpdateService,
        CommentUpdateService,
        ObservableFileReaderService,
        UsernameValidatorService,
        MetaControlService,
        SvgIconCacheService,
        WebvrService,
        ResumableUploadService,
        RedirectorService,
        PoseComparisonService,
        ClipOptionsService,
        SelectionService,
        AdditionalDataService,
        PlaybackControlService,
        TimecodeService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
