<ng-template #modalTemplate>
  <div class="modal-content">
    <div class="modal-body">
      <!-- <angular-cropper #cropper [cropperOptions]="cropperConfig" [imageUrl]="image"></angular-cropper> -->
      <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1"
          [resizeToWidth]="256"
          output="base64"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (loadImageFailed)="loadImageFailed()" #cropper></image-cropper>
      <!-- <img [src]="croppedImage" width="100%"/> -->
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
      <button class="btn btn-primary" (click)="save(cropper)" autofocus>Save</button>
    </div>
  </div>
</ng-template>

