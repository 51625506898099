import { Injectable } from '@angular/core';
import { Timecode } from 'timecode';
import { TimecodeFormatterPipe } from '../shared/timecode-formatter.pipe';


@Injectable()
export class TimecodeService {

  private startingTimecode: Timecode | null;
  private framerate: string;
  private baseTimecode: string;
  private formatter = new TimecodeFormatterPipe();

  // Service to be removed by https://gitlab.com/moveshelf/polyb/-/issues/59

  public set clipId(clipId: string) {
    this.framerate = null;
    this.baseTimecode = null;
  }

  public isBaseTimecodeSet(): boolean {
    return !!this.baseTimecode;
  }
  public getTimecode(timeOffset: number): string {
    return this.formatter.transform(timeOffset, this.baseTimecode, this.framerate);
  }

  public getFramerate() {
    if (this.framerate)
      return TimecodeFormatterPipe.convertFramerate(this.framerate);
    else
      return -1;
  }

  public getDeltaTime(timecode: string): number {

    const framerate = TimecodeFormatterPipe.convertFramerate(this.framerate);
    const timecode1 = Timecode.init({
      framerate: framerate,
      timecode: timecode,
      drop_frame: framerate % 1 !== 0
    });

    const timecode2 = Timecode.init({
      framerate: framerate,
      timecode: this.baseTimecode,
      drop_frame: framerate % 1 !== 0
    });

    timecode1.subtract(timecode2);
    timecode1.timecodeToFrameNumber();
    return timecode1.frame_count / framerate;
  }
}

