<div id="chartContainer" [class]="playerClass">
  <app-averages-switch *ngIf="isFullscreen && this.echartsService.getChartMode() === 'report' && this.canToggleConditionAverages()" class="left-right-togglers"></app-averages-switch>
  <app-left-right-switch *ngIf="isFullscreen" class="left-right-togglers"></app-left-right-switch>
  <app-playhead
  *ngIf="this.playhead"
  [playheads]="this.playheads"
  [top]="this.playheadTop"
  [height]="this.playheadHeight"
  [width]="this.playheadCanvasWidth"
  ></app-playhead>
  <app-reference-canvas
    *ngIf="this.referenceBars"
    [referenceBars]="this.referenceBars"
    [top]="this.playheadTop"
    [height]="this.playheadHeight"
    [width]="this.playheadCanvasWidth"
  ></app-reference-canvas>

  <div echarts class="h-100" [options]="echartOption" (chartInit)="setEchartsInstance($event)" (chartClick)="handleLineClick($event)"></div>
  <div id="clickerContainer">
    <div *ngIf="clickers && leftRightService.enabled" class="leftRightSelecters"
      [class.showSelected]="leftRightService.isTileSelected(chartsInput)">
      <i class="clickable-info fa fa-caret-square-o-left"
        [class.text-success]="leftRightService.isTileSelectedInColumn(chartsInput, leftRightService.getLeftTiles())"
        (click)="leftRightService.selectLeft(chartsInput)" tooltip="Show on the left in split screen"></i>
      <i class="clickable-info fa fa-caret-square-o-right"
        [class.text-success]="leftRightService.isTileSelectedInColumn(chartsInput, leftRightService.getRightTiles())"
        (click)="leftRightService.selectRight(chartsInput)" tooltip="Show on the right in split screen"></i>
      <app-left-right-maximize></app-left-right-maximize>
    </div>
    <!-- Note, we currently use "fa-arrows-v" with/without coloring to indicate the scale to fit. If we have updated icons available, we need to update this, see https://gitlab.com/moveshelf/mvp/-/issues/2154, and commented code-->
    <div class="scaleytofit" [ngClass]="{'always-visible-icons': isFullscreen || (splitView && !multiChartSplitScreen)}">
      <i *ngIf="!echartsService.isScaleYToFit" class="clickable-info fa fa-arrows-v"(click)="scaleYToFit()" tooltip="Scale Y axis to fit data" placement="right"></i>
      <i *ngIf="echartsService.isScaleYToFit && echartsService.hasYLimits" class="clickable-info fa fa-arrows-v selected" (click)="scaleYToFit()" tooltip="Scale Y axis to fixed limits" placement="right"></i>
    </div>
    <!--<div class="scaleytofit">
      <i class="clickable-info fa" [class.fa-arrow-down-up-across-line]="echartsService.isScaleYToFit && echartsService.hasYLimits" [class.fa-arrow-down-up-lock]="!echartsService.isScaleYToFit" (click)="scaleYToFit()" tooltip="Scale y-axis to fit data"></i>
    </div>-->
    <div *ngIf="clickers" (click)="resizeChart()" [ngClass]="{'always-visible-icons': isFullscreen}">
      <i class="resizer clickable-info fa" [class.fa-compress]="isFullscreen"
      [class.fa-expand]="!isFullscreen" (click)="toggleFullscreen()"></i>
    </div>
  </div>
  <div *ngIf="echartsService.isEmgConsistency && echartsLegendService.showEmgConsistencyRawRmsToggle" class="d-flex" [formGroup]="formGroup"
        [class]="isFullscreen ? 'raw-rms-togglers' : splitView ? multiChartSplitScreen ? 'toggle-raw-rms-multichart-spliscreen' : 'toggle-raw-rms-splitscreen' : ' flex-column scale-icons'">
    <div [class]="splitView || isFullscreen ? '' : 'toggle-raw'">
      <app-checkbox-icon *ngIf="echartsService.isEmgConsistency && echartsLegendService.showEmgConsistencyRawRmsToggle"
        [label]="'Raw'" [colorClass]="'azure'" [tooltip]="'Toggle raw data'" [control]="formGroup.controls['Raw']">
      </app-checkbox-icon>
    </div>
    <div [class]="splitView || isFullscreen ? '' : 'toggle-rms'" [ngClass]="{'toggle-rms-multichart-spliscreen': multiChartSplitScreen}">
      <app-checkbox-icon *ngIf="echartsService.isEmgConsistency && echartsLegendService.showEmgConsistencyRawRmsToggle"
        [label]="'RMS'" [colorClass]="'azure'" [tooltip]="'Toggle rms data'" [control]="formGroup.controls['Rms']">
      </app-checkbox-icon>
    </div>
  </div>
</div>
