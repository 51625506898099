import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

export enum VideoEvents {
  OPEN_SPLIT_VIEW,
  CLOSE_SPLIT_VIEW,
}

@Injectable({
  providedIn: "root"
})
export class VideoEventsService {

  private readonly $events = new Subject<VideoEvents>();

  public emit(event: VideoEvents): void {
    this.$events.next(event);
  }

  public getEventsObservable(): Observable<VideoEvents> {
    return this.$events.asObservable();
  }

}
