import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDndFile]'
})
export class DndFileDirective {
  @Input()
  public accept: string = '';

  @Input()
  public dragOverClass: string = 'file-over';

  @Input()
  public enabled: boolean = true;

  @Output()
  public acceptedFiles: EventEmitter<File[]> = new EventEmitter();

  @Output()
  public rejectedFiles: EventEmitter<File[]> = new EventEmitter();

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    if (this.enabled) {
      evt.preventDefault();
      this.renderer.addClass(this.el.nativeElement, this.dragOverClass);
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt) {
    if (this.enabled) {
      evt.preventDefault();
      this.renderer.removeClass(this.el.nativeElement, this.dragOverClass);
    }
  }

  private traverseFileTree(files, item, path?: string): Promise<any> {

    return new Promise ( (resolve, reject) => {
    path = path || "";
    if (item.isFile) {
      // Get file
      item.file((file) => {
        file.path = path;
        files.push(file);
        //console.log("File:", path + file.name);
        resolve(files);
      });
    } else if (item.isDirectory) {
      // Get folder contents
      const dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        Promise.all(entries.map( (entry) => this.traverseFileTree(files, entry, path + item.name + "/") )).then ( () => {
          if (entries.length === 0) {
            resolve(files);
          } else {
            this.readEntries(dirReader, files, item, path + item.name + "/", resolve);
          }
        } );
      });
    }
  });
}

  private readEntries(reader, files, item, path, resolve) {
    reader.readEntries((entries) => {
      Promise.all(entries.map( (entry) => this.traverseFileTree(files, entry, path) )).then ( () => {
        if (entries.length === 0) {
          resolve(files);
        } else {
          this.readEntries(reader, files, item, path, resolve);
        }
      } );
    });
  }

  processFilesList(files: any) {
      const acceptedExtensions = this.accept.split(',');

      if (files.length > 0) {
        const validFiles: Array<File> = [];
        const invalidFiles: Array<File> = [];

        for (let i = 0, len = files.length; i < len; i++) {
          const file = files[i];
          let accepted = false;
          for (let j = 0, len2 = acceptedExtensions.length; j < len2; j++) {
            if (file.name.toLowerCase().endsWith(acceptedExtensions[j])) {
              validFiles.push(file);
              accepted = true;
              break;
            }
          }
          if (!accepted)
            invalidFiles.push(file);
          }

        this.acceptedFiles.emit(validFiles);
        this.rejectedFiles.emit(invalidFiles);
      }
  }

  @HostListener('drop', ['$event'])
  onDrop(evt) {
    if (this.enabled) {
      evt.preventDefault();
      this.renderer.removeClass(this.el.nativeElement, this.dragOverClass);

      const droppedItems = evt.dataTransfer.items;
      if (droppedItems && droppedItems.length > 0) {
        for (const item of droppedItems) {
            const files = [];
            this.traverseFileTree(files, item.webkitGetAsEntry(), "/").then( () => {
            this.processFilesList(files);
          });
        }
      } else {
        const files: FileList = evt.dataTransfer.files;
        this.processFilesList(files);
      }
    }
  }
}
