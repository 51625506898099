import { Injectable } from '@angular/core';
import { AdditionalDataService } from 'app/core/additional-data.service';
import { ClipUploaderService, CreatedClipWithData } from 'app/core/clip-uploader.service';
import { ConditionsService } from 'app/projects/conditions.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { DirectoryOptions } from 'app/projects/project-info/project-info-data.service';
import { DirectoryUploadService, FileWrapper } from './directory-upload.service';
import { Trial } from "./vicon-directory.service";

const videoFileRegex = /\.avi/i;
const dataFileRegex = /\.mox/i;

@Injectable({
  providedIn: 'root'
})
export class MoxDirectoryService extends DirectoryUploadService {
  private files: FileWrapper[];
  private options: DirectoryOptions = {
    filesFilter: undefined,
    requireC3D: false,
    requireData: false,
    dataFileFormat: undefined,
  };

  constructor(
    private readonly clipUploader: ClipUploaderService,
    private readonly additionalData: AdditionalDataService,
    private conditionsService: ConditionsService
  ) {
    super();
  }

  public getProviderName(): string {
    return 'MOX';
  }

  /**
   * Sets the files for the service. These files are all the files of the
   * directory that was dropped.
   * @param newFiles - An array of files to set.
   */
  public setFiles(newFiles: File[]): void {
    this.files = this.fileWrap(newFiles);
  }

  /**
   * Not necessary for MOX but implementation is required as we are extending abstract class DirectoryUploadService.
   * @param options
   * @returns
   */
  public setOptions(options: DirectoryOptions): void {
    // set this.options
    this.options = options;
    return;
  }

  public getOptions(): DirectoryOptions {
    return this.options;
  }

  /**
   * Returns a list of trials. A MOX directory import can have multiple trials. There is no information about conditions,
   * so they are grouped together into a hard-coded "MOX" condition.
   * @returns a list of trials
   */
  public async getTrials(): Promise<Trial[]> {
    const trials: Trial[] = [];
    const conditions = await this.groupFiles();
    for (const condition_name in conditions) {
      const condition = conditions[condition_name];
      for (const trial_name in condition) {
        const trial = condition[trial_name];
        trials.push({
          name: trial_name,
          notes: '',
          condition: condition_name,
          forcePlates: '',
          plane: '',
          side: '',
          hasCharts: trial.some((file) => dataFileRegex.test(file.name)),
          hasVideo: trial.some((file) => videoFileRegex.test(file.name)),
          date: new Date(Date.UTC(2000, 1, 1, 0, 0, 0)),
        });
      }
    }
    return trials;
  }

  /**
   * Groups the files by condition and trials according to the MOX requirements.
   * @returns
   */
  private async groupFiles() {
    // Step 1: Filter files to keep only the relevant ones
    const filteredFiles = this.files.filter((file) => {
      return file.name.endsWith(this.options.dataFileFormat) ||
        this.options.filesFilter.some((filter) => file.name.endsWith(filter));
    });

    // Step 2: Group files by condition
    const conditions = filteredFiles.reduce((conditions, file) => {
      let condition_path = file.path;
      condition_path = condition_path.replace(/^\/|\/$/g, ''); // removes leading or trailing slashes
      const subpaths = condition_path.split('/');
      const condition_name = subpaths[subpaths.length - 1];
      if (!conditions[condition_name]) {
        conditions[condition_name] = [];
      }
      conditions[condition_name].push(file);
      return conditions;
    }, {});

    /**
     * Step 3: For each condition, group files by trial.
     * Trials are identified by mox files: a trial for each mox file.
     */
    for (const condition_name of Object.keys(conditions)) {
      const condition_files = conditions[condition_name];
      // get .mox files
      const moxFiles = condition_files.filter(
        file => file.name.endsWith(this.options.dataFileFormat)
      );
      // For each mox file in moxFiles, read it and parse it (it's an XML file).
      const condition_trials = {};
      for (const moxFile of moxFiles) {
        // get trial name (mox filename without the extention)
        let trialName = moxFile.name;
        trialName = trialName.substring(0, trialName.lastIndexOf('.')) || trialName;

        condition_trials[trialName] = [moxFile];  // include mox file itself in the files.
        const parsedXML = new DOMParser().parseFromString(
          await moxFile.file.text(), 'application/xml'
        );
        if (parsedXML.getElementsByTagName("parsererror").length > 0) {
          console.error("Error parsing XML");
          return;
        }

        let videofileElement: string;
        try {
          const videofileElements = parsedXML.getElementsByTagName("videofile");
          if (videofileElements.length != 1) {
            let warnMsg = `Unexpected number of <videofile> tags found for file '${moxFile.name}' in '${moxFile.path}': ${videofileElements.length} found. Expected 1.`;
            if (videofileElements.length == 0) {
              warnMsg += " No video files will be associated with this trial.";
            } else {
              warnMsg += " Only the first one will be used.";
            }
            console.warn(warnMsg);
          }
          // if no <videofile> tag is found, set videofileElement to ""
          if (videofileElements.length == 0) {
            videofileElement = "";
          } else {
            /**
             * get the text content of the first <videofile> tag (there should
             * be only one, but in case there are multiple we get the first)
             */
            videofileElement = videofileElements[0].textContent;
          }
        } catch (e) {
          console.error(`Error while trying to access the <videofile> tag for file '${moxFile.name}' in '${moxFile.path}'`);
          console.error(e);
          return;
        }

        /**
         * split videoFileElement by * (mox files have a tag <videofile> that
         * contains the names of the related videos separated
         * by the character *). The tag can contain an arbitrary number of video
         * files so we split it and filter out empty strings.
         */
        const videoFileNames = videofileElement.split('*')
          .filter(name => name.trim() !== '');
        // find matching videoFiles  in aviFiles
        for (const videoFileName of videoFileNames) {
          // match case insensitive
          const matchingVideoFile = condition_files.find(
            f => f.name.toLocaleLowerCase()
              .includes(videoFileName.toLocaleLowerCase())
          );
          if (matchingVideoFile) {
            condition_trials[trialName].push(matchingVideoFile);
          }
        }
      }
      conditions[condition_name] = condition_trials;
    }
    return conditions;
  }


  /**
   * Uploads the data:
   * - Creates the mocapClips to hold data for each trial
   * - Uploads data for each trial (mocapclip)
   * @param session
   */
  public async upload(session: Session): Promise<void> {
    const clipsToCreate: Promise<CreatedClipWithData>[] = [];
    for (const trial of await this.getTrials()) {
      const metadata = {
        title: trial.name,
        projectPath: `${session.projectPath}${trial.condition || 'Trials'}/`,
        description: trial.notes,
      };
      clipsToCreate.push(this.clipUploader.createClip(session.project.name, metadata).toPromise());
      // Throttle down clips creation request by allowing at least 0.5s to pass. This avoids sending
      // all requests at once.
      await this.delay(500);
    }

    try {
      const data = await this.groupFiles();
      for await (const createdClip of clipsToCreate) {
        const clip = createdClip.data.createClips.response[0].mocapClip;
        const splittedProjectPath = this.conditionsService.splitProjectPath(clip.projectPath);
        const uploads = data[splittedProjectPath.conditionName][clip.title];
        await this.additionalData.uploadMultipleAdditionalDataWithoutType(clip.id, uploads).toPromise();
      }
    } catch (clipOrDataCreationError) {
      // for..await will throw on the first clip creation exception and won't continue the process.
      // Errors on additional data creation are not currently blocking.
      console.error('Error while creating clip or data');
      console.debug('Error while creating clip or data', clipOrDataCreationError);
    }
  }

  // returns the list of files to upload
  public async getExpectedUploads(): Promise<File[]> {
    const expectedUploads: File[] = [];
    const data = await this.groupFiles();
    for (const condition_name in data) {
      const condition = data[condition_name];
      for (const trial_name in condition) {
        expectedUploads.push(...condition[trial_name]);
      }
    }
    return expectedUploads;
  }

  /**
   * Loops through current trials and generate a list of the current conditions sorted by date
   * @see #2185 for details on logic
   */
  public async getSortedConditions(): Promise<string[]> {
    const trials = await this.getTrials();
    const conditionsDates = new Map<string, Date>();

    // Map each condition with the earliest trial's date it contains
    for (const trial of trials) {
      if (!conditionsDates.get(trial.condition)) {
        conditionsDates.set(trial.condition, trial.date);
      } else if (conditionsDates.get(trial.condition) > trial.date) {
        conditionsDates.set(trial.condition, trial.date);
      } else {
        continue;
      }
    }

    // Sort the mapped condition by date and return their names only, as an array
    const sortedConditions = [...conditionsDates.entries()].sort((c1, c2) => {
      return c1[1] < c2[1] ? -1 : 1;
    });
    const sortedConditionsArray = sortedConditions.map(tuple => {
      return tuple[0];
    });

    return sortedConditionsArray;
  }
}
