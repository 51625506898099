import gql from 'graphql-tag';
import { RelayNode } from '../graphql-types/relay-node';

export const ProjectMetadataFragment = gql`
  fragment ProjectMetadata on Project {
    id,
    name,
    public,
    canView,
    unlistedAccess
  }
`;

export class ProjectMetadata extends RelayNode {
  name: string;
  public: boolean;
  canView: boolean;
  unlistedAccess: boolean;
}
