import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ClipLoader } from "./clip-loader";

@Injectable()
export class ClipLoaderService extends ClipLoader {

  constructor(
    protected apollo: Apollo,
    protected http: HttpClient,
    @Inject(PLATFORM_ID) protected platformId
  ) {
    super(apollo, http, platformId);
  }

}
