import {
  Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ClipOptionsService } from '../../core/clip-options.service';
import { TimeSelection } from '../../core/time-selection';
import { ClipPlayerInput } from '../clip-player';
import { ClipLoaderService } from './clip-loader.service';

@Component({
  template: ''
})
export abstract class AClipLoaderLogicsComponent implements OnInit, OnDestroy {

  protected _clipId: string;
  get clipId(): string { return this._clipId; }
  @Input() set clipId(clipId: string) {
    this._clipId = clipId;
    if (this.autoLoad) { this.loadClipFile(); }
  }

  @Input() allowSelectionEditing = false;
  @Input() includeEditLink = false;
  @Input() includeShareButton = false;
  @Input() previewImageUri: string;
  @Input() autoLoad: boolean = false;
  @Input() hidePreview: boolean = false;
  @Input() hideControls: boolean = false;
  @Input() loadOnClick: boolean = true;
  @Input() hideBackground: boolean = false;
  @Input() hideBranding: boolean = false;
  @Input() noCharts: boolean = false;
  @Input() noVideo: boolean = false;
  @Input() globalTimebar: boolean = false;
  @Input() autoPlay: boolean = true;

  @Input() selection: TimeSelection;
  @Output() selectionChange: EventEmitter<TimeSelection> = new EventEmitter<TimeSelection>();
  @Output() share = new EventEmitter<void>();
  @Output() animationPlayed = new EventEmitter<void>();

  @ViewChild('progressbar') bar: ElementRef;

  animationClips: ClipPlayerInput[] = []
  additionalData: any;
  loadingComplete: boolean;

  loadingClip: boolean = false;
  subs: Subscription[] = [];
  protected loader: any;

  constructor(protected optionsService: ClipOptionsService) {
    this.loader = ClipLoaderService;
  }

  ngOnInit(): void {
    if (this.autoLoad)
      this.loadClipFile();

    this.subs.push(this.optionsService.newOptionsAvailable.subscribe ( (value) => {
      this.loadingComplete = false;
      this.loadingClip = true;

      setTimeout( () => this.loadClipFile(true, value), 100);
    }));

    this.subs.push(this.optionsService.additionalClipsId.subscribe( (additionalIds) => {
      for (const id of additionalIds)
        this.addClip(id);
    }));

    /*if (this.hideControls && this.hidePreview)
      this.hideBackground = true;*/
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  clickPlayButton(): void {
    if (!this.loadOnClick)
      return;

    this.loadClipFile();
  }

  loadClipFile(updateOptions: boolean = false, forceReload: boolean = false): void {
    this.loadingComplete = false;
    this.loadingClip = true;

    this.loader.loadClipId(this._clipId, progress => this.onProgress(progress), updateOptions, forceReload).then(res => {

      if (res.customOptions && res.customOptions.meshAssetUri != undefined && res.customOptions.meshAssetUri.toLowerCase().indexOf(".glb") != -1) {
        this.loader.loadMesh(res.customOptions.meshAssetUri).then( (result) => {
          res.animation.mesh = result.mesh;
          if (res.customOptions.meshAssetUri.indexOf('ercole') == -1)
          res.animation.skeleton = result.skeleton;
          this.finalizeResult(res);
        });
      } else
        this.finalizeResult(res);
    }).catch(err => console.log(err));
  }

  finalizeResult(result: any): void {
    this.animationClips = [result];
    this.optionsService.setClipId(this._clipId);
    this.loadingClip = false;
    this.loadingComplete = true;
  }

  addClip(clipId: string): void {
    this.loader.loadClipId(clipId).then(res => {
      this.animationClips.push(res);
    }).catch(err => console.log(err));
  }

  onProgress(progressEvent: any): void {
    let perc = 0;
    let contentLength = 0;

    if (progressEvent.lengthComputable)
      contentLength = progressEvent.total;
    else
      contentLength = parseInt(progressEvent.target.getResponseHeader('x-goog-meta-original-content-length'));

    //last resort for files which don't have a length in the meta
    if (isNaN(contentLength) || contentLength == 0)
      contentLength = parseInt(progressEvent.target.getResponseHeader('content-length')) * 1.5;

    // TODO: contentLength might be 0 so division by zero exception?
    perc = progressEvent.loaded*100 / contentLength;
    if (perc > 100)
      perc = 100;

    const width = perc.toString() + "%";
    if (this.bar)
      this.bar.nativeElement.style.width = width;
  }
}
