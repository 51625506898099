<!-- <span class="btn btn-outline-success" id="create-account" role="button" (click)="showModal(signUpModal)">Sign up</span> -->

<ng-template #signUpModal>
  <div class="invite-dialog">
    <div class="modal-header">
      <h4 class="modal-title">Complete sign up</h4>
      <img height="1" width="1" style="display:none;" alt=""
        src="https://dc.ads.linkedin.com/collect/?pid=390724&conversionId=478738&fmt=gif" />
    </div>
    <div class="row mx-auto" style="width: 80%">
      <div class="d-block card col-9 col-sm-7 col-sm-6 mx-auto p-0 mt-3" style="position: relative;">
        <img class="mx-auto card-img-top" src="{{avatar.value}}"
         (click)="file.click()"> <!-- appDndFile accept=".png,.jpg" (acceptedFiles)="avatarFile = $event[0]"  -->
        <div class="btn btn-secondary btn-block btn-sm" (click)="file.click()"
          style="opacity: 0.9; position: absolute; bottom: 0px">
          Change Avatar
        </div>
        <input type="file" accept="image/*" style="display:none" (change)="avatarFile = $event"
          #file>
      </div>
    </div>
    <form [formGroup]="form" class="ms-form" (ngSubmit)="submit()" novalidate>
      <div class="modal-body container row">
        <div class="col">
          <div [class]="(completeOnly? 'd-none' : 'd-block')">
            <div class="form-group">
              <label for="email">Email:</label>
              <input class="form-control" type="email" formControlName="email" id="email_sign"
                [(ngModel)]="emailAddress" readonly autofocus />
              <div *ngIf="email.invalid" class="form-text small text-danger">
                <p *ngIf="email.errors.email">You must provide a valid email address.</p>
                <p *ngIf="email.errors.inUse">{{email.errors.inUse}}</p>
                <p *ngIf="email.errors?.pattern">Invalid email domain</p>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input class="form-control" type="password" formControlName="password" id="password" />
              <div *ngIf="password.invalid" class="form-text small text-danger">
                <p *ngIf="password.errors.required">You must provide a password.</p>
                <p *ngIf="password.errors.minlength">Your password must be at least 8 characters</p>
                <p *ngIf="password.errors.weak">{{password.errors.weak}}</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="username"> Username:</label>
            <input class="form-control" type="text" formControlName="username" id="username" />
            <div *ngIf="username.invalid" class="form-text small text-danger">
              <p *ngIf="username.errors.required">You must provide a username.</p>
              <p *ngIf="username.errors.minlength">Your username must be more than 4 characters.</p>
              <p *ngIf="username.errors.maxlength">Your pusername must be less than 32 characters.</p>
              <p *ngIf="username.errors.pattern">Your username must contain only latin letters, numbers, and
                underscores.</p>
              <p *ngIf="username.errors.userExists">Username is already taken.</p>
            </div>
          </div>
          <div class="form-group">
            <label for="displayName"> Name: </label>
            <input class="form-control" type="text" id="displayName" formControlName="displayName" />
            <div *ngIf="displayName.invalid" class="form-text small text-danger">
              <p *ngIf="displayName.errors.maxlength">Your name must be less than 32 characters.</p>
              <p *ngIf="displayName.errors.required">You must provide a name.</p>
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="termsAndConditions"
              id="termsAndConditions" />
            <label class="form-check-label">I have read and understood the terms of the Moveshelf <a target="_blank"
                class="nav-link d-inline p-0" href="https://moveshelf.com/legal/terms" target="_blank"><i>usage</i></a> and <a target="_blank"
                class="nav-link d-inline p-0" href="https://moveshelf.com/legal/privacy" target="_blank"><i>privacy</i></a> policies.</label>
            <div *ngIf="termsAndConditions.invalid" class="form-text small text-danger">
              <p *ngIf="termsAndConditions.errors.required">You must agree to the terms of service.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="ms-button btn btn-secondary" (click)="cancel()">
          Cancel
        </button>
        <button type="submit" class="ms-button btn btn-primary" [disabled]="form.invalid || signUpPending">
          Complete sign up
        </button>
      </div>
    </form>
  </div>
</ng-template>
<app-image-cropper [filename]="avatarFile" (imageDataUri)="avatar.setValue($event)"></app-image-cropper>
