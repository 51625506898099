import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DictionaryService } from './dictionary.service';
import { ProjectConfiguration } from './project-info-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectInfoService {

  /*examplePatientInfoJson = {
    "ehr-id": "4324432",
    "name": "Aaltje Paaltje",
    "date-of-birth": "1955-08-13",
    "gender": "Female",
    "diagnosys": [{
      "value": "Primary Hip OA",
      "date": "2021-03-10"
    }],
    "laterality": "Right",
    "status": "Pre-Op",
    "weight": [
      {
        "value": "85",
        "date": "2021-03-10",
        "unit": "kg"
      },
      {
        "value": "86",
        "date": "2021-01-10",
        "unit": "kg"
      }
    ],
    "height": [
      {
        "value": "180",
        "date": "2021-03-10",
        "unit": "cm"
      },
      {
        "value": "179",
        "date": "2019-01-10",
        "unit": "cm"
      }
    ],
    "notes": [{
      "value": "My notes",
      "date": "2021-03-10"
    }],
  }*/

  dictionaryDefault = {
    types: {
      "gender": [
        "Male",
        "Female"
      ],
      "diagnosis": [
        "Healthy",
        "Knee OA",
        "Hip OA"
      ],
      "procedure": [
        "TKA - Right",
        "TKA - Left",
        "THA - Right",
        "THA - Left",
        "revision TKA - Right",
        "revision TKA - Left",
        "revision THA - Right",
        "revision THA - Left",
      ],
      "laterality": [
        "Left",
        "Right",
        "Both",
      ],
      "comorbidities": [
        "Stroke",
        "Various",
      ],
      "consent": [
        "Consent for research",
        "Consent for publication",
      ]
    },
    template:
    {
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "EHR ID"
      },
      "name": {
        "type": "value",
        "valueType": "string",
        "label": "Name"
      },
      "gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender"
      },
      "date-of-birth": {
        "type": "value",
        "valueType": "date",
        "label": "Date of Birth"
      },
      "diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Diagnosis"
      },
      "laterality": {
        "type": "list",
        "valueType": "laterality",
        "label": "Laterality of complaint"
      },
      "procedure": {
        "type": "list",
        "valueType": "procedure",
        "label": "Procedure"
      },
      "weight": {
        "type": "list",
        "valueType": "measure",
        "unit": "kg",
        "label": "Body weight"
      },
      "height": {
        "type": "list",
        "valueType": "measure",
        "unit": "cm",
        "label": "Body height"
      },
      "bmi": {
        "type": "value",
        "valueType": "number",
        "label": "BMI"
      },
      "gaitspeed": {
        "type": "list",
        "valueType": "measure",
        "unit": "m/s",
        "label": "Gait speed"
      },
      "comorbidities": {
        "type": "value",
        "valueType": "comorbidities",
        "label": "Comorbidities"
      },
      "consent": {
        "type": "value",
        "valueType": "consent",
        "required": false,
        "label": "Informed consent"
      },
      "notes": {
        "type": "list",
        "valueType": "textarea",
        "label": "Notes"
      }
    }
  };

  public physicalExaminationItems = {
    "promankledorsalflexionleft": {
      "type": "list",
      "valueType": "measure",
      "unit": "deg",
      "label": "PROM Ankle Dorsiflexion left [deg]"
    },
    "promankledorsalflexionright": {
      "type": "list",
      "valueType": "measure",
      "unit": "deg",
      "label": "PROM Ankle Dorsiflexion right [deg]"
    },
    "poplitealangleleft": {
      "type": "list",
      "valueType": "measure",
      "unit": "deg",
      "label": "Popliteal Angle Left [deg]"
    },
    "poplitealangleright": {
      "type": "list",
      "valueType": "measure",
      "unit": "deg",
      "label": "Popliteal Angle Right [deg]"
    }
  };

  dictionaryMovita = {
    types: {
      "gender": [
        "Male",
        "Female"
      ],
      "diagnosis": [
        "Healthy",
        "Knee OA",
      ],
      "procedure": [
        "TKA - Right",
        "TKA - Left",
      ],
      "laterality": [
        "Left",
        "Right",
        "Both",
      ],
    },
    template:
    {
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "EHR ID"
      },
      "name": {
        "type": "value",
        "valueType": "string",
        "label": "Name"
      },
      "gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender"
      },
      "date-of-birth": {
        "type": "value",
        "valueType": "date",
        "label": "Date of Birth (yyyy-mm-dd)"
      },
      "diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Diagnosis"
      },
      "laterality": {
        "type": "list",
        "valueType": "laterality",
        "label": "Laterality of complaint"
      },
      "procedure": {
        "type": "list",
        "valueType": "procedure",
        "label": "Procedure"
      },
      "weight": {
        "type": "list",
        "valueType": "measure",
        "unit": "kg",
        "label": "Body weight"
      },
      "height": {
        "type": "list",
        "valueType": "measure",
        "unit": "cm",
        "label": "Body height"
      },
      "gaitspeed": {
        "type": "list",
        "valueType": "measure",
        "unit": "m/s",
        "label": "Gait speed"
      },
      "steps": {
        "type": "list",
        "valueType": "measure",
        "unit": "#",
        "label": "Number of steps"
      },
      "walking-duration": {
        "type": "list",
        "valueType": "measure",
        "unit": "s",
        "label": "Walking duration max"
      },
      "movement-intensity": {
        "type": "list",
        "valueType": "measure",
        "unit": "mg",
        "label": "Movement intensity walking"
      },
      "notes": {
        "type": "list",
        "valueType": "textarea",
        "label": "Notes"
      }
    }
  }

  dictionaryRPM = {
    types: {
      "gender": [
        "Male",
        "Female"
      ],
      "diagnosis": [
        "Healthy",
        "Knee OA",
      ],
      "procedure": [
        "TKA - Right",
        "TKA - Left",
      ],
      "laterality": [
        "Left",
        "Right",
        "Both",
      ],
    },
    template:
    {
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "EHR ID"
      },
      "name": {
        "type": "value",
        "valueType": "string",
        "label": "Name"
      },
      "gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender"
      },
      "date-of-birth": {
        "type": "value",
        "valueType": "date",
        "label": "Date of Birth (yyyy-mm-dd)"
      },
      "diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Diagnosis"
      },
      "laterality": {
        "type": "list",
        "valueType": "laterality",
        "label": "Laterality of complaint"
      },
      "procedure": {
        "type": "list",
        "valueType": "procedure",
        "label": "Procedure"
      },
      "weight": {
        "type": "list",
        "valueType": "measure",
        "unit": "kg",
        "label": "Body weight"
      },
      "height": {
        "type": "list",
        "valueType": "measure",
        "unit": "cm",
        "label": "Body height"
      },
      "gaitspeed": {
        "type": "list",
        "valueType": "measure",
        "unit": "m/s",
        "label": "Gait speed (lab)"
      },
      "gaitspeed-hk": {
        "type": "list",
        "valueType": "measure",
        "unit": "m/s",
        "label": "Gait speed (daily life)"
      },
      "notes": {
        "type": "list",
        "valueType": "textarea",
        "label": "Notes"
      }
    }
  }

  dictionaryCP = {
    types: {
      "gender": [
        "Male",
        "Female"
      ],
      "diagnosis": [
        "Healthy",
        "Apparent Equinus",
        "True Equinus",
        "Jump gait",
        "Crouch gait",
      ],
      "procedure": [
        "Multi-level Surgery"
      ]
    },
    template:
    {
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "EHR ID"
      },
      "name": {
        "type": "value",
        "valueType": "string",
        "label": "Name"
      },
      "date-of-birth": {
        "type": "value",
        "valueType": "date",
        "label": "Date of Birth"
      },
      "gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender"
      },
      "diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Gait pattern"
      },
      "procedure": {
        "type": "list",
        "valueType": "procedure",
        "label": "Procedure"
      },
      "weight": {
        "type": "list",
        "valueType": "measure",
        "unit": "kg",
        "label": "Body weight"
      },
      "height": {
        "type": "list",
        "valueType": "measure",
        "unit": "cm",
        "label": "Body height"
      },
      "notes": {
        "type": "list",
        "valueType": "textarea",
        "label": "Notes"
      },
      ...this.physicalExaminationItems
    },
  };

  dictionaryGaitScript = {
    types: {
      "gender": [
        "Male",
        "Female",
        "Other"
      ],
      "diagnosis": [
        "Spastic Cerebral Palsy",
        "Other"
      ],
      "localization": [
        "Unilateral - right",
        "Unilateral - left",
        "Bilateral"
      ],
      "GMFCS": [
        "GMFCS I",
        "GMFCS II",
        "GMFCS III",
        "GMFCS IV",
        "GMFCS V"
      ],
      "FMS": [
        "1: uses wheelchair",
        "2: uses a walker or frame",
        "3: uses crutches",
        "4: uses sticks (one or two)",
        "5: independent on LEVEL surfaces",
        "6: independent on ALL surfaces"
      ],
      "prerequisite": [
        "I: Stability in stance",
        "II: Foot clearance during swing",
        "III: Appropriate prepositioning during swing",
        "IV: Adequate step length",
        "V: Energy conservation"
      ],
      "assessed_leg": [
        "left",
        "right",
        "left and right"
      ],
      "spasticity": [
        "0 no spasticity",
        "1 light resistance",
        "2 catch and release",
        "3 catch and stop",
        "<5 clonus",
        ">= 5 clonus",
        "Impractical"
      ],
      "selectivity": [
        "0 no selectivity",
        "1 partially selective",
        "2 selective",
        "Impractical"
      ],
      "ortho-tibiofemoral_angle": [
        "45° exo",
        "40° exo",
        "35° exo",
        "30° exo",
        "25° exo",
        "20° exo",
        "15° exo",
        "10° exo",
        "5° exo",
        "0°",
        "5° endo",
        "10° endo",
        "15° endo",
        "Impractical"
      ],
      "ortho-leg_length_discrepancy": [
        "No",
        "Yes, R > L",
        "Yes, L > R",
        "Impractical"
      ],
      "strenght-kneeling": [
        ">= 10 sec",
        "3 - 9 sec",
        "< 3 sec",
        "Impractical"
      ],
      "strenght-squat": [
        "8 times, complete trajectory",
        "< 8 times, uncomplete trajectory",
        "< 3 times",
        "Impractical"
      ],
      "strenght-toe_stance": [
        "10 times, powerful",
        "3 - 9 times, reduced strength",
        "< 3 times, weak",
        "Impractical"
      ],
      "emg": [
        "absent",
        "present"
      ],
      "effect_on_gait": [
        "none",
        "mild",
        "moderate",
        "marked"
      ]
    },
    template: {
      "last-name": {
        "type": "value",
        "valueType": "string",
        "label": "Last name",
        "placeholder": "Last name"
      },
      "first-name": {
        "type": "value",
        "valueType": "string",
        "label": "First name",
        "placeholder": "First name"
      },
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "Patient number"
      },
      "gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender"
      },
      "date-of-birth": {
        "type": "date",
        "valueType": "date",
        "label": "Date of birth"
      },
      "diagnosis": {
        "type": "value",
        "valueType": "diagnosis",
        "label": "Diagnosis"
      },
      "laterality": {
        "type": "value",
        "valueType": "localization",
        "label": "Localization"
      },
      "GMFCS": {
        "type": "value",
        "valueType": "GMFCS",
        "label": "GMFCS"
      },
      "FMS-5": {
        "type": "value",
        "valueType": "FMS",
        "label": "FMS 5m"
      },
      "FMS-50": {
        "type": "value",
        "valueType": "FMS",
        "label": "FMS 50m"
      },
      "FMS-500": {
        "type": "value",
        "valueType": "FMS",
        "label": "FMS 500m"
      },
    },
    sessionMetadataTabs: [
      {
        name: "Session info",
        template: {
          "date-of-gait-analysis": {
            "type": "date",
            "valueType": "date",
            "label": "Date of gait analysis"
          },
          "assessed-leg": {
            "type": "value",
            "valueType": "assessed_leg",
            "label": "Assessed leg"
          },
          "request-for-help": {
            "type": "value",
            "valueType": "string",
            "label": "Main request for help"
          },
          "prerequisites-of-gait": {
            "type": "value",
            "valueType": "prerequisite",
            "label": "Prerequisite(s) of gait"
          }
        }
      },
      {
        name: "Physical exam",
        template: {
          "hip-flexion": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip flexion",
            "minRequirementStart": [50, 139],
            "norm": [140, 180]
          },
          "hip-extension_prone": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip extension prone",
            "minimumRequirement": [5, 9],
            "norm": [10, 45]
          },
          "hip-abduction_0": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip abduction 0°",
            "minimumRequirement": [10, 44],
            "norm": [45, 90]
          },
          "hip-abduction_90": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip abduction 90°",
            "minimumRequirement": [],
            "norm": [45, 90]
          },
          "hip-endorotation": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip endorotation",
            "minimumRequirement": [10, 44],
            "norm": [45, 90]
          },
          "hip-exorotation": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Hip exorotation",
            "minimumRequirement": [10, 44],
            "norm": [45, 90]
          },
          "knee-flexion": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee flexion",
            "minimumRequirement": [60, 129],
            "norm": [130, 180]
          },
          "knee-extension": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee extension",
            "minimumRequirement": [-5, -1],
            "norm": [0, 45]
          },
          "knee-flexion_prone": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee flexion prone (RF)",
            "minimumRequirement": [55, 109],
            "norm": [110, 160]
          },
          "knee-popliteal_angle": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Knee popliteal angle",
            "minimumRequirement": [31, 70],
            "norm": [0, 30]
          },
          "ankle-dorsal_flexion_90": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Ankle dorsal flexion 90°",
            "minimumRequirement": [],
            "norm": [20, 45]
          },
          "ankle-dorsal_flexion_0": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Ankle dorsal flexion 0°",
            "minimumRequirement": [10, 19],
            "norm": [20, 45]
          },
          "ortho-femoral_anteversion": {
            "type": "value",
            "valueType": "measure",
            "hasContext": true,
            "label": "Femoral anteversion (orthopedic exam)",
            "minimumRequirement": [],
            "norm": [10, 65]
          },
          "ortho-tibiofemoral_angle": {
            "type": "value",
            "valueType": "ortho-tibiofemoral_angle",
            "hasContext": true,
            "label": "Tibiofemoral angle (orthopedic exam)",
            "minimumRequirement": [],
            "norm": [2]
          },
          "ortho-leg_length_discrepancy": {
            "type": "value",
            "valueType": "ortho-leg_length_discrepancy",
            "hasContext": false,
            "showInline": true,
            "label": "Leg length discrepancy (orthopedic exam)",
            "minimumRequirement": [],
            "norm": [0]
          },
          "spasticity-hip_adductor": {
            "type": "value",
            "valueType": "spasticity",
            "hasContext": true,
            "label": "Spasticity hip adductor",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "spasticity-hamstring": {
            "type": "value",
            "valueType": "spasticity",
            "hasContext": true,
            "label": "Spasticity hamstring",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "spasticity-rectus_femoris": {
            "type": "value",
            "valueType": "spasticity",
            "hasContext": true,
            "label": "Spasticity rectus femoris",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "spasticity-soleus": {
            "type": "value",
            "valueType": "spasticity",
            "hasContext": true,
            "label": "Spasticity soleus",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "spasticity-gastrocnemius": {
            "type": "value",
            "valueType": "spasticity",
            "hasContext": true,
            "label": "Spasticity gastrocnemius",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "selectivity-hip_flexion": {
            "type": "value",
            "valueType": "selectivity",
            "hasContext": true,
            "label": "Selectivity hip flexor",
            "minimumRequirement": [1],
            "norm": [2]
          },
          "selectivity-knee_extension": {
            "type": "value",
            "valueType": "selectivity",
            "hasContext": true,
            "label": "Selectivity knee extension",
            "minimumRequirement": [1],
            "norm": [2]
          },
          "selectivity-ankle_dorsal_flexion": {
            "type": "value",
            "valueType": "selectivity",
            "hasContext": true,
            "label": "Selectivity ankle dorsal flexion",
            "minimumRequirement": [1],
            "norm": [2]
          },
          "strenght-kneeling": {
            "type": "value",
            "valueType": "strenght-kneeling",
            "hasContext": false,
            "showInline": true,
            "label": "Strenght kneeling",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "strenght-squat": {
            "type": "value",
            "valueType": "strenght-squat",
            "hasContext": false,
            "showInline": true,
            "label": "Strength squat",
            "minimumRequirement": [1],
            "norm": [0]
          },
          "strenght-toe_stance": {
            "type": "value",
            "valueType": "strenght-toe_stance",
            "hasContext": true,
            "label": "Strenght toe stance",
            "minimumRequirement": [1],
            "norm": [0]
          }
        }
      }
    ]
  };

  dictionaryAUMC = {
    types: {
      "gender": [
        "Male",
        "Female",
        "X-Status",
      ],
      "diagnosis": [
        "Amputatie",
        "Cerebrale Parese (CP)",
        "Chronische idiopathische axonale polyneuropathie (CIAP)",
        "Hernia nuclei pulposi (HNP)",
        "Hereditaire spastische parese (HSP)",
        "Hereditaire motorische sensorische neuropathie (HMSN)",
        "Habituele tenenloper",
        "Inclusion body myositis (IBM)",
        "Multiple Sclerosis (MS)",
        "Myotone Dystrofie (MD)",
        "Paraplegie (incompleet)",
        "Parkinson",
        "Polyneuropathie",
        "Poliomyelitis",
        "Spastische parese eci.",
        "Spina Bifida (SB)",
        "Cerebro Vasculair Accident (CVA)",
        "Anders",
        "Onbekend",
        "Bethlem myopathie",
        "Diabetes mellitus (DM)",
        "Chronische inflammatoire demyeliniserende polyneuropathie (CIDP)",
        "Spinale distale spieratrofie (SMA)",
        "Limb-gridle spierdystrofie (LGMD)",
        "Lumbosacrale plexusneuropathie",
        "Myasthenia Gravis (MG)",
        "Neurofibromatosis (NF)",
        "Oculofaryngeale spierdystrofie (OPMD)",
        "Radiculopathie",
        "Brown-Vialetto-Van Laere syndroom (BVVL)",
        "Emery-Dreifuss spierdystrofie"
      ],
      "localization": [
        "AMC",
        "VUmc"
      ],
      "informed-consent-education": [
        "Yes incl. videos",
        "Yes without videos",
        "No",
        "Unknown"
      ],
      "informed-consent-research": [
        "Yes",
        "No",
        "Unknown"
      ],
      "affected-side-legs": [
        "Unilateral - left",
        "Unilateral - right",
        "Bilateral R>L ",
        "Bilateral L>R",
        "Bilateral equal",
        "N/A"
      ],
    },
    template:
    {
      "subject-first-name": {
        "type": "value",
        "valueType": "string",
        "label": "First Name",
        "placeholder": "First name",
        "column": "1",
      },
      "subject-prefix-name": {
        "type": "value",
        "valueType": "string",
        "label": "Prefix Name",
        "placeholder": "Prefix name",
        "column": "2",
      },
      "subject-last-name": {
        "type": "value",
        "valueType": "string",
        "label": "Last Name",
        "placeholder": "Last name",
        "column": "3",
      },
      "ehr-id": {
        "type": "value",
        "valueType": "string",
        "label": "MDN",
        "placeholder": "Medisch Dossier Nummer",
        "column": "1",
      },
      "subject-date-of-birth": {
        "type": "date",
        "valueType": "date",
        "label": "Date of Birth",
        "column": "2",
      },
      "subject-gender": {
        "type": "value",
        "valueType": "gender",
        "label": "Gender",
        "column": "3",
      },
      "subject-diagnosis": {
        "type": "list",
        "valueType": "diagnosis",
        "label": "Diagnosis",
        "column": "1",
      },
      "subject-localization": {
        "type": "value",
        "valueType": "localization",
        "label": "Location of measurement",
        "column": "2",
      },
      "subject-informed-consent-research": {
        "type": "value",
        "valueType": "informed-consent-research",
        "label": "Informed consent research",
        "column": "3",
      },
      "subject-affected-side-legs": {
        "type": "value",
        "valueType": "affected-side-legs",
        "label": "Affected side (legs)",
        "column": "1",
      },
      "subject-informed-consent-education": {
        "type": "value",
        "valueType": "informed-consent-education",
        "label": "Informed consent education",
        "column": "3",
      },
    }
  };


  dictionaryGoldStandard = JSON.parse(JSON.stringify(this.dictionaryDefault)); // deep copy
  dictionaryOA = JSON.parse(JSON.stringify(this.dictionaryDefault)); // deep copy

  constructor(
    private apollo: Apollo,
    private dictionaryService: DictionaryService,
  ) {
    this.dictionaryGoldStandard.template = { ...this.dictionaryGoldStandard.template, ...this.physicalExaminationItems };
    this.dictionaryOA.types.procedure[0] = "TKA/UKA - Right";
    this.dictionaryOA.types.procedure[1] = "TKA/UKA - Left";
    this.dictionaryOA.types.comorbidities = [
      "None",
      "Neurological diseases",
      "Others - affecting gait",
      "Neurological diseases, Others - affecting gait"
    ];
    this.dictionaryOA.types.consent = [
      "Consent for GaitTool",
      "Consent for GaitTool, Consent for research",
    ];

    this.dictionaryOA.template.consent.required = true;
  }

  isPredictionAvailable(projectInfo) {
    if (projectInfo['description'] && projectInfo['description'].indexOf("Osteo-Arthritis") != -1)
      return true;
    return false;
  }

  isClassifierAvailable(projectInfo) {
    if (projectInfo['description'] && projectInfo['description'].indexOf("Cerebral Palsy") != -1)
      return true;

    return false;
  }

  // passing info as argument for now
  // this service could keep project info to avoid queries duplication
  getProjectTemplate(projectInfo) {
    if (projectInfo['configuration']) {
      const configuration: ProjectConfiguration = JSON.parse(projectInfo['configuration']);
      const availableTemplates = {
        "GaitTool": this.dictionaryOA,
        "Cerebral Palsy": this.dictionaryCP,
        "3D Gait Analysis": this.dictionaryGoldStandard,
        "SportsMedicine": this.dictionaryService.dictionarySportsMedicine,
        "ShrinersGait": this.dictionaryService.dictionaryGait,
        "Movita": this.dictionaryMovita,
        "RPM": this.dictionaryRPM,
        "GaitScript": this.dictionaryGaitScript,
        "AUMC clinical": this.dictionaryAUMC
      };
      if (configuration?.subjectInfoTemplate in availableTemplates) {
        return availableTemplates[configuration?.subjectInfoTemplate];
      }
    }

    return this.dictionaryDefault;
  }

  // returns the subjectInfoTemplate name from project configuration, or an empty string if not found
  public getSubjectInfoTemplateName(projectInfo): string {
    if (!projectInfo['configuration']) {
      return '';
    }
    const configuration: ProjectConfiguration = JSON.parse(projectInfo['configuration']);
    if (!configuration?.subjectInfoTemplate) {
      return '';
    }
    return configuration.subjectInfoTemplate;
  }
}
