import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { TimeSelection } from '../core/time-selection';

export interface CommentCreationInput {
  clipId: string;
  comment: string;
  timeSelection?: TimeSelection;
}

interface CommentUpdateInput {
  commentId: string;
  comment: string;
  timeSelection?: TimeSelection;
}

const CreateCommentMutation = gql`
mutation createComment($commentInputs: CommentCreationInput!){
  createComment(inputs: $commentInputs) {
    comment {
      ... on Comment
      {
        id,
        comment
      },
      ... on ReportComment
      {
        id,
        comment
      }
    }
  }
}
`

const UpdateCommentMutation = gql`
mutation updateComment($commentInputs: CommentUpdateInput!){
  updateComment(inputs: $commentInputs) {
    comment {
      ... on Comment
      {
        id,
        comment
      },
      ... on ReportComment
      {
        id,
        comment
      }
    }
  }
}
`;

const DeleteCommentMutation = gql`
mutation deleteComment(
    $commentId: String!) {
      deleteComment(commentId: $commentId) {
    ok
  }
}
`;

@Injectable()
export class CommentUpdateService {

  constructor(private apollo: Apollo) { }

  createCommentForClip(inputs: CommentCreationInput) {
    return this.apollo.mutate({
      mutation: CreateCommentMutation,
      variables: { commentInputs: inputs }
  });
  }

  updateComment(inputs: CommentUpdateInput) {
    return this.apollo.mutate({
      mutation: UpdateCommentMutation,
      variables: { commentInputs: inputs}
  });
  }

  deleteComment(id: string) {
    return this.apollo.mutate({
      mutation: DeleteCommentMutation,
      variables: { commentId: id },
    });
  }
}
