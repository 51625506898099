import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { OrganizationService } from 'app/shared/services/organization/organization.service';
import gql from 'graphql-tag';
import { Subscription } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { SubjectContextService } from './subject-context/subject-context.service';
import { SubjectResolverService } from './subject-resolver.service';

export const ProjectDataByIdMenuQuery = gql`
query getProjectMenuMetadata($id: ID!) {
  node(id: $id) {
    ... on Project {
      id,
      name,
      imageUri
    }
  }
}
`;

@Component({
  selector: 'app-project-menu',
  templateUrl: './project-menu.component.html',
  styleUrls: ['./project-menu.component.scss']
})
export class ProjectMenuComponent implements OnInit {
  @Input() project;

  projectOverviewExpanded: boolean = true;

  subs: Subscription[] = [];

  private projectId: string = '';
  public overviewLink: string = '';
  public datasetLink: string = '';
  public subjectLink: string = '';

  constructor(
    private router: Router,
    public auth: AuthService,
    private organizationService: OrganizationService,
    private subjectResolver: SubjectResolverService,
    private apollo: Apollo,
    private subjectService: SubjectContextService,
  ) { }

  ngOnDestroy() {
    for (const s of this.subs)
      s.unsubscribe();
  }

  public ngOnInit(): void {
    const url = this.router.url;

    if (this.subjectResolver.isSubjectUrl(this.router.url)) {
      this.projectOverviewExpanded = false;
    }

    this.handleNewUrl(url);
    this.overviewLink = '/project/' + this.projectId;
    this.datasetLink = this.overviewLink + '/dataset';
    this.subjectLink = this.overviewLink + '/subjects';
  }

  private handleNewUrl(url: string) {
    if (url.indexOf('project/') != -1) {
      this.projectId = url.split('/')[2];
      this.updateProjectInfo(this.projectId);
    }
  }

  updateProjectInfo(id: string) {
    this.apollo.query<any>({
      query: ProjectDataByIdMenuQuery,
      variables: {
        id: id
      },
    }).subscribe(({ data }) => {
      this.project = (data as any).node;
    });
  }

  showDataset() {
    if (this.auth.isTesterAuthenticated())
      return true;

    if (this.organizationService.onEnterpriseDomain() || this.auth.isEnterpriseAuthenticated())
      return false;

    return true;
  }

  public isSubjectLock(): boolean {
    return this.subjectService.getSubjectLock() !== null;
  }
}
