<ng-template *ngIf="urlToShare" #modalTemplate>
  <div class="modal-header modal-sm">
    <h4 class="modal-title">Share</h4>
    <i class="fa fa-times clickable-danger" (click)="hideModal()"></i>
  </div>
  <div class="modal-body modal-sm">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-2 p-2" shareButton="facebook" [url]="urlToShare">
          <i class="fa fa-facebook clickable" style="font-size: 35px"></i>
        </div>
        <div class="col-2 p-2" shareButton="twitter" [url]="urlToShare">
          <i class="fa fa-twitter clickable" style="font-size: 35px"></i>
        </div>
        <div class="col-2 p-2" shareButton="whatsapp" [url]="urlToShare">
          <i class="fa fa-whatsapp clickable" style="font-size: 35px"></i>
        </div>
        <div class="col-2 p-2" shareButton="linkedin" [url]="urlToShare">
          <i class="fa fa-linkedin clickable" style="font-size: 35px"></i>
        </div>
        <div *ngIf="allowCodeSnippet" class="col-2 p-2" id="code-share" (click)="displayCode=true"><i
            class="fa fa-code clickable" aria-hidden="true" style="font-size: 31px;"></i></div>
      </div>
      <div *ngIf="displayCode" class="row bg-secondary p-3">
        <code
          style="color: #fff">&lt;iframe src="{{embedUrl}}" width="500px" height="281px" scrolling="no" frameborder="0" allowfullscreen allow="vr"&gt;&lt;/iframe&gt;</code>
      </div>
    </div>
  </div>
</ng-template>

<ng-template *ngIf="urlToShare" #privateSharing>
  <div class="modal-header">
    <h4 class="modal-title">Share</h4>
    <i class="fa fa-times clickable-danger" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="container">
      <div *ngIf="allowUnlistedAccess || sharingUrls" class="d-block flex-row m-1">
        <div class="mb-2 mt-2">
          <textarea id="input-url" class="form-control form-control-sm" [value]="getUrlToShare()" [tooltip]="tooltipLink()"
            placement="top" rows="3" #inputurl></textarea>
        </div>
        <div class="mb-1 mt-3">
          <input type="checkbox" [checked]="noSSOCheckbox" (change)="toggleNoSSOCheckbox()"/>
          <label class="ml-1">Create link for users without single sign-on.</label>
        </div>
        <div class="mt-1">
          <button class="btn btn-primary" (click)="copyLink(inputurl)">Copy Link</button>
          <a [href]="'mailto:?subject=Check this out on Moveshelf!&body=Hi!%0D%0APlease have a look at this movement analysis report on Moveshelf:%0D%0A' + urlToShare.replace('?', '%3F')"
            class="btn btn-primary m-2">
            Send email
          </a>
        </div>
        <ng-container *ngIf="displayCode">
          <div class="mt-3 mb-2">Embed code:</div>
          <div class="row bg-secondary p-3">
            <code
              style="color: #fff">&lt;iframe src="{{embedUrl}}" width="500px" height="281px" scrolling="no" frameborder="0" allowfullscreen allow="vr"&gt;&lt;/iframe&gt;</code>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="this.subject !== undefined && this.isSharingPatientActive" class="modal-body">
    <div class="container">
      <!-- Add the "Subject access rights" text -->
      <label>Share outside of your organization</label>
      <div class="mb-3" *ngIf="this.getExternalEmail() !== null">
        <textarea class="form-control p-2 text-secondary" id="usernamePassword" rows="3" disabled>Username: {{ this.getExternalEmail() }}
Password: {{ this.getExternalPassword() }}
        </textarea>
      </div>
      <div class="d-flex mb-3">
        <button class="btn btn-primary mr-3" (click)="createCredentials()">Create temporary (90-day) account</button>
        <button class="btn btn-primary" [cdkCopyToClipboard]="copyToClipboard()"
          (cdkCopyToClipboardCopied)="onClipboardCopy($event)" [disabled]="!this.getExternalEmail()">Copy to
          clipboard</button>
      </div>
      <div class="d-flex mb-3">
        <span style="color: orange; font-size: small;"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          IMPORTANT NOTE: By granting access, people external to your organization can access records of this patient by
          using these credentials. Please make sure this meets policy of your organization.</span>
      </div>
      <div class="d-flex mb-3" *ngIf="this.deletedUser !== undefined">
        <span style="color: orange; font-size: small;">{{this.deletedUser}}</span>
      </div>
      <div class="d-flex mb-3" *ngIf="this.copyToClipboardMsg !== undefined">
        <span style="color: orange; font-size: small;">{{this.copyToClipboardMsg}}</span>
      </div>
      <div class="d-flex mb-3" *ngIf="!!this.errorMessage">
        <span style="color: red; font-size: small;">{{this.errorMessage}}</span>
      </div>
      <div class="card bg-dark p-3">
        <h5>External users with access</h5>
        <p *ngIf="usersWithAccess.length === 0">No external users with access</p>
        <!-- Loop through users with access and display rows -->
        <div *ngFor="let t of usersWithAccess;" class="d-flex flex-row justify-content-between align-items-baseline">
          <p>{{ replaceDomain(t.user.email) }}</p>
          <i *ngIf="!this.confirmationVisible[t.user.email]" class="clickable-danger fa fa-trash align-self"
            (click)="this.toggleConfirmationVisible(t.user.email)"></i>
          <div *ngIf="this.confirmationVisible[t.user.email]" class="d-flex flex-row align-items-baseline">
            <span class="cancel-text confirmation-text mr-1" (click)="this.toggleConfirmationVisible(t.user.email)">Cancel</span>
            <span class="confirmation-text">/</span>
            <span class="clickable-danger confirmation-text ml-1" (click)="this.deleteUserRelationship(t.user.email)">Delete</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
