export interface Report {
  title: string;
  author: string;
  customOptions: string; // JSON string
  project: { norms: [] };
  layoutType: string;
  session: string;
  avgs: object[];
  projectPath: string;
  description: string;
  clips?: Clip[];
}

export interface Clip {
  id: string;
  name: string;
  title?: string;
  has3d?: boolean;
  hasVideo?: boolean;
  hasCharts?: boolean;
  projectPath?: string;
  defaultSelection?: any;
  additionalData?: AdditionalData[];
  customOptions?: any;
  projectName?: string;
  sessionName?: string;
  conditionName?: string;
  // hasVideoOverlay?: boolean;
}

export interface AdditionalData {
  id: string;
  dataType: string;
  originalFileName: string;
}

export enum SideSelection {
  LEFT = 'left',
  RIGHT = 'right'
}