<div class="modal-body pb-0">
    <h4 class="mb-3 text-center">Save layout</h4>
    <form class="d-flex ml-4 form-group" [formGroup]="formGroup">
        <label class="text-muted align-self-center col-2">Name:</label>
        <input id="input" type="text" class="d-block text-input col-9" formControlName="layoutName" />
    </form>
</div>
<div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary left-button ml-auto" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary right-button mr-auto" [disabled]="!formGroup.valid"
        (click)="saveLayout()">Save</button>
</div>