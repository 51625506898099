<div *ngIf="!this.loading; then charts else spinner"></div>
<ng-template #charts>
  <div *ngFor="let r of tracks?.reports">
    <h5 class="clickable-success ml-3" (click)="r.show = !r.show">{{r.name}} <i
        [class]="r.show ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i></h5>
    <div *ngIf="r.show" class="mb-3" style="width: 100%; overflow-x: auto;" [innerHTML]="r.data">
    </div>
  </div>
  <div #testDiv>
    <div *ngFor="let gaitParamsTable of tracks?.barCharts" class="mb-3">
      <div class="ml-1 mb-3">
        <h5><span class="clickable-success" (click)="this.toggleExpandParams(gaitParamsTable)">{{gaitParamsTable.name}}
            <i [class]="gaitParamsTable.expanded ? 'fa fa-angle-up': 'fa fa-angle-down'"></i></span></h5>
      </div>
      <div *ngIf="gaitParamsTable.expanded" class="card p-2">
        <app-gait-parameter-table [input]="gaitParamsTable"></app-gait-parameter-table>
      </div>
    </div>
    <div *ngFor="let d of tracks?.pdfs">
      <div class="ml-1 mb-3">
        <h5><span class="clickable-success" (click)="d.collapsed = !d.collapsed">{{d.name}}&nbsp;<i
              [class]="d.collapsed ? 'fa fa-angle-down': 'fa fa-angle-up'"></i></span>
        </h5>
        <div *ngIf="!d.collapsed">
          <iframe [src]="d.url | safe: 'url'" width="100%" height="1000px" frameborder="0"></iframe>
        </div>
      </div>
    </div>
    <app-left-right-switch *ngIf="lineChartMajorGroups.length > 0"></app-left-right-switch>
    <div *ngFor="let majorGroup of lineChartMajorGroups">
      <div class="ml-1 mb-3 chart-group-name">
        <div id="chartGroupNameArea" class="d-flex">
          <h5>
            <span class="clickable-success" (click)="this.toggleExpandCharts(majorGroup)">{{majorGroup.name}}&nbsp;<i
                [class]="majorGroup.expanded ? 'fa fa-angle-up': 'fa fa-angle-down'"></i></span>
            <i *ngIf="showBodyMassWarning(majorGroup.name)" class="fa fa-warning ml-1 mr-1 warning"
              tooltip="No bodyweight found (1) in the C3D file (2) in subject information on the exact date of the session. Latest bodyweight from subject information used, please check with an operator that these graphs are correct."></i>
          </h5>
          <div id="clickerContainer">
            <div *ngIf="clickers && leftRightService.enabled" class="leftRightSelecters"
              [class.showSelected]="leftRightService.isTileSelected(multiChartTiles[majorGroup.name])">
              <i class="clickable-info fa fa-caret-square-o-left"
                [class.text-success]="leftRightService.isTileSelectedInColumn(multiChartTiles[majorGroup.name], leftRightService.getLeftTiles())"
                (click)="leftRightService.selectLeft(multiChartTiles[majorGroup.name])"
                tooltip="Show on the left in split screen"></i>
              <i class="clickable-info fa fa-caret-square-o-right"
                [class.text-success]="leftRightService.isTileSelectedInColumn(multiChartTiles[majorGroup.name], leftRightService.getRightTiles())"
                (click)="leftRightService.selectRight(multiChartTiles[majorGroup.name])"
                tooltip="Show on the right in split screen"></i>
              <app-left-right-maximize></app-left-right-maximize>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="majorGroup.expanded">
        <div *ngFor="let lineChartGroup of majorGroup.groups">
          <div *ngIf="lineChartGroup.name.length>0" class="ml-3 mb-2">
            <h6>{{lineChartGroup.name}}&nbsp;</h6>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-3" *ngFor="let dataTrack of lineChartGroup.tracks"
              style="position: relative; width: 100%; height: 400px;">
              <div class="card" style="width: 100%; height: 100%;">
                <app-charts [input]="dataTrack" [playhead]="playhead" [playbackControl]="playbackControl" #chart
                  style="width: 100%; height: 100%;"></app-charts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-template>
<ng-template #spinner>
  <div>
    <app-spinner [size]="'average'"></app-spinner>
  </div>
</ng-template>
