import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ObservableFileReaderService {

  constructor() { }

  readFile(file: File) {
    return Observable.create(observer => {
      let reader = new FileReader();
      reader.onload = () => {
        observer.next({file: file, data: reader.result});
        observer.complete();
      }
      reader.readAsArrayBuffer(file);
    });
  }
}
