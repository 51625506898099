<div class="main-content px-3 m-3 mw-100">
  <alert type="danger" *ngIf="processingFailed" [dismissible]="true" (onClosed)="onClosed()">
    Error: {{failureMessage}}
  </alert>
  <alert type="warning" *ngIf="hasWarning" [dismissible]="true" (onClosed)="onClosed()">
    <span [innerText]="warningMessage"></span>
  </alert>
  <alert type="warning" *ngIf="sessionUpdateErrorMessage" [dismissible]="true">
    {{sessionUpdateErrorMessage}}
  </alert>
  <app-session-editor *ngIf='this.session' [currentSession]="this.session" [metadataEnabled]="!!projectInfo.canEditSessionMetadata"
    [canEdit]="session?.project?.canEdit" [canPublishSessions]="this.projectInfo.canPublishSessions" [savingMetadata]="this.savingMetadata"
    (updatedSession)="updateSession($event)" (metadataEditing)="toggleMetadataEdit($event)"
    class="pb-2"></app-session-editor>
  <tabset #tabLinks [hidden]="!tabVisualization">
    <tab [disabled]="this.isEditingMetadata" [hidden]="!this.dataSetTabEnabled === true" heading="Dataset"
      [id]="tabs.Dataset" (selectTab)="changeTab('dataset')" style="background-color: #212121">
      <div *ngIf="tabVisualization then dataset"></div>
    </tab>
    <!-- for the evaluation, we have logic to not show gait script evaluation and shriners evaluation at the same time-->
    <tab *ngIf="this.canUseGaitScriptEvaluation()" [disabled]="this.isEditingMetadata" heading="Gait evaluation"
      [id]="tabs.GaitEvaluation" (selectTab)="changeTab(tabs.GaitEvaluation)" style="background-color: #212121"
      class="card p-2">
      <app-gait-evaluation [canEdit]="this.canEditCurrentProject()" [evaluations]="this.gaitScriptEvaluations" (openEvaluation)="openGaitEvaluation()"></app-gait-evaluation>
    </tab>
    <tab *ngIf="!this.canUseGaitScriptEvaluation() && canPerformBiomechEvaluation()" [disabled]="this.isEditingMetadata" [hidden]="!this.evaluationTabEnabled === true" heading="Evaluation"
      [id]="tabs.Evaluation" (selectTab)="changeTab(tabs.Evaluation)" style="background-color: #212121"
      class="card p-2">
      <app-session-biomechanical-evaluation-viewer [metadata]="this.session?.metadata"
        [currentSession]="this.session"></app-session-biomechanical-evaluation-viewer>
    </tab>
    <tab *ngFor="let sessionTab of this.sessionMetadataTabs" [heading]="sessionTab.name"
      [hidden]="this.sessionMetadataTabs.length === 0" [id]="sessionTab.name" style="background-color: #212121"
      class="card p-2" (selectTab)="changeTab(sessionTab.name)">
      <ng-container *ngIf="subjectInfoTemplateName === 'ShrinersGait' && sessionTab.template">
        <div [ngSwitch]="sessionTab.name">
          <app-multi-column-metadata-viewer *ngSwitchCase="'Interview'"
            [currentSession]="this.session" [template]="sessionTab.template"
            [isEditingMetadata]="this.isEditingMetadata" [cancelEditMode]="this.$cancelEditMode"
            [refreshMetadata]="this.$refreshMetadata"></app-multi-column-metadata-viewer>
          <app-multi-column-metadata-viewer *ngSwitchCase="'Session info'"
            [currentSession]="this.session" [template]="sessionTab.template"
            [isEditingMetadata]="this.isEditingMetadata" [cancelEditMode]="this.$cancelEditMode"
            [refreshMetadata]="this.$refreshMetadata"></app-multi-column-metadata-viewer>
          <app-physical-examination-metadata-viewer *ngSwitchDefault
            [currentSession]="this.session" [template]="sessionTab.template" [isQuestionnaires]="sessionTab.name === 'Questionnaires'"
            [isEditingMetadata]="this.isEditingMetadata" [cancelEditMode]="this.$cancelEditMode"
            [refreshMetadata]="this.$refreshMetadata"></app-physical-examination-metadata-viewer>
          </div>
      </ng-container>
      <app-session-metadata-viewer *ngIf="subjectInfoTemplateName !== 'ShrinersGait'" [currentSession]="this.session" [template]="sessionTab.template"
        [isEditingMetadata]="this.isEditingMetadata" [cancelEditMode]="this.$cancelEditMode"
        [refreshMetadata]="this.$refreshMetadata"></app-session-metadata-viewer>
    </tab>
  </tabset>
  <div *ngIf="!tabVisualization then dataset"></div>
  <ng-template #dataset>
    <div [ngClass]="{'tab-card p-2 pt-3': tabVisualization}">
      <app-upload-directory
        *ngIf="session && (projectInfo.canUploadViconDirectory || projectInfo.canUploadNoraxonDirectory || projectInfo.canUploadTheiaDirectory || projectInfo.canUploadGRAILDirectory || projectInfo.canUploadMoxDirectory) && !isUploadInProgress"
        [projectId]="projectId" [session]="session"
        (expectedUploads)="expectedUploadsEvent($event)"></app-upload-directory>
      <app-upload-progress-bar *ngIf="this.expectedUploads.length > 0" [currentSession]="this.session"
        [expectedUploads]="this.expectedUploads" (uploadInProgressEvent)="uploadInProgressEvent($event)"
        [sessionChanges]="this.$sessionChanges"></app-upload-progress-bar>
      <div *ngIf="session" class="mb-3">
        <div class="mt-3">
          <ng-container *ngIf="gaitAnalysisReports.length > 0" >
            <div class="d-flex mb-2 justify-content-end">
              <h5 class="mr-auto">Reports</h5>
              <button class="btn btn-outline-danger text-white ml-2" type="button" [disabled]="selectedReportsCount() < 1"
                (click)="openDeleteReportConfirmationModal()">
                <i class="fa fa-trash-o"></i> ({{selectedReportsCount()}})
              </button>
            </div>
            <ng-container [ngTemplateOutlet]="expandedReports"></ng-container>
          </ng-container>
          <div class="d-flex mb-2 justify-content-end">
            <h5 class="mr-auto">Conditions</h5>
            <app-create-condition-dialog class="mr-2"
              (newConditionNameEvent)="createNew($event)"></app-create-condition-dialog>
            <app-create-report-dialog *ngIf="!hideCreateReportButton()" class="mr-2" [createReportData]="getCreateReportData()"
            (newReportEvent)="createReport($event)" [numberOfTrials]="selectedCount()"></app-create-report-dialog>
            <app-order-conditions class="mr-2" [enabled]="this.canEditCurrentProject() && (tests && tests.length>1)"
              [conditions]="tests" [currentSession]="this.session"></app-order-conditions>
            <app-move-to [numberOfTrials]="selectedTrialsCount()" [selectedIds]="selectedIds()" [conditions]="tests"
              (moveEvent)="moveTrialsHere($event)" [sessionProjectPath]="session.projectPath"></app-move-to>
            <button class="btn btn-outline-danger text-white ml-2" type="button" [disabled]="selectedCount() < 1"
              (click)="openDeleteConfirmationModal()">
              <i class="fa fa-trash-o"></i> ({{selectedCount()}})
            </button>
          </div>
          <div class="alert alert-warning" *ngIf="conditionCreateErrorMessage">
            {{conditionCreateErrorMessage}}
            <button type="button" aria-label="Close" class="close" (click)="conditionCreateErrorMessage = ''"><span
                aria-hidden="true">×</span></button>
          </div>
          <ng-container *ngIf="session?.clips?.length > 0" [ngTemplateOutlet]="expandedSession"
            [ngTemplateOutletContext]="{tests:tests}">
          </ng-container>
          <div class="mt-3">
            <h5>Other sessions</h5>
            <div *ngFor="let s of otherSessions">
              <p (click)="s.expandSession = !s.expandSession" class="ml-3 mb-2 noselect clickable-success">
                {{this.extractSessionName(s)}} <i
                  [class]="' ml-2 fa fa-angle-' + (s.expandSession ? 'up' : 'down')"></i>
              </p>
              <ng-container *ngIf="s.expandSession">
                <app-other-session-view [tests]="s.tests" [sessionId]="s.id" [projectId]="projectId"
                  [initialSelectedClipsAndData]="this.selectedClipsAndData"
                  [parentClipSelectionEvent]="this.clipSelectionOtherSessionEvent"
                  [parentClipDeSelectionEvent]="this.clipDeSelectionOtherSessionEvent"
                  (clipSelection)="this.handleClipSelectionOtherSession($event)"
                  (dataSelection)="this.eventHandleDataSelection($event)"></app-other-session-view>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="this.canUseProcessors && !tabWithoutProcessors" class="sidebar">
  <div *ngIf="isEditing() else collapsedEdit" class="m-3"
    style="position: -webkit-sticky; position: sticky; top: 50px; min-width: 300px">
    <app-processor [clips]="this.selectedIds()" [data]="this.getSelectedData()" [featureFlags]="this.projectInfo"
      [selectAll]="this.allDataSelected()" (cancelEvent)="expandEdit()" (processEvent)="handleProcessorEvent($event)"
      (selectAllEvent)="selectAllEvent($event)"
      (selectMarkedTrialsEvent)="selectMarkedTrialsEvent($event)"></app-processor>
  </div>
</div>

<ng-template #collapsedEdit>
  <div *ngIf="session?.project?.canEdit" (click)="expandEdit()" class="mt-3 p-3 btn btn-primary"
    style="width: 30px; position: -webkit-sticky; position: sticky; top: 50px; margin-right: 0px; float: right">
    <i class="fa fa-angle-double-left"></i>
  </div>
</ng-template>


<ng-template #expandedSession let-tests='tests'>
  <div *ngFor="let t of tests">
    <div *ngIf="!t.path.toLowerCase().includes('gait analysis reports')" class="px-3 py-2 mb-2 card" style="background-color: #333;">
      <div style="width: 100%">
        <div class="d-flex mb-1">
          <input *ngIf="this.canEditCurrentProject()" type="checkbox" class="condition-checkbox"
            [checked]="this.selectedConditions[t.fullPath]" (click)="handleAllConditionClips(t, $event)">
          <h5 [ngClass]="this.canClickConditions() === true && !t.path.toLowerCase().includes('additional files') ? 'ml-2 mr-auto clickable-info' : 'ml-2 mr-auto'"
            (click)="this.canClickConditions() && this.goToCondition(t)"> {{t.path | stripSlashes}}</h5>
          <button *ngIf="projectInfo.enableAverageButton" class="btn btn-primary mr-2" type="submit"
            (click)="average(t)">
            Average
          </button>
        </div>

        <div *ngFor="let c of t.clips">
          <input *ngIf="this.canEditCurrentProject()" type="checkbox"
            [checked]="this.selectedClipsAndData[c.id] !== undefined" (click)="this.handleClipSelection(c, $event)">

          <span *ngIf="(c.uploadStatus === 'Complete' || c.uploadStatus === 'Failed') else spinner"></span>
          <span (click)="goToClip(c.id)" class="clickable-info"> {{c | clipTitle}}</span>
          <app-outdated-warning *ngIf="c.outdated === true"></app-outdated-warning>
          <app-clip-error-warning
            *ngIf="c.uploadStatus === 'Failed' && this.canEditCurrentProject()"></app-clip-error-warning>
          <app-line-chart-icon *ngIf="c.hasCharts"></app-line-chart-icon>
          <app-video-icon *ngIf="c.hasVideo" class="ml-2"></app-video-icon>

          <span *ngIf="this.canEditCurrentProject()" (click)="expanded[c.id] = !expanded[c.id]"
            class="ml-3 noselect clickable-success">
            Data <i [class]="' ml-2 fa fa-angle-' + (expanded[c.id] ? 'up' : 'down')"></i>
          </span>
          <div class="m-3" style="width: 600px" [hidden]="!expanded[c.id]">
            <app-media-tracks-manager [clipId]="c.id" [parentClipSelectionEvent]="this.clipSelectionEvent"
              [parentClipDeSelectionEvent]="this.clipDeSelectionEvent" [sessionChanges]="this.$sessionChanges"
              (dataSelection)="this.handleDataSelection($event, c)"
              (dataDeletion)="this.fileDeleted($event)"></app-media-tracks-manager>
          </div>
        </div>
        <div *ngFor="let n of t.norms">
          <input *ngIf="this.canEditCurrentProject()" type="checkbox" [(ngModel)]="selectedNorms[n.id]">
          <span *ngIf="(n.uploadStatus === 'Complete') else spinner"></span>
          <span class="text-muted"> {{n.name}}</span><i (click)="expanded[n.id] = !expanded[n.id]"
            class="fa fa-info-circle clickable-info my-0 ml-2 mr-0"></i>
          <div *ngIf="expanded[n.id]" class="mt-1">
            <div *ngFor="let c of n.clips" class="mb-1"><span class="ml-4 text-muted my-0">{{c.title}}</span></div>
          </div>
        </div>
        <button *ngIf="!t.path.toLowerCase().startsWith('gait analysis reports'); else emptySpace" class="btn btn-outline-secondary text-white my-2" type="submit" (click)="addNewTrial(t)">
          + Add trial
        </button>
        <ng-template #emptySpace>
          <br><br>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #expandedReports>
  <div *ngFor="let t of gaitAnalysisReports">
    <div class="px-3 py-2 mb-2 card" style="background-color: #333;">
      <div style="width: 100%">
        <div class="d-flex mb-1">
          <input *ngIf="this.canEditCurrentProject()" type="checkbox" class="condition-checkbox"
            [checked]="this.selectedReportGroups[t.fullPath] === true" (click)="handleAllReportsSelection(t)">
          <h5 class="ml-2 mr-auto">Gait Word Reports</h5>
        </div>
        <div *ngFor="let c of t.clips">
          <input *ngIf="this.canEditCurrentProject()" type="checkbox"
            [checked]="this.selectedGaitReportTrials[c.id] === true" (click)="this.handleGaitReportTrialSelection(c, t)">
          <span *ngIf="(c.uploadStatus === 'Complete' || c.uploadStatus === 'Failed') else spinner"></span>
          <span (click)="additionalDataService.downloadGaitAnalysisReport(c.id)" class="clickable-info"> {{c | clipTitle}}</span>
          <app-outdated-warning *ngIf="c.outdated === true"></app-outdated-warning>
          <app-clip-error-warning
            *ngIf="c.uploadStatus === 'Failed' && this.canEditCurrentProject()"></app-clip-error-warning>
          <span *ngIf="this.canEditCurrentProject()" (click)="expanded[c.id] = !expanded[c.id]"
            class="ml-3 noselect clickable-success">
            Data <i [class]="' ml-2 fa fa-angle-' + (expanded[c.id] ? 'up' : 'down')"></i>
          </span>
          <div class="m-3" style="width: 600px" [hidden]="!expanded[c.id]">
            <app-media-tracks-manager [clipId]="c.id" [parentClipSelectionEvent]="this.clipSelectionEvent"
              [parentClipDeSelectionEvent]="this.clipDeSelectionEvent" [sessionChanges]="this.$sessionChanges"
              [hideDragAndDrop]="true"
              (dataSelection)="this.handleDataSelection($event, c)"
              (dataDeletion)="this.fileDeleted($event)"></app-media-tracks-manager>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <i class="fa fa-spinner fa-spin"></i>
</ng-template>
