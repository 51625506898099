<ng-template #loginDialog>
  <div class="login-dialog">
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <span class="pull-right"><i class="fa fa-times clickable-danger" aria-label="Close"
          (click)="hide();"></i></span>
    </div>
    <div *ngIf="showPasswordReset; then passwordResetForm else loginForm"></div>
  </div>
</ng-template>

<ng-template #loginForm>
  <div class="modal-body">

    <!-- Display organization logo -->
    <div *ngIf="getOrganizationLogo()?.trim()" class="card mx-auto mb-3" style="width: 50%">
      <img class="card-img-top" [src]="getOrganizationLogo()" />
    </div>

    <!-- Display SSO buttons options, if available -->
    <form class="ms-form" *ngIf="this.isSsoAvailable()">
      <div *ngFor="let idp of availableSSO; let i=index" class="row form-group">
        <div class="col">
          <div class="btn btn-light" role="button" style="text-transform:none" (click)="loginWithSso(idp, undefined)">
            <img width="20px" style="margin-bottom:3px; margin-right:5px"
              alt="{{this.availableSsoProvidersInfo[idp].label}} sign-in"
              src="{{this.availableSsoProvidersInfo[idp].icon}}" />
            Login with {{this.availableSsoProvidersInfo[idp].label}}
          </div>
        </div>
      </div>
      <div>
        <a class="hint form-text small text-white form-group" (click)="loginFormCollapsed = !loginFormCollapsed">
          Login with username and password or guest</a>
      </div>
    </form>

    <div *ngIf="this.availableOrganization" class="alert bg-gray" style="opacity: 0.5; font-size: 0.8em;">
      <i class="fa fa-warning mr-1"></i>
      <strong>Important:</strong> keep in mind that Moveshelf may contain personal health information, which is confidential.
    </div>

    <div *ngIf="this.inactive" class="alert alert-warning" style="font-size: 1em;">
      <i class="fa fa-warning mr-1"></i>
      You have been logged out automatically after 15 minutes of inactivity. Please log in again.
    </div>

    <!-- Display username/password login form -->
    <form [hidden]="loginFormCollapsed" [formGroup]="form" (ngSubmit)="logIn()" novalidate class="ms-form" style="margin-top: -10px">
      <div *ngIf="errorMessage" class="card border-danger">
        <div class="card-body" id='error-message'>
          {{errorMessage}}
        </div>
      </div>
      <br>

      <!-- Display username/password inputs -->
      <div *ngIf="!selectedGuestAccount" class="form-group row">
        <div class="col">
          <input class="form-control" placeholder="username" type="email" formControlName="email"
            id="email" autofocus />
          <div *ngIf="email.invalid && email.dirty" class="form-text small text-danger">
            <p *ngIf="email.errors.email">Please enter an email address</p>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <input class="form-control" placeholder="password" type="password"
            formControlName="password" id="password" />
          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="form-text small text-danger">
            <p *ngIf="password.errors.required">Please enter your password</p>
          </div>
        </div>
      </div>
      <div *ngIf="!selectedGuestAccount else hintguest" class="row d-flex justify-content-between">
        <a *ngIf="availableGuestAccount" class="nav-link p-0 pl-3 hint" (click)="getGuestAccount()">Guest login</a>
        <div class="nav-link p-0 pr-3 hint ml-auto" (click)="displayPasswordReset(true)">Forgot password?</div>
      </div>
      <ng-template #hintguest>
        <div class="row d-flex justify-content-left">
          <a class="nav-link p-0 pl-3 hint" (click)="switchToRegular()">Regular user login</a>
        </div>
      </ng-template>
      <div class="modal-footer">
        <button type="submit" class="sign-in btn btn-primary" [disabled]="form.invalid" id="submit-button">
          Sign&nbsp;in
        </button>
      </div>
    </form>

    <!-- End of login modal -->
  </div>
</ng-template>

<ng-template #passwordResetForm>
  <app-password-reset-form [emailAddress]="email.value" (cancel)="displayPasswordReset(false)">
  </app-password-reset-form>
</ng-template>
