<main style="position: relative; z-index: 0">
  <!-- <div *ngIf="showCookieBanner && showHeader" class="sticky-top bg-dark">
    <div class="container text-small">
      This website uses cookies to help us improve your experience. For more information
      please read our
      <a routerLink="/legal/privacy">privacy policy</a>.
      <button id="enable-tracking" class="btn btn-sm btn-outline-light m-1" type="button"
        (click)="enableTracking(true)">Agree</button>
    </div>
  </div> -->
  <app-login-dialog></app-login-dialog>
  <app-sign-up></app-sign-up>
  <router-outlet name="overlay"></router-outlet>
  <router-outlet></router-outlet>
</main>
