import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ClipUploaderService } from './clip-uploader.service';

@Component({
  selector: 'app-upload-banner',
  templateUrl: './upload-banner.component.html',
  styleUrls: ['./upload-banner.component.scss']
})
export class UploadBannerComponent implements OnInit, OnDestroy {

  public uploadComplete = false;
  public pendingUploads = 0;
  public enable = true;

  private subs: Subscription[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private uploader: ClipUploaderService,
  ) { }

  ngOnInit() {
    this.subs.push(this.uploader.pendingUploads().subscribe(prog => {
      if (this.pendingUploads > 0 && prog.pendingUploads === 0) {
        this.uploadComplete = true;
      }
      this.pendingUploads = prog.pendingUploads;
    }));

    this.subs.push(
      this.router.events.pipe(
        filter(ev => ev instanceof NavigationEnd)
      ).subscribe((ev: NavigationEnd) => {
        this.enable = true;
      }
      )
    );

  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public viewProject() {
    if (this.uploader.lastProject != undefined)
      this.router.navigate(['project', this.uploader.lastProject.id]);
    else
      this.router.navigate(['profile', this.authService.getAuthenticatedUsername()]);
    this.uploadComplete = false;
  }

  public hideBanner() {
    this.uploadComplete = false;
  }
}
