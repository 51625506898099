import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {

  @Input() text: string = '';
  @Input() active = true;
  /** If true, the button will show a spinner */
  @Input() loading = false;
  /** If true, the button will be displayed as a warning (orange based colors) */
  @Input() warning = false;
  /** If true, the button will be displayed as a secondary button (grey based colors). Used for "cancel" buttons */
  @Input() secondary = false;
  @Input() submit = false;
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  /** Any additional class that should be added to the inner <button> element */
  @Input() extraClasses = '';
  @Output() clickEvent = new EventEmitter<void>();

  public click(): void {
    this.clickEvent.emit();
  }

  public getButtonStyle(): string {
    let style = this.warning && 'btn-warning' || 'btn-primary';
    if (this.size === 'small') {
      style += ' btn-sm';
    }
    if (this.size === 'large') {
      style += ' btn-lg';
    }
    if (this.secondary === true) {
      style += ' btn-secondary';
    }

    return style;
  }

}
