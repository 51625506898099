<div>
  <select class="mx-auto chart-selector" (change)="this.changeChart($any($event.target))">
    <ng-container *ngFor="let chartGroup of this.fetchCharts()">
      <option  [label]="chartGroup.name" [value]="chartGroup.name" class="group-option" [selected]="chartGroup.name === tile.originalId"></option>
      <option *ngFor="let track of chartGroup.tracks" [value]="chartGroup.name + '----' + track.labels.title"
        [selected]="track.labels.title === tile.labels.title">
        &nbsp;&nbsp;&nbsp;{{track.labels.title}}
      </option>
    </ng-container>
  </select>
</div>
<div style="height: calc(100% - 30px);" *ngIf="!isMultiChart()">
  <app-charts
    [splitView]="true"
    [input]="tile"
    [playhead]="playhead"
    [playbackControl]="playbackControl"
    [clickers]="false"
    [highlightActiveCycle]="true"
    [selectedClip]="this.selectedChartFilter"
    [zoomChartOnVideoPlayhead]="this.zoomChartOnVideoPlayhead()"
    [zoomRightChart]="this.zoomRightChart"
  >
  </app-charts>
</div>
<div *ngIf="isMultiChart()" class="multi-chart-tile" [ngClass]="lastTile ? 'last-tile-height' : 'tile-height'" #multiChart>
  <app-splitscreen-multi-chart *ngIf="tile.dataType === 'multi' && multiChartElement" [playhead]="true" [parentRef]="multiChartElement" [chartHeight]="chartHeight" [majorGroup]="tile.originalId"></app-splitscreen-multi-chart>
  <app-splitscreen-multi-chart *ngIf="tile.dataType === 'multi-report' && multiChartElement" [playhead]="true" [parentRef]="multiChartElement" [chartHeight]="chartHeight" [majorGroup]="tile.originalId" [isReport]="true" [selectedChartFilter]="this.selectedChartFilter"></app-splitscreen-multi-chart>
</div>
