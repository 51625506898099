import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { PlaybackControlService } from 'app/core/playback-controls/playback-control.service';
import { Clip } from 'app/projects/report/report.types';
import { MediaData } from 'app/shared/multi-media-player/media-player/media-player.types';
import { VideoTrackService } from 'app/shared/multi-media-player/media-player/video-tracks.service';
import { TileUpdateIdsAndType } from '../../left-right';
import { LeftRightService } from '../../left-right.service';

@Component({
  selector: 'app-video-tile',
  templateUrl: './video-tile.component.html',
  styleUrls: ['./video-tile.component.scss']
})
export class VideoTileComponent implements AfterViewInit {
  @Input() tile: MediaData;
  @Input() playbackControl: PlaybackControlService;
  @Input() videosMaxHeight: number;
  @Input() currentTrialName: string = "";
  @Input() showDropdown: boolean = false;
  @Input() clipList: Clip[] = [];
  @Input() playbackSpeed: number;

  @Output() public renderedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() public initialSyncEvent: EventEmitter<string> = new EventEmitter();
  @Output() public tileChanged: EventEmitter<TileUpdateIdsAndType> = new EventEmitter<TileUpdateIdsAndType>();

  constructor(
    private videoTrackService: VideoTrackService,
    private readonly leftRightService: LeftRightService,
  ) {
    this.videoTrackService.getTracks.length > 1;
  }

  ngAfterViewInit(): void {
    this.showDropdown = this.leftRightService.containsMultipleVideos() && this.clipList.length > 0;
  }

  public renderedCustomEvent(): void {
    this.renderedEvent.emit(true);
  }

  public changeVideo(videoDropdownElement: HTMLSelectElement): void {
    const tracks = this.videoTrackService.getTracksById(videoDropdownElement.value);
    if (tracks.length > 0) {
      const newTile = tracks[0];
      const changedTile: TileUpdateIdsAndType = { oldId: this.tile.dataUrl, newId: newTile.dataUrl };
      this.tileChanged.emit(changedTile);
      this.leftRightService.updateTrialNamesForVideoSync(this.tile.trialNameInReport, newTile.trialNameInReport);
      const isPrimaryVideo = this.tile.primaryVideo;
      this.tile = newTile;
      this.tile.primaryVideo = isPrimaryVideo;
      if (this.leftRightService.cyclesForSync.length > 0) {
        // select the first by default
        this.initialSyncEvent.emit(this.leftRightService.cyclesForSync[0]);
      }
    }
  }
}
