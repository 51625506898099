import { Injectable } from "@angular/core";
import { VideoForceProjection } from "app/shared/data-helper";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class VideoForceOverlayService {

  private forceDataForVideos$: BehaviorSubject<VideoForceProjection[]> = new BehaviorSubject<VideoForceProjection[]>([]);
  private otherForceDataForVideos: VideoForceProjection[] = [];
  public tForceDataOffset = 0;
  public applyOverlay = true;

  public setForceData(forceData: VideoForceProjection[], otherForceData?: VideoForceProjection[]): void {
    if (this.applyOverlay) {
      this.forceDataForVideos$.next((this.forceDataForVideos$.value).concat(forceData));
      this.otherForceDataForVideos = otherForceData || [];
    } else {
      this.resetForceData();
    }
  }

  public resetForceData(): void {
    this.forceDataForVideos$.next([]);
    this.otherForceDataForVideos = [];
    this.tForceDataOffset = 0;
  }

  public getForceData(): Observable<VideoForceProjection[]> {
    return this.forceDataForVideos$.asObservable();
  }

  public getOtherForceData(): VideoForceProjection[] {
    // Return force data that are not in the current trial
    return this.otherForceDataForVideos;
  }

}
