import { Injectable } from '@angular/core';
import { DataTrack, LineChartMajorGroup } from '../chart/chart.types';

@Injectable({
  providedIn: 'root'
})
export class MultiChartDataService {
  private lineChartMajorGroups: LineChartMajorGroup[] = [];

  public setLineChartMajorGroups(lineChartMajorGroups: LineChartMajorGroup[]): void {
    this.lineChartMajorGroups = lineChartMajorGroups;
  }

  public getLineChartMajorGroups(): LineChartMajorGroup[] {
    return this.lineChartMajorGroups;
  }

  public getMultiChartTiles(id: string, chartMode: 'trial' | 'report'): Record<string, DataTrack> {
    const multiChartTiles: Record<string, DataTrack> = {};
    this.lineChartMajorGroups.forEach((group) => {
      // make multi chart a record
      multiChartTiles[group.name] = {
        id: id,
        values: [],
        events: [],
        colors: [],
        labels: {
          title: '',
          hAxis: '',
          vAxis: '',
          time: '',
          data: {},
        },
        series: {},
        originalId: group.name,
        dataType: chartMode === 'report' ? 'multi-report' : 'multi'
      };
    });
    return multiChartTiles;
  }
}
