import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { AuthService } from './auth.service';

export interface UpdateViewerInput {
  username?: string;
  email?: string;
  displayName?: string;
  avatar?: string;
}


@Injectable()
export class UserUpdateService {
  private mutation = gql`
    mutation updateViewer(
        $inputs: UpdateViewerInput) {
      updateViewer(userData: $inputs) {
        user {
          id,
          username,
          email,
          displayName,
          avatar
        }
      }
    }
  `;

  constructor(private apollo: Apollo, private authService: AuthService) { }

  updateUser(params: UpdateViewerInput) {
    return this.apollo.mutate({
      mutation: this.mutation,
      variables: {inputs: params}
    });
  }

  deleteAccount() {
    return this.apollo.mutate({
      mutation: gql`
        mutation deleteAccount($username: String!) {
          deleteAccount(username: $username) {
            deleted
          }
        }
      `,
      variables: {
        username: this.authService.getAuthenticatedUsername()
      }
    }).toPromise();
  }
}
