import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';


@Injectable({providedIn: 'root'})
export class WindowService {
  private inBrowser: boolean;
  private window: Window;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.inBrowser = isPlatformBrowser(this.platformId);
    if (this.inBrowser) {
      this.window = window;
    } else {
      this.window = ({} as Window);
    }
  }

  get checkWindowDefined(): boolean {
    return this.inBrowser;
  }

  get devicePixelRatio(): number {
    return this.inBrowser ? this.window.devicePixelRatio : 0;
  }

  get innerHeight(): number  {
    return this.inBrowser ? this.window.innerHeight : 0;
  }

  get getPageYOffset(): number  {
    return this.inBrowser ? this.window.scrollY : 0;
  }

  public scrollToTop() {
    if (this.inBrowser) {
      this.window.scrollTo(0,0);
    }
  }

  public embedded(): boolean {
    if (this.inBrowser) {
      return this.window.self !== this.window.top;
    }
  }

  public redirect(location: string): void {
    if (this.inBrowser) {
      this.window.location.href = location;
    }
  }

  public postMessageToEmbedder(message: object, option: string): void {
    if (this.inBrowser) {
      this.window.parent.postMessage(message, option);
    }
  }

  public prompt(message: string, url: string): void {
    if (this.inBrowser) {
      this.window.prompt(message, url);
    }
  }

  public open(url: string): void {
    if (this.inBrowser) {
      this.window.open(url);
    }
  }

  public alert(message: string): void {
    if (this.inBrowser) {
      this.window.alert(message);
    }
  }

  public getCurrentHost(): string {
    if (this.inBrowser) {
      return this.window.location.hostname;
    }
  }

  public getOrigin(): string {
    if (this.inBrowser) {
      return this.window.location.origin;
    }
  }

}
