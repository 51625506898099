<div [ngClass]="{
    'controls': true,
    'no-selection' : true,
    'd-inline-flex': true,
    'align-items-center': true,
    'py-2': true,
    'full-controls': fullControls,
    'separate-fullscreen': separateFullscreen,
    'insight-clip-controls': isInsight
  }">


  <i class="clickable" [ngClass]="playingClass" (click)="playPause()"></i>

  <div *ngIf="enablePlaybackSpeedController" style="display: flex;">
    <select class="form-control mr-2 playback-speed" style="width: fit-content;" [(ngModel)]="playbackSpeed"
      (change)="onPlaybackSpeedChange()">
      <option value="1">1x</option>
      <option value="0.5">1/2x</option>
      <option value="0.25">1/4x</option>
      <option value="0.1">1/10x</option>
      <option value="0.0625">1/16x</option>
    </select>
  </div>
  {{timecode}}
  <i class="fa fa-info-circle ml-2 testme" [tooltip]="tooltip" containerClass="controls-info"></i>
  <app-timebar [allowSelectionEditing]="allowSelectionEditing" [minFramerate]="minFramerate"
     [timebarColors]="this.timebarColors"  [timeMarks]="marks"></app-timebar>
  <a *ngIf="includeEditLink" [routerLink]="['/edit', clipId]" target="_blank"><svg-icon src="/assets/moveshelf_icon.svg"
      class="no-selection svg-clickable mx-2" style="width: 1.2em;" tooltip="View on Moveshelf"
      placement="top"></svg-icon></a>
  <i *ngIf="includeShareButton" class="fa fa-share-square-o clickable mx-2" (click)="shareOnSocial()"></i>
  <span class="mr-2"></span>
  <ng-template #tooltip>
    <div class="ml-1">
      Playback controls:
      <ul style="padding-left: 25px;">
        <li><b>Tap</b> or <b>click on timebar</b>: jump to time</li>
        <li><b>Shift + hover on timebar</b>: jog </li>
        <li><b>j</b> or <b>left-arrow</b>: jump -20ms</li>
        <li><b>l</b> or <b>right-arrow</b>: jump +20ms</li>
        <li><b>k</b> or <b>spacebar</b>: play/pause</li>
        <ng-container *ngIf="allowSelectionEditing">
          <li><b>Shift + i</b>: set selection start</li>
          <li><b>Shift + o</b>: set selection end</li>
          <li><b>Hold, then drag</b>: select region (if enabled)</li>
          <li><b>Double tap</b>: clear selection (if enabled).</li>
        </ng-container>
      </ul>
    </div>
    <div *ngIf="fullControls" class="ml-1">
      Viewport controls:
      <ul style="padding-left: 25px;">
        <li><b>Left click</b>: orbit camera</li>
        <li><b>Right click</b>: pan camera</li>
        <li><b>Mouse wheel</b>: zoom</li>
        <li><b>f</b>: toggle follow camera</li>
        <li><b>Shift + l</b>: show/hide optical segments</li>
        <li><b>Shift + m</b>: show/hide markers</li>
        <li><b>Shift + h</b>: toggle GRF visualization</li>
        <li><b>Shift + ></b>: show/hide markers trajectory</li>
        <li><b>Shift + t</b>: show/hide technical mesh</li>
        <li><b>Shift + f</b>: select next chart</li>
        <li><b>Shift + )</b>: ortographic/perspective camera</li>
        <li><b>Shift + n</b>: select next take</li>
      </ul>
    </div>
  </ng-template>
</div>
