import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, PLATFORM_ID } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DataHelper } from "app/shared/data-helper";
import { LRUMap } from "lru_map";
import { firstValueFrom } from "rxjs";
import 'three';
import '../BVHLoader';
import { ClipPlayerInput, ClipPlayerOptions, CoordinatesType } from "../clip-player";
import '../DRACOLoader';
import '../FBXAnimationLoader';
import '../FBXLoader';
import '../GLTFLoader';
import { ClipServerMetadata, MocapClip } from "../mocap-clip";
import '../TGALoader';
import * as THREE from '../three-global';
import '../TRCLoader';
import '../zlib-global';
import '../zlib.min';

declare const require: any;
const THREE = require('three');


export interface Clip {
  skeleton: THREE.Skeleton;
  clips: THREE.AnimationClip [];
  mesh?: any;
}

const CACHE_LIMIT = 20;

const getNodeClip = gql `
query getNodeClip($id: ID!) {
  node(id: $id){
    ... on MocapClip {
      id,
      previewImageUri
    }
  }
}
`;

const clipLoaderQuery = gql`
query clip($id: ID!) {
  node(id: $id) {
    ... on MocapClip {
      id,
      title,
      previewDataUri,
      description,
      originalFileName,
      uploadStatus,
      views,
      customOptions,
      projectPath,
      additionalData {
        id,
        dataType,
        originalFileName,
        previewDataUri,
        uploadStatus
      }
    }
  }
}
`;

export class ClipLoader {
  // comma separated list
  public readonly supportedFileTypes = ".fbx,.bvh,.trc,.c3d,.tdf,.dae,.glb,.gltf"

  private cache: LRUMap<string, ClipPlayerInput>;

  constructor(
    protected apollo: Apollo,
    protected http: HttpClient,
    @Inject(PLATFORM_ID) protected platformId
  ) {
    this.cache = new LRUMap<string, ClipPlayerInput>(CACHE_LIMIT);
    THREE.DRACOLoader.setDecoderPath( './js/draco/' );
  }

  public loadFile(file: File, onProgress?: ((ev: ProgressEvent) => void)): Promise<ClipPlayerInput> {
    const ext = file.name.split('.').pop();
    switch (ext.toLowerCase()) {
      case 'bvh': return new Promise ( (resolve, reject) => {
        this.loadBvhFile(file, onProgress).then( res => {
          const input: ClipPlayerInput = { animation: res };
          resolve(input);
        })
        .catch( res => {
          reject(res);
        });
      });
      case 'fbx': return new Promise ( (resolve, reject) => {
        this.loadFbxFile(file, onProgress).then( res => {
          const input: ClipPlayerInput = { animation: res };
          resolve(input);
        })
        .catch( res => {
          reject(res);
        });
      });
      case 'glb': return new Promise ( (resolve, reject) => {
        console.log('GLTF');
        THREE.DRACOLoader.setDecoderPath( './js/draco/' );
        this.loadGltfFile(file, onProgress).then( res => {
          const input: ClipPlayerInput = { animation: res };
          resolve(input);
        })
        .catch( res => {
          reject(res);
        });
      });
      case 'trc': return new Promise ( (resolve, reject) => {
        console.log('TRC');
        this.loadTrcFile(file, onProgress).then( res => {
          const input: ClipPlayerInput = { animation: res };
          resolve(input);
        })
        .catch( res => {
          reject(res);
        });
      });

      case 'c3d':
      case 'tdf':
        return Promise.resolve({ animation: { clips: [new THREE.Object3D()] }, nopreview: true });

      case 'dae':
        return Promise.reject(`Format of ${file.name} was not recognized as supported  ${ext.toUpperCase()} data`);

      default:
        return Promise.reject('Moveshelf does not support ' + ext.toUpperCase() + ' files');
    }
  }

  getDefaultOptions(filename: string): ClipPlayerOptions {
    const defaultOptions: ClipPlayerOptions = { enableOpticalSegments: true };

    if (filename == 'unknown' || filename == undefined) {
      defaultOptions.coordinates = CoordinatesType.yUp;
      return defaultOptions;
    }

    const ext = filename.split('.').pop();

    switch (ext.toLowerCase()) {
      case 'c3d':
        defaultOptions.coordinates = CoordinatesType.zUp;
        break;
      case 'tdf':
        // this works but it get lost, as soon as options are updated once
        defaultOptions.coordinates = CoordinatesType.yUp;
        defaultOptions.skeletonUnits = 1;
        break;
      default:
        defaultOptions.coordinates = CoordinatesType.yUp;
        break;
    }
    return defaultOptions;
  }

  getPreviewImage(clipId: string): Promise<any> {
    return new Promise( (resolve) => {
      this.apollo.query<any>({
        query: getNodeClip,
        variables: {
          id: clipId
        }
      }).subscribe(({data}) => {
        if (data.node != null) {
          const clipPreviewUri = data.node.previewImageUri ? data.node.previewImageUri : "assets/preview_xl.jpg";
          const clipId = data.node.id;
          resolve ({ clipId: clipId, previewUri: clipPreviewUri });
        }
      });
    });
  }

  public async getHeadersContentType(url: string): Promise<string> {
    const headResult = await firstValueFrom(this.http.head(url, { observe: 'response' }));
    return headResult.headers.get('content-type');
  }

public async loadClip(clip: MocapClip, onProgress?: ((ev: ProgressEvent) => void)): Promise<ClipPlayerInput> {
    // FIXME: assumes original file is bvh unless specified
    const defaultOptions: ClipPlayerOptions = this.getDefaultOptions(clip.originalFileName);
    const options = this.mergeDefaultOptionsWithCustomOptions(defaultOptions, clip.customOptions);

    if (!clip.previewDataUri || clip.originalFileName === 'unknown') {
      return { animation: undefined, customOptions: options };
    } else {
      let ext = clip.originalFileName ? clip.originalFileName.split('.').pop() : 'bvh';
      const contentType = await this.getHeadersContentType(clip.previewDataUri);
      if (contentType == 'model/gltf-binary') {
        ext = 'glb';
      }

      const playerInput = await this.parseClip(clip, ext, onProgress, options);
      return playerInput;
    }
  }

  public parseClip(clip, ext, onProgress, options): Promise<ClipPlayerInput>  {
    switch (ext.toLowerCase()) {
      case 'bvh': return new Promise ( (resolve, reject) => {
        this.loadBvhUri(clip.previewDataUri, onProgress).then( res => {
          resolve({
            animation: res,
            customOptions: options
          });
        })
        .catch( res => {
          reject(res);
        });
      });
      case 'glb': return new Promise ( (resolve, reject) => {
        this.loadGltfUri(clip.previewDataUri, onProgress, options.loadMeshFromFile).then( res => {
          resolve({
            animation: res,
            customOptions: options
          });
        })
        .catch( res => {
          reject(res);
        });
      });
      case 'fbx': return new Promise ( (resolve, reject) => {
        //options.newFbxLoader = true;
        if (options.loadMeshFromFile || options.newFbxLoader) {
          this.loadFbxUri(clip.previewDataUri, onProgress, options.loadMeshFromFile).then( res => {
            resolve({
              animation: res,
              customOptions: options
            });
          })
          .catch( res => {
            reject(res);
          });
        } else {
          this.loadFbxAnimationUri(clip.previewDataUri, onProgress).then( res => {
            resolve({
              animation: res,
              customOptions: options
            });
          })
          .catch( res => {
            reject(res);
          });
        }
      });

      case 'trc':
      case 'c3d':
      case 'tdf':
        return new Promise ( (resolve, reject) => {
        this.loadTrcUri(clip.previewDataUri, onProgress).then( res => {
          resolve({
            animation: res,
            customOptions: options
          });
        })
        .catch( res => {
          reject(res);
        });
      });
      case 'mox':
        return new Promise ( (resolve, reject) => {
          this.http.get(clip.previewDataUri, {responseType: 'text'})
          .subscribe(parsedData => {
            const res = DataHelper.parseMoxClip(parsedData, clip.title, clip.projectPath);
            resolve({
              animation: res,
              customOptions: options
            });
          });
        });
      case 'avi':
      case 'mov':
        clip.additionalData.push( { dataType: 'video', id: clip.id, previewDataUri: clip.previewDataUri, uploadStatus: clip.uploadStatus });
        return Promise.resolve( { animation: undefined, customOptions: options });
      case 'pdf':
        clip.additionalData.push( { dataType: 'doc', id: clip.id, previewDataUri: clip.previewDataUri, uploadStatus: clip.uploadStatus });
        return Promise.resolve( { animation: undefined, customOptions: options });
      case 'xlsx':
        for (let i=0; i < clip.additionalData.length; i++) {
          const d = clip.additionalData[i];
          if (d.dataType == 'motion') {
            clip.additionalData.splice(i, 1);
            return this.parseClip(d, 'mox', onProgress, options );
          }
        }
        return Promise.resolve( { animation: undefined, customOptions: options });
      default:
        return Promise.reject('Moveshelf does not support ' + ext.toUpperCase() + ' files');
    }
  }

  public loadMesh(uri: string): Promise<any> {
    return new Promise( (resolve) => {
      this.loadGltfUri(uri, progress => { }, true).then( (result) => {
        resolve(result);
      });
    });
  }

  public async loadClipId(clipId: string, onProgress?: ((ev: ProgressEvent) => void), updateOptions: boolean = false, forceReload: boolean = false): Promise<ClipPlayerInput | undefined> {
    if (!isPlatformBrowser(this.platformId)) {
      return undefined;
    }

    const cachedClip = this.cache.get(clipId);

    if (cachedClip && !forceReload) {
      if (updateOptions) {
        const clip = await firstValueFrom(this.apollo.query<{node: ClipServerMetadata}>({
          query: clipLoaderQuery,
          variables: {
            id: clipId
          }
        }));
        cachedClip.additionalData = clip.data.node.additionalData;
        cachedClip.customOptions = this.mergeDefaultOptionsWithCustomOptions(
          this.getDefaultOptions(clip.data.node?.originalFileName), clip.data.node?.customOptions
        );
      }
      return cachedClip;
    }
    const clip = await firstValueFrom(this.apollo.query<{node: ClipServerMetadata}>({
      query: clipLoaderQuery,
      variables: {
        id: clipId
      },
      fetchPolicy: 'network-only'
    }));
    const loadedClip = await this.loadClip(clip.data.node, onProgress);
    loadedClip.id = clipId;
    loadedClip.additionalData = clip.data.node.additionalData;
    loadedClip.title = clip.data.node.title;
    loadedClip.projectPath = clip.data.node.projectPath;
    this.cache.set(clipId, loadedClip);
    return loadedClip;
  }

  getGltfAnimation(result: any, includeMesh?: boolean): Clip {
    const obj =  result.scene.children[0];
    if (obj.skeleton == undefined) {
      const skeleton = this.getSkeleton(obj);
      obj.skeleton = skeleton;
    }
    const clip = { clips: result.animations, skeleton: obj.skeleton, mesh: includeMesh ? obj : undefined };
    return clip;
  }

  getFbxContent(result: any, includeMesh?: boolean): Clip {
    if (result.skeleton == undefined) {
      const skeleton = this.getSkeleton(result);
      result.skeleton = skeleton;
    }

    const clip = { clips: result.animations, skeleton: result.skeleton, mesh: includeMesh ? result : undefined };
    return clip;
  }

  getFbxAnimation(result: any): Clip {

    if (result.skeleton == undefined) {
      const skeleton = this.getSkeleton(result);
      result.skeleton = skeleton;
    }
    const clip = { clips: result.animations, skeleton: result.skeleton }; //, mesh: groups };
    return clip;

  }

  getSkeleton(mesh: any) {
    const bones = [];
    mesh.traverse( (child) => {
      if (child.type == "Bone" ||
      (child.userData && child.userData.type && child.userData.type.toLowerCase().indexOf('marker') != -1))
        bones.push(child);

    });
    return new THREE.Skeleton(bones);
  }

  private loadGltfUri(uri: string, onProgress?: ((ev: ProgressEvent) => void), includeMesh?: boolean): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const loader = new THREE.GLTFLoader();
      loader.setDRACOLoader( new THREE.DRACOLoader() );
      loader.load(uri, (result) => {
        const clip = this.getGltfAnimation(result, includeMesh);
        resolve(clip); // : reject(clip);
      }, progress => {
        if (onProgress) {
          onProgress(progress);
        }
      });
    });
  }

  private loadFbxUri(uri: string, onProgress?: ((ev: ProgressEvent) => void), includeMesh?: boolean): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const loader = new THREE.FBXLoader();
      loader.load(uri, result => {
        const clip = this.getFbxContent(result, includeMesh);
        this.verifyFbxResult(clip) ? resolve(clip) : reject(clip);
      }, progress => {
        if (onProgress) {
          onProgress(progress);
        }
      });
    });
  }


  private loadFbxAnimationUri(uri: string, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const loader = new THREE.FBXAnimationLoader();
      loader.load(uri, result => {
        const clip = this.getFbxAnimation(result);
        this.verifyFbxResult(clip) ? resolve(clip) : reject(clip);
      }, progress => {
        if (onProgress) {
          onProgress(progress);
        }
      });
    });
  }

  private loadTrcUri(uri: string, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const loader = new THREE.TRCLoader();
      loader.load(uri, result => {
        resolve(result); //this.verifyTrcResult(result) ? resolve(result) : reject(result);
      }, progress => {
        if (onProgress) {
          onProgress(progress);
        }
      });
    });
  }

  private loadBvhUri(uri: string, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const loader = new THREE.BVHLoader();
      loader.load(uri, result => {
        this.verifyBvhResult(result as unknown as Clip) ? resolve(result as unknown as Clip) : reject(result);
      }, progress => {
        if (onProgress) {
          onProgress(progress);
        }
      });
    });
  }

  private loadTrcFile(file: File, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const loader = new THREE.TRCLoader();
      reader.onload = () => {
        try {
          const clip = loader.parse(reader.result);
          this.verifyTrcResult(clip) ? resolve(clip) : reject(clip);
        } catch (error) {
          console.log(error);
          reject('Format of ' + file.name + ' was not recognized as supported TRC data');
        }
      };

      if (onProgress) {
        reader.onprogress = (ev) => onProgress(ev);
      }
      reader.readAsText(file);
    });
  }

  private loadGltfFile(file: File, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const loader = new THREE.GLTFLoader();
      loader.setDRACOLoader( new THREE.DRACOLoader() );
      reader.onload = () => {
          loader.parse(reader.result, '', (result) => {
            const clip = this.getGltfAnimation(result);
            try {
              this.verifyGltfResult(clip) ? resolve(clip) : reject(clip);
            } catch (error) {
              console.log(error);
              reject('Format of ' + file.name + ' was not recognized as supported GLTF data');
            }
          }, (error) => {
            console.log(error);
            reject('Format of ' + file.name + ' was not recognized as supported GLTF data');
        });

      };

      if (onProgress) {
        reader.onprogress = (ev) => onProgress(ev);
      }
      reader.readAsArrayBuffer(file);
    });
  }

  private loadFbxFile(file: File, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const loader = new THREE.FBXAnimationLoader();
      reader.onload = () => {
        try {
          const result = loader.parse(reader.result);
          const clip = this.getFbxAnimation(result);
          this.verifyFbxResult(clip) ? resolve(clip) : reject(clip);
        } catch (error) {
          console.log(error);
          reject('Format of ' + file.name + ' was not recognized as supported FBX data');
        }
      };

      if (onProgress) {
        reader.onprogress = (ev) => onProgress(ev);
      }
      reader.readAsArrayBuffer(file);
    });
  }

  private loadBvhFile(file: File, onProgress?: ((ev: ProgressEvent) => void)): Promise<Clip> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const loader = new THREE.BVHLoader();
      reader.onload = () => {
        try {
          const result = loader.parse(reader.result);
          this.verifyBvhResult(result) ? resolve(result) : reject(result);
        } catch (error) {
          console.log(error);
          reject('Format of ' + file.name + ' was not recognized as valid BVH data');
        }
      };

      if (onProgress) {
        reader.onprogress = (ev) => onProgress(ev);
      }
      reader.readAsText(file);
    });
  }

  private verifyTrcResult(result: Clip): boolean {
    return result.clips[0].duration > 0 && result.skeleton.bones.length > 0;
  }

  private verifyGltfResult(result: Clip): boolean {
    return result.clips[0].duration > 0 && result.skeleton.bones.length > 0;
  }

  private verifyFbxResult(result: Clip): boolean {
    return result.clips[0].duration > 0 && result.skeleton.bones.length > 0;
  }

  private verifyBvhResult(result: Clip): boolean {
    // TODO: Do we have any requirements that should be validated?
    return result.clips[0].duration > 0 && result.skeleton.bones.length > 0;
  }

  /**
   * Merges the default options with a stringified JSON options object.
   * The additional options keys are given precendence if needed.
   * @param defaultOptions ClipPlayerOptions
   * @param customOptions A stringified custom options object
   * @returns ClipPlayerOptions
   */
  private mergeDefaultOptionsWithCustomOptions(
    defaultOptions: ClipPlayerOptions,
    customOptionsAsJsonString?: string
  ): ClipPlayerOptions {
    if (!customOptionsAsJsonString) {
      return defaultOptions;
    }
    return {...defaultOptions, ...JSON.parse(customOptionsAsJsonString)};
  }
}
