import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { firstValueFrom, map } from 'rxjs';
import { ProjectPatientByEhrId } from '../shared/queries';

type patientMetadataJSON = string;
export type Patient = {
  id: string,
  metadata: patientMetadataJSON,
  name: string,
  updated: string,
}

const PatientIdFromSessionQuery = gql`
query getPatientIdFromSession($id: ID!) {
  node(id: $id) {
    ... on Session {
      id,
      patient {
        id
      }
    }
  }
}
`;

const PatientIdFromReportQuery = gql`
query getPatientIdFromReport($id: ID!) {
  node(id: $id) {
    ... on Report {
      id,
      patient {
        id
      }
    }
  }
}
`;

const PatientIdFromTrialQuery = gql`
query getPatientIdFromTrial($id: ID!) {
  node(id: $id) {
    ... on MocapClip {
      id,
      session {
        id
        patient {
          id
        }
      }
    }
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class SubjectResolverService  {
  constructor(
    private apollo: Apollo,
    private readonly router: Router,
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<string> {
    switch (route.url[0].path) {
      case 'subject': {
        if (route.queryParamMap.get('ehrId') === null) {
          // Normal routing, the patient id is a route parameter.
          return route.paramMap.get('patientId');
        }

        // The route is trying to use a ehr id to locate a subject
        const patientId = await this.getPatientByEhrId(
          route.paramMap.get('patientId'), route.paramMap.get('projectId')
        );
        if (!patientId) {
          this.router.navigate(['/project', route.paramMap.get('projectId'), 'patient-unavailable']);
        }
        return patientId;
      }
      case 'trial':
        return await this.apollo.query<any>({
          query: PatientIdFromTrialQuery,
          variables: {
            id: route.paramMap.get('trialId')
          },
        }).pipe(map(result => {
          return result.data.node.session?.patient?.id;
        })).toPromise();
      case 'session':
        return await this.apollo.query<any>({
          query: PatientIdFromSessionQuery,
          variables: {
            id: route.paramMap.get('sessionId')
          },
        }).pipe(map(result => {
          return result.data.node.patient?.id;
        })).toPromise();
      case 'report':
        return await this.apollo.query<any>({
          query: PatientIdFromReportQuery,
          variables: {
            id: route.paramMap.get('reportId')
          },
        }).pipe(map(result => {
          return result.data.node.patient?.id;
        })).toPromise();
    }
    return undefined;
  }



  /**
   * Checks if the given URL is a subject URL.
   * A URL is considered a subject URL if the second to last segment is one of the following:
   * - subject
   * - trial
   * - session
   * - report
   * - comparison
   *
   * @param url - The URL to check.
   * @returns True if the URL is a subject URL, false otherwise.
   */
  isSubjectUrl(url: string): boolean {
    const segments = url.split('/');
    // Last segment is the actual id, segment before that is the type, which determines whether this is a subject URL.
    const pageType = segments[segments.length - 2];
    return (['subject', 'trial', 'session', 'report', 'comparison'].includes(pageType));
  }

  /**
   * Exchange a patient EHR ID within a project for a global id
   * @returns A patient global id
   */
  async getPatientByEhrId(ehrId: string, projectId: string): Promise<string> {
    let projectPatient;
    try {
      projectPatient = await firstValueFrom(this.apollo.query<{ data: { patient: { id: string } } }>({
        query: ProjectPatientByEhrId,
        variables: {
          ehrId: ehrId,
          projectId: projectId,
        }
      }));
    } catch (GraphqlError) {
      if (GraphqlError.message.startsWith("You are not authorized")) {
        // if error is "unauthorized" redirect to unauthorized page
        throw GraphqlError;
      }
      console.warn(`graphql error:`, GraphqlError);
      return undefined;
    }

    return projectPatient?.data?.patient?.id;
  }

}

export const SubjectResolverFunction: ResolveFn<Promise<string>> = (route: ActivatedRouteSnapshot) => {
  return inject(SubjectResolverService).resolve(route);
};
