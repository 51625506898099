<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Choose sign-up subdomain</h4>
  </div>
  <div class="lessons-list" *ngIf="!this.chooseDomain else orgSubdomainModal">
    <div class="modal-body">
      <div class="form-group">
        <p>You are signing up to Moveshelf on {{this.getHost()}}.</p>
        <div class="modal-footer d-flex flex-column align-items-end">
          <button type="submit" class="btn btn-primary" (click)="this.continueHere()">
            Continue
          </button>
          <small class="clickable-info text-muted mt-3" (click)="toggleTenantDialog()">My organization has a custom subdomain</small>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #orgSubdomainModal>
  <form [formGroup]="tenantForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="form-group">
        <p>To go to your organization, enter your organization's custom domain name. (Ask your administrator if unsure.)
        </p>
        <label for="domain">Custom domain</label>
        <input type="text" class="form-control" id="domain" autofocus formControlName="domain">
        <p *ngIf="domain.invalid" class="form-text small text-warning">
          You must provide a domain name.
        </p>
        <small id="domainHelp" class="form-text text-muted">https://<i>domain</i>{{subdomainTemplate}}</small>
      </div>
    </div>
    <div class="modal-footer d-flex flex-column align-items-end">
      <button type="submit" class="btn btn-primary" [disabled]="tenantForm.invalid">
        Continue
      </button>
      <small class="clickable-info text-muted mt-3" (click)="toggleTenantDialog()">Continue on {{this.getHost()}} instead</small>
    </div>
  </form>
</ng-template>