import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { ClipMetadata, ClipMetadataFragment } from '../shared/graphql-fragments/clip-metadata';
import { ClipServerMetadata, ClipUserMetadata } from '../shared/mocap-clip';
import { GetClipsQuery } from '../shared/queries';



export interface UpdateClipInput extends ClipServerMetadata {
  metadata?: ClipUserMetadata;
}

export interface QueryInterface {
  id: number;
  query: any;
  variables?: any;
}

@Injectable()
export class ClipUpdateService {
  private updateMutation = gql`
  mutation updateClip(
      $input: UpdateClipInput!) {
    updateClip(clipData: $input) {
      clip {
        ... ClipMetadata
      }
    }
  }
  ${ClipMetadataFragment}
  `;

  private deleteClipMutation = gql`
  mutation deleteClip(
      $clipId: String!) {
    deleteClip(clipId: $clipId) {
      ok
    }
  }
  `;

  private readonly getOptionsQuery = gql`
  query getAdditionalDataInfo($clipId: ID!) {
    node(id: $clipId) {
      ... on MocapClip {
        id,
        originalFileName,
        customOptions
      }
    }
  }
`;

  queryList: QueryInterface [] = [];

  constructor(private apollo: Apollo) { }

  updateClip(clipData: UpdateClipInput) {
    return this.apollo.mutate<{ updateClip: { clip: ClipMetadata}}, { input: UpdateClipInput}>({
      mutation: this.updateMutation,
      variables: { input: clipData }
    });
  }

  findClipWithId(id: string, clips: any) {
      for (let i = 0; i < clips.length; i++) {
        if (clips[i].node.id == id) {
          return i;
        }
      }
      return -1;
  }

  private registerQueryForUpdates(query: QueryInterface) {
    this.queryList.push(query);
    return query;
  }

  findQueryWithId(id: number): number {
    for (let i = 0; i < this.queryList.length; i++) {
      if (this.queryList[i].id == id) {
        return i;
      }
    }
    return -1;
  }

  updateQueryVariables(query: QueryInterface) {
    const index = this.findQueryWithId(query.id);
    if (index == -1) {
      this.registerQueryForUpdates(query);
    } else {
      this.queryList[index].variables = query.variables;
    }
  }


  fetchCustomOptions(clipId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: this.getOptionsQuery,
      variables: {
        clipId: clipId
      }
    }).valueChanges;
  }



  deleteClip(id: string) {
    return this.apollo.mutate({
      mutation: this.deleteClipMutation,
      variables: { clipId: id },
      update: (proxy, { data: {} }) => {
        for (const query of this.queryList) {
          try {
            // Read the data from our cache for all the queries. Throws error if doesn't exist
            const chachedResult = proxy.readQuery<any>({ query: query.query, variables: query.variables });
            let clips: any;
            if (query.query == GetClipsQuery ) {
              clips = chachedResult.mocapClips.edges;
            } else {
              clips = chachedResult.user.mocapClips.edges;
            } // Modify the caches records

            const index = this.findClipWithId(id, clips);
            if (index != -1) {
              clips.splice(index, 1);
              // Write our data back to the caches.
              proxy.writeQuery({ query: query.query, variables: query.variables, data: chachedResult });
            }
          } catch (e) { console.log(e); }
        }
      }
    });
  }

}
