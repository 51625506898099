import { Injectable } from '@angular/core';
import { SessionService } from 'app/projects/patient-view/create-session/session.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { AnalyticEvent } from './analytics/analytics.events';
import { AnalyticsService } from './analytics/analytics.service';
import { MetadataObject } from './metadata.service';

@Injectable({
    providedIn: 'root'
})
export class SessionMetadataTabsService {
  private infoToSave = {};

  constructor(
    protected readonly sessionService: SessionService,
    protected readonly analyticsService: AnalyticsService,
  ) {}

  public updateMetadataObject(dataToSave: MetadataObject): void {
    for (const key in dataToSave) {
      this.infoToSave[key] = dataToSave[key];
    }
  }

  public clearMetadataObject(): void {
    this.infoToSave = {};
  }

  public async saveMetadata(session: Session): Promise<void | string> {
    const sessionMetadata = session?.metadata || {};
      if (sessionMetadata['metadata'] === undefined) {
        sessionMetadata['metadata'] = this.infoToSave;
      } else {
        for (const key in this.infoToSave) {
          if (this.infoToSave[key] !== "") {
            sessionMetadata['metadata'][key] = this.infoToSave[key];
          } else {
            sessionMetadata['metadata'][key] = undefined;
          }
        }
      }
      session.metadata = sessionMetadata;
      return await this.updateSession(session);
  }

  public async updateSession( payload: Session): Promise<void | string> {
    try {
      await this.sessionService.update(payload.id, payload.name, payload.metadata);
    } catch (sessionUpdateError) {
      return 'Error while updating session';
    }
    this.analyticsService.trackEvent(AnalyticEvent.SESSION_EDIT);
  }
}
