import { Injectable } from '@angular/core';
import { ChartConfig } from 'app/shared/chart/echarts.service';

/**
 * Provides logic for identifying which charts are available for a given trial (internal name: clip/mocapclip).
 */
@Injectable({
  providedIn: 'root'
})
export class ColorService {
  readonly threeDPlayerLeft = 0xff0000; //red
  readonly threeDPlayerRight = 0x00ff00; //green
  readonly threeDPlayerForceOverlayVectors = [0xff7300, 0xd0d000, 0xff0073];
  readonly playheadLeft = '#c80000';
  readonly playheadRight = '#00c800';
  readonly playbarEventLeft = 'rgba(200,0,0,1)';
  readonly playbarEventLeftTransparent = 'rgba(200,0,0,0.5)';
  readonly playbarEventRight = 'rgba(0,200,0,1)';
  readonly playbarEventRightTransparent = 'rgba(0,200,0,0.5)';
  readonly playbarEventCurrentTime = 'rgba(0,205,201,1)';
  readonly playbarMiddleBar = "#c8c8c8";
  readonly fullscreenLegendColor = '#F9F9F9';
  readonly videoForceOverlayColor = '#ff00ff';
  readonly left: string = '#ff4a4a'; // red
  readonly right: string = '#55ff4a'; // green
  readonly blue: string = '#4396ff';
  readonly gray: string = '#aaaaaa';
  readonly cyan: string = '#00cdc9';
  readonly purple: string = '#5755ff';
  readonly orange: string = '#ff7300';
  readonly brown: string = '#cc690c';
  readonly magenta: string = '#db5aba';
  readonly pink: string = '#DE369D';
  readonly yellow: string = '#F4D06F';
  readonly chartBackground = "#404040";
  readonly referenceColor: string = '#ffffff';
  readonly groupNamesForBodyMassWarningIcon = ['Moments', 'Powers', 'Forces', 'Lengths and velocities', 'Force plates'];
  public defaultColorsChart = [this.right, this.cyan, this.blue, this.magenta, this.yellow, this.orange, this.left, this.gray, this.pink, this.purple];
  public defaultColorsConsistency = [
  '#FF6633', '#FF33FF', '#FFFF57', '#00B3E6',
  '#B34D4D', '#99FF99', '#1658FF', '#E6B333', '#80B300',
  '#E6B3B3', '#6680B3', '#FF99E6', '#CCFF1A', '#FF1A66',
  '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000',
  '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF',
  '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#CC9999',
  '#B3B31A', '#00E680', '#4D8066', '#809980',
  '#E6FF80', '#1AFF33', '#999933', '#FF3380', '#CCCC00',
  '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380',
  '#FF4D4D', '#99E6E6', '#6666FF'];

  private defaultChartConfig: ChartConfig = {
    leftColor: '#ff4a4a',
    rightColor: '#55ff4a',
    neutralColor: '#4396ff',
    referenceColor: '#ffffff',
    activeCycleWidth: 2.75,
    inactiveCycleWidth: 0.75,
  }

  public getDefaultChartConfig(): ChartConfig {
    return this.defaultChartConfig;
  }
}
