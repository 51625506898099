import { Injectable } from '@angular/core';
import { AdditionalDataService } from 'app/core/additional-data.service';
import { ClipUploaderService, CreatedClipWithData } from 'app/core/clip-uploader.service';
import { Session } from 'app/projects/patient-view/create-session/session.types';
import { DirectoryOptions } from 'app/projects/project-info/project-info-data.service';
import { SideSelection } from 'app/projects/report/report.types';
import { InvalidTrialData, RepresentativeTrialData } from 'app/projects/trial-template.service';
import { parse } from 'ini';
import { DirectoryUploadService, JSONString } from './directory-upload.service';
import { UploadOptionEnum } from './upload-options.enum';

const trialSuffixRegex = /\.Trial([0-9]*)\.enf$/; // regex to retrieve .enf files

export interface Trial {
  name: string;
  hasCharts: boolean;
  hasVideo: boolean;
  condition: string;
  notes: string;
  forcePlates: string;
  plane: string;
  side: string;
  date: Date;
}


export interface TrialCustomOptions {
  trialTemplate: {
    contextForcePlates?: JSONString;
    side?: JSONString;
    plane?: JSONString;
    representativeTrial?: RepresentativeTrialData;
    invalidTrialData?: InvalidTrialData;
  };
}


interface TrialEnf {
  TRIAL_INFO: {
    DESCRIPTION?: string;
    NOTES?: string;
    FORCE_PLATES?: string;
    PLANE?: string;
    SIDE?: string;
    CREATIONDATEANDTIME?: string;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ViconDirectoryService extends DirectoryUploadService {
  private files: File[];
  private options: DirectoryOptions = {
    filesFilter: ['.avi'],
    requireC3D: false,
    requireData: false,
    dataFileFormat: 'c3d',
  };
  private videoExtensions = ['.avi'];
  public UploadOptionEnum = UploadOptionEnum;

  constructor(
    private readonly clipUploader: ClipUploaderService,
    private readonly additionalData: AdditionalDataService,
  ) {
    super();
  }

  public getProviderName(): string {
    return 'Vicon';
  }

  public setFiles(newFiles: File[]): void {
    this.files = newFiles;
  }

  public setOptions(options: DirectoryOptions): void {
    if (typeof this.options === 'object') {
      this.options = {
        filesFilter: options.filesFilter || this.videoExtensions,
        requireC3D: options.requireC3D === true,
        requireData: options.requireData === true,
        dataFileFormat: options.dataFileFormat || 'c3d',
      };
    }
  }

  private getTrialFiles() {
    return this.filterByRegexSuffix(this.files, trialSuffixRegex);
  }

  /**
   * Returns single trial data information
   * @param trialEnf
   * @param gcdName
   * @returns
   */
  private async getTrialData(trialEnf: File): Promise<Trial> {
    const trialData = await this.parseTrialEnfFile(trialEnf);
    const trialName = this.removeSuffix(trialEnf.name, trialSuffixRegex);

    // check if the trial must be uploaded in a specific condition based on the
    // uploadConfig file instead of the trial description inside the .enf file
    let conditionFromConfig: string;
    if (this.uploadConfig.conditionDefinition) {
      // find trial in upload condition def
      for (const c in this.uploadConfig.conditionDefinition) {
        if (this.uploadConfig.conditionDefinition[c].includes(trialName)) {
          conditionFromConfig = c;
        }
      }
    }

    return {
      name: trialName,
      notes: trialData.TRIAL_INFO.NOTES,
      condition: conditionFromConfig !== undefined ? conditionFromConfig : trialData.TRIAL_INFO.DESCRIPTION || 'Trials',
      forcePlates: trialData.TRIAL_INFO.FORCE_PLATES,
      plane: trialData.TRIAL_INFO.PLANE,
      side: trialData.TRIAL_INFO.SIDE,
      hasCharts: this.hasCharts(this.files, trialName),
      hasVideo: this.hasVideo(this.files, trialName),
      date: this.parseDateFromViconFormat(trialData.TRIAL_INFO.CREATIONDATEANDTIME)
    };
  }

  public async getTrials(): Promise<Trial[]> {
    const trialFiles: File[] = this.getTrialFiles(); // retrieve .enf files (trial files)
    const trials: Trial[] = [];
    for (const trialFile of trialFiles) { // for each .enf file
      if (this.options.requireC3D === true || this.options.requireData === true) {
        const trialName = this.removeSuffix(trialFile.name, trialSuffixRegex);
        if (this.hasCharts(this.files, trialName)) {
          trials.push(await this.getTrialData(trialFile));
        }
      } else {
        trials.push(await this.getTrialData(trialFile));
      }
    }

    /**
     * if this.options.filesFilter contains .pdf, add additional files to trials
     * Introduced in https://gitlab.com/moveshelf/mvp/-/issues/3020
     */
    if (this.options.filesFilter.includes('.pdf')) {
      const pdfFiles = this.files.filter(file => file.name.endsWith('.pdf'));
      pdfFiles.forEach(pdf => {
        trials.push({
          name: pdf.name.replace('.pdf', ''),
          notes: '',
          condition: 'Additional files',
          forcePlates: '',
          plane: '',
          side: '',
          hasCharts: false,
          hasVideo: false,
          date: new Date() // set to now so it appears at the end of the list
        });
      });
    }

    return trials;
  }

  private hasVideo(files: File[], trialName: string): boolean {
    return this.getVideosForTrialName(files, trialName).length > 0;
  }

  private hasCharts(files: File[], trialName: string): boolean {
    return this.getDataFilesForTrialName(files, trialName).length > 0;
  }

  private getDataFilesForTrialName(files: File[], trialName: string): File[] {
    const findGCDs = this.options.dataFileFormat === 'gcd';
    if (findGCDs === true) {
      const filesToIgnore = this.findFilesToIgnore(files, trialName);
      const validSuffix = [this.options.dataFileFormat];
      const allDataFiles = files.filter(file => file.name.startsWith(trialName) && this.isFileSuffixValid(file.name, validSuffix));
      return allDataFiles.filter(item => filesToIgnore.indexOf(item) < 0);
    } else {
      const trialNameToCheck = trialName !== undefined && this.options.dataFileFormat !== undefined ? `${trialName.toLowerCase()}.${this.options.dataFileFormat.toLowerCase()}` : trialName;
      return files.filter(file => (file.name.toLowerCase() === trialNameToCheck));
    }
  }

  private findFilesToIgnore(files: File[], trialName: string): File[] {
    // Find other .enf files that start with the current trial.enf and if found, find files associated to that enf so we don't add duplicates
    const trialEnfFiles = this.getTrialFiles();
    const matchingEnfFiles = trialEnfFiles.filter(file => file.name.startsWith(trialName));
    const filesToIgnore: File[] = [];
    if (matchingEnfFiles.length > 1) {
      const trialEnfName = trialName + '.Trial.enf';
      for (const trialEnfFile of matchingEnfFiles) {
        if (trialEnfFile.name !== trialEnfName) {
          const trialNameToCheck = this.removeSuffix(trialEnfFile.name, trialSuffixRegex);
          filesToIgnore.push(...files.filter(file => (file.name.startsWith(trialNameToCheck))));
        }
      }
    }
    return filesToIgnore;
  }

  private getVideosForTrialName(files: File[], trialName: string): File[] {
    return this.filterTrialFiles(files, trialName, this.options.filesFilter);
  }

  private filterTrialFiles(files: File[], trialName: string, validSuffixes: string[]): File[] {
    const filesToIgnore = this.findFilesToIgnore(files, trialName);
    const allDataFiles = files.filter(file => (file.name.startsWith(trialName + '.') || file.name.startsWith(trialName + '-')) && this.isFileSuffixValid(file.name, validSuffixes));
    return allDataFiles.filter(item => filesToIgnore.indexOf(item) < 0);
  }

  /**
   * Retrives the files to upload for a specific trial
   * @param files
   * @param trialName
   * @returns an array of files that will be uploaded
   */
  private getUploadsForTrialName(files: File[], trialName: string): File[] {
    const trialNameWithoutExt = trialName.substring(0, trialName.lastIndexOf('.')) || trialName;
    // retrive all files to potentially upload for a specific trial (data files, video files ecc). This is filterd by the filesFilter Options
    const uploads = this.getDataFilesForTrialName(files, trialNameWithoutExt).concat(this.filterTrialFiles(files, trialName, this.options.filesFilter));
    /**
     * Filter files based on the uploadOption.
     * 1: split into video and non-video files.
     * 2: only return what is needed based on uploadOptions
     */
    if (this.uploadOption === this.UploadOptionEnum.VideosOnly || this.uploadOption === this.UploadOptionEnum.NonVideosOnly) {
      const { videos, nonVideos } = this.splitFilesByType(uploads);
      if (this.uploadOption === this.UploadOptionEnum.VideosOnly) {
        return videos;
      } else {
        return nonVideos;
      }
    } else {
      return uploads;
    }
  }

  private filterByRegexSuffix(files: File[], suffixRegex: RegExp) {
    return files.filter((file) => {
      return suffixRegex.test(file.name);
    });
  }

  private removeSuffix(filename: string, suffixRegex: RegExp) {
    // Note that Vicon ENF nodes do not have a NAME entry in their
    // contents. This is why we rely on slicing the file name.
    if (suffixRegex.test(filename)) {
      filename = filename.replace(suffixRegex, '');
    }
    return filename;
  }

  private async parseTrialEnfFile(trialEnfFile: File): Promise<TrialEnf> {
    const fileContent = await trialEnfFile.text();
    const parsedContent: { [key: string]: string } = parse(fileContent);
    if (typeof parsedContent?.TRIAL_INFO !== 'object') {
      // Invalid Enf
      throw TypeError('Invalid Trial enf file!');
    }

    if (parsedContent && parsedContent.TRIAL_INFO) {
      let planeStr: string = parsedContent.TRIAL_INFO['PLANE'];
      if (planeStr !== undefined && planeStr.length === 0) {
        planeStr = 'sag';
      }

      return {
        TRIAL_INFO: {
          DESCRIPTION: parsedContent.TRIAL_INFO['DESCRIPTION'],
          NOTES: parsedContent.TRIAL_INFO['NOTES'],
          FORCE_PLATES: this.parseForcePlatesConfiguration(parsedContent.TRIAL_INFO),
          SIDE: parsedContent.TRIAL_INFO['SIDE'],
          PLANE: planeStr,
          CREATIONDATEANDTIME: parsedContent.TRIAL_INFO['CREATIONDATEANDTIME'],
        }
      };
    } else {
      return null;
    }
  }

  private parseForcePlatesConfiguration(trialInfo: Record<string, string>): JSONString {
    const forcePlatesSettings = {};
    for (const trialInfoKey in trialInfo) {
      if (!trialInfoKey.startsWith('FP')) continue;
      forcePlatesSettings[trialInfoKey] = trialInfo[trialInfoKey];
    }
    if (Object.keys(forcePlatesSettings).length === 0) {
      return undefined;
    }
    return JSON.stringify(forcePlatesSettings);
  }

  /**
   * Reads a serie of JSON stringified template settings and produce a full trial custom object
   * @returns a JSON object with a full trial template or undefined
   */
  private buildTrialCustomOptions(
    forcePlatesConfig: JSONString,
    side: JSONString,
    plane: JSONString
  ): TrialCustomOptions | undefined {
    if (!forcePlatesConfig && !side && !plane) {
      return undefined;
    }
    let contextFpStr = '';
    let sideStr = '';
    let planeStr = '';
    const commaStr = ', ';
    let addComma = false;
    if (forcePlatesConfig) {
      contextFpStr += `"contextForcePlates": ${forcePlatesConfig}`;
      addComma = true;
    }

    if (side) {
      if (addComma) {
        sideStr += commaStr;
      }
      sideStr += `"side": "${side}"`;
      addComma = true;
    }

    if (plane) {
      if (addComma) {
        planeStr += commaStr;
      }
      planeStr += `"plane": "${plane}"`;
      addComma = true;
    }
    return JSON.parse('{"trialTemplate": {' + contextFpStr + sideStr + planeStr + '}}');
  }

  public async upload(session: Session): Promise<void> {
    const clipsToCreate: Promise<CreatedClipWithData>[] = [];

    // for each trial (mocapClip)
    for (const trial of await this.getTrials()) {
      const metadata = {
        title: trial.name,
        projectPath: `${session.projectPath}${trial.condition || 'Trials'}/`,
        description: trial.notes,
      };

      let trialCustomOptions: TrialCustomOptions = this.buildTrialCustomOptions(
        trial.forcePlates,
        trial.side,
        trial.plane
      );

      // if there is an uploadConfig, we check if there are specific
      // settings for this trial
      if (this.uploadConfig) {
        if (trialCustomOptions === undefined) {
          trialCustomOptions = { 'trialTemplate': {} }; // create a new trialTemplate if it doesn't exist
        }
        // check if uploadConfig has specific representative trial for this
        const representativeTrialDataFromUpload = this.uploadConfig.representativeTrials?.[trial.name];
        if (representativeTrialDataFromUpload) {
          // if so, add it to the trial's customOptions inside trialTemplate
          trialCustomOptions['trialTemplate']['representativeTrial'] = {};
          if (representativeTrialDataFromUpload.includes(SideSelection.LEFT)) {
            trialCustomOptions['trialTemplate']['representativeTrial'][SideSelection.LEFT] = true;
          }
          if (representativeTrialDataFromUpload.includes(SideSelection.RIGHT)) {
            trialCustomOptions['trialTemplate']['representativeTrial'][SideSelection.RIGHT] = true;
          }
        }
        // check if uploadConfig has specific invalid trial data for this trial
        const invalidTrialDataFromUpload = this.uploadConfig.invalidTrials?.[trial.name];
        if (invalidTrialDataFromUpload) {
          // if so, add it to the trial's customOptions inside trialTemplate
          trialCustomOptions['trialTemplate']['invalidTrialData'] = invalidTrialDataFromUpload;
        }
      }

      if (trialCustomOptions) {
        metadata['customOptions'] = JSON.stringify(trialCustomOptions);
      }
      clipsToCreate.push(this.clipUploader.createClip(session.project.name, metadata).toPromise());
      // Throttle down clips creation request by allowing at least 0.5s to pass. This avoids sending
      // all requests at once.
      await this.delay(500);
    }

    try {
      for await (const createdClip of clipsToCreate) {
        const clip = createdClip.data.createClips.response[0].mocapClip;
        const uploads = this.getUploadsForTrialName(this.files, clip.title);
        await this.additionalData.uploadMultipleAdditionalDataWithoutType(clip.id, uploads).toPromise();
      }
    } catch (clipOrDataCreationError) {
      // for..await will throw on the first clip creation exception and won't continue the process.
      // Errors on additional data creation are not currently blocking.
      console.error('Error while creating clip or data');
      console.debug('Error while creating clip or data', clipOrDataCreationError);
    }
  }

  // returns the list of files to upload
  public async getExpectedUploads(): Promise<File[]> {
    const expectedUploads: File[] = [];
    for (const trial of await this.getTrials()) {
      expectedUploads.push(...this.getUploadsForTrialName(this.files, trial.name));
    }
    return expectedUploads;
  }

  /**
   * Loops through current trials and generate a list of the current conditions sorted by date
   * @see #2185 for details on logic
   */
  public async getSortedConditions(): Promise<string[]> {
    const trials = await this.getTrials();
    const conditionsDates = new Map<string, Date>();

    // Map each condition with the earliest trial's date it contains
    for (const trial of trials) {
      if (!conditionsDates.get(trial.condition)) {
        conditionsDates.set(trial.condition, trial.date);
      } else if (conditionsDates.get(trial.condition) > trial.date) {
        conditionsDates.set(trial.condition, trial.date);
      } else {
        continue;
      }
    }

    // Sort the mapped condition by date and return their names only, as an array
    const sortedConditions = [...conditionsDates.entries()].sort((c1, c2) => {
      return c1[1] < c2[1] ? -1 : 1;
    });
    const sortedConditionsArray = sortedConditions.map(tuple => {
      return tuple[0];
    });

    return sortedConditionsArray;
  }

  /**
   * Dates in Vicon enf files are comma separated numbers in descending order, up to seconds.
   * There is not timezone information
   * i.e. 2020, 3, 25, 15, 12, 11
   */
  private parseDateFromViconFormat(viconDate: string): Date {
    if (!viconDate) return undefined;

    const p = viconDate.split(',');
    if (p.length !== 6) return undefined;

    const parsedDate = new Date(Date.UTC(+p[0], +p[1] - 1, +p[2], +p[3], +p[4], +p[5]));

    return parsedDate;
  }

}
