import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EmailAuthProvider, User as FirebaseUser } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { EnvironmentService } from 'app/shared/services/environment.service';
import { OrganizationService, PersistenceState } from 'app/shared/services/organization/organization.service';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, throwError as _throw, firstValueFrom, interval, of as observableOf, throwError } from 'rxjs';
import { catchError, filter, first, map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { UserMetadataFragment } from '../shared/graphql-fragments/user-metadata';

export interface UpdateViewerInput {
  username?: string;
  email?: string;
  displayName?: string;
  avatar?: string;
  termsAndConditions?: boolean;
}

const query = gql`
  query {
    viewer {
      projects {
        id,
        name
      }
      organizations {
        name
      }
      stripeId
      email
      role
      ... UserMetadata
    }
  }
  ${UserMetadataFragment}
`;

const mutation = gql`
  mutation updateViewer(
      $inputs: UpdateViewerInput) {
    updateViewer(userData: $inputs) {
      user {
        id,
        username,
        email,
        tier,
        displayName,
        avatar
      }
    }
  }
`;

interface ViewerResponse {
  viewer: {
    id: string;
    username: string;
    email: string;
    avatar: string;
    tier: string;
    role: string;
    organizations: {
      name: string;
    };
    stripeId: string;
    displayName: string;
  };
}

interface ViewerUpdateResponse {
  updateViewer: {
    user: {
      id: string;
      email: string;
      username: string;
      displayName: string;
      avatar: string;
      tier: string;
    }
  };
}

export interface SignUpDetails extends UpdateViewerInput {
  password: string;
  beta?: boolean;
  username?: string;
  // invitation?: boolean;
}

export enum AuthState {
  Uninitialized = 'UNINITIALIZED',
  LoginRequested = 'LOGIN_REQUESTED',
  LoggingIn = 'LOGGING_IN',
  FetchingUserDetails = 'FETCHING_USER_DETAILS',
  UserDetailsMissing = 'USER_DETAILS_MISSING',
  RedeemInvitation = 'REDEEM_INVITATION',
  SigningUp = 'SIGNING_UP',
  LoggedIn = 'LOGGED_IN',
  LoggedOut = 'LOGGED_OUT',
  ServerSide = 'SERVER_SIDE'
}

@Injectable()
export class AuthService {

  private idToken: string;
  private userId: string;
  private userRole: string;
  private user: FirebaseUser;
  private username: string;
  private displayName: string;
  private stripeId: string;
  private userTier: string;
  private _state: BehaviorSubject<AuthState>;

  private signUpRef;
  public verificationEmailRequested: boolean = false;

  public paymentCards: any[] = [];

  public organization: any;

  public tempEmail: string;
  public invitationInfo: { email: string, redirectUrl: string };
  public identified = new Promise<void>(resolve => this.identifiedResolver = resolve);
  private identifiedResolver;
  public currentPersistance: PersistenceState = PersistenceState.local;

  private readonly env;

  constructor(
    private apollo: Apollo,
    private fireAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
    private organizationService: OrganizationService,
    private environment: EnvironmentService,
    @Inject(PLATFORM_ID) private readonly platformId,
  ) {
    this.env = this.environment.getEnvironment();
    if (isPlatformBrowser(this.platformId)) {
      this._state = new BehaviorSubject<AuthState>(AuthState.Uninitialized);
      this.fireAuth.onIdTokenChanged(async user => {
        const token = await user?.getIdToken();
        this.idToken = token;
      });

      interval(60 * 1000).subscribe(() => {
        if (this.user) {
          this.user?.getIdToken();
        }
      });

      this.fireAuth.onAuthStateChanged(user => {
        this.user = user;

        if (user) {
          if (this._state.getValue() != AuthState.SigningUp) {
            this.fetchUsername();
          }
        } else {
          this.username = null;
          this._state.next(AuthState.LoggedOut);
        }
      });
    } else {
      this._state = new BehaviorSubject<AuthState>(AuthState.ServerSide);
    }
  }

  /*public createInvitation(email: string): Promise<string> {
    return new Promise( (resolve) => {
    let invitationNumber = '001';
    let tempUsername = this.tempUsername + invitationNumber;
    this.fireAuth.auth.createUserWithEmailAndPassword(
      email,
      this.tempPassword
    ).then( () => {
      this.apollo.mutate<ViewerUpdateResponse>({
        mutation: mutation,
        variables: {inputs: {
          username: tempUsername,
          password: this.tempPassword,
          invitation: true }}
      }).subscribe( () => {
        resolve( this.tempUsername );
      });
    });
  });
  }*/

  public setPersistence(persistanceState: PersistenceState) {
    this.currentPersistance = persistanceState;
    this.fireAuth.setPersistence(this.currentPersistance.toString());
  }

  public checkIfUserExist(email: string) {
    return this.fireAuth.fetchSignInMethodsForEmail(email);
  }

  public signUp(userDetails: SignUpDetails, sendEmailVerification = false): Promise<FirebaseUser> {
    this._state.next(AuthState.SigningUp);
    return this.fireAuth.createUserWithEmailAndPassword(
      userDetails.email,
      userDetails.password
    ).then(user => {
      if (!sendEmailVerification) {
        return;
      }
      return user.user.sendEmailVerification({
        url: this.env.baseHref
      }).then(() => {
        return this.fireAuth.idToken.pipe(
          take(1),
          mapTo(user.user)
        ).toPromise();
      });
    }).catch(err => {
      return Promise.reject(err);
    });
  }

  public isWebsiteUrl() {
    const url = this.router.url;

    if (url === '') {
      return true;
    }

    const roots = ['/home',
      '/about',
      '/character-animation',
      '/movement-science',
      '/pricing',
      '/legal',
      '/contact'
    ];
    for (const r of roots) {
      if (url.startsWith(r)) {
        return true;
      }
    }
  }

  private isLoginUrl() {
    return this.router.url.startsWith('/login');
  }

  public redirectToApp(url = 'dashboard'): void {
    if (this.isWebsiteUrl() || this.isLoginUrl()) {
      this.router.navigateByUrl(url);
    }
  }

  public authApiCall(endpoint: string, params: any, token?: any) {
    if (token == undefined) {
      token = this.getAuthToken();
    }

    const httpOptions = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
    };
    return this.http.post(
      this.env.privateApiUrl + endpoint, params,
      httpOptions
    );
  }

  public completeSignup(userDetails: SignUpDetails) {
    delete userDetails.password;
    return this.apollo.mutate<ViewerUpdateResponse>({
      mutation: mutation,
      variables: { inputs: userDetails }
    }).pipe(
      tap(({ data }) => {
        this.userId = data.updateViewer.user.id
        this.username = data.updateViewer.user.username;
        this.userTier = data.updateViewer.user.tier;
        this._state.next(AuthState.LoggedIn);
      })
    ).toPromise();
  }

  /**
   * Returns the status of the current firebase user email verification. This is only used in
   * self registration scenarios when browsing app.moveshelf.com, as enterprise users are always
   * verified by Moveshelf (either via invitation redeem or SSO provisioning).
   */
  public getVerificationState(): boolean {
    if (!this.user || this.organizationService.onEnterpriseDomain()) {
      // If there is no current user or it is on an enterprise domain, ignore email verification
      return true;
    }

    return this.user.emailVerified;
  }

  public async sendVerification() {
    if (this.user) {
      this.verificationEmailRequested = true;
      (await this.fireAuth.currentUser).sendEmailVerification({
        url: this.env.baseHref
      }).then(() => {
        this.verificationEmailRequested = false;
      });
    }
  }

  public get state(): Observable<AuthState> {
    return this._state.asObservable();
  }

  public get stableState(): Promise<AuthState> {
    return this.state.pipe(
      filter(state => {
        switch (state) {
          case AuthState.LoggedIn:
          case AuthState.LoggedOut:
          case AuthState.ServerSide:
            return true;

          default:
            return false;
        }
      }),
      first()
    ).toPromise();
  }

  public setSignUpRef(signup) {
    this.signUpRef = signup;
  }

  public getSignUpRef() {
    return this.signUpRef;
  }

  isAuthenticated(username?: string): boolean {
    if (this.user) {
      return username ? this.username === username : true;
    }
    return false;
  }

  async getAuthToken(): Promise<string | null> {
    return await this.idToken || await this.user?.getIdToken();
  }

  getAuthenticatedUsername(): string | null {
    return this.username;
  }

  getAuthenticatedUserId(): string | null {
    return this.userId;
  }

  public getAuthenticatedUserRole(): string {
    return this.userRole;
  }

  getAuthenticatedDisplayName(): string | null {
    return this.displayName;
  }

  getStripeId(): string | null {
    return this.stripeId;
  }

  isTesterAuthenticated(): boolean {
    return this.userTier == 'Tester';
  }

  isBasicAuthenticated(): boolean {
    return this.userTier == 'BasicTier';
  }

  isTeamAuthenticated(): boolean {
    return this.userTier == 'TeamTier';
  }

  isEnterpriseAuthenticated(): boolean {
    return this.userTier == 'EnterpriseTier';
  }

  public async isSMKAuthenticated(): Promise<boolean> {
    const org = await this.organizationService.getCurrentOrganization();
    return org?.domain?.startsWith('smk');
  }

  hasAccessToSubjects() {
    if (this.isTesterAuthenticated()) {
      return true;
    }

    if (this.organizationService.onEnterpriseDomain() || this.isEnterpriseAuthenticated()) {
      return true;
    }

    return false;
  }

  getAuthenticatedUserEmail(): string | null {
    return this.user.email;
  }

  public login(): Promise<boolean> {
    return this.state.pipe(
      filter(state => {
        switch (state) {
          case AuthState.LoggedIn:
          case AuthState.LoggedOut:
          case AuthState.ServerSide:
            return true;

          default:
            return false;
        }
      }),
      take(1),
      mergeMap(state => {
        switch (state) {
          case AuthState.LoggedIn:
            return observableOf(true);

          case AuthState.LoggedOut:
            this._state.next(AuthState.LoginRequested);
            return this.state.pipe(
              filter(state => state === AuthState.LoggedIn || state === AuthState.LoggedOut),
              take(1),
              map(state => state === AuthState.LoggedIn)
            );

          case AuthState.ServerSide:
            const err = new Error('Auth not available on server'); 
            throwError(() => err);
        }
      })
    ).toPromise();
  }

  public cancelLogin() {
    this._state.next(AuthState.LoggedOut);
    this.fireAuth.signOut();
  }

  public async changeFirebaseEmailAddress(newEmail: string, password: string): Promise<void> {
    if (this.user && this.user.email) {
      const credential = EmailAuthProvider.credential(this.user.email, password);
      await (await this.fireAuth.currentUser).reauthenticateWithCredential(credential);
      await (await this.fireAuth.currentUser).updateEmail(newEmail);
    }
  }

  public submitLogin(username: string, password: string): Promise<FirebaseUser> {
    this._state.next(AuthState.LoggingIn);
    return this.fireAuth.signInWithEmailAndPassword(username, password).then((credentials) => credentials.user);
  }

  logOut(inactive: boolean = false): Promise<void> {
    return this.fireAuth.signOut().then(() => {
      this.apollo.client.cache.reset();
      let params = {};

      if (inactive) {
        params["queryParams"] = { inactive: true };
      }

      this.router.navigate(["login"], params);
    });
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const credential = EmailAuthProvider.credential(this.user.email, oldPassword);
    await (await this.fireAuth.currentUser).reauthenticateWithCredential(credential);
    await (await this.fireAuth.currentUser).updatePassword(newPassword);
  }

  public resetPassword(email: string, url: string): Promise<void> {
    return this.fireAuth.sendPasswordResetEmail(email, { url: url });
  }

  public removeOutletAndNavigate(url: string): Promise<boolean> {
    return this.router.navigate([{ outlets: { overlay: null } }]).then(() => {
      return this.router.navigate([url]);
    });
  }
  public completePasswordReset(newPassword: string, code: string, url: string): Promise<any> {
    return this.fireAuth.verifyPasswordResetCode(code).then(email => {
      return this.fireAuth.confirmPasswordReset(code, newPassword)
        .then(() => {
          this.removeOutletAndNavigate(url).then(() => { this.submitLogin(email, newPassword); });
        });
    });
  }

  public submitRedeem(email: string) {
    return this.fireAuth.signInWithEmailAndPassword(email, '12345678');
  }

  async handleRecoverEmail(actionCode: string) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    try {
      const info = await this.fireAuth.checkActionCode(actionCode);
      restoredEmail = info['data']['email'];
      await this.fireAuth.applyActionCode(actionCode);
      return restoredEmail;
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case "auth/invalid-action-code":
          throw new Error("Invalid code!");
      }
      // Invalid code
    }
  }

  public handleRedeem(email: string, url: string) {
    // this.tempEmail = email;
    this.invitationInfo = { email: email, redirectUrl: url };
    this._state.next(AuthState.RedeemInvitation);
  }

  public handleEmailVerification(actionCode: string, redirect?: string) {
    this.fireAuth.applyActionCode(actionCode).then(async () => {

      (await this.fireAuth.currentUser).reload().then(() => {
        // correctly verified
      });
      if (redirect) {
        this.removeOutletAndNavigate(redirect);
      }
    });
  }

  public patchUserTierCache(newTier: string) {

    if (newTier.indexOf('ProTier') != -1) {
      this.userTier = 'ProTier';
    }

    if (newTier.indexOf('TeamTier') != -1) {
      this.userTier = 'TeamTier';
    }

    return this.userTier;
  }

  private async fetchUsername(): Promise<void> {
    this._state.next(AuthState.FetchingUserDetails);
    const viewer = await firstValueFrom(this.apollo.query<ViewerResponse>({ query: query }).pipe(
      map(({ data }) => {
        return data.viewer;
      }),
      catchError(err => observableOf(null))
    ));
    if (viewer) {
      this.stripeId = viewer.stripeId;
      this.userId = viewer.id;
      this.userRole = viewer.role;
      this.username = viewer.username;
      this.displayName = viewer.displayName;
      this.userTier = viewer.tier;
      this._state.next(AuthState.LoggedIn);
    } else {
      this._state.next(AuthState.UserDetailsMissing);
    }
    this.identifiedResolver();
  }
}
