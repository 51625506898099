import { AdditionalDataInfo, UploadStatus } from "app/core/additional-data.service"

export interface ClipUserMetadata
{
  title?: string,
  description?: string,
  public?: boolean
  previewImageUri?: string
  project?: string,
  projectPath?: string,
  license?: string,
  allowDownload?: boolean
  allowUnlistedAccess?: boolean
  defaultSelection?: {
    start: number;
    end: number;
  }
}

export interface ClipServerMetadata
{
  id?: string,
  createdAt?: string;
  previewDataUri?: string;
  originalFileName?: string;
  projectPath?: string;
  customOptions?: any;
  keyframes?: any;
  authorUsername?: string;
  uploadStatus?: UploadStatus;
  additionalData?: AdditionalDataInfo[];
}

export interface MocapClip extends ClipServerMetadata, ClipUserMetadata {

}
