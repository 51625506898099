import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClipUpdateService } from '../core/clip-update.service';
import { CommentsFeedComponent } from './comments-feed.component';
import { MocapClip } from './mocap-clip';
import { MoreActionsComponent, MoreActionsEvent } from './more-actions.component';

@Component({
  selector: 'app-clip-info',
  templateUrl: './clip-info.component.html',
  styleUrls: ['./clip-info.component.scss'],
})
export class ClipInfoComponent implements OnInit {
  @Input() clip_info: MocapClip;
  @Input() allow_edit: boolean = false;
  @Input() include_comments: boolean = false;

  @Output() destroyCheck: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('commentsFeed') commentsFeed: CommentsFeedComponent;
  @ViewChild('moreActions') moreActions: MoreActionsComponent;
  @ViewChild('inputTitle') inputTitle: ElementRef;

  constructor(private clipUpdateService: ClipUpdateService) { }

  ngOnInit() {
  }

  updateTitle(title: string) {
    this.clipUpdateService.updateClip({id: this.clip_info.id, metadata: {title: title}}).subscribe();
  }

  deleteClip() {
    this.clipUpdateService.deleteClip(this.clip_info.id).subscribe(data => this.destroyCheck.emit('destroyed'));
  }

  commentsClicked() {
    this.commentsFeed.toggleShowComments();
  }

  moreActionsClick(event: MoreActionsEvent) {
    switch (event) {
      case MoreActionsEvent.Edit:
        this.moreActions.showConfirm();
        break;
      case MoreActionsEvent.Delete:
        this.deleteClip();
        break;
      case MoreActionsEvent.Approve:
        this.updateTitle(this.inputTitle.nativeElement.value);
        break;
      case MoreActionsEvent.Deny:
        this.moreActions.hideConfirm();
        break;
    }

  }
}
