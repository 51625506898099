import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AdditionalDataService } from 'app/core/additional-data.service';
import { GlobalPlaybackControlService } from 'app/core/playback-controls/global-playback-control.service';
import { ClipLoaderService } from 'app/moveshelf-3dplayer/clip-loader/clip-loader.service';
import { ClipPlayerOptions } from 'app/moveshelf-3dplayer/clip-player';
import { SessionAndConditionObject } from 'app/projects/patient-view/create-session/session.service';
import { ForcePlateContext, ForcePlateContextTemplate, TrialTemplate } from 'app/projects/trial-template.service';
import { Subject } from 'rxjs';
import { ChartGroup, DataTrack, Event, GaitParameter, GaitParameterTable, LineChartGroup, LineChartMajorGroup, Sample, SwingTransition, Tracks } from '../chart/chart.types';
import { CyclePoint, DataContext, DataHelper, VideoForceMap, VideoForceProjection } from '../data-helper';
import { ParamRecord, ParamTableRow, ParamWithOrigin } from '../gait-parameter-table/gait-parameter-table.component';
import { VideoForceOverlayService } from '../multi-media-player/video-force-overlay/video-force-overlay.service';
import { ChartTemplateGroup } from '../services/charts-templates/charts-templates.service';
import { ColorService } from '../services/color-service/color-service.service';

export interface TrialTracksGroup {
  [key: string]: TrialTracks;
}

export interface TrialTracks {
  [key: string]: DataTrack;
}

interface ClipParsingOptions {
  skipMox: boolean,
  fetchTimeBasisCharts: boolean,
  applyChartTemplate: boolean,
  cameraNamesForDlt: string[][],
  applyRegularGcdCycles: boolean,
}

interface ClipDetails {
  tracks: Tracks,
  customOptions: any;
}

export interface VideoDataForOverlay {
  name: string,
  t_offset: number,
  force_plates: any,
  dlt_matrix_for_all_force_plates?: number[][],   // this is renamed to dlt_matrix, but kept for legacy
  dlt_matrix?: number[][],
  sensor_size: number[],
  pixel_scale: number,
  focal_point: number,
  q_gc_yup?: number[],
  q_gc_zup: number[],
  position: number[],
  principal_point: number[],
  vicon_radial: number[],  // this is used to determine Vicon model vs openCV model
  aspect_ratio: number,
  p_c_yup?: number[],
  p_s_g_3D?: number[],
  radial_distortion?: number[],
  tangential_distortion?: number[],
  H_lab?: number[][],
  k: number[][],
  k_inv: number[][],
  is_initialized: boolean,
}

export type GaitMeasureArray = GaitParameter[];

interface SensorDataOffsetInVideo {
  [x: string]: number;
}

interface MoxEvents {
  events: Event[],
  forceDataForVideo: VideoForceProjection[],
  tVideoOffset: number,
  duration: number
}

interface MoxEventClip {
  id: string,
  moxEvents: MoxEvents,
}

interface CycleSelected {
  name: string;
  tMin: number;
  isInCycle: boolean;
}

export interface RepresentativeClipPathsCondition {
  sessionName: string,
  conditionName: string,
  representativeClipPaths: RepresentativeClipPaths
}

export interface RepresentativeClipPaths {
  left: string;
  right: string;
}

interface GcdDisabledStruct {
  name: string,
  left: boolean,
  right: boolean
}

/**
 * Provides logic for identifying which charts are available for a given trial (internal name: clip/mocapclip).
 */
@Injectable({
  providedIn: 'root'
})
export class TrialChartsService implements OnDestroy {
  public defaultLineDashStyles = [[0, 0], [7, 7], [14, 2, 2, 7], [2, 2], [5, 1, 3], [1, 1], [2, 2, 20, 2, 20, 2], [4, 1], [14, 2, 7, 2], [10, 2]];
  protected currentLineStyleIndexRight = -1;
  protected currentLineStyleIdRight = '';
  protected currentLineStyleIndexLeft = -1;
  protected currentLineStyleIdLeft = '';
  protected chartMode: 'report' | 'trial' = 'trial';

  public lineCharts: LineChartGroup[] = [];
  public showLineStyles = true;
  public mergeLegendLabelsForCycles = false;
  public simplifyLegendLabelsForReport = false;
  public subjectWeight: number = undefined;
  public contextForcePlates: ForcePlateContext[] = [];    // can we use this to view/set definition in trial-view template?
  public playbarOffset = 0;

  public sensorDataOffsetInVideo = 0;
  public sensorDataOffsets: SensorDataOffsetInVideo[] = [];
  public selectedClipPath: string;

  meanDataLabel = 'mean';
  stdDataLabel = 'std';

  public totalForcePlates: Subject<ForcePlateContext[]> = new Subject<ForcePlateContext[]>();
  public showAveragesForCycles = true;
  public applyAveragePerCondition = false;
  public isConditionSummary = false;
  public normalizeToBodyWeight = false;
  public normalizeToBodyMassInsteadOfWeight = false;
  protected _has3dAvailable = false;
  readonly gravitationalAcceleration = 9.81;
  public timeSeriesMergeWarning = '';
  public allowConditionAvgToggle = false;
  public videoResolutionRatio: Record<string, number> = { default: 1 };
  public forceCyclesFromForcePlateData = true;
  // used to set the timebar duration on trial and report view, after checking if the charts are available
  public timebarDuration: number = 0;
  myForcePlates: string[] = [];
  public moxEventClips: MoxEventClip[] = [];
  public clipId: string = '';
  public trialNamesReport: string[] = [];
  private applyRegularGcdCycles: boolean = false;
  public representativeClipPathsInReport: RepresentativeClipPathsCondition[] = [];
  public disabledLines: Record<string, GcdDisabledStruct[]> = {};

  constructor(
    protected http: HttpClient,
    protected clipLoaderService: ClipLoaderService,
    protected videoForceOverlayService: VideoForceOverlayService,
    protected colorService: ColorService,
    protected additionalDataService: AdditionalDataService,
    protected globalPlaybackControlService: GlobalPlaybackControlService,
  ) { }

  public ngOnDestroy(): void {
    // no need to unsubscribe from anything
  }

  public async getDataFromId(clipId: string, options?: ClipParsingOptions, forceReload?: boolean): Promise<ClipDetails> {
    const forceReloadBool = forceReload !== undefined ? forceReload === true: false;
    const clip = await this.clipLoaderService.loadClipId(clipId, undefined, forceReloadBool);
    const tracks: Tracks = {
      charts: [],
      barCharts: [],
      reports: [],
      pdfs: []
    };
    const customOptions = clip.customOptions;
    const additionalData = clip.additionalData;
    if (clip.animation) {
      this._has3dAvailable = true;
      DataHelper.parseQualysisData(clip.animation.data, tracks.charts);
    }
    if (clip.animation && clip.animation.chartData) {
      for (const group of clip.animation.chartData) {
        tracks.charts.push(group);
      }
    }
    if (!(customOptions.charts && customOptions.charts.useCycles)) {
      await this.parseAdditionalData(additionalData, customOptions, tracks, undefined, options, clip.title, clip.id);
      // Note, the "else" is handled in the loop below.
    }

    this.disabledLines[clipId] = [];
    for (let d of additionalData) {
      // allows event.json to be picked up if manually uploaded in the trial
      if (
        d.originalFileName.toLowerCase().includes("event.json") &&
        tracks?.charts &&
        tracks.charts.length < 1
      ) {
        d = { ...d, dataType: "event" };
      }

      if (d.dataType === "event") {
        const res = await this.http.get(d.previewDataUri).toPromise();
        const parsedData = (res || {}) as any;

        if (customOptions.charts && customOptions.charts.useCycles) {
          await this.parseAdditionalData(
            additionalData,
            customOptions,
            tracks,
            parsedData.events,
            options,
            clip.title,
            clip.id,
          );
        }
        const events = parsedData.events;
        // Check if the event.json is for video + sensors
        if (
          parsedData.info &&
          parsedData.info.start_frame &&
          parsedData.info.sample_rate &&
          tracks?.charts &&
          tracks.charts.length < 1
        ) {
          // add the offset
          this.sensorDataOffsets.push({
            [clipId]: parsedData.info.start_frame / parsedData.info.sample_rate,
          });
        }

        if (tracks?.charts && events !== undefined && events.length > 0) {
          for (const chart of tracks.charts) {
            for (const track of chart.tracks) {
              if (
                track?.hasCycles &&
                track.hasCycles === true &&
                (track?.cycleTimes || events.some(x => x?.perc !== undefined))
              ) {
                track.swingTransitions = this.getSwingTransitions(
                  track,
                  events,
                  clipId
                );
              }
            }
          }
        }
      }

      // check if we have gcd with sideAdditionalData defined and update in mapping
      const sideAdditionalDataObj = customOptions?.trialTemplate?.sideAdditionalData
      if (sideAdditionalDataObj && Object.keys(sideAdditionalDataObj).includes(d.id)) {
        const sideAdditionalData = customOptions.trialTemplate.sideAdditionalData[d.id];
        const disableLeft = !sideAdditionalData.includes('left');
        const disableRight = !sideAdditionalData.includes('right');
        const gcdName = d.originalFileName.split('.gcd')[0].split('.GCD')[0];
        const gcdDisabled: GcdDisabledStruct = {
          name: gcdName,
          left: disableLeft,
          right: disableRight
        }
        let curGcd: GcdDisabledStruct = this.disabledLines[clipId].find(x => x.name === gcdName);
        this.disabledLines[clipId].filter(x => x.name !== gcdName);
        if (curGcd === undefined) {
          curGcd = gcdDisabled;
        }
        this.disabledLines[clipId].push(curGcd);
      }
    }
    return { tracks: tracks, customOptions: customOptions };
  }

  public getSwingTransitions(
    track: DataTrack,
    events: Event[],
    groupId: string,
  ): SwingTransition[] {
    const swingTransitions: SwingTransition[] = [];
    if ((track.cycleTimes && events.length > 0) || (events !== undefined && events.some(x => x?.perc !== undefined))) {
      for (const curEvent of events) {
        const swingTransitionContext = this.extractContextFromEvent(curEvent);
        const swingTransition: SwingTransition = {
          trialName: groupId,
          time: -1,
          context: swingTransitionContext,
          perc: -1,
          cycle: undefined,
          lineStyle: undefined,
          opposite: curEvent.opposite,
          gaitEventName: undefined
        };
        if (curEvent.name?.includes('Foot Off')){
          swingTransition.gaitEventName = 'Foot Off';
        } else if (curEvent.name?.includes('Foot Strike')){
          swingTransition.gaitEventName = 'Foot Strike';
        }

        if (this.isEventValidForSwingTransitionTime(curEvent)) {
          swingTransition.time = curEvent.time;
          swingTransitions.push(swingTransition);
        } else if (this.isEventValidForSwingTransitionPerc(curEvent)) {
          swingTransition.perc = curEvent.perc;
          swingTransitions.push(swingTransition);
        }
      }
    }
    return swingTransitions;
  }

  protected isEventValidForSwingTransitionTime(event: Event): boolean {
    return event.name !== undefined && event.name?.includes('Foot Off') && event.time !== undefined && event.opposite !== true;
  }

  protected isEventValidForSwingTransitionPerc(event: Event): boolean {
    return event.name !== undefined && event?.perc !== undefined  && (event.name?.includes('Foot Off') || (event.name?.includes('Foot Strike') && event.opposite === true));
  }

  protected extractContextFromEvent(event: { context?: string }): DataContext {
    return event.context?.toLowerCase().includes('right') ? DataContext.rightSide : DataContext.leftSide;
  }

  protected originalFilenameIncludes(data: any, fname: string) {
    return data.originalFileName.toLowerCase().includes(fname);
  }

  protected getKeyByValue(object: any, value: any): string {
    return Object.keys(object).find((key) => object[key] === value);
  }

  /**
   * Modifies `tracks` in place by parsing the additionalData according to options.
   */
  protected async parseAdditionalData(additionalData: any, options: ClipPlayerOptions, tracks: Tracks, events?: any, additionalOptions?: ClipParsingOptions, clipName?: string, clipId?: string): Promise<void> {
    let csvTrackNo = 1;
    const csvChartTracks = [];
    let csvGroupName = "CSV data";
    let sensorSpec = null;

    this.playbarOffset = 0;
    let videoDataForOverlay: VideoDataForOverlay[];
    let forceDataForOverlay;
    let invertForceOverlayXy = false;
    this.videoResolutionRatio = { default: 1 };
    this.applyRegularGcdCycles = additionalOptions?.applyRegularGcdCycles ? additionalOptions?.applyRegularGcdCycles : false;

    // get the sensorspec information if data are coming from modys
    for (const d of additionalData) {
      if (d.originalFileName.indexOf("sensorspec.json.txt") !== -1) {
        const parsedData = await this.http.get(d.previewDataUri).toPromise();
        sensorSpec = parsedData;
        break;
      }
    }

    for (const data of additionalData) {
      if (data.dataType === 'video') {
        const videoName = data.originalFileName !== undefined ? data.originalFileName.substring(0, data.originalFileName.lastIndexOf('.')) || data.originalFileName : 'default';
        this.videoResolutionRatio[videoName] = await this.additionalDataService.getVideoResolutionRatio(data);
      }
    }

    for (const data of additionalData) {
      if (data.uploadStatus !== 'Complete') {
        continue;
      }
      if (data.originalFileName.indexOf(".noraxon") !== -1) {
        invertForceOverlayXy = true;
      }


      if (data.dataType === 'motion' && data.originalFileName.endsWith(".mox")) {
        this._has3dAvailable = true;
        if (!additionalOptions.skipMox) {
          const parsedData = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
          const clipInfo = await this.clipLoaderService.loadClipId(clipId);
          const res = DataHelper.parseMoxClip(parsedData, clipName, clipInfo.projectPath, this.videoResolutionRatio, additionalOptions?.cameraNamesForDlt);
          if (res.chartData.length > 0) {
            for (const chartGroup of res.chartData) {
              tracks.charts.push(chartGroup);
            }
          }
          if (res.barChartData.length > 0) {
            for (const barChart of res.barChartData) {
              tracks.barCharts.push(barChart);
            }
          }

          const moxEventClip: MoxEventClip = {
            id: clipId,
            moxEvents: {
              events: res.events,
              forceDataForVideo: res.forceDataForVideo,
              tVideoOffset: res.tVideoOffset,
              duration: res.duration
            }
          };

          this.moxEventClips.push(moxEventClip);
        }
      }

      if (data.dataType === 'raw' && data.originalFileName.indexOf(".mvnx") !== -1) {
        const parsedData = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
        const chartTracks = [];
        DataHelper.parseMvnxData(parsedData, chartTracks);

        for (const group of chartTracks) {
          const chartGroup = { name: group.name, tracks: group.tracks };
          tracks.charts.push(chartGroup);
        }
      }
      if (data.dataType === 'html') {
        const parsedData = await this.http.get(data.previewDataUri, { responseType: 'text' }).toPromise();
        tracks.reports.push({ name: data.originalFileName.split('.')[0], show: false, data: parsedData });

      }
      if (data.dataType === 'doc') {
        const url = data.previewDataUri + "#toolbar=0";
        tracks.pdfs.push({ name: data.originalFileName.split('.')[0], collapsed: true, url: url });
      }

      if (data.dataType === 'force') {
        let fetchTimeBasisCharts = additionalOptions.fetchTimeBasisCharts;
        if (!this.additionalDataContains(additionalData, 'force_normalized.json')) {
          fetchTimeBasisCharts = true;
        }

        if (this.originalFilenameIncludes(data, 'force.json')) {
          // We load force data anyway since we might need it for the overlay
          const res = await this.http.get(data.previewDataUri).toPromise();
          const parsedData = (res || {}) as any;
          forceDataForOverlay = parsedData.forces;
        }

        if (fetchTimeBasisCharts) {
          if (this.originalFilenameIncludes(data, 'point_forces.json')) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, false, "Forces"));
          } else {
            const res = await this.http.get(data.previewDataUri).toPromise();
            const parsedData = (res || {}) as any;
            const chartTracks = [];
            const groupName = data.originalFileName.split('.')[0];

            forceDataForOverlay = parsedData.forces;

            for (let i = 0; i < parsedData.forces.length; i++) {
              const plateIndex = i;
              const chartData: DataTrack = {
                id: groupName + '-input#' + i,
                originalId: groupName + '-input#' + i,
                values: parsedData.forces[plateIndex].force,
                events: events,
                labels: {
                  title: "Force plate #" + (plateIndex + 1),
                  hAxis: "Time [s]",
                  vAxis: "Force [N]",
                  time: "time",
                  data: {
                    x: "Fx",
                    y: "Fy",
                    z: "Fz"
                  }
                },
                colors: undefined,
                series: [],
                dataType: 'force'
              };

              chartTracks.push(chartData);
            }

            if (chartTracks.length > 0) {
              const chartGroup = { name: 'Force plates', tracks: chartTracks };
              tracks.charts.push(chartGroup);
            }
          }
        } else {
          // continue and pick up in 'data'
          continue;
        }
      }


      if (data.dataType === 'data' && data.originalFileName.indexOf(".csv") === -1 && data.originalFileName.indexOf(".txt") === -1) {

        const ignoreParamsFile = data.originalFileName.toLowerCase().indexOf('quality_gait_params') !== -1 || data.originalFileName.toLowerCase().indexOf('gcd_params') !== -1;
        const progressionFile = data.originalFileName.toLowerCase().indexOf('general_gait_params') !== -1;
        const forceOverlayFile = data.originalFileName.toLowerCase().indexOf('force_params') !== -1 || data.originalFileName.toLowerCase().indexOf('camera_params') !== -1;
        if (data.originalFileName.toLowerCase().indexOf('params') !== -1 && !ignoreParamsFile) {
          if (forceOverlayFile) {
            videoDataForOverlay = await this.parseCameraParams(data.previewDataUri);
          } else {
            let filenameStr = '';
            if (progressionFile) {
              filenameStr = data.originalFileName;
            }
            tracks.barCharts.push(await this.parseGaitParameters(data.previewDataUri, filenameStr));
          }
        }

        let isAnalog = false;
        const hasEMG = this.additionalDataContains(additionalData, 'emg.json') || this.additionalDataContains(additionalData, 'emg_normalized.json');
        if (this.originalFilenameIncludes(data, 'analog.json') && !hasEMG) {
          isAnalog = true;
        }

        let fetchTimeBasisCharts = additionalOptions.fetchTimeBasisCharts;
        if (this.originalFilenameIncludes(data, 'angles.json')) {
          if (!this.additionalDataContains(additionalData, 'angles_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "Kinematics"));
          }
        }

        if (this.originalFilenameIncludes(data, 'moments.json')) {
          if (!this.additionalDataContains(additionalData, 'moments_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "Moments"));
          }
        }

        if (this.originalFilenameIncludes(data, 'powers.json')) {
          if (!this.additionalDataContains(additionalData, 'powers_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "Powers"));
          }
        }

        if (this.originalFilenameIncludes(data, 'muscle_lengths_velocities.json')) {
          if (!this.additionalDataContains(additionalData, 'muscle_lengths_velocities_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "Muscle lengths and velocities"));
          }
        }

        if (this.originalFilenameIncludes(data, 'emg.json')) {
          if (!this.additionalDataContains(additionalData, 'emg_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "EMG"));
          }
        }

        if (this.originalFilenameIncludes(data, 'forces.json')) {
          // if we get 'forces.json here, it's cycles from xlsx
          if (!this.additionalDataContains(additionalData, 'force_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "Forces"));
          }
        }

        if (this.originalFilenameIncludes(data, 'trajectories.json')) {
          if (!this.additionalDataContains(additionalData, 'trajectories_normalized.json')) {
            // fall back to read time-series since it may also have cycles (e.g. from xlsx)
            fetchTimeBasisCharts = true;
          }
          if (fetchTimeBasisCharts) {
            tracks.charts.push(...await this.fetchAndParseData(data, events, options, isAnalog, "Trajectories"));
          }
        }


        if (this.originalFilenameIncludes(data, 'angles_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "Kinematics");
        }

        if (this.originalFilenameIncludes(data, 'moments_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "Moments");
        }

        if (this.originalFilenameIncludes(data, 'powers_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "Powers");
        }

        if (this.originalFilenameIncludes(data, 'muscle_lengths_velocities_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "Muscle lengths and velocities");
        }

        if (this.originalFilenameIncludes(data, 'emg_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "EMG");
        }

        if (this.originalFilenameIncludes(data, 'force_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "Forces");
        }

        if (this.originalFilenameIncludes(data, 'trajectories_normalized.json')) {
          tracks.charts = await this.addDataToChart(tracks.charts, data, events, options, isAnalog, "Trajectories");
        }

        if ((data.originalFileName.toLowerCase().indexOf('analog.json') === -1 || hasEMG) &&
          data.originalFileName.toLowerCase().indexOf('.mvnx') === -1 &&
          data.originalFileName.toLowerCase().indexOf('.csv') === -1 &&
          data.originalFileName.toLowerCase().indexOf('.txt') === -1) {
          continue;
        }

        const res = await this.fetchAndParseData(data, events, options, isAnalog);
        tracks.charts.push(...res);
      }

      if (
        (data.dataType === "raw" || data.dataType === "data") &&
        data.originalFileName.indexOf(".csv") !== -1
      ) {
        const parsedData = await this.http
          .get(data.previewDataUri, { responseType: "text" })
          .toPromise();
        let location = "";
        if (sensorSpec) {
          const sensorId =
            data.originalFileName.split("_").length > 1
              ? data.originalFileName.split("_")[1]
              : data.originalFileName;

          const sensorLocation = Object.values(sensorSpec).find(
            (value: string) => value.split("_")[0] === sensorId
          );

          location =
            sensorLocation !== undefined
              ? this.getKeyByValue(sensorSpec, sensorLocation)
              : location;
        }
        //Check if the CSV belongs to an XSens DOT reading and add it as 'Kinematics' group. If not, apply the generic label 'CSV Data'
        const isXsensDot = parsedData
          .split(",")[1]
          ?.toLowerCase()
          .includes("devicetag");
        csvGroupName = isXsensDot ? "Kinematics" : "CSV data";
        // this needs to happen only with csv data
        if (csvChartTracks.length === 0) {
          const chartGroup = { name: csvGroupName, tracks: csvChartTracks };
          tracks.charts.push(chartGroup);
        }
        // parse the csv and generate charts
        DataHelper.parseCSV(
          parsedData,
          csvChartTracks,
          data.originalFileName.split(".")[0],
          csvGroupName,
          csvTrackNo++,
          location
        );
      }
    }
    this.applyForceOverlay(videoDataForOverlay, forceDataForOverlay, invertForceOverlayXy, clipName);
    this.applyVideoOffset(videoDataForOverlay);
  }

  public applyMoxEvents(clipId: string, hasTimeCharts = true): void {

    const forceData = [];
    const otherForceData = [];

    for (const clip of this.moxEventClips) {
      const moxEvents = clip.moxEvents;

      if (clip.id === clipId) {
        this.videoForceOverlayService.resetForceData();
        if (moxEvents.events && this.globalPlaybackControlService.getPlaybackControl()) {
          this.globalPlaybackControlService.getPlaybackControl().events.next(moxEvents.events);
        }

        forceData.push(...moxEvents.forceDataForVideo);

        if (hasTimeCharts) {
          if (moxEvents.tVideoOffset || moxEvents.tVideoOffset === 0) {
            this.playbarOffset = moxEvents.tVideoOffset;
            // if we find the video starts later than the sensor data, then we apply the inverse of the offset for the playhead in splitscreen with flag enabled (if video starts earlier, it will be synced to the data)
            if (moxEvents.tVideoOffset > 0) {
              this.sensorDataOffsetInVideo = - moxEvents.tVideoOffset;
            }
          }
        } else {
          // if we don't have time charts, we stop using the offset for the playbar, but set it for the overlayservice instead
          this.playbarOffset = 0;
          if (moxEvents.tVideoOffset || moxEvents.tVideoOffset === 0) {
            this.videoForceOverlayService.tForceDataOffset = moxEvents.tVideoOffset;
          }
        }
        // #2556: setting the clip and timebar duration, so that we also take the charts duration into consideration
        if (moxEvents.duration > 0) {
          this.timebarDuration = moxEvents.duration;
        }
      } else {
        otherForceData.push(...moxEvents.forceDataForVideo);
      }

      if (forceData.length > 0) {
        this.videoForceOverlayService.setForceData(forceData, otherForceData);
      }
    }
  }

  private async addDataToChart(charts: LineChartGroup[], data, events, options, isAnalog, groupName: string): Promise<LineChartGroup[]> {
    const chartGroup = await this.fetchAndParseData(data, events, options, isAnalog, groupName);
    if (chartGroup.length > 0 && this.isGcd(chartGroup[0])) {
      charts = this.mergeCyclesForGcd(charts, chartGroup, data.originalFileName, groupName);
    } else {
      charts.push(...chartGroup);
    }
    return charts;
  }

  private mergeCyclesForGcd(charts: LineChartGroup[], chartGroup: ChartGroup[], fileName: string, groupName: string): LineChartGroup[] {
    // Note: we currently merge gcd cycles here based on the gcdname between <<<gcdname>>>, this could be moved to the backend, but this currently gives the flexibility of adding/removing single files without reprcessing all
    let gcdName = fileName.split('_')[0];
    gcdName = gcdName.replace('<<<', '');
    gcdName = gcdName.replace('>>>', '');
    let hasCycles = false;

    let cycleLabel = '';
    const timeLabel = 'perc';
    const chartGroupNames = charts.map(x => x.name);

    for (const track of chartGroup[0].tracks) {
      hasCycles = false;
      track.hasGcdCycles = false;
      track.id = track.id.substring(track.id.indexOf('>>>_') + 4);
      // first check if we have cycles available
      for (const value of track.values) {
        if (!hasCycles && Object.keys(value).includes('perc')) {
          hasCycles = true;
          track.hasGcdCycles = true;
          break;
        }
      }

      // now, if we don't want to apply the gcd cycle names, update with cycle-i label
      if (this.applyRegularGcdCycles) {
        const newValues: Sample[] = [];
        // find what cycles we have in the data
        const gcdCycleMapping: Record<string, string> = {}
        let iCycleStart = 0;
        if (chartGroupNames.includes(groupName)) {
          const index = charts.findIndex(trackGroup => trackGroup.name === groupName);
          const curTitle = track.labels.title;
          for (const existingTrack of charts[index].tracks) {
            if (existingTrack.labels.title === curTitle && existingTrack.values.length === track.values.length) {
              // our track exists, check for highest cycle label
              const existingLabels = DataHelper.updateLabelsFromSamples(existingTrack.values, timeLabel);
              const existingKeysSorted = Object.keys(existingLabels).sort();
              if (existingKeysSorted.length > 0 && existingKeysSorted[existingKeysSorted.length - 1].startsWith('cycle-')) {
                iCycleStart = parseInt(existingKeysSorted[existingKeysSorted.length - 1].replace('cycle-', '')) + 1;
              }
              break;
            }
          }
        }

        for (const value of track.values) {
          let iCycle = iCycleStart;
          const newValue: Sample = {};
          cycleLabel = 'cycle-' + iCycle;
          for (const label in value) {
            if (label.toLowerCase().indexOf(timeLabel) !== -1) {
              newValue[label] = value[label];
            } else {
              newValue[cycleLabel] = value[label];
              gcdCycleMapping[label] = cycleLabel;
              iCycle++;
            }
          }
          newValues.push(newValue)
        }
        track.values = newValues;
        track.labels.data = DataHelper.updateLabelsFromSamples(track.values, timeLabel);
        track.hasGcdCycles = false;
        track.gcdCycleMapping = gcdCycleMapping;
      }
    }

    if (hasCycles && chartGroupNames.includes(groupName)) {
      // add to existing
      const index = charts.findIndex(trackGroup => trackGroup.name === groupName);
      for (const newTrack of chartGroup[0].tracks) {
        const title = newTrack.labels.title;
        let trackFound = false;
        for (const track of charts[index].tracks) {
          if (track.labels.title === title && track.values.length === newTrack.values.length) {
            track.values = this.mergeSamples(track.values, newTrack.values, timeLabel);
            track.labels.data = DataHelper.updateLabelsFromSamples(track.values, timeLabel);
            trackFound = true;
            break;
          }
        }
        if (!trackFound) {
          charts[index].tracks.push(newTrack);
        }
      }
    } else {
      charts.push(...chartGroup);
    }
    return charts;
  }

  private isGcd(chartGroup: ChartGroup): boolean {
    const trackId = chartGroup.tracks && chartGroup.tracks.length > 0 && chartGroup.tracks[0].id !== undefined ? chartGroup.tracks[0].id : '';
    if (trackId.includes('<<<') && trackId.includes('>>>')) {
      return true;
    } else {
      return false;
    }

  }
  protected async fetchAndParseData(data, events, options, isAnalog, chartGroupName?: string, localData: boolean = false): Promise<ChartGroup[]> {
    const foundChartTracks: ChartGroup[] = [];
    let useCycles = events !== undefined;
    let hasCycleTimes = false;
    let hasCycles = false;

    let parsedData: any = {};
    let groupName: string;
    if (localData) {
      const res = await this.http.get(data).toPromise();
      parsedData = (res || {}) as any;
      groupName = data;
    } else {
      let res;
      try {
        res = await this.http.get(data.previewDataUri).toPromise();
      } catch (e) {
        console.error(e);
      }
      parsedData = (res || {}) as any;
      groupName = data.originalFileName.split('.')[0];
    }
    const chartGroupChartTracks = [];
    const emgChartTracks = [];
    let timeLabel = (options && options.charts && options.charts.timeLabel) ? options.charts.timeLabel : 'time';
    if (parsedData.data) {
      for (const p in parsedData.data[0].values[0]) {
        if (p.toLowerCase().indexOf('perc') !== -1) {
          timeLabel = 'perc';
          useCycles = true;
          hasCycles = true;
          break;
        }
      }

      if (parsedData.data[0]['cycles']) {
        hasCycleTimes = true;
      }

      for (let i = 0; i < parsedData.data.length; i++) {
        const index = i;
        let isEmgFromAnalog = false;
        let colors;
        let series;
        const chartValues = parsedData.data[index].values;

        const dataLabels: any = {};
        for (const p_1 in chartValues[0]) {
          if (p_1.toLowerCase().indexOf(timeLabel) !== -1) {
            continue;
          }
          dataLabels[p_1] = p_1;
          if (p_1.indexOf('rms') !== -1) {
            colors = [this.colorService.orange, this.colorService.right];
            // The assumption here is that the second item is always the RMS.
            series = { 1: { lineWidth: '2' } };
            isEmgFromAnalog = true;
          }
        }

        if (isAnalog && parsedData.data[index].label.toLowerCase().indexOf('joints') === -1 && !isEmgFromAnalog) {
          continue;
        }

        let chartDataType = undefined;
        if ((chartGroupName && chartGroupName.toLowerCase() === 'emg') || isEmgFromAnalog) {
          chartDataType = 'emg';
        } else if (chartGroupName && chartGroupName.toLowerCase() === 'forces') {
          chartDataType = 'force';
        }

        if (chartValues.length > 0) {
          const chartData: DataTrack = {
            id: groupName + "-input#" + index,
            originalId: groupName + "-input#" + index,
            values: chartValues,
            events: events,
            colors: colors ? colors : this.colorService.defaultColorsChart,
            cycleTimes: hasCycleTimes ? parsedData.data[index].cycles : undefined,
            hasCycles: hasCycles,
            labels: {
              title: parsedData.data[index].label + " - " + parsedData.data[index].description,
              hAxis: useCycles ? "Cycle [%]" : 'Time [s]',
              vAxis: "Value [" + parsedData.data[index].unit + "]",
              time: timeLabel,
              data: dataLabels
            },
            series: series,
            dataType: chartDataType
          };

          if (chartGroupName) {
            chartGroupChartTracks.push(chartData);
          } else if (isEmgFromAnalog) {
            emgChartTracks.push(chartData);
          }
        }
      }
      if (emgChartTracks.length > 0) {
        const chartGroup = { name: 'EMG', tracks: emgChartTracks };
        foundChartTracks.push(chartGroup);
      }
      if (chartGroupChartTracks.length > 0) {
        const chartGroup_1 = { name: chartGroupName, tracks: chartGroupChartTracks };
        foundChartTracks.push(chartGroup_1);
      }
    }
    return foundChartTracks;
  }

  protected additionalDataContains(additionalData: any, normalizedFilename: string): boolean {
    let normalized = false;
    for (const data of additionalData) {
      const filename = data.originalFileName;
      if (!filename) {
        continue;
      }

      if (filename.toLowerCase().includes(normalizedFilename)) {
        normalized = true;
        break;
      }
    }
    return normalized;
  }


  protected applyForceOverlay(videoDataForOverlay: VideoDataForOverlay[], forceDataForOverlay: any, invertXyForce: boolean, trialName?: string): void {
    if (videoDataForOverlay !== undefined && forceDataForOverlay !== undefined) {
      const forceProjections: VideoForceProjection[] = [];
      const forceThreshold = 20;        // threshold for vertical force below which we don't calculate cop and don't provide force for projection
      const forceScale = 0.0015;        // scale factor used to normalize forces for projection
      const zUp = true;
      const useMmForProjection = true;
      let tOffset = 0;

      for (const cameraData of videoDataForOverlay) {
        if (cameraData.name !== undefined && cameraData.force_plates !== undefined && cameraData.t_offset !== undefined) {
          tOffset = cameraData.t_offset;           // Note, we currently overwrite tOffset and assume videos have same sync.
          const forceProjection: VideoForceProjection = { name: cameraData.name, trialName: trialName, forceplate: [] };
          let hasValues = false;

          // prefill force_plates if we need to apply the dltmatrix for all force plates
          if (cameraData?.dlt_matrix !== undefined || cameraData?.dlt_matrix_for_all_force_plates !== undefined) {
            cameraData.force_plates = [];
            const dlt_matrix = cameraData.dlt_matrix ? cameraData.dlt_matrix : cameraData.dlt_matrix_for_all_force_plates;
            for (const forceData of forceDataForOverlay) {
              cameraData.force_plates.push({ name: forceData.name, dlt_matrix: dlt_matrix });
            }
          }

          for (const forcePlate of cameraData.force_plates) {
            if (forcePlate['name'] !== undefined && forcePlate['dlt_matrix'] !== undefined) {
              let myValues = [];
              let myForcePlateName = forcePlate['name'];
              if (forcePlate['name'].lastIndexOf(' - ') !== -1) {
                // cut part before ' - ' (e.g. we get AMTI - Force plate, and want to keep Force plate)
                myForcePlateName = forcePlate['name'].slice(forcePlate['name'].lastIndexOf(' - ') + 3);
              }
              const dltMatrix = forcePlate['dlt_matrix'];
              let apply2D = false;

              if (dltMatrix === undefined || dltMatrix.length < 3) {
                continue;
              } else {
                // for 2D, make sure we get a 3x4 matrix (insert column with 0 if needed)
                if (dltMatrix[0].length === 3) {
                  apply2D = true;
                  for (let i = 0; i < dltMatrix.length; i++) {
                    dltMatrix[i].splice(2, 0, 0);
                  }
                }
              }

              for (const forceData of forceDataForOverlay) {
                if (forceData.name.toLowerCase() === myForcePlateName.toLowerCase()) {
                  myValues = forceData.force;
                  break;
                }
              }

              const cameraNameWithoutExtension = cameraData.name.substring(0, cameraData.name.lastIndexOf('.')) || cameraData.name;
              const cameraName = Object.keys(this.videoResolutionRatio).find(x => x === cameraNameWithoutExtension || x.includes('.' + cameraNameWithoutExtension + '.'));
              const videoResolutionRatio = cameraName !== undefined ? this.videoResolutionRatio[cameraName] : this.videoResolutionRatio.default;
              if (myValues.length > 0) {
                const myForcePlate: VideoForceMap = { name: myForcePlateName, map: undefined };
                const mySamples = DataHelper.applyDltMatrix(dltMatrix, myValues, forceThreshold, forceScale, zUp, useMmForProjection, apply2D, invertXyForce);
                myForcePlate.map = new Map(mySamples.map(i => [i.time, { x_start: i.x_start * videoResolutionRatio, y_start: i.y_start * videoResolutionRatio, x_end: i.x_end * videoResolutionRatio, y_end: i.y_end * videoResolutionRatio }]));
                forceProjection.forceplate.push(myForcePlate);
                hasValues = true;
              }
            }
          }
          if (hasValues) {
            forceProjections.push(forceProjection);
          }
        }
      }

      if (forceProjections.length > 0) {
        this.videoForceOverlayService.setForceData(forceProjections);
      }
    }
  }

  protected applyVideoOffset(videoDataForOverlay: VideoDataForOverlay[]): void {
    let tOffset = 0;
    if (videoDataForOverlay !== undefined) {
      for (const videoData of videoDataForOverlay) {
        if (videoData.t_offset !== undefined) {
          tOffset = videoData.t_offset;           // Note, we currently overwrite tOffset and assume videos have same sync.
          break;
        }
      }
      this.playbarOffset = tOffset;
    }
  }

  public async parseCameraParams(dataUri: string): Promise<VideoDataForOverlay[]> {
    const res = await this.http.get(dataUri).toPromise();
    const parsedData = (res || {}) as any;
    return parsedData.cameraData ? parsedData.cameraData : parsedData.videoData;
  }

  protected async parseGaitParameters(dataUri: string, filenameStr: string): Promise<GaitParameterTable> {
    const res = await this.http.get(dataUri).toPromise();
    const parsedData = (res || {}) as any;
    let chartName = parsedData.description;
    if (chartName === 'Gait params') {
      chartName = 'Spatiotemporal parameters ';
      if (filenameStr.length > 0) {
        chartName = chartName + '(' + filenameStr + ')';
      }
    }
    const chartData = {
      id: "barChart",
      originalId: "barChart",
      values: [parsedData.data],
      labels: {
        title: [parsedData.description],
        std: 'std',
        hAxis: "Value",
        vAxis: "Parameter [unit]",
      },
      colors: undefined
    };
    return { name: chartName, tracks: chartData };
  }

  public generateDataSample(dataTracks: any, chartLabels: any, currentTimeLabel: string, currentSample: number) {
    //TODO: will be obsolete with merge of ChartsComponent...
    const timeLabel = chartLabels.xAxisDataLabel;
    const firstTrackData = dataTracks[0].data;

    const dataSample = [{ v: currentTimeLabel }];
    let count = 1;
    for (let i = 0; i < dataTracks.length; i++) {
      const yLabel = dataTracks[i].yLabel;
      const yLabelPlus = dataTracks[i].yLabelPlus;
      const yLabelMinus = dataTracks[i].yLabelMinus;

      const stdLabel = dataTracks[i].stdDataLabel || yLabelPlus;
      const currentTrackData = dataTracks[i].data;
      const hasStd = stdLabel !== undefined && currentTrackData[currentSample][stdLabel] !== undefined
        && currentTrackData[currentSample][stdLabel] > 0;

      let meanValue = 0;
      if (yLabel) {
        meanValue = currentTrackData[currentSample][yLabel];
        const valuePlus = currentTrackData[currentSample][yLabelPlus];
        const valueMinus = currentTrackData[currentSample][yLabelMinus];

        dataSample[count++] = { v: meanValue.toString() };
        dataSample[count++] = { v: valuePlus };
        dataSample[count++] = { v: valueMinus };
      } else {
        // if std label found, add mean +/- std pair
        // if std label not found, calculate mean +/- std
        let nVals = 0;
        let sum = 0;
        let sumsq = 0;
        let stdValue = 0;
        if (hasStd) {
          stdValue = currentTrackData[currentSample][stdLabel];
        }
        for (const p in currentTrackData[currentSample]) {
          if (p.toLowerCase() !== timeLabel && p.toLowerCase() !== stdLabel) {
            const value: number = currentTrackData[currentSample][p];

            if (hasStd) {
              meanValue = value;
              break;
            } else {
              if (value !== null) {
                sum += value;
                sumsq += value * value;
                nVals++;
              }
            }
          }
        }
        if (!hasStd) {
          if (nVals > 0) {
            meanValue = sum / nVals;
          }

          if (nVals > 1) {
            stdValue = Math.sqrt((sumsq - sum * sum / nVals) / (nVals - 1));
          }
        }

        dataSample[count++] = { v: meanValue.toString() };
        dataSample[count++] = { v: (meanValue + stdValue).toString() };
        dataSample[count++] = { v: (meanValue - stdValue).toString() };
      }
      // TODO: clean up line-chart component to get it inline with charts component
      // (e.g. use charts-tooltip service) -> Gitlab issue 1303
      dataSample[count++] = {
        v: dataTracks[i].name + '\n' + chartLabels.hAxisDescription + ': '
          + firstTrackData[currentSample][timeLabel] + '\n' + chartLabels.vAxisDescription + ': ' + meanValue?.toFixed(2)
      };
    }
    return dataSample;
  }

  public mergeOrSplitTrackData(chartGroups: LineChartGroup[], applyNewChartTemplate: boolean): LineChartGroup[] {
    // If we find "emg" or "forces", we merge tracks with same title into one track
    // For the other, if we apply the new chart template, we split x, y, and z into separate tracks if needed
    for (const chartGroup of chartGroups) {
      if ((chartGroup.name.toLowerCase().includes('emg') || chartGroup.name.toLowerCase().includes('forces')) && chartGroup.tracks.length > 0) {
        const trackTitles = [];
        const newTracks: DataTrack[] = [];
        // first find unique labels
        for (const track of chartGroup.tracks) {
          if (!trackTitles.includes(track.labels.title)) {
            trackTitles.push(track.labels.title);
          }
        }

        for (const trackTitle of trackTitles) {
          const newTrack: DataTrack = this.mergeCycleTracksBasedOnTitle(chartGroup, trackTitle);

          if (newTrack.labels.title.includes('Left/Right')) {
            // We have a force track that we want to show for left and right, so we duplicate the track to make sure it's added for both left and right
            const extraTrack = JSON.parse(JSON.stringify(newTrack)); // deep copy
            newTrack.labels.title = newTrack.labels.title.replace('Left/Right', 'Left');
            extraTrack.labels.title = extraTrack.labels.title.replace('Left/Right', 'Right');
            extraTrack.originalId = extraTrack.originalId !== undefined ? extraTrack.originalId + '-extra' : extraTrack.labels.title;
            newTracks.push(newTrack);
            newTracks.push(extraTrack);
          } else {
            // for force cycles, if we find left or right, add it to original id to make sure we distinghuish in split screen left and right cycle charts
            if (chartGroup.name.toLowerCase().includes('forces') && newTrack?.hasCycles === true && newTrack.labels.title.startsWith('Left')) {
              newTrack.originalId += '-left';
            } else if (chartGroup.name.toLowerCase().includes('forces') && newTrack?.hasCycles === true && newTrack.labels.title.startsWith('Right')) {
              newTrack.originalId += '-right';
            }
            newTracks.push(newTrack);
          }
        }
        chartGroup.tracks = newTracks;
      } else if (applyNewChartTemplate) {
        const newTracks: DataTrack[] = [];
        for (const track of chartGroup.tracks) {
          const labels = Object.keys(track.labels.data);
          const xLabel = track.labels.time;
          if (track.hasCycles === false && labels && labels.indexOf('x') > -1 && labels.indexOf('y') > -1 && labels.indexOf('z') > -1) {
            // split based on labels
            for (const myLabel in track.labels.data) {
              const newTrack = JSON.parse(JSON.stringify(track)); // deep copy
              const myTitle = track.labels.title.replace(' - ', '');
              newTrack.originalId = newTrack.originalId !== undefined ? newTrack.originalId + '-' + myLabel : myTitle + '-' + myLabel;
              const newValues = [];
              for (let i = 0; i < track.values.length; i++) {
                const newValue = {};
                const myValue = track.values[i];
                for (const p in myValue) {
                  if (p === myLabel || p === xLabel) {
                    newValue[p] = myValue[p];
                  }
                }
                newValues.push(newValue);
              }
              newTrack.values = newValues;
              newTrack.labels.data = DataHelper.updateLabelsFromSamples(newValues, xLabel);
              newTrack.labels.title = myTitle + '-' + myLabel;
              newTracks.push(newTrack);
            }
          }
        }
        if (newTracks.length > 0) {
          chartGroup.tracks = newTracks;
        }

      }
    }
    return chartGroups;
  }

  public mergeCycleTracksBasedOnTitle(chartGroup: LineChartGroup, title: string): DataTrack {
    let firstFound = false;
    let newTrack: DataTrack;
    for (const track of chartGroup.tracks) {
      if (track.labels.title === title) {
        if (firstFound === false) {
          newTrack = track;
          firstFound = true;
        } else {
          // merge
          let xLabel = 'perc';
          if (track.labels !== undefined && track.labels.time !== undefined) {
            xLabel = track.labels.time;
          }

          const hasDifferentLength = track.values.length !== newTrack.values.length;
          for (let i = 0; i < track.values.length; i++) {
            let curValueIdx = -1;
            if (hasDifferentLength) {
              // check if we find current sample, otherwise add it
              const curPerc = track.values[i][xLabel];
              curValueIdx = newTrack.values.findIndex(x => x[xLabel] === curPerc);
            }
            for (const label in track.values[i]) {
              if (i == 0 && label !== xLabel) {
                newTrack.labels.data[label] = track.labels.data[label];
              }
              if (hasDifferentLength) {
                if (curValueIdx == -1) {
                  // add new sample if we don't have it yet
                  curValueIdx = newTrack.values.length;
                  const newSample: Sample = {};
                  newSample[xLabel] = track.values[i][xLabel]
                  newTrack.values.push(newSample);
                }
                newTrack.values[curValueIdx][label] = track.values[i][label];
              } else {
                newTrack.values[i][label] = track.values[i][label];
              }
            }
          }
          // If we added new samples, sort values
          if (hasDifferentLength) {
            newTrack.values.sort((a, b) => a[xLabel] - b[xLabel]);
          }
        }
      }
    }
    return newTrack;
  }

  public mergeLeftRightCharts(chartGroups: LineChartGroup[], applyNewChartTemplate: boolean): LineChartGroup[] {
    // try to find starting 'l'/'r' or Rotation 'l'/'r'
    // combine tracks + add context
    const copy = JSON.parse(JSON.stringify(chartGroups)); // deep copy
    const result = [];
    for (const chartGroup of copy) {
      const chartGroupName = chartGroup.name.toLowerCase();
      const validGroupName = ['kinematics', 'moments', 'powers', 'muscle lengths and velocities', 'emg', 'forces'].includes(chartGroupName);
      if (validGroupName) {
        // merge!
        let applyEmgOrForceMerge = false;
        if (chartGroupName.includes('emg') || chartGroupName.includes('forces')) {
          applyEmgOrForceMerge = true;    // Handle EMG and Forces data separately
        }

        if (chartGroup.tracks.length === 0) {
          continue;
        }

        const tracks = [];
        let candidateNamesRight = [];
        let indexLeftRight = 0;
        let indexLeftRightPre = -1;
        let strToCheckPrefix = "";
        let adaptiveIndexLeftRightFromEnd: number;
        let emgNamePatternAtEndOfString = false;
        for (const track of chartGroup.tracks) {
          if (track.mergedTrack && track.mergedTrack === true) {
            continue;
          }
          const titleToCheck = track.labels.title.toLowerCase();
          if (titleToCheck.substr(0, 9) === 'rotation ') {
            indexLeftRightPre = 8;
            indexLeftRight = 9;
            strToCheckPrefix = 'rotation';
            break;
          }

          if (titleToCheck.substr(0, 7) === 'moment ') {
            indexLeftRightPre = 6;
            indexLeftRight = 7;
            strToCheckPrefix = 'moment';
            break;
          }

          if (titleToCheck.substr(0, 6) === 'power ') {
            indexLeftRightPre = 5;
            indexLeftRight = 6;
            strToCheckPrefix = 'power';
            break;
          }

          if (titleToCheck.substr(0, 8) === 'voltage.') {
            indexLeftRightPre = 7;
            indexLeftRight = 8;
            // Check whether we have 'voltage.r/l...' or 'voltage.01 r/l'
            if (!isNaN(titleToCheck[indexLeftRight])) {
              indexLeftRight = 11;
            }
            strToCheckPrefix = 'voltage';
            break;
          }
          if (titleToCheck.substr(0, 8).toLowerCase() === 'emg.emg_') {
            // check for e.g. 'emg.emg_10 lgm'
            const titleToCheckTemp = titleToCheck.substring(0, titleToCheck.lastIndexOf(' '));
            indexLeftRightPre = 7;
            indexLeftRight = titleToCheckTemp.length + 1; // Index at e.g. 'lgm' or 'rgm'
            emgNamePatternAtEndOfString = true;         // Find e.g. 'lgm' or 'rgm' at end of string
            strToCheckPrefix = 'emg.emg';
            break;
          }
          if (titleToCheck.substr(0, 4) === 'emg.' || titleToCheck.substr(0, 4) === 'emg-') {
            indexLeftRightPre = 3;
            indexLeftRight = -1; // check for last character
            strToCheckPrefix = 'emg';
            break;
          }
          if (titleToCheck.substr(0, 11).toLowerCase() === 'ultium emg-') {
            // check for e.g. 'ultium emg-tib.ant. lt - 2'
            const titleToCheckTemp = titleToCheck.substring(0, titleToCheck.lastIndexOf(' - '));
            indexLeftRightPre = 10;
            indexLeftRight = titleToCheckTemp.length - 2; // Index at 'lt' or 'rt'
            adaptiveIndexLeftRightFromEnd = -1;         // to be used to update each iteration
            strToCheckPrefix = 'ultium emg';
            break;
          }
        }
        const checkContextBasedOnFirstCharacterWithoutTemplate = !applyNewChartTemplate && (chartGroupName.includes('kinematics') || chartGroupName.includes('moments') || chartGroupName.includes('powers')) || chartGroupName.includes('lengths and velocities') && indexLeftRight === 0 && indexLeftRightPre === -1;

        if (!applyEmgOrForceMerge) {
          for (const track of chartGroup.tracks) {
            const titleToCheck = track.labels.title.toLowerCase();
            if (adaptiveIndexLeftRightFromEnd) {
              const titleToCheckTemp = titleToCheck.substring(0, titleToCheck.lastIndexOf(' - '));
              indexLeftRight = titleToCheckTemp.length - 1 + adaptiveIndexLeftRightFromEnd;
            }
            const indexToCheck = indexLeftRight === -1 ? titleToCheck.length - 1 : indexLeftRight;
            if (titleToCheck.substr(0, indexLeftRightPre) === strToCheckPrefix && titleToCheck[indexToCheck] === 'r') {
              candidateNamesRight.push(titleToCheck);
            }
          }
        }

        for (const track of chartGroup.tracks) {
          // try to find left and right for all.
          const xLabel = track?.labels?.time ?? 'perc';
          track.mergedTrack = true;
          const curTitle = track.labels.title.toLowerCase();

          if (emgNamePatternAtEndOfString) {
            const titleToCheckTemp = curTitle.substring(0, curTitle.lastIndexOf(' '));
            indexLeftRight = titleToCheckTemp.length + 1;
          } else if (adaptiveIndexLeftRightFromEnd) {
            const titleToCheckTemp = curTitle.substring(0, curTitle.lastIndexOf(' - '));
            indexLeftRight = titleToCheckTemp.length - 1 + adaptiveIndexLeftRightFromEnd;
          }
          const indexToCheck = indexLeftRight === -1 ? curTitle.length - 1 : indexLeftRight;

          if (applyEmgOrForceMerge) {

            if (curTitle.substr(0, indexLeftRightPre) === strToCheckPrefix && (curTitle[indexToCheck] === 'l' || curTitle[indexToCheck] === 'r')) {
              let lrString = ' (left)';
              if (curTitle.substr(0, indexLeftRightPre) === strToCheckPrefix && curTitle[indexToCheck] === 'r') {
                lrString = ' (right)';
              }
              if (this.updateTrackWithContext(track, xLabel, lrString)) {
                track.values = this.updateSamplesWithUpdatedLabels(track.values, xLabel, lrString);
                track.labels.data = DataHelper.updateLabelsFromSamples(track.values, xLabel);
                track.cycleTimes = this.updateCycleTimesWithContext(track, lrString);
              }
            }
            tracks.push(track);
          } else if (curTitle.substr(0, indexLeftRightPre) === strToCheckPrefix && curTitle[indexToCheck] === 'l') {
            // potential left, try to find right
            let rightAndLeftFound = false;
            let stringToCheck: string;
            if (indexLeftRight === -1) {
              stringToCheck = curTitle.substring(0, curTitle.length - 1) + 'r';
            } else if (indexLeftRight === 0 && curTitle.toLowerCase().startsWith('left') && curTitle.length > 4) {
              stringToCheck = 'right' + curTitle.substring(indexToCheck + 4, curTitle.length);
            } else {
              stringToCheck = 'r' + curTitle.substring(indexToCheck + 1, curTitle.length);
            }
            let trackRight;

            for (const trackRightCheck of chartGroup.tracks) {
              if (trackRightCheck.labels.title.substring(indexToCheck, trackRightCheck.labels.title.length).toLowerCase() === stringToCheck) {
                rightAndLeftFound = true;
                trackRight = trackRightCheck;
                // remove from candidates
                candidateNamesRight = candidateNamesRight.filter(obj => obj.toLowerCase() !== trackRightCheck.labels.title.toLowerCase());
                break;
              }
            }

            // if we have left and right or the new template, then we add the context
            // or check on first character
            const checkFirstCharacter = checkContextBasedOnFirstCharacterWithoutTemplate && !rightAndLeftFound;
            if (rightAndLeftFound || applyNewChartTemplate || checkFirstCharacter) {
              let titleStr: string;
              if (indexLeftRight === -1) {
                titleStr = track.labels.title.substring(0, track.labels.title.length - 1);
                if (titleStr[titleStr.length - 1] === '_') {
                  titleStr = track.labels.title.substring(0, track.labels.title.length - 2);
                }
              } else if (checkFirstCharacter) {
                titleStr = track.labels.title;    // keep first character if we only find left or right without template
              } else {
                if (indexToCheck === 0 && track.labels.title.toLowerCase().startsWith('left') && track.labels.title.length > 4) {
                  titleStr = track.labels.title.substring(indexToCheck + 4, track.labels.title.length);
                } else {
                  titleStr = track.labels.title.substring(0, indexLeftRightPre + 1) + track.labels.title.substring(indexToCheck + 1, track.labels.title.length);
                }
              }
              track.labels.title = titleStr;
              if (this.updateTrackWithContext(track, xLabel, ' (left)')) {
                track.values = this.updateSamplesWithUpdatedLabels(track.values, xLabel, ' (left)');
                track.cycleTimes = this.updateCycleTimesWithContext(track, ' (left)');
              }
              if (rightAndLeftFound) {
                if (this.updateTrackWithContext(track, xLabel, ' (right)')) {
                  trackRight.values = this.updateSamplesWithUpdatedLabels(trackRight.values, xLabel, ' (right)');
                  trackRight.cycleTimes = this.updateCycleTimesWithContext(trackRight, ' (right)');
                }
                const mergedTrack = this.mergeSameTracksFromTrials(track, trackRight, xLabel, false);
                track.values = mergedTrack.values;
                track.cycleTimes = mergedTrack.cycleTimes;
              }
              track.labels.data = DataHelper.updateLabelsFromSamples(track.values, xLabel);
            }
            tracks.push(track);
          } else if (curTitle.substr(0, indexLeftRightPre) === strToCheckPrefix && curTitle[indexToCheck] === 'r') {
            // skip here, but we'll loop later the remaining candidates
          } else {
            track.mergedTrack = false;
            tracks.push(track);
          }
        }
        if (!applyEmgOrForceMerge && candidateNamesRight.length > 0) {
          // handle remaining right tracks and add context if we have the new template.
          for (const tracksToAdd of candidateNamesRight) {
            for (const track of chartGroup.tracks) {
              track.mergedTrack = true;
              const xLabel = track?.labels?.time ?? 'perc';
              if (track.labels.title.toLowerCase() === tracksToAdd.toLowerCase()) {
                if (applyNewChartTemplate || checkContextBasedOnFirstCharacterWithoutTemplate) {
                  const indexToCheck = indexLeftRight === -1 ? track.labels.title.length - 1 : indexLeftRight;
                  let titleStr: string;
                  if (indexLeftRight === -1) {
                    titleStr = track.labels.title.substring(0, track.labels.title.length - 1);
                    if (titleStr[titleStr.length - 1] === '_') {
                      titleStr = track.labels.title.substring(0, track.labels.title.length - 2);
                    }
                  } else if (checkContextBasedOnFirstCharacterWithoutTemplate) {
                    titleStr = track.labels.title;    // keep first character if we only find left or right without template
                  } else {
                    if (indexToCheck === 0 && track.labels.title.toLowerCase().startsWith('right') && track.labels.title.length > 5) {
                      titleStr = track.labels.title.substring(indexToCheck + 5, track.labels.title.length);
                    } else {
                      titleStr = track.labels.title.substring(0, indexLeftRightPre + 1) + track.labels.title.substring(indexToCheck + 1, track.labels.title.length);
                    }
                  }
                  track.labels.title = titleStr;
                  if (this.updateTrackWithContext(track, xLabel, ' (right)')) {
                    track.values = this.updateSamplesWithUpdatedLabels(track.values, xLabel, ' (right)');
                    track.labels.data = DataHelper.updateLabelsFromSamples(track.values, xLabel);
                    track.cycleTimes = this.updateCycleTimesWithContext(track, ' (right)');
                  }
                }
                tracks.push(track);
                break;
              }
            }
          }
        }
        chartGroup.tracks = tracks;
        if (tracks.length > 0) {
          result.push(chartGroup);
        }
      } else {
        result.push(chartGroup);
      }
    }
    return result;
  }

  protected updateTrackWithContext(track: DataTrack, xLabel: string, contextCheckStr: string): boolean {
    const curLabels = Object.keys(DataHelper.updateLabelsFromSamples(track.values, xLabel));
    return !curLabels.some(x => x.includes(contextCheckStr));
  }

  protected updateCycleTimesWithContext(track: DataTrack, contextStr: string): Record<string, string> {
    const cycleTimes = {};
    if (track?.cycleTimes) {
      for (const c in track.cycleTimes) {
        cycleTimes[c + contextStr] = track.cycleTimes[c];
      }
    }
    return cycleTimes;
  }



  /**
   * This attempts to merge a new set of tracks into a TrialTrack container. Merging strategy is to
   * simply try to update all existing labels and data points names by prefixing them with session+trial
   * name and include all data in the same chart.
   * @param charts A LineChartGroup array with the already existing tracks
   * @param chartIndex If defined, indicates the index of the currently selected LineChartGroup
   * @param container A container where new tracks will be stored (this contains the tracks that will be shown on the chart)
   * @param chartTemplates A template containing the chart details (title, filter names, values, vAxis)
   * @param name The name of current trial
   * @param addToEmptyChart If false, don't create an empty chart with just this track. This is intended for reference tracks, which should not create charts without real data.
   * @param mergeTrials If false, data will not be merged (no updates of labels, new container will be created if allowed)
   * @param findNearestSample If true, data will be synchronized based on nearest sample search, including interpolation of data (currently for EMG only)
   */
  public parseTracks(
    charts: LineChartGroup[],
    chartIndex: number,
    container: TrialTracksGroup,
    chartTemplateGroups: ChartTemplateGroup[],
    name: string,
    addToEmptyChart: boolean,
    mergeTrials: boolean,
    findNearestSample: boolean = false
  ): void {
    for (const chartTemplateGroup of chartTemplateGroups) {
      const chartTemplates = chartTemplateGroup.charts;
      for (const chartTemplate of chartTemplates) {
        let iStart = 0;
        let iEnd = charts.length;
        let combinedGroup = false;
        if (chartIndex == undefined) {
          combinedGroup = true;
        } else {
          iStart = chartIndex;
          iEnd = chartIndex + 1;
        }

        for (let i = iStart; i < iEnd; i++) {
          const chart = charts[i];
          if (combinedGroup && (chartTemplate?.chartGroupSourceName == undefined || chartTemplate?.chartGroupSourceName && !chart.name.toLowerCase().includes(chartTemplate.chartGroupSourceName.toLowerCase()))) {
            continue;
          }
          for (const track of chart.tracks) {
            if (this.isLabelInChart(track.labels.title, chartTemplate.names)) {
              let curChart_vAxis: string = undefined;
              if (chartTemplate.vAxis) {
                curChart_vAxis = chartTemplate.vAxis;
              }
              let curChart_vLimits: [number, number] = undefined;
              if (chartTemplate.vLimits) {
                curChart_vLimits = chartTemplate.vLimits;
              }

              let newTrack = track;
              if (name == '<<<reference>>>') {
                // in case of reference, track can be added multiple times. Make a copy to make sure we don't use information that is updated later inside track
                newTrack = JSON.parse(JSON.stringify(track)); // deep copy
              }

              this.sortTrackLabels(newTrack);
              this.addTrackToContainer(container, newTrack, name, chartTemplateGroup.name, chartTemplate.title, chartTemplate.id, curChart_vAxis, curChart_vLimits, addToEmptyChart, mergeTrials, findNearestSample);
              if (name !== '<<<reference>>>') {
                // allow to add line again for reference data
                break;
              }
            }
          }
        }
      }
    }
  }

  public addTrackToContainer(container: TrialTracksGroup, track: DataTrack, name: string, curGroupName: string, curChartName: string, curChartId: string, curChart_vAxis: string, curChart_vLimits: number[], addToEmptyChart: boolean, mergeTrials: boolean, findNearestSample: boolean): void {
    let myTrack = track;
    if (mergeTrials) {
      // Then, update the labels of the new chart
      const trackWithUpdatedLabels = this.updateTrackLabels(track, name);
      trackWithUpdatedLabels.mergedTrack = true;
      myTrack = trackWithUpdatedLabels;
    }
    myTrack.id = curChartId !== undefined ? curChartId : curChartName;
    myTrack.labels.title = curChartName;
    if (curChart_vAxis) {
      myTrack.labels.vAxis = curChart_vAxis;
    }
    if (curChart_vLimits) {
      myTrack.labels.vLimits = curChart_vLimits;
    }

    const currentTrack = container[curGroupName]?.[curChartName];
    const xLabel = track?.labels?.time ?? 'perc';
    if (mergeTrials && currentTrack) {
      // If the container already has at least one track, attempt to merge them
      container[curGroupName][curChartName] = this.mergeSameTracksFromTrials(currentTrack, myTrack, xLabel, findNearestSample);
    } else if (addToEmptyChart) {
      // Otherwise just add the new track and return
      if (container[curGroupName] === undefined) {
        container[curGroupName] = {};
      }
      // check if we already have such id in our charts and update if we find.
      for (const trackGroup in container) {
        for (const containerTrack in container[trackGroup]) {
          if (myTrack.originalId !== undefined && container[trackGroup][containerTrack].originalId === myTrack.originalId) {
            myTrack.originalId += myTrack.id;
            break;
          }
        }
      }
      container[curGroupName][curChartName] = myTrack;
    }
  }
  public filterTrackData(track: DataTrack): DataTrack {
    // First filter the values
    const myDataLabels = track.labels.data;
    const meanStdLabels = {};
    const meanStdConditionLabels = {};
    const otherLabels = {};
    const refLabels = {};
    let meanLabelFound = false;
    let meanConditionLabelFound = false;
    let otherLabelFound = false;
    // update labels
    for (const label in myDataLabels) {
      if (track?.disabledLines !== undefined && track.disabledLines.includes(label)) {
        continue
      } else if (label.endsWith('<<<reference>>>')) {
        refLabels[label] = label;
      } else if (label.toLowerCase().startsWith(this.meanDataLabel.toLowerCase()) && label.endsWith('<<<conditionAvg>>>')) {
        meanStdConditionLabels[label] = label;
        meanConditionLabelFound = true;
      } else if (label.toLowerCase().startsWith(this.stdDataLabel.toLowerCase()) && label.endsWith('<<<conditionAvg>>>')) {
        meanStdConditionLabels[label] = label;
      } else if (label.toLowerCase().startsWith(this.meanDataLabel.toLowerCase())) {
        meanStdLabels[label] = label;
        meanLabelFound = true;
      } else if (label.toLowerCase().startsWith(this.stdDataLabel.toLowerCase())) {
        meanStdLabels[label] = label;
      } else {
        otherLabelFound = true;
        otherLabels[label] = label;
      }
    }
    if (meanConditionLabelFound && otherLabelFound) {
      this.allowConditionAvgToggle = true;
    }
    let xLabel = 'perc';
    if (track.labels !== undefined && track.labels.time !== undefined) {
      xLabel = track.labels.time;
    }

    const useAverages = this.showAveragesForCycles || Object.keys(otherLabels).length == 0;
    if (useAverages && this.applyAveragePerCondition && meanConditionLabelFound) {
      track.labels.data = meanStdConditionLabels;
      this.showAveragesForCycles = true;
    } else if (useAverages && !this.applyAveragePerCondition && meanLabelFound) {
      track.labels.data = meanStdLabels;
      this.showAveragesForCycles = true;
    } else if (otherLabelFound) {
      track.labels.data = otherLabels;
      this.showAveragesForCycles = false;
    }
    // always include the references
    track.labels.data = { ...track.labels.data, ...refLabels };
    return track;
  }

  public prepareTrackData(myChart: LineChartGroup, applyNewChartTemplate: boolean, trialTemplate: TrialTemplate, clipId: string = undefined): DataTrack[] {
    let myCharts: LineChartGroup[] = [];
    myCharts.push(myChart);
    myCharts = this.handleTrackData(myCharts, trialTemplate);
    myCharts = this.mergeOrSplitTrackData(myCharts, applyNewChartTemplate);
    myCharts = this.mergeLeftRightCharts(myCharts, applyNewChartTemplate);
    myCharts = this.convertFootOffTimesToPercentages(myCharts, trialTemplate);
    myCharts = this.handleDisabledTracks(myCharts, clipId);
    let myDataTrack: DataTrack[] = [];
    if (myCharts.length > 0) {
      myDataTrack = myCharts[0].tracks;
    }
    return myDataTrack;
  }

  protected handleDisabledTracks(chartGroup: LineChartGroup[], clipId: string): LineChartGroup[] {
    const clipIds = Object.keys(this.disabledLines)
    if (clipIds.length === 0 || clipId === undefined || (clipId !== undefined && !clipIds.includes(clipId))) {
      return chartGroup;
    }

    let curClip = this.disabledLines[clipId];
    if (curClip === undefined) {
      return chartGroup;
    }

    const gcdNames = curClip.map(x => x.name);

    if (gcdNames.length === 0) {
      return chartGroup;
    }

    // loop over all tracks and check if we have any labels that need to be disabled. If so, add to "disabledLines", so it's not used for averages later and not shown.
    for (const chart of chartGroup) {
      for (const track of chart.tracks) {
        const hasGcdCycleMap = track?.gcdCycleMapping !== undefined;
        const dataLabels = Object.keys(track.labels.data);
        for (const dataLabel of dataLabels) {
          for (const gcdName of gcdNames) {
            const curGcdDisabled = curClip.find(x => x.name === gcdName);
            const disableLeft = curGcdDisabled.left === true && dataLabel.endsWith('(left)');
            const disableRight = curGcdDisabled.right === true && dataLabel.endsWith('(right)');
            const needsDisabling = disableLeft || disableRight;
            if (needsDisabling && (dataLabel.startsWith(gcdName) || (hasGcdCycleMap && dataLabel.startsWith(track.gcdCycleMapping[gcdName])))) {
              if (track?.disabledLines === undefined) {
                track.disabledLines = [];
              }
              if (!track.disabledLines.includes(dataLabel)) {
                track.disabledLines.push(dataLabel)
              }
            }
          }
        }
      }
    }
    return chartGroup;
  }

  protected convertFootOffTimesToPercentages(chartGroup: LineChartGroup[], trialTemplate: TrialTemplate): LineChartGroup[] {
    const drawVerticalLineAtSwingTransition = trialTemplate?.drawVerticalLineAtSwingTransition !== undefined ? trialTemplate.drawVerticalLineAtSwingTransition : true;
    if (drawVerticalLineAtSwingTransition) {
      for (const chart of chartGroup) {
        for (const track of chart.tracks) {
          const chartLabels = Object.keys(track.labels.data);
          const hasCyclesRight = chartLabels.some(item => item.includes('(right)')) === true;
          const hasCyclesLeft = chartLabels.some(item => item.includes('(left)')) === true;
          const trackLabelsLeft = chartLabels.filter(item => item.includes('(left)'));
          const trackLabelsRight = chartLabels.filter(item => item.includes('(right)'));
          const hasContext = (track.mergedTrack === true && (hasCyclesRight || hasCyclesLeft)) ? true : false;
          if (track?.hasCycles && track.hasCycles === true && track?.swingTransitions) {
            for (const swingTransition of track.swingTransitions) {
              const curContext = swingTransition.context;
              const curTime = swingTransition.time;
              const curPerc = swingTransition?.perc;
              let curCycle: CyclePoint;
              if (hasContext === true) {
                if (hasCyclesRight && curContext === DataContext.rightSide) {
                  const hasSingleCycleLabel = hasCyclesRight && trackLabelsRight.length == 1 && trackLabelsRight[0].includes('cycle-');
                  const hasEmgRawAndRmsTracks = hasCyclesRight && trackLabelsRight.length == 2 && trackLabelsRight.some(x => x.includes('-value (right)') && x.includes('cycle-'));
                  const idxCycle = hasSingleCycleLabel ? 0 : hasEmgRawAndRmsTracks ? trackLabelsRight.findIndex(x => x.includes('-value (right)') && x.includes('cycle-')) : -1;
                  if (curPerc !== undefined && curPerc > -1 && idxCycle > -1) {
                    curCycle = {
                      cycleLabel: trackLabelsRight[idxCycle],
                      cyclePerc: curPerc
                    }
                  } else {
                    curCycle = DataHelper.getCyclePercentageFromTime(curTime, track.cycleTimes, DataContext.rightSide);
                  }
                }
                if (hasCyclesLeft && curContext === DataContext.leftSide) {
                  const hasSingleCycleLabel = hasCyclesLeft && trackLabelsLeft.length == 1 && trackLabelsLeft[0].includes('cycle-')
                  const hasEmgRawAndRmsTracks = hasCyclesLeft && trackLabelsLeft.length == 2 && trackLabelsLeft.some(x => x.includes('-value (left)') && x.includes('cycle-'));
                  const idxCycle = hasSingleCycleLabel ? 0 : hasEmgRawAndRmsTracks ? trackLabelsLeft.findIndex(x => x.includes('-value (left)') && x.includes('cycle-')) : -1;
                  if (curPerc !== undefined && curPerc > -1 && idxCycle > -1) {
                    curCycle = {
                      cycleLabel: trackLabelsLeft[idxCycle],
                      cyclePerc: curPerc
                    }
                  } else {
                    curCycle = DataHelper.getCyclePercentageFromTime(curTime, track.cycleTimes, DataContext.leftSide);
                  }
                }
              } else {
                if (curContext === DataContext.leftSide) {
                  curCycle = DataHelper.getCyclePercentageFromTime(curTime, track.cycleTimes, DataContext.noSide);
                }
              }
              if (curCycle?.cyclePerc > -1) {
                swingTransition.perc = curCycle.cyclePerc;
                swingTransition.cycle = curCycle.cycleLabel;
                swingTransition.lineStyle = this.getCurrentSwingTransitionLineStyle(swingTransition, curContext);
              }
            }
          }
        }
      }
    }
    return chartGroup;
  }

  /**
   * Picks a line style for the a swing transition based on the current context.
   * Line styles are changed when new cycles for a specific laterality are found within a new trial.
   * @see https://gitlab.com/moveshelf/mvp/-/issues/2032
   */
  protected getCurrentSwingTransitionLineStyle(swingTransition: SwingTransition, context: DataContext): number[] {
    let currentLineStyle = context === DataContext.rightSide ? this.currentLineStyleIndexRight : this.currentLineStyleIndexLeft;
    const groupId = swingTransition.trialName;
    if (context === DataContext.rightSide && groupId !== this.currentLineStyleIdRight) {
      this.currentLineStyleIdRight = groupId;
      currentLineStyle = ++this.currentLineStyleIndexRight;
    }
    if (context === DataContext.leftSide && groupId !== this.currentLineStyleIdLeft) {
      this.currentLineStyleIdLeft = groupId;
      currentLineStyle = ++this.currentLineStyleIndexLeft;
    }

    return this.defaultLineDashStyles[currentLineStyle];
  }

  public extractForcePlateContext(chartGroup: LineChartGroup[], trialTemplate: TrialTemplate): void {
    for (const chart of chartGroup) {
      if (chart.name.toLowerCase().includes('forces')) {
        this.contextForcePlates = [];
        this.myForcePlates = [];
        // extract all forceplate names
        for (const track of chart.tracks) {
          const forcePlateName = track.labels.title.split('-');
          // check whether we have FP names, expect <Left/Right>-<fpName>-<Fx/Fy/Fz>
          if (track?.hasCycles && track.hasCycles === true && forcePlateName && forcePlateName.length >= 3 && forcePlateName[2] !== ' ' && this.myForcePlates.indexOf(forcePlateName[1]) === -1) {
            this.myForcePlates.push(forcePlateName[1]);
          }
        }
        let contextForcePlatesFromTemplate: ForcePlateContextTemplate;
        let fpNames: string[] = [];
        if (trialTemplate !== undefined && trialTemplate.contextForcePlates !== undefined) {
          contextForcePlatesFromTemplate = trialTemplate.contextForcePlates;
          const entries = Object.entries(contextForcePlatesFromTemplate);
          contextForcePlatesFromTemplate = Object.fromEntries(
            entries.map(([key, value]) => {
              return [key.toLowerCase(), value];
            })
          );
          fpNames = Object.keys(contextForcePlatesFromTemplate);
        }

        let iForcePlate = 1;
        for (const myForcePlate of this.myForcePlates) {
          if (myForcePlate.indexOf('ForceCycles') !== -1) {
            // Don't add name 'ForceCycles' to context def, this originates from xlsx processor with context defined.
            continue;
          }

          const fpRegEx = /fp([0-9])$/;     // if we find structure FPx, then use this, otherwise use an increasing increment
          const fpName = fpRegEx.test(myForcePlate.toLowerCase()) ? myForcePlate.toLowerCase() : 'fp' + String(iForcePlate);
          const myFp: ForcePlateContext = {
            name: fpName,
            context: undefined,
            label: myForcePlate,
          };
          const index = fpNames.indexOf(fpName);
          if (index > -1 && contextForcePlatesFromTemplate[fpName] && contextForcePlatesFromTemplate[fpName].length > 0
            && (contextForcePlatesFromTemplate[fpName].toLowerCase() === 'left' || contextForcePlatesFromTemplate[fpName].toLowerCase() === 'right' || contextForcePlatesFromTemplate[fpName].toLowerCase() === 'invalid')) {
            myFp.context = contextForcePlatesFromTemplate[fpName].toLowerCase();
          }
          this.contextForcePlates.push(myFp);
          iForcePlate++;
        }
        this.totalForcePlates.next(this.contextForcePlates);
      }
    }
  }

  public handleTrackData(chartGroup: LineChartGroup[], trialTemplate: TrialTemplate): LineChartGroup[] {
    const newCharts: LineChartGroup[] = [];
    for (const chart of chartGroup) {
      const fpLabels = this.contextForcePlates.map(fp => fp.label);
      let applyNormalizeToBodyWeight = false;
      if ((chart.name.toLowerCase().includes('force') || chart.name.toLowerCase().includes('moments') || chart.name.toLowerCase().includes('powers') || chart.name.toLowerCase().includes('lengths and velocities'))
        && this.normalizeToBodyWeight && this.subjectWeight !== undefined) {
        applyNormalizeToBodyWeight = true;
      }
      const newChart = { name: chart.name, tracks: [] };
      const newTracks = [];
      for (const track of chart.tracks) {
        let skipTrack = false;
        let xLabel = 'perc';
        if (track.labels !== undefined && track.labels.time !== undefined) {
          xLabel = track.labels.time;
        }
        if (track.dataType === 'emg' && !track.labels.vAxis.includes('[uV]')) {
          let multiplier = 1;
          if (trialTemplate !== undefined && trialTemplate.emgDataUnit !== undefined) {
            if (track.labels.vAxis.endsWith(']') && track.labels.vAxis.includes('[')) {
              track.labels.vAxis = track.labels.vAxis.substring(0, track.labels.vAxis.lastIndexOf('['));
            }
            track.labels.vAxis = track.labels.vAxis + '[' + trialTemplate.emgDataUnit.unit + ']';
            multiplier = trialTemplate.emgDataUnit.multiplier;
          } else {
            // convert V to uV
            multiplier = 1e6;
            track.labels.vAxis = track.labels.vAxis.replace('[V]', '[uV]');
          }
          track.values = this.applyScalarToSamples(track.values, xLabel, multiplier);
        }
        track.mergedTrack = false;

        let cycleTimes;
        let hasCycles = false;
        let samples: Sample[];
        if (track.events) {
          const context = DataHelper.getDataContext(track.labels.title, DataContext.leftSide, track.mergedTrack);
          const cycles = DataHelper.getCyclesForContext(track.events, context);
          cycleTimes = DataHelper.getCycleTimes(cycles);
          if (cycles.length > 0) {
            samples = DataHelper.addDataToCycles(track.values, cycles, track.labels.data);
            track.labels.data = DataHelper.updateLabelsFromSamples(samples, xLabel);
            hasCycles = true;
          } else {
            samples = track.values;
            hasCycles = false;
          }
        } else {
          samples = track.values;
          if (track.labels.hAxis.toLowerCase().includes('cycle')) {
            hasCycles = true;
            cycleTimes = track['cycleTimes'];

            // Do averaging
            samples = this.calculateAverage(samples, xLabel);
            // update title, labels and samples if needed
            let newTitle = track.labels.title;
            let strToAdd = '';
            if (track.dataType === 'emg') {
              const stringsToCheck = ['-rms', '-value', '-processed'];
              ({ newTitle, strToAdd } = this.getStringAndTitle(track.labels.title, stringsToCheck));
              samples = this.updateSamplesWithUpdatedLabels(samples, xLabel, strToAdd);
            } else if (track.dataType === 'force' && chart.name.toLowerCase().includes('forces')) {
              const myLabels = ['-Fx', '-Fy', '-Fz'];
              let stringsToCheck = [];
              for (const myForcePlate of this.myForcePlates) {
                for (const myLabel of myLabels) {
                  stringsToCheck.push('-' + myForcePlate + myLabel);
                }
              }
              // if we extract forces from "forces" in c3d, we get our "normalized" structure, and replace it to get our expected -<fpName>-<fDir> structure
              const fpLabelsToCheck = ['-x - ', '-y - ', '-z - '];
              const fpLabelsToCheckReplace = ['-fp-Fx', '-fp-Fy', '-fp-Fz'];
              stringsToCheck = stringsToCheck.concat(fpLabelsToCheck);
              ({ newTitle, strToAdd } = this.getStringAndTitle(track.labels.title, stringsToCheck));
              for (let i = 0; i < fpLabelsToCheck.length; i++) {
                strToAdd = strToAdd.replace(fpLabelsToCheck[i], fpLabelsToCheckReplace[i]);
              }
              const trackContext = track.labels.title.split('-')[0].toLowerCase();
              const myForcePlate = strToAdd.split('-')[1];
              const index = fpLabels.indexOf(myForcePlate);
              if (myForcePlate !== undefined && this.contextForcePlates && index > -1 && this.contextForcePlates[index] !== undefined) {
                if (this.contextForcePlates[index].context === undefined || this.contextForcePlates[index].context.includes(trackContext) === true) {
                  skipTrack = false;
                } else {
                  skipTrack = true;
                }
              }
              samples = this.updateSamplesWithUpdatedLabels(samples, xLabel, strToAdd);
            }
            track.labels.title = newTitle;
            track.labels.data = DataHelper.updateLabelsFromSamples(samples, xLabel);
          } else {
            hasCycles = false;
          }
        }
        track.hasCycles = hasCycles;
        track.cycleTimes = cycleTimes;
        track.values = samples;
        const filteredTrack = track;

        // Note: we only apply the normalization for forces, since we assume the moments and powers to already have been normalized (part of the common workflow, e.g. Polygon
        if (applyNormalizeToBodyWeight) {
          if (chart.name.toLowerCase().includes('force') && !track.labels.vAxis.includes('[%BW]') && !track.labels.vAxis.includes('[N/kg]')) {
            filteredTrack.values = this.normalizeSamplesToBodyWeight(filteredTrack.values, xLabel, this.normalizeToBodyMassInsteadOfWeight);
            if (this.normalizeToBodyMassInsteadOfWeight === false) {
              filteredTrack.values = this.applyScalarToSamples(filteredTrack.values, xLabel, 100); // Get to percentage 1-100
            }
          } else if (chart.name.toLowerCase().includes('moment') && track.labels.vAxis.length >= 4 && track.labels.vAxis.substring(track.labels.vAxis.length - 4, track.labels.vAxis.length) === 'Nmm]') {
            // Check if we get Nmm from c3d processor and convert to Nm (/1000)
            filteredTrack.values = this.applyScalarToSamples(filteredTrack.values, xLabel, 0.001);
            track.labels.vAxis = track.labels.vAxis.replace('Nmm]', 'Nm]');
          }

          if (filteredTrack.labels.vAxis.length > 0) {
            filteredTrack.labels.vAxis = this.updateLabelForWeightNormalization(filteredTrack.labels.vAxis, chart.name);
          }
        }
        if (skipTrack === false) {
          newTracks.push(filteredTrack);
        }
      }
      if (newTracks.length > 0) {
        newChart.tracks = newTracks;
        newCharts.push(newChart);
      }
    }
    return newCharts;
  }

  public prepareForceRefTrack(refGroup: LineChartGroup, xLabel: string): DataTrack {
    let newTitle = '';
    for (const track of refGroup.tracks) {
      newTitle = track.labels.title;
      let strToAdd = '';
      const stringsToCheck = ['-Fx', '-Fy', '-Fz'];
      ({ newTitle, strToAdd } = this.getStringAndTitle(track.labels.title, stringsToCheck));
      track.values = this.updateSamplesWithUpdatedLabels(track.values, xLabel, strToAdd);
      track.labels.title = newTitle;
      track.labels.data = DataHelper.updateLabelsFromSamples(track.values, xLabel);
    }

    return this.mergeCycleTracksBasedOnTitle(refGroup, newTitle);
  }

  public updateLabelForWeightNormalization(vAxis: string, name: string): string {
    if (name.toLowerCase().includes('force') && vAxis.length > 0 && vAxis.substring(vAxis.length - 3, vAxis.length) === '[N]') {
      // if we normalize to body weight, we output a percentage (0-100+) wrt body weight (%BW). If we normalize to body mass, it's N/kg
      if (this.normalizeToBodyMassInsteadOfWeight) {
        vAxis = vAxis.substring(0, vAxis.length - 1) + '/kg]';
      } else {
        vAxis = vAxis.replace('[N]', '[%BW]');
      }
    }
    if ((name.toLowerCase().includes('moment') || name.toLowerCase().includes('power')) && vAxis.length > 0 && vAxis[vAxis.length - 1] === ']') {
      vAxis = vAxis.substring(0, vAxis.length - 1) + '/kg]';
    }
    return vAxis;
  }

  public normalizeSamplesToBodyWeight(samples: Sample[], xLabel: string, normalizeToBodyMassInsteadOfWeight: boolean): Sample[] {
    let div = this.subjectWeight;
    if (normalizeToBodyMassInsteadOfWeight) {
      div /= this.gravitationalAcceleration;
    }
    for (const sample of samples) {
      for (const label in sample) {
        if (label !== xLabel && sample !== null) {
          sample[label] /= div;
        }
      }
    }
    return samples;
  }

  public applyScalarToSamples(samples: Sample[], xLabel: string, scalar: number): Sample[] {
    for (const sample of samples) {
      for (const label in sample) {
        if (label !== xLabel && sample[label] !== null) {
          sample[label] *= scalar;
        }
      }
    }
    return samples;
  }

  protected updateSamplesWithUpdatedLabels(samples: Sample[], xLabel: string, strToAdd: string): Sample[] {
    const newSamples = [];

    for (let k = 0; k < samples.length; k++) {
      const newSample = {};
      for (const label in samples[k]) {
        if (label === xLabel) {
          newSample[xLabel] = samples[k][xLabel];
        } else {
          const newLabel = label + strToAdd;
          newSample[newLabel] = samples[k][label];
        }
      }
      newSamples.push(newSample);
    }
    return newSamples;
  }

  protected getStringAndTitle(title: string, stringsToCheck: string[]) {
    let newTitle = title;
    let strToAdd = '';
    for (const stringToCheck of stringsToCheck) {
      if (title.includes(stringToCheck)) {
        newTitle = title.split(stringToCheck)[0];
        strToAdd = stringToCheck;
        break;
      }
    }

    return { newTitle, strToAdd };
  }

  // NOTE: this is also done in the report component (addDataToKinematics), but will become obsolete there.
  protected calculateAverage(samples: Sample[], xLabel: string): Sample[] {
    let meanLabel;
    let stdLabel;
    let hasMean = false;
    let hasStd = false;
    const newSamples: Sample[] = [];
    let nCycles = 0;
    for (let iSample = 0; iSample < samples.length; iSample++) {
      if (iSample === 0) {
        for (const p in samples[iSample]) {
          if (p.toLowerCase().substring(0, this.meanDataLabel.length) === this.meanDataLabel.toLowerCase()) {
            meanLabel = p;
            hasMean = true;
          } else if (p.toLowerCase().substring(0, this.stdDataLabel.length) === this.stdDataLabel.toLowerCase()) {
            stdLabel = p;
            hasStd = true;
          } else if (p.toLowerCase() !== xLabel) {
            nCycles++;
          }
        }
      }

      const sample = samples[iSample];

      let meanValue = 0;
      let stdValue = 0;
      if (hasMean && hasStd) {
        meanValue = samples[iSample][meanLabel];
        stdValue = samples[iSample][stdLabel];
      } else {
        if (meanLabel === undefined) {
          meanLabel = this.meanDataLabel;
        }
        if (stdLabel === undefined) {
          stdLabel = this.stdDataLabel;
        }
        ({ meanValue, stdValue } = this.calculateMissingMeanStd(samples[iSample], xLabel, stdLabel, meanValue, hasStd, stdValue));
      }
      if (nCycles > 1) {
        sample[meanLabel] = meanValue;  // add mean to sample
        sample[stdLabel] = stdValue;    // add std to sample
      }
      newSamples.push(sample);
    }
    return newSamples;
  }

  public calculateMissingMeanStd(sample: Sample, xLabel: string, stdLabel: any, meanValue: number, hasStd: boolean, stdValue: number) {
    let nVals = 0;
    let sum = 0;
    let sumsq = 0;
    for (const p in sample) {
      if (p.toLowerCase() !== xLabel && p.toLowerCase() !== stdLabel) {
        const value: number = sample[p];

        if (value !== null) {
          sum += value;
          sumsq += value * value;
          nVals++;
        }
      }
    }
    if (nVals > 0) {
      meanValue = sum / nVals;
    }
    if (hasStd) {
      stdValue = sample[stdLabel];
    } else if (nVals > 1) {
      stdValue = Math.sqrt((sumsq - sum * sum / nVals) / (nVals - 1));
    }
    return { meanValue, stdValue };
  }

  private calculateFilteredMeanStd(sample: Sample, xLabel: string, labels: string[]) {
    let nVals = 0;
    let sum = 0;
    let sumsq = 0;
    let meanValue: number = undefined;
    let stdValue = 0;
    for (const p in sample) {
      if (p.toLowerCase() !== xLabel && labels.includes(p)) {
        const value: number = sample[p];
        if (value !== null) {
          sum += value;
          sumsq += value * value;
          nVals++;
        }
      }
    }
    if (nVals > 0) {
      meanValue = sum / nVals;
    }
    if (nVals > 1) {
      stdValue = Math.sqrt((sumsq - sum * sum / nVals) / (nVals - 1));
    }
    return { meanValue, stdValue };
  }

  public averagePerCondition(track: DataTrack, sessionAndConditionObject: SessionAndConditionObject[], groupName: string): DataTrack {
    const xLabel = track?.labels?.time ?? 'perc';
    const dataLabels = Object.keys(track.labels.data);
    for (const value of track.values) {
      for (const session of sessionAndConditionObject) {
        for (const condition of session.conditions) {
          const contexts = [' (left)', ' (right)', '<<<noContext>>>'];
          for (const context of contexts) {
            let stringsToCheck: string[] = [''];
            if (groupName.toLowerCase().includes('emg')) {
              stringsToCheck = ['-rms', '-processed'];              // we don't average the values.
            } else if (groupName.toLowerCase().includes('forces')) {
              const myLabels = ['-Fx', '-Fy', '-Fz'];
              stringsToCheck = [];
              if (!this.forceCyclesFromForcePlateData) {
                const myForcePlate = 'fp';      // label is fixed for cycles in file
                for (const myLabel of myLabels) {
                  stringsToCheck.push('-' + myForcePlate + myLabel);
                }
              } else {
                for (const myForcePlate of this.myForcePlates) {
                  for (const myLabel of myLabels) {
                    stringsToCheck.push('-' + myForcePlate + myLabel);
                  }
                }
              }
            }
            for (const stringToCheck of stringsToCheck) {
              const conditionDataLabels: string[] = [];
              for (const trial of condition.trials) {
                const path = session.sessionName + ' > ' + condition.conditionName + ' > ' + trial.title;
                if (context === '<<<noContext>>>') {
                  conditionDataLabels.push(...dataLabels.filter(x => x.split(' > ')[0].startsWith('cycle-') && x.split(' > ')[0].endsWith(stringToCheck) && !(x.split(' > ')[0].endsWith(' (left)') || x.split(' > ')[0].endsWith(' (right)')) && x.endsWith(path)));
                } else {
                  // check here whether dataLabel is disabled
                  const filteredLabels = dataLabels.filter(x => !(track?.disabledLines !== undefined && track.disabledLines.includes(x)) && x.split(' > ')[0].startsWith('cycle-') && x.split(' > ')[0].endsWith(stringToCheck + context) && x.endsWith(path));
                  conditionDataLabels.push(...filteredLabels);
                }
              }

              if (conditionDataLabels.length === 0) {
                continue;
              }

              let meanValue = 0;
              let stdValue = 0;
              const contextStr = context === '<<<noContext>>>' ? '' : context;
              const meanLabel = 'mean' + stringToCheck + contextStr + ' > ' + session.sessionName + ' > ' + condition.conditionName + ' > <<<conditionAvg>>>';
              const stdLabel = 'std' + stringToCheck + contextStr + ' > ' + session.sessionName + ' > ' + condition.conditionName + ' > <<<conditionAvg>>>';

              ({ meanValue, stdValue } = this.calculateFilteredMeanStd(value, xLabel, conditionDataLabels));
              if (meanValue !== undefined) {
                value[meanLabel] = meanValue;
                value[stdLabel] = stdValue;
              }
            }
          }
        }
      }
    }
    track.labels.data = DataHelper.updateLabelsFromSamples(track.values, xLabel);
    return track;
  }

  public filterValues(values: Sample[], timeLabel: string, labelsToShow: Record<string, string>): Sample[] {
    const localValues: Sample[] = [];
    for (let i = 0; i < values.length; i++) {
      const sampleTime = values[i][timeLabel];
      const dataSample: Sample = {};
      dataSample[timeLabel] = sampleTime;

      for (const label in labelsToShow) {
        dataSample[label] = values[i][labelsToShow[label]];
      }

      localValues.push(dataSample);
    }
    return localValues;
  }

  public addDataTrack(trackData: any, name: string, vAxisLabel: string, applyWeightNormalization: boolean, paramsData: any = undefined): LineChartGroup {
    let normalizeToWeight = false;
    let correctMoments = false;
    let nameToCheck = name;
    const isReference = name.toLowerCase().includes('<<<reference>>>');
    const refOrAvg = isReference || name.toLowerCase().includes('<<<average>>>');
    const refOrAvgWithForce = refOrAvg && vAxisLabel.toLowerCase().includes('force');
    const refOrAvgWithMoment = refOrAvg && vAxisLabel.toLowerCase().includes('moment');
    if (applyWeightNormalization && this.normalizeToBodyWeight) {
      // Note: we only apply the normalization for forces, since we assume the moments and powers to already have been normalized (part of the common workflow, e.g. Polygon)
      if ((name.toLowerCase().includes('force') || refOrAvgWithForce) && !vAxisLabel.includes('[%BW]') && !vAxisLabel.includes('[N/kg]')) {
        normalizeToWeight = true;
      } else if ((name.toLowerCase().includes('moment') || refOrAvgWithMoment) && vAxisLabel.length >= 4 && vAxisLabel.substring(vAxisLabel.length - 4, vAxisLabel.length) === 'Nmm]') {
        // Check if we get Nmm from c3d processor and convert to Nm (/1000)
        correctMoments = true;
        vAxisLabel = vAxisLabel.replace('Nmm]', 'Nm]');
      }

      if (refOrAvg) {
        nameToCheck = vAxisLabel;
      }
    }

    const vAxisLabelLower = vAxisLabel.toLowerCase();
    let emgMultiplier = -1;
    if (refOrAvg && vAxisLabelLower.includes('emg')) {
      // Reference data is provided in V, so convert it to the same unit used by the emgCharts.
      // our emg unit label contains unit at the end [uV], [mV] or [V]
      emgMultiplier = 1;
      if (vAxisLabelLower.includes('[uv]') || vAxisLabelLower.includes('[µv]')) {
        emgMultiplier = 1e6;
      } else if (vAxisLabelLower.includes('[mv]')) {
        emgMultiplier = 1e3;
      } else if (vAxisLabelLower.includes('[v]')) {
        emgMultiplier = 1;
      } else {
        console.warn(`Unknown EMG unit in ${vAxisLabel}, defaulting to uV`);
        emgMultiplier = 1e6;
      }
    }

    const dataTracks: DataTrack[] = [];
    for (const data of trackData) {
      // Rename 'cycle' to mean
      data.values.forEach(function (obj) {
        obj.mean = obj.cycle;
        delete obj.cycle;
      });

      const xLabel = 'perc';
      if (normalizeToWeight) {
        // skip applying body weight for ref and avg
        if (!!refOrAvgWithForce && this.subjectWeight > 0) {
          data.values = this.normalizeSamplesToBodyWeight(data.values, xLabel, this.normalizeToBodyMassInsteadOfWeight);
        }
        if (this.normalizeToBodyMassInsteadOfWeight === false) {
          data.values = this.applyScalarToSamples(data.values, xLabel, 100); // Get to percentage 1-100
        }
      } else if (correctMoments) {
        data.values = this.applyScalarToSamples(data.values, xLabel, 0.001);
      } else if (emgMultiplier > -1) {
        data.values = this.applyScalarToSamples(data.values, xLabel, emgMultiplier);
      }

      if (vAxisLabel.length > 0) {
        vAxisLabel = this.updateLabelForWeightNormalization(vAxisLabel, nameToCheck);
      }

      let colors = this.colorService.defaultColorsChart;
      const swingTransitions: SwingTransition[] = [];
      if (isReference) {
        colors = [this.colorService.referenceColor];
        if (paramsData !== undefined) {
          const footOff = paramsData.data.find(x => x.label && x.label.toLowerCase().includes("footoff") && !x.label.toLowerCase().includes("opposite"));
          if (footOff && footOff.values && footOff.values.mean !== undefined) {
            swingTransitions.push({trialName: '<<<reference>>>', time: -1, context: DataContext.noSide, perc: footOff.values.mean, cycle: undefined, lineStyle: [0, 0], opposite: false, gaitEventName: 'Foot Off'});
          }
          const oppositefootOff = paramsData.data.find(x => x.label && x.label.toLowerCase().includes("footoff") && x.label.toLowerCase().includes("opposite"));
          if (oppositefootOff && oppositefootOff.values && oppositefootOff.values.mean !== undefined) {
            swingTransitions.push({trialName: '<<<reference>>>', time: -1, context: DataContext.noSide, perc: oppositefootOff.values.mean, cycle: undefined, lineStyle: [0, 0], opposite: true, gaitEventName: 'Foot Off'});
          }
          const oppositefootStrike = paramsData.data.find(x => x.label && x.label.toLowerCase().includes("footcontact") && x.label.toLowerCase().includes("opposite"));
          if (oppositefootStrike && oppositefootStrike.values && oppositefootStrike.values.mean !== undefined) {
            swingTransitions.push({trialName: '<<<reference>>>', time: -1, context: DataContext.noSide, perc: oppositefootStrike.values.mean, cycle: undefined, lineStyle: [0, 0], opposite: true, gaitEventName: 'Foot Strike'});
          }
        }
      }
      const dataTrack: DataTrack = {
        id: name,
        values: data.values,
        events: undefined,
        colors: colors,
        labels: {
          title: data.label,
          hAxis: 'Cycle [%]',
          vAxis: vAxisLabel,
          time: xLabel,
          data: { 'mean': 'mean', 'std': 'std' },
        },
        series: [],
        hasCycles: true,
        swingTransitions: swingTransitions.length > 0 ? swingTransitions : undefined,
        dataType: 'reference'
      };
      dataTracks.push(dataTrack);
    }
    return { name: name, tracks: dataTracks };
  }

  /** Function specific to EMG consistency chart to separate left and right values in the datatrack into separate tracks to be individually plotted. */
  public splitEmgConsistencyLeftRight(dataTracks: DataTrack[]): DataTrack[] {
    const dataTracksRight: DataTrack[] = [];
    for (const dataTrack of dataTracks) {
      const xLabel = dataTrack.labels.time;
      const myLabels = dataTrack.labels.data;
      const labelsRightArray = Object.keys(myLabels).filter(x => x.includes('right'));
      const labelsOtherArray = Object.keys(myLabels).filter(x => !x.includes('right'));
      if (labelsRightArray.length > 0 && labelsOtherArray.length > 0) {
        // If we have 'right' labels, extract them and place in separate track (and remove from original)
        const dataTrackRight = JSON.parse(JSON.stringify(dataTrack)); // deep copy
        const labelsOther = {};
        for (const label of labelsOtherArray) {
          labelsOther[label] = myLabels[label];
        }
        dataTrack.labels.data = labelsOther;

        const labelsRight = {};
        for (const label of labelsRightArray) {
          labelsRight[label] = myLabels[label];
        }
        dataTrackRight.labels.data = labelsRight;

        const newValues: Sample[] = [];
        const newValuesRight: Sample[] = [];
        for (const value of dataTrack.values) {
          const newValue: Sample = {};
          const newValueRight: Sample = {};
          if (value[xLabel] !== undefined) {
            newValue[xLabel] = value[xLabel];
            newValueRight[xLabel] = value[xLabel];
          }
          for (const label in labelsOther) {
            if (value[label] !== undefined) {
              newValue[label] = value[label];
            }
          }
          for (const label in labelsRight) {
            if (value[label] !== undefined) {
              newValueRight[label] = value[label];
            }
          }
          newValues.push(newValue);
          newValuesRight.push(newValueRight);
        }
        dataTrack.values = newValues;
        dataTrackRight.values = newValuesRight;
        dataTrackRight.id += '_right';

        dataTracksRight.push(dataTrackRight);
      }

    }
    return dataTracks.concat(dataTracksRight);
  }

  /**
   * Attempts to merge two tracks together
   * @see parseTracks for info on merging strategy
   * @param track A track
   * @param newTrack Another track
   * @param xLabel Label of x axis
   * @param findNearestSample If true, data will be synchronized based on nearest sample search, including interpolation of data (currently for EMG only)
   * @returns A new DataTrack containing the result of merging newChart into chart
   */
  protected mergeSameTracksFromTrials(track: DataTrack, newTrack: DataTrack, xLabel: string, findNearestSample: boolean): DataTrack {
    // Create the new merged track
    const mergedTrack: DataTrack = {
      id: track.id, // Id is the same for all track
      colors: track.colors,
      cycleTimes: undefined,
      swingTransitions: undefined,
      mergedTrack: true,
      originalId: track?.originalId !== undefined ? track.originalId : track.id,
      hasCycles: track.hasCycles || newTrack.hasCycles,
      hasGcdCycles: track.hasGcdCycles || newTrack.hasGcdCycles,
      values: [],
      events: undefined,
      series: [],
      labels: { ...track.labels, data: { ...track.labels.data, ...newTrack.labels.data } },
      dataType: track?.dataType !== undefined ? track.dataType : newTrack?.dataType,
      disabledLines: undefined
    };

    // Generate values by appending the new track's into the old one
    let applyNearestSampleSearch = false;
    if (findNearestSample === true) {
      applyNearestSampleSearch = true;
      if (track.values.length == newTrack.values.length) {
        applyNearestSampleSearch = false;
      } else {
        applyNearestSampleSearch = applyNearestSampleSearch && this.checkForIncreasingXaxis(track.values, xLabel);
        applyNearestSampleSearch = applyNearestSampleSearch && this.checkForIncreasingXaxis(newTrack.values, xLabel);
      }
    }
    if (applyNearestSampleSearch === true) {
      let mergedValues: Sample[];
      let newValues: Sample[];
      // deep copy necessary tracks based on shortest length
      if (track.values.length <= newTrack.values.length) {
        mergedValues = JSON.parse(JSON.stringify(track.values));
        newValues = JSON.parse(JSON.stringify(newTrack.values));
      } else {
        mergedValues = JSON.parse(JSON.stringify(newTrack.values));
        newValues = JSON.parse(JSON.stringify(track.values));
      }
      newValues = this.interpolateSamples(newValues, mergedValues.length, xLabel);
      mergedTrack.values = this.mergeNearestSample(mergedValues, newValues, xLabel);
    } else {
      const hasRawSamplesForCycles = track.hasCycles && newTrack.hasCycles && (track.values.length > 101 || newTrack.values.length > 101);
      if (track.values.length >= newTrack.values.length) {
        mergedTrack.values = this.mergeSamples(track.values, newTrack.values, track.labels.time, hasRawSamplesForCycles);
      } else {
        mergedTrack.values = this.mergeSamples(newTrack.values, track.values, track.labels.time, hasRawSamplesForCycles);
      }
    }
    // handle cycleTimes
    const newCycleTimes = track?.cycleTimes ?? {};
    if (newTrack.cycleTimes) {
      for (const valueObjKey in newTrack.cycleTimes) {
        newCycleTimes[valueObjKey] = newTrack.cycleTimes[valueObjKey];
      }
    }
    mergedTrack.cycleTimes = newCycleTimes;

    // handle swingTransitions
    const newSwingTransitions = track?.swingTransitions ?? [];
    if (newTrack?.swingTransitions) {
      for (const swingTransition of newTrack.swingTransitions) {
        newSwingTransitions.push(swingTransition);
      }
    }
    mergedTrack.swingTransitions = newSwingTransitions.length > 0 ? newSwingTransitions : undefined;

    // handle disabledLines
    const newDisabledLines = track?.disabledLines ?? [];
    if (newTrack?.disabledLines) {
      for (const disabledLine of newTrack.disabledLines) {
        newDisabledLines.push(disabledLine);
      }
    }
    mergedTrack.disabledLines = newDisabledLines.length > 0 ? newDisabledLines : undefined;

    return mergedTrack;
  }

  protected checkForIncreasingXaxis(values: Sample[], xLabel: string): boolean {
    // Note: in our c3d processor we had a bug (fixed in issue 1985), which gave faulty emg tracks when multiple cycles were present.
    // One way to check is to check non-ascending x-axis (we get multiple 0-100 percent ranges)
    let applyNearestSampleSearch = true;
    let xLast = -1;
    for (let i = 0; i < values.length; i++) {
      if (values[i][xLabel] !== undefined && values[i][xLabel] > xLast) {
        xLast = values[i][xLabel];
      } else {
        applyNearestSampleSearch = false;
        break;
      }
    }
    return applyNearestSampleSearch;
  }

  public interpolateCycleTrack(newValues: Sample[], nPointsInterp: number, xLabel: string, startAtZero = false, maxLength = -1): Sample[] {
    const interpValues: Sample[] = [];
    let iStart = 0;
    if (startAtZero && newValues[0][xLabel] == 1) {
      iStart = -1;
    }
    for (let i = iStart; i < newValues.length; i++) {
      if (i === newValues.length - 1) {
        // push last sample
        if (interpValues.length < maxLength) {
          interpValues.push(newValues[i]);
        }
      } else {
        // Linear interpolation between consecutive samples using nPointsInterp points
        const next_t = newValues[i + 1][xLabel];
        const cur_t = i == -1 ? 0 : newValues[i][xLabel];
        const delta = 1 / (next_t - cur_t);
        const step = (next_t - cur_t) / nPointsInterp;
        for (let t = 0; t <= 1; t = t + step) {
          if (cur_t + t >= next_t - step / 2) {   // adding step/2 to prevent rounding adding samples close to next
            break;
          }
          const sample = {};
          const curSample = i == -1 ? newValues[0] : newValues[i]
          for (const valueObjKey in curSample) {
            if (valueObjKey === xLabel) {
              sample[xLabel] = Math.round((cur_t + t) * nPointsInterp) / nPointsInterp;
            } else if (i == -1) {
              sample[valueObjKey] = undefined;
            } else {
              const curValue = i == -1 ? 0 : newValues[i][valueObjKey]
              sample[valueObjKey] = curValue + (newValues[i + 1][valueObjKey] - curValue) * t * delta;
            }
          }
          if (interpValues.length < maxLength) {
            interpValues.push(sample);
          }
        }
      }
    }
    newValues = interpValues;
    return newValues;
  }


  protected interpolateSamples(newValues: Sample[], mergedValuesLength: number, xLabel: string): Sample[] {
    const nPointsInterp = 10;
    if (Math.round(newValues.length / (mergedValuesLength * 10)) < 10) {
      const interpValues: Sample[] = [];
      for (let i = 0; i < newValues.length; i++) {
        if (i === newValues.length - 1) {
          // push last sample
          interpValues.push(newValues[i]);
        } else {
          // Linear interpolation between consecutive samples using nPointsInterp points
          const next_t = newValues[i + 1][xLabel];
          const cur_t = newValues[i][xLabel];
          const delta = 1 / (next_t - cur_t);
          const step = (next_t - cur_t) / nPointsInterp;

          for (let t = 0; t <= 1; t = t + step) {
            if (cur_t + t >= next_t) {
              break;
            }
            const sample = {};
            for (const valueObjKey in newValues[i]) {
              if (valueObjKey === xLabel) {
                sample[xLabel] = cur_t + t;
              } else {
                sample[valueObjKey] = newValues[i][valueObjKey] + (newValues[i + 1][valueObjKey] - newValues[i][valueObjKey]) * t * delta;
              }
            }
            interpValues.push(sample);
          }
        }
      }
      newValues = interpValues;
    }
    return newValues;
  }

  protected mergeNearestSample(mergedValues: Sample[], newValues: Sample[], xLabel: string): Sample[] {
    // Find nearest sample and merge the Sample arrays based on min time difference (xLabel)
    // Sort the final array if samples were added due to minimum not found.
    let doSort = false;
    for (let i = 0; i < newValues.length; i++) {
      let minDiff = 1;
      let j_min = -1;
      for (let j = 0; j < mergedValues.length; j++) {
        if (newValues[i][xLabel] !== undefined && mergedValues[j][xLabel] !== undefined && Math.abs(newValues[i][xLabel] - mergedValues[j][xLabel]) < minDiff) {
          minDiff = Math.abs(newValues[i][xLabel] - mergedValues[j][xLabel]);
          j_min = j;
          if (minDiff < 1e-6) {
            break;
          }
        }
      }
      if (j_min > -1) {
        for (const valueObjKey in newValues[i]) {
          if (valueObjKey !== xLabel) {
            mergedValues[j_min][valueObjKey] = newValues[i][valueObjKey];
          }
        }
      } else {
        mergedValues.push(newValues[i]);
        doSort = true;
      }
    }
    if (doSort === true) {
      mergedValues.sort((a, b) => a[xLabel] - b[xLabel]);
    }
    return mergedValues;
  }

  protected mergeSamples(values: Sample[], newValues: Sample[], xLabel: string, hasRawSamplesForCycles: boolean = false): Sample[] {
    // merge two Sample arrays
    let mergedValues: Sample[];
    let doSort = false;
    // If we get the same length, use simple merge logic
    if (values.length === newValues.length) {
      mergedValues = [];
      for (let i = 0; i < newValues.length; i++) {
        const newValue: Sample = { ...values[i] };
        for (const valueObjKey in newValues[i]) {
          newValue[valueObjKey] = newValues[i][valueObjKey];
        }
        mergedValues.push(newValue);
      }
    } else {
      // merge samples by matching the time values
      // if we get raw samples for cycles (i.e. more than 101 that we get for GCD files, we allow to merge as well so tracks are shown, here we interpolate the points in echarts)
      const doMerge = this.roundXaxisValuesAndCheckForMerge(values, newValues, xLabel) || hasRawSamplesForCycles;
      mergedValues = JSON.parse(JSON.stringify(values)); // deep copy
      if (!doMerge) {
        const errorMsg = 'Trials have data with different frequencies, chart data can not be merged, displaying first trial only.';
        console.error(errorMsg);
        this.timeSeriesMergeWarning = errorMsg;
      } else {
        for (let i = 0; i < newValues.length; i++) {
          const value = mergedValues.find(x => x[xLabel] === newValues[i][xLabel]);
          if (value) {
            // add to existing value
            for (const valueObjKey in newValues[i]) {
              value[valueObjKey] = newValues[i][valueObjKey];
            }
          } else {
            // push new value
            doSort = true;
            const newValue: Sample = {};
            for (const valueObjKey in newValues[i]) {
              newValue[valueObjKey] = newValues[i][valueObjKey];
            }
            mergedValues.push(newValue);
          }
        }
        if (doSort) {
          mergedValues.sort((a, b) => a[xLabel] - b[xLabel]);
        }
      }
    }
    return mergedValues;
  }

  private roundXaxisValuesAndCheckForMerge(values: Sample[], newValues: Sample[], xLabel: string): boolean {
    const f1 = this.getFreqForValues(values, xLabel);
    const f2 = this.getFreqForValues(newValues, xLabel);

    if (f1 <= 0 || f2 <= 0 || f1 !== f2) {
      console.error('different frequencies found for time series');
      return false;
    }

    const maxDev = 100;
    const maxFrequency = maxDev * f1;

    let doMerge;
    doMerge = this.checkForSampleIncrements(values, xLabel, f1, maxDev) || doMerge;
    doMerge = this.checkForSampleIncrements(newValues, xLabel, f2, maxDev) || doMerge;

    if (doMerge && maxFrequency > 0) {

      for (const value of values) {
        if (value[xLabel] !== undefined) {
          value[xLabel] = Math.round(value[xLabel] * maxFrequency) / maxFrequency;
        }
      }
      for (const value of newValues) {
        if (value[xLabel] !== undefined) {
          value[xLabel] = Math.round(value[xLabel] * maxFrequency) / maxFrequency;
        }
      }
      return true;
    }
    return false;
  }

  private getFreqForValues(values: Sample[], xLabel: string): number {
    const tDiff = values.map((item, index) => {
      if (index === 0 || item[xLabel] === undefined || values[index - 1][xLabel] === undefined) return 0;
      return item[xLabel] - values[index - 1][xLabel];
    });
    return Math.round(1 / this.medianOfArr(tDiff));
  }

  private medianOfArr(arr: number[]): number {
    const sortedArr = arr.sort(function (a, b) { return a - b; });
    const iMedian = Math.round(sortedArr.length / 2);
    return sortedArr[iMedian];
  }

  private checkForSampleIncrements(values: Sample[], xLabel: string, freq: number, maxDev: number): boolean {
    // check if first value is matching the frequency increments (should be within the inverse of maxDev times the frequency of the expected increment)
    if (values.length === 0 || freq <= 0) {
      return false;
    }
    return values[0][xLabel] * freq % 1 < 1 / (maxDev * freq);
  }

  /**
   * This clones a chart while updating all labels and data points names with the chart's trial name.
   * Especially useful when displaying charts from multiple trials (e.g. comparisons/reports).
   * Final names will be: `:chart_id: > :session_name: > :trial_name: > `
   * @param track A dataTrack
   * @param name The chart's trial name
   * @returns A DataTrack which has the same content of chart but all labels and data points names
   *          are prefixed with the trial's name
   */
  protected updateTrackLabels(track: DataTrack, name: string): DataTrack {
    // Copy the track and erase all values
    const newTrack = { ...track, values: [], cycleTimes: undefined };
    // Create a new labels object by copying the old one while prefixing all keys
    const context = DataHelper.getDataContext(track.labels.title, DataContext.noSide, track.mergedTrack);
    let contextLabel = '';
    const isRefOrAvg = name.includes('<<<reference>>>') || name.includes('<<<average>>>');
    if (!isRefOrAvg && context === DataContext.leftSide) {
      contextLabel = ' (left)';
    } else if (!isRefOrAvg && context === DataContext.rightSide) {
      contextLabel = ' (right)';
    }
    const labelsData = {};
    for (const labelKey in track.labels.data) {
      // skip if we already detected context
      if (labelKey.includes(' (left)') || labelKey.includes(' (right')) {
        contextLabel = '';
      }
      labelsData[labelKey + contextLabel + ' > ' + name] = track.labels.data[labelKey] + contextLabel + ' > ' + name;
    }
    if (track?.disabledLines) {
      for (const labelKey in track.disabledLines) {
        const curLabel = track.disabledLines[labelKey]
        // skip if we already detected context
        if (curLabel.includes(' (left)') || curLabel.includes(' (right')) {
          contextLabel = '';
        }
        track.disabledLines[labelKey] = curLabel + contextLabel + ' > ' + name;
      }
    }

    const xLabel = track?.labels?.time ?? 'perc';
    // Create the new values array
    for (const valueObj of track.values) {
      // For each value, just copy the `perc` key as it is
      const newValue = {};
      newValue[xLabel] = valueObj[xLabel];
      for (const valueObjKey in valueObj) {
        // But prefix all other data points
        if (valueObjKey !== xLabel) {
          newValue[valueObjKey + contextLabel + ' > ' + name] = valueObj[valueObjKey];
        }
      }
      newTrack.values.push(newValue);
    }

    // Create the new cycleTimes array
    if (track.cycleTimes) {
      const newCycleTimes = {};
      for (const valueObjKey in track.cycleTimes) {
        newCycleTimes[valueObjKey + ' > ' + name] = track.cycleTimes[valueObjKey];
      }
      newTrack.cycleTimes = newCycleTimes;
    }

    // Create the new swingTransitions array
    if (track?.swingTransitions) {
      newTrack.swingTransitions = track.swingTransitions;
      for (let i = 0; i < newTrack.swingTransitions.length; i++) {
        newTrack.swingTransitions[i].trialName = name;
      }
    }

    newTrack.labels.data = labelsData;
    return newTrack;
  }

  public isLabelInChart(label: string, names: string[]): boolean {
    if (!names) return false;
    let labelLow = label.toLowerCase();
    if (labelLow.startsWith('voltage.')) {
      // EMG channels often have names like 'Voltage.R_TA' or 'Voltage.01 LGM'.
      // We want to match using a string like 'TA', however that would match
      // every channel with 'Voltage' in it. Therefore, remove that prefix
      // before matching.
      labelLow = labelLow.slice(8);
    }

    for (const name of names) {
      if (labelLow.indexOf(name.toLowerCase()) !== -1) {
        return true; // found
      }
    }
    return false;
  }

  public orderAccordingToTemplateAndChartHiding(tracksGroup: TrialTracksGroup, template: ChartTemplateGroup[]): LineChartGroup[] {
    const orderedTracks: LineChartGroup[] = [];
    for (const chartTemplateGroup of template) {
      if (tracksGroup[chartTemplateGroup.name] !== undefined) {
        const chartTemplates = chartTemplateGroup.charts;
        const orderedTrack = [];
        for (const chartTemplate of chartTemplates) {
          const foundTrack = tracksGroup[chartTemplateGroup.name][chartTemplate.title];
          // using hideChart boolean on template
          if (foundTrack !== undefined && !chartTemplate.hideChart) {
            orderedTrack.push(foundTrack);
          }
        }
        if (orderedTrack.length > 0) {
          orderedTracks.push({ name: chartTemplateGroup.name, tracks: orderedTrack });
        }
      }
    }
    return orderedTracks;
  }

  public applyTemplateHiding(tracksGroup: LineChartGroup[], template: ChartTemplateGroup[]): void {
    for (const chartTemplateGroup of template) {
      // the tracksGroup have empty names, but we usually have a name for the group, so taking the first and only element
      if (tracksGroup && tracksGroup.length > 0 && tracksGroup[0] !== undefined) {
        const chartTemplates = chartTemplateGroup.charts;
        for (const chartTemplate of chartTemplates) {
          // if the chart is hidden, remove it from the tracksGroup tracks array
          if (chartTemplate.hideChart) {
            const chartIndex = tracksGroup[0].tracks.findIndex(x => x.labels.title === chartTemplate.title)
            tracksGroup[0].tracks.splice(chartIndex, 1);
          }
        }
      }
    }
  }


  public addToLineChartMajorGroup(lineChartMajorGroups: LineChartMajorGroup[], myTracks: TrialTracksGroup, myCharts: ChartTemplateGroup[], myChartGroup: LineChartGroup[], name: string): void {
    let myGroup: LineChartMajorGroup;
    let index: number;
    if (Object.values(myTracks).length > 0) {
      const orderedTracks = this.orderAccordingToTemplateAndChartHiding(myTracks, myCharts);
      if (orderedTracks.length > 0) {
        myGroup = { name: name, groups: orderedTracks, expanded: false };
      }
    } else if (myCharts && myChartGroup) {
      index = myChartGroup.findIndex(trackGroup => trackGroup.name === name);
      if (index > -1 && myChartGroup[index].tracks.length > 0) {
        const myTrackGroup: LineChartGroup[] = [{ name: '', tracks: myChartGroup[index].tracks }];
        this.applyTemplateHiding(myTrackGroup, myCharts);
        myGroup = { name: name, groups: myTrackGroup, expanded: myChartGroup[index].expanded };
      }
    } else if (myChartGroup) {
      index = myChartGroup.findIndex(trackGroup => trackGroup.name === name);
      if (index > -1 && myChartGroup[index].tracks.length > 0) {
        const myTrackGroup: LineChartGroup[] = [{ name: '', tracks: myChartGroup[index].tracks }];
        myGroup = { name: name, groups: myTrackGroup, expanded: myChartGroup[index].expanded };
      }
    }

    if (myGroup !== undefined) {
      lineChartMajorGroups.push(myGroup);
    }
  }

  public reset3dStatus(): void {
    this._has3dAvailable = false;
  }

  public has3dAvailable(): boolean {
    return this._has3dAvailable;
  }

  public resetLineStyles(): void {
    this.currentLineStyleIdRight = '';
    this.currentLineStyleIndexRight = -1;
    this.currentLineStyleIdLeft = '';
    this.currentLineStyleIndexLeft = -1;
  }

  public setChartMode(mode: 'trial' | 'report'): void {
    this.chartMode = mode;
  }

  public getChartMode(): 'trial' | 'report' {
    return this.chartMode;
  }

  public addGaitParams(parsedData: any, description: string, out_structure: any, clipId: string = undefined, gcdName: string = undefined): void {
    const out = { data: [], color: undefined, description: undefined };
    let disableLeft = false;
    let disableRight = false;
    if (gcdName !== undefined && clipId !== undefined && this.disabledLines[clipId] !== undefined) {
      const curClip = this.disabledLines[clipId];
      const curGcdDisabled = curClip.find(x => x.name === gcdName);
      disableLeft = curGcdDisabled?.left === true;
      disableRight = curGcdDisabled?.right === true;
    }
    if (parsedData.data) {
      for (const d of parsedData.data) {
        const contextStr = d.context as string
        if (disableLeft && contextStr && contextStr.toLowerCase().includes('left')) {
          continue
        }
        if (disableRight && contextStr && contextStr.toLowerCase().includes('right')) {
          continue
        }
        out.data.push(d);
      }
      if (out.data.length > 0) {
        out.description = description;
        out_structure.push(out);
      }
    }
  }

  public getGaitParamsTableRows(value: GaitParameterTable, groupByCondition: boolean): [ParamTableRow[], boolean, boolean, boolean] {
    const rows: ParamTableRow[] = [];
    let hasLeft = false;
    let hasRight = false;
    let hasNoContext = false;

    const nEntriesPerParam = value.tracks.values.length;
    const shouldShowOrigin = nEntriesPerParam > 1;
    // First, restructure the data from the format it arrives in, an array of
    // parameters, which can have: no context, left context, or right context.
    // Determine:
    // - What are the trial titles involved (for reports)
    // - Prepare the structure for each param so we have entries for all
    // - Whether there are entries with no context, left context or right
    //   context.
    // - Whether there are two entries about the same parameter with a left and
    //   right context. If so, wrangle them into the same row.
    const params: Record<string, ParamRecord> = {};
    const trialTitles: string[] = [];
    for (let i = 0; i < nEntriesPerParam; i++) {
      const trial_title = value.tracks.labels.title[i];
      let origin = '';
      if (shouldShowOrigin) {
        if (groupByCondition) {
          origin = this.splitSessionAndConditionName(trial_title);
        } else {
          origin = trial_title;
        }
      }
      trialTitles.push(origin);
    }

    for (let i = 0; i < nEntriesPerParam; i++) {
      const trial_params = value.tracks.values[i];
      for (const param of trial_params) {
        const paramLabel = this.getLabelWithUnit(param);
        if (!(paramLabel in params)) {
          for (let j = 0; j < nEntriesPerParam; j++) {
            if (j == 0) {
              params[paramLabel] = { value: [], left: [], right: [] };
            }
            params[paramLabel].value.push({ value: undefined, origin: trialTitles[j] });
            params[paramLabel].left.push({ value: undefined, origin: trialTitles[j] });
            params[paramLabel].right.push({ value: undefined, origin: trialTitles[j] });
          }
        }
      }
    }

    for (let i = 0; i < nEntriesPerParam; i++) {
      const trial_params = value.tracks.values[i];
      for (const param of trial_params) {
        const paramLabel = this.getLabelWithUnit(param);
        let myValue = param.values.mean === null ? undefined : (Math.round(param.values.mean * 100) / 100).toString();
        if (param?.values?.std !== undefined) {
          const stdValue = Math.round(param.values.std * 100) / 100;
          myValue += ` ± ${stdValue}`;
        }
        const context = param?.context?.trim();
        if (context === "Left") {
          params[paramLabel].left[i].value = myValue;
          hasLeft = true;
        } else if (context === "Right") {
          params[paramLabel].right[i].value = myValue;
          hasRight = true;
        } else {
          params[paramLabel].value[i].value = myValue;
          hasNoContext = true;
        }
      }
    }

    if (groupByCondition) {
      this.groupByConditions(rows, params);
    } else {
      // Now that we have the data structured and deduplicated, put it into the
      // format required for ngx-datatable.
      for (const paramLabel in params) {
        const element = params[paramLabel];
        const mostParameters = Math.max(element.value.length, element.left.length, element.right.length);

        for (let i = 0; i < mostParameters; i++) {
          const row = { parameter: '', value: element.value[i], left: element.left[i], right: element.right[i], underline: false };
          if (i == 0) {
            row.parameter = paramLabel;
            // apply border to first element of each section
            row.underline = true;
          }
          rows.push(row);
        }
      }
    }
    // Remove our internal insightSession reference
    for (const row of rows) {
      if (row.value && row.value.origin) {
        row.value.origin = row.value.origin.replace('insightSession > ', '');
      }
      if (row.left && row.left.origin) {
        row.left.origin = row.left.origin.replace('insightSession > ', '');
      }
      if (row.right && row.right.origin) {
        row.right.origin = row.right.origin.replace('insightSession > ', '');
      }
    }
    return [rows, hasLeft, hasRight, hasNoContext];
  }

  private groupByConditions(rows: ParamTableRow[], params: Record<string, ParamRecord>): void {
    for (const paramLabel in params) {
      const parameter = params[paramLabel];
      const leftConditions = this.groupByConditionName(parameter.left);
      const rightConditions = this.groupByConditionName(parameter.right);
      const noContextConditions = this.groupByConditionName(parameter.value);

      const leftConditionsAvgs = this.conditionAveragesByCondition(leftConditions);
      const rightConditionsAvgs = this.conditionAveragesByCondition(rightConditions);
      const noContextConditionsAvgs = this.conditionAveragesByCondition(noContextConditions);

      const mostParameters = Math.max(leftConditionsAvgs.size, rightConditionsAvgs.size, noContextConditionsAvgs.size);
      for (let i = 0; i < mostParameters; i++) {
        const leftKey = Array.from(leftConditionsAvgs.keys())[i];
        const rightKey = Array.from(rightConditionsAvgs.keys())[i];
        const noContextKey = Array.from(noContextConditionsAvgs.keys())[i];
        // if one of the key is undefined, we will get an empty cell, which is fine
        const row = {
          parameter: i == 0 ? paramLabel : '',
          value: { value: noContextConditionsAvgs.get(noContextKey), origin: noContextKey },
          left: { value: leftConditionsAvgs.get(leftKey), origin: leftKey },
          right: { value: rightConditionsAvgs.get(rightKey), origin: rightKey },
          underline: false,
        };
        // apply border to first element of each section
        if (i === 0) {
          row.underline = true;
        }
        rows.push(row);
      }
    }
  }

  private conditionAveragesByCondition(conditions: { [key: string]: ParamWithOrigin[] }): Map<string, string> {
    const conditionsAvg = new Map<string, string>();
    for (const conditionName in conditions) {
      let sum: number = 0;
      for (const entry of conditions[conditionName]) {
        if (typeof entry.value === 'string' && entry.value.includes('±')) {
          sum += Number(entry.value.split('±')[0]);
        } else {
          sum += Number(entry.value);
        }
      }
      let conditionAvgVal;
      let stdVal;
      if (conditions[conditionName].length == 1) {
        conditionAvgVal = conditions[conditionName][0].value;
      } else {
        conditionAvgVal = Math.round(sum / conditions[conditionName].length * 100) / 100;
      }
      if (conditions[conditionName].length == 0) {
        conditionsAvg.set(conditionName, undefined);
      } else if (conditionName.indexOf(' > ') !== -1 && conditions[conditionName].length > 1) {
        if (conditions[conditionName].some(x => typeof x.value === 'string' && x.value.includes('±'))) {
          // we have at least one trial for this condition where the std is already calculated
          stdVal = this.getStandardDeviationFromArray(conditions[conditionName].map(x => Number(x.value.split('±')[0])), conditions[conditionName].map(x => Number(x.value.split('±')[1]))).toFixed(2);
        } else {
          stdVal = this.getStandardDeviationFromArray(conditions[conditionName].map(x => Number(x.value))).toFixed(2);
        }
        conditionsAvg.set(conditionName, `${conditionAvgVal} ± ${stdVal}`);
      } else {
        // case for reference data or single measure
        conditionsAvg.set(conditionName, `${conditionAvgVal}`);
      }
    }
    return conditionsAvg;
  }

  private getLabelWithUnit(param: GaitParameter): string {
    let paramLabel = param.label.trim();
    const hasUnit = paramLabel.includes('[') && paramLabel.includes(']');
    if (param?.unit !== undefined && !hasUnit) {
      paramLabel += ` [${param.unit.trim()}]`;
    } else if (!hasUnit) {
      paramLabel += ' [-]';
    }
    return paramLabel;
  }

  private splitSessionAndConditionName(trialName: string): string {
    const trialNameArray = trialName.split(' > ');
    if (trialNameArray.length === 1) {
      // reference data cases
      return trialName;
    } else {
      return trialNameArray[0] + ' > ' + trialNameArray[1];
    }
  }

  private groupByConditionName(parameterArray: ParamWithOrigin[]): { [key: string]: ParamWithOrigin[] } {
    const conditions = parameterArray.reduce((conditions, item) => {
      const group = conditions[item.origin] || [];
      if (item.value !== undefined) {
        group.push(item);
      }
      conditions[item.origin] = group;
      return conditions;
    }, {});
    return conditions;
  }

  lightenDarkenColor(col: string, amt: number): string {
    let usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) { r = 255; } else if (r < 0) { r = 0; }

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) { b = 255; } else if (b < 0) { b = 0; }

    let g = (num & 0x0000FF) + amt;

    if (g > 255) { g = 255; } else if (g < 0) { g = 0; }

    const newColor = (g | (b << 8) | (r << 16));

    const pad = "000000";
    const result = (pad + newColor.toString(16)).slice(-pad.length);
    return (usePound ? "#" : "") + result; // newColor.toString(16);
  }

  public getAverageMeasureData(dataTracks: GaitMeasureArray[], iRefStartIndex = -1): GaitMeasureArray[] {
    const avgGaitParams: GaitMeasureArray[] = [];

    // if we have ref, exclude from averaging and add to the end of params
    const hasRef = iRefStartIndex > -1;
    const dataTracksWithoutRef = hasRef ? dataTracks.slice(0, iRefStartIndex) : dataTracks;

    const measureGroups: Map<string, GaitMeasureArray> = this.getMeasureGroups(dataTracksWithoutRef);
    const averages = [];
    for (const group of measureGroups.values()) {
      const leftArray: GaitParameter[] = [];
      const rightArray: GaitParameter[] = [];
      const noContextArray: GaitParameter[] = [];
      for (const measure of group) {
        if (measure.context && measure.context.indexOf('Left') !== -1) {
          leftArray.push(measure);
        } else if (measure.context && measure.context.indexOf('Right') !== -1) {
          rightArray.push(measure);
        } else {
          noContextArray.push(measure);
        }
      }
      const leftParam = this.getAvgForParamsArray(leftArray);
      if (leftParam) {
        averages.push(leftParam);
      }
      const rightParam = this.getAvgForParamsArray(rightArray);
      if (rightParam) {
        averages.push(rightParam);
      }
      const noContextParam = this.getAvgForParamsArray(noContextArray);
      if (noContextParam) {
        averages.push(noContextParam);
      }
    }
    avgGaitParams.push(averages);
    if (hasRef) {
      avgGaitParams.push(dataTracks.slice(iRefStartIndex).reduce((accumulator, value) => accumulator.concat(value), []));
    }

    return avgGaitParams;
  }

  private getAvgForParamsArray(paramsArray: GaitParameter[]): GaitParameter {
    let gaitParam: GaitParameter;
    let sum = 0;
    for (const value of paramsArray) {
      sum += Number(value.values.mean);
    }
    const average = sum / paramsArray.length;
    if (paramsArray.length > 0) {
      const paramHasStd = paramsArray.length == 1 && paramsArray[0].values?.std !== undefined ? true : false;
      let std = this.getStandardDeviationFromArray(paramsArray.map(x => Number(x.values.mean)));
      if (paramsArray.some(x => Object.keys(x.values).includes('std')) && paramsArray.map(x => Number(x.values.mean)).length === paramsArray.map(x => Number(x.values.std)).length) {
        std = this.getStandardDeviationFromArray(paramsArray.map(x => Number(x.values.mean)), paramsArray.map(x => Number(x.values.std)));
      }

      gaitParam = {
        context: paramsArray[0].context,
        label: paramsArray[0].label,
        unit: paramsArray[0].unit,
        values: { mean: Number(average.toFixed(2)), std: paramsArray.length > 1 || paramHasStd ? Number(std.toFixed(2)) : undefined }
      };
    }
    return gaitParam;
  }

  private getMeasureGroups(dataTracks: GaitMeasureArray[]): Map<string, GaitMeasureArray> {
    const measureGroups: Map<string, GaitMeasureArray> = new Map<string, GaitMeasureArray>();

    for (const dataTrack of dataTracks) {
      for (const sample of dataTrack) {
        if (!measureGroups.has(sample.label)) {
          measureGroups.set(sample.label, new Array(sample));
        } else {
          measureGroups.get(sample.label).push(sample);
        }
      }
    }
    return measureGroups;
  }

  public getStandardDeviationFromArray(array: number[], stdArray?: number[]): number {
    const mean = array.reduce((a, b) => a + b) / array.length;
    if (stdArray !== undefined && array.length > 0 && array.length === stdArray.length) {
      // combine variance using equal weight of all samples https://www.emathzone.com/tutorials/basic-statistics/combined-variance.html
      // For 2 samples: std = sqrt((n1*(s1^2 + (x1 - sc)^2) + n2*(s2^2 + (x2 - sc)^2))/(n1 + n2)) with xc mean, and n1/n2 the number of samples (== 1 in this case)
      let variance = 0;
      for (let i = 0; i < array.length; i++) {
        variance += Math.pow(stdArray[i], 2) + Math.pow(array[i] - mean, 2);
      }
      return Math.sqrt(variance / array.length);
    } else {
      // sqrt(sum(Δx^2)/arrayLength) https://en.wikipedia.org/wiki/Standard_deviation#Uncorrected_sample_standard_deviation
      return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / array.length);
    }
  }

  public hasContextInChart(lineChartMajorGroups: LineChartMajorGroup[], contextToCheck: DataContext): boolean {
    // Check if we have left or right in the data
    for (const majorGroup of lineChartMajorGroups) {
      for (const lineChartGroup of majorGroup.groups) {
        for (const dataTrack of lineChartGroup.tracks) {
          const isEmgWithoutCycles = dataTrack.hasCycles === false && dataTrack.dataType === 'emg' && (dataTrack.id.toLowerCase().includes('emg-input#') || dataTrack.id.toLowerCase().includes('analog-input#'));
          const chartsToSkipForContextColors = (isEmgWithoutCycles || (dataTrack.dataType === 'force' && dataTrack.id.toLowerCase().includes('force-input#') && dataTrack.hasCycles !== true) || dataTrack.id.toLowerCase().includes('progression_params') || dataTrack.dataType === 'csv');
          if (chartsToSkipForContextColors) {
            continue;
          }
          const context = DataHelper.getDataContext(dataTrack.labels.title, DataContext.noSide, dataTrack.mergedTrack);
          for (const p in dataTrack.labels.data) {
            const labelToCheckWithoutTrialName = p.split(' > ')[0];   // Cut everything after ' > ' (keep original string if ' > ' not found)
            if (!p.toLowerCase().includes('<<<reference>>>')) {
              const curContext = DataHelper.splitDataContextFromLabel(context, labelToCheckWithoutTrialName);
              if (contextToCheck == DataContext.leftSide && curContext == DataContext.leftSide) {
                return true;
              } else if (contextToCheck == DataContext.rightSide && curContext == DataContext.rightSide) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  public setLineCharts(sharedTracks: LineChartGroup[], sortTrackLabels: boolean): void {
    this.lineCharts = sharedTracks;
    if (sortTrackLabels) {
      for (const chart of this.lineCharts) {
        for (const track of chart.tracks) {
          this.sortTrackLabels(track);
        }
      }
    }
  }

  private sortTrackLabels(track: DataTrack): void {
    // sort the labels in tracks alphabetically for gcd cycles, so legends are also sorted
    track.labels.data = Object.keys(track.labels.data).sort().reduce((obj, key) => {
      obj[key] = track.labels.data[key];
      return obj;
    }, {});
  }

  public findTimeInChart(chartData: DataTrack, xClickCoordinate: number, curTime: number, leftDisabled: boolean, rightDisabled: boolean, useCycle?: string): number {
    // Check if we have time or cycles chart.
    // For time chart we jump to the clicked time, for cycles we try to find matching percentage
    let newTime: number;
    if (curTime >= 0 && xClickCoordinate !== undefined) {
      if (chartData.hasCycles !== true) {
        newTime = xClickCoordinate;
      } else if (chartData.hasCycles === true && chartData.cycleTimes !== undefined) {
        let cycleLabels: string[] = [];
        if (this.getChartMode() === 'trial') {
          cycleLabels = Object.keys(chartData.cycleTimes);
        } else if (this.getChartMode() === 'report' && this.selectedClipPath) {
          // for report, filter based on trial selected
          cycleLabels = Object.keys(chartData.cycleTimes).filter(x => x.endsWith(this.selectedClipPath));
        }

        // for a click in cycle chart, we find left and/or right cycle based on current time of the timebar
        // if we are in a cycle, use that cycle, otherwise find the nearest.
        // if we find left and right, left has preference (cycles without context are stored as left)
        const cycleLeftSelected: CycleSelected = { name: undefined, tMin: 1e6, isInCycle: false };
        const cycleRightSelected: CycleSelected = { name: undefined, tMin: 1e6, isInCycle: false };
        if (!useCycle) {
          for (const cycle of cycleLabels) {
            const cycleTime = chartData.cycleTimes[cycle];
            const tMinNew = Math.min(Math.abs(curTime - cycleTime['time-start']), Math.abs(curTime - cycleTime['time-end']));
            let isInCycle = false;
            if (curTime >= cycleTime['time-start'] && curTime <= cycleTime['time-end']) {
              isInCycle = true;
            }

            const cycleNameToCheck = cycle.split(' > ')[0];
            if (cycleNameToCheck.includes('(left)') && !leftDisabled || cycleNameToCheck.includes('(right)') && !rightDisabled || (!cycleNameToCheck.includes('(left)') && !cycleNameToCheck.includes('(right)'))) {
              if (cycleNameToCheck.includes('(right)')) {
                if (!cycleRightSelected.isInCycle) {
                  cycleRightSelected.isInCycle = cycleRightSelected.isInCycle || isInCycle;
                  this.checkTimeInCycle(cycleRightSelected, cycle, tMinNew);
                }
              } else {
                if (!cycleLeftSelected.isInCycle) {
                  cycleLeftSelected.isInCycle = cycleLeftSelected.isInCycle || isInCycle;
                  this.checkTimeInCycle(cycleLeftSelected, cycle, tMinNew);
                }
              }
            }
          }
          // Now find corresponding percentage
          if (cycleLeftSelected.name || cycleRightSelected.name) {
            // by default we pick left
            let selectedCycle = chartData.cycleTimes[cycleLeftSelected.name];
            if (cycleLeftSelected.name === undefined && cycleRightSelected.name !== undefined) {
              selectedCycle = chartData.cycleTimes[cycleRightSelected.name];
            }
            const cycleStart = selectedCycle['time-start'];
            const cycleEnd = selectedCycle['time-end'];
            const cycleDuration = cycleEnd - cycleStart;
            newTime = xClickCoordinate / 100 * cycleDuration + cycleStart;
          }
        } else {
          // if the cycle to use has been passed, we just use that one
          const selectedCycle = chartData.cycleTimes[useCycle];
          const cycleStart = selectedCycle['time-start'];
          const cycleEnd = selectedCycle['time-end'];
          const cycleDuration = cycleEnd - cycleStart;
          newTime = xClickCoordinate / 100 * cycleDuration + cycleStart;
        }
      }
    }
    return newTime;
  }


  private checkTimeInCycle(cycleLeftSelected: CycleSelected, cycle: string, tMinNew: number): void {
    if (cycleLeftSelected.isInCycle) {
      cycleLeftSelected.name = cycle;
      cycleLeftSelected.tMin = tMinNew;
    } else if (tMinNew < cycleLeftSelected.tMin) {
      cycleLeftSelected.name = cycle;
      cycleLeftSelected.tMin = tMinNew;
    }
  }

  // returns true if the label is a reference or average
  public isAvgOrReference(label: string): boolean {
    if (label.endsWith('<<<reference>>>') ||
      (label.toLowerCase().startsWith(this.meanDataLabel.toLowerCase()) && label.endsWith('<<<conditionAvg>>>')) ||
      (label.toLowerCase().startsWith(this.stdDataLabel.toLowerCase()) && label.endsWith('<<<conditionAvg>>>')) ||
      label.toLowerCase().startsWith(this.meanDataLabel.toLowerCase()) ||
      label.toLowerCase().startsWith(this.stdDataLabel.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }
}
