import { Pipe, PipeTransform } from '@angular/core';
import { Timecode } from 'timecode';

@Pipe({
  name: 'timecodeFormatter'
})
export class TimecodeFormatterPipe implements PipeTransform {

  transform(value: number, base: string, fps: string): any {
    const framerate = TimecodeFormatterPipe.convertFramerate(fps);
    if (base) {
      const tc = Timecode.init({
        framerate: framerate,
        timecode: base,
        drop_frame: framerate % 1 !== 0
      });
      const frames = Math.floor(value * framerate);
      tc.add(frames);
      return tc.toString();
    }
    return value.toFixed(2);
  }

  static convertFramerate(framerate: string): number {
    switch (framerate) {
      case 'FPS_24': return 24;
      case 'FPS_25': return 25;
      case 'FPS_29_97': return 29.97;
      case 'FPS_30': return 30;
      case 'FPS_50': return 50;
      case 'FPS_59_94': return 59.94;
      case 'FPS_60': return 60;
      case 'FPS_1000': return 1000;
    }
  }
}
