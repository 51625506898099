  <div *ngIf="!loadingComplete else clipdiv" class="preview-image">
  <div *ngIf="!hidePreview" class="preview-image" [ngStyle]="{ 'background-image': 'url(' + (previewImageUri || 'assets/preview_xl.jpg') + ')'}"></div>
  <div class="progress-overlay">
    <div *ngIf="(loadingClip && !loadingComplete) else playbutton">
      <img *ngIf="!hideBranding; else nobrand" class="progress-text" src="assets/logo_horizontal.svg"/>
      <ng-template #nobrand>
        <div class="progress-text mb-3" style="color: #aaa; font-weight: lighter">LOADING 3D DATA ...</div>
      </ng-template>
      <div class="progress-border">
        <div class="progress-bar" #progressbar></div>
      </div>
    </div>
    <ng-template #playbutton>
        <svg-icon *ngIf="loadOnClick" src="/assets/play.svg" class="playbutton svg-clickable-success" (click)="clickPlayButton()"></svg-icon>
    </ng-template>
  </div>
  <img style="position: absolute; width: 0px; visibility: hidden;" src="assets/logo_horizontal.svg"/> <!-- preload the svg -->
  <!-- <img class="preview-image" [src]="previewImageUri || 'assets/preview_xl.jpg'"/> -->
</div>
<ng-template #clipdiv>
  <div id="clip-container" *ngIf="clipId" class="clip">
    <app-splitscreen-mocap-clip
      [globalTimebar]="true"
      [clipId]="clipId"
      [noCharts]="noCharts"
      [noVideo]="noVideo"
      [includeEditLink]="includeEditLink"
      [includeShareButton]="includeShareButton"
      [animationClips]="animationClips"
      [autoPlay]="this.autoPlay"
      [selection]="selection"
      [hideControls]="hideControls"
      [noBack]="hideBackground"
      [allowSelectionEditing]="allowSelectionEditing"
      [showFullscreenIcon]="showFullscreenIcon"
      [moveFollowCam]="moveFollowCam"
      (animationPlayed)="animationPlayed.emit()"
      (selectionChange)="selectionChange.emit($event)"
      (share)="share.emit()"
      class="clip"
    #mocapClip></app-splitscreen-mocap-clip>
  </div>
</ng-template>
