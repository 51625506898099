import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sync-dropdown',
  templateUrl: './sync-dropdown.component.html',
  styleUrls: ['./sync-dropdown.component.scss']
})
export class SyncDropdownComponent {
  @Input() currentSync: string;
  @Input() syncList: string[] = [];

  @Output() public selectedSyncDropdownElement: EventEmitter<HTMLSelectElement> = new EventEmitter();
  @Output() public keyPress: EventEmitter<number> = new EventEmitter();

  public change(syncDropdownElement: HTMLSelectElement): void {
    this.selectedSyncDropdownElement.emit(syncDropdownElement);
  }

  /**
   * @param event: string containing an event name, typically "cycle-x" where x is a number starting from zero
   * @returns the event string where the last number present is increased by 1, plus the "cycle-" renaming and moving the left and right laterality
   * e.g. "cycle-0 (left)" returns "Left Initial Contact 1"
   */
  public renameEvent(event: string): string {
    if (event.match(/\d+/) && event.match(/\d+/).length > 0) {
      const lastNum = +event.match(/\d+/)[0];
      const numIndex = event.lastIndexOf(lastNum.toString());
      let newName = event.slice(0, numIndex) + (lastNum + 1).toString() + event.slice(numIndex+ (lastNum.toString().length));
      if (newName.indexOf(' (left)') !== -1) {
        newName = newName.replace(' (left)', '');
        newName = 'Left ' + newName;
      } else if (newName.indexOf(' (right)') !== -1) {
        newName = newName.replace(' (right)', '');
        newName = 'Right ' + newName;
      }
      return newName.replace('cycle-', 'Initial Contact ');
    } else {
      return event;
    }
  }

  public keyPressing($event): void {
    this.keyPress.emit($event.keyCode);
    $event.preventDefault();
  }
}
