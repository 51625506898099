<app-css-loading-spinner *ngIf="this.loading"></app-css-loading-spinner>
<ngx-datatable *ngIf="!this.loading" [rows]="rows" [columnMode]="ColumnMode.force" [rowClass]="getRowClass">
    <ngx-datatable-column name="Parameter" [sortable]="false">
        <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Value" *ngIf="hasNoContext" [sortable]="false" [cellClass]="getCellClass">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span [tooltip]="value.origin" placement="left">{{value.value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Left" *ngIf="hasLeft" [sortable]="false" [cellClass]="getCellClass">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span [tooltip]="value.origin" placement="left">{{value.value}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Right" *ngIf="hasRight" [sortable]="false" [cellClass]="getCellClass">
        <ng-template let-value="value" ngx-datatable-cell-template>
            <span [tooltip]="value.origin" placement="left">{{value.value}}</span>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
