import { ColorService } from 'app/shared/services/color-service/color-service.service';
import * as THREE from 'three';
import { OpticalSegmentGroup, SideEnum } from "./clip-player";
import { SkeletonMathHelper } from "./skeleton-math-helper";

export class OpticalSegmentRenderer {

  constructor(markerList: any, segments: OpticalSegmentGroup[], colorService?: ColorService) {

    this.opticalSegmentContainer = new THREE.Group();

    for (let i = 0; i < segments.length; i++) {
      if (colorService) {
        this.createOpticalSegmentGroup(markerList, this.opticalSegmentContainer, segments[i], colorService.threeDPlayerRight, colorService.threeDPlayerLeft);
      } else {
        this.createOpticalSegmentGroup(markerList, this.opticalSegmentContainer, segments[i]);
      }
    }
  }


  opticalSegmentContainer: any;

  public static createSegment(source: {position: THREE.Vector3 }, target: {position: THREE.Vector3}, color, lineLength?: number) {

    // This is a fix for the latest version of threejs, restore it after updating it

    // const geometry = new BufferGeometry();
    // const points: THREE.Vector3[] = [];
    // points.push(source.position);
    // points.push(target.position);
    // geometry.setFromPoints(points);
    // geometry.attributes.position.needsUpdate = true;
    // (geometry.attributes.position as THREE.BufferAttribute).usage = THREE.DynamicDrawUsage;

    const geometry = new THREE.Geometry();
    geometry.dynamic = true;
    geometry.vertices.push(source.position);
    geometry.vertices.push(target.position);
    geometry.verticesNeedUpdate = true;

    const material = new THREE.LineBasicMaterial({ color: color, linewidth: 0.6 }); // , transparent: true, opacity: 0.6});
    const line = new THREE.Line(geometry, material);
    line.castShadow = true;
    line.frustumCulled = false;
    if (lineLength !== undefined) {
      line.userData.lineLength = lineLength;
    }

    return line;
  }
  private createOpticalSegmentGroup(markerList: any, container: THREE.Group, segments: OpticalSegmentGroup, rightSideColor = 0x00ff00, leftSideColor = 0xff0000) {
    const noSideColor = 0xeeeeee;
    let color;
    if (segments.color) {
      color = segments.color;
    } else if (segments.side) {
      switch (segments.side) {
        case SideEnum.leftSide:
          color = leftSideColor;
          break;
        case SideEnum.rightSide:
          color = rightSideColor;
          break;
        default:
          color = noSideColor;
          break;
      }
    } else {
      color = noSideColor;
    }
    const allConnected = segments.allConnected ? true : false;

    if (allConnected) {
      for (let i = 0; i < segments.markerList.length; i++) {
        for (let j = 1; j < segments.markerList.length; j++) {
          const source = SkeletonMathHelper.getMarkerWithName(markerList, segments.markerList[i]);
          const target = SkeletonMathHelper.getMarkerWithName(markerList, segments.markerList[j]);

          if (source && target) {
            this.opticalSegmentContainer.add(OpticalSegmentRenderer.createSegment(source, target, color, segments?.lineLength));
          }
        }
      }
    } else {
      for (let i = 1; i < segments.markerList.length; i++) {
        const source = SkeletonMathHelper.getMarkerWithName(markerList, segments.markerList[i - 1]);
        const target = SkeletonMathHelper.getMarkerWithName(markerList, segments.markerList[i]);

        if (source && target) {
          this.opticalSegmentContainer.add(OpticalSegmentRenderer.createSegment(source, target, color, segments?.lineLength));
        }
      }
    }
  }

  public getSegmentsContainer() {
    return this.opticalSegmentContainer;
  }

  public scale(scale: number) {
    this.opticalSegmentContainer.scale.set(scale, scale, scale);
  }

  public toggleVisibility(visible?: boolean) {
    if (this.opticalSegmentContainer !== undefined) {
      this.opticalSegmentContainer.visible = (visible !== undefined) ? visible : !this.opticalSegmentContainer.visible;
    }
  }

  public update() {
    if (this.opticalSegmentContainer && this.opticalSegmentContainer.visible) {
      for (let i = 0; i < this.opticalSegmentContainer.children.length; i++) {
        if (this.opticalSegmentContainer.children[i].userData?.lineLength !== undefined && this.opticalSegmentContainer.children[i].geometry.vertices.length == 2) {
          let dummy = new THREE.Vector3();
          dummy = this.opticalSegmentContainer.children[i].geometry.vertices[1].sub(this.opticalSegmentContainer.children[i].geometry.vertices[0]);
          dummy.setLength(this.opticalSegmentContainer.children[i].userData?.lineLength);
          this.opticalSegmentContainer.children[i].geometry.vertices[1] = dummy.add(this.opticalSegmentContainer.children[i].geometry.vertices[0]);
        }
        this.opticalSegmentContainer.children[i].geometry.verticesNeedUpdate = true;
      }
    }
  }


}
