import { Component } from '@angular/core';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-clip-error-warning',
  templateUrl: './clip-error-warning.component.html',
  styleUrls: ['./clip-error-warning.component.scss']
})
export class ClipErrorWarningComponent {
  public constructor(
    private readonly authService: AuthService,
  ) {}
}
