<div style="color: #eee; width: 250px;">
  <ng-container *ngIf="project">
    <div class="noselect clickable-info" (click)="projectOverviewExpanded = !projectOverviewExpanded">
      <span class="mr-2">{{project.name | stripFirstPath }}</span>
      <i [class]="projectOverviewExpanded ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
    </div>


    <div [collapse]="!projectOverviewExpanded">
      <div class="card col text-white bg-dark p-0 mb-2" style="width: 50%">
        <img class="card-img-top mx-auto" src="{{project.imageUri}}">
      </div>
      <div class="noselect">
        <div *ngIf="!isSubjectLock()" class="mb-3 clickable-info" [routerLink]="overviewLink" routerLinkActive='highlight-info'
          [routerLinkActiveOptions]="{exact: true}">
          <i style="font-size: 1.5em" class="fa fa-home"></i><span class="ml-3">Overview</span>
        </div>
        <div *ngIf="showDataset()" class="mb-3 clickable-info" [routerLink]="datasetLink" routerLinkActive='highlight-info'>
          <i style="font-size: 1.5em" class="fa fa-database"></i><span class="ml-3">Dataset</span>
        </div>
        <div *ngIf="this.auth.hasAccessToSubjects() && !isSubjectLock()" class="mb-3 clickable-info" [routerLink]="subjectLink" routerLinkActive='highlight-info'>
          <i style="font-size: 1.5em" class="fa fa-user"></i><span class="ml-3">Subjects</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
