import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class KeyboardHandlerService {
  // determine if the given event originated from an input or text area
  public isEventFromInput(event: KeyboardEvent): boolean {
    if (event.target instanceof Node) {
      // case "SELECT":
      switch (event.target.nodeName) {
        case "INPUT":
        case "TEXTAREA":
          return true;
          break;
        default:
          return false;
          break;
      }
    }
    return false;
  }
}
