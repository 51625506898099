import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';

import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-like-clip-button',
  templateUrl: './like-clip-button.component.html',
  styleUrls: ['./like-clip-button.component.scss']
})
export class LikeClipButtonComponent implements OnInit, OnDestroy {

  @Input()
  set clipId(id: string) {
    this._clipIdString = id;
    this._clipId.next(id);
  }

  private _clipId = new Subject<string>();
  private _clipIdString: string;

  public likes: number;
  public likedByViewer: boolean;
  likers: string [] = [];

  private subs: Subscription[] = [];
  private query: QueryRef<any>;

  constructor(
    private apollo: Apollo,
    private auth: AuthService
  ) {
    this.subs.push(this._clipId.pipe(
      switchMap(clipId =>
        this.apollo.watchQuery<any>({
          query: gql`
            query getLikes($clipId: ID!) {
              node(id: $clipId) {
                ... on MocapClip {
                  id,
                  likes,
                  likedByViewer,
                  likedBy {
                    id,
                    displayName
                  }
                }
              }
            }
          `,
          variables: {
            clipId: clipId
          }
        }).valueChanges
      )
    ).subscribe(({data}) => {
      this.likes = data.node.likes;
      this.likedByViewer = data.node.likedByViewer;
      this.likers = [];
      const maxListNo = 3;
      for (const liker of data.node.likedBy) {
        if (this.likers.length >= maxListNo) {
          this.likers.push('and ' + (data.node.likedBy.length - maxListNo) + ' more');
          break;
        } else
          this.likers.push(liker.displayName);
      }
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public toggleLike() {
    this.auth.login().then(loggedIn => {
      if (loggedIn) {
        this.apollo.mutate({
          mutation: gql`
          mutation toggleLike($clipId: ID!) {
            toggleLike(clipId: $clipId) {
              clip {
                id,
                likes,
                likedByViewer,
                likedBy {
                  id,
                  displayName
                }
              }
            }
          }
        `,
          variables: {
            clipId: this._clipIdString
          }
        }).subscribe();
      }
    });
  }
}
