import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PublicAllowedService {
  public enterpriseTooltip: string = `As an Enterprise user with strict
  privacy requirements, public projects are disabled. If you are looking
  to share data outside your organization, please get in touch with us at
  support@moveshelf.com and we will work with you to find a solution.`

  constructor(public auth: AuthService) { }
  
  defaultPublic() : boolean {
    // Projects are private by default for everyone except Basic users, who
    // can't make private projects.
    return this.auth.isBasicAuthenticated()
  }

  switchDisabled(): boolean {
    // Basic users can't make private projects.
    // Enterprise users can't public projects, so they both can't flip the switch.
    return (this.auth.isBasicAuthenticated() || this.auth.isEnterpriseAuthenticated())
  }

  privateSharingDisabled(): boolean {
    return this.auth.isEnterpriseAuthenticated();
  }
  
  tooltip(): string {
    if (this.auth.isBasicAuthenticated()) 
      return "Upgrade to activate private projects";
    else if (this.auth.isEnterpriseAuthenticated()) {
      return this.enterpriseTooltip;
    } else {
      return undefined;
    }
  }
}
