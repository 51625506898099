import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'regexEscape'
})
export class RegexEscapePipe implements PipeTransform {

  transform(value: string): string {
    return value ? value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') : '';
  }

}
