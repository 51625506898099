const THREE = require('three');
ReferenceSystemHelper.prototype = Object.create( THREE.LineSegments.prototype );
ReferenceSystemHelper.prototype.constructor = ReferenceSystemHelper;

ReferenceSystemHelper.prototype.updateMatrixWorld = function () {

	var vector = new THREE.Vector3();

	var boneMatrix = new THREE.Matrix4();
	var matrixWorldInv = new THREE.Matrix4();

	return function updateMatrixWorld( force ) {

		var bones = this.bones;
		var geometry = this.geometry;
		var position = geometry.getAttribute( 'position' );

		matrixWorldInv.getInverse( this.root.matrixWorld );

		for ( var i = 0, j = 0, k = 0; i < bones.length; i ++ ) {

			var bone = bones[ i ];
			boneMatrix.multiplyMatrices( matrixWorldInv, bone.matrixWorld );
			vector.setFromMatrixPosition( boneMatrix );
			var lineLength = this.lineLength;
            position.setXYZ( k, vector.x, vector.y, vector.z );

			position.setXYZ( k + 1, vector.x + boneMatrix.elements[0]*lineLength, vector.y + boneMatrix.elements[1]*lineLength, vector.z + boneMatrix.elements[2]*lineLength);
			position.setXYZ( k + 2, vector.x, vector.y, vector.z );
			position.setXYZ( k + 3, vector.x + boneMatrix.elements[4]*lineLength, vector.y + boneMatrix.elements[5]*lineLength, vector.z + boneMatrix.elements[6]*lineLength);
			position.setXYZ( k + 4, vector.x, vector.y, vector.z );
			position.setXYZ( k + 5, vector.x + boneMatrix.elements[8]*lineLength, vector.y + boneMatrix.elements[9]*lineLength, vector.z + boneMatrix.elements[10]*lineLength);

			k += 6;

		}

		geometry.getAttribute( 'position' ).needsUpdate = true;
		THREE.Object3D.prototype.updateMatrixWorld.call( this, force );
	};

}();

function ReferenceSystemHelper( object, firstBone, axLength ) {

	var fullBones = getBoneList( object );
	var bones = [];
	var geometry = new THREE.BufferGeometry();

	var vertices = [];
	var colors = [];

	var vertices2 = [];
	var colors2 = [];

	var color1 = new THREE.Color( 1, 0, 0 );
	var color2 = new THREE.Color( 0, 1, 0 );
	var color3 = new THREE.Color( 0, 0, 1 );

	for ( var i = firstBone; i < fullBones.length; i ++ ) {

		var bone = fullBones[ i ];
		bones.push(bone);

        vertices.push( 0, 0, 0 );
        vertices.push( 0, 0, 0 );

        vertices.push( 0, 0, 0 );
        vertices.push( 0, 0, 0 );

        vertices.push( 0, 0, 0 );
        vertices.push( 0, 0, 0 );

        colors.push( color1.r, color1.g, color1.b );
        colors.push( color1.r, color1.g, color1.b );

        colors.push( color2.r, color2.g, color2.b );
        colors.push( color2.r, color2.g, color2.b );

        colors.push( color3.r, color3.g, color3.b );
        colors.push( color3.r, color3.g, color3.b );

	}

	geometry.addAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
	geometry.addAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

	var material = new THREE.LineBasicMaterial( { vertexColors: THREE.VertexColors, depthTest: false, depthWrite: false, transparent: true } );

	THREE.LineSegments.call( this, geometry, material );

	this.root = object;
	this.bones = bones;
    this.lineLength = axLength;

	this.matrix = object.matrixWorld;
	this.matrixAutoUpdate = false;


}


function getBoneList( object ) {

	var boneList = [];

	if ( object && object.isBone ) {

		boneList.push( object );

	}

	for ( var i = 0; i < object.children.length; i ++ ) {

		boneList.push.apply( boneList, getBoneList( object.children[ i ] ) );

	}

	return boneList;

}

export {ReferenceSystemHelper}
