import { HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { additionalDataDownloadUri, additionalDataMetadata } from 'app/shared/queries';
import { BackendService } from 'app/shared/services/backend/backend.service';
import { pollConfig } from 'app/shared/services/polling.service';
import { FileWrapper } from 'app/shared/upload-directory/directory-upload.service';
import gql from 'graphql-tag';
import { BehaviorSubject, firstValueFrom, from, Observable, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ObservableFileReaderService } from './clip-upload/observable-file-reader.service';
import { Progress } from './clip-uploader.service';
import { calculateCrc32c } from './crc32c';
import { ResumableUploadService } from './resumable-upload.service';



export enum UploadStatus {
  Pending = "Pending",
  Processing = "Processing",
  Complete = "Complete",
  Failed = "Failed"
}

export interface AdditionalDataInfo {
  id: string;
  dataType: string;
  originalFileName?: string;
  uploadStatus: UploadStatus;
  previewDataUri?: string;
  selected?: boolean;
  generation?: string;
  outdated?: boolean;
}

export interface EventsRawAdditionalData {
  events: [],
  info: {
    start_frame: number,
    end_frame: number,
    sample_rate: number,
  }
}

interface EventsAdditionalData {
  events: [],
  duration: number,
  startFrame: number,
  endFrame: number
  frameRate: number,
}
export type AdditionalData = EventsAdditionalData;

// A string representation of a framerate as output of ffprobe, expressed as a division;
// e.g. "124999/3125"
type framerateString = string;

@Injectable()
export class AdditionalDataService {

  private readonly getDataQuery = gql`
    query getAdditionalDataInfo($clipId: ID!) {
      node(id: $clipId) {
        ... on MocapClip {
          id,
          additionalData {
            id
            dataType
            originalFileName
            uploadStatus
            outdated
          }
        }
      }
    }
  `;
  private _monitorUploads = new Subject<AdditionalDataInfo[]>();
  private _progressUploads: BehaviorSubject<Progress>;
  private _fileInUpload: BehaviorSubject<number>;

  constructor(
    private apollo: Apollo,
    private uploader: ResumableUploadService,
    private reader: ObservableFileReaderService,
    private readonly backendService: BackendService,
  ) {
    this._progressUploads = new BehaviorSubject<Progress>({pendingUploads: 0, progress: 0, currentUploadId: null});
    this._fileInUpload = new BehaviorSubject<number>(0);
  }

  fetchAdditionalDataInfo(clipId: string): Observable<AdditionalDataInfo[]> {
    // Generate a random polling interval between DEFAULT (5s) and 7s. This dilutes pollings in a
    // window instead of executing them all at the same time
    // @note: this window is being kept small to avoid issues similar to #2016. Will be refactored
    //        with #2005
    const randomPollingInterval = Math.round(pollConfig.DEFAULT + Math.random() * 2000);
    return this.apollo.watchQuery<any>({
      query: this.getDataQuery,
      pollInterval: randomPollingInterval,
      variables: {
        clipId: clipId
      }
    }).valueChanges.pipe(
      map(({data}) => {
        return data.node.additionalData;
      }),
      tap(items => this._monitorUploads.next(items))
    );
  }

  monitorProgress() {
    return this._progressUploads;
  }

  public fileInUpload() {
    return this._fileInUpload.asObservable();
  }

  extensionCheck(filename: string, exts: string[]) {
    for (const ext of exts) {
      if (filename.toLowerCase().indexOf(ext) !== -1) {
        return true;
      }
    }

    return false;
  }

  isVideoFile(filename: string): boolean {
    const videoExt = ['.mp4', '.mov', '.mpg', '.avi', '.mkv'];
    return this.extensionCheck(filename, videoExt);
  }

  isDocumentFile(filename: string): boolean {
    const docExt = ['.pdf'];
    return this.extensionCheck(filename, docExt);
  }

  isImageFile(filename: string): boolean {
    const imgExt = ['.png', '.jpg'];
    return this.extensionCheck(filename, imgExt);
  }

  isDataFile(filename: string): boolean {
    const dataExt = ['.csv', '.json', '.txt'];
    return this.extensionCheck(filename, dataExt);
  }

  isMotionFile(filename: string): boolean {
    const motionExt = ['.bvh', '.fbx', '.trc', '.glb', '.mox'];
    return this.extensionCheck(filename, motionExt);
  }

  public uploadMultipleAdditionalDataWithoutType(clipId: string, files: File[] | FileWrapper[]): Observable<any> {
    return from(files).pipe(mergeMap(file => this.uploadAdditionalDataWithoutType(clipId, file)));
  }

  public uploadAdditionalDataWithoutType(clipId: string, file: File | FileWrapper): Observable<any> {
    let data_type = 'raw';

    if (this.isVideoFile(file.name)) {
      data_type = 'video';
    }

    if (this.isMotionFile(file.name)) {
      data_type = 'motion';
    }

    if (this.isDocumentFile(file.name)) {
      data_type = 'doc';
    }

    if (this.isDataFile(file.name)) {
      data_type = 'data';
    }

    if (this.isImageFile(file.name)) {
      data_type = 'img';
    }

    if (file.name.indexOf('.xcp') !== -1 || file.name.indexOf('.settings.xml') !== -1) {
      data_type = 'camera';
    }

    return this.uploadAdditionalData(clipId, data_type, file);
  }

  uploadAdditionalData(clipId: string, dataType: string, file: File | FileWrapper): Observable<any> {
    const rawFile = file instanceof FileWrapper ? file.file : file;

    this._progressUploads.next({pendingUploads: 1, progress: 0, currentUploadId: file.name});
    this._fileInUpload.next(this._fileInUpload.value + 1);
    return this.reader.readFile(rawFile).pipe(
      mergeMap(({readFile, data}) => this.apollo.mutate<any>({
        mutation: gql`
          mutation upsertAdditionalData($input: UpsertAdditionalDataInput) {
            upsertAdditionalData(input: $input) {
              uploadUrl
              data {
                id
                dataType
                originalFileName
                uploadStatus
              }
            }
          }
        `,
        variables: {
          input: {
            clipId: clipId,
            dataType: dataType,
            crc32c: calculateCrc32c(new Uint8Array(data)),
            filename: file.name,
            clientId: file.name
          }
        }
      })),
      mergeMap(({data}) => this.uploader.upload(
        new HttpRequest('PUT', data.upsertAdditionalData.uploadUrl, rawFile, {
          reportProgress: true
        })
      ).pipe( map( (evt) => { this.handleHttpEvent(rawFile.name, evt);})))
    );
  }

  private handleHttpEvent(filename: string, event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      const prog = event.loaded / event.total;
      this._progressUploads.next({pendingUploads: 1, progress: prog, currentUploadId: filename});
      return;
    } else if (event.type === HttpEventType.Response) {
      if (event.status === 200) {
        if (this._fileInUpload.value - 1 >= 0) {
          this._fileInUpload.next(this._fileInUpload.value - 1);
        } else {
          this._fileInUpload.next(0);
        }
      }
    }
    return event;
  }

  deleteAdditionalData(clipId: string, dataId: string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation deleteAdditionalData($id: ID!) {
          deleteAdditionalData(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id: dataId
      }
    });
  }

  public async fetchAdditionalData(additionalDataPayload: AdditionalDataInfo): Promise<AdditionalData> {
    switch (additionalDataPayload.dataType) {
      case 'event': {
        const additionalDataResult = await this.backendService.externalGet<EventsRawAdditionalData>(additionalDataPayload.previewDataUri);
        // if there is no info, return undefined
        if (!additionalDataResult?.info) {
          return undefined
        }
        // check info field for required fields
        if (!additionalDataResult?.info?.end_frame || !additionalDataResult?.info?.sample_rate) {
          throw Error('Event json file found but it is missing mandatory information')
        }
        const info = additionalDataResult.info;

        // attempt to calculate global clip duration, this covers 3d clips trimmed at the start
        // we trust the json info object to contain consistent data
        const clipDuration = info.end_frame / info.sample_rate;

        return {
          events: additionalDataResult.events,
          duration: clipDuration,
          startFrame: info.start_frame,
          endFrame: info.end_frame,
          frameRate: info.sample_rate,
        };
      }
      // Add new additional data handlers here while refactoring
      default:
        throw Error('unkown event type');
    }
  }

  public async getVideoResolutionRatio(additionalData: {id: string}): Promise<number> {
    const res = await firstValueFrom(this.apollo.query<{node: {blobMetadata: string}}>({
      query: additionalDataMetadata,
      variables: {
        id: additionalData.id
      },
    }));
    if (res.data?.node?.blobMetadata) {
      // Extract video resolution from video metadata
      const metadata = JSON.parse(res.data.node.blobMetadata);
      const currentRresolution = metadata?.current_resolution
      const originalResolution = metadata?.original_resolution

      if (!currentRresolution || !originalResolution) return 1;

      const resolutionRatio = +currentRresolution/+originalResolution;

      return resolutionRatio;
    }

    // Missing metadata for the object, consider it to have ratio 1
    return 1;
  }

  public getFramerate(additionalData: {framerate: framerateString}): number {
    // Handle high precision framerate definitions
    // @see #2048
    const frameRateParts = additionalData?.framerate?.split('/');
    if (!frameRateParts) return 0;
    let framerate: number = +frameRateParts[0];
    if (frameRateParts.length > 1) {
      framerate /= +frameRateParts[1];
    }
    return framerate;
  }

  public async getAdditionalDataDownloadURI(additionalDataId: string): Promise<string> {
    let downloadUri: string;
    try {
      downloadUri = (await firstValueFrom(this.apollo.query<{node: {originalDataDownloadUri: string}}>({
        query: additionalDataDownloadUri,
        variables: {
          id: additionalDataId
        },
      }))).data.node.originalDataDownloadUri;
    } catch (apiError) {
      console.error('Cannot download the file, error:', apiError);
      return undefined;
    }

    return downloadUri;
  }

  public async downloadGaitAnalysisReport(clipId: string): Promise<void> {
    this.apollo.query<any>({
      query: gql `
        query downloadUri($id: ID!) {
          node(id: $id) {
            ... on MocapClip {
              id
              previewDataUri,
              originalDataDownloadUri,
              additionalData {
                id,
                dataType,
                originalDataDownloadUri,
                originalFileName
              }
            }
          }
        }
        `,
      variables: { id: clipId }
    }).subscribe(({data}) => {
      if (data.node && data.node.additionalData) {
        for (const additionalData of data.node.additionalData) {
          if (additionalData.originalFileName.includes('.docx')) {
            const a = document.createElement('a');
            a.href = additionalData.originalDataDownloadUri;
            document.body.appendChild(a);  // Append the anchor to body to ensure it can be clicked
            a.click();  // Simulate a click on the anchor
            setTimeout(() => {
              document.body.removeChild(a);  // Remove the anchor from the body after clicking
            }, 1000);
          }
        }
      }
    },
    error => {
      console.error('Error getting the download URI: ', error);
    });
  }
}
