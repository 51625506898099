import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from './auth.service';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss'],
})
export class PasswordResetFormComponent implements OnInit {
  @Input()
  public emailAddress: string = '';

  @Input()
  public errorMessage: string;

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public passwordResetSent: boolean = false;
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    @Inject(DOCUMENT) private document) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [
        this.emailAddress,
        Validators.email
      ]
    });
  }

  public sendPasswordReset() {
    this.errorMessage = undefined;
    const url = this.document.location.href;
    this.authService.resetPassword(this.email.value, url)
      .then(() => {
        this.passwordResetSent = true;
      }).catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            // Treat as user not found. There is a difference between angular
            // email validation and firebase validation.
            break;
          case 'auth/user-not-found':
            this.errorMessage = `The email address ${this.email.value} is not registered with Moveshelf.com`;
            break;

          default:
            console.log(error);
        }
      });
  }

  back() {
    this.cancel.emit();
  }

  get email() { return this.form.get('email'); }
}
